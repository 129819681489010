import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../app/hooks';
import { selectAuth } from '../../../features';
import { usePagination } from '../../../hooks';
import { ITranslationKeys } from '../../../i18n/types';
import { IUserVideosResponse } from '../../../types/accountManagement.types';
import { createClassNames, getUserVideos, prepareVideo } from '../../../utils';
import { Loading } from '../../Loading';
import { PaginationControls } from '../../PaginationControls';
import { DownloadManagementButton } from '../DownloadManagementButton';
import { DownloadManagementRow, IDownloadManagementRowProps } from '../DownloadManagementRow';
import './DownloadManagement.styles.scss';

const classNames = createClassNames('download-management');

/**
 * DownloadManagement UI component.
 */
export const DownloadManagement = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectAuth);
  const [data, setData] = useState<IDownloadManagementRowProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { pagination, maxPagination, getMinIndex, getMaxIndex, moveToPrevious, moveToNext } =
    usePagination(12, data.length);

  const handleDownloadAll = () => {
    data.forEach(video => {
      window.open(video.file);
    });
  };

  const handleGetUserVideos = () => {
    if (!user) return;
    getUserVideos(user.id) // TODO: Jirka - Pozor místo user.id by mělo být user.username, které ale nyní nemáme z API
      .then((res: IUserVideosResponse) => {
        res.data.forEach(videoRow => {
          const video = prepareVideo(videoRow);
          if (video.file !== '') {
            setData(prevState => [...prevState, video]);
          }
        });
        setIsLoading(false);
      })
      .catch(err => console.log('Get user videos err: ', err));
  };

  useEffect(() => {
    setData([]);
    setIsLoading(true);
    handleGetUserVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className={classNames()}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={classNames('table-container')}>
            <table className={classNames('table-container__table')}>
              <thead>
                <tr>
                  <th>{t(ITranslationKeys.category)}</th>
                  <th>{t(ITranslationKeys.match)}</th>
                  <th>{t(ITranslationKeys.player)}</th>
                  <th>{t(ITranslationKeys.time)}</th>
                  <th>
                    <DownloadManagementButton
                      label={ITranslationKeys.downloadAll}
                      type='download'
                      onClick={handleDownloadAll}
                      multiple
                    />
                  </th>
                  {/* //TODO: after delete endpoint addition
                   <th>
                    <DownloadManagementButton
                      label={ITranslationKeys.deleteAll}
                      type='delete'
                      multiple
                    />
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {data.slice(getMinIndex(), getMaxIndex()).map((video, index) => (
                  <DownloadManagementRow key={index} {...video} />
                ))}
              </tbody>
            </table>
            <div className={classNames('table-container__pagination')}>
              <PaginationControls
                moveToPrevious={moveToPrevious}
                moveToNext={moveToNext}
                pagination={pagination}
                pageCount={maxPagination}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
