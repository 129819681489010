import { IHokejPlayerManagementRowProps } from '../components';
import { axiosInstance } from '../services/axiosInstance';
import { IClip, IClipsResponse, IGoalkeeper, ILanguageType, IPlayer, IUserVideos } from '../types';

/*
 * API Requests
 */
export const getUserVideos = async (userEmail: string) => {
  const body = { customer: userEmail };
  const response = await axiosInstance.post('videoCut/search', body);
  return response;
};

export const getVideoClips = async () => {
  const response: IClipsResponse = await axiosInstance.get('videoCoach/videoClip');
  return response;
};

export const getLanguage = async (playerId: string) => {
  const response = await axiosInstance.get(`videoCoach/player/${playerId}/language`);
  return response;
};

export const changeLanguage = async (playerId: string, language: keyof ILanguageType<string>) => {
  const response = await axiosInstance.put(`videoCoach/player/${playerId}/language`, { language });
  return response;
};

export const changeEmail = async (playerId: string, email: string) => {
  const response = await axiosInstance.put(`videoCoach/player/${playerId}/email`, { email });
  return response;
};

export const deleteEmail = async (playerId: string) => {
  const response = await axiosInstance.delete(`videoCoach/player/${playerId}/email`);
  return response;
};

export const deleteClip = async (videoClipId: string) => {
  const response = await axiosInstance.delete(`videoCoach/videoClip/${videoClipId}`);
  return response;
};

/*
 * Helper functions
 */
export const preparePlayer = (player: IPlayer | IGoalkeeper, clips: IClip[]) => {
  const playerClips = [...clips.filter(clip => clip.players.includes(player.id))];

  return {
    logoPath: player.currentTeam,
    playerId: player.id,
    playerPost: player.position,
    playerNumber: player.jersey,
    playerFirstName: player.name,
    playerLastName: player.surname,
    playerEmail: player.email,
    clips: playerClips,
  };
};

export const prepareVideo = (video: IUserVideos) => {
  const videoInfo = video.videos[0].description.split(';');
  const match = prepareMatch(videoInfo[0]);

  return {
    ...match,
    player: videoInfo[1],
    time: videoInfo.length === 6 ? videoInfo[3] : videoInfo[4],
    type: videoInfo.length === 6 ? videoInfo[5] : videoInfo[6],
    file: video.videos[0].cut ? video.videos[0].cut.url : '',
    multiple: video.videos.length > 1,
  };
};

export const prepareMatch = (match: string) => {
  const matchInfo = match.split(' ');

  return {
    date: matchInfo[0].substring(0, matchInfo[0].length - 1),
    homeShortcut: matchInfo[1],
    awayShortcut: matchInfo[3],
    score: matchInfo[4].replace('-', ':'),
  };
};

export const sortPlayersByClips = (
  a: IHokejPlayerManagementRowProps,
  b: IHokejPlayerManagementRowProps,
) => {
  const aLength = a.clips.length;
  const bLength = b.clips.length;

  if (aLength < bLength) {
    return 1;
  } else if (aLength > bLength) {
    return -1;
  } else {
    return 0;
  }
};

export const sortPlayersByPost = (
  a: IHokejPlayerManagementRowProps,
  b: IHokejPlayerManagementRowProps,
) => {
  const posts: Record<string, number> = {
    GK: 1,
    DE: 2,
    FO: 3,
  };
  const aPos = posts[a.playerPost];
  const bPos = posts[b.playerPost];

  if (aPos > bPos) {
    return 1;
  } else if (aPos < bPos) {
    return -1;
  } else {
    return 0;
  }
};
