import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  enterExitLocationOptions,
  exitTypeOptions,
  puckGainTypeOptions,
  shotDangerOptions,
  shotGameActionTypeOptions,
  shotLocationOptions,
  shotTypeBaseOptions,
} from '../../../constants';
import { IMetricParamsFilterState, INetZoneUnion, ISelectOption } from '../../../types';
import { getDefaultPredefinedOptionByValue } from '../../../utils';

const initialState: IMetricParamsFilterState = {
  shotLocation: getDefaultPredefinedOptionByValue(shotLocationOptions),
  shotDanger: getDefaultPredefinedOptionByValue(shotDangerOptions),
  shotType: getDefaultPredefinedOptionByValue(shotTypeBaseOptions),
  gameActionType: getDefaultPredefinedOptionByValue(shotGameActionTypeOptions),
  netZone: undefined,
  entryExitType: getDefaultPredefinedOptionByValue(exitTypeOptions),
  enterExitLocation: getDefaultPredefinedOptionByValue(enterExitLocationOptions),
  puckGainType: getDefaultPredefinedOptionByValue(puckGainTypeOptions),
};

export const metricParamsFilterSlice = createSlice({
  name: 'metricParamsFilter',
  initialState,
  reducers: {
    setShotLocation: (state, action: PayloadAction<ISelectOption>) => {
      state.shotLocation = action.payload;
    },
    setShotDanger: (state, action: PayloadAction<ISelectOption>) => {
      state.shotDanger = action.payload;
    },
    setShotType: (state, action: PayloadAction<ISelectOption>) => {
      state.shotType = action.payload;
    },
    setGameActionType: (state, action: PayloadAction<ISelectOption>) => {
      state.gameActionType = action.payload;
    },
    setNetZone: (state, action: PayloadAction<INetZoneUnion | undefined>) => {
      state.netZone = action.payload;
    },
    setEntryExitType: (state, action: PayloadAction<ISelectOption>) => {
      state.entryExitType = action.payload;
    },
    setEnterExitLocation: (state, action: PayloadAction<ISelectOption>) => {
      state.enterExitLocation = action.payload;
    },
    setPuckGainType: (state, action: PayloadAction<ISelectOption>) => {
      state.puckGainType = action.payload;
    },
    resetMetricParamsFilter: (
      _,
      action: PayloadAction<Partial<IMetricParamsFilterState> | undefined>,
    ) => ({
      ...initialState,
      ...action.payload,
    }),
  },
});

export const {
  setShotLocation,
  setShotDanger,
  setShotType,
  setGameActionType,
  setNetZone,
  setEntryExitType,
  setEnterExitLocation,
  setPuckGainType,
  resetMetricParamsFilter,
} = metricParamsFilterSlice.actions;

export const metricParamsFilterReducer = metricParamsFilterSlice.reducer;
