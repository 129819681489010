import { createSlice } from '@reduxjs/toolkit';

import { IMetricsState } from '../../../types';
import { getMetrics, putMetrics } from './metricsActions';

const initialState: IMetricsState = {
  metrics: undefined,
  isLoading: false,
  isAllMetricsLoaded: false,
  error: undefined,
};

export const metricsSlice = createSlice({
  name: 'settings/metrics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMetrics.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getMetrics.fulfilled, (state, action) => {
        state.metrics = action.payload;
        state.isLoading = false;
        state.isAllMetricsLoaded = true;
      })
      .addCase(getMetrics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(putMetrics.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(putMetrics.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.metrics = action.payload;
        }
        state.isLoading = false;
      })
      .addCase(putMetrics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
