import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { IFormationStatsTableData } from '../../../types';
import { Sticker } from '../../Sticker';
import './TeamColumn.styles.scss';

export const TeamColumnHeader = () => {
  const { t } = useTranslation();
  return <span className='team-column'>{t(ITranslationKeys.team)}</span>;
};

export interface ITeamColumnRowProps extends CellContext<IFormationStatsTableData, any> {}

export const TeamColumnCell: FC<ITeamColumnRowProps> = props => {
  return (
    <span className='team-column'>
      <Sticker label={props.getValue()} size='small' />
    </span>
  );
};
