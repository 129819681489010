import { FC, useState } from 'react';

import { createClassNames, getColorByPercent, transformObjectKeysToKebabCase } from '../../utils';
import { Tooltip } from '../Tooltip';
import './Bar.styles.scss';

export interface IBarProps {
  /**
   * Metric value
   */
  metricValue: number;
  /**
   * Percentile value
   */
  percent: number;
  /**
   * Name of the attribute - only used for card component (not required)
   */
  name?: string;
  /**
   * Color of the bar
   * @optional "purple" - bar component is colored in purple color.
   * @optional "orange" - bar component is colored in orange color.
   */
  color?: 'purple' | 'orange';
  /**
   * If true, the center line of the bar component is shown
   */
  showCenter?: boolean;
  /**
   * If true, the bar component is reversed
   */
  reversed?: boolean;
  /**
   * If true, the tooltip component is shown when user hovers over the bar component
   */
  showTooltip?: boolean;
}

const classNames = createClassNames('bar');

export const Bar: FC<IBarProps> = ({
  metricValue,
  percent,
  name,
  color,
  showCenter = false,
  reversed = false,
  showTooltip = true,
}) => {
  const [isTooltipShown, setIsTooltipShown] = useState<boolean>(false);
  const [isTooltipHovered, setIsTooltipHovered] = useState<boolean>(false);

  return (
    <div
      className={classNames()}
      onMouseEnter={() => setIsTooltipShown(true)}
      onMouseLeave={() => {
        setIsTooltipShown(false);
        setIsTooltipHovered(false);
      }}
      data-testid='bar'
    >
      <div
        className={classNames('progress', {
          ...transformObjectKeysToKebabCase({
            color: getColor(percent, color),
            reversed,
            showCenter,
          }),
        })}
        style={{ width: percent + '%' }}
        data-testid='bar__progress'
      ></div>
      {showTooltip && (
        <Tooltip
          children={
            name ? (
              <>
                <strong>{name}:&nbsp;</strong>
                {metricValue}
              </>
            ) : (
              metricValue
            )
          }
          onMouseEnter={() => setIsTooltipHovered(true)}
          onMouseLeave={() => setIsTooltipHovered(false)}
          isShown={isTooltipShown || isTooltipHovered}
          reversed={reversed}
        />
      )}
    </div>
  );
};

/**
 * Returns a class that represents the color of the bar.
 */
const getColor = (value: number, color?: string) => {
  return color ? (color === 'purple' ? 'purple' : 'orange') : getColorByPercent(value);
};
