import {
  IPlayer,
  ISelectedPlayerItem,
  ISelectedPlayersRecord,
  ISimilarPlayerNameRecord,
} from '../types';

export const isAtLeastOnePlayerOn = (
  selectedPlayerItems: ISelectedPlayersRecord,
  disabledOnOff: boolean,
): boolean =>
  Object.values(selectedPlayerItems).some(item =>
    disabledOnOff ? item.selectedPlayer : item.isActive,
  );

export const isSelectedPlayerSelected = (
  selectedPlayer: ISelectedPlayerItem | undefined,
  disabledOnOff: boolean,
): boolean => {
  if (
    !selectedPlayer ||
    selectedPlayer.selectedPlayer === undefined ||
    selectedPlayer.isActive === undefined
  ) {
    return false;
  }

  return disabledOnOff ? !!selectedPlayer.selectedPlayer : selectedPlayer.isActive;
};

export const isPlayerSelected = (
  selectedPlayerItems: ISelectedPlayersRecord,
  playerId: string | undefined,
  disabledOnOff: boolean,
): boolean => {
  const selectedPlayer = Object.values(selectedPlayerItems).find(
    item => item.selectedPlayer?.value === playerId,
  );
  return isSelectedPlayerSelected(selectedPlayer, disabledOnOff);
};

export const differenciateEqualNames = (players: IPlayer[]): ISimilarPlayerNameRecord =>
  players.reduce<ISimilarPlayerNameRecord>((acc, player) => {
    const equalShortenedNames = players.filter(
      secondaryPlayer =>
        secondaryPlayer.surname === player.surname &&
        secondaryPlayer.name[0] === player.name[0] &&
        secondaryPlayer.id !== player.id,
    );

    if (equalShortenedNames.length === 0) return acc;

    equalShortenedNames.forEach(equalPlayer => {
      if (!acc[equalPlayer.id]) {
        acc[equalPlayer.id] =
          equalPlayer.surname + ' ' + decideDifferentName(equalPlayer.name, player.name);
      }

      if (!acc[player.id]) {
        acc[player.id] = player.surname + ' ' + decideDifferentName(player.name, equalPlayer.name);
      }
    });

    return acc;
  }, {});

const decideDifferentName = (originalName: string, equalName: string): string => {
  let letter = '';
  let index = 0;
  const shorterName = originalName.length < equalName.length ? originalName : equalName;

  for (let i = 0; i < shorterName.length; i++) {
    letter += originalName[i];
    if (originalName[i] !== equalName[i]) {
      index = i;
      break;
    }
  }

  if (index < 3) return letter + '.';
  return originalName;
};
