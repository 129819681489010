import { useState } from 'react';

import { useResizeObserver } from './useResizeObserver';

export const useTableCenterPartWidth = () => {
  const [centerPartWidth, setCenterPartWidth] = useState<number | undefined>(undefined);

  const handleCenterTablePartSizeChange = (target: HTMLDivElement) => {
    setCenterPartWidth(target.children[0].getBoundingClientRect().width);
  };

  const centerTablePartRef = useResizeObserver(handleCenterTablePartSizeChange);
  return { centerPartWidth, centerTablePartRef };
};
