import { ChangeEvent, FC, InputHTMLAttributes, useEffect, useState } from 'react';

import { useClickOutside } from '../../hooks';
import { CloseBlueIcon } from '../../icons';
import { ISelectOption } from '../../types';
import { createClassNames } from '../../utils';
import { Input } from '../Input';
import { SelectOptionItem } from '../SelectOptionItem';
import { IInputSharedProps } from '../types';
import './SearchInput.styles.scss';

export interface ISearchInputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    IInputSharedProps {
  /** Available select options */
  options: ISelectOption[];
  /** Selected option. */
  selected?: ISelectOption;
  /** Function called when other option is picked. */
  onChange: (...args: any[]) => void;
}

const classNames = createClassNames('search-input');

/**
 * SearchInput component for filtering and selecting single value.
 */
export const SearchInput: FC<ISearchInputProps> = ({
  options,
  selected,
  placeholder,
  disabled,
  onChange,
  ...props
}) => {
  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState<boolean>(false);

  const ref = useClickOutside<HTMLInputElement>(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    setSearchText(selected?.label || '');
  }, [selected]);

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleOnBlurSearchInput = () => {
    const option = options.find(option => option.label === searchText);
    if (option) {
      onChange(option);
    }
  };

  const handleClearSearchInput = () => {
    setSearchText('');
    onChange('');
  };

  const filteredOptions = options.filter(item => {
    return item.label.toLowerCase().includes(searchText.toLowerCase());
  });

  return (
    <div className={classNames()}>
      <div className={classNames('input-container', { disabled })}>
        <Input
          ref={ref}
          {...props}
          disabled={disabled}
          value={searchText}
          onChange={handleSearchInputChange}
          onMouseDown={() => setOpen(true)}
          onBlur={handleOnBlurSearchInput}
          data-testid='search-input__input'
          placeholder={placeholder}
        />
        {searchText !== '' ? (
          <CloseBlueIcon
            className={classNames('close-icon')}
            onMouseDown={handleClearSearchInput}
            data-testid='search-input__clear-icon'
          />
        ) : null}
      </div>
      <div
        className={classNames('options', {
          visible: open && filteredOptions.length > 0 && !disabled,
          scrollable: filteredOptions.length > 15,
        })}
        data-testid='search-input__options'
      >
        {filteredOptions.map(option => (
          <SelectOptionItem
            key={
              option.additionalValue ? `${option.value}_${option.additionalValue}` : option.value
            }
            onChange={() => onChange(option)}
            {...{ option }}
          />
        ))}
      </div>
    </div>
  );
};
