import { ISelectOption } from '../types';

export const normalizeSelectWithAllValues = (
  selected: ISelectOption | undefined,
): string | undefined => {
  if (!selected || selected.value === 'all') {
    return undefined;
  }

  return selected.value;
};

export const normalizeNumberValue = (value: number | string | undefined): number | undefined => {
  if (!value || value === '' || isNaN(Number(value))) {
    return undefined;
  }

  return Number(value);
};

export const normalizeTimeValues = (timeValue: string | number | undefined): number | undefined => {
  const timeValueVerified = normalizeNumberValue(timeValue);
  if (timeValueVerified) {
    return timeValueVerified * 60;
  }

  return undefined;
};

export const normalizeOpponentTeams = (opponents: ISelectOption[]): string[] | undefined =>
  opponents.length > 0 ? opponents.map(opponent => opponent.value) : undefined;
