import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { IShotFlowGraphConfig, IShotFlowGraphPlayerInfo } from '../../../types';
import { createClassNames } from '../../../utils';
import { PlayerCircle } from '../PlayerCircle';
import { ShotFlowGraphAxis } from '../ShotFlowGraphAxis';
import { ShotFlowGraphTooltip } from '../ShotFlowGraphTooltip';
import './ShotFlowGraph.styles.scss';

const classNames = createClassNames('shot-flow-graph');

/**
 * ShotFlowGraph UI component for user interaction.
 */
export const ShotFlowGraph: FC<IShotFlowGraphConfig> = ({ items, teamShortcut, min, max }) => {
  const [selectedPlayer, setSelectedPlayer] = useState<IShotFlowGraphPlayerInfo>();
  const { t } = useTranslation();

  return (
    <div className={classNames()} data-testid='shot-flow-graph'>
      <span className={classNames('title')}>{teamShortcut}</span>
      <div className={classNames('container')}>
        {items.map((item, index) => (
          <PlayerCircle
            {...item}
            key={index}
            onMouseEnter={playerInfo => setSelectedPlayer(playerInfo)}
            onMouseLeave={() => setSelectedPlayer(undefined)}
          />
        ))}
        <div className={classNames('container__box', { x: 1 })}>{t(ITranslationKeys.negative)}</div>
        <div className={classNames('container__box', { x: 2 })}>30%</div>
        <div className={classNames('container__box', { x: 3 })}>40%</div>
        <div className={classNames('container__box', { x: 4 })}>50%</div>
        <div className={classNames('container__box', { y: 1 })}>{t(ITranslationKeys.positive)}</div>
        <div className={classNames('container__box', { y: 2 })}>60%</div>
        <div className={classNames('container__box', { y: 3 })}>70%</div>
        {selectedPlayer && <ShotFlowGraphTooltip {...selectedPlayer} />}
      </div>
      <ShotFlowGraphAxis min={min} max={max} isYAxis={false} />
      <ShotFlowGraphAxis min={min} max={max} isYAxis={true} />
    </div>
  );
};
