import { useCallback, useState } from 'react';

import { useResizeObserver } from '../../../hooks';
import { ITableResizeValues } from '../../../types';

export const useTableResize = (
  leftPartInitialWidth: number = 350,
  rightPartInitialWidth: number = 126,
): ITableResizeValues => {
  const [leftPartWidth, setLeftPartWidth] = useState<number>(leftPartInitialWidth);
  const [rightPartWidth, setRightPartWidth] = useState<number>(rightPartInitialWidth);

  const onResizeLeft = useCallback((target: HTMLTableElement) => {
    setLeftPartWidth(target.getBoundingClientRect().width);
  }, []);

  const onResizeRight = useCallback((target: HTMLTableElement) => {
    setRightPartWidth(target.getBoundingClientRect().width);
  }, []);

  const leftPartRef = useResizeObserver(onResizeLeft);
  const rightPartRef = useResizeObserver(onResizeRight);

  return {
    leftPartWidth,
    rightPartWidth,
    leftPartRef,
    rightPartRef,
  };
};
