import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ITranslationKeys } from '../../i18n/types';
import { INavigationContentKeys, INavigationPageKeys, IUrlFilterParams } from '../../types';
import {
  combineSearchAndFilterParams,
  createClassNames,
  normalizeUrlWithFilterParams,
} from '../../utils';
import { Tooltip } from '../Tooltip';
import './EyeDropdown.styles.scss';

export interface IEyeDropdownTooltipItem {
  /** Translation key to be translated as label */
  pageTranslationLabel: ITranslationKeys;
  /** Navigation key to be navigated to */
  navigationPageKey: INavigationPageKeys;
  /** Navigation tab key to be navigated to */
  navigationTabKey?: INavigationContentKeys;
  /** Filter params to set on tooltip item click. */
  filterParams?: IUrlFilterParams[];
}

export interface IEyeDropdownProps {
  /** List of items to be displayed in the dropdown */
  eyeDropdownTooltipItems: IEyeDropdownTooltipItem[];
}

const classNames = createClassNames('eye-dropdown');

/** UI component to display available actions. */
export const EyeDropdown: FC<IEyeDropdownProps> = ({ eyeDropdownTooltipItems }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const onItemClick = (item: IEyeDropdownTooltipItem) => {
    if (!item.navigationTabKey) return;

    const urlParamsToOpen = combineSearchAndFilterParams(searchParams, item.filterParams);
    const pagesUrl = `/${item.navigationPageKey}/${item.navigationTabKey}`;
    const urlToOpen = normalizeUrlWithFilterParams(pagesUrl, urlParamsToOpen);
    window.open(urlToOpen, '_blank');
  };

  return (
    <div
      className={classNames()}
      onMouseEnter={() => setIsTooltipShown(true)}
      onMouseLeave={() => setIsTooltipShown(false)}
    >
      <Tooltip isShown={isTooltipShown} arrowDirection='left' centerYAxis effect='fade'>
        <ul>
          {eyeDropdownTooltipItems?.map((item, index) => (
            <li key={index} onClick={() => onItemClick(item)}>
              {t(item.pageTranslationLabel)}
            </li>
          ))}
        </ul>
      </Tooltip>
    </div>
  );
};
