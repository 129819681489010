import { createSlice } from '@reduxjs/toolkit';

import { ISimilarPlayersState } from '../../types';
import { getSimilarPlayers } from './similarPlayersActions';

const initialState: ISimilarPlayersState = {
  referencedValues: {},
  referencedPlayer: undefined,
  byId: {},
  allIds: [],
  isLoading: false,
  error: undefined,
};

export const similarPlayersSlice = createSlice({
  name: 'similarPlayers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSimilarPlayers.pending, state => {
        state.referencedValues = {};
        state.referencedPlayer = undefined;
        state.byId = {};
        state.allIds = [];
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getSimilarPlayers.fulfilled, (state, action) => {
        state.referencedValues = action.payload.referencedValues;
        state.referencedPlayer = action.payload.referencedPlayer;
        action.payload.topSimilarities.forEach(({ id, ...player }) => {
          if (!state.byId[id]) {
            state.byId[id] = {
              id,
              teamStats: {
                [player.teamId]: player,
              },
            };
            state.allIds.push(id);
          } else {
            state.byId[id].teamStats[player.teamId] = player;
          }
        });
        state.isLoading = false;
      })
      .addCase(getSimilarPlayers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const similarPlayersReducer = similarPlayersSlice.reducer;
