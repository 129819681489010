import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { CloseBlueIcon } from '../../../icons';
import { createClassNames } from '../../../utils';
import './VideocoachHeader.styles.scss';

interface IVideocoachHeader {
  /** Function that handles modal close */
  onClose: () => void;
}

const classNames = createClassNames('videocoach-header');

/** VideocoachHeader UI component. */
export const VideocoachHeader: FC<IVideocoachHeader> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      <h1 className={classNames('title')}>{t(ITranslationKeys.videocoachNotes)}</h1>
      <div className={classNames('action')} onClick={onClose}>
        <CloseBlueIcon />
        <p>{t(ITranslationKeys.close)}</p>
      </div>
    </div>
  );
};
