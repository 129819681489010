import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { ITranslationKeys } from '../../../i18n/types';
import { axiosInstance } from '../../../services/axiosInstance';
import { IDefinedDatasets } from '../../../types';
import { defaultCatchErrorCallback } from '../../../utils';

export const getDefinedDatasets = createAsyncThunk<IDefinedDatasets>(
  'settings/definedDatasets/getDefinedDatasets',
  async () => {
    const definedDatasets: IDefinedDatasets = await axiosInstance
      .get(`/setting/template`)
      .then(response => response.data)
      .catch(defaultCatchErrorCallback);

    return definedDatasets;
  },
);

export const putDefinedDatasets = createAsyncThunk<IDefinedDatasets | undefined, IDefinedDatasets>(
  'settings/definedDatasets/putDefinedDatasets',
  async newDefinedDatasets => {
    const status = await axiosInstance
      .put(`/setting/template`, newDefinedDatasets)
      .then(response => response.status)
      .catch((error: AxiosError) => {
        const errorMessage = error.message ?? ITranslationKeys.saveDataFailedMessage;
        throw new Error(errorMessage);
      });

    return status === 204 ? newDefinedDatasets : undefined;
  },
);
