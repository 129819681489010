import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  filteredGamesTablesFormationsSelector,
  selectGames,
  selectMainFilter,
  selectPlayers,
} from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IGame,
  IGamesTablesFormations,
  IGamesTablesFormationsTableData,
  IMetricWithDatasetMetric,
  IPlayer,
  IPlayerRecord,
  ISelectOption,
  ISimilarPlayerNameRecord,
} from '../../../../types';
import {
  filteredFormationsOfGameTablesByTeamId,
  getMetricsWithValue,
  getPlayersTwoRows,
} from '../../../../utils';

const getTableData = (
  formations: IGamesTablesFormations[],
  playerRecord: IPlayerRecord,
  metrics: IMetricWithDatasetMetric[],
  similarPlayerNames: ISimilarPlayerNameRecord,
  formationType?: ISelectOption,
  selectedGame?: IGame | null,
) => {
  const tableData = formations.reduce<IGamesTablesFormationsTableData[]>((acc, formation) => {
    const players = formation.playerIds.map<IPlayer>(playerId => playerRecord[playerId]);
    const { firstRow, secondRow } = getPlayersTwoRows(players, similarPlayerNames, formationType);
    const stats = getMetricsWithValue(metrics, formation.stats);

    acc.push({
      entityId: formation.teamId,
      teamId: formation.teamId,
      players: {
        firstRow,
        secondRow,
      },
      gp: formation.stats.gp,
      toi: formation.stats.toi,
      stats,
    });

    return acc;
  }, []);

  const homeTeamId = selectedGame ? selectedGame.homeTeamId : false;
  const dataHome = tableData
    .filter(data => data.teamId === homeTeamId)
    .sort((a, b) => b.toi - a.toi);
  const dataAway = tableData
    .filter(data => data.teamId !== homeTeamId)
    .sort((a, b) => b.toi - a.toi);

  return !homeTeamId ? tableData : dataHome.concat(dataAway);
};

export const useDataForTable = (selectedFilterTeamId?: string) => {
  const { formationType, selectedGame } = useAppSelector(selectMainFilter);
  const { gamesTables } = useAppSelector(selectGames);
  const { formationsById } = gamesTables;
  const { byId, similarPlayerNames } = useAppSelector(selectPlayers);
  const filteredGamesTablesData = useAppSelector(filteredGamesTablesFormationsSelector);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const playersWithTeamIdAndStatsFiltered = useMemo(
    () =>
      filteredFormationsOfGameTablesByTeamId(
        filteredGamesTablesData.filter(formation => formation),
        selectedFilterTeamId,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formationsById, selectedFilterTeamId],
  );

  const data: IGamesTablesFormationsTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();

    if (filteredMetrics) {
      return getTableData(
        playersWithTeamIdAndStatsFiltered,
        byId,
        filteredMetrics,
        similarPlayerNames,
        formationType,
        selectedGame,
      );
    }

    return [];
  }, [
    playersWithTeamIdAndStatsFiltered,
    byId,
    similarPlayerNames,
    formationType,
    selectedGame,
    getFilteredMetrics,
  ]);

  return data;
};
