import { FC } from 'react';

import { CzechFlagIcon, EnglishFlagIcon } from '../../../icons';
import { ILanguageType } from '../../../types';
import { createClassNames } from '../../../utils';
import './LanguageController.styles.scss';

interface ILanguageControllerProps {
  activeLanguage: keyof ILanguageType<string>;
  onClick: () => void;
}

const classNames = createClassNames('language-controller');

export const LanguageController: FC<ILanguageControllerProps> = ({ activeLanguage, onClick }) => (
  <div className={classNames()} onClick={onClick}>
    {activeLanguage === 'cz' ? <CzechFlagIcon /> : <EnglishFlagIcon />}
  </div>
);
