import { FC } from 'react';

import { IPlayerExpectedGoals } from '../../../types';
import { createClassNames } from '../../../utils';
import { Bar } from '../../Bar';
import './GameExpectedGoalsRow.styles.scss';

interface IGameExpectedGoalsRowProps {
  /**
   * Player expected goal and name data.
   */
  player: IPlayerExpectedGoals;
  /**
   * Maximum xG of all displayed players.
   */
  calculateFrom: number;
  /**
   * Decides which color is the bar filled with.
   */
  team: 'home' | 'away';
  /**
   * If true, elements are in reversed order.
   */
  reversed?: boolean;
}

const classNames = createClassNames('game-expected-goals-row');

/**
 * GameExpectedGoalsRow UI component.
 */
export const GameExpectedGoalsRow: FC<IGameExpectedGoalsRowProps> = ({
  player,
  calculateFrom,
  team,
  reversed = false,
}) => {
  const getSizePercent = () => (player.value / calculateFrom) * 100;

  return (
    <div className={classNames({ reversed })}>
      <p className={classNames('name', { reversed })}>
        {player.firstName} <span>{player.lastName}</span>
      </p>
      <Bar
        metricValue={player.value}
        percent={getSizePercent()}
        color={team === 'home' ? 'purple' : 'orange'}
        reversed={!reversed}
        showTooltip={false}
      />
      <p className={classNames('value')}>{player.value.toFixed(2)}</p>
    </div>
  );
};
