import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { IHelpTextItem } from '../../../types';
import { createClassNames } from '../../../utils';
import { TipsItem } from '../TipsItem';
import './TipsContent.styles.scss';

export interface ITipsContentProps {
  items: IHelpTextItem[];
}

const classNames = createClassNames('tips-content');

export const TipsContent: FC<ITipsContentProps> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      <h4>{t(ITranslationKeys.chosenTips)}</h4>
      {items.map((item, index) => (
        <TipsItem key={index} {...item} />
      ))}
    </div>
  );
};
