import { useFormContext } from 'react-hook-form';

import { FilterWrapper, WidgetItemList } from '../../components';
import { useLocationPaths } from '../../hooks';
import { IMainFilterForm } from '../../types';
import { ContentRoutesContainer } from '../ContentRoutesContainer';
import { usePageConfig } from './contents';

export const VideomapsPage = () => {
  const { activePage, activeTab, pathSegments } = useLocationPaths();
  const pageConfig = usePageConfig();
  const { formState } = useFormContext<IMainFilterForm>();

  return (
    <div className='grow-and-flex-column' data-testid='videomaps-page'>
      <form noValidate>
        <FilterWrapper
          isExtended={pathSegments.length > 1}
          errors={formState.errors}
          {...{ activeTab, pageConfig }}
        />
        {pathSegments.length === 1 && (
          <WidgetItemList config={pageConfig.tabsConfig} errors={formState.errors} />
        )}
      </form>
      <ContentRoutesContainer config={pageConfig.tabsConfig} activePage={activePage} />
    </div>
  );
};
