import { format } from 'date-fns';
import { cs } from 'date-fns/locale';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { closeVideoCenter, selectTeams, setShowBugReport } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { BugIcon, CloseBlueIcon, DownloadIcon } from '../../../icons';
import { INavigationPageKeys, IVideo } from '../../../types';
import { createClassNames, secondsToMinutesAndSeconds } from '../../../utils';
import './VideoCenterHeader.styles.scss';

interface IVideoCenterHeader {
  /** Category of selected video. */
  categoryKey: string;
  /** Informations about selected video. */
  video?: IVideo;
  /** Function that handles modal close */
  onClose: () => void;
}

const classNames = createClassNames('video-center-header');

/** VideoCenterHeader UI component. */
export const VideoCenterHeader: FC<IVideoCenterHeader> = ({ categoryKey, video, onClose }) => {
  const { t } = useTranslation();
  const teams = useAppSelector(selectTeams);
  const dispatch = useAppDispatch();

  const handleDownloadManagement = () => {
    dispatch(closeVideoCenter());
    const urlToOpen = `/${INavigationPageKeys.accountManagement}`;
    window.open(urlToOpen, '_blank');
  };

  const handleBugReport = (video: IVideo) => {
    const homeTeam = video.homeTeamId ? teams.byId[video.homeTeamId].shortcut : undefined;
    const awayTeam = video.awayTeamId ? teams.byId[video.awayTeamId].shortcut : undefined;

    dispatch(
      setShowBugReport({
        show: true,
        type: 'video',
        videoInfo: {
          eventTime: secondsToMinutesAndSeconds(video.time),
          eventType: t(categoryKey).toString(),
          match: `${homeTeam} - ${awayTeam} ${format(new Date(video.matchDate), 'P', {
            locale: cs,
          })}`,
        },
      }),
    );
  };

  return (
    <div className={classNames()}>
      <h1 className={classNames('title')}>{t(ITranslationKeys.videoCenter)}</h1>
      <div className={classNames('actions-container')}>
        {video && (
          <div className={classNames('action')} onClick={() => handleBugReport(video)}>
            <BugIcon />
            <p>{t(ITranslationKeys.reportBug)}</p>
          </div>
        )}
        <div className={classNames('action')} onClick={handleDownloadManagement}>
          <DownloadIcon />
          <p>{t(ITranslationKeys.downloadsManagement)}</p>
        </div>
        <div className={classNames('action')} onClick={onClose}>
          <CloseBlueIcon />
          <p>{t(ITranslationKeys.close)}</p>
        </div>
      </div>
    </div>
  );
};
