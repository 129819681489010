import { PaginationState } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { CmsUserFilter, Loading, TcmsButton, TcmsTable, UsersModal } from '../../../../components';
import {
  DEFAULT_CMS_USER_FILTER_FORM_VALUES,
  DEFAULT_TCMS_TABLE_PAGE_ITEMS_COUNT,
  mediaOptions,
} from '../../../../constants';
import { getIdentities, selectIdentity, selectTeams } from '../../../../features';
import { useHandleOnSubmitWithValues } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { AddUserIcon, DownloadUsersIcon } from '../../../../icons';
import { ICmsUserFilterForm, IExportRows, IIdentitiesTableData } from '../../../../types';
import {
  createClassNames,
  filterIdentitiesByFilter,
  handleExportToXLSX,
  teamOrMediaIdByRole,
} from '../../../../utils';
import './UserManagementPage.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';

const classNames = createClassNames('user-management-page');

export const UserManagementPage = () => {
  const { identities, isLoading, hasBeenIdentitiesLoaded } = useAppSelector(selectIdentity);
  const teams = useAppSelector(selectTeams);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [modalState, setModalState] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_TCMS_TABLE_PAGE_ITEMS_COUNT,
  });

  const methods = useForm<ICmsUserFilterForm>({
    defaultValues: DEFAULT_CMS_USER_FILTER_FORM_VALUES,
    mode: 'onBlur',
  });

  const { submitValues, handleOnSubmit } = useHandleOnSubmitWithValues(
    methods,
    DEFAULT_CMS_USER_FILTER_FORM_VALUES,
  );

  const filteredIdentities = useMemo(
    () => filterIdentitiesByFilter(identities, submitValues, teams.byId),
    [identities, submitValues, teams.byId],
  );

  const handleOpenAddUser = () => {
    setModalState(true);
  };

  const handleOpenChangePassword = (userId: string) => {
    setUserId(userId);
    setModalState(true);
  };

  const handleClose = () => {
    setModalState(false);
    setUserId('');
  };

  useEffect(() => {
    if (!hasBeenIdentitiesLoaded) {
      dispatch(getIdentities());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { columns, initialSorting } = useColumnsConfig(submitValues, handleOpenChangePassword);

  /* Export dat */
  const commonHeader = [
    'ID',
    t(ITranslationKeys.user),
    t(ITranslationKeys.role),
    t(ITranslationKeys.teamMedia),
    t(ITranslationKeys.activeUser),
  ];
  const tableRowCallback = (row: IIdentitiesTableData): IExportRows => ({
    id: row.id,
    username: row.username,
    role: row.role,
    teamOrMedia:
      teamOrMediaIdByRole(row.role, row.teamId, row.media, teams.byId, mediaOptions) ??
      'Nevyplněno',
    disabled: row.disabled ? 'Neaktivní' : 'Aktivní',
  });

  return (
    <div className={classNames()}>
      <div className={classNames('toolbar')}>
        <div className={classNames('toolbar__filters')}>
          <FormProvider {...methods}>
            <CmsUserFilter onSubmit={handleOnSubmit} />
          </FormProvider>
        </div>
        <div className={classNames('toolbar__controls')}>
          <TcmsButton
            color='blue'
            variant='text'
            label={ITranslationKeys.addUser}
            iconComponent={<AddUserIcon />}
            iconPosition='left'
            disabled={isLoading}
            onClick={handleOpenAddUser}
          />
          <TcmsButton
            color='blue'
            variant='text'
            label={ITranslationKeys.downloadUsersList}
            iconComponent={<DownloadUsersIcon />}
            iconPosition='left'
            disabled={isLoading || filteredIdentities.length === 0}
            onClick={() =>
              handleExportToXLSX(
                t(ITranslationKeys.appUsers),
                commonHeader,
                tableRowCallback,
                filteredIdentities,
              )
            }
          />
        </div>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={classNames('content')}>
          {filteredIdentities.length === 0 ? (
            <div className='content-info-box'>{t(ITranslationKeys.noDataForSelected)}</div>
          ) : (
            <TcmsTable
              {...{
                columns,
                initialSorting,
                pagination,
                setPagination,
              }}
              data={filteredIdentities}
              disableCellLine
              paginationConfig={{
                itemsOnPage: DEFAULT_TCMS_TABLE_PAGE_ITEMS_COUNT,
                displayTableBottomLine: true,
              }}
              hasTransparentBackground
            />
          )}
        </div>
      )}
      <UsersModal open={modalState} onClose={handleClose} userId={userId} />
    </div>
  );
};
