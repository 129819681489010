import { FC } from 'react';

import { IShotFlowGraphPlayerInfo } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './ShotFlowGraphTooltip.styles.scss';

const classNames = createClassNames('shot-flow-graph-tooltip');

/**
 * Tooltip component for displaying additional information in the ShotFlowGraph component.
 */
export const ShotFlowGraphTooltip: FC<IShotFlowGraphPlayerInfo> = ({
  name,
  toi,
  cf_percent,
  cf,
  ca,
}) => {
  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          visible: toi !== undefined,
        }),
      })}
      data-testid='shot-flow-graph-tooltip'
    >
      <span className={classNames('name')}>
        <strong>{name}</strong>
      </span>
      <span className={classNames('toi')}>
        <strong>TOI = </strong>
        {toi}
      </span>
      <span className={classNames('toi')}>
        <strong>CF% = </strong>
        {cf_percent}%
      </span>
      <span className={classNames('toi')}>
        <strong>CF = </strong>
        {cf}
      </span>
      <span className={classNames('toi')}>
        <strong>CA = </strong>
        {ca}
      </span>
    </div>
  );
};
