import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { DeleteCircleIcon, DownloadCircleIcon } from '../../../icons';
import { createClassNames } from '../../../utils';
import './DownloadManagementButton.styles.scss';

interface IDownloadManagementButtonProps {
  /**
   * Label of the button.
   */
  label: ITranslationKeys;
  /**
   * Type decides background color & icon on "multiple" buttons
   */
  type: 'download' | 'delete';
  /**
   * If true, button is rendered with icon.
   */
  multiple?: boolean;
  /**
   * On click function.
   */
  onClick?: () => void;
}

const classNames = createClassNames('download-management-button');

/**
 * DownloadManagementButton UI component.
 */
export const DownloadManagementButton: FC<IDownloadManagementButtonProps> = ({
  label,
  type,
  multiple,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames({ type, multiple })} onClick={onClick}>
      {multiple && type === 'download' && <DownloadCircleIcon width={16} height={16} />}
      {multiple && type === 'delete' && <DeleteCircleIcon width={16} height={16} />}
      {t(label)}
    </div>
  );
};
