import { FC } from 'react';

import { IHelpTextItem } from '../../../types';
import { createClassNames } from '../../../utils';
import './FunctionsItem.styles.scss';

export interface IFunctionsItemProps {
  title: string;
  descriptions: Pick<IHelpTextItem, 'text'>[];
}

const classNames = createClassNames('functions-item');

export const FunctionsItem: FC<IFunctionsItemProps> = ({ title, descriptions }) => {
  return (
    <div className={classNames()}>
      <h4 className={classNames('title')}>{title}</h4>
      <div className={classNames('description')}>
        {descriptions.map((description, index) => (
          <span key={index} dangerouslySetInnerHTML={{ __html: description.text }}></span>
        ))}
      </div>
    </div>
  );
};
