import { createSelector } from '@reduxjs/toolkit';

import { METRIC_PARAMETERS_INFO } from '../../constants';
import {
  IMetricCategory,
  IMetricRowsRecord,
  IMetricsSectionsToDisplayRecord,
  INetZoneUnion,
  ISectionMetricsRecord,
  ISelectOption,
} from '../../types';
import {
  getMetricRowsOrder,
  getMetricsColumns,
  getMetricsInfoDataTypeIds,
  getMetricsSectionIds,
  getMetricsSubgroups,
} from '../../utils';
import { selectDataSettingsFilter } from '../selectors';

const createCommonPrefix = (option: ISelectOption): ISelectOption | undefined => {
  if (option.value !== 'all') {
    const metricInfo = METRIC_PARAMETERS_INFO[option.value];

    return {
      value: metricInfo.prefix,
      label: metricInfo.prefixLabel,
    };
  }

  return undefined;
};

const createNetZonePrefix = (netZone: INetZoneUnion | undefined): ISelectOption | undefined =>
  netZone ? { value: `${netZone}.`, label: `${netZone.toUpperCase()}.` } : undefined;

const createCompleteMetricsPrefix = (options: ISelectOption[]): ISelectOption | undefined => {
  if (options.length > 0) {
    return {
      value: options.map(item => item.value).join(''),
      label: options.map(item => item.label).join(''),
    };
  }
  return undefined;
};

export const metricsByGroupSelector = createSelector(
  [selectDataSettingsFilter, (_, metrics: IMetricCategory) => metrics],
  (dataSettingsFilterState, metrics) => {
    const { selectedGroup } = dataSettingsFilterState;

    const filteredMetrics = Object.values(metrics.byId).filter(metric =>
      Object.values(metric.metricGroups).some(group => group.id === selectedGroup?.value),
    );

    return filteredMetrics;
  },
);

export const metricsBySectionsSelector = createSelector([metricsByGroupSelector], metrics => {
  const allMetricsSectionsIds = getMetricsSectionIds(metrics);
  const metricsBySections = allMetricsSectionsIds.reduce<ISectionMetricsRecord>(
    (acc, sectionId) => {
      acc[sectionId] = {
        id: sectionId,
        metrics: metrics.filter(metric => metric.metricSectionId === sectionId),
      };

      return acc;
    },
    {},
  );

  return metricsBySections;
});

export const metricsSectionsToDisplaySelector = createSelector(
  [metricsBySectionsSelector],
  metricsBySection => {
    const metricsSectionToDisplay = Object.values(
      metricsBySection,
    ).reduce<IMetricsSectionsToDisplayRecord>((accSectionToDisplay, section) => {
      const rowIds = getMetricsInfoDataTypeIds(section.metrics);
      const columnMetricsSubgroups = getMetricsSubgroups(section.metrics);

      const rows = rowIds.reduce<IMetricRowsRecord>((accRows, rowInfoData) => {
        const metricsByRowInfoData = section.metrics.filter(
          metric => metric.metricInfo.data_type === rowInfoData,
        );

        const columns = getMetricsColumns(metricsByRowInfoData, columnMetricsSubgroups);

        accRows[rowInfoData] = {
          name: rowInfoData,
          columns,
          order: getMetricRowsOrder(rowInfoData),
        };

        return accRows;
      }, {});

      accSectionToDisplay[section.id] = {
        id: section.id,
        rows,
      };

      return accSectionToDisplay;
    }, {});

    return metricsSectionToDisplay;
  },
);

export const metricsByActionTypeSelector = createSelector(
  [selectDataSettingsFilter, metricsByGroupSelector],
  (dataSettingsFilterState, metrics) => {
    const { gameActionType, selectedGroup } = dataSettingsFilterState;

    if (gameActionType.value === 'all' || !selectedGroup) return metrics;

    return metrics.filter(metric => {
      const group = metric.metricGroups[selectedGroup.value];

      if (group) {
        return group.metricParameters.action_type;
      }

      return false;
    });
  },
);

export const metricsByShotTypeSelector = createSelector(
  [selectDataSettingsFilter, metricsByGroupSelector],
  (dataSettingsFilterState, metrics) => {
    const { shotType, selectedGroup } = dataSettingsFilterState;

    if (shotType.value === 'all' || !selectedGroup) return metrics;

    return metrics.filter(metric => {
      const group = metric.metricGroups[selectedGroup.value];

      if (group) {
        return group.metricParameters.shot_type;
      }

      return false;
    });
  },
);

export const metricsByShotDangerSelector = createSelector(
  [selectDataSettingsFilter, metricsByGroupSelector],
  (dataSettingsFilterState, metrics) => {
    const { shotDanger, selectedGroup } = dataSettingsFilterState;

    if (shotDanger.value === 'all' || !selectedGroup) return metrics;

    return metrics.filter(metric => {
      const group = metric.metricGroups[selectedGroup.value];

      if (group) {
        return group.metricParameters.shot_danger;
      }

      return false;
    });
  },
);

export const metricsByShotLocationSelector = createSelector(
  [selectDataSettingsFilter, metricsByGroupSelector],
  (dataSettingsFilterState, metrics) => {
    const { shotLocation, selectedGroup } = dataSettingsFilterState;

    if (shotLocation.value === 'all' || !selectedGroup) return metrics;

    return metrics.filter(metric => {
      const group = metric.metricGroups[selectedGroup.value];

      if (group) {
        return group.metricParameters.shot_location;
      }

      return false;
    });
  },
);

export const metricsByNetZoneSelector = createSelector(
  [selectDataSettingsFilter, metricsByGroupSelector],
  (dataSettingsFilterState, metrics) => {
    const { netZone, selectedGroup } = dataSettingsFilterState;

    if (!netZone || !selectedGroup) return metrics;

    return metrics.filter(metric => {
      const group = metric.metricGroups[selectedGroup.value];

      if (group) {
        return group.metricParameters.net_zones;
      }

      return false;
    });
  },
);

export const metricsByPuckGainTypeSelector = createSelector(
  [selectDataSettingsFilter, metricsByGroupSelector],
  (dataSettingsFilterState, metrics) => {
    const { puckGainType, selectedGroup } = dataSettingsFilterState;

    if (puckGainType.value === 'all' || !selectedGroup) return metrics;

    return metrics.filter(metric => {
      const group = metric.metricGroups[selectedGroup.value];

      if (group) {
        return group.metricParameters.puck_gain_type;
      }

      return false;
    });
  },
);

export const metricsByEntryTypeSelector = createSelector(
  [selectDataSettingsFilter, metricsByGroupSelector],
  (dataSettingsFilterState, metrics) => {
    const { entryType, selectedGroup } = dataSettingsFilterState;

    if (entryType.value === 'all' || !selectedGroup) return metrics;

    return metrics.filter(metric => {
      const group = metric.metricGroups[selectedGroup.value];

      if (group) {
        return group.metricParameters.entry_type;
      }

      return false;
    });
  },
);

export const metricsByExitTypeSelector = createSelector(
  [selectDataSettingsFilter, metricsByGroupSelector],
  (dataSettingsFilterState, metrics) => {
    const { exitType, selectedGroup } = dataSettingsFilterState;

    if (exitType.value === 'all' || !selectedGroup) return metrics;

    return metrics.filter(metric => {
      const group = metric.metricGroups[selectedGroup.value];

      if (group) {
        return group.metricParameters.exit_type;
      }

      return false;
    });
  },
);

export const metricsByEnterExitLocationSelector = createSelector(
  [selectDataSettingsFilter, metricsByGroupSelector],
  (dataSettingsFilterState, metrics) => {
    const { enterExitLocation, selectedGroup } = dataSettingsFilterState;

    if (enterExitLocation.value === 'all' || !selectedGroup) return metrics;

    return metrics.filter(metric => {
      const group = metric.metricGroups[selectedGroup.value];

      if (group) {
        return group.metricParameters.enter_exit_location;
      }

      return false;
    });
  },
);

export const metricsPrefixSelector = createSelector(
  [selectDataSettingsFilter],
  dataSettingsFilterState => {
    const {
      gameActionType,
      netZone,
      shotType,
      shotDanger,
      puckGainType,
      entryType,
      exitType,
      enterExitLocation,
      shotLocation,
    } = dataSettingsFilterState;

    const existingPrefixes: ISelectOption[] = [
      createCommonPrefix(gameActionType),
      createNetZonePrefix(netZone),
      createCommonPrefix(shotType),
      createCommonPrefix(shotDanger),
      createCommonPrefix(puckGainType),
      createCommonPrefix(entryType),
      createCommonPrefix(exitType),
      createCommonPrefix(enterExitLocation),
      createCommonPrefix(shotLocation),
    ].filter((item): item is ISelectOption => !!item);

    return createCompleteMetricsPrefix(existingPrefixes);
  },
);

export const metricsByParametersSelector = createSelector(
  [
    metricsByActionTypeSelector,
    metricsByNetZoneSelector,
    metricsByShotTypeSelector,
    metricsByShotDangerSelector,
    metricsByPuckGainTypeSelector,
    metricsByEntryTypeSelector,
    metricsByExitTypeSelector,
    metricsByEnterExitLocationSelector,
    metricsByShotLocationSelector,
    metricsPrefixSelector,
  ],
  (
    filteredByActionType,
    filteredByNetZone,
    filteredByShotType,
    filteredByShotDanger,
    filteredByPuckGainType,
    filteredByEntryType,
    filteredByExitType,
    filteredByEnterExitLocation,
    filteredByShotLocation,
    metricsPrefix,
  ) => {
    const filteredMetricsByParameters = filteredByActionType.filter(shot => {
      return (
        filteredByNetZone.includes(shot) &&
        filteredByShotType.includes(shot) &&
        filteredByShotDanger.includes(shot) &&
        filteredByPuckGainType.includes(shot) &&
        filteredByEntryType.includes(shot) &&
        filteredByExitType.includes(shot) &&
        filteredByEnterExitLocation.includes(shot) &&
        filteredByShotLocation.includes(shot)
      );
    });

    if (metricsPrefix) return filteredMetricsByParameters;

    return filteredMetricsByParameters.filter(metric => metric.metricInfo.default_data);
  },
);

export const dataSettingsFilterAllDataSelector = createSelector(
  [
    metricsByGroupSelector,
    metricsBySectionsSelector,
    metricsByParametersSelector,
    metricsSectionsToDisplaySelector,
    metricsPrefixSelector,
  ],
  (
    filteredByGroup,
    filteredBySections,
    filteredByParameters,
    filteredMetricsSectionsToDisplay,
    metricsPrefix,
  ) => {
    return {
      filteredByGroup,
      filteredBySections,
      filteredByParameters,
      filteredMetricsSectionsToDisplay,
      metricsPrefix,
    };
  },
);
