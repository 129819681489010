import { BaseEditor } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import { ITranslationKeys } from '../../i18n/types';
import { ISelectOption } from '../common.types';
import { IHelpTextItem } from '../settings';

// Definice typů pro CustomEditor & CustomElement
export type CustomEditor = BaseEditor &
  ReactEditor &
  HistoryEditor & {
    resetSelection: (newContentValue?: CustomElement[]) => void;
  };

export type ParagraphElement = {
  type: 'paragraph';
  children: CustomText[];
};

export type HeadingElement = {
  type: 'heading';
  level: number;
  children: CustomText[];
};

export type CustomElement = ParagraphElement | HeadingElement;

export type FormattedText = { text: string; bold?: true; italic?: true };

export type CustomText = FormattedText;

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

export interface IWysiwygToolbarButton {
  icon: JSX.Element;
  onClick: void;
}

export interface IHelpManagementItemEditation {
  index: number;
  title: string;
  content: Pick<IHelpTextItem, 'text'>[];
}

export const helpContentEditButtonOptions: ISelectOption[] = [
  {
    label: ITranslationKeys.editFunctions,
    value: 'functions',
  },
  {
    label: ITranslationKeys.editNews,
    value: 'news',
  },
  {
    label: ITranslationKeys.editTips,
    value: 'tips',
  },
  {
    label: ITranslationKeys.editOpeningText,
    value: 'main',
  },
];

export const helpManagementSectionKeys: ISelectOption[] = [
  {
    label: ITranslationKeys.players,
    value: 'players',
  },
  {
    label: ITranslationKeys.formations,
    value: 'formations',
  },
  {
    label: ITranslationKeys.videomaps,
    value: 'videomaps',
  },
  {
    label: ITranslationKeys.goalkeeper,
    value: 'goalkeepers',
  },
  {
    label: ITranslationKeys.games,
    value: 'games',
  },
  {
    label: ITranslationKeys.teams,
    value: 'teams',
  },
];

export const helpManagementSubsectionKeys: ISelectOption[] = [
  {
    label: ITranslationKeys.tabs,
    value: 'tabs',
  },
  {
    label: ITranslationKeys.gamelog,
    value: 'gamelog',
  },
  {
    label: ITranslationKeys.trend,
    value: 'trend',
  },
  {
    label: ITranslationKeys.playerCard,
    value: 'player-card',
  },
  {
    label: ITranslationKeys.comparePlayers,
    value: 'compare-players',
  },
  {
    label: ITranslationKeys.similarityPlayers,
    value: 'similarity-players',
  },
  {
    label: ITranslationKeys.dataTemplates,
    value: 'data-settings',
  },
  {
    label: ITranslationKeys.pairs,
    value: 'pairs',
  },
  {
    label: ITranslationKeys.formations,
    value: 'formations',
  },
  {
    label: ITranslationKeys.playerCombinations,
    value: 'player-combinations',
  },
  {
    label: ITranslationKeys.shots,
    value: 'shots',
  },
  {
    label: ITranslationKeys.heatmap,
    value: 'heatmap',
  },
  {
    label: ITranslationKeys.passes,
    value: 'passes',
  },
  {
    label: ITranslationKeys.faceoffs,
    value: 'faceoffs',
  },
  {
    label: ITranslationKeys.zoneEntries,
    value: 'entries',
  },
  {
    label: ITranslationKeys.zoneExits,
    value: 'exits',
  },
  {
    label: ITranslationKeys.shootouts,
    value: 'shootouts',
  },
  {
    label: ITranslationKeys.netZones,
    value: 'net-zones',
  },
  {
    label: ITranslationKeys.shotmap,
    value: 'shotmap',
  },
  {
    label: ITranslationKeys.goalkeeperCard,
    value: 'goalkeeper-card',
  },
  {
    label: ITranslationKeys.compareGoalkeepers,
    value: 'compare-coalkeepers',
  },
  {
    label: ITranslationKeys.graphicOverview,
    value: 'graphic-overview',
  },
  {
    label: ITranslationKeys.report,
    value: 'report',
  },
  {
    label: ITranslationKeys.players,
    value: 'players',
  },
  {
    label: ITranslationKeys.goalkeepers,
    value: 'goalkeepers',
  },
  {
    label: ITranslationKeys.headToHead,
    value: 'h2h',
  },
];
