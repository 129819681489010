import { createSlice } from '@reduxjs/toolkit';

import { IGamelogState } from '../../types';
import { getGamelog } from './gamelogActions';

const initialState: IGamelogState = {
  byId: {},
  allIds: [],
  isLoading: false,
  error: undefined,
  isLoadedFirstTime: undefined,
};

export const gamelogSlice = createSlice({
  name: 'gamelog',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getGamelog.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getGamelog.fulfilled, (state, action) => {
        state.allIds = [];
        state.byId = {};
        action.payload.forEach(gamelog => {
          if (!state.byId[gamelog.id]) {
            state.allIds.push(gamelog.id);
          }
          state.byId[gamelog.id] = gamelog;
        });
        state.isLoading = false;
        state.isLoadedFirstTime = true;
      })
      .addCase(getGamelog.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const gamelogReducer = gamelogSlice.reducer;
