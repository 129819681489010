import {
  ColumnDef,
  ColumnPinningState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  BasicColumnCell,
  BasicColumnHeader,
  DeviationColumnCell,
  DeviationColumnHeader,
  ImageColumn,
  PositionCircle,
  StickGripCircle,
  Sticker,
  SummaryPercentileColumnCell,
  TranslatedColumnHeader,
} from '../../../../components';
import { logosPaths } from '../../../../constants';
import { useFilteredMetricsForTable } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { IColumnsConfig, ISimilarPlayersTableData } from '../../../../types';
import {
  createDynamicStatsColumnsDefSimilarity,
  createIndexColumn,
  roundNumberTo2Decimals,
  secondsToMinutesAndSeconds,
} from '../../../../utils';

const columnHelper = createColumnHelper<ISimilarPlayersTableData>();

const columnPinning: ColumnPinningState = {
  left: ['index', 'teamId', 'player', 'position', 'stick', 'toi'],
  right: ['total', 'positiveDeviation'],
};

const initialSorting: SortingState = [
  {
    id: 'toi',
    desc: true,
  },
];

const fixedColumns: ColumnDef<ISimilarPlayersTableData, any>[] = [
  columnHelper.display({
    id: 'index',
    header: '',
    cell: props => createIndexColumn(props),
  }),
  columnHelper.accessor('teamId', {
    header: '',
    cell: props => <ImageColumn src={logosPaths[props.getValue()]} alt='team-logo' />,
  }),
  columnHelper.accessor('player', {
    header: '',
    cell: props => (
      <span style={{ display: 'flex' }}>
        <Sticker key={props.getValue().id} label={props.getValue().displayName} size='small' />
      </span>
    ),
  }),
  columnHelper.accessor('position', {
    header: 'P',
    cell: props => <PositionCircle position={props.getValue()} />,
  }),
  columnHelper.accessor('stick', {
    header: () => <TranslatedColumnHeader label={ITranslationKeys.stickLetter} />,
    cell: props => <StickGripCircle stick={props.getValue()} />,
  }),
  columnHelper.accessor('toi', {
    header: props => <BasicColumnHeader {...props}>TOI</BasicColumnHeader>,
    cell: props => (
      <BasicColumnCell {...props}>{secondsToMinutesAndSeconds(props.getValue())}</BasicColumnCell>
    ),
  }),
  columnHelper.accessor('total', {
    header: props => (
      <BasicColumnHeader {...props}>
        <TranslatedColumnHeader label={ITranslationKeys.total} />
      </BasicColumnHeader>
    ),
    cell: props => (
      <BasicColumnCell {...props}>
        <SummaryPercentileColumnCell
          value={roundNumberTo2Decimals(props.getValue())}
          fontWeightInherit
        />
      </BasicColumnCell>
    ),
  }),
  columnHelper.accessor('positiveDeviation', {
    header: props => (
      <BasicColumnHeader {...props}>
        <DeviationColumnHeader />
      </BasicColumnHeader>
    ),
    cell: props => (
      <BasicColumnCell {...props}>
        <DeviationColumnCell value={props.getValue()} />
      </BasicColumnCell>
    ),
  }),
];

export const useColumnsConfig = (
  centerPartWidth: number | undefined,
): IColumnsConfig<ISimilarPlayersTableData> => {
  const getFilteredMetrics = useFilteredMetricsForTable();

  const columns = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();
    const dynamicColumns = createDynamicStatsColumnsDefSimilarity(
      filteredMetrics,
      columnHelper,
      centerPartWidth,
    );

    return fixedColumns.concat(dynamicColumns);
  }, [centerPartWidth, getFilteredMetrics]);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};
