import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { selectTrend } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IMetricWithDatasetMetric,
  ITimePeriodPlayerStatsRecord,
  ITrendPlayerStatsTableData,
} from '../../../../types';
import { getMetricsWithValue } from '../../../../utils';

const getTableData = (
  tableTimePeriodPlayerStats: ITimePeriodPlayerStatsRecord,
  metrics: IMetricWithDatasetMetric[],
) => {
  const tableData = Object.values(tableTimePeriodPlayerStats).map<ITrendPlayerStatsTableData>(
    (item, index) => {
      const metricsWithValue = getMetricsWithValue(metrics, item.stats);

      return {
        entityId: index.toString(),
        rowIndex: index,
        toi: item.stats.toi,
        stats: metricsWithValue,
      };
    },
  );

  return tableData;
};

export const useDataForTable = () => {
  const { tableTimePeriodPlayerStats } = useAppSelector(selectTrend);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const data: ITrendPlayerStatsTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();

    if (filteredMetrics) {
      return getTableData(tableTimePeriodPlayerStats, filteredMetrics);
    }

    return [];
  }, [tableTimePeriodPlayerStats, getFilteredMetrics]);

  return data;
};
