import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { ILeftRight, IOnOffColors, IPlayerColors } from '../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './StickGripCircle.styles.scss';

export interface IStickGripCircleProps {
  /** Stick grip of the player. */
  stick: ILeftRight | undefined;
  /** Color variant of component. */
  color?: IPlayerColors | IOnOffColors;
  /** If true, component has white background. */
  hasWhiteBackground?: boolean;
}

const classNames = createClassNames('stick-grip-circle');

/**
 * UI component showing players position in the circle separating opponents by color.
 */
export const StickGripCircle: FC<IStickGripCircleProps> = ({
  stick,
  color,
  hasWhiteBackground,
}) => {
  const { t } = useTranslation();

  const render = () => {
    if (stick === undefined) return '?';

    return stick === 'left' ? t(ITranslationKeys.leftLetter) : t(ITranslationKeys.rightLetter);
  };

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          color,
          hasWhiteBackground,
        }),
      })}
      data-testid='stick-grip-circle'
    >
      {render()}
    </div>
  );
};
