import { combineReducers } from '@reduxjs/toolkit';

import { faceoffsFilterSlice } from './faceoffsFilter';
import { goalkeepersFilterSlice } from './goalkeepersFilter';
import { metricParamsFilterSlice } from './metricParamsFilter';
import { shootoutFilterSlice } from './shootoutFilter';
import { videomapsFilterSlice } from './videomapsFilter';
import { zonesFilterSlice } from './zonesFilter';

export * from './faceoffsFilter';
export * from './goalkeepersFilter';
export * from './metricParamsFilter';
export * from './shootoutFilter';
export * from './videomapsFilter';
export * from './zonesFilter';

export const filtersReducers = combineReducers({
  metricParamsFilter: metricParamsFilterSlice.reducer,
  videomapsFilter: videomapsFilterSlice.reducer,
  faceoffsFilter: faceoffsFilterSlice.reducer,
  goalkeepersFilter: goalkeepersFilterSlice.reducer,
  shootoutFilter: shootoutFilterSlice.reducer,
  zonesFilter: zonesFilterSlice.reducer,
});
