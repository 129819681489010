import { MutableRefObject } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NoData } from '../components';
import { ITranslationKeys } from '../i18n/types';
import { IMainFilterForm } from '../types';
import { isFormValid } from '../utils';

/**
 * Hook that returns method that decides if error info box should be displayed.
 * @returns shouldDisplayErrorInfoBox method
 */
export const useContentErrorInfoBox = () => {
  const { formState } = useFormContext<IMainFilterForm>();
  const { errors } = formState;

  const { t } = useTranslation();

  /**
   * Returns content for error info box or false if there is no error.
   * @param isInitialMountRef - Ref says if component is mounted first time for specific content.
   * @param dataLength - Length of data for specific content.
   */
  const shouldDisplayErrorInfoBox = (
    isInitialMountRef: MutableRefObject<boolean>,
    dataLength: number,
  ) => {
    if (isInitialMountRef.current) {
      if (!isFormValid(errors)) {
        return (
          <div className='content-info-box'>
            {Object.values(formState.errors).map((error, index) => (
              <div key={`${error.message}_${index}`}>
                {error.message ? t(error.message) : error.message}
              </div>
            ))}
          </div>
        );
      } else {
        return <div className='content-info-box'>{t(ITranslationKeys.youCanLoadData)}</div>;
      }
    }

    if (!isInitialMountRef.current && dataLength === 0) {
      return <NoData />;
    }

    return false;
  };

  return shouldDisplayErrorInfoBox;
};
