import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import downloadIconPath from '../../../assets/icons/icobutton-download.svg';
import { ITranslationKeys } from '../../../i18n/types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './PlaygroundIconWithTooltip.styles.scss';

export interface IPlaygroundIconWithTooltipProps {
  /**
   * Label for the tooltip.
   */
  label: ITranslationKeys;
  /**
   * Icon to be shown.
   */
  icon: ReactNode;
  /**
   * If true, the icon is rotated 180 deg.
   */
  rotateIcon?: boolean;
  /**
   * If true, the icon has cursor pointer.
   */
  isClickable?: boolean;
  /**
   * When true, the download icon is shown.
   */
  showDownloadIcon?: boolean;
  /**
   * Fired when the icon is clicked.
   */
  onIconClick?: () => void;
}

const classNames = createClassNames('playground-icon-with-tooltip');

export const PlaygroundIconWithTooltip: FC<IPlaygroundIconWithTooltipProps> = ({
  label,
  icon,
  rotateIcon = false,
  isClickable = false,
  showDownloadIcon = false,
  onIconClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()} data-testid='playground-icon-with-tooltip' onClick={onIconClick}>
      <div className={classNames('tooltip')}>
        {t(label)}
        {showDownloadIcon && <img src={downloadIconPath} alt='download-icon' />}
      </div>
      <div
        className={classNames('icon', {
          ...transformObjectKeysToKebabCase({ rotateIcon, isClickable }),
        })}
      >
        {icon}
      </div>
    </div>
  );
};
