import { IPlayer, IPlayerForTable, ISelectOption, ISimilarPlayerNameRecord } from '../types';
import { getPlayerShortName } from './common.utils';

export const mapPlayerToPlayerForTable = (
  player: IPlayer,
  similarPlayerNames: ISimilarPlayerNameRecord,
): IPlayerForTable => ({
  id: player.id,
  displayName: getPlayerShortName(player, similarPlayerNames),
});

export const getPlayersTwoRows = (
  players: IPlayer[],
  similarPlayerNames: ISimilarPlayerNameRecord,
  formationType?: ISelectOption,
) => {
  const shouldSplit =
    formationType?.value === 'all' ||
    formationType?.value === '5:5' ||
    formationType?.value === '5:4' ||
    formationType?.value === '4:5';

  if (
    !shouldSplit ||
    (formationType?.value === 'all' && players.length % 2 === 0 && players.length < 5)
  ) {
    return {
      firstRow: players.map<IPlayerForTable>(value =>
        mapPlayerToPlayerForTable(value, similarPlayerNames),
      ),
      secondRow: [],
    };
  }

  const attackers = players
    .filter(player => player.position === 'FO')
    .map<IPlayerForTable>(value => mapPlayerToPlayerForTable(value, similarPlayerNames));
  const defenders = players
    .filter(player => player.position === 'DE')
    .map<IPlayerForTable>(value => mapPlayerToPlayerForTable(value, similarPlayerNames));

  return {
    firstRow: attackers,
    secondRow: defenders,
  };
};
