import { IHelp, IHelpIds, IHelpItem } from '../../types';

export const findHelpContent = <T extends IHelpIds>(
  helpContent: Record<string, T>,
  pageName: string,
  tabName: string,
  open: boolean,
): T | undefined =>
  Object.values(helpContent).find(item => {
    const actualSubPageId = open ? 'data-settings' : tabName;

    return item.pageId === pageName && item.subPageId === actualSubPageId;
  });

export const findHelpItem = (
  help: IHelp,
  pageName: string,
  tabName: string,
  open: boolean,
): IHelpItem => ({
  main: findHelpContent(help.main, pageName, tabName, open),
  tips: findHelpContent(help.tips, pageName, tabName, open),
  functions: findHelpContent(help.functions, pageName, tabName, open),
  news: findHelpContent(help.news, pageName, tabName, open),
});
