import { Cell, flexRender } from '@tanstack/react-table';

import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './TableCell.styles.scss';

interface ITableCellProps<TData, TValue> {
  /** Cell definition. */
  cell: Cell<TData, TValue>;
  /** Variant of component. */
  variant?: 'default' | 'tcms';
  /** Bottom border of cell will be bold. */
  boldBorder?: boolean;
  /** Hides cell's bottom border, that visually divides rows. */
  disableBorder?: boolean;
  /** Custom height of cell. */
  height?: number;
}

const classNames = createClassNames('table-cell');

/**
 * Table cell component for table body (tbody).
 * @returns Styled `<td>` element
 */
export const TableCell = <TData, TValue>({
  cell,
  variant = 'default',
  boldBorder,
  disableBorder,
  height,
}: ITableCellProps<TData, TValue>) => (
  <td
    className={classNames({
      ...transformObjectKeysToKebabCase({
        variant,
        boldBorder,
        disableBorder,
      }),
    })}
    style={{ height }}
  >
    {flexRender(cell.column.columnDef.cell, cell.getContext())}
  </td>
);
