import { CSSProperties, FC, ReactNode } from 'react';

import { createClassNames } from '../../../utils';
import './CellBase.styles.scss';

interface ICellBaseProps {
  /** Wrapped element */
  children: ReactNode;
  /** Custom style for wrapper */
  style?: CSSProperties;
}

const classNames = createClassNames('cell-base');

/** Wrapper component adds most common table cell style. */
export const CellBase: FC<ICellBaseProps> = ({ children, style }) => (
  <div className={classNames()} style={style}>
    {children}
  </div>
);
