import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IBaseRequestBody, IBaseVideoShifts, IBaseVideoShiftsDTO } from '../../types';
import { defaultCatchErrorCallback } from '../../utils';
import { parseVideoShiftsData } from './parseVideoShifts';

interface IGetVideoShiftsParams {
  competitionsUuid: string;
  matchUuid: string;
  playerUuid: string;
  body?: IBaseRequestBody;
}

export const getVideoShifts = createAsyncThunk<IBaseVideoShifts, IGetVideoShiftsParams>(
  'videoShifts/getVideoShifts',
  async ({ competitionsUuid, matchUuid, playerUuid, body }) => {
    const videoShifts: IBaseVideoShifts = await axiosInstance
      .post<IBaseVideoShiftsDTO>(
        `/video/${competitionsUuid}/${matchUuid}/${playerUuid}`,
        body ?? {},
      )
      .then(response => parseVideoShiftsData(response.data, matchUuid, playerUuid))
      .catch(defaultCatchErrorCallback);

    return videoShifts;
  },
);
