import { useAppDispatch } from '../../../app/hooks';
import {
  shootoutEndResultGoalOption,
  shootoutEndTypeFeintOption,
  shootoutEndTypeShotOption,
} from '../../../constants';
import { setShootoutEndResult, setShootoutEndType } from '../../../features';
import { IMetricWithDatasetMetric } from '../../../types';

export const useSetShootoutsFilter = () => {
  const dispatch = useAppDispatch();

  const setShootoutsFilterSetter = (stat: IMetricWithDatasetMetric) => {
    const isParameter = stat.metric.id.includes('.');
    const [parameter, origin] = stat.metric.id.split('.');

    const mainMetric = isParameter ? origin : stat.metric.id;

    if (isParameter) {
      if (parameter === 'd') {
        dispatch(setShootoutEndType(shootoutEndTypeFeintOption));
      } else if (parameter === 's') {
        dispatch(setShootoutEndType(shootoutEndTypeShotOption));
      }
    }

    if (mainMetric.startsWith('gso')) {
      dispatch(setShootoutEndResult(shootoutEndResultGoalOption));
    }
  };

  return { setShootoutsFilterSetter };
};
