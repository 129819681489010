import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { ITranslationKeys } from '../../../i18n/types';
import { axiosInstance } from '../../../services/axiosInstance';
import { IMetrics } from '../../../types';
import { defaultCatchErrorCallback } from '../../../utils';

export const getMetrics = createAsyncThunk<IMetrics>('settings/metrics/getMetrics', async () => {
  const metrics: IMetrics = await axiosInstance
    .get(`/setting/metrics`)
    .then(response => response.data)
    .catch(defaultCatchErrorCallback);

  return metrics;
});

export const putMetrics = createAsyncThunk<IMetrics | undefined, IMetrics>(
  'settings/metrics/putMetrics',
  async newMetrics => {
    const status = await axiosInstance
      .put(`/setting/metrics`, newMetrics)
      .then(response => response.status)
      .catch((error: AxiosError) => {
        const errorMessage = error.message ?? ITranslationKeys.saveDataFailedMessage;
        throw new Error(errorMessage);
      });

    return status === 204 ? newMetrics : undefined;
  },
);
