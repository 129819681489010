import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';

import { useGetPlayersLabelByWowyType } from '../../../hooks';
import { IPlayerForTable, IWowyTableData } from '../../../types';
import { createClassNames, getStickerColorByWowyType } from '../../../utils';
import { Sticker } from '../../Sticker';
import './WowyPlayerColumn.styles.scss';

export interface IWowyPlayerColumnCellProps extends CellContext<IWowyTableData, IPlayerForTable> {}

const classNames = createClassNames('wowy-player-column');

export const WowyPlayerColumnCell: FC<IWowyPlayerColumnCellProps> = ({ row, getValue }) => {
  const player = getValue();
  const getPlayersLabelByWowyType = useGetPlayersLabelByWowyType();
  const label = getPlayersLabelByWowyType(player.displayName, row.original.type);
  const color = getStickerColorByWowyType(row.original.type);

  return (
    <span className={classNames()}>
      <Sticker label={label} color={color} />
    </span>
  );
};
