import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IBugReportState, IBugReportType, IBugReportVideoInfo } from '../../types';

interface ISetShowBugReportPayload {
  show: boolean;
  type?: IBugReportType;
  videoInfo?: IBugReportVideoInfo;
}

const initialState: IBugReportState = {
  showBugReportSidebar: false,
  type: 'normal',
  videoInfo: undefined,
};

export const bugReportSlice = createSlice({
  name: 'bugReport',
  initialState,
  reducers: {
    setShowBugReport: (state, action: PayloadAction<ISetShowBugReportPayload>) => {
      state.showBugReportSidebar = action.payload.show;
      state.type = action.payload.type || 'normal';
      state.videoInfo = action.payload.videoInfo;
    },
  },
});

export const { setShowBugReport } = bugReportSlice.actions;

export const bugReportReducer = bugReportSlice.reducer;
