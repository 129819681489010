import { IStats, IStatsGoalkeeper } from '../types';

export const getStatValueByMetricId = (stats: IStats | undefined, id: string) => {
  if (stats) {
    return stats[id as keyof IStats] ?? 0;
  }

  return 0;
};

export const getGoalkeeperStatValueByMetricId = (
  stats: IStatsGoalkeeper | undefined,
  id: string,
) => {
  if (stats) {
    return stats[id as keyof IStatsGoalkeeper] ?? 0;
  }

  return 0;
};
