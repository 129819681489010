import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../app/hooks';
import { selectPlayers, selectWowy } from '../features';
import { ITranslationKeys } from '../i18n/types';
import { IWowyType } from '../types';

export const useGetPlayersLabelByWowyType = () => {
  const { wowy } = useAppSelector(selectWowy);
  const { byId } = useAppSelector(selectPlayers);

  const { t } = useTranslation();

  const getSelectedPlayer = () => {
    if (wowy) {
      const selectedPlayerInfo = byId[wowy.selectedPlayer.id];

      return `${selectedPlayerInfo?.surname} ${selectedPlayerInfo?.name.substring(0, 1)}.`;
    }

    return t(ITranslationKeys.selectedPlayer);
  };

  const getPlayersLabelByWowyType = (
    selectedPlayerName: string,
    playerName: string,
    type?: IWowyType,
  ) => {
    if (type === IWowyType.separately) {
      return `${selectedPlayerName} ${t(ITranslationKeys.without)} ${playerName}`;
    }

    if (type === IWowyType.teammateSeparately) {
      return `${playerName} ${t(ITranslationKeys.without)} ${selectedPlayerName}`;
    }

    if (type === IWowyType.together) {
      return `${selectedPlayerName} ${t(ITranslationKeys.with)} ${playerName}`;
    }

    return selectedPlayerName;
  };

  return (playerName: string, type?: IWowyType) =>
    getPlayersLabelByWowyType(getSelectedPlayer(), playerName, type);
};
