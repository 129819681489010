import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Caption,
  CommonTable,
  DataSettingsModal,
  Loading,
  SelectInput,
  TableToolbar,
} from '../../../../components';
import { stickGripOptions } from '../../../../constants';
import {
  selectDataSettingsFilter,
  selectIndividualStats,
  selectMetrics,
  selectSimilarPlayers,
  selectTableFilter,
  setOpenDataSettings,
  setStickGrip,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useFetchSimilarityPlayers,
  useHandleOnSubmit,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { IMainFilterForm, ISimilarPlayersTableData } from '../../../../types';
import { createClassNames, isFormValid } from '../../../../utils';
import './SimilarityPlayersContent.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';
import { useExtraRowHeaderRender } from './useExtraRowHeaderRender';

const classNames = createClassNames('similarity-players-content');

export const SimilarityPlayersContent = () => {
  const { isLoading: isLoadingIndividual } = useAppSelector(selectIndividualStats);
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { isLoading } = useAppSelector(selectSimilarPlayers);
  const { metrics } = useAppSelector(selectMetrics);
  const { position, stickGrip } = useAppSelector(selectTableFilter);
  const dispatch = useAppDispatch();

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();

  const { formState } = useFormContext<IMainFilterForm>();
  const { errors, isDirty } = formState;
  const disabled = !isFormValid(errors);

  const isInitialMountRef = useRef(true);

  const { selectedPlayerData, data } = useDataForTable();
  const { columns, columnPinning, initialSorting } = useColumnsConfig(centerPartWidth);

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();
  const extraRowHeaderRender = useExtraRowHeaderRender(selectedPlayerData);

  const fetchSimilarityPlayers = useFetchSimilarityPlayers();
  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchSimilarityPlayers(values);
  }, isInitialMountRef);

  useEffect(() => {
    dispatch(setStickGrip(stickGrip ?? stickGripOptions[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInitialMountRef.current) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stickGrip]);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
    disablePositionRefetch: true,
  });

  useEffect(() => {
    if (!isInitialMountRef.current && isDirty) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  useRefetchContent({ handleOnSubmit, isLoading });

  const renderTableOrInfoBox = () => {
    if (isLoading || isLoadingIndividual) {
      return <Loading />;
    }

    const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, data.length);
    if (errorInfoBox) {
      return errorInfoBox;
    }

    return (
      <CommonTable<ISimilarPlayersTableData>
        centerPartRef={centerTablePartRef}
        extraRowHeaderRender={extraRowHeaderRender}
        {...{ data, columns, columnPinning, initialSorting }}
      />
    );
  };

  return (
    <div className={classNames()} data-testid='players-page__similarity-players-content'>
      <TableToolbar
        centerTablePartRef={centerTablePartRef}
        scrollSizePx={130}
        extraElement={
          <div>
            <Caption label={ITranslationKeys.stickGrip} />
            <SelectInput
              onChange={value => dispatch(setStickGrip(value))}
              selected={stickGrip}
              options={stickGripOptions}
              placeholder={ITranslationKeys.defaultSelectPlaceholder}
              variant='filter'
              disabled={disabled}
            />
          </div>
        }
      />
      {renderTableOrInfoBox()}
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.players}
          open={open}
          onClose={() => dispatch(setOpenDataSettings(false))}
          individualName={ITranslationKeys.playerData}
          onIceName={ITranslationKeys.playerOnIceData}
        />
      )}
    </div>
  );
};
