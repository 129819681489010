import { Dispatch, FC, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../app/hooks';
import {
  Caption,
  DateRangeController,
  SearchInput,
  SeasonSelect,
  SelectInput,
  TcmsButton,
  TeamSelectionCardList,
} from '../../../../../../components';
import { ACTUAL_SEASON_TEAMS_TCMS_OPTIONS, attendanceOptions } from '../../../../../../constants';
import { selectSeasons } from '../../../../../../features';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { FoldUpIcon, SearchIcon } from '../../../../../../icons';
import { IHokejPlayerTrackingFilterForm, ISelectOption } from '../../../../../../types';
import {
  createClassNames,
  createDateRangeFromSeasonYear,
  isFormValid,
} from '../../../../../../utils';
import './HokejPlayerTrackingFilter.styles.scss';

interface IHokejPlayerTrackingFilterProps {
  /** On data submit callback. */
  onSubmit: () => void;
  /** All available players options. */
  playersOptions: ISelectOption[];
}

const classNames = createClassNames('hokej-player-tracking-filter');

export const HokejPlayerTrackingFilter: FC<IHokejPlayerTrackingFilterProps> = ({
  onSubmit,
  playersOptions,
}) => {
  const seasons = useAppSelector(selectSeasons);

  const { t } = useTranslation();

  const { control, formState, setValue } = useFormContext<IHokejPlayerTrackingFilterForm>();
  const { isDirty, errors } = formState;

  const handleSelectSeasonDateRange = (
    season: ISelectOption,
    setOpen: Dispatch<SetStateAction<boolean>>,
  ) => {
    const seasonDateRange = createDateRangeFromSeasonYear(season.value);

    setValue('dateFromTo', seasonDateRange, {
      shouldDirty: true,
      shouldValidate: true,
    });

    setOpen(false);
  };

  return (
    <div className={classNames()}>
      <div>
        <Caption label={ITranslationKeys.teamSelection} variant='tcms' color='slate' />
        <Controller
          name='teamSelected'
          control={control}
          render={({ field: { value, onChange } }) => (
            <TeamSelectionCardList
              onChange={option => onChange(option)}
              selected={value}
              options={ACTUAL_SEASON_TEAMS_TCMS_OPTIONS}
            />
          )}
        />
      </div>
      <div className={classNames('main-part')}>
        <div>
          <Caption label={ITranslationKeys.searchPlayer} variant='tcms' color='slate' />
          <Controller
            name='searchedPlayer'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SearchInput
                onChange={option => onChange(option)}
                selected={value}
                options={playersOptions}
                placeholder={t(ITranslationKeys.searchPlayer).toString()}
                variant='tcms'
                iconComponent={<SearchIcon />}
              />
            )}
          />
        </div>
        <div>
          <Caption label={ITranslationKeys.attendance} variant='tcms' color='slate' />
          <Controller
            name='attendance'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectInput
                onChange={option => onChange(option)}
                selected={value}
                options={attendanceOptions}
                placeholder={ITranslationKeys.defaultSelectPlaceholder}
                variant='tcms'
                iconComponent={<FoldUpIcon />}
              />
            )}
          />
        </div>
        <DateRangeController
          name='dateFromTo'
          control={control}
          renderExtraControlElement={setOpen => (
            <SeasonSelect
              seasonRecord={seasons.byId}
              width='280px'
              onSeasonClick={season => handleSelectSeasonDateRange(season, setOpen)}
            />
          )}
        />
        <TcmsButton
          variant='filled'
          color='blue'
          label={ITranslationKeys.displayData}
          width={216}
          onClick={onSubmit}
          disabled={!isDirty || !isFormValid(errors)}
        />
      </div>
    </div>
  );
};
