import { FC, MouseEventHandler } from 'react';

import { CroppedTimeIcon } from '../../icons';
import { IVideoControls } from '../../types';
import {
  createClassNames,
  secondsToMinutesAndSeconds,
  transformObjectKeysToKebabCase,
} from '../../utils';
import './CroppedTime.styles.scss';

export interface ICroppedTimeProps extends Partial<IVideoControls> {
  /** Is video time or cut edited? */
  isEdited?: boolean;
  /** Function called on click. */
  onClick?: () => void;
}

const classNames = createClassNames('cropped-time');

/** UI component displays cropped time icon and tooltip. */
export const CroppedTime: FC<ICroppedTimeProps> = ({
  videoTime,
  cutBefore,
  cutAfter,
  isEdited = false,
  onClick,
}) => {
  const handleOnClick: MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();
    onClick?.();
  };

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({ isEdited, isClickable: !!onClick }),
      })}
      onClick={onClick ? handleOnClick : undefined}
    >
      <CroppedTimeIcon />
      <div className='cropped-tooltip'>
        {videoTime ? secondsToMinutesAndSeconds(videoTime) : videoTime}, -{cutBefore}, {cutAfter}
      </div>
    </div>
  );
};
