import { ChangeEvent, Dispatch, ReactNode, SetStateAction } from 'react';
import { DateRange } from 'react-day-picker';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { analyticsTypeOptions, roleOptionsWithAll } from '../../../constants';
import { ITranslationKeys } from '../../../i18n/types';
import {
  AnalyticsTypeIcon,
  CalendarIcon,
  SearchIcon,
  UserGroupIcon,
  UserRoleIcon,
} from '../../../icons';
import {
  IControllerWithControlProps,
  IControllersWithCustomOptionsProps,
  IMultiParamsVoid,
  ISelectOption,
} from '../../../types';
import {
  getEmptyArrayValidationObject,
  parseDateToStringDateRange,
  parseStringToDateDateRange,
} from '../../../utils';
import { Caption } from '../../Caption';
import { DateRangePicker } from '../../DateRangePicker';
import { Input } from '../../Input';
import { MultiselectInput } from '../../MultiselectInput';
import { SelectInput } from '../../SelectInput';

/** Select team or user controller. */
export const SelectTeamOrMediaController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  options,
  disabled = false,
}: IControllerWithControlProps<TFieldValues, TName> &
  IControllersWithCustomOptionsProps<TFieldValues, TName> & { disabled?: boolean }) => {
  const { t } = useTranslation();

  const handleOnChange = (value: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(value);
  };

  return (
    <div>
      <Caption
        label={`${t(ITranslationKeys.selectTeam)} / ${t(ITranslationKeys.media).toLowerCase()}`}
        variant='tcms'
        color='slate'
      />
      <Controller
        name={name}
        control={control}
        rules={{
          validate: {
            ...getEmptyArrayValidationObject(ITranslationKeys.pleaseSelectTeamOrMedia),
          },
        }}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <MultiselectInput
            onChange={newValue => handleOnChange(newValue, onChange)}
            selected={value}
            options={options || []}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='tcms'
            iconComponent={<UserGroupIcon />}
            error={!!error}
            onBlur={onBlur}
            disabled={disabled}
          />
        )}
      />
    </div>
  );
};

/** Analytics type controller. */
export const AnalyticsTypeController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  options = analyticsTypeOptions,
}: IControllerWithControlProps<TFieldValues, TName> &
  IControllersWithCustomOptionsProps<TFieldValues, TName>) => {
  const handleOnChange = (value: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(value);
  };

  return (
    <div>
      <Caption label={ITranslationKeys.analyticsType} variant='tcms' color='slate' />
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <SelectInput
            onChange={newValue => handleOnChange(newValue, onChange)}
            selected={value}
            options={options}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='tcms'
            iconComponent={<AnalyticsTypeIcon />}
          />
        )}
      />
    </div>
  );
};

/** Date range controller. */
export const DateRangeController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  renderExtraControlElement,
}: IControllerWithControlProps<TFieldValues, TName> & {
  renderExtraControlElement?: (setOpen: Dispatch<SetStateAction<boolean>>) => ReactNode;
}) => {
  const handleChangeDateFromTo = (value: DateRange | undefined, onChange: IMultiParamsVoid) => {
    onChange(parseDateToStringDateRange(value));
  };

  return (
    <div>
      <Caption label={ITranslationKeys.interval} variant='tcms' color='slate' />
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <DateRangePicker
            value={parseStringToDateDateRange(value)}
            onChange={(value: DateRange | undefined) => handleChangeDateFromTo(value, onChange)}
            variant='tcms'
            iconComponent={<CalendarIcon />}
            popUpAlignSelf='center'
            renderExtraControlElement={renderExtraControlElement}
          />
        )}
      />
    </div>
  );
};

/**
 * User email controller.
 */
export const UserController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
}: IControllerWithControlProps<TFieldValues, TName>) => {
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>, onChange: IMultiParamsVoid) => {
    onChange(event.target.value);
  };

  return (
    <div>
      <Caption label={t(ITranslationKeys.inputSearchedEmail)!} variant='tcms' color='slate' />
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Input
            variant='tcms'
            min={0}
            value={value}
            placeholder={t(ITranslationKeys.inputSearchedEmail)!}
            onChange={event => handleChange(event, onChange)}
            iconComponent={<SearchIcon />}
          />
        )}
      />
    </div>
  );
};

/**
 * User role controller.
 */
export const RoleController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  options,
}: IControllerWithControlProps<TFieldValues, TName> &
  IControllersWithCustomOptionsProps<TFieldValues, TName>) => {
  const handleChange = (value: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(value);
  };

  return (
    <div>
      <Caption label={ITranslationKeys.userRole} variant='tcms' color='slate' />
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <SelectInput
            onChange={event => handleChange(event, onChange)}
            selected={value}
            options={options ?? roleOptionsWithAll}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='tcms'
            iconComponent={<UserRoleIcon />}
          />
        )}
      />
    </div>
  );
};

/**
 * User role multiselect controller.
 */
export const RoleMultiselectController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  options,
  handleOnChange,
}: IControllerWithControlProps<TFieldValues, TName> &
  IControllersWithCustomOptionsProps<TFieldValues, TName> & { handleOnChange?: () => void }) => {
  const handleChange = (value: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(value);
    handleOnChange?.();
  };

  return (
    <div>
      <Caption label={ITranslationKeys.userRole} variant='tcms' color='slate' />
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <MultiselectInput
            onChange={newValue => handleChange(newValue, onChange)}
            selected={value}
            options={options ?? roleOptionsWithAll}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='tcms'
            iconComponent={<UserRoleIcon />}
          />
        )}
      />
    </div>
  );
};
