import {
  IGoalkeeperNetZoneCardPlayerStats,
  IPlayerWithStatsDTO,
  IPlayerWithTeamIdAndStats,
  ITeamWithPlayersWithStatsDTO,
} from '../../types';
import { parseGoalkeepersStatsData } from '../../utils/goalkeepers.utils';

export const parseGoalkeepersNetZonesCardInfoData = (
  data: IGoalkeeperNetZoneCardPlayerStats[],
  playerUuid: string,
): IGoalkeeperNetZoneCardPlayerStats => {
  const playerData = data.find(player => player.player === playerUuid);
  if (!playerData) throw new Error('Player not found');
  return playerData;
};

export const parseGoalkeepersNetZonesStatsData = (
  data: ITeamWithPlayersWithStatsDTO[] | IPlayerWithStatsDTO[],
  teamUuid?: string,
): IPlayerWithTeamIdAndStats[] => parseGoalkeepersStatsData(data, teamUuid);
