import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { LoadingIcon } from '../../icons';
import { ISize } from '../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './Loading.styles.scss';

export type ILoadingBackgroundColor = 'dark' | 'gray' | 'transparent';

export interface ILoadingProps {
  /** Size of the component. */
  size?: ISize;
  /** Background color of the component. */
  backgroundColor?: ILoadingBackgroundColor;
  /** If true, the message will not be shown. */
  disableMessage?: boolean;
  /** Disables absolute position. */
  disableAbsolutePosition?: boolean;
  /** Disables padding. */
  disableHorizontalMargin?: boolean;
}

const classNames = createClassNames('loading-component');

/** UI component for indicating data loading. */
export const Loading: FC<ILoadingProps> = ({
  size = 'large',
  backgroundColor = 'dark',
  disableMessage = false,
  disableAbsolutePosition = false,
  disableHorizontalMargin = false,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          size,
          backgroundColor,
          absoluteCenter: !disableAbsolutePosition,
          horizontalMargin: !disableHorizontalMargin,
        }),
      })}
      data-testid='loading-component'
    >
      <LoadingIcon />
      {!(disableMessage || size === 'small') && <span>{t(ITranslationKeys.loadingData)}</span>}
    </div>
  );
};
