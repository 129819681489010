import { createSlice } from '@reduxjs/toolkit';

import { IShootoutState } from '../../types';
import { getShootouts } from './shootoutActions';

const initialState: IShootoutState = {
  isLoading: undefined,
  shootouts: undefined,
  shootoutPage: undefined,
  error: undefined,
};

export const shootoutSlice = createSlice({
  name: 'shootout',
  initialState,
  reducers: {
    setShootouts: (state, action) => {
      state.shootouts = action.payload;
    },
    setShootoutPage: (state, action) => {
      state.shootoutPage = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getShootouts.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getShootouts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.shootouts = action.payload;
      })
      .addCase(getShootouts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { setShootouts, setShootoutPage } = shootoutSlice.actions;

export const shootoutReducer = shootoutSlice.reducer;
