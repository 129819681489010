import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { bugReportOptions, bugReportVideoOptions } from '../../constants';
import { ITranslationKeys } from '../../i18n/types';
import { CloseBlueIcon } from '../../icons';
import { IBugReportType, IBugReportVideoInfo, ISelectOption } from '../../types';
import { createClassNames, postBugReport, transformObjectKeysToKebabCase } from '../../utils';
import { Button } from '../Button';
import { ToggleSelect } from '../ToggleSelect';
import './BugReport.styles.scss';

export interface IBugReportProps {
  isOpened: boolean;
  onClose: () => void;
  type: IBugReportType;
  pageName: string;
  tabName: string;
  videoInfo?: IBugReportVideoInfo;
}

const classNames = createClassNames('bugReport');

export const BugReport: FC<IBugReportProps> = ({
  isOpened,
  onClose,
  type,
  pageName,
  tabName,
  videoInfo,
}) => {
  const { t } = useTranslation();

  const [problemType, setProblemType] = useState<ISelectOption>();
  const [message, setMessage] = useState<string>('');

  const onMessageChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    if (type === 'normal') {
      setProblemType(bugReportOptions[0]);
    } else {
      setProblemType(bugReportVideoOptions[0]);
    }
    setMessage('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  const handleSend = () => {
    postBugReport({
      issueType: `${t(pageName)}/${t(tabName)} - ${t(problemType!.value)}`,
      description: message,
      ...videoInfo,
    })
      .then(() => {
        toast.success(t(ITranslationKeys.reportSent), { toastId: 'reportSent' });
        onClose();
      })
      .catch(err => {
        console.log('Post report err:', err);
        toast.error(t(ITranslationKeys.reportSentError), { toastId: 'reportSentError' });
      });
  };

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isOpened,
        }),
      })}
    >
      <div className={classNames('header')}>
        <h3 className={classNames('title')}>{t(ITranslationKeys.reportBug)}</h3>
        <span className={classNames('close')} onClick={onClose}>
          {t(ITranslationKeys.close)}
          <CloseBlueIcon />
        </span>
      </div>
      <ToggleSelect
        selected={problemType}
        options={type === 'normal' ? bugReportOptions : bugReportVideoOptions}
        onChange={newValue => setProblemType(newValue)}
        twoColumns
      />
      <textarea
        className={classNames('message')}
        value={message}
        onChange={onMessageChange}
        placeholder={t(ITranslationKeys.describeProblem) || ''}
      />
      <Button label={t(ITranslationKeys.send) || ''} onClick={handleSend} />
    </div>
  );
};
