import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { createClassNames } from '../../../utils';
import { LogoCircle } from '../../LogoCircle';
import './HokejPlayerManagementHeader.styles.scss';

interface IHokejPlayerManagementHeaderProps {
  /**
   * Path (uuid) of videocoach's team.
   */
  logoPath: string;
}

const classNames = createClassNames('hokej-player-management-header');

/**
 * HokejPlayerManagementHeader UI component.
 */
export const HokejPlayerManagementHeader: FC<IHokejPlayerManagementHeaderProps> = ({
  logoPath,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      <h1>{t(ITranslationKeys.hokejPlayerManagement)}</h1>
      <LogoCircle logoPath={logoPath} />
    </div>
  );
};
