import { FC } from 'react';

import { INetZone } from '../../../../types';
import { createClassNames } from '../../../../utils';
import './GoalNetCountContent.styles.scss';

export interface IGoalNetCountContentProps {
  netZonesShotsCount: Record<INetZone, number>;
}

const classNames = createClassNames('goal-net-count-content');

export const GoalNetCountContent: FC<IGoalNetCountContentProps> = ({ netZonesShotsCount }) => (
  <div className={classNames()}>
    {Object.entries(netZonesShotsCount).map(([zone, value]) => (
      <div key={zone} className={classNames('circle', { zone })}>
        {value}
      </div>
    ))}
  </div>
);
