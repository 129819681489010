import { DISABLED_TABS_ON_OFF, NET_ZONES, SELECT_PLAYERS_INPUT_COLORS } from '../constants';
import { ITranslationKeys } from '../i18n/types';
import {
  IDefaultPlayerColorNames,
  IGoalStatsBoxProps,
  IGoalStatsBoxWrapperProps,
  INavigationContentKeys,
  INetZone,
  INetZoneUnion,
  IPlayerRecord,
  ISelectedPlayersRecord,
  IShootout,
  ISimilarPlayerNameRecord,
  IVideoInfo,
} from '../types';
import { getPlayerShortName } from './common.utils';
import { isAtLeastOnePlayerOn, isSelectedPlayerSelected } from './players.utils';

const disabledOnOff = DISABLED_TABS_ON_OFF.includes(INavigationContentKeys.shootouts);

export const mapShootoutToVideoInfo = (
  shootout: IShootout,
  isGoalkeeper: boolean = false,
): IVideoInfo => ({
  videoId: shootout.videoId,
  videoTime: shootout.videoTime,
  matchId: shootout.matchId,
  matchDate: shootout.matchDate,
  playerId: isGoalkeeper ? shootout.goalkeeper.playerId : shootout.skater.playerId,
  time: 1,
});

export const filterShootoutsByGateZone = (shootouts: IShootout[], zone: INetZoneUnion) => {
  if (zone === '') return shootouts;
  return shootouts.filter(shootout => shootout.gateZone === zone);
};

export const countShootoutGoals = (shootouts: IShootout[]) =>
  shootouts.reduce((acc, shootout) => (shootout.goal ? acc + 1 : acc), 0);

export const countShootoutPercentage = (shootouts: IShootout[], zone: INetZoneUnion) => {
  const filteredByGateZone = filterShootoutsByGateZone(shootouts, zone);
  const percent = Math.round(
    (100 / filteredByGateZone.length) * countShootoutGoals(filteredByGateZone),
  );

  if (isNaN(percent)) return 0;

  return percent;
};

export const filterShootoutByPlayerId = (shootouts: IShootout[], playerId: string) =>
  shootouts.filter(shootout => shootout.skater.playerId === playerId);

export const transformShootoutsToDefaultVideomapsBox = (
  filteredShootoutShots: IShootout[],
  onBoxClick: (array: IVideoInfo[]) => void,
  isGoalkeeper: boolean = false,
): IGoalStatsBoxWrapperProps[] => {
  return NET_ZONES.map<IGoalStatsBoxWrapperProps>(zone => {
    const shootoutByGameZone = filterShootoutsByGateZone(filteredShootoutShots, zone);
    if (zone === INetZone.ce) {
      const shootoutCenterZone = filterShootoutsByGateZone(filteredShootoutShots, INetZone.ce);
      const centerShootoutValues: IGoalStatsBoxWrapperProps = {
        goalStatsBoxItems: [
          {
            items: [
              {
                label: ITranslationKeys.count,
                value: shootoutCenterZone.length.toString(),
              },
            ],
            onClick: () =>
              onBoxClick(
                shootoutCenterZone.map(shootout => mapShootoutToVideoInfo(shootout, isGoalkeeper)),
              ),
          },
        ],
      };
      return centerShootoutValues;
    }

    const zoneValues: IGoalStatsBoxWrapperProps = {
      goalStatsBoxItems: [
        {
          items: [
            {
              label: ITranslationKeys.count,
              value: shootoutByGameZone.length.toString(),
            },
            {
              label: ITranslationKeys.goals,
              value: countShootoutGoals(shootoutByGameZone).toString(),
            },
            {
              label: 'Sv%',
              value: isGoalkeeper
                ? 100 - countShootoutPercentage(filteredShootoutShots, zone) + '%'
                : countShootoutPercentage(filteredShootoutShots, zone) + '%',
            },
          ],
          onClick: () =>
            onBoxClick(
              shootoutByGameZone.map(shootout => mapShootoutToVideoInfo(shootout, isGoalkeeper)),
            ),
        },
      ],
    };
    return zoneValues;
  });
};

const transformShootoutsToPlayerVideomapsBox = (
  filteredShootoutShots: IShootout[],
  selectedPlayerItems: ISelectedPlayersRecord,
  zone: INetZoneUnion,
  onBoxClick: (array: IVideoInfo[]) => void,
): IGoalStatsBoxProps[] => {
  const playerItemValues = Object.values(selectedPlayerItems);
  return playerItemValues.reduce<IGoalStatsBoxProps[]>((acc, player) => {
    if (!isSelectedPlayerSelected(player, disabledOnOff) || !player.selectedPlayer) return acc;
    const playerShootout = filterShootoutByPlayerId(
      filteredShootoutShots,
      player.selectedPlayer.value,
    );

    const color: IDefaultPlayerColorNames = SELECT_PLAYERS_INPUT_COLORS[player.id];
    const shootoutByGameZone = filterShootoutsByGateZone(playerShootout, zone);
    if (zone === INetZone.ce) {
      const shootoutCenterZone = filterShootoutsByGateZone(playerShootout, INetZone.ce);
      acc.push({
        items: [
          {
            label: ITranslationKeys.count,
            value: shootoutCenterZone.length.toString(),
          },
        ],
        color,
        onClick: () =>
          onBoxClick(shootoutCenterZone.map(shootout => mapShootoutToVideoInfo(shootout))),
      });
      return acc;
    }

    acc.push({
      items: [
        {
          label: ITranslationKeys.count,
          value: shootoutByGameZone.length.toString(),
        },
        {
          label: ITranslationKeys.goals,
          value: countShootoutGoals(shootoutByGameZone).toString(),
        },
        {
          label: 'Sv%',
          value: countShootoutPercentage(playerShootout, zone) + '%',
        },
      ],
      color,
      onClick: () =>
        onBoxClick(shootoutByGameZone.map(shootout => mapShootoutToVideoInfo(shootout))),
    });
    return acc;
  }, [] as IGoalStatsBoxProps[]);
};

export const goalNetProps = (
  filteredShootoutShots: IShootout[],
  selectedPlayerItems: ISelectedPlayersRecord,
  onBoxClick: (array: IVideoInfo[]) => void,
): IGoalStatsBoxWrapperProps[] => {
  const isSomePlayerSelected = isAtLeastOnePlayerOn(selectedPlayerItems, disabledOnOff);
  if (!isSomePlayerSelected)
    return transformShootoutsToDefaultVideomapsBox(filteredShootoutShots, onBoxClick);

  const goalNetStatsPropsByPlayers: IGoalStatsBoxWrapperProps[] =
    NET_ZONES.map<IGoalStatsBoxWrapperProps>(zone => {
      return {
        goalStatsBoxItems: transformShootoutsToPlayerVideomapsBox(
          filteredShootoutShots,
          selectedPlayerItems,
          zone,
          onBoxClick,
        ),
      };
    });
  return goalNetStatsPropsByPlayers;
};

export const totalValuesPerTeamWrappers = (
  filteredShootoutShots: IShootout[],
  players: IPlayerRecord,
  selectedPlayerItems: ISelectedPlayersRecord,
  teamName: string,
  similarPlayerNames: ISimilarPlayerNameRecord,
  onBoxClick: (array: IVideoInfo[]) => void,
): IGoalStatsBoxWrapperProps[] => {
  const isSomePlayerSelected = isAtLeastOnePlayerOn(selectedPlayerItems, disabledOnOff);
  if (!isSomePlayerSelected) {
    const shootoutByGameZone = filterShootoutsByGateZone(filteredShootoutShots, '');
    const personalShootout = shootoutByGameZone.length.toString();
    const personalGoals = countShootoutGoals(filteredShootoutShots).toString();
    const personalPercentage = countShootoutPercentage(filteredShootoutShots, '') + '%';
    const teamStatsBoxWrapper = [
      {
        goalStatsBoxItems: [
          {
            items: [
              { value: personalShootout },
              { value: personalGoals },
              { value: personalPercentage },
            ],
            onClick: () =>
              onBoxClick(shootoutByGameZone.map(shootout => mapShootoutToVideoInfo(shootout))),
          },
        ],
        fontWeight: 'bold',
        showHeader: false,
        label: teamName,
      },
    ];
    return teamStatsBoxWrapper;
  }

  const playerWrappers = Object.values(selectedPlayerItems).reduce<IGoalStatsBoxWrapperProps[]>(
    (acc, player) => {
      if (!player.isActive || !player.selectedPlayer) return acc;
      const playerName = getPlayerShortName(
        players[player.selectedPlayer.value],
        similarPlayerNames,
      );
      const playerShootouts = filterShootoutByPlayerId(
        filteredShootoutShots,
        player.selectedPlayer.value,
      );
      const playerGoals = countShootoutGoals(playerShootouts).toString();
      const playerPercentage = countShootoutPercentage(playerShootouts, '') + '%';
      const playerStatsBoxWrappers: IGoalStatsBoxWrapperProps = {
        goalStatsBoxItems: [
          {
            items: [
              { value: playerShootouts.length.toString() },
              { value: playerGoals },
              { value: playerPercentage },
            ],
            variant: 'dark',
            fontWeight: 'bold',
            onClick: () =>
              onBoxClick(playerShootouts.map(shootout => mapShootoutToVideoInfo(shootout))),
          },
        ],
        color: SELECT_PLAYERS_INPUT_COLORS[player.id],
        showHeader: false,
        label: playerName,
      };
      acc.push(playerStatsBoxWrappers);
      return acc;
    },
    [],
  );
  return playerWrappers;
};
