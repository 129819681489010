import {
  ColumnDef,
  ColumnPinningState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  BasicColumnCell,
  BasicColumnHeader,
  EyeDropdown,
  ImageColumn,
  PlayerColumnCell,
} from '../../../../components';
import { logosPaths } from '../../../../constants';
import { selectMainFilter } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IColumnsConfig,
  IEntity,
  IGamesTablesPlayerTableData,
  INavigationContentKeys,
  INavigationPageKeys,
  IUrlFilterParams,
} from '../../../../types';
import { createDynamicStatsColumnsDef, secondsToMinutesAndSeconds } from '../../../../utils';

const columnHelper = createColumnHelper<IGamesTablesPlayerTableData>();

const columnPinning: ColumnPinningState = {
  left: ['actions', 'teamId', 'player', 'toi'],
};

const initialSorting: SortingState = [
  {
    id: 'toi',
    desc: true,
  },
];

const fixedColumns: ColumnDef<IGamesTablesPlayerTableData, any>[] = [
  columnHelper.accessor('teamId', {
    header: '',
    cell: props => <ImageColumn src={logosPaths[props.getValue()]} alt='team-logo' />,
  }),
  columnHelper.accessor('player', {
    header: () => '',
    cell: props => <PlayerColumnCell {...props} isGoalkeeper />,
  }),
  columnHelper.accessor('toi', {
    header: props => <BasicColumnHeader {...props}>TOI</BasicColumnHeader>,
    cell: props => (
      <BasicColumnCell {...props}>{secondsToMinutesAndSeconds(props.getValue())}</BasicColumnCell>
    ),
  }),
];

export const useColumnsConfig = (
  centerPartWidth: number | undefined,
): IColumnsConfig<IGamesTablesPlayerTableData> => {
  const getFilteredMetrics = useFilteredMetricsForTable();
  const { selectedGame } = useAppSelector(selectMainFilter);

  const columns = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(IEntity.goalkeepers);
    const dynamicColumns = createDynamicStatsColumnsDef(
      filteredMetrics,
      columnHelper,
      undefined,
      centerPartWidth,
    );
    const actionsColumn = createActionsColumn(selectedGame?.id);

    return [actionsColumn].concat(fixedColumns).concat(dynamicColumns);
    // eslint-disable-next-line
  }, [centerPartWidth, getFilteredMetrics]);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};

const createActionsColumn = (selectedGameId?: string) =>
  columnHelper.display({
    id: 'actions',
    cell: props => {
      const filterParams: IUrlFilterParams[] = [
        {
          name: 'selectedTeam',
          value: props.row.original.teamId,
        },
        {
          name: 'selectedGoalkeeper',
          value: props.row.original.player.id,
        },
      ];

      if (selectedGameId) {
        // setGoalkeeperSelectedGames([selectedGameOption]) TODO-Navigace: add selected games
        filterParams.push({
          name: 'goalkeeperSelectedGames',
          value: selectedGameId,
        });
      }

      return (
        <EyeDropdown
          eyeDropdownTooltipItems={[
            {
              pageTranslationLabel: ITranslationKeys.gamelog,
              navigationPageKey: INavigationPageKeys.goalkeepers,
              navigationTabKey: INavigationContentKeys.gamelog,
              filterParams,
            },
            // TODO: Add goalkeepers trend after implementing it
            // {
            //   pageTranslationLabel: ITranslationKeys.trend,
            //   navigationPageKey: INavigationPageKeys.goalkeepers,
            //   navigationTabKey: INavigationContentKeys.trend,
            //   filterParams,
            // },
            {
              pageTranslationLabel: ITranslationKeys.shots,
              navigationPageKey: INavigationPageKeys.goalkeepers,
              navigationTabKey: INavigationContentKeys.shotmap,
              filterParams,
            },
            {
              pageTranslationLabel: ITranslationKeys.shootouts,
              navigationPageKey: INavigationPageKeys.goalkeepers,
              navigationTabKey: INavigationContentKeys.shootouts,
              filterParams,
            },
          ]}
        />
      );
    },
  });
