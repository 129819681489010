import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { IDatasetMetric, IMetricInfo } from '../../../types';
import {
  createClassNames,
  getMetricParametersInfo,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import './TableMetricTooltip.styles.scss';

export interface ITableMetricTooltipProps {
  /** Metric's informations. */
  metricInfo: Partial<IMetricInfo>;
  /** Whether the tooltip is shown or not. */
  isShown?: boolean;
  /** Whether the tooltip is aligned to the right side of table or not. */
  alignToRight?: boolean;
  /** Whether the tooltip is for last metric of right part table or not. */
  isLastChildRightTable?: boolean;
  /** Dataset metric to get extra informations (e.g. metric's parameters descriptions). */
  datasetMetric?: IDatasetMetric;
}

const classNames = createClassNames('table-metric-tooltip');

export const TableMetricTooltip: FC<ITableMetricTooltipProps> = ({
  metricInfo,
  isShown,
  alignToRight,
  isLastChildRightTable,
  datasetMetric,
}) => {
  const { t } = useTranslation();

  const parametersInfo = datasetMetric ? getMetricParametersInfo(datasetMetric) : undefined;

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isShown,
          alignToRight,
          isLastChildRightTable,
        }),
      })}
      data-testid='table-metric-tooltip'
    >
      <div className={classNames('title')}>{metricInfo.eng}</div>
      <div className={classNames('description')}>
        {metricInfo.description}
        {parametersInfo?.map(parameter => (
          <Fragment key={parameter.id}>
            <br />
            {`${t(parameter.paramGroupLabel)}: ${t(parameter.label)}`}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
