import { FC } from 'react';

import { useLocationPaths } from '../../hooks';
import { ITabConfig } from '../../types';
import { createClassNames } from '../../utils';
import { TabItem } from '../TabItem';
import './TabItemList.styles.scss';

export interface ITabItemListProps {
  /** Config with information about tabs */
  config: ITabConfig[];
}

const classNames = createClassNames('tab-item-list');

/** Component displays list of TabItems. */
export const TabItemList: FC<ITabItemListProps> = ({ config }) => {
  const { activePage, activeTab, search } = useLocationPaths();

  return (
    <div className={classNames()} data-testid={'tab-item-list'}>
      {config.map(tab => (
        <TabItem
          key={tab.id}
          label={tab.name}
          isActive={activeTab === tab.id}
          to={{
            pathname: `/${activePage}/${tab.id}`,
            search,
          }}
          disabled={tab.disabled}
        />
      ))}
    </div>
  );
};
