import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IIdentity, INewIdentityParams, IRole } from '../../types';
import { defaultCatchErrorCallback } from '../../utils';

interface IBaseChangeIdentityParams {
  identityId: string;
}

interface IChangeIdentityPasswordParams extends IBaseChangeIdentityParams {
  newPassword: string;
}

interface IChangeIdentityRoleParams extends IBaseChangeIdentityParams {
  newRole: IRole;
}

export interface IChangeIdentityTeamOrMediaParams extends IBaseChangeIdentityParams {
  newTeamOrMediaId: string | null;
  type?: 'team' | 'media';
}

export const getIdentities = createAsyncThunk<IIdentity[]>('identity/getIdentities', async () => {
  const identities: IIdentity[] = await axiosInstance
    .get('/identity')
    .then(response => response.data)
    .catch(defaultCatchErrorCallback);

  return identities;
});

export const createIdentity = createAsyncThunk<number, INewIdentityParams>(
  'identity/createIdentity',
  async ({ name, surname, username, password, role, teamId }) => {
    const status = await axiosInstance
      .post('/identity', {
        name,
        surname,
        username,
        password,
        role,
        teamId,
      })
      .then(response => response.status)
      .catch(defaultCatchErrorCallback);

    return status;
  },
);

export const deleteIdentity = createAsyncThunk<number, string>(
  'identity/deleteIdentity',
  async identityId => {
    const status = await axiosInstance
      .delete(`/identity/${identityId}`)
      .then(response => response.status)
      .catch(defaultCatchErrorCallback);

    return status;
  },
);

export const enableIdentity = createAsyncThunk<number, string>(
  'identity/enableIdentity',
  async identityId => {
    const status = await axiosInstance
      .post(`/identity/${identityId}/enable`, {})
      .then(response => response.status)
      .catch(defaultCatchErrorCallback);

    return status;
  },
);

export const changeIdentityPassword = createAsyncThunk<number, IChangeIdentityPasswordParams>(
  'identity/changeIdentityPassword',
  async ({ identityId, newPassword }) => {
    const status = await axiosInstance
      .put(`/identity/${identityId}/password`, { password: newPassword })
      .then(response => response.status)
      .catch(defaultCatchErrorCallback);

    return status;
  },
);

export const changeIdentityRole = createAsyncThunk<number, IChangeIdentityRoleParams>(
  'identity/changeIdentityRole',
  async ({ identityId, newRole }) => {
    const status = await axiosInstance
      .put(`/identity/${identityId}/role`, { role: newRole })
      .then(response => response.status)
      .catch(defaultCatchErrorCallback);

    return status;
  },
);

export const changeIdentityTeamOrMedia = createAsyncThunk<number, IChangeIdentityTeamOrMediaParams>(
  'identity/changeIdentityTeamOrMedia',
  async ({ identityId, newTeamOrMediaId, type = 'team' }) => {
    const key = type === 'team' ? 'teamId' : 'media';

    const status = await axiosInstance
      .put(`/identity/${identityId}/${type}`, { [key]: newTeamOrMediaId })
      .then(response => response.status)
      .catch(defaultCatchErrorCallback);

    return status;
  },
);
