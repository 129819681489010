import { createSlice } from '@reduxjs/toolkit';

import { IFaceoffState } from '../../types';
import { getFaceoffs } from './faceoffsActions';

const initialState: IFaceoffState = {
  byId: {},
  allIds: [],
  isLoading: undefined,
  error: undefined,
};

export const faceoffsSlice = createSlice({
  name: 'faceoffs',
  initialState,
  reducers: {
    resetFaceoffs: state => {
      state.byId = {};
      state.allIds = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getFaceoffs.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getFaceoffs.fulfilled, (state, action) => {
        const playerId = action.meta.arg.playerUuid;
        if (playerId) {
          state.byId[playerId] = action.payload;
          if (state.allIds.indexOf(playerId) === -1) state.allIds.push(playerId);
        } else {
          state.byId['all'] = action.payload;
          if (state.allIds.indexOf('all') === -1) state.allIds.push('all');
        }
        state.isLoading = false;
      })
      .addCase(getFaceoffs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetFaceoffs } = faceoffsSlice.actions;

export const faceoffsReducer = faceoffsSlice.reducer;
