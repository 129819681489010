import { ColumnDef, SortingState, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../app/hooks';
import { BasicColumnCell, BasicColumnHeader, UserLogColumnHeader } from '../../../../components';
import { roleOptions } from '../../../../constants';
import { teamAndMediaToSetUserOptionsSelector } from '../../../../features';
import { ITranslationKeys } from '../../../../i18n/types';
import { HokejCoachLogoIcon, HokejLogicLogoIcon, PlayIcon } from '../../../../icons';
import {
  IIdentitiesTableData,
  IIdentityAccessLogData,
  INavigationPageKeys,
  INavigationTrackingContentKeys,
  IUrlFilterParams,
} from '../../../../types';
import {
  createClassNames,
  createIndexColumn,
  formatDateByLanguage,
  normalizeUrlWithFilterParams,
} from '../../../../utils';
import './columns.styles.scss';

const classNames = createClassNames('users-content-columns');

const columnHelper = createColumnHelper<IIdentitiesTableData>();

const initialSorting: SortingState = [
  {
    id: 'username',
    desc: false,
  },
];

const sortUserLogColumns = (a?: IIdentityAccessLogData, b?: IIdentityAccessLogData) => {
  if (!a || !b) return 0;

  if (a.total === b.total) {
    return a.inLastThirtyDays - b.inLastThirtyDays;
  }

  return a.total - b.total;
};

const fixedColumns: ColumnDef<IIdentitiesTableData, any>[] = [
  columnHelper.display({
    id: 'index',
    header: props => (
      <BasicColumnHeader
        {...props}
        translationKey={ITranslationKeys.id}
        allowSortIcon
        variant='tcms'
      />
    ),
    cell: props => (
      <BasicColumnCell {...props} variant='tcms'>
        <div className={[classNames(), classNames('id-cell')].join(' ')}>
          {createIndexColumn(props)}
        </div>
      </BasicColumnCell>
    ),
    enableSorting: false,
  }),
  columnHelper.accessor('username', {
    header: props => (
      <BasicColumnHeader
        {...props}
        translationKey={ITranslationKeys.user}
        justifyContent='flex-start'
        allowSortIcon
        variant='tcms'
      />
    ),
    cell: props => (
      <BasicColumnCell {...props} variant='tcms'>
        <div
          className={[classNames(), classNames('username-cell')].join(' ')}
          title={props.row.original.username}
        >
          {props.row.original.username}
        </div>
      </BasicColumnCell>
    ),
  }),
];

export const useColumnsConfig = () => {
  const teamAndMediaToSetUserOptions = useAppSelector(teamAndMediaToSetUserOptionsSelector);

  const { t } = useTranslation();

  const columns = useMemo(
    () => {
      const dynamicColumns: ColumnDef<IIdentitiesTableData, any>[] = [
        columnHelper.accessor('role', {
          header: props => (
            <BasicColumnHeader
              {...props}
              translationKey={ITranslationKeys.role}
              allowSortIcon
              variant='tcms'
            />
          ),
          cell: props => {
            const roleLabel = roleOptions.find(option => option.value === props.getValue())?.label;
            return (
              <BasicColumnCell {...props} variant='tcms'>
                <div className={[classNames(), classNames('role-cell')].join(' ')}>
                  {t(roleLabel ?? '')}
                </div>
              </BasicColumnCell>
            );
          },
        }),
        columnHelper.display({
          id: 'teamId',
          header: props => (
            <BasicColumnHeader
              {...props}
              translationKey={ITranslationKeys.teamMedia}
              allowSortIcon
              variant='tcms'
            />
          ),
          cell: props => {
            const { original } = props.row;
            const teamMediaId = original.teamId ?? original.media;

            return (
              <BasicColumnCell {...props} variant='tcms'>
                <div className={[classNames(), classNames('videocoach-cell')].join(' ')}>
                  {teamAndMediaToSetUserOptions.find(option => option.value === teamMediaId)?.label}
                </div>
              </BasicColumnCell>
            );
          },
        }),
        columnHelper.accessor('accessLog.HL', {
          header: props => (
            <UserLogColumnHeader
              {...props}
              translationKey={ITranslationKeys.loginCountTotalAndLast30Days}
              allowSortIcon
              color='blue'
              appLogo={<HokejLogicLogoIcon height={32} />}
            />
          ),
          cell: props => {
            const total = props.row.original.accessLog?.HL.total;
            const lastThrityDays = props.row.original.accessLog?.HL.inLastThirtyDays;

            return (
              <BasicColumnCell {...props} color='blue'>
                <div className={[classNames(), classNames('hl-login-count-cell')].join(' ')}>
                  {`${total ?? 0}; ${lastThrityDays ?? 0}`}
                </div>
              </BasicColumnCell>
            );
          },
          sortingFn: (a, b) =>
            sortUserLogColumns(a.original.accessLog?.HL, b.original.accessLog?.HL),
        }),
        columnHelper.accessor('accessLog.HL.lastAccessAt', {
          header: props => (
            <UserLogColumnHeader
              {...props}
              translationKey={ITranslationKeys.lastLogin}
              allowSortIcon
              color='blue'
            />
          ),
          cell: props => {
            const dateStr = props.row.original.accessLog?.HL.lastAccessAt;
            const date = dateStr ? formatDateByLanguage(new Date(dateStr), 'P') : dateStr;

            return (
              <BasicColumnCell {...props} color='blue'>
                <div className={[classNames(), classNames('last-hl-login-cell')].join(' ')}>
                  {date}
                </div>
              </BasicColumnCell>
            );
          },
        }),
        columnHelper.accessor('accessLog.HLEC', {
          header: props => (
            <UserLogColumnHeader
              {...props}
              translationKey={ITranslationKeys.loginCountTotalAndLast30Days}
              allowSortIcon
              appLogo={<HokejCoachLogoIcon height={32} />}
            />
          ),
          cell: props => {
            const total = props.row.original.accessLog?.HLEC.total;
            const lastThrityDays = props.row.original.accessLog?.HLEC.inLastThirtyDays;

            return (
              <BasicColumnCell {...props} color='slate'>
                <div className={[classNames(), classNames('ec-login-count-cell')].join(' ')}>
                  {`${total ?? 0}; ${lastThrityDays ?? 0}`}
                </div>
              </BasicColumnCell>
            );
          },
          sortingFn: (a, b) =>
            sortUserLogColumns(a.original.accessLog?.HLEC, b.original.accessLog?.HLEC),
        }),
        columnHelper.accessor('accessLog.HLEC.lastAccessAt', {
          header: props => (
            <UserLogColumnHeader
              {...props}
              translationKey={ITranslationKeys.lastLogin}
              allowSortIcon
            />
          ),
          cell: props => {
            const dateStr = props.row.original.accessLog?.HLEC.lastAccessAt;
            const date = dateStr ? formatDateByLanguage(new Date(dateStr), 'P') : dateStr;

            return (
              <BasicColumnCell {...props} color='slate'>
                <div className={[classNames(), classNames('last-ec-login-cell')].join(' ')}>
                  {date}
                </div>
              </BasicColumnCell>
            );
          },
        }),
        columnHelper.display({
          id: 'loginList',
          header: props => (
            <BasicColumnHeader
              {...props}
              translationKey={ITranslationKeys.loginList}
              allowSortIcon
              variant='tcms'
            />
          ),
          cell: props => {
            const handleLoginListClick = () => {
              const filterParams: IUrlFilterParams[] = [
                {
                  name: 'username',
                  value: props.row.original.username,
                },
              ];
              const pagesUrl = `/${INavigationPageKeys.tracking}/${INavigationTrackingContentKeys.logins}`;
              const urlToOpen = normalizeUrlWithFilterParams(pagesUrl, filterParams);
              window.open(urlToOpen, '_blank');
            };

            return (
              <BasicColumnCell {...props} variant='tcms'>
                <div
                  className={[classNames(), classNames('login-list-cell')].join(' ')}
                  onClick={handleLoginListClick}
                >
                  <PlayIcon />
                </div>
              </BasicColumnCell>
            );
          },
        }),
      ];

      return fixedColumns.concat(dynamicColumns);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamAndMediaToSetUserOptions],
  );

  return {
    columns,
    initialSorting,
  };
};
