import { ITranslationKeys } from '../i18n/types';
import { INavigationPath } from './types';

export const NAVIGATION_PATHS: INavigationPath[] = [
  {
    label: ITranslationKeys.players,
    to: '/players',
  },
  {
    label: ITranslationKeys.formations,
    to: '/formations',
  },
  {
    label: ITranslationKeys.videomaps,
    to: '/videomaps',
  },
  {
    label: ITranslationKeys.goalkeepers,
    to: '/goalkeepers',
  },
  {
    label: ITranslationKeys.games,
    to: '/games',
  },
  {
    label: ITranslationKeys.teams,
    to: '/teams',
  },
  {
    label: ITranslationKeys.accountManagement,
    to: '/account-management',
  },
];

export const ACCOUNT_MANAGEMENT_TABS = [
  ITranslationKeys.downloadsManagement,
  ITranslationKeys.hokejPlayerManagement,
  ITranslationKeys.changeOfPassword,
];
