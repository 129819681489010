import { FC } from 'react';

import { createClassNames } from '../../../utils';
import './Switch.styles.scss';

export interface ISwitchProps {
  /** Is the switch on? */
  checked: boolean;
  /** Function called when the switch is clicked. */
  onClick: () => void;
  /** If true, the switch is disabled. */
  disabled?: boolean;
}

const classNames = createClassNames('switch');

/**
 * Switch UI component for user interaction.
 */
export const Switch: FC<ISwitchProps> = ({ checked, disabled = false, onClick }) => {
  return (
    <div className={classNames({ disabled })} onClick={!disabled ? onClick : undefined}>
      <div className={classNames('toggle-wrapper', { checked })}>
        <span className={classNames('toggle-wrapper__selector', { checked })}></span>
      </div>
    </div>
  );
};
