import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { REQUIRED_ARRAY, notFilledOutOption, roleOptions } from '../../../constants';
import {
  changeIdentityPassword,
  createIdentity,
  getIdentities,
  teamAndMediaToSetUserOptionsSelector,
} from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { AddUserIcon, ChangePasswordIcon } from '../../../icons';
import { IMultiParamsVoid, INewIdentityForm, IRole, ISelectOption } from '../../../types';
import {
  changeRequestFailed,
  createClassNames,
  teamAndMediaOptionsByRole,
  teamOrMediaTypeByRole,
  validateEmail,
  validatePassword,
  validatePasswordLength,
} from '../../../utils';
import { Caption } from '../../Caption';
import { Input } from '../../Input';
import { SelectInput } from '../../SelectInput';
import { Modal, ModalButton } from '../../modalComponents';
import './UsersModal.styles.scss';

export interface IUsersModalProps {
  /** Is modal visible? */
  open: boolean;
  /** Function used to hide modal. */
  onClose: () => void;
  /** Decidec texts and request (add user / change password). */
  userId?: string;
}

const classNames = createClassNames('users-modal');

/** UsersModal component. */
export const UsersModal: FC<IUsersModalProps> = ({ open, onClose, userId }) => {
  const teamAndMediaToSetUserOptions = useAppSelector(teamAndMediaToSetUserOptionsSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { control, formState, handleSubmit, reset, watch, setValue } = useForm<INewIdentityForm>({
    defaultValues: {
      username: '',
      name: '',
      surname: '',
      password: '',
      role: roleOptions.find(role => role.value === IRole.user),
      teamOrMediaOption: null,
    },
    mode: 'onBlur',
  });

  const handleAddUserSubmit = () => {
    handleSubmit(({ username, password, name, surname, role, teamOrMediaOption }) => {
      const type = teamOrMediaOption
        ? teamOrMediaTypeByRole(teamOrMediaOption, role.value as IRole)
        : null;

      dispatch(
        createIdentity({
          username,
          password,
          name,
          surname,
          role: role.value as IRole,
          teamId: teamOrMediaOption && type && type === 'team' ? teamOrMediaOption.value : null,
          media: teamOrMediaOption && type && type === 'media' ? teamOrMediaOption.value : null,
        }),
      )
        .unwrap()
        .then(() => {
          toast.success(t(ITranslationKeys.userAdded), { toastId: ITranslationKeys.userAdded });
          onClose();
          dispatch(getIdentities());
        })
        .catch(err => console.log('[UsersModal]: Add user err:', err));
    })();
  };

  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordAgain, setNewPasswordAgain] = useState<string>('');

  const handleChangePassword = () => {
    const validation = validatePassword(newPassword, newPasswordAgain);

    if (validation === 'dont-match') {
      toast.error(t(ITranslationKeys.passwordsDontMatch), {
        toastId: ITranslationKeys.passwordsDontMatch,
      });
    } else if (validation === 'too-short') {
      toast.error(t(ITranslationKeys.passwordTooShort), {
        toastId: ITranslationKeys.passwordTooShort,
      });
    } else {
      dispatch(changeIdentityPassword({ identityId: userId!, newPassword: newPasswordAgain }))
        .unwrap()
        .then(() => {
          toast.success(t(ITranslationKeys.passwordChanged), {
            toastId: ITranslationKeys.passwordChanged,
          });
          onClose();
        })
        .catch((error: AxiosError) => {
          changeRequestFailed(
            t(ITranslationKeys.cantChangePasswordErrorMessage),
            ITranslationKeys.cantChangePasswordErrorMessage,
            error,
          );
        });
    }

    setNewPassword('');
    setNewPasswordAgain('');
  };

  const handleSetNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const handleSetNewPasswordAgain = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPasswordAgain(event.target.value);
  };

  const handleOnChangeRole = (
    option: ISelectOption,
    onChange: IMultiParamsVoid,
    prevOption: ISelectOption,
    teamOrMedia: string | undefined,
  ) => {
    onChange(option);
    if (teamOrMedia && option.value !== prevOption.value) {
      setValue('teamOrMediaOption', null);
    }
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const role = watch('role').value as IRole;
  const teamOrMedia = watch('teamOrMediaOption')?.value;

  return (
    <Modal
      open={open}
      size='small'
      modalButtons={
        <>
          <ModalButton label={ITranslationKeys.cancel} onClick={onClose} />
          <ModalButton
            label={userId ? ITranslationKeys.changePassword : ITranslationKeys.addUser}
            onClick={userId ? handleChangePassword : handleAddUserSubmit}
            disabled={!formState.isValid}
            fontWeight={'bold'}
          />
        </>
      }
      overflowVisible
    >
      <div className={classNames('header')}>
        <div className={classNames('header__icon')}>
          {userId ? <ChangePasswordIcon /> : <AddUserIcon />}
        </div>
        <h2>{t(userId ? ITranslationKeys.changeOfPassword : ITranslationKeys.addUser)}</h2>
      </div>
      {userId ? (
        <div className={classNames('body')}>
          <Input
            variant='filter'
            type='password'
            placeholder={t(ITranslationKeys.newPassword).toString()}
            value={newPassword}
            onChange={handleSetNewPassword}
          />
          <Input
            variant='filter'
            type='password'
            placeholder={t(ITranslationKeys.newPasswordAgain).toString()}
            value={newPasswordAgain}
            onChange={handleSetNewPasswordAgain}
          />
        </div>
      ) : (
        <div className={classNames('body')}>
          <div className={classNames('input-wrapper')}>
            <Caption label={ITranslationKeys.email} />
            <Controller
              name='username'
              control={control}
              rules={{
                required: REQUIRED_ARRAY,
                validate: {
                  wrongEmailFormat: value => validateEmail(value) ?? false,
                },
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Input
                  type='email'
                  value={value}
                  onChange={onChange}
                  placeholder={t(ITranslationKeys.email).toString()}
                  variant='filter'
                  error={!!error}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
          <div className={classNames('input-wrapper')}>
            <Caption label={ITranslationKeys.password} />
            <Controller
              name='password'
              control={control}
              rules={{
                required: REQUIRED_ARRAY,
                validate: {
                  wrongEmailFormat: value =>
                    validatePasswordLength(value) === 'ok' || ITranslationKeys.passwordTooShort,
                },
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Input
                  type='password'
                  value={value}
                  onChange={onChange}
                  placeholder={t(ITranslationKeys.password).toString()}
                  variant='filter'
                  error={!!error}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
          <div className={classNames('input-wrapper')}>
            <Caption label={ITranslationKeys.name} />
            <Controller
              name='name'
              control={control}
              rules={{ required: REQUIRED_ARRAY }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder={t(ITranslationKeys.name).toString()}
                  variant='filter'
                  error={!!error}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
          <div className={classNames('input-wrapper')}>
            <Caption label={ITranslationKeys.surname} />
            <Controller
              name='surname'
              control={control}
              rules={{ required: REQUIRED_ARRAY }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder={t(ITranslationKeys.surname).toString()}
                  variant='filter'
                  error={!!error}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
          <div className={classNames('input-wrapper')}>
            <Caption label={ITranslationKeys.role} />
            <Controller
              name='role'
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectInput
                  selected={value}
                  onChange={newValue => handleOnChangeRole(newValue, onChange, value, teamOrMedia)}
                  options={roleOptions}
                  variant='filter'
                />
              )}
            />
          </div>
          <div className={classNames('input-wrapper')}>
            <Caption label={getCaptionByRole(role)} />
            <Controller
              name='teamOrMediaOption'
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectInput
                  selected={value ?? notFilledOutOption}
                  onChange={onChange}
                  options={teamAndMediaOptionsByRole(teamAndMediaToSetUserOptions, role)}
                  variant='filter'
                  disabled={role === IRole.user || role === IRole.client}
                  dropUp={role === IRole.team}
                />
              )}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

const getCaptionByRole = (role: IRole) => {
  if (role !== IRole.media && role !== IRole.team) return ITranslationKeys.teamMedia;
  if (role === IRole.media) return ITranslationKeys.media;
  return ITranslationKeys.team;
};
