import { COLORS } from '../../../constants';

const tickRectHeight = 152;

export const TrendTick = (props: any) => {
  const [date, opponent, score, won]: string[] = props.payload.value.split(';');
  const moreMatches = props.moreThanSeason;

  return (
    <g transform={`translate(${props.x - 10},${props.y})`}>
      <rect fill={COLORS.coolGray[10]} width={22} height={tickRectHeight} rx='4'></rect>
      <text
        x={moreMatches ? -(tickRectHeight - 40) : -(tickRectHeight - 8)}
        y={0}
        dy={moreMatches ? 16 : 14}
        fontSize={moreMatches ? '14px' : '10px'}
        textAnchor='start'
        fill={COLORS.coolGray[100]}
        transform={'rotate(-90)'}
      >
        {date}
      </text>
      {!moreMatches && (
        <text
          x={-(tickRectHeight - 80)}
          y={0}
          dy={14}
          fontSize='10px'
          textAnchor='start'
          fill={COLORS.coolGray[100]}
          transform={'rotate(-90)'}
          fontWeight='bold'
        >
          {opponent}
        </text>
      )}
      {!moreMatches && (
        <text
          x={-8}
          y={0}
          dy={14}
          fontSize='10px'
          textAnchor='end'
          fill={won === 'won' ? COLORS.green[40] : COLORS.red[60]}
          transform={'rotate(-90)'}
          fontWeight='bold'
        >
          {score}
        </text>
      )}
    </g>
  );
};
