import { SortDirection } from '@tanstack/react-table';
import queryString from 'query-string';

import { IAccessLog, IEventScreenLog, ILogItemsWithTotal, ISharedLogParams } from '../../types';
import { api } from './api';
import { parseAccessLogData, parseEventScreenLogCoachData, parseEventScreenLogData } from './utils';

interface IAccessAndScreenLogParams {
  role?: string;
  team?: string[];
  orderDirection?: SortDirection;
}

export interface IGetAccessLogParams extends ISharedLogParams, IAccessAndScreenLogParams {
  app?: string;
}

export interface IGetScreenLogParams extends ISharedLogParams, IAccessAndScreenLogParams {
  tab?: string;
}

const DEFAULT_LIMIT = 20;

export const logApi = api.injectEndpoints({
  endpoints: builder => ({
    getAccessLog: builder.query<ILogItemsWithTotal<IAccessLog>, IGetAccessLogParams>({
      query: ({ limit = DEFAULT_LIMIT, ...restParams }) =>
        '/log/access?' +
        queryString.stringify({ limit, ...restParams }, { arrayFormat: 'bracket' }),
      transformResponse: parseAccessLogData,
      serializeQueryArgs: ({ queryArgs: { offset, ...restArgs } }) => restArgs,
      forceRefetch: ({ currentArg, previousArg }) => sharedForceRefetch(currentArg, previousArg),
      merge: (currentData = { items: [], totalCount: 0 }, newData) => ({
        items: [...currentData.items, ...newData.items],
        totalCount: newData.totalCount,
      }),
    }),
    getScreenLogLogic: builder.query<ILogItemsWithTotal<IEventScreenLog>, IGetScreenLogParams>({
      query: ({ limit = DEFAULT_LIMIT, ...restParams }) =>
        '/log/screen?' +
        queryString.stringify({ limit, ...restParams }, { arrayFormat: 'bracket' }),
      transformResponse: parseEventScreenLogData,
      serializeQueryArgs: ({ queryArgs: { offset, ...restArgs } }) => restArgs,
      forceRefetch: ({ currentArg, previousArg }) => sharedForceRefetch(currentArg, previousArg),
      merge: (currentData = { items: [], totalCount: 0 }, newData) => ({
        items: [...currentData.items, ...newData.items],
        totalCount: newData.totalCount,
      }),
    }),
    getScreenLogCoach: builder.query<ILogItemsWithTotal<IEventScreenLog>, IGetScreenLogParams>({
      query: ({ limit = DEFAULT_LIMIT, ...restParams }) =>
        '/log/extra-coach-screen?' +
        queryString.stringify({ limit, ...restParams }, { arrayFormat: 'bracket' }),
      transformResponse: parseEventScreenLogCoachData,
      serializeQueryArgs: ({ queryArgs: { offset, ...restArgs } }) => restArgs,
      forceRefetch: ({ currentArg, previousArg }) => sharedForceRefetch(currentArg, previousArg),
      merge: (currentData = { items: [], totalCount: 0 }, newData) => ({
        items: [...currentData.items, ...newData.items],
        totalCount: newData.totalCount,
      }),
    }),
  }),
});

export const { useGetAccessLogQuery, useGetScreenLogLogicQuery, useGetScreenLogCoachQuery } =
  logApi;

const sharedForceRefetch = (
  currentArg?: IGetAccessLogParams,
  previousArg?: IGetAccessLogParams,
) => {
  if (!previousArg || !currentArg) {
    return false;
  }
  return currentArg?.offset !== previousArg?.offset;
};
