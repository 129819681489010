import type { AppStore } from 'app/store';
import i18n from 'i18next';
import { toast } from 'react-toastify';

import { logout } from '../features';
import { ITranslationKeys } from '../i18n/types';
import { isTokenExpired } from '../utils';
import { axiosInstance } from './axiosInstance';

export const setupAxiosInstance = (store: AppStore) => {
  const { dispatch, getState } = store;

  axiosInstance.interceptors.request.use(
    config => {
      const { token, loginDate } = getState().auth;

      if (isTokenExpired(loginDate)) {
        dispatch(logout());
        toast.error(i18n.t(ITranslationKeys.sessionExpired), { toastId: 'tokenExpired' });
      }
      if (!config.headers.Authorization && token && token.access_token && token.token_type) {
        config.headers.Authorization = `${token.token_type} ${token.access_token}`;
      }

      return config;
    },
    error => Promise.reject(error),
  );
};
