import { FC } from 'react';

import { TabItem } from '..';
import { ITranslationKeys } from '../../i18n/types';
import { createClassNames } from '../../utils';
import './SubNavigation.styles.scss';

export interface ISubNavigationProps {
  /**
   * Current tab.
   */
  activeTab: ITranslationKeys;
  /**
   * Sets current tab onClick
   */
  setActiveTab: (value: ITranslationKeys) => void;
  /**
   * Array of all tabs.
   */
  tabs: ITranslationKeys[];
}

const classNames = createClassNames('sub-navigation');

/**
 * SubNavigation UI component.
 */
export const SubNavigation: FC<ISubNavigationProps> = ({ activeTab, setActiveTab, tabs }) => {
  return (
    <div className={classNames()}>
      {tabs.map(tab => (
        <TabItem
          key={tab}
          label={tab}
          isActive={tab === activeTab}
          onClick={() => setActiveTab(tab)}
        />
      ))}
    </div>
  );
};
