import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { TRANSLATION_CZ } from './cz';
import { TRANSLATION_EN } from './en';

const language = localStorage.getItem('language') || 'cz';

i18n.use(initReactI18next).init({
  debug: false,
  lng: language,
  fallbackLng: 'cz',
  resources: {
    en: {
      translation: TRANSLATION_EN,
    },
    cz: {
      translation: TRANSLATION_CZ,
    },
  },
});

export default i18n;
