import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Loading } from '../../../../components';
import { DEFAULT_EVENT_SCREEN_LOG_FILTER_FORM_VALUES } from '../../../../constants';
import { getIdentities, selectIdentity } from '../../../../features';
import { useHandleOnSubmitWithValues } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { IEventScreenLogFilterForm } from '../../../../types';
import { createClassNames, filterEnabledRoles } from '../../../../utils';
import './EventLogPage.styles.scss';
import { EventLogFilter, EventLogTableCoach, EventLogTableLogic } from './components';

const classNames = createClassNames('event-log-page');

export const EventLogPage = () => {
  const { identities, isLoading, hasBeenIdentitiesLoaded, error } = useAppSelector(selectIdentity);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [pageLogic, setPageLogic] = useState(0);
  const [pageCoach, setPageCoach] = useState(0);

  const methods = useForm<IEventScreenLogFilterForm>({
    defaultValues: DEFAULT_EVENT_SCREEN_LOG_FILTER_FORM_VALUES,
    mode: 'onBlur',
  });

  const { submitValues, handleOnSubmit } = useHandleOnSubmitWithValues(
    methods,
    DEFAULT_EVENT_SCREEN_LOG_FILTER_FORM_VALUES,
  );

  useEffect(() => {
    if (!hasBeenIdentitiesLoaded) {
      dispatch(getIdentities());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitForm = () => {
    if (pageLogic !== 0) {
      setPageLogic(0);
    }
    if (pageCoach !== 0) {
      setPageCoach(0);
    }
    handleOnSubmit();
  };

  if (isLoading) {
    return (
      <div className={classNames()}>
        <Loading />
      </div>
    );
  }

  if (error) {
    return <div className='content-info-box'>{t(ITranslationKeys.defaultErrorMessage)}</div>;
  }

  const filteredIdentities = identities.filter(identity => filterEnabledRoles(identity));

  return (
    <div className={classNames()}>
      <FormProvider {...methods}>
        <form noValidate>
          <EventLogFilter identities={filteredIdentities} onSubmit={onSubmitForm} />
        </form>
      </FormProvider>
      <div className={classNames('content')}>
        <EventLogTableLogic submitValues={submitValues} page={pageLogic} setPage={setPageLogic} />
        <EventLogTableCoach submitValues={submitValues} page={pageCoach} setPage={setPageCoach} />
      </div>
    </div>
  );
};
