import { FC } from 'react';

import { PlayIcon } from '../../../icons';
import { IVideoInfo } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { IGoalsOverviewData } from '../../types';
import './GoalsOverviewRow.styles.scss';

interface IGoalsOverviewRowProps {
  /** Contains all needed data about the goal. */
  goalData: IGoalsOverviewData;
  /** Decides on whis side is data rendered. */
  team: 'home' | 'away';
  /** Number of goals scored by home team. */
  homeGoals: number;
  /** Number of goals scored by away team. */
  awayGoals: number;
  /** Applies specific style for first row in list. */
  isFirstRow?: boolean;
  /** Fired when user clicks on the play icon. */
  onPlayClick: (array: IVideoInfo[]) => void;
}

const classNames = createClassNames('goals-overview-row');

/** GoalsOverviewRow UI component. */
export const GoalsOverviewRow: FC<IGoalsOverviewRowProps> = ({
  goalData,
  team,
  homeGoals,
  awayGoals,
  isFirstRow = false,
  onPlayClick,
}) => {
  const { assists, shot, player, time, countOfPlayers } = goalData;

  return (
    <div className={classNames({ away: team === 'away' })}>
      <div className={classNames('data')}>
        <p className={classNames('data__player')}>
          <strong>{player}</strong>
          {assists.length > 0 && <>&nbsp;{`(${assists.join(', ')})`}</>}
        </p>
        <p className={classNames('data__count-of-players')}>{countOfPlayers}</p>
        <p className={classNames('data__time')}>
          <strong>{time}</strong>
        </p>
        {shot && <PlayIcon onClick={() => onPlayClick([shot])} />}
      </div>
      <div
        className={classNames('score', {
          ...transformObjectKeysToKebabCase({
            isFirstRow,
          }),
        })}
      >
        {homeGoals}:{awayGoals}
      </div>
      <div className={classNames('data')}></div>
    </div>
  );
};
