import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { createUserDataset, selectDataSettingsFilter } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { CloseBlueIcon } from '../../../icons';
import { IDatasetModalContentProps } from '../../../types';
import { createClassNames, createUserDatasetMetricRecordFromSelected } from '../../../utils';
import { Button } from '../../Button';
import { Input } from '../../Input';
import './NewDataset.styles.scss';

export interface INewDatasetProps extends IDatasetModalContentProps {}

const classNames = createClassNames('new-dataset');

export const NewDataset: FC<INewDatasetProps> = ({ entity, onClose }) => {
  const { selectedMetrics } = useAppSelector(selectDataSettingsFilter);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [newDatasetName, setNewDatasetName] = useState<string>('');
  const [newDatasetNameError, setNewDatasetNameError] = useState(false);

  const handleOnChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNewDatasetName(value);
    setNewDatasetNameError(value === '' || value.length > 80);
  };

  const handleOnSubmit = () => {
    const datasetMetrics = createUserDatasetMetricRecordFromSelected(selectedMetrics);

    dispatch(createUserDataset({ name: newDatasetName, datasetMetrics, entity }))
      .unwrap()
      .then(() => {
        toast.success(t(ITranslationKeys.datasetSavedSuccessfully), {
          toastId: ITranslationKeys.datasetSavedSuccessfully,
        });
        onClose();
      })
      .catch(error => {
        toast.error(t(ITranslationKeys.datasetSaveFailed), {
          toastId: ITranslationKeys.datasetSaveFailed,
        });
        console.error('[NewDataset]: Add user dataset error:', error);
      });
  };

  return (
    <div className={classNames()}>
      <div className={classNames('header')}>
        <span>{t(ITranslationKeys.saveDataset)}</span>
        <CloseBlueIcon className={classNames('close')} onClick={onClose} />
      </div>
      <div className={classNames('content')}>
        {t(ITranslationKeys.nameYourDataset)}:
        <Input
          value={newDatasetName}
          onChange={handleOnChangeName}
          placeholder={t(ITranslationKeys.name).toString()}
          variant='filter'
          error={newDatasetNameError}
        />
        <Button
          size='small'
          label={ITranslationKeys.saveDataset}
          onClick={handleOnSubmit}
          disabled={newDatasetName === '' || newDatasetNameError}
        />
      </div>
    </div>
  );
};
