import { axiosInstance } from '../services/axiosInstance';
import { IBugReportQueryParams } from '../types';

export const postBugReport = async ({
  issueType,
  description,
  eventType,
  eventTime,
  match,
}: IBugReportQueryParams) => {
  const response = await axiosInstance.post('issue', {
    issueType,
    description,
    eventType,
    eventTime,
    match,
  });

  return response;
};
