import { IPass, IPassDTO } from '../../types';

export const parseGetPassesData = (data: IPassDTO[]): IPass[] =>
  data
    .map<IPass | undefined>(pass => {
      const { match, player, homeTeam, awayTeam, ...restData } = pass;

      if (!pass.shot) return undefined;

      return {
        ...restData,
        matchId: match,
        playerId: player,
        awayTeamId: awayTeam,
        homeTeamId: homeTeam,
        shot: {
          ...pass.shot,
          shotDanger: pass.shot.shotDanger.toLowerCase(),
        },
      };
    })
    .filter((item): item is IPass => !!item);
