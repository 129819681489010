import { FC } from 'react';

import { ArrowNarrowIcon, ArrowSwitchIcon } from '../../icons';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './ScrollButton.styles.scss';

export interface IScrollButtonProps {
  /** Function called on click. */
  onClick: () => void;
  /** Does scroll button component arrow points to left? */
  isLeft?: boolean;
  /** Variant of scroll button component. */
  variant?: 'default' | 'tcms';
  /** Is scroll button component disabled? */
  disabled?: boolean;
}

const classNames = createClassNames('scroll-button');

/** Clickable arrow button for scrolling. */
export const ScrollButton: FC<IScrollButtonProps> = ({
  onClick,
  isLeft = false,
  variant = 'default',
  disabled = false,
}) => (
  <div
    className={classNames({
      ...transformObjectKeysToKebabCase({
        variant,
        isLeft,
        disabled,
      }),
    })}
    onClick={onClick}
  >
    {variant === 'tcms' ? <ArrowNarrowIcon /> : <ArrowSwitchIcon />}
  </div>
);
