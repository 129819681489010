import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Loading } from '../../../../components';
import { DEFAULT_ACCESS_LOG_FILTER_FORM_VALUES } from '../../../../constants';
import { getIdentities, selectIdentity } from '../../../../features';
import { useHandleOnSubmitWithValues } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { IAccessLogFilterForm } from '../../../../types';
import { createClassNames, filterEnabledRoles, parseUrlUsername } from '../../../../utils';
import './AccessLogPage.styles.scss';
import { AccessLogFilter, AccessLogTableCoach, AccessLogTableLogic } from './components';

const classNames = createClassNames('access-log-page');

export const AccessLogPage = () => {
  const { identities, isLoading, hasBeenIdentitiesLoaded, error } = useAppSelector(selectIdentity);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const [pageLogic, setPageLogic] = useState(0);
  const [pageCoach, setPageCoach] = useState(0);

  const methods = useForm<IAccessLogFilterForm>({
    defaultValues: DEFAULT_ACCESS_LOG_FILTER_FORM_VALUES,
    mode: 'onBlur',
  });

  const { submitValues, handleOnSubmit } = useHandleOnSubmitWithValues(
    methods,
    DEFAULT_ACCESS_LOG_FILTER_FORM_VALUES,
  );

  useEffect(() => {
    if (!hasBeenIdentitiesLoaded) {
      dispatch(getIdentities());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredIdentities = useMemo(
    () => identities.filter(identity => filterEnabledRoles(identity)),
    [identities],
  );

  const selectedUsernameFromParams = useMemo(
    () => parseUrlUsername(searchParams, filteredIdentities),
    [searchParams, filteredIdentities],
  );

  const onSubmitForm = () => {
    if (pageLogic !== 0) {
      setPageLogic(0);
    }
    if (pageCoach !== 0) {
      setPageCoach(0);
    }
    handleOnSubmit();
  };

  if (isLoading) {
    return (
      <div className={classNames()}>
        <Loading />
      </div>
    );
  }

  if (error) {
    return <div className='content-info-box'>{t(ITranslationKeys.defaultErrorMessage)}</div>;
  }

  return (
    <div className={classNames()}>
      <FormProvider {...methods}>
        <form noValidate>
          <AccessLogFilter
            identities={filteredIdentities}
            onSubmit={onSubmitForm}
            selectedUsernameFromParams={selectedUsernameFromParams}
          />
        </form>
      </FormProvider>
      <div className={classNames('content')}>
        <AccessLogTableLogic
          submitValues={submitValues}
          page={pageLogic}
          setPage={setPageLogic}
          selectedUsernameFromParams={selectedUsernameFromParams}
        />
        <AccessLogTableCoach
          submitValues={submitValues}
          page={pageCoach}
          setPage={setPageCoach}
          selectedUsernameFromParams={selectedUsernameFromParams}
        />
      </div>
    </div>
  );
};
