import { useAppSelector } from '../../../app/hooks';
import {
  CountOfPlayersController,
  PartsController,
  SeasonsController,
  TeamController,
} from '../../../components';
import { selectMainFilter } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import {
  FaceoffsIcon,
  HeatmapIcon,
  PassesIcon,
  ShootoutsIcon,
  ShotsIcon,
  ZoneEntriesIcon,
  ZoneExistsIcon,
} from '../../../icons';
import { INavigationContentKeys, IPageConfig } from '../../../types';
import { getNotSelectedTeamValidationObject } from '../../../utils';
import { FaceoffsContent } from './FaceoffsContent';
import { HeatmapContent } from './HeatmapContent';
import { PassesContent } from './PassesContent';
import { ShootoutsContent } from './ShootoutsContent';
import { ShotsContent } from './ShotsContent';
import { ZoneEntriesContent } from './ZoneEntriesContent';
import { ZoneExitsContent } from './ZoneExitsContent';

/** All tabs has same content filter, so it is in constant. */
const contentFilterTemplate = (
  <>
    <SeasonsController required />
    <PartsController required />
    <TeamController
      rules={{
        validate: getNotSelectedTeamValidationObject(ITranslationKeys.pleaseSelectTeam),
      }}
      required
    />
    <CountOfPlayersController />
  </>
);

/** Custom hook returns config for Videomaps tabs and widgets. */
export const usePageConfig = (): IPageConfig => {
  const { selectedTeam } = useAppSelector(selectMainFilter);
  const disableLoadDataButton = !selectedTeam || selectedTeam.value === 'all';

  return {
    baseFilter: (
      <>
        <SeasonsController required />
        <PartsController required />
        <TeamController required />
        <CountOfPlayersController />
      </>
    ),
    tabsConfig: [
      {
        id: INavigationContentKeys.shots,
        name: ITranslationKeys.shots,
        icon: <ShotsIcon />,
        description: ITranslationKeys.shotsDescription,
        ContentComponent: ShotsContent,
        contentFilter: contentFilterTemplate,
        disableLoadDataButton,
      },
      {
        id: INavigationContentKeys.heatmap,
        name: ITranslationKeys.heatmap,
        icon: <HeatmapIcon />,
        description: ITranslationKeys.heatmapDescription,
        ContentComponent: HeatmapContent,
        contentFilter: contentFilterTemplate,
        disableLoadDataButton,
      },
      {
        id: INavigationContentKeys.passes,
        name: ITranslationKeys.passes,
        icon: <PassesIcon />,
        description: ITranslationKeys.passesDescription,
        ContentComponent: PassesContent,
        contentFilter: contentFilterTemplate,
        disableLoadDataButton,
      },
      {
        id: INavigationContentKeys.faceoffs,
        name: ITranslationKeys.faceoffs,
        icon: <FaceoffsIcon />,
        description: ITranslationKeys.faceoffsDescription,
        ContentComponent: FaceoffsContent,
        contentFilter: contentFilterTemplate,
        disableLoadDataButton,
      },
      {
        id: INavigationContentKeys.zoneEntries,
        name: ITranslationKeys.zoneEntries,
        icon: <ZoneEntriesIcon />,
        description: ITranslationKeys.zoneEntriesDescription,
        ContentComponent: ZoneEntriesContent,
        contentFilter: contentFilterTemplate,
        disableLoadDataButton,
      },
      {
        id: INavigationContentKeys.zoneExits,
        name: ITranslationKeys.zoneExits,
        icon: <ZoneExistsIcon />,
        description: ITranslationKeys.zoneExitsDescription,
        ContentComponent: ZoneExitsContent,
        contentFilter: contentFilterTemplate,
        disableLoadDataButton,
      },
      {
        id: INavigationContentKeys.shootouts,
        name: ITranslationKeys.shootouts,
        icon: <ShootoutsIcon />,
        description: ITranslationKeys.shootoutsPlayersDescription,
        ContentComponent: ShootoutsContent,
        contentFilter: contentFilterTemplate,
        disableLoadDataButton,
      },
    ],
  };
};
