import { combineReducers } from '@reduxjs/toolkit';

import { gamesSlice } from './games';
import { gamesReportSlice } from './gamesReport';
import { gamesTablesSlice } from './gamesTables';

export * from './games';
export * from './gamesReport';
export * from './gamesTables';

export const gamesReducers = combineReducers({
  games: gamesSlice.reducer,
  gamesReport: gamesReportSlice.reducer,
  gamesTables: gamesTablesSlice.reducer,
});
