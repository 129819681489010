import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { INavigationState } from '../../types';

const initialState: INavigationState = {
  triggerContentReloadValue: 0,
  isLoadingMinimumInitialData: true,
  isLoadingAllInitialData: true,
  disableMetricVideo: false,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    triggerContentReload: state => {
      state.triggerContentReloadValue = new Date().getTime();
    },
    endLoadingMinimumInitialData: state => {
      state.isLoadingMinimumInitialData = false;
    },
    endLoadingAllInitialData: state => {
      state.isLoadingAllInitialData = false;
    },
    setDisableMetricVideo: (state, action: PayloadAction<boolean>) => {
      state.disableMetricVideo = action.payload;
    },
    resetNavigation: () => initialState,
  },
});

export const {
  triggerContentReload,
  endLoadingMinimumInitialData,
  endLoadingAllInitialData,
  setDisableMetricVideo,
  resetNavigation,
} = navigationSlice.actions;

export const navigationReducer = navigationSlice.reducer;
