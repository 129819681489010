import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IGoalkeepersNetZonesState, INetZonesGoalkeeperRecord } from '../../types';
import {
  getGoalkeeperNetZonesCardInfo,
  getGoalkeepersNetZoneStats,
} from './goalkeepersNetZonesActions';

const initialNetZonesGoalkeeperItems: INetZonesGoalkeeperRecord = {
  '0': { id: '0' },
  '1': { id: '1' },
  '2': { id: '2' },
  '3': { id: '3' },
};

const initialState: IGoalkeepersNetZonesState = {
  netZonesGoalkeeperItems: initialNetZonesGoalkeeperItems,
};

export const goalkeepersNetZonesSlice = createSlice({
  name: 'goalkeepersNetZones',
  initialState,
  reducers: {
    setNetZonesGoalkeeperItems: (state, action: PayloadAction<INetZonesGoalkeeperRecord>) => {
      state.netZonesGoalkeeperItems = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // Next action case
      .addCase(getGoalkeeperNetZonesCardInfo.pending, (state, action) => {
        const goalkeeperId = action.meta.arg.playerUuid;
        const goalkeeperIndex = action.meta.arg.goalkeeperIndex;

        if (goalkeeperId && goalkeeperIndex !== undefined) {
          state.netZonesGoalkeeperItems[goalkeeperIndex] = {
            id: goalkeeperId,
            isLoadingGoalkeeperStats: true,
          };
        }
      })
      .addCase(getGoalkeeperNetZonesCardInfo.fulfilled, (state, action) => {
        const goalkeeperId = action.payload.player;
        const goalkeeperIndex = action.meta.arg.goalkeeperIndex;

        if (goalkeeperId && goalkeeperIndex !== undefined) {
          const netZonesGoalkeeperItem = state.netZonesGoalkeeperItems[goalkeeperIndex.toString()];
          netZonesGoalkeeperItem.id = goalkeeperId;
          netZonesGoalkeeperItem.goalkeeperStats = action.payload.stats;
          netZonesGoalkeeperItem.isLoadingGoalkeeperStats = false;
        }
      })
      .addCase(getGoalkeeperNetZonesCardInfo.rejected, (state, action) => {
        const goalkeeperIndex = action.meta.arg.goalkeeperIndex;

        if (goalkeeperIndex !== undefined) {
          const netZonesGoalkeeperItem = state.netZonesGoalkeeperItems[goalkeeperIndex];
          netZonesGoalkeeperItem.goalkeeperStats = undefined;
          netZonesGoalkeeperItem.isLoadingGoalkeeperStats = false;
          netZonesGoalkeeperItem.error = action.error.message;
        }
        console.error('Error:', action.error.message);
      })
      // Next action case
      .addCase(getGoalkeepersNetZoneStats.pending, (state, action) => {
        const goalkeeperId = action.meta.arg.goalkeeperId;
        const goalkeeperIndex = action.meta.arg.goalkeeperIndex;
        if (goalkeeperId && goalkeeperIndex !== undefined) {
          state.netZonesGoalkeeperItems[goalkeeperIndex] = {
            id: goalkeeperId,
            isLoadingTeamStats: true,
          };
        }
      })
      .addCase(getGoalkeepersNetZoneStats.fulfilled, (state, action) => {
        const goalkeeperId = action.meta.arg.goalkeeperId;
        const goalkeeperIndex = action.meta.arg.goalkeeperIndex;

        if (goalkeeperId && goalkeeperIndex !== undefined) {
          const netZonesGoalkeeperItem = state.netZonesGoalkeeperItems[goalkeeperIndex.toString()];
          netZonesGoalkeeperItem.id = goalkeeperId;
          const goalkeeperStats = action.payload.find(player => player.id === goalkeeperId);
          if (goalkeeperStats) {
            netZonesGoalkeeperItem.teamStats = goalkeeperStats.stats;
          } else {
            netZonesGoalkeeperItem.error = 'Player not found';
          }
          netZonesGoalkeeperItem.isLoadingTeamStats = false;
        }
      })
      .addCase(getGoalkeepersNetZoneStats.rejected, (state, action) => {
        const goalkeeperIndex = action.meta.arg.goalkeeperIndex;

        if (goalkeeperIndex !== undefined) {
          const netZonesGoalkeeperItem = state.netZonesGoalkeeperItems[goalkeeperIndex];
          netZonesGoalkeeperItem.teamStats = undefined;
          netZonesGoalkeeperItem.isLoadingTeamStats = false;
          netZonesGoalkeeperItem.error = action.error.message;
        }
        console.error('Error:', action.error.message);
      });
  },
});

export const { setNetZonesGoalkeeperItems } = goalkeepersNetZonesSlice.actions;

export const goalkeepersNetZonesReducer = goalkeepersNetZonesSlice.reducer;
