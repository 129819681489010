import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { HockeySticksIcon } from '../../icons';
import { createClassNames } from '../../utils';
import './NoDataGames.styles.scss';

export interface INoDataGamesProps {
  /** Message to display to user. */
  message?: ITranslationKeys;
}

const classNames = createClassNames('no-data-games');

export const NoDataGames: FC<INoDataGamesProps> = ({
  message = ITranslationKeys.noGamesToDisplay,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      <HockeySticksIcon className={classNames('icon')} />
      <span className={classNames('message')}>{t(message)}</span>
    </div>
  );
};
