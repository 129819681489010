import { useMemo } from 'react';

import { ILogHokejPlayer, ILogHokejPlayerTableData } from '../../../../../../types';

export const useDataForTable = (
  originalData: ILogHokejPlayer[] | undefined,
): ILogHokejPlayerTableData[] => {
  const data = useMemo(() => {
    if (!originalData) return [];

    const sortedOriginalTopFiveData = [...originalData]
      .sort((a, b) => {
        const aRate = (a.openedNotificationCount / a.notificationCount) * 100;
        const bRate = (b.openedNotificationCount / b.notificationCount) * 100;

        if (isNaN(aRate) && isNaN(bRate)) return 0;

        if (aRate < bRate) return 1;
        if (aRate > bRate) return -1;

        return a.openedNotificationCount < b.openedNotificationCount ? 1 : -1;
      })
      .slice(0, 5);

    return sortedOriginalTopFiveData.map<ILogHokejPlayerTableData>(
      ({ registrationNumber, name, surname, team, notificationCount, openedNotificationCount }) => {
        const clickthroughRate = (openedNotificationCount / notificationCount) * 100;
        const percentage = !isNaN(clickthroughRate) ? clickthroughRate : 0;

        return {
          player: {
            registrationNumber,
            name,
            surname,
          },
          team: team || '',
          value: {
            mainValue: percentage,
            notificationCount,
            openedNotificationCount,
          },
        };
      },
    );
  }, [originalData]);

  return data;
};
