import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { createClassNames } from '../../../utils';
import './ChartLegend.styles.scss';

interface IChartLegendProps {}

const classNames = createClassNames('chart-legend');

export const ChartLegend: FC<IChartLegendProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      <div className={classNames('item')}>
        <div className={classNames('circle', { team: true })}></div>
        {t(ITranslationKeys.teams)}
      </div>
      <div className={classNames('item')}>
        <div className={classNames('circle', { media: true })}></div>
        {t(ITranslationKeys.media)}
      </div>
      <div className={classNames('item')}>
        <div className={classNames('circle')}></div>
        {t(ITranslationKeys.others)}
      </div>
    </div>
  );
};
