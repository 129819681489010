import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import {
  createClassNames,
  getColorByPercent,
  secondsToMinutesAndSeconds,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import { CardItem, ICardItemProps } from '../CardItem';
import './Card.styles.scss';

interface ICardProps {
  /**
   * CardItem items to be shown
   */
  items: ICardItemProps[];
  /**
   * Time on the ice
   */
  toi: number | undefined;
  /**
   * Label of the card (not required)
   */
  label?: string;
  /**
   * Percentile of the card showed in the right side (not required)
   */
  percentile?: number;
  /**
   * If true, the number legend is shown
   */
  showLegend?: boolean;
  /**
   * If true, the time on ice is always shown
   */
  showToi?: boolean;
}

const classNames = createClassNames('card');

export const Card: FC<ICardProps> = ({ items, label, percentile, showLegend, showToi, toi }) => {
  const { t } = useTranslation();

  const isToiShown = () => {
    if (!toi) {
      return (
        <div className={classNames('header__label__toi')}>
          <strong>TOI:</strong>&nbsp;{t(ITranslationKeys.insufficientMinutes)}
        </div>
      );
    }

    if (showToi) {
      return (
        <div className={classNames('header__label__toi')}>
          <strong>TOI:</strong>&nbsp;{secondsToMinutesAndSeconds(toi)}
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          hasHeader: !!label,
        }),
      })}
      data-testid='card'
    >
      <div className={classNames('header')}>
        <div className={classNames('header__label')}>
          {label && <h3>{label}</h3>}
          {isToiShown()}
        </div>
        <div className={classNames('header__percentile-box')}>
          {percentile !== undefined && (
            <span
              className={classNames('header__percentile-box__percentile', {
                color: getColorByPercent(percentile),
              })}
            >
              {percentile}
            </span>
          )}
        </div>
      </div>
      {items.map((item, index) => (
        <CardItem
          key={index}
          label={item.label}
          name={item.name}
          metricValue={item.metricValue}
          percent={item.percent}
          rank={item.rank}
          color={item.color}
          showCenter={item.showCenter}
          reversed={item.reversed}
          showTooltip={item.showTooltip}
          showHash={item.showHash}
        />
      ))}
      {showLegend && (
        <div className={classNames('legend')}>
          <span>0</span>
          <span className={classNames('middle-value')}>50</span>
          <span>100</span>
        </div>
      )}
    </div>
  );
};
