import { useMemo } from 'react';

import { useAppSelector } from '../../../../../../app/hooks';
import { selectGames, selectTeams } from '../../../../../../features';
import { useFilteredMetricsForTable } from '../../../../../../hooks';
import {
  IEntity,
  IGameReportTeams,
  IGameTeamsTableData,
  IMetricWithDatasetMetric,
  IStats,
  ITeam,
  ITeamRecord,
} from '../../../../../../types';
import { getMetricsWithValue } from '../../../../../../utils';

const getGameTeam = (
  team: ITeam,
  stats: IStats,
  metrics: IMetricWithDatasetMetric[],
  homeAway: 'home' | 'away',
): IGameTeamsTableData => {
  const metricsWithValue = getMetricsWithValue(metrics, stats);

  return {
    entityId: team.id,
    teamId: team.id,
    teamName: team.name,
    toi: stats.toi,
    stats: metricsWithValue,
    homeAway,
  };
};

const getTableData = (
  teams: IGameReportTeams,
  teamsRecord: ITeamRecord,
  metrics: IMetricWithDatasetMetric[],
): IGameTeamsTableData[] => {
  const homeTeam = teamsRecord[teams.homeTeam.id];
  const awayTeam = teamsRecord[teams.awayTeam.id];

  return [
    getGameTeam(homeTeam, teams.homeTeam.stats, metrics, 'home'),
    getGameTeam(awayTeam, teams.awayTeam.stats, metrics, 'away'),
  ];
};

export const useDataForTable = () => {
  const teamsState = useAppSelector(selectTeams);
  const { gamesReport } = useAppSelector(selectGames);
  const { teams } = gamesReport;

  const getFilteredMetrics = useFilteredMetricsForTable();

  const data: IGameTeamsTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(IEntity.teams);

    if (filteredMetrics && teams) {
      return getTableData(teams, teamsState.byId, filteredMetrics);
    }

    return [];
  }, [teams, teamsState.byId, getFilteredMetrics]);

  return data;
};
