import { FC } from 'react';

import { ITranslationKeys } from '../../../i18n/types';
import { NeutralSelectedIcon, WarningIcon } from '../../../icons';
import { createClassNames } from '../../../utils';
import { Button } from '../../Button';
import './ConfirmDialog.styles.scss';

const getIcon = (type: IConfirmDialogTypes) => {
  switch (type) {
    case 'warning':
      return <WarningIcon className={classNames('icon', { type })} />;
    case 'danger':
      return <WarningIcon className={classNames('icon', { type })} />;
    default:
      return <NeutralSelectedIcon className={classNames('icon', { type })} />;
  }
};

export type IConfirmDialogTypes = 'info' | 'warning' | 'danger';

export interface IConfirmDialogProps {
  /** Translated message to display. */
  message: string;
  /** Function called after user clicks on cancel button. */
  onCancel: () => void;
  /** Type of dialog component. */
  type?: IConfirmDialogTypes;
  /** Function called after user clicks on confirm button. */
  onConfirm?: () => void;
}

const classNames = createClassNames('confirm-dialog');

/** Confirm dialog component. */
export const ConfirmDialog: FC<IConfirmDialogProps> = ({
  message,
  onCancel,
  onConfirm,
  type = 'info',
}) => (
  <div className={classNames()}>
    {getIcon(type)}
    <div className={classNames('content')}>{message}</div>
    <div className={classNames('actions')}>
      {type !== 'info' && (
        <Button
          variant={type === 'danger' ? 'red' : 'normal'}
          label={ITranslationKeys.confirm}
          onClick={onConfirm}
        />
      )}
      <Button
        variant='normal'
        label={type === 'info' ? ITranslationKeys.close : ITranslationKeys.cancel}
        onClick={onCancel}
      />
    </div>
  </div>
);
