import { createSlice } from '@reduxjs/toolkit';

import { IGamesStateReport } from '../../../types';
import { getGameSummary, getGameSummaryBlock, getGameTeams } from './gamesReportActions';

const initialState: IGamesStateReport = {
  goals: [],
  scorePeriods: undefined,
  block1: undefined,
  block2: undefined,
  teams: undefined,
  isLoading: false,
  isBlockOneLoading: false,
  isBlockTwoLoading: false,
  isTeamsLoading: false,
  error: undefined,
  errorBlockOne: undefined,
  errorBlockTwo: undefined,
  errorTeams: undefined,
};

export const gamesReportSlice = createSlice({
  name: 'games/report',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getGameSummary.pending, state => {
        state.goals = initialState.goals;
        state.scorePeriods = initialState.scorePeriods;
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getGameSummary.fulfilled, (state, action) => {
        state.goals = action.payload.goals;
        state.scorePeriods = action.payload.scorePeriods;
        state.isLoading = false;
      })
      .addCase(getGameSummary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(getGameSummaryBlock.pending, (state, action) => {
        if (action.meta.arg.isSecondBlock) {
          state.isBlockTwoLoading = true;
          state.errorBlockTwo = undefined;
        } else {
          state.isBlockOneLoading = true;
          state.errorBlockOne = undefined;
        }
      })
      .addCase(getGameSummaryBlock.fulfilled, (state, action) => {
        if (action.meta.arg.isSecondBlock) {
          state.block2 = action.payload;
          state.isBlockTwoLoading = false;
        } else {
          state.block1 = action.payload;
          state.isBlockOneLoading = false;
        }
      })
      .addCase(getGameSummaryBlock.rejected, (state, action) => {
        if (action.meta.arg.isSecondBlock) {
          state.isBlockTwoLoading = false;
          state.errorBlockTwo = action.error.message;
        } else {
          state.isBlockOneLoading = false;
          state.errorBlockOne = action.error.message;
        }
      })
      // Next action case
      .addCase(getGameTeams.pending, state => {
        state.isTeamsLoading = true;
        state.errorTeams = undefined;
      })
      .addCase(getGameTeams.fulfilled, (state, action) => {
        state.teams = action.payload;
        state.isTeamsLoading = false;
      })
      .addCase(getGameTeams.rejected, (state, action) => {
        state.isTeamsLoading = false;
        state.errorTeams = action.error.message;
      });
  },
});
