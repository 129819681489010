import type { AppStartListening } from 'app/store';

import { resetCompetitionsDetail } from '../competitionsDetail';
import { resetGoalkeepers } from '../goalkeepers';
import { resetNavigation } from '../navigation';
import { resetPlayers } from '../players';
import { resetSeasons } from '../seasons';
import { resetTeams } from '../teams';
import { getLoggedUser, login } from './authActions';
import { logout } from './authSlice';

export const addAuthListeners = (startListening: AppStartListening) => {
  startListening({
    actionCreator: login.fulfilled,
    effect: async (_, listenerApi) => {
      listenerApi.dispatch(getLoggedUser());
    },
  });

  startListening({
    actionCreator: logout,
    effect: async (_, listenerApi) => {
      listenerApi.dispatch(resetSeasons());
      listenerApi.dispatch(resetPlayers());
      listenerApi.dispatch(resetTeams());
      listenerApi.dispatch(resetGoalkeepers());
      listenerApi.dispatch(resetCompetitionsDetail());
      listenerApi.dispatch(resetNavigation());
    },
  });
};
