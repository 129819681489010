import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { INavigationContentKeys, INavigationPageKeys } from '../types';

export const useLocationPaths = () => {
  const location = useLocation();
  const navigateOriginal = useNavigate();

  const navigate = (
    toPage: INavigationPageKeys,
    toTab?: INavigationContentKeys,
    search?: string,
  ) => {
    const pathname = toTab ? `/${toPage}/${toTab}` : `/${toPage}`;
    navigateOriginal({ pathname, search });
  };

  const locationPaths = useMemo(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);

    return {
      activePage: pathSegments[0] as INavigationPageKeys,
      activeTab: pathSegments[1] as INavigationContentKeys,
      pathSegments,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return {
    navigate,
    ...locationPaths,
    ...location,
  };
};
