import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  Caption,
  GamesSelectList,
  GoalNetShootout,
  GoalNetShootoutContent,
  GoalStatsBoxWrapper,
  Loading,
  ToggleSelect,
} from '../../../../components';
import {
  shootoutEndResultOptions,
  shootoutEndTypeOptions,
  shootoutStickGripOptions,
  shotoutEndSideOptions,
} from '../../../../constants';
import {
  filteredShootoutFilterDataSelector,
  selectGames,
  selectGoalkeepers,
  selectMainFilter,
  selectShootout,
  selectShootoutFilter,
  selectTeams,
  selectVideomapsFilter,
  setSelectedGames,
  setShootoutEndResult,
  setShootoutEndSide,
  setShootoutEndType,
  setShootoutStickGrip,
  triggerContentReload,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useExportAsImage,
  useFetchShootouts,
  useFetchShotsOrPasses,
  useHandleOnSubmit,
  useShootoutEffects,
  useVideoCenter,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import { IGoalStatsBoxWrapperProps, ISelectOption } from '../../../../types';
import {
  changeUrlSearchParamsMultiselectInput,
  countShootoutGoals,
  countShootoutPercentage,
  createClassNames,
  filterShootoutsByGateZone,
  getGamesOptions,
  getPlayerShortName,
  mapShootoutToVideoInfo,
  transformObjectKeysToKebabCase,
  transformShootoutsToDefaultVideomapsBox,
} from '../../../../utils';
import './ShootoutsContent.styles.scss';

const classNames = createClassNames('goalkeepers-shootouts-content');

export const ShootoutsContent = () => {
  const dispatch = useAppDispatch();
  const { games } = useAppSelector(selectGames);
  const goalkeepers = useAppSelector(selectGoalkeepers);
  const teams = useAppSelector(selectTeams);
  const { filteredShootoutGameEntity, filteredShootoutsBySelectedGamesWithSelectedGoalkeeper } =
    useAppSelector(filteredShootoutFilterDataSelector);
  const { selectedGoalkeeper, selectedTeam } = useAppSelector(selectMainFilter);
  const { selectedGames } = useAppSelector(selectVideomapsFilter);
  const { endResult, endSide, endType, stickGrip } = useAppSelector(selectShootoutFilter);
  const { isLoading, shootouts } = useAppSelector(selectShootout);

  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();

  const gamesOptions = useMemo(() => getGamesOptions(games.byId), [games.byId]);
  const isInitialMountRef = useRef(true);

  /* Export dat */
  const exportRef = useRef<HTMLDivElement>(null);
  const { isExporting, exportImage } = useExportAsImage(exportRef, `hokejLogic-karta-hráče`, 'png');

  const { fetchGamesAndToiForShotsOrPasses } = useFetchShotsOrPasses();
  const { fetchShootouts } = useFetchShootouts();
  const { playSelectedGamesVideos, playGameVideos, playVideos } = useVideoCenter(
    filteredShootoutGameEntity,
    ITranslationKeys.shootouts,
    true,
  );

  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGamesAndToiForShotsOrPasses(values);
    fetchShootouts(values, 'goalkeepers');
    dispatch(triggerContentReload());
  }, isInitialMountRef);
  useShootoutEffects(handleOnSubmit, isLoading);

  const handleChangeGame = (options: ISelectOption[]) => {
    dispatch(setSelectedGames(options));
    changeUrlSearchParamsMultiselectInput(options, 'selectedGames', setSearchParams);
  };

  if (!selectedGoalkeeper || !selectedTeam) {
    return (
      <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectTeamAndGoalkeeper)}</div>
    );
  }

  if (isLoading) return <Loading />;

  if (!shootouts)
    return <div className='content-info-box'>{t(ITranslationKeys.youCanLoadData)}</div>;

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, shootouts.length);
  if (errorInfoBox) return errorInfoBox;

  const shootoutByGameZone = filterShootoutsByGateZone(
    filteredShootoutsBySelectedGamesWithSelectedGoalkeeper,
    '',
  );
  const personalShootout = shootoutByGameZone.length.toString();
  const personalGoals = countShootoutGoals(
    filteredShootoutsBySelectedGamesWithSelectedGoalkeeper,
  ).toString();
  const personalPercentage =
    100 - countShootoutPercentage(filteredShootoutsBySelectedGamesWithSelectedGoalkeeper, '') + '%';
  const playerName =
    selectedGoalkeeper && getPlayerShortName(goalkeepers.byId[selectedGoalkeeper.value]);

  const goalNetPropsMapped: IGoalStatsBoxWrapperProps[] = transformShootoutsToDefaultVideomapsBox(
    filteredShootoutsBySelectedGamesWithSelectedGoalkeeper,
    playVideos,
    true,
  );

  const render = () => {
    return (
      <div className={classNames()} data-testid='goalkeepers-page__shootouts-content'>
        <div className={classNames('main')}>
          <GamesSelectList
            selected={selectedGames}
            options={gamesOptions}
            gameRecord={games.byId}
            teamRecord={teams.byId}
            shouldCheckFullBody={false}
            gameEntities={filteredShootoutGameEntity}
            onChange={newValue => handleChangeGame(newValue)}
            onItemContentActionClick={gameId => playGameVideos(gameId)}
            onPlaySelectedGamesVideos={playSelectedGamesVideos}
            hasSelectAllOption
          />
          <div className={classNames('main__playground-wrapper')}>
            <GoalNetShootout content={<GoalNetShootoutContent boxes={goalNetPropsMapped} />} />
            <div className={classNames('main__playground-wrapper__user-box')}>
              <span className={classNames('main__playground-wrapper__user-box-total')}>
                {t(ITranslationKeys.total)}
              </span>
              <GoalStatsBoxWrapper
                goalStatsBoxItems={[
                  {
                    items: [
                      { value: personalShootout },
                      { value: personalGoals },
                      { value: personalPercentage },
                    ],
                    onClick: () =>
                      playVideos(
                        shootoutByGameZone.map(shootout => mapShootoutToVideoInfo(shootout, true)),
                      ),
                    variant: 'dark',
                  },
                ]}
                color='black'
                showHeader={false}
                label={playerName}
              />
            </div>
          </div>
          <div className={classNames('main__form-box')}>
            <div>
              <Caption label={ITranslationKeys.resultOfEnding} />
              <ToggleSelect
                selected={endResult}
                options={shootoutEndResultOptions}
                onChange={newValue => dispatch(setShootoutEndResult(newValue))}
                hasOnlyOneColumn
              />
            </div>
            <div>
              <Caption label={ITranslationKeys.typeOfEnding} />
              <ToggleSelect
                selected={endType}
                options={shootoutEndTypeOptions}
                onChange={newValue => dispatch(setShootoutEndType(newValue))}
                twoColumns
              />
            </div>
            <div>
              <Caption label={ITranslationKeys.sideOfEnding} />
              <ToggleSelect
                selected={endSide}
                options={shotoutEndSideOptions}
                onChange={newValue => dispatch(setShootoutEndSide(newValue))}
                twoColumns
              />
            </div>
            <div>
              <Caption label={ITranslationKeys.opponentsStick} />
              <ToggleSelect
                selected={stickGrip}
                options={shootoutStickGripOptions}
                onChange={newValue => dispatch(setShootoutStickGrip(newValue))}
                twoColumns
              />
            </div>
            <div className={classNames('main__form-box__save-button')}>
              <Button
                label={ITranslationKeys.saveMap}
                iconComponent={<DownloadIcon />}
                onClick={exportImage}
                disabled={isExporting}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      ref={exportRef}
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isLoading,
        }),
      })}
    >
      {render()}
    </div>
  );
};
