import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { ITeamsFormation, ITeamsFormationRequestBody } from '../../types';
import { buildPath, defaultCatchErrorCallback, urlBooleanParamResolver } from '../../utils';
import { parseTeamsFormationData } from './parseTeamsFormation';

interface IGetTeamsFormationParams {
  competitionsUuids: string[];
  teamUuid: string;
  body?: ITeamsFormationRequestBody;
  isPercentile?: boolean;
}

export const getTeamsFormation = createAsyncThunk<ITeamsFormation, IGetTeamsFormationParams>(
  'teamsFormation/getTeamsFormation',
  async ({ competitionsUuids, teamUuid, body, isPercentile }) => {
    const base = `/teamsFormation/${competitionsUuids}/${teamUuid}`;
    const requestPath = buildPath(base, [urlBooleanParamResolver(isPercentile, 'percentile')]);
    const teamsFormation: ITeamsFormation = await axiosInstance
      .post(requestPath, body ?? {})
      .then(response => parseTeamsFormationData(response.data, teamUuid))
      .catch(defaultCatchErrorCallback);

    return teamsFormation;
  },
);
