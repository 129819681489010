import { ChangeEvent, FC, useEffect, useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { Controller, Noop, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  countOfPlayersOptions,
  formationTypeOptions,
  placeOptions,
  scoreStateOptions,
  teamAllOption,
} from '../../../constants';
import {
  filteredMainFilterDataSelector,
  selectCompetitionsDetail,
  selectGames,
  selectMainFilter,
  selectSeasons,
  setCountOfPlayers,
  setDateFromTo,
  setFormationType,
  setOpponentsAtGamesPage,
  setPlace,
  setScoreState,
  setSelectedGame,
  setSelectedGoalkeeper,
  setSelectedOpponents,
  setSelectedParts,
  setSelectedPlayer,
  setSelectedSeasons,
  setSelectedTeam,
} from '../../../features';
import { useResetOnPartChange, useResetOnSeasonChange, useResetOnTeamChange } from '../../../hooks';
import { ITranslationKeys } from '../../../i18n/types';
import {
  IControllerProps,
  IControllersWithCustomCaptionProps,
  IControllersWithCustomOptionsProps,
  IGame,
  IMainFilterForm,
  IMultiParamsVoid,
  ISelectOption,
} from '../../../types';
import {
  changeUrlSearchParamsDateRange,
  changeUrlSearchParamsMultiselectInput,
  createClassNames,
  formatNumberValueForForm,
  getEmptyArrayValidationObject,
  getPartsOptions,
  getSeasonsOptions,
  parseDateToStringDateRange,
  parseStringToDateDateRange,
  validFormValidationObject,
} from '../../../utils';
import { Caption } from '../../Caption';
import { DateRangePicker } from '../../DateRangePicker';
import { Input } from '../../Input';
import { MultiselectInput } from '../../MultiselectInput';
import { SelectInput } from '../../SelectInput';
import { GameButtonList } from '../../gameComponents';
import './Controllers.styles.scss';

const classNames = createClassNames('controller');

/**
 * Seasons controller.
 */
export const SeasonsController: FC<IControllerProps> = ({ required, rules }) => {
  const { byId } = useAppSelector(selectSeasons);
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();
  const resetOnSeasonChange = useResetOnSeasonChange();

  const { control, setValue, clearErrors } = useFormContext<IMainFilterForm>();

  const fieldName = 'selectedSeasons';

  const handleChangeSeason = (options: ISelectOption[], onChange: IMultiParamsVoid) => {
    onChange(options);
    dispatch(setSelectedSeasons(options));
    changeUrlSearchParamsMultiselectInput(options, fieldName, setSearchParams, [
      'selectedParts',
      'selectedTeam',
      'selectedPlayer',
      'selectedGoalkeeper',
      'selectedGame',
      'selectedOpponents',
      'opponentsAtGamesPage',
    ]);
    resetOnSeasonChange(setValue);
  };

  const seasonOptions = useMemo(() => getSeasonsOptions(byId), [byId]);

  useEffect(() => {
    return () => {
      clearErrors(fieldName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Caption label={ITranslationKeys.season} {...{ required }} />
      <Controller
        name={fieldName}
        control={control}
        rules={{
          validate: {
            ...getEmptyArrayValidationObject(ITranslationKeys.pleaseSelectSeason),
            ...rules?.validate,
          },
          ...rules,
        }}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <MultiselectInput
            onChange={value => handleChangeSeason(value, onChange)}
            selected={value}
            options={seasonOptions}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='filter'
            error={!!error}
            onBlur={onBlur}
          />
        )}
      />
    </div>
  );
};

/**
 * Parts controller.
 */
export const PartsController: FC<IControllerProps> = ({ required, rules }) => {
  const { byId } = useAppSelector(selectCompetitionsDetail);
  const { selectedSeasons } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();
  const resetOnPartChange = useResetOnPartChange();

  const { control, setValue, clearErrors } = useFormContext<IMainFilterForm>();
  const fieldName = 'selectedParts';

  const partOptions = useMemo(() => getPartsOptions(byId), [byId]);

  useEffect(() => {
    return () => {
      clearErrors(fieldName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeasons]);

  const handleChangePart = (options: ISelectOption[], onChange: IMultiParamsVoid) => {
    onChange(options);
    dispatch(setSelectedParts(options));
    changeUrlSearchParamsMultiselectInput(options, fieldName, setSearchParams, [
      'selectedTeam',
      'selectedPlayer',
      'selectedGoalkeeper',
      'selectedGame',
      'selectedOpponents',
      'opponentsAtGamesPage',
    ]);
    resetOnPartChange(setValue);
  };

  return (
    <div>
      <Caption label={ITranslationKeys.partOfSeason} {...{ required }} />
      <Controller
        name={fieldName}
        control={control}
        rules={{
          validate: {
            ...getEmptyArrayValidationObject(ITranslationKeys.pleaseSelectPart),
            ...rules?.validate,
          },
        }}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <MultiselectInput
            onChange={value => handleChangePart(value, onChange)}
            selected={value}
            options={partOptions}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='filter'
            error={!!error}
            onBlur={onBlur}
          />
        )}
      />
    </div>
  );
};

/**
 * Team controller.
 */
export const TeamController: FC<IControllerProps> = ({ required, rules }) => {
  const { filteredTeamsOptions } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedParts, selectedOpponents, opponentsAtGamesPage } =
    useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();
  const resetOnTeamChange = useResetOnTeamChange();

  const { control, setValue, clearErrors } = useFormContext<IMainFilterForm>();
  const fieldName = 'selectedTeam';

  useEffect(() => {
    return () => {
      clearErrors(fieldName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParts]);

  const handleChangeTeam = (
    newValue: ISelectOption,
    onChange: IMultiParamsVoid,
    prevValue?: ISelectOption,
  ) => {
    if (newValue.value !== prevValue?.value) {
      const isSelectedSameOpponent = selectedOpponents.some(
        opponent => opponent.value === newValue.value,
      );

      onChange(newValue);
      dispatch(setSelectedTeam(newValue));
      setSearchParams(params => {
        params.set(fieldName, newValue.value);
        ['selectedPlayer', 'selectedGoalkeeper', 'selectedGame'].forEach(param =>
          params.delete(param),
        );

        if (isSelectedSameOpponent) {
          const filteredOpponents = selectedOpponents.filter(
            opponent => opponent.value !== newValue.value,
          );
          params.set(
            'selectedOpponents',
            filteredOpponents.map(opponent => opponent.value).join(','),
          );
        }

        if (opponentsAtGamesPage?.value === newValue.value) {
          params.delete('opponentsAtGamesPage');
        }

        return params;
      });

      resetOnTeamChange(setValue);
      if (isSelectedSameOpponent) {
        const filteredOpponents = selectedOpponents.filter(
          opponent => opponent.value !== newValue.value,
        );
        setValue('selectedOpponents', filteredOpponents, {
          shouldDirty: true,
          shouldValidate: true,
        });
        dispatch(setSelectedOpponents(filteredOpponents));
      }
      if (opponentsAtGamesPage?.value === newValue.value) {
        const allOption = filteredTeamsOptions.find(option => option.value === teamAllOption.value);
        const resetValue = allOption ?? filteredTeamsOptions[0];
        setValue('opponentsAtGamesPage', resetValue, { shouldDirty: true });
        dispatch(setOpponentsAtGamesPage(resetValue));
      }
    }
  };

  return (
    <div>
      <Caption label={ITranslationKeys.selectTeam} {...{ required }} />
      <Controller
        name={fieldName}
        control={control}
        rules={{
          validate: rules?.validate ?? validFormValidationObject(),
          ...rules,
        }}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <SelectInput
            onChange={newValue => handleChangeTeam(newValue, onChange, value ?? undefined)}
            selected={value ?? undefined}
            options={filteredTeamsOptions}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='filter'
            error={!!error}
            onBlur={onBlur}
          />
        )}
      />
    </div>
  );
};

/**
 * Count of players controller.
 */
export const CountOfPlayersController: FC<IControllerProps> = ({ required }) => {
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();

  const { control } = useFormContext<IMainFilterForm>();

  const fieldName = 'countOfPlayers';

  const handleChangeCountOfPlayers = (option: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(option);
    dispatch(setCountOfPlayers(option));
    setSearchParams(params => {
      params.set(fieldName, option.value);
      return params;
    });
  };

  return (
    <div>
      <Caption label={ITranslationKeys.countOfPlayers} {...{ required }} />
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { value, onChange } }) => (
          <SelectInput
            onChange={value => handleChangeCountOfPlayers(value, onChange)}
            selected={value}
            options={countOfPlayersOptions}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='filter'
          />
        )}
      />
    </div>
  );
};

/**
 * Opponents controller.
 */
export const OpponentsController: FC<IControllerProps> = ({ required }) => {
  const { filteredTeamsOptions } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedGame } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();

  const { control, setValue, getValues } = useFormContext<IMainFilterForm>();

  const fieldName = 'selectedOpponents';

  const handleChangeOpponents = (options: ISelectOption[], onChange: IMultiParamsVoid) => {
    onChange(options);
    dispatch(setSelectedOpponents(options));
    changeUrlSearchParamsMultiselectInput(options, fieldName, setSearchParams, ['selectedGame']);

    if (selectedGame) {
      setValue('selectedGame', null, { shouldDirty: true, shouldValidate: true });
      dispatch(setSelectedGame(undefined));
    }
  };

  return (
    <div>
      <Caption label={ITranslationKeys.selectOpponent} {...{ required }} />
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { value, onChange } }) => (
          <MultiselectInput
            hasSelectAllOption
            onChange={value => handleChangeOpponents(value, onChange)}
            selected={value ?? []}
            options={filteredTeamsOptions.filter(
              team => team.value !== getValues().selectedTeam?.value && team.value !== 'all',
            )}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='filter'
          />
        )}
      />
    </div>
  );
};

/**
 * Opponents controller at games page.
 */
export const OpponentsAtGamesPageController: FC<IControllerProps> = ({ required }) => {
  const { filteredTeamsOptions } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam, selectedGame } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();

  const { control, setValue, getValues, clearErrors } = useFormContext<IMainFilterForm>();

  const fieldName = 'opponentsAtGamesPage';

  useEffect(() => {
    return () => {
      clearErrors(fieldName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam]);

  const handleChangeOpponents = (option: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(option);
    dispatch(setOpponentsAtGamesPage(option));
    setSearchParams(params => {
      params.set(fieldName, option.value);
      if (selectedGame) {
        params.delete('selectedGame');
      }
      return params;
    });

    if (selectedGame) {
      setValue('selectedGame', null, { shouldDirty: true, shouldValidate: true });
      dispatch(setSelectedGame(undefined));
    }
  };

  return (
    <div>
      <Caption label={ITranslationKeys.selectOpponent} {...{ required }} />
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { value, onChange } }) => (
          <SelectInput
            onChange={value => handleChangeOpponents(value, onChange)}
            selected={value ?? undefined}
            options={filteredTeamsOptions.filter(
              team => team.value !== getValues().selectedTeam?.value || team.value === 'all',
            )}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='filter'
          />
        )}
      />
    </div>
  );
};

/**
 * Player controller.
 */
export const PlayerController: FC<
  IControllersWithCustomCaptionProps<IMainFilterForm, 'selectedPlayer'>
> = ({ required, captionTranslationKey = ITranslationKeys.selectPlayer, rules }) => {
  const { filteredPlayersOptions } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();

  const { control, clearErrors } = useFormContext<IMainFilterForm>();
  const fieldName = 'selectedPlayer';

  useEffect(() => {
    return () => {
      clearErrors(fieldName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePlayer = (option: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(option);
    dispatch(setSelectedPlayer(option));

    setSearchParams(params => {
      params.set(fieldName, option.value);
      return params;
    });
  };

  return (
    <div>
      <Caption label={captionTranslationKey} {...{ required }} />
      <Controller
        name={fieldName}
        control={control}
        rules={rules}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <SelectInput
            onChange={value => handleChangePlayer(value, onChange)}
            selected={value ?? undefined}
            options={filteredPlayersOptions}
            placeholder={ITranslationKeys.player}
            disabled={!selectedTeam || selectedTeam.value === 'all'}
            variant='filter'
            error={!!error}
            onBlur={onBlur}
            scrollable
          />
        )}
      />
    </div>
  );
};

/**
 * Goalkeeper controller.
 */
export const GoalkeeperController: FC<IControllerProps<IMainFilterForm, 'selectedGoalkeeper'>> = ({
  required,
  rules,
}) => {
  const { filteredGoalkeepersOptions } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();

  const { control, clearErrors } = useFormContext<IMainFilterForm>();
  const fieldName = 'selectedGoalkeeper';

  useEffect(() => {
    return () => {
      clearErrors(fieldName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeGoalkeeper = (option: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(option);
    dispatch(setSelectedGoalkeeper(option));

    setSearchParams(params => {
      params.set(fieldName, option.value);
      return params;
    });
  };

  return (
    <div>
      <Caption label={ITranslationKeys.selectGoalkeeper} {...{ required }} />
      <Controller
        name={fieldName}
        control={control}
        rules={rules}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <SelectInput
            onChange={value => handleChangeGoalkeeper(value, onChange)}
            selected={value ?? undefined}
            options={filteredGoalkeepersOptions}
            placeholder={ITranslationKeys.goalkeeper}
            disabled={!selectedTeam || selectedTeam.value === 'all'}
            variant='filter'
            error={!!error}
            onBlur={onBlur}
            scrollable
          />
        )}
      />
    </div>
  );
};

/**
 * Time on ice controller.
 */
export const TimeOnIceController: FC<IControllerProps> = ({ required }) => {
  const { t } = useTranslation();

  const [, setSearchParams] = useSearchParams();

  const { control } = useFormContext<IMainFilterForm>();

  const handleChangeTimeOnIce = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: IMultiParamsVoid,
  ) => {
    const value = formatNumberValueForForm(event.target.valueAsNumber);
    onChange(value);
    setSearchParams(params => {
      params.set('timeOnIce', value.toString());
      return params;
    });
  };

  return (
    <div>
      <Caption label={ITranslationKeys.minimumTOI} additionalLabel=' (Min.)' {...{ required }} />
      <Controller
        name='timeOnIce'
        control={control}
        render={({ field: { value, onChange } }) => (
          <Input
            type='number'
            variant='filter'
            min={0}
            value={formatNumberValueForForm(value)}
            placeholder={t(ITranslationKeys.insertTime) ?? ''}
            onChange={event => handleChangeTimeOnIce(event, onChange)}
          />
        )}
      />
    </div>
  );
};

/**
 * Last played matches controller.
 */
export const LastPlayedMatchesController: FC<IControllerProps> = ({ required }) => {
  const { t } = useTranslation();

  const [, setSearchParams] = useSearchParams();

  const { control } = useFormContext<IMainFilterForm>();

  const handleChangeLastPlayedMatches = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: IMultiParamsVoid,
  ) => {
    const value = formatNumberValueForForm(event.target.valueAsNumber);
    onChange(value);
    setSearchParams(params => {
      params.set('lastPlayedMatches', value.toString());
      return params;
    });
  };

  return (
    <div>
      <Caption label={ITranslationKeys.lastPlayedMatches} {...{ required }} />
      <Controller
        name='lastPlayedMatches'
        control={control}
        render={({ field: { value, onChange } }) => (
          <Input
            type='number'
            variant='filter'
            min={0}
            value={formatNumberValueForForm(value)}
            placeholder={t(ITranslationKeys.insertCount) ?? ''}
            onChange={event => handleChangeLastPlayedMatches(event, onChange)}
          />
        )}
      />
    </div>
  );
};

/**
 * Date from to controller.
 */
export const DateFromToController: FC<IControllerProps> = ({ required }) => {
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();

  const { control } = useFormContext<IMainFilterForm>();

  const fieldName = 'dateFromTo';

  const handleChangeDateFromTo = (value: DateRange | undefined, onChange: IMultiParamsVoid) => {
    const dateString = parseDateToStringDateRange(value);
    onChange(value);
    dispatch(setDateFromTo(dateString));
    changeUrlSearchParamsDateRange(dateString, fieldName, setSearchParams);
  };

  return (
    <div>
      <Caption label={ITranslationKeys.dateFromTo} {...{ required }} />
      <Controller
        name='dateFromTo'
        control={control}
        render={({ field: { value, onChange } }) => (
          <DateRangePicker
            value={parseStringToDateDateRange(value)}
            onChange={(value: DateRange | undefined) => handleChangeDateFromTo(value, onChange)}
          />
        )}
      />
    </div>
  );
};

/**
 * Place (home or away) controller.
 */
export const PlaceController: FC<IControllerProps> = ({ required }) => {
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();

  const { control } = useFormContext<IMainFilterForm>();

  const fieldName = 'place';

  const handleChangePlace = (option: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(option);
    dispatch(setPlace(option));
    setSearchParams(params => {
      params.set(fieldName, option.value);
      return params;
    });
  };

  return (
    <div>
      <Caption label={ITranslationKeys.homeOrAway} {...{ required }} />
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { value, onChange } }) => (
          <SelectInput
            onChange={value => handleChangePlace(value, onChange)}
            selected={value}
            options={placeOptions}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='filter'
          />
        )}
      />
    </div>
  );
};

/**
 * Score state controller.
 */
export const ScoreStateController: FC<IControllerProps> = ({ required }) => {
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();

  const { control } = useFormContext<IMainFilterForm>();

  const fieldName = 'scoreState';

  const handleChangeScoreState = (option: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(option);
    dispatch(setScoreState(option));
    setSearchParams(params => {
      params.set(fieldName, option.value);
      return params;
    });
  };

  return (
    <div>
      <Caption label={ITranslationKeys.gameState} {...{ required }} />
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { value, onChange } }) => (
          <SelectInput
            onChange={value => handleChangeScoreState(value, onChange)}
            selected={value}
            options={scoreStateOptions}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='filter'
          />
        )}
      />
    </div>
  );
};

/**
 * Game time controller.
 */
export const GameTimeController: FC<IControllerProps> = ({ required }) => {
  const { t } = useTranslation();

  const [, setSearchParams] = useSearchParams();

  const { control } = useFormContext<IMainFilterForm>();

  const handleChangeGameTimeFrom = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: IMultiParamsVoid,
  ) => {
    const value = formatNumberValueForForm(event.target.valueAsNumber);
    onChange(value);
    setSearchParams(params => {
      params.set('gameTimeFrom', value.toString());
      return params;
    });
  };

  const handleChangeGameTimeTo = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: IMultiParamsVoid,
  ) => {
    const value = formatNumberValueForForm(event.target.valueAsNumber);
    onChange(value);
    setSearchParams(params => {
      params.set('gameTimeTo', value.toString());
      return params;
    });
  };

  return (
    <div>
      <Caption label={ITranslationKeys.gameTime} {...{ required }} />
      <div className={classNames('game-time')}>
        <Controller
          name='gameTimeFrom'
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              type='number'
              variant='filter'
              disableAppearance
              min={0}
              value={formatNumberValueForForm(value)}
              placeholder={t(ITranslationKeys.from) ?? ''}
              onChange={event => handleChangeGameTimeFrom(event, onChange)}
            />
          )}
        />
        <Controller
          name='gameTimeTo'
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              type='number'
              variant='filter'
              disableAppearance
              min={0}
              value={formatNumberValueForForm(value)}
              placeholder={t(ITranslationKeys.to) ?? ''}
              onChange={event => handleChangeGameTimeTo(event, onChange)}
            />
          )}
        />
      </div>
    </div>
  );
};

/**
 * Formation type controller.
 */
export const FormationTypeController: FC<
  IControllersWithCustomCaptionProps & IControllersWithCustomOptionsProps
> = ({
  required,
  captionTranslationKey = ITranslationKeys.chooseFormationType,
  options = formationTypeOptions,
}) => {
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();

  const { control } = useFormContext<IMainFilterForm>();

  const fieldName = 'formationType';

  const handleChangeFormationType = (option: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(option);
    dispatch(setFormationType(option));
    setSearchParams(params => {
      params.set(fieldName, option.value);
      return params;
    });
  };

  return (
    <div>
      <Caption label={captionTranslationKey} {...{ required }} />
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { value, onChange } }) => (
          <SelectInput
            onChange={value => handleChangeFormationType(value, onChange)}
            selected={value}
            options={options}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='filter'
          />
        )}
      />
    </div>
  );
};

/**
 * GameButtonList controller.
 */
export const GameButtonListController: FC<IControllerProps<IMainFilterForm, 'selectedGame'>> = ({
  rules,
}) => {
  const dispatch = useAppDispatch();
  const { games } = useAppSelector(selectGames);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);

  const [, setSearchParams] = useSearchParams();

  const {
    control,
    formState: { errors },
  } = useFormContext<IMainFilterForm>();

  const fieldName = 'selectedGame';

  const handleGameButtonClick = (game: IGame, onChange: IMultiParamsVoid, onBlur: Noop) => {
    onChange(game);
    dispatch(setSelectedGame(game));
    setSearchParams(params => {
      params.set(fieldName, game.id);
      return params;
    });
    onBlur();
  };

  const isRequiredSelected = !errors.selectedSeasons && !errors.selectedParts;

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, onBlur } }) => (
        <GameButtonList
          games={Object.values(games.byId)}
          isLoading={games.isLoading || !games.isAllGamesLoaded}
          selectedGame={value ?? undefined}
          onChange={value => handleGameButtonClick(value, onChange, onBlur)}
          showNoDataMessage={!isRequiredSelected || filteredParts.length === 0}
        />
      )}
    />
  );
};
