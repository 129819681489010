import { FC } from 'react';

import { IPlayerExpectedGoals } from '../../../types';
import { createClassNames } from '../../../utils';
import { GameExpectedGoalsRow } from '../GameExpectedGoalsRow';
import './GameExpectedGoalsStats.styles.scss';

interface IGameExpectedGoalsStatsProps {
  /** All teams's players data. */
  players: IPlayerExpectedGoals[];
  /** Maximum value from both teams. */
  maxValue: number;
  /** Decides if is reversed or not. */
  team: 'home' | 'away';
}

const classNames = createClassNames('game-expected-goals-stats');

/**
 * GameExpectedGoalsStats UI component.
 */
export const GameExpectedGoalsStats: FC<IGameExpectedGoalsStatsProps> = ({
  players,
  maxValue,
  team,
}) => {
  return (
    <div className={classNames({ reversed: team === 'away' })}>
      {players.map((player, index) => (
        <GameExpectedGoalsRow
          key={index}
          player={player}
          calculateFrom={maxValue}
          team={team}
          reversed={team === 'away'}
        />
      ))}
    </div>
  );
};
