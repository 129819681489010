import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../../i18n/types';
import {
  IFaceoffComponentsParams,
  IFaceoffPlayerInfoByPositionRecord,
  IFaceoffPositionKeys,
  IGoalStatsBoxProps,
} from '../../../../types';
import { createClassNames } from '../../../../utils';
import { GoalStatsBoxWrapper } from '../../../goalStatsBoxComponents';
import './FaceOffPlaygroundContent.styles.scss';

export interface IFaceOffPlaygroundContentProps extends IFaceoffComponentsParams {}

const classNames = createClassNames('face-off-playground-content');

export const FaceOffPlaygroundContent: FC<IFaceOffPlaygroundContentProps> = ({
  faceoffPlayerInfoByPositionRecord,
  onBoxClick,
}) => {
  const { t } = useTranslation();

  const positionMap = createPositionMap(faceoffPlayerInfoByPositionRecord);

  return (
    <div className={classNames()} data-testid='face-off-playground-content'>
      {positionMap.map((position, index) => (
        <div key={index} className={classNames(position.name)}>
          <GoalStatsBoxWrapper
            goalStatsBoxItems={position.faceoffs.map<IGoalStatsBoxProps>(
              ({ faceoffs, attributes }) => ({
                items: [{ value: attributes.ratio }, { value: attributes.percentile }],
                color: attributes.color,
                onClick: () => onBoxClick(faceoffs),
                fontWeight: 'normal',
              }),
            )}
            showHeader={false}
          />
        </div>
      ))}
      <div className={classNames('defend-zone')}>{t(ITranslationKeys.defensiveZone)}</div>
      <div className={classNames('attack-zone')}>{t(ITranslationKeys.offensiveZone)}</div>
    </div>
  );
};

const createPositionMap = (
  faceoffPlayerInfoByPositionRecord: IFaceoffPlayerInfoByPositionRecord,
) => {
  const faceoffInfos = Object.values(faceoffPlayerInfoByPositionRecord);

  const positionMap = [
    {
      name: 'left-top-face-off-box',
      positionId: IFaceoffPositionKeys.defenseDefenseLeft,
      faceoffs: faceoffInfos.map(faceoffInfo => faceoffInfo.faceoffPositions.defenseDefenseLeft),
    },
    {
      name: 'left-bottom-face-off-box',
      positionId: IFaceoffPositionKeys.defenseDefenseRight,
      faceoffs: faceoffInfos.map(faceoffInfo => faceoffInfo.faceoffPositions.defenseDefenseRight),
    },
    {
      name: 'left-top-line-box',
      positionId: IFaceoffPositionKeys.defenseNeutralLeft,
      faceoffs: faceoffInfos.map(faceoffInfo => faceoffInfo.faceoffPositions.defenseNeutralLeft),
    },
    {
      name: 'left-bottom-line-box',
      positionId: IFaceoffPositionKeys.defenseNeutralRight,
      faceoffs: faceoffInfos.map(faceoffInfo => faceoffInfo.faceoffPositions.defenseNeutralRight),
    },
    {
      name: 'center-box',
      positionId: IFaceoffPositionKeys.center,
      faceoffs: faceoffInfos.map(faceoffInfo => faceoffInfo.faceoffPositions.center),
    },
    {
      name: 'right-top-face-off-box',
      positionId: IFaceoffPositionKeys.offenseDefenseLeft,
      faceoffs: faceoffInfos.map(faceoffInfo => faceoffInfo.faceoffPositions.offenseDefenseLeft),
    },
    {
      name: 'right-bottom-face-off-box',
      positionId: IFaceoffPositionKeys.offenseDefenseRight,
      faceoffs: faceoffInfos.map(faceoffInfo => faceoffInfo.faceoffPositions.offenseDefenseRight),
    },
    {
      name: 'right-top-line-box',
      positionId: IFaceoffPositionKeys.offenseNeutralLeft,
      faceoffs: faceoffInfos.map(faceoffInfo => faceoffInfo.faceoffPositions.offenseNeutralLeft),
    },
    {
      name: 'right-bottom-line-box',
      positionId: IFaceoffPositionKeys.offenseNeutralRight,
      faceoffs: faceoffInfos.map(faceoffInfo => faceoffInfo.faceoffPositions.offenseNeutralRight),
    },
  ];

  return positionMap;
};
