import { ColumnDef, SortingState, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

import { ImageColumn } from '../../../../components';
import { logosPathsTcms } from '../../../../constants';
import { ILogHokejPlayerTableData } from '../../../../types';
import { createIndexColumn } from '../../../../utils';

const columnHelper = createColumnHelper<ILogHokejPlayerTableData>();

const initialSorting: SortingState = [
  {
    id: 'value',
    desc: true,
  },
];

const fixedColumns: ColumnDef<ILogHokejPlayerTableData, any>[] = [
  columnHelper.display({
    id: 'index',
    header: '',
    cell: props => <div style={{ textAlign: 'start' }}>{createIndexColumn(props)}</div>,
  }),
  columnHelper.accessor('team', {
    header: '',
    cell: props => (
      <ImageColumn
        src={logosPathsTcms[props.getValue()]}
        alt='team-logo'
        variant='tcms'
        width={32}
      />
    ),
  }),
  columnHelper.accessor('player', {
    header: '',
    cell: props => (
      <div style={{ textAlign: 'start', fontSize: '1.4rem' }}>
        {props.getValue().name} <strong>{props.getValue().surname}</strong>
      </div>
    ),
  }),
];

const defaultValueColumn = columnHelper.accessor('value', {
  header: '',
  cell: props => (
    <div style={{ textAlign: 'end', fontWeight: 'bold', fontSize: '1.4rem' }}>
      {props.getValue().mainValue}
    </div>
  ),
  sortingFn: (a, b) => a.original.value.mainValue - b.original.value.mainValue,
});

const clickthroughRateValueColumn = columnHelper.accessor('value', {
  header: '',
  cell: props => {
    const values = props.getValue();

    return (
      <div style={{ textAlign: 'end', fontSize: '1.4rem' }}>
        <strong>
          {values.mainValue === 100 || values.mainValue === 0
            ? values.mainValue.toFixed(0) + '%'
            : values.mainValue.toFixed(1) + '%'}
        </strong>{' '}
        {`(${values.openedNotificationCount}/${values.notificationCount})`}
      </div>
    );
  },
  sortingFn: (a, b) => {
    if (a.original.value.mainValue > b.original.value.mainValue) return 1;
    if (a.original.value.mainValue < b.original.value.mainValue) return -1;

    return a.original.value.openedNotificationCount - b.original.value.openedNotificationCount;
  },
});

export const useColumnsConfigHokejPlayer = (isClickthrough: boolean = false) => {
  const columns = useMemo(() => {
    const conditionalColumns = isClickthrough ? clickthroughRateValueColumn : defaultValueColumn;

    return fixedColumns.concat(conditionalColumns);
  }, [isClickthrough]);

  return {
    columns,
    initialSorting,
  };
};
