import { ITranslationKeys } from '../../i18n/types';
import {
  IBaseVideoShifts,
  IBaseVideoShiftsDTO,
  IShiftVideo,
  IShiftVideoDTO,
  IShiftVideoRecord,
} from '../../types';
import { createShiftVideoUuid } from '../../utils';

const parseEvents = (
  events: IShiftVideoDTO[],
  shiftEventId: ITranslationKeys | string,
  matchId: string,
  videoId: string,
): IShiftVideoRecord =>
  events.reduce<IShiftVideoRecord>(
    (acc, { time, videoTime, gameState, teamId, player, realTime }) => {
      const video: IShiftVideo = {
        videoUuid: createShiftVideoUuid(player, videoTime),
        matchId,
        matchDate: realTime,
        videoId,
        videoTime,
        time,
        playerId: player,
        gameState,
        eventInfo: {
          teamId,
          shiftEventId,
        },
        cutBefore: 5,
        cutAfter: 5,
      };

      return {
        ...acc,
        [video.videoUuid]: video,
      };
    },
    {},
  );

export const parseVideoShiftsData = (
  data: IBaseVideoShiftsDTO,
  matchId: string,
  playerId: string,
): IBaseVideoShifts => {
  const { shifts, videoPlayer } = data;

  const parsedShiftVideoRecord = shifts.reduce<IShiftVideoRecord>((acc, shift) => {
    const { videoTime, videoEndTime, start, end, gameState, realTime } = shift;

    const shiftVideoRecordFromEvents: IShiftVideoRecord = {
      ...parseEvents(shift.shots, ITranslationKeys.shot, matchId, videoPlayer.videoId),
      ...parseEvents(shift.passes, ITranslationKeys.pass, matchId, videoPlayer.videoId),
      ...parseEvents(shift.faceOffs, ITranslationKeys.faceoffs, matchId, videoPlayer.videoId),
      ...parseEvents(shift.puckWins, ITranslationKeys.puckGain, matchId, videoPlayer.videoId),
      ...parseEvents(shift.dumpIns, ITranslationKeys.dumpins, matchId, videoPlayer.videoId),
      ...parseEvents(shift.dumpOuts, ITranslationKeys.dumpouts, matchId, videoPlayer.videoId),
      ...parseEvents(shift.zoneEntries, ITranslationKeys.zoneEntries, matchId, videoPlayer.videoId),
      ...parseEvents(shift.zoneExits, ITranslationKeys.zoneExits, matchId, videoPlayer.videoId),
    };

    const video: IShiftVideo = {
      videoUuid: createShiftVideoUuid(playerId, videoTime),
      matchId,
      matchDate: realTime,
      videoId: videoPlayer.videoId,
      videoTime,
      time: start,
      playerId,
      gameState,
      shiftInfo: {
        timeEnd: end,
        videoEndTime,
        shiftVideoEventIds: Object.keys(shiftVideoRecordFromEvents),
      },
      cutBefore: 5,
      cutAfter: 5,
    };

    return {
      ...acc,
      ...shiftVideoRecordFromEvents,
      [video.videoUuid]: video,
    };
  }, {});

  return {
    shifts: parsedShiftVideoRecord,
    videoPlayer,
  };
};
