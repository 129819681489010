import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { IScore } from '../../../types';
import {
  createClassNames,
  getScoreStateShortcut,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import './ScoreColumn.styles.scss';

export interface IScoreColumnCellProps<TData, TValue> extends CellContext<TData, TValue> {
  score: IScore;
  isColumnBold?: boolean;
}

const classNames = createClassNames('score-column');

export const ScoreColumnCell = <TData, TValue>({
  isColumnBold,
  score,
}: IScoreColumnCellProps<TData, TValue>) => {
  const { t } = useTranslation();
  const scoreState = getScoreStateShortcut(score.state);

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isColumnBold,
        }),
      })}
    >
      {score.home}:{score.away}
      {scoreState && t(scoreState)}
    </div>
  );
};
