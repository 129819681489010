import {
  IFormationDTO,
  IFormationsPlayerStats,
  IFormationsPlayerStatsDTO,
  ITeamStats,
} from '../../types';

export const parseFormationsData = (data: IFormationDTO, teamId: string): ITeamStats => ({
  stats: data,
  teamId,
});

export const parseFormationsPlayersStatsData = (
  data: IFormationsPlayerStatsDTO[],
): IFormationsPlayerStats[] =>
  data.map<IFormationsPlayerStats>(playerData => ({
    id: playerData.player,
    stats: playerData.stats,
  }));
