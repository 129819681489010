import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ISeasonsState } from '../../types';
import { getSeasons } from './seasonsActions';

const initialState: ISeasonsState = {
  byId: {},
  allIds: [],
  isLoading: false,
  isSeasonsLoaded: false,
  error: undefined,
  lastSeasonId: undefined,
};

export const seasonsSlice = createSlice({
  name: 'seasons',
  initialState,
  reducers: {
    setLastSeasonId: (state, action: PayloadAction<string>) => {
      state.lastSeasonId = action.payload;
    },
    resetSeasons: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getSeasons.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getSeasons.fulfilled, (state, action) => {
        state.byId = action.payload.byId;
        state.allIds = Object.keys(action.payload.byId).map(id => id);
        state.isLoading = false;
        state.isSeasonsLoaded = true;
      })
      .addCase(getSeasons.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { setLastSeasonId, resetSeasons } = seasonsSlice.actions;

export const seasonsReducer = seasonsSlice.reducer;
