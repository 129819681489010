import { FC } from 'react';
import { Rectangle } from 'recharts';

import { COLORS_TCMS } from '../../../constants';
import { ITrackingUserType } from '../../../types';

const fillByType = (type: ITrackingUserType, isActive?: boolean) => {
  if (type === ITrackingUserType.team) {
    return COLORS_TCMS.blue[isActive ? 800 : 700];
  }

  if (type === ITrackingUserType.media) {
    return COLORS_TCMS.fuchsia[isActive ? 700 : 600];
  }

  return COLORS_TCMS.cyan[isActive ? 600 : 500];
};

export interface IRenderProps {
  props: any;
  isActive?: boolean;
}

export const BarRectangle: FC<IRenderProps> = ({ props, isActive }) => {
  const fill = fillByType(props.type, isActive);

  return <Rectangle {...props} radius={[6, 6, 0, 0]} fill={fill} />;
};
