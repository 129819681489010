import { useAppSelector } from '../app/hooks';
import {
  filteredFaceoffsDataSelector,
  selectFaceoffsFilter,
  selectVideomapsFilter,
} from '../features';
import {
  IFaceoffPlayerInfo,
  IFaceoffPlayerInfoByPositionRecord,
  IFaceoffPositionKeys,
  IFaceoffPositionRecord,
} from '../types';
import { getFaceoffPosition } from '../utils';

export const useGetFaceoffPositions = (disableOnOff: boolean) => {
  const { selectedPlayerItems } = useAppSelector(selectVideomapsFilter);
  const { filteredFaceoffsPlayerInfoBySelectedGames } = useAppSelector(
    filteredFaceoffsDataSelector,
  );
  const { faceoffResult } = useAppSelector(selectFaceoffsFilter);

  const positions = Object.keys(IFaceoffPositionKeys) as IFaceoffPositionKeys[];

  const getFaceoffPositionRecord = (
    acc: IFaceoffPositionRecord,
    positionId: IFaceoffPositionKeys,
    faceoffPlayerInfo: IFaceoffPlayerInfo,
  ) => {
    acc[positionId] = getFaceoffPosition(
      positionId,
      faceoffPlayerInfo,
      selectedPlayerItems,
      faceoffResult.value,
      disableOnOff,
    );
    return acc;
  };

  const getFaceoffPlayerInfoByPositionRecord = (
    acc: IFaceoffPlayerInfoByPositionRecord,
    faceoffPlayerInfo: IFaceoffPlayerInfo,
  ) => {
    const faceoffPositions = positions.reduce<IFaceoffPositionRecord>(
      (acc, positionId) => getFaceoffPositionRecord(acc, positionId, faceoffPlayerInfo),
      {} as IFaceoffPositionRecord,
    );

    acc[faceoffPlayerInfo.playerId] = {
      playerId: faceoffPlayerInfo.playerId,
      faceoffPositions,
    };

    return acc;
  };

  const faceoffsPlayerInfoByPositionRecord = Object.values(
    filteredFaceoffsPlayerInfoBySelectedGames,
  ).reduce<IFaceoffPlayerInfoByPositionRecord>(
    (acc, faceoffPlayerInfo) => getFaceoffPlayerInfoByPositionRecord(acc, faceoffPlayerInfo),
    {} as IFaceoffPlayerInfoByPositionRecord,
  );

  return faceoffsPlayerInfoByPositionRecord;
};
