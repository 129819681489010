import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../../i18n/types';
import { ZoneArrowIcon } from '../../../../icons';
import { IDefaultPlayerColorNames, IVideoInfo } from '../../../../types';
import {
  createClassNames,
  getMetricsFromBoxes,
  transformObjectKeysToKebabCase,
} from '../../../../utils';
import { GoalStatsBoxWrapper } from '../../../goalStatsBoxComponents';
import './PlaygroundZoneContent.styles.scss';

export type IPlaygroundZoneStats = {
  /** Count metric value. */
  count: string;
  /** xG metric value. */
  xG: string;
  /** GoalStatBox color. */
  color?: IDefaultPlayerColorNames;
  /** Video info array. */
  videoInfo: IVideoInfo[];
};

export interface IPlaygroundZoneContentProps {
  /**
   * Summary box stats.
   */
  summaryBox: IPlaygroundZoneStats[];
  /**
   * Left box stats.
   */
  leftBox: IPlaygroundZoneStats[];
  /**
   * Center box stats.
   */
  centerBox: IPlaygroundZoneStats[];
  /**
   * Right box stats.
   */
  rightBox: IPlaygroundZoneStats[];
  /**
   * True when the boxes in the PlaygroundBar component are shown.
   */
  showBoxes: boolean;
  /**
   * Callback fired when a box is clicked.
   * @param box position of the box (left | center | right | total)
   */
  onClick: (array: IVideoInfo[]) => void;
  /**
   * When true, the design follows zone entry config.
   */
  isZoneEntry?: boolean;
}

const classNames = createClassNames('playground-zone-content');

export const PlaygroundZoneContent: FC<IPlaygroundZoneContentProps> = ({
  summaryBox,
  leftBox,
  centerBox,
  rightBox,
  showBoxes,
  isZoneEntry = false,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({ ...transformObjectKeysToKebabCase({ showBoxes, isZoneEntry }) })}
      data-testid='playground-zone-content'
    >
      <div className={classNames('total-box')}>
        <GoalStatsBoxWrapper
          goalStatsBoxItems={getMetricsFromBoxes(summaryBox, t(ITranslationKeys.count), onClick)}
          color='default'
          label={t(ITranslationKeys.total)}
        />
      </div>
      <div className={classNames('left-box')}>
        <GoalStatsBoxWrapper
          goalStatsBoxItems={getMetricsFromBoxes(leftBox, t(ITranslationKeys.count), onClick)}
          color='default'
          label={t(ITranslationKeys.left)}
        />
      </div>
      <div className={classNames('center-box')}>
        <GoalStatsBoxWrapper
          goalStatsBoxItems={getMetricsFromBoxes(centerBox, t(ITranslationKeys.count), onClick)}
          color='default'
          label={t(ITranslationKeys.center)}
        />
      </div>
      <div className={classNames('right-box')}>
        <GoalStatsBoxWrapper
          goalStatsBoxItems={getMetricsFromBoxes(rightBox, t(ITranslationKeys.count), onClick)}
          color='default'
          label={t(ITranslationKeys.right)}
        />
      </div>
      <div className={classNames('arrow-left')}>
        <ZoneArrowIcon />
      </div>
      <div className={classNames('arrow-right')}>
        <ZoneArrowIcon />
      </div>
    </div>
  );
};
