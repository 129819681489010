import logoCt from '../assets/icons/tcms/logos/ct-logo.svg';
import logoHokejCz from '../assets/icons/tcms/logos/hokejcz-logo.svg';
import logoISport from '../assets/icons/tcms/logos/isport-logo.svg';
import logoO2TV from '../assets/icons/tcms/logos/o2tv-logo.png';
import logoSportCz from '../assets/icons/tcms/logos/sportcz-logo.svg';
import logoLitvinov from '../assets/logos/3c08ada2-3fd6-49b7-974d-2db9c92676ac.png';
import logoLiberec from '../assets/logos/3c85029c-140f-4971-96d2-76a95f429c2b.png';
import logoSparta from '../assets/logos/9af95c87-b24b-411b-a615-c1d86861fca5.png';
import logoKladno from '../assets/logos/42bafa4d-5e60-4f00-81c5-4f44ffa55927.png';
import logoTrinec from '../assets/logos/55d9085b-58b5-4c52-bc73-42fdc106c944.png';
import logoVitkovice from '../assets/logos/99f32b0e-ddcc-4330-8ef5-66c8bbdd22ba.png';
import logoPlzen from '../assets/logos/524e1ff0-e670-42d9-93aa-8ba35bb94942.png';
import logoBudejovice from '../assets/logos/732e68fc-13c7-45a8-bb51-5eeab8b10283.png';
import logoChomutov from '../assets/logos/1460b1ac-a5a9-4819-997e-afa26b512299.png';
import logoHK from '../assets/logos/29579d3e-1502-4ec0-80e3-f5ead6d22fb0.png';
import logoKometa from '../assets/logos/69802e5a-b9de-4bc0-8001-f5e11d0f2048.png';
import logoKVary from '../assets/logos/302630a7-3aff-4303-9539-951bdd605ab9.png';
import logoOlomouc from '../assets/logos/71760283-d630-4c7f-b52f-e0b193a622cd.png';
import logoBoleslav from '../assets/logos/b4a4a812-fd88-47e4-a626-be3300b390cc.png';
import logoVsetin from '../assets/logos/ce662469-d588-45c2-bb1a-9729afbe040f.png';
import logoJihlava from '../assets/logos/e3bf29fb-e963-4922-9064-1b6101047a9e.png';
import logoZlin from '../assets/logos/eec5f929-a5bb-4bf4-8e69-34d0a274c661.png';
import logoPardubice from '../assets/logos/f9b85077-6ea5-4769-9bba-27d5da8e97bd.png';
import logoExtraliga from '../assets/logos/telh_logo.png';
import { ILogoPaths } from '../types';

export const logosPaths: ILogoPaths = {
  '3c08ada2-3fd6-49b7-974d-2db9c92676ac': logoLitvinov,
  '3c85029c-140f-4971-96d2-76a95f429c2b': logoLiberec,
  '9af95c87-b24b-411b-a615-c1d86861fca5': logoSparta,
  '42bafa4d-5e60-4f00-81c5-4f44ffa55927': logoKladno,
  '55d9085b-58b5-4c52-bc73-42fdc106c944': logoTrinec,
  '99f32b0e-ddcc-4330-8ef5-66c8bbdd22ba': logoVitkovice,
  '524e1ff0-e670-42d9-93aa-8ba35bb94942': logoPlzen,
  '732e68fc-13c7-45a8-bb51-5eeab8b10283': logoBudejovice,
  '1460b1ac-a5a9-4819-997e-afa26b512299': logoChomutov,
  '29579d3e-1502-4ec0-80e3-f5ead6d22fb0': logoHK,
  '69802e5a-b9de-4bc0-8001-f5e11d0f2048': logoKometa,
  '302630a7-3aff-4303-9539-951bdd605ab9': logoKVary,
  '71760283-d630-4c7f-b52f-e0b193a622cd': logoOlomouc,
  'b4a4a812-fd88-47e4-a626-be3300b390cc': logoBoleslav,
  'e3bf29fb-e963-4922-9064-1b6101047a9e': logoJihlava,
  'eec5f929-a5bb-4bf4-8e69-34d0a274c661': logoZlin,
  'f9b85077-6ea5-4769-9bba-27d5da8e97bd': logoPardubice,
  'ce662469-d588-45c2-bb1a-9729afbe040f': logoVsetin,
};

export const logosPathsTcms: ILogoPaths = {
  LIT: logoLitvinov,
  LIB: logoLiberec,
  SPA: logoSparta,
  KLA: logoKladno,
  TRI: logoTrinec,
  VIT: logoVitkovice,
  PLZ: logoPlzen,
  CEB: logoBudejovice,
  CHO: logoChomutov,
  MHK: logoHK,
  KOM: logoKometa,
  KVA: logoKVary,
  OLO: logoOlomouc,
  MBL: logoBoleslav,
  JIH: logoJihlava,
  ZLN: logoZlin,
  PCE: logoPardubice,
  VSE: logoVsetin,
  all: logoExtraliga,
};

export const logosPathsTcmsMedia: ILogoPaths = {
  O2TV: logoO2TV,
  CT: logoCt,
  iSport: logoISport,
  sportCz: logoSportCz,
  hokejCz: logoHokejCz,
};
