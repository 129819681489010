import {
  ColumnDef,
  ColumnPinningState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  BasicColumnCell,
  BasicColumnHeader,
  ImageColumn,
  ScoreColumnCell,
  Sticker,
} from '../../../../components';
import { logosPaths } from '../../../../constants';
import { useFilteredMetricsForTable } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { IColumnsConfig, IEntity, IGamelogGoalkeepersStatsTableData } from '../../../../types';
import {
  createDynamicStatsColumnsDef,
  formatDateByLanguage,
  secondsToMinutesAndSeconds,
} from '../../../../utils';

const columnHelper = createColumnHelper<IGamelogGoalkeepersStatsTableData>();

const columnPinning: ColumnPinningState = {
  left: ['homeTeamId', 'gameTeams', 'awayTeamId', 'date', 'score', 'toi'],
};

const initialSorting: SortingState = [
  {
    id: 'date',
    desc: true,
  },
];

const fixedColumns: ColumnDef<IGamelogGoalkeepersStatsTableData, any>[] = [
  columnHelper.accessor('homeTeamId', {
    header: '',
    cell: props => <ImageColumn src={logosPaths[props.getValue()]} alt='team-logo' />,
    enableSorting: false,
  }),
  columnHelper.accessor('gameTeams', {
    header: props => <BasicColumnHeader {...props} translationKey={ITranslationKeys.match} />,
    cell: props => (
      <Sticker
        label={`${props.getValue().homeTeam.shortcut} - ${props.getValue().awayTeam.shortcut}`}
        size='small'
      />
    ),
    enableSorting: false,
  }),
  columnHelper.accessor('awayTeamId', {
    header: '',
    cell: props => <ImageColumn src={logosPaths[props.getValue()]} alt='team-logo' />,
    enableSorting: false,
  }),
  columnHelper.accessor('date', {
    header: props => <BasicColumnHeader {...props} translationKey={ITranslationKeys.date} />,
    cell: props => (
      <BasicColumnCell {...props}>
        {formatDateByLanguage(new Date(props.getValue()), 'P')}
      </BasicColumnCell>
    ),
  }),
  columnHelper.accessor('score', {
    header: props => <BasicColumnHeader {...props} translationKey={ITranslationKeys.score} />,
    cell: props => <ScoreColumnCell {...props} score={props.getValue()} isColumnBold />,
    enableSorting: false,
  }),
  columnHelper.accessor('toi', {
    header: props => <BasicColumnHeader {...props}>TOI</BasicColumnHeader>,
    cell: props => (
      <BasicColumnCell {...props}>{secondsToMinutesAndSeconds(props.getValue())}</BasicColumnCell>
    ),
  }),
];

export const useColumnsConfig = (
  centerPartWidth: number | undefined,
): IColumnsConfig<IGamelogGoalkeepersStatsTableData> => {
  const getFilteredMetrics = useFilteredMetricsForTable();

  const columns = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(IEntity.goalkeepers);
    const dynamicColumns = createDynamicStatsColumnsDef(
      filteredMetrics,
      columnHelper,
      undefined,
      centerPartWidth,
    );
    return fixedColumns.concat(dynamicColumns);
  }, [centerPartWidth, getFilteredMetrics]);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};
