import { useTranslation } from 'react-i18next';

import { COLORS, logosPaths } from '../../constants';
import { ITranslationKeys } from '../../i18n/types';
import { IApexChartConfig, ICoordinates, ITeamsCoordinates } from '../../types';
import { getMaxChartTicks, secondsToMinutesAndSeconds } from '../../utils';

const getPeriodLabel = (period: number, yMax: number, text: string): PointAnnotations => ({
  x: 1200 * period - 600,
  y: yMax,
  marker: { size: 0 },
  label: {
    style: {
      cssClass: 'game-timeline__period-label',
      background: 'transparent',
      color: COLORS.black.base,
    },
    text,
    borderWidth: 0,
  },
});

const getGoalPoints = (goalCoordinates: ICoordinates, teamId: string): PointAnnotations => ({
  x: goalCoordinates.x,
  y: goalCoordinates.y,
  marker: {
    size: 0,
  },
  image: {
    path: logosPaths[teamId],
    width: 36,
    height: 36,
    offsetX: 0,
    offsetY: 8,
  },
});

const generatePeriodLabels = (
  yMax: number,
  maxTime: number,
  translatedPeriod: string,
  translatedOvertime: string,
): PointAnnotations[] => {
  const length = Math.round((maxTime - 1) / 1200);

  return Array.from<number>({ length }).map((_, i) => {
    const index = i + 1;
    const periodText = index > 3 ? translatedOvertime : `${index}. ${translatedPeriod}`;

    return getPeriodLabel(index, yMax, periodText);
  });
};

const generateGoalPoints = (teamGoalsCoordinates: ITeamsCoordinates): PointAnnotations[] => {
  const { homeTeam, awayTeam } = teamGoalsCoordinates;

  const homeGoalsPoints = homeTeam.coordinates.map(goal => getGoalPoints(goal, homeTeam.teamId));
  const awayGoalsPoints = awayTeam.coordinates.map(goal => getGoalPoints(goal, awayTeam.teamId));

  return [...homeGoalsPoints, ...awayGoalsPoints];
};

const periodsDividerStyles = {
  strokeDashArray: 0,
  borderColor: COLORS.gray[80],
  label: {
    borderColor: 'transparent',
    style: {
      color: COLORS.gray[80],
      background: 'transparent',
      fontSize: '12px',
      fontWeight: 600,
    },
    offsetY: -12,
    orientation: 'horizontal',
  },
};
const homeTeamColor = COLORS.purple[80];
const awayTeamColor = COLORS.orange[60];
const defaultConfig: IApexChartConfig = {
  xaxis: {
    type: 'numeric',
    min: 0,
    max: 3600,
    tickAmount: 12,
    labels: {
      show: true,
      style: {
        fontWeight: 600,
      },
      formatter: (val: string) => secondsToMinutesAndSeconds(Number(val)),
    },
    axisBorder: {
      show: true,
      color: COLORS.gray[80],
      offsetX: 0,
      offsetY: 0,
    },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: COLORS.gray[80],
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    min: 0,
    labels: {
      style: {
        fontWeight: 600,
      },
      formatter: (val: number) => val.toFixed(2),
    },
    tooltip: {
      enabled: false,
    },
  },
  grid: {
    show: true,
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
  chart: {
    type: 'line',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    selection: {
      enabled: false,
    },
  },
  stroke: {
    curve: 'stepline',
    colors: [homeTeamColor, awayTeamColor],
    width: 3,
  },
  dataLabels: {
    enabled: false,
  },
  title: {
    text: undefined,
  },
  markers: {
    size: 6,
    colors: [homeTeamColor, awayTeamColor],
    strokeColors: [homeTeamColor, awayTeamColor],
  },
  legend: {
    show: false,
  },
  annotations: {
    xaxis: [
      { ...periodsDividerStyles, x: 1200 },
      { ...periodsDividerStyles, x: 2400 },
      { ...periodsDividerStyles, x: 4800 },
      { ...periodsDividerStyles, x: 6000 },
      { ...periodsDividerStyles, x: 7200 },
    ],
  },
};

export const useGameTimelineConfig = (
  yMax: number,
  maxTime: number,
  teamGoalsCoordinates?: ITeamsCoordinates,
): IApexChartConfig => {
  const { t } = useTranslation();

  const periodLabels = generatePeriodLabels(
    yMax,
    maxTime,
    t(ITranslationKeys.period),
    t(ITranslationKeys.overtime),
  );
  const goalPoints = teamGoalsCoordinates ? generateGoalPoints(teamGoalsCoordinates) : [];
  const points = [...periodLabels, ...goalPoints];

  if (maxTime > 3600) {
    const { xaxis, yaxis, annotations } = defaultConfig;

    const tickAmount = getMaxChartTicks(maxTime);

    const overtimeConfig: IApexChartConfig = {
      ...defaultConfig,
      xaxis: {
        ...xaxis,
        max: tickAmount * 300,
        tickAmount,
      },
      yaxis: {
        ...yaxis,
        max: yMax,
        tickAmount: Math.ceil(yMax),
      },
      annotations: {
        ...annotations,
        xaxis: [...(annotations.xaxis ?? []), { ...periodsDividerStyles, x: 3600 }],
        points,
      },
    };

    return overtimeConfig;
  }

  return {
    ...defaultConfig,
    yaxis: {
      ...defaultConfig.yaxis,
      max: yMax,
      tickAmount: Math.ceil(yMax),
    },
    annotations: {
      ...defaultConfig.annotations,
      points,
    },
  };
};
