import { FC, memo, useEffect, useRef } from 'react';
import simpleheat from 'simpleheat';

import { COLORS } from '../../../constants';
import { IHeatmapData } from '../../../types';
import { calculateHeatmapPointPositionToPercent, createClassNames } from '../../../utils';
import './HeatmapComparative.styles.scss';

interface IHeatmapComparativeProps {
  data: IHeatmapData[];
  metric: string;
  width?: number;
  height?: number;
  isChance?: boolean;
}

const classNames = createClassNames('heatmap-comparative-component');

const gradientRed = {
  0.2: 'white',
  0.4: COLORS.red[50],
  0.6: COLORS.red[60],
  0.8: COLORS.red[70],
  1.0: COLORS.red[80],
};

const gradientBlue = {
  0.2: 'white',
  0.4: COLORS.blue[50],
  0.6: COLORS.blue[60],
  0.8: COLORS.blue[70],
  1.0: COLORS.blue[80],
};

const radius = {
  r: 60,
  blur: 50,
};

export const HeatmapComparative: FC<IHeatmapComparativeProps> = memo(
  ({ data, metric, width = 760, height = 582, isChance = false }) => {
    const dataMetricsValues = data.map(item => item.z[metric]);
    const minimumMetricValue = Math.min(...dataMetricsValues);
    const maximumMetricValue = Math.max(...dataMetricsValues);
    const maxValue = Math.max(Math.abs(minimumMetricValue), Math.abs(maximumMetricValue));

    const positiveData = data.filter(item => item.z[metric] > 0);
    const negativeData = data.filter(item => item.z[metric] < 0);

    const redData: [number, number, number][] = positiveData.map(item => {
      const { x, y } = calculateHeatmapPointPositionToPercent(
        { x: item.x, y: item.y },
        height,
        width,
      );

      return [y, x, item.z[metric]];
    });

    const blueData: [number, number, number][] = negativeData.map(item => {
      const { x, y } = calculateHeatmapPointPositionToPercent(
        { x: item.x, y: item.y },
        height,
        width,
      );

      return [y, x, Math.abs(item.z[metric])];
    });

    const canvasRefRed = useRef<HTMLCanvasElement | null>(null);
    const canvasRefBlue = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
      if (canvasRefRed.current) {
        const heat = simpleheat(canvasRefRed.current);

        if (isChance) {
          heat.max(maxValue);
        }

        heat.gradient(gradientRed);
        heat.radius(radius.r, radius.blur);
        heat.data(redData);
        heat.draw();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redData]);

    useEffect(() => {
      if (canvasRefBlue.current) {
        const heat = simpleheat(canvasRefBlue.current);

        if (isChance) {
          heat.max(maxValue);
        }

        heat.max(maxValue);
        heat.gradient(gradientBlue);
        heat.radius(radius.r, radius.blur);
        heat.data(blueData);
        heat.draw();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blueData]);

    return (
      <div className={classNames()} data-testid={'heatmap-comparative-component'}>
        <canvas className={classNames('canvas')} ref={canvasRefRed} {...{ width, height }} />
        <canvas className={classNames('canvas')} ref={canvasRefBlue} {...{ width, height }} />
      </div>
    );
  },
);
