import { PayloadAction } from '@reduxjs/toolkit';

import { IDataSettingsFilterState, INetZoneUnion, ISelectOption } from '../../types';
import { DEFAULT_DATA_SETTINGS_METRIC_PARAMS_STATE } from './constants';
import {
  setDataSettingsEnterExitLocation,
  setDataSettingsEntryType,
  setDataSettingsExitType,
  setDataSettingsGameActionType,
  setDataSettingsNetZone,
  setDataSettingsPuckGainType,
  setDataSettingsShotDanger,
  setDataSettingsShotLocation,
  setDataSettingsShotType,
} from './dataSettingsFilterSlice';

const resetOldestMetricParam = (state: IDataSettingsFilterState, metricParamActionName: string) => {
  const {
    gameActionType,
    shotType,
    shotDanger,
    shotLocation,
    puckGainType,
    entryType,
    exitType,
    enterExitLocation,
    netZone,
  } = DEFAULT_DATA_SETTINGS_METRIC_PARAMS_STATE;

  if (metricParamActionName === setDataSettingsGameActionType.toString()) {
    state.gameActionType = gameActionType;
  } else if (metricParamActionName === setDataSettingsShotType.toString()) {
    state.shotType = shotType;
  } else if (metricParamActionName === setDataSettingsShotDanger.toString()) {
    state.shotDanger = shotDanger;
  } else if (metricParamActionName === setDataSettingsShotLocation.toString()) {
    state.shotLocation = shotLocation;
  } else if (metricParamActionName === setDataSettingsPuckGainType.toString()) {
    state.puckGainType = puckGainType;
  } else if (metricParamActionName === setDataSettingsEntryType.toString()) {
    state.entryType = entryType;
  } else if (metricParamActionName === setDataSettingsExitType.toString()) {
    state.exitType = exitType;
  } else if (metricParamActionName === setDataSettingsEnterExitLocation.toString()) {
    state.enterExitLocation = enterExitLocation;
  } else if (metricParamActionName === setDataSettingsNetZone.toString()) {
    state.netZone = netZone;
  }
};

/**
 * Metric parameter was set to not default value. If param action name isn`t same as at previous state change,
 * it checks if we need to reset oldest metric param. After that it adds new param action name to stack.
 */
const addParamAndMaybeResetOldest = (state: IDataSettingsFilterState, actionType: string) => {
  const lastSelectedParam = state.lastTwoMetricParamActions[0];
  if (lastSelectedParam !== actionType) {
    const newLength = state.lastTwoMetricParamActions.length + 1;

    if (newLength > 2) {
      const oldestSelectedParam = state.lastTwoMetricParamActions[1];
      resetOldestMetricParam(state, oldestSelectedParam);
      state.lastTwoMetricParamActions = state.lastTwoMetricParamActions.slice(0, 1);
    }

    state.lastTwoMetricParamActions.unshift(actionType);
  }
};

/** Metric parameter was set to default value so it removes param from lastTwoMetricParamActions stack. */
const removeParam = (state: IDataSettingsFilterState, actionType: string) => {
  state.lastTwoMetricParamActions = state.lastTwoMetricParamActions.filter(
    item => item !== actionType,
  );
};

export const handleLastTwoMetricParamActions = (
  state: IDataSettingsFilterState,
  action: PayloadAction<ISelectOption>,
) => {
  if (action.payload.value !== 'all') {
    addParamAndMaybeResetOldest(state, action.type);
  } else {
    removeParam(state, action.type);
  }
};

export const handleLastTwoMetricParamActionsNetZone = (
  state: IDataSettingsFilterState,
  action: PayloadAction<INetZoneUnion | undefined>,
) => {
  if (action.payload) {
    addParamAndMaybeResetOldest(state, action.type);
  } else {
    removeParam(state, action.type);
  }
};
