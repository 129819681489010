import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ITeamsFormationState } from '../../types';
import { getTeamsFormation } from './teamsFormationActions';

const initialState: ITeamsFormationState = {
  byId: {},
  allIds: [],
  isLoading: undefined,
  error: undefined,
  isLoadedFirstTime: undefined,
  isTeamsFormationPercentilesActive: undefined,
  isTeamsFormationPercentilesLoading: undefined,
  errorTeamsFormationPercentiles: undefined,
};

export const teamsFormationSlice = createSlice({
  name: 'teamsFormation',
  initialState,
  reducers: {
    setIsTeamsFormationPercentilesActive: (state, action: PayloadAction<boolean>) => {
      state.isTeamsFormationPercentilesActive = action.payload;
    },
    resetTeamsFormationLoadedData: state => {
      state.byId = {};
      state.allIds = [];
      state.isLoadedFirstTime = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTeamsFormation.pending, (state, action) => {
        if (action.meta.arg.isPercentile) {
          state.isTeamsFormationPercentilesLoading = true;
          state.errorTeamsFormationPercentiles = undefined;
        } else {
          state.isLoading = true;
          state.error = undefined;
        }
      })
      .addCase(getTeamsFormation.fulfilled, (state, action) => {
        if (action.meta.arg.isPercentile) {
          state.byId[action.payload.teamId].teamsFormationPercentiles =
            action.payload.teamsFormation;
          state.isTeamsFormationPercentilesLoading = false;
        } else {
          state.byId[action.payload.teamId] = action.payload;
          state.allIds.push(action.payload.teamId);
          state.isLoading = false;
          state.isLoadedFirstTime = true;
        }
      })
      .addCase(getTeamsFormation.rejected, (state, action) => {
        if (action.meta.arg.isPercentile) {
          state.isTeamsFormationPercentilesLoading = false;
          state.errorTeamsFormationPercentiles = action.error.message;
        } else {
          state.isLoading = false;
          state.error = action.error.message;
        }
      });
  },
});

export const { setIsTeamsFormationPercentilesActive, resetTeamsFormationLoadedData } =
  teamsFormationSlice.actions;

export const teamsFormationReducer = teamsFormationSlice.reducer;
