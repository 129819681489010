import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  IActualLoadedPlayer,
  IComparePlayerItemPayload,
  IComparePlayerRecord,
  IPlayersState,
  ISimilarPlayerNameRecord,
} from '../../types';
import { getCompetitionDetail } from '../competitionsDetail';
import { getPlayerCardInfo } from './playersActions';

const initialComparePlayerItems: IComparePlayerRecord = {
  '0': { id: '0' },
  '1': { id: '1' },
  '2': { id: '2' },
  '3': { id: '3' },
};

const initialState: IPlayersState = {
  byId: {},
  allIds: [],
  isLoading: undefined,
  error: undefined,
  isPlayerInfoLoading: undefined,
  errorPlayerInfo: undefined,
  actualLoadedPlayer: undefined,
  comparePlayerItems: initialComparePlayerItems,
  similarPlayerNames: {},
};

export const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    setActualLoadedPlayer: (state, action: PayloadAction<IActualLoadedPlayer | undefined>) => {
      state.actualLoadedPlayer = action.payload;
    },
    setComparePlayerItems: (state, action: PayloadAction<IComparePlayerItemPayload>) => {
      state.comparePlayerItems[action.payload.itemKey] = action.payload.comparePlayerItems;
    },
    setSimilarPlayerNames: (state, action: PayloadAction<ISimilarPlayerNameRecord>) => {
      state.similarPlayerNames = action.payload;
    },
    resetPlayers: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getCompetitionDetail.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getCompetitionDetail.fulfilled, (state, action) => {
        action.payload.players.forEach(player => {
          if (!state.byId[player.id]) {
            state.byId[player.id] = player;
            state.allIds.push(player.id);
          } else {
            if (!state.byId[player.id].seasonIds.includes(player.seasonIds[0])) {
              state.byId[player.id].seasonIds.push(player.seasonIds[0]);
            }
            if (!state.byId[player.id].teamIds.includes(player.teamIds[0])) {
              state.byId[player.id].teamIds.push(player.teamIds[0]);
            }
            Object.values(player.competitionTeams).forEach(competitionTeam => {
              state.byId[player.id].competitionTeams[competitionTeam.id] = competitionTeam;
            });
          }
        });
        state.isLoading = false;
      })
      .addCase(getCompetitionDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(getPlayerCardInfo.pending, state => {
        state.isPlayerInfoLoading = true;
        state.errorPlayerInfo = undefined;
      })
      .addCase(getPlayerCardInfo.fulfilled, (state, action) => {
        const playerId = action.payload.playerId;
        if (playerId) {
          state.byId[playerId].playerInfos.push(action.payload.playerInfo);
        }
        state.actualLoadedPlayer = {
          id: playerId,
          teamId: action.payload.playerTeamId,
        };
        state.isPlayerInfoLoading = false;
      })
      .addCase(getPlayerCardInfo.rejected, (state, action) => {
        state.isPlayerInfoLoading = false;
        state.errorPlayerInfo = action.error.message;
      });
  },
});

export const { setActualLoadedPlayer, setComparePlayerItems, setSimilarPlayerNames, resetPlayers } =
  playersSlice.actions;

export const playersReducer = playersSlice.reducer;
