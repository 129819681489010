import { axiosInstance } from '../services/axiosInstance';
import { INavigationContentKeys, INavigationPageKeys } from '../types';

export const logScreen = async (tab: INavigationPageKeys, subTab: INavigationContentKeys) =>
  await axiosInstance.post('log/screen', {
    tab,
    subTab,
  });

/**
 * Finds navigation key name in the navigation keys object (value) and returns correct translation key (key).
 * @param name Name of the page or content.
 * @param isPage Is it page? Default value is false.
 * @returns Correct translation key for navigation key.
 */
export const getTranslationKeyForNavigationKey = (name: string, isPage = false) => {
  const keyValue = Object.entries(isPage ? INavigationPageKeys : INavigationContentKeys).find(
    ([_, value]) => name === value,
  );

  return keyValue ? keyValue[0] : name;
};
