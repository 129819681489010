import { ColumnDef, PaginationState, SortingState } from '@tanstack/react-table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ITablePaginationConfig, TcmsButton, TcmsTable } from '../../../../../../components';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { FileTypeXlsIcon } from '../../../../../../icons';
import { IExportRows, ILogHokejPlayerTableData } from '../../../../../../types';
import { createClassNames, handleExportToXLSX } from '../../../../../../utils';
import './HokejPlayerTableContainer.styles.scss';

export interface IHokejPlayerTableContainerProps {
  data: ILogHokejPlayerTableData[] | undefined;
  columns: ColumnDef<ILogHokejPlayerTableData, any>[];
  initialSorting?: SortingState;
  paginationConfig?: ITablePaginationConfig;
  label: ITranslationKeys | string;
  dateFrom: string;
  dateTo: string;
  isClickthrough?: boolean;
}

const classNames = createClassNames('hokej-player-table-container');

export const HokejPlayerTableContainer: FC<IHokejPlayerTableContainerProps> = ({
  data,
  columns,
  initialSorting,
  paginationConfig,
  label,
  dateFrom,
  dateTo,
  isClickthrough = false,
}) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 });

  const commonHeader = [
    'ID',
    t(ITranslationKeys.name),
    t(ITranslationKeys.surname),
    t(ITranslationKeys.value),
  ];

  return (
    <div className={classNames()}>
      <div className={classNames('table-caption')}>
        <h3>{t(label)}</h3>
        <p>{`${t(ITranslationKeys.dataByTimePeriod)} ${dateFrom} - ${dateTo}`}</p>
      </div>
      <div className={classNames('table-card')}>
        <TcmsTable
          {...{ columns, initialSorting, paginationConfig, pagination, setPagination }}
          data={data || []}
          showHeader={false}
          disableCellLine
        />
      </div>
      <TcmsButton
        color='blue'
        variant='text'
        label={ITranslationKeys.exportData}
        iconComponent={<FileTypeXlsIcon />}
        iconPosition='left'
        disabled={!data?.length}
        onClick={() =>
          handleExportToXLSX(
            `HokejPlayer-${label}`,
            isClickthrough
              ? [...commonHeader, t(ITranslationKeys.openLinks), t(ITranslationKeys.totalLinks)]
              : commonHeader,
            (rows: ILogHokejPlayerTableData) => tableRowCallback(rows, isClickthrough),
            data,
          )
        }
      />
    </div>
  );
};

const tableRowCallback = (
  { player, value }: ILogHokejPlayerTableData,
  isClickthrough: boolean = false,
): IExportRows => {
  const main = {
    registrationNumber: player.registrationNumber,
    name: player.name,
    surname: player.surname,
    value: isClickthrough ? value.mainValue.toFixed(1) : value.mainValue,
  };

  const clickthroughRateRows = {
    openedNotificationCount: value.openedNotificationCount,
    notificationCount: value.notificationCount,
  };

  return isClickthrough ? { ...main, ...clickthroughRateRows } : main;
};
