import { FC } from 'react';

import { IMultiParamsVoid, ISelectOption } from '../../../types';
import { createClassNames } from '../../../utils';
import { TeamSelectionCard } from '../TeamSelectionCard/TeamSelectionCard';
import './TeamSelectionCardList.styles.scss';

export interface ITeamSelectionCardListProps {
  /** Array of TeamSelectionCard components */
  options: ISelectOption[];
  /** Function to be called when some child component is clicked */
  onChange: IMultiParamsVoid;
  /** Selected option item */
  selected?: ISelectOption;
}

const classNames = createClassNames('team-selection-card-list');

export const TeamSelectionCardList: FC<ITeamSelectionCardListProps> = ({
  options,
  selected,
  onChange,
}) => {
  return (
    <div className={classNames()} data-testid='team-selection-card-list'>
      {options.map(option => (
        <TeamSelectionCard
          key={option.value}
          teamId={option.value}
          isActive={selected?.value === option.value}
          onClick={() => onChange(option)}
        />
      ))}
    </div>
  );
};
