import { MutableRefObject, useEffect } from 'react';

import { useAppSelector } from '../app/hooks';
import { selectTableFilter } from '../features';

export interface IUseTableCommonEffectsConfig {
  isInitialMountRef: MutableRefObject<boolean>;
  handleOnSubmit: () => Promise<void>;
  disablePositionRefetch?: boolean;
}

export const useTableCommonEffects = ({
  isInitialMountRef,
  handleOnSubmit,
  disablePositionRefetch = false,
}: IUseTableCommonEffectsConfig) => {
  const { dataTemplate, position, customMetrics } = useAppSelector(selectTableFilter);

  const positionDependency = !disablePositionRefetch ? position : undefined;

  useEffect(() => {
    if (isInitialMountRef && !isInitialMountRef.current) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplate, positionDependency, customMetrics]);
};
