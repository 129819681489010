import { useState } from 'react';

import { useAppSelector } from '../../app/hooks';
import { Loading, SubNavigation } from '../../components';
import {
  ChangePassword,
  DownloadManagement,
  HokejPlayerManagement,
} from '../../components/accountManagementComponents';
import { selectAuth, selectNavigation } from '../../features';
import { useLoadInitialData } from '../../hooks';
import { ITranslationKeys } from '../../i18n/types';
import { createClassNames } from '../../utils';
import { ACCOUNT_MANAGEMENT_TABS } from '../constants';
import './AccountManagementPage.styles.scss';

const classNames = createClassNames('account-management');

export const AccountManagementPage = () => {
  const { user } = useAppSelector(selectAuth);
  const { isLoadingMinimumInitialData } = useAppSelector(selectNavigation);
  useLoadInitialData();

  const [activeTab, setActiveTab] = useState<ITranslationKeys>(
    ITranslationKeys.downloadsManagement,
  );

  return (
    <div data-testid='account-management-page' className={classNames()}>
      {isLoadingMinimumInitialData && <Loading />}
      <SubNavigation
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={
          user?.teamId
            ? ACCOUNT_MANAGEMENT_TABS
            : ACCOUNT_MANAGEMENT_TABS.filter(tab => tab !== ITranslationKeys.hokejPlayerManagement)
        }
      />
      <div className={classNames('sub-page')}>
        {activeTab === ITranslationKeys.downloadsManagement && <DownloadManagement />}
        {activeTab === ITranslationKeys.hokejPlayerManagement && <HokejPlayerManagement />}
        {activeTab === ITranslationKeys.changeOfPassword && <ChangePassword />}
      </div>
    </div>
  );
};
