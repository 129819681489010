import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Sidebar } from '../components';
import { selectDataSettingsFilter, selectSettings, setShowHelpSidebar } from '../features';
import i18n from '../i18n/i18n';
import { ILanguageType } from '../types';
import { findHelpItem } from '../utils';

export const useHelpSidebar = () => {
  const { help, showHelpSidebar } = useAppSelector(selectSettings).help;
  const { open } = useAppSelector(selectDataSettingsFilter);
  const dispatch = useAppDispatch();

  const getHelpSidebar = (pageName: string, tabName: string) => {
    if (!help) return null;

    const language = i18n.language as keyof ILanguageType<string>;
    const { main, tips, functions } = findHelpItem(help, pageName, tabName, open);

    if (!main || !tips || !functions) {
      console.error(`[useHelpSidebar]: Help content for ${pageName} - ${tabName} not found`);
      return null;
    }

    return (
      <Sidebar
        isOpened={showHelpSidebar}
        title={main[language].title}
        description={main[language].text}
        functions={functions.items}
        tips={tips[language]}
        onHelpClose={() => dispatch(setShowHelpSidebar(false))}
      />
    );
  };

  return getHelpSidebar;
};
