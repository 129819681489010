import { COLORS_TCMS } from '../../../constants';
import { ITrackingTimeInterval } from '../../../types';
import { formatDateByTimeInterval } from '../../../utils';
import './LogStackedBarChart.styles.scss';

export const BarTick = (props: any) => {
  const [total, date]: string[] = props.payload.value.split(';');
  const timeInterval: ITrackingTimeInterval | undefined = props.timeInterval;

  return (
    <g transform={`translate(${props.x - 12.3},${props.y})`}>
      <text
        x={12}
        y={18}
        fontSize='16px'
        textAnchor='middle'
        fontWeight='bold'
        fill={COLORS_TCMS.slate[950]}
      >
        {total}
      </text>
      <text
        x={12}
        y={42}
        textAnchor='middle'
        fill={COLORS_TCMS.slate[500]}
        className='log-stacked-bar-chart__bar-tick'
      >
        {formatDateByTimeInterval(date, timeInterval)}
      </text>
    </g>
  );
};
