import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredMainFilterDataSelector,
  getTrendFormationStats,
  getTrendPlayerStats,
  selectMainFilter,
  selectVideomapsFilter,
} from '../../features';
import { IFormation, IFormationStatsRequestBody, IMainFilterForm } from '../../types';
import { isAtLeastOnePlayerOn } from '../../utils';
import { useNormalizeMetrics } from '../useNormalizeMetrics';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

export const useFetchTrendPlayerStats = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam } = useAppSelector(selectMainFilter);
  const { selectedPlayerItems } = useAppSelector(selectVideomapsFilter);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchTrendPlayerStats = (data: Partial<IMainFilterForm>) => {
    const competitionsUuids = filteredParts.map(part => part.id);

    const filteredSelectedPlayers = selectedPlayerItems
      ? Object.values(selectedPlayerItems).filter(item => item.selectedPlayer)
      : [];
    const formation: IFormation[] = filteredSelectedPlayers.map(item => ({
      player: item.selectedPlayer?.value || '',
      on: item.isActive ?? false,
    }));

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IFormationStatsRequestBody = {
      ...requestBodyBase,
      formation: formation.length > 1 ? formation : undefined,
      metrics: normalizeMetrics(),
    };

    const isSomePlayerOn = isAtLeastOnePlayerOn(selectedPlayerItems, false);
    const isSelectionValid =
      filteredParts.length > 0 && selectedTeam && selectedTeam.value !== 'all' && isSomePlayerOn;

    if (isSelectionValid) {
      if (formation.length === 1) {
        dispatch(
          getTrendPlayerStats({
            competitionsUuids,
            playerUuid: formation[0].player,
            teamUuid: selectedTeam.value,
            body: requestBody,
          }),
        );
      } else {
        dispatch(
          getTrendFormationStats({
            competitionsUuids,
            teamUuid: selectedTeam.value,
            body: requestBody,
          }),
        );
      }
    }
  };

  return fetchTrendPlayerStats;
};
