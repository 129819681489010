import { FC } from 'react';

import { createClassNames } from '../../../utils';
import './TcmsFooter.styles.scss';

export interface ITcmsFooterProps {
  /** Year next to the copyright */
  year: number;
  /** Version of the application */
  version: string;
  /** Function called when the Changelog is clicked. */
  onChangelogClick: () => void;
}

const classNames = createClassNames('tcms-footer');

/**
 * TcmsFooter UI component for displaying information at the bottom of the TCMS pages.
 */
export const TcmsFooter: FC<ITcmsFooterProps> = ({ year, version, onChangelogClick }) => {
  return (
    <div className={classNames()}>
      <p>
        &copy;{year} eSports.cz & BPA a.s.
        <br />
        Verze aplikace: {version} | <span onClick={onChangelogClick}>Changelog</span>
      </p>
    </div>
  );
};
