import { useTranslation } from 'react-i18next';

import { createClassNames } from '../../../utils';
import { TcmsButton } from '../TcmsButton';
import './ScreenBarChart.styles.scss';

const classNames = createClassNames('screen-bar-chart__bar-tick');

const width = 227;

export const BarTick = (props: any) => {
  const { t } = useTranslation();
  const total: number = props.total;
  const label = props.payload.value;
  const hasButtons: boolean = props.hasButtons;
  const isSelected: boolean = props.isSelected;
  const onClick: (() => void) | undefined = props.onClick;

  return (
    <foreignObject
      className={classNames()}
      width={width}
      height='32px'
      x={props.x + 8 - width}
      y={props.y - 16}
    >
      <div className={classNames('content')}>
        {hasButtons ? (
          <TcmsButton
            label={label}
            color={isSelected ? 'blue' : 'gray'}
            textAlign='start'
            variant='filled'
            onClick={onClick}
          />
        ) : (
          <span>{t(label)}</span>
        )}
        <div className={classNames('content__number')}>{total}</div>
      </div>
    </foreignObject>
  );
};
