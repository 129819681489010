import { FieldErrors } from 'react-hook-form';

import { ITranslationKeys } from '../i18n/types';
import { ISelectOption, IValidationTypes } from '../types';

export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const validatePassword = (password: string, passwordAgain: string) => {
  if (password !== passwordAgain) {
    return 'dont-match';
  } else {
    return validatePasswordLength(password);
  }
};

export const validatePasswordLength = (password: string) => {
  if (password.length < 6) {
    return 'too-short';
  } else {
    return 'ok';
  }
};

export const getArrayLengthValidationObject = <TOption = undefined>(
  lengthToCompare: number,
  message: ITranslationKeys,
  arrayLengthShouldBeLower: boolean = false,
) => ({
  [IValidationTypes.wrongArrayLength]: (values: ISelectOption<TOption>[]) => {
    if (arrayLengthShouldBeLower) {
      return values.length < lengthToCompare || message;
    }

    return values.length > lengthToCompare || message;
  },
});

export const getEmptyArrayValidationObject = <TOption = undefined>(message: ITranslationKeys) => ({
  [IValidationTypes.emptyArray]: (values: ISelectOption<TOption>[]) => values.length > 0 || message,
});

export const getNotSelectedTeamValidationObject = <TOption = undefined>(
  message: ITranslationKeys,
) => ({
  [IValidationTypes.notSelectedTeam]: (selectedTeam?: ISelectOption<TOption> | null) =>
    (selectedTeam && selectedTeam.value !== 'all') || message,
});

export const isFormValid = <T extends {}>(errors: FieldErrors<T>) =>
  Object.keys(errors).length === 0;

/**
 * Workaround method to set valid input. Main filter is shared at multiple pages and
 * sometimes one page has some input required and other page doesn't. In this case,
 * we need to set validation rule to return true for the page that doesn't have required input.
 */
export const validFormValidationObject = () => ({ valid: () => true });
