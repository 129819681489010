import { useAppSelector } from '../../../app/hooks';
import {
  CountOfPlayersController,
  FormationTypeController,
  PartsController,
  PlayerController,
  SeasonsController,
  TeamController,
  TimeOnIceController,
} from '../../../components';
import { REQUIRED_PLAYER } from '../../../constants';
import { selectMainFilter } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { LinesIcon, PlayerCombinationsIcon, TeamsInPairsIcon } from '../../../icons';
import { INavigationContentKeys, IPageConfig } from '../../../types';
import { getNotSelectedTeamValidationObject } from '../../../utils';
import { FormationsContent } from './FormationsContent';
import { PairsContent } from './PairsContent';
import { PlayerCombinationsContent } from './PlayerCombinationsContent';

/** Custom hook returns config for FormationsPage tabs and widgets. */
export const usePageConfig = (): IPageConfig => {
  const { selectedTeam, selectedPlayer, formationType } = useAppSelector(selectMainFilter);

  return {
    baseFilter: (
      <>
        <SeasonsController required />
        <PartsController required />
        <TeamController />
      </>
    ),
    tabsConfig: [
      {
        id: INavigationContentKeys.pairs,
        name: ITranslationKeys.pairs,
        icon: <TeamsInPairsIcon />,
        description: ITranslationKeys.pairsDescription,
        ContentComponent: PairsContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <PlayerController
              captionTranslationKey={ITranslationKeys.playerInLines}
              rules={{ required: REQUIRED_PLAYER }}
              required
            />
            <CountOfPlayersController />
            <TimeOnIceController />
          </>
        ),
        additionalInput: <PlayerController />,
        disableLoadDataButton: !selectedPlayer,
      },
      {
        id: INavigationContentKeys.formations,
        name: ITranslationKeys.formations,
        icon: <LinesIcon />,
        description: ITranslationKeys.formationsDescription,
        ContentComponent: FormationsContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController />
            <PlayerController
              captionTranslationKey={ITranslationKeys.playerInLines}
              rules={{ required: false }}
            />
            <FormationTypeController />
            <TimeOnIceController />
          </>
        ),
        additionalInput: <FormationTypeController />,
        disableLoadDataButton: !formationType,
      },
      {
        id: INavigationContentKeys.playerCombinations,
        name: ITranslationKeys.playerCombinations,
        icon: <PlayerCombinationsIcon />,
        description: ITranslationKeys.playerCombinationsDescription,
        ContentComponent: PlayerCombinationsContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController
              rules={{
                validate: getNotSelectedTeamValidationObject(ITranslationKeys.pleaseSelectTeam),
              }}
              required
            />
            <CountOfPlayersController />
            <TimeOnIceController />
          </>
        ),
        disableLoadDataButton: !selectedTeam || selectedTeam.value === 'all',
      },
    ],
  };
};
