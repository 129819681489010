import { IRole, IRoleMediaTeam } from '../identity.types';

export const IAccessAnalyticsType = {
  accessCount: 'accessCount',
  uniqueAccessCount: 'uniqueAccessCount',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IAccessAnalyticsType = (typeof IAccessAnalyticsType)[keyof typeof IAccessAnalyticsType];

export const IScreenAnalyticsType = {
  screenCount: 'screenCount',
  uniqueScreenCount: 'uniqueScreenCount',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IScreenAnalyticsType = (typeof IScreenAnalyticsType)[keyof typeof IScreenAnalyticsType];

export const IAnalyticsType = {
  ...IAccessAnalyticsType,
  [IScreenAnalyticsType.screenCount]: IScreenAnalyticsType.screenCount,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IAnalyticsType = (typeof IAnalyticsType)[keyof typeof IAnalyticsType];

export const ITrackingUserType = {
  [IRole.team]: IRole.team,
  [IRole.media]: IRole.media,
  other: 'other',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITrackingUserType = (typeof ITrackingUserType)[keyof typeof ITrackingUserType];

export interface IAccessTrackingAnalytics {
  [IAccessAnalyticsType.accessCount]: number;
  [IAccessAnalyticsType.uniqueAccessCount]: number;
}

export interface IScreenTrackingAnalytics {
  [IScreenAnalyticsType.screenCount]: number;
  [IScreenAnalyticsType.uniqueScreenCount]: number;
}

export interface ITrackingAnalytics
  extends IAccessTrackingAnalytics,
    Omit<IScreenTrackingAnalytics, 'uniqueScreenCount'> {}

export interface IAccessAndScreenCounts extends Omit<ITrackingAnalytics, 'uniqueAccessCount'> {}

export interface IAccessLogAggregation {
  username: string;
  name: string;
  surname: string;
  team: string | null;
  analytics: IAccessAndScreenCounts;
  media?: string | null;
}

export interface IAccessLogAggregationEntity {
  id: string;
  type: ITrackingUserType;
  users: IAccessLogAggregation[];
  analytics: ITrackingAnalytics;
}
export type IAccessLogAggregationEntityRecord = Record<string, IAccessLogAggregationEntity>;

export interface IAccessLogAggregationDTO extends IAccessAndScreenCounts, IRoleMediaTeam {
  username: string;
  name: string;
  surname: string;
}

export interface IRoleLogDTO extends ITrackingAnalytics {
  date: string;
}

export interface IRoleLogAggregationDTO extends IRoleMediaTeam {
  aggregation: IRoleLogDTO[];
}

export interface IRoleLogAggregation {
  /** Type of tracking user. */
  type: ITrackingUserType;
  /** Name of team, media or just other (KOM / O2TV / other). */
  name: string;
  /** Analytics object with data. */
  analytics: ITrackingAnalytics;
}

export interface IRoleLogAggregationDate {
  date: string;
  [ITrackingUserType.team]: IRoleLogAggregation[];
  [ITrackingUserType.media]: IRoleLogAggregation[];
  [ITrackingUserType.other]: IRoleLogAggregation[];
}
export type IRoleLogAggregationDateRecord = Record<string, IRoleLogAggregationDate>;

export interface IScreenLogDTO extends IScreenTrackingAnalytics, IRoleMediaTeam {}

export interface IScreenLogAggregationDTO {
  tab: string;
  subTab: string | null;
  aggregation: IScreenLogDTO[];
}

export interface IScreenLog extends IRoleMediaTeam {
  analytics: IScreenTrackingAnalytics;
}

export interface ISubTab {
  name: string;
  aggregation: {
    [ITrackingUserType.team]: IScreenLog[];
    [ITrackingUserType.media]: IScreenLog[];
    [ITrackingUserType.other]: IScreenLog[];
  };
}

export type ISubTabRecord = Record<string, ISubTab>;

export interface ITab {
  name: string;
  subTabs: ISubTabRecord;
}

export type IScreenLogTabRecord = Record<string, ITab>;

export interface IEventScreenLogDTO {
  username: string;
  tab: string;
  subTab: string | null;
  ip: string;
  accessAt: string;
  team: string | null;
  media: string | null;
  role: IRole;
}

export interface IEventScreenLog extends IEventScreenLogDTO {
  teamId: string | null;
}

export interface IEventScreenLogTableData extends IEventScreenLog {}

export interface ILogHokejPlayerDTO {
  registrationNumber: string;
  name: string;
  surname: string;
  team: string | null;
  notificationCount: number;
  openedNotificationCount: number;
  clipsInPlaylist: number;
  videoCoachNotes: number;
}
export interface ILogHokejPlayer extends ILogHokejPlayerDTO {}

export interface IPlayerForTableHokejPlayer {
  registrationNumber: string;
  name: string;
  surname: string;
}

export interface ILogHokejPlayerTableData {
  player: IPlayerForTableHokejPlayer;
  team: string;
  value: {
    mainValue: number;
    notificationCount: number;
    openedNotificationCount: number;
  };
}

export type ITrackingTimeInterval = 'D' | 'W' | 'M' | 'S';
