import type { AppStartListening } from 'app/store';

import { IS_USER_DATASET, defaultTemplate } from '../../constants';
import { setDataSettingsDataTemplate } from '../dataSettingsFilter';
import { setDataTemplate } from '../tableFilter';
import {
  changeUserDatasetName,
  createUserDataset,
  deleteUserDatasetById,
} from './userDatasetsActions';

export const addUserDatasetsListeners = (startListening: AppStartListening) => {
  startListening({
    actionCreator: deleteUserDatasetById.fulfilled,
    effect: async (action, listenerApi) => {
      const { dataTemplate } = listenerApi.getState().tableFilter;
      listenerApi.dispatch(setDataSettingsDataTemplate(defaultTemplate));

      if (action.meta.arg === dataTemplate?.value) {
        listenerApi.dispatch(setDataTemplate(defaultTemplate));
      }
    },
  });

  startListening({
    actionCreator: changeUserDatasetName.fulfilled,
    effect: async (action, listenerApi) => {
      const { dataSettingsFilter, tableFilter } = listenerApi.getState();
      const { dataSettingsDataTemplate } = dataSettingsFilter;
      const { dataTemplate } = tableFilter;

      if (action.meta.arg.datasetId === dataSettingsDataTemplate?.value) {
        listenerApi.dispatch(
          setDataSettingsDataTemplate({
            ...dataSettingsDataTemplate,
            label: action.meta.arg.newName,
          }),
        );
      }

      if (action.meta.arg.datasetId === dataTemplate?.value) {
        listenerApi.dispatch(
          setDataTemplate({
            ...dataTemplate,
            label: action.meta.arg.newName,
          }),
        );
      }
    },
  });

  startListening({
    actionCreator: createUserDataset.fulfilled,
    effect: async (action, listenerApi) => {
      listenerApi.dispatch(
        setDataSettingsDataTemplate({
          value: action.payload.id,
          label: action.meta.arg.name,
          additionalValue: IS_USER_DATASET,
        }),
      );
    },
  });
};
