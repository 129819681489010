import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../../app/hooks';
import {
  Caption,
  SearchInput,
  SelectInput,
  SelectTeamOrMediaController,
  TcmsButton,
} from '../../../../../../components';
import {
  DEFAULT_ACCESS_LOG_FILTER_FORM_VALUES,
  allTeamOrMediaAllOption,
  trackingRoleOptions,
} from '../../../../../../constants';
import { teamAndMediaAccessAndScreenLogOptionsSelector } from '../../../../../../features';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { UserRoleIcon, UserSearchIcon } from '../../../../../../icons';
import {
  IAccessLogFilterForm,
  IIdentity,
  IMultiParamsVoid,
  ISelectOption,
  ITrackingUserType,
} from '../../../../../../types';
import { createClassNames, isFormValid } from '../../../../../../utils';
import './AccessLogFilter.styles.scss';

interface IAccessLogFilterProps {
  identities: IIdentity[];
  onSubmit: () => void;
  selectedUsernameFromParams: string | undefined;
}

const classNames = createClassNames('access-log-filter');

export const AccessLogFilter: FC<IAccessLogFilterProps> = ({
  identities,
  onSubmit,
  selectedUsernameFromParams,
}) => {
  const teamAndMediaOptions = useAppSelector(teamAndMediaAccessAndScreenLogOptionsSelector);
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const { control, formState, watch, setValue, reset } = useFormContext<IAccessLogFilterForm>();
  const { isDirty, errors } = formState;

  useEffect(() => {
    reset({
      ...DEFAULT_ACCESS_LOG_FILTER_FORM_VALUES,
      selectedUser: {
        label: selectedUsernameFromParams,
        value: selectedUsernameFromParams,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsernameFromParams]);

  const selectedRole = watch('selectedRole');
  const filteredTeamOrMediaOptions = teamAndMediaOptions.filter(
    option => option.additionalValue === selectedRole?.value || option.value === 'all',
  );

  const handleSelectRole = (value: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(value);
    setValue('selectedRole', value, {
      shouldDirty: true,
      shouldValidate: true,
    });

    onChange(value);
    setValue('selectedTeamOrMedia', [allTeamOrMediaAllOption], {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleSelectUser = (option: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(option);
    setValue('selectedUser', option, {
      shouldDirty: true,
      shouldValidate: true,
    });
    if (searchParams.has('username')) {
      setSearchParams(params => {
        params.delete('username');
        return params;
      });
    }
  };

  const userOptions: ISelectOption[] = identities.map(identity => ({
    label: identity.username,
    value: identity.username,
  }));

  return (
    <div className={classNames()}>
      <div>
        <Caption label={ITranslationKeys.userRole} variant='tcms' color='slate' />
        <Controller
          name='selectedRole'
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectInput
              onChange={newValue => handleSelectRole(newValue, onChange)}
              selected={value}
              options={trackingRoleOptions}
              placeholder={ITranslationKeys.defaultSelectPlaceholder}
              variant='tcms'
              iconComponent={<UserRoleIcon />}
            />
          )}
        />
      </div>
      <SelectTeamOrMediaController
        name='selectedTeamOrMedia'
        control={control}
        options={filteredTeamOrMediaOptions}
        disabled={selectedRole?.value === 'all' || selectedRole?.value === ITrackingUserType.other}
      />
      <div>
        <Caption label={ITranslationKeys.pickUser} variant='tcms' color='slate' />
        <Controller
          name='selectedUser'
          control={control}
          render={({ field: { value, onChange } }) => (
            <SearchInput
              onChange={value => handleSelectUser(value, onChange)}
              selected={value}
              options={userOptions}
              placeholder={`${t(ITranslationKeys.allUsers)}`}
              variant='tcms'
              iconComponent={<UserSearchIcon />}
            />
          )}
        />
      </div>
      <TcmsButton
        variant='filled'
        color='blue'
        label={ITranslationKeys.displayData}
        width={216}
        onClick={onSubmit}
        disabled={!isDirty || !isFormValid(errors)}
      />
    </div>
  );
};
