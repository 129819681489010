import { ColumnDef, SortingState, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { BasicColumnCell, BasicColumnHeader } from '../../../../../../components';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { IEventScreenLogTableData } from '../../../../../../types';
import {
  createClassNames,
  formatAccessAtToDate,
  getTranslationKeyForNavigationKey,
} from '../../../../../../utils';
import './columns.styles.scss';

const classNames = createClassNames('event-log-users-content-columns');

const columnHelper = createColumnHelper<IEventScreenLogTableData>();

const initialSorting: SortingState = [
  {
    id: 'accessAt',
    desc: true,
  },
];

const fixedColumns: ColumnDef<IEventScreenLogTableData, any>[] = [
  columnHelper.accessor('username', {
    header: props => (
      <BasicColumnHeader
        {...props}
        translationKey={ITranslationKeys.user}
        justifyContent='flex-start'
        allowSortIcon
        variant='tcms'
      />
    ),
    cell: props => (
      <BasicColumnCell {...props} variant='tcms'>
        <div className={[classNames(), classNames('username-cell')].join(' ')}>
          {props.row.original.username}
        </div>
      </BasicColumnCell>
    ),
    enableSorting: false,
  }),
  columnHelper.accessor('accessAt', {
    header: props => (
      <BasicColumnHeader
        {...props}
        translationKey={ITranslationKeys.dateAndTime}
        justifyContent='start'
        allowSortIcon
        variant='tcms'
      />
    ),
    cell: props => (
      <div className={[classNames(), classNames('access-at-cell')].join(' ')}>
        {formatAccessAtToDate(props.row.original.accessAt)}
      </div>
    ),
  }),
];

export const useColumnsConfig = () => {
  const { t } = useTranslation();

  const dynamicColumns: ColumnDef<IEventScreenLogTableData, any>[] = [
    columnHelper.display({
      id: 'event',
      header: props => (
        <BasicColumnHeader
          {...props}
          translationKey={ITranslationKeys.openedPage}
          justifyContent='start'
          allowSortIcon={false}
          variant='tcms'
        />
      ),
      cell: props => {
        const subTab = props.row.original.subTab
          ? ` - ${t(getTranslationKeyForNavigationKey(props.row.original.subTab))}`
          : '';

        return (
          <BasicColumnCell {...props} variant='tcms'>
            <div className={[classNames(), classNames('event-cell')].join(' ')}>
              {`${t(props.row.original.tab)}${subTab}`}
            </div>
          </BasicColumnCell>
        );
      },
    }),
  ];

  return {
    columns: fixedColumns.concat(dynamicColumns),
    initialSorting,
  };
};
