import {
  IGame,
  IGameBlock,
  IGameBlockDTO,
  IGameReportCompetition,
  IGameReportMatch,
  IGameReportTeamDTO,
  IGameReportTeams,
  IGameSummary,
  IGameSummaryDTO,
  IGoal,
} from '../../../types';
import { parseShot } from '../../../utils';

export const parseGameSummaryData = (data: IGameSummaryDTO, gameInfo: IGame): IGameSummary => {
  const goals = data.goals.map<IGoal>(
    ({ firstAssistsUuid, secondAssistsUuid, shooterUuid, shot, teamUuid, time, type }) => ({
      firstAssistsId: firstAssistsUuid,
      secondAssistsId: secondAssistsUuid,
      shooterId: shooterUuid,
      shot: shot ? parseShot(shot) : null,
      teamId: teamUuid,
      time,
      type,
    }),
  );

  return {
    goals,
    scorePeriods: {
      homeTeam: Object.values(data.score[gameInfo.homeTeamId]),
      awayTeam: Object.values(data.score[gameInfo.awayTeamId]),
    },
  };
};

export const parseGameSummaryBlockData = (data: IGameBlockDTO, gameInfo: IGame): IGameBlock => {
  const homeTeamCompetitionData = data.competition[gameInfo.homeTeamId];
  const awayTeamCompetitionData = data.competition[gameInfo.awayTeamId];
  const homeTeamMatchData = data.match[gameInfo.homeTeamId];
  const awayTeamMatchData = data.match[gameInfo.awayTeamId];

  const competition: IGameReportCompetition = {
    homeTeam: {
      averages: homeTeamCompetitionData.averages,
      index: homeTeamCompetitionData.index,
      players: homeTeamCompetitionData.players.map(({ player, stats }) => ({
        id: player,
        teamId: gameInfo.homeTeamId,
        stats: stats,
      })),
    },
    awayTeam: {
      averages: awayTeamCompetitionData.averages,
      index: awayTeamCompetitionData.index,
      players: awayTeamCompetitionData.players.map(({ player, stats }) => ({
        id: player,
        teamId: gameInfo.awayTeamId,
        stats: stats,
      })),
    },
  };

  const match: IGameReportMatch = {
    homeTeam: {
      stats: homeTeamMatchData.metrics,
      players: homeTeamMatchData.players.map(({ uuid, teamUuid, stats }) => ({
        id: uuid,
        teamId: teamUuid,
        stats: stats,
      })),
    },
    awayTeam: {
      stats: awayTeamMatchData.metrics,
      players: awayTeamMatchData.players.map(({ uuid, teamUuid, stats }) => ({
        id: uuid,
        teamId: teamUuid,
        stats: stats,
      })),
    },
  };

  return {
    competition,
    match,
  };
};

export const parseGameTeamsData = (data: IGameReportTeamDTO[]): IGameReportTeams => ({
  homeTeam: {
    id: data[0].team,
    stats: data[0].stats,
  },
  awayTeam: {
    id: data[1].team,
    stats: data[1].stats,
  },
});
