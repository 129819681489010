import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IBaseRequestBody, IShootout } from '../../types';
import { defaultCatchErrorCallback } from '../../utils';
import { parseShootoutData } from './parseShootouts';

interface IGetShootoutsDetailParams {
  competitionUuids: string[];
  teamUuid: string;
  body?: IBaseRequestBody;
}

export const getShootouts = createAsyncThunk<IShootout[], IGetShootoutsDetailParams>(
  'shootouts/getShootout',
  async ({ competitionUuids, teamUuid, body }) => {
    const getShootout: IShootout[] = await axiosInstance
      .post(`/shootout/${competitionUuids}/${teamUuid}`, body)
      .then(response => parseShootoutData(response.data))
      .catch(defaultCatchErrorCallback);

    return getShootout;
  },
);
