import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectGames, selectMainFilter, setSelectedGames, triggerContentReload } from '../features';
import { useRefetchContent } from '../hooks';
import { IHandleOnSubmitConfig, IMainFilterForm } from '../types';
import { getGamesOptions, parseUrlSelectedGames } from '../utils';

export const useShootoutEffects = (
  handleOnSubmit: (config?: IHandleOnSubmitConfig) => Promise<void>,
  isLoading?: boolean,
  isGoalieRequired?: boolean,
) => {
  const { selectedTeam, selectedGoalkeeper } = useAppSelector(selectMainFilter);
  const { games } = useAppSelector(selectGames);
  const dispatch = useAppDispatch();
  const { byId } = games;

  const { formState } = useFormContext<IMainFilterForm>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (Object.keys(byId).length > 0) {
      const gamesOptions = getGamesOptions(byId);
      const optionsToSelect = [...gamesOptions];

      const selectedGames = parseUrlSelectedGames(searchParams, gamesOptions);

      dispatch(setSelectedGames(selectedGames || optionsToSelect));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byId]);

  useEffect(() => {
    if (formState.dirtyFields.selectedTeam) {
      dispatch(triggerContentReload());
    }

    if (!isGoalieRequired) {
      dispatch(triggerContentReload());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoalkeeper, selectedTeam]);

  useRefetchContent({ handleOnSubmit, isLoading });
};
