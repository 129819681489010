import { useAppDispatch } from '../../../app/hooks';
import { goalShotCategoryOption, passTypeOptions } from '../../../constants';
import { setPassType, setShotCategory } from '../../../features';
import { IMetricWithDatasetMetric, ISelectOption } from '../../../types';

export const useSetPassesFilter = () => {
  const dispatch = useAppDispatch();

  const setPassesFilterSetter = (stat: IMetricWithDatasetMetric) => {
    const option = getPassMetricSubgroupOption(stat.metric.id);
    if (option) dispatch(setPassType(option));

    if (stat.metric.id.startsWith('gf_')) {
      dispatch(setShotCategory(goalShotCategoryOption));
    }
  };

  return { setPassesFilterSetter };
};

const getPassMetricSubgroupOption = (metricId: string): ISelectOption | undefined => {
  const metricIdWithoutPrefix =
    metricId.startsWith('gf_') || metricId.startsWith('xgf_')
      ? metricId.replace(/(.*)(?<=_)/, '')
      : metricId;

  if (metricIdWithoutPrefix.startsWith('cr.')) {
    return passTypeOptions.find(({ value }) => value === 'crossIce');
  }

  if (metricIdWithoutPrefix.startsWith('bn.')) {
    return passTypeOptions.find(({ value }) => value === 'behindTheNet');
  }

  if (metricIdWithoutPrefix.startsWith('1t.')) {
    return passTypeOptions.find(({ value }) => value === 'oneTimer');
  }

  return undefined;
};
