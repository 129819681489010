import { createSelector } from '@reduxjs/toolkit';

import {
  filterGoalkeepers,
  filterGoalkeepersToOptions,
  filterParts,
  filterPlayers,
  filterPlayersToOptions,
  filterTeams,
  filterTeamsToOptions,
} from '../../utils';
import {
  selectCompetitionsDetail,
  selectGoalkeepers,
  selectMainFilter,
  selectPlayers,
  selectTeams,
} from '../selectors';

export const filteredPartsSelector = createSelector(
  [selectMainFilter, selectCompetitionsDetail],
  (mainFilterState, competitionsDetailState) => {
    const { selectedSeasons, selectedParts } = mainFilterState;

    const selectedSeasonsIds = selectedSeasons.map(season => season.value);
    const selectedPartsIds = selectedParts.map(part => part.value);

    return filterParts(competitionsDetailState.byId, selectedSeasonsIds, selectedPartsIds);
  },
);

export const filteredTeamsSelector = createSelector(
  [selectTeams, filteredPartsSelector],
  (teams, filteredParts) => filterTeams(teams.byId, filteredParts),
);

export const filteredTeamsOptionsSelector = createSelector([filteredTeamsSelector], filteredTeams =>
  filterTeamsToOptions(filteredTeams),
);

export const filteredPlayersSelector = createSelector(
  [selectMainFilter, selectPlayers, filteredPartsSelector],
  ({ selectedTeam }, players, filteredParts) =>
    filterPlayers(players.byId, filteredParts, selectedTeam?.value),
);

export const filteredPlayersOptionsSelector = createSelector(
  [filteredPlayersSelector],
  filteredPlayers => filterPlayersToOptions(filteredPlayers),
);

export const filteredGoalkeepersSelector = createSelector(
  [selectMainFilter, selectGoalkeepers, filteredPartsSelector],
  ({ selectedTeam }, goalkeepers, filteredParts) =>
    filterGoalkeepers(goalkeepers.byId, filteredParts, selectedTeam?.value),
);

export const filteredGoalkeepersOptionsSelector = createSelector(
  [filteredGoalkeepersSelector],
  filteredGoalkeepers => filterGoalkeepersToOptions(filteredGoalkeepers),
);

export const filteredMainFilterDataSelector = createSelector(
  [
    filteredPartsSelector,
    filteredTeamsSelector,
    filteredTeamsOptionsSelector,
    filteredPlayersSelector,
    filteredPlayersOptionsSelector,
    filteredGoalkeepersSelector,
    filteredGoalkeepersOptionsSelector,
  ],
  (
    filteredParts,
    filteredTeams,
    filteredTeamsOptions,
    filteredPlayers,
    filteredPlayersOptions,
    filteredGoalkeepers,
    filteredGoalkeepersOptions,
  ) => ({
    filteredParts,
    filteredTeams,
    filteredTeamsOptions,
    filteredPlayers,
    filteredPlayersOptions,
    filteredGoalkeepers,
    filteredGoalkeepersOptions,
  }),
);
