import { COLORS_TCMS, logosPathsTcms, logosPathsTcmsMedia } from '../../../constants';
import { UserCircleIcon } from '../../../icons';
import { ITrackingUserType } from '../../../types';

export const BarTick = (props: any) => {
  const [identityId, identityType, count]: string[] = props.payload.value.split(';');

  const icon = identityType === ITrackingUserType.other ? <UserCircleIcon /> : null;
  const imageHref = getImageHref(identityId, identityType);

  return (
    <g transform={`translate(${props.x - 12},${props.y})`}>
      {icon}
      {imageHref ? <image href={imageHref} height='24' width='24' /> : null}
      <text x={12} y={40} fontSize='12px' textAnchor='middle' fill={COLORS_TCMS.slate[500]}>
        {count}
      </text>
    </g>
  );
};

const getImageHref = (identityId: string, identityType: string) => {
  if (identityType === ITrackingUserType.team) {
    return logosPathsTcms[identityId];
  }

  if (identityType === ITrackingUserType.media) {
    return logosPathsTcmsMedia[identityId];
  }

  return null;
};
