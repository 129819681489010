import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, To } from 'react-router-dom';

import { ITranslationKeys } from '../../i18n/types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './TabItem.styles.scss';

interface ITabItemProps {
  /** Item contents */
  label: ITranslationKeys | string;
  /** Is the item located in the main navigation? You have to set also `to` prop. */
  isNavigationBar?: boolean;
  /** Link to other page. You have to set also `isNavigationBar` prop to true. */
  to?: To;
  /** Is the item active? */
  isActive?: boolean;
  /** Is the item disabled? */
  disabled?: boolean;
  /** Optional click handler */
  onClick?: () => void;
}

const classNames = createClassNames('tab-item');

/** TabItem component for navigation bar and subnavigation bar. */
export const TabItem: FC<ITabItemProps> = ({
  isNavigationBar = false,
  isActive = false,
  label,
  to,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation();

  if (to) {
    return (
      <Link
        className={classNames({
          ...transformObjectKeysToKebabCase({
            navigationBarItem: isNavigationBar,
            active: isActive,
            disabled,
          }),
        })}
        data-testid={label}
        data-text={t(label)}
        {...{ to }}
      >
        {t(label)}
      </Link>
    );
  }

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          active: isActive,
          disabled,
        }),
      })}
      {...props}
      data-testid={label}
      data-text={t(label)}
    >
      {t(label)}
    </div>
  );
};
