import { MutableRefObject, useEffect, useState } from 'react';
import { UseFormReturn, useFormContext } from 'react-hook-form';

import { IHandleOnSubmitConfig, IMainFilterForm } from '../types';

/**
 * Hook gets methods from useFormContext (React Hook Form) and returns handleOnSubmit function,
 * that takes values from form, sets new default form values (reset) and submits form.
 * @param fetchCallback Callback function to handle submit for specific content.
 * @returns Function to handle submit. Just call it when you need to submit form.
 */
export const useHandleOnSubmit = (
  fetchCallback: (values: IMainFilterForm, config?: IHandleOnSubmitConfig) => void,
  isInitialMountRef?: MutableRefObject<boolean>, // // TODO: možná povinná hodnota
) => {
  const { reset, handleSubmit } = useFormContext<IMainFilterForm>();

  const handleOnSubmit = (config?: IHandleOnSubmitConfig) =>
    handleSubmit(
      values => {
        console.log('debug: form values', values); // TODO: smazat potom
        reset(values);
        fetchCallback(values, config);
        if (isInitialMountRef) {
          isInitialMountRef.current = false;
        }
      },
      errors => {
        // TODO: smazat potom
        console.log('invalid handler errors: ', errors);
      },
    )();

  return handleOnSubmit;
};

export const useHandleOnSubmitSubForm = <T extends {}>(
  fetchCallback: (values: T) => void,
  methods: UseFormReturn<T>,
  isInitialMountRef?: MutableRefObject<boolean>,
) => {
  const { reset, handleSubmit } = methods;

  const handleOnSubmit = () =>
    handleSubmit(
      values => {
        console.log('debug: sub form values', values); // TODO: smazat potom
        reset(values);
        fetchCallback(values);
        if (isInitialMountRef) {
          isInitialMountRef.current = false;
        }
      },
      errors => {
        // TODO: smazat potom
        console.log('invalid handler errors: ', errors);
      },
    )();

  return handleOnSubmit;
};

/**
 * Handles form submit on initial mount and returns handleOnSubmit function and last submitted values.
 * @param methods React hook form methods.
 * @param defaultValues Default values for form.
 * @returns HandleOnSubmit function and last submitted values.
 */
export const useHandleOnSubmitWithValues = <T extends {}>(
  methods: UseFormReturn<T>,
  defaultValues: T,
) => {
  const { reset, handleSubmit } = methods;

  const [submitValues, setSubmitValues] = useState<T>(defaultValues);

  useEffect(() => {
    handleOnSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit = handleSubmit(values => {
    reset(values);
    setSubmitValues(values);
  });

  return { submitValues, handleOnSubmit };
};
