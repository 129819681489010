export const isTokenExpired = (loginDate: string | undefined): boolean => {
  if (loginDate) {
    const loginTime = new Date(loginDate);
    const currentTime = new Date();
    const differenceInHours = (currentTime.getTime() - loginTime.getTime()) / 1000 / 3600;

    return Math.abs(Math.round(differenceInHours)) > 6;
  }

  return false;
};
