import { createSelector } from '@reduxjs/toolkit';

import { IGoalsOverview } from '../../../components';
import { IGame, IGameResult, IGoal, IPlayer, IScore } from '../../../types';
import { getPlayerFullName, secondsToMinutesAndSeconds } from '../../../utils';
import { selectGames, selectMainFilter, selectPlayers, selectTeams } from '../../selectors';

const getAssistingPlayerArray = (players: IPlayer[]): string[] =>
  players.filter(player => player !== undefined).map(player => getPlayerFullName(player));

const getTeam = (player: IPlayer, gameInfo: IGame, teamId?: string | null): 'home' | 'away' => {
  // new goal objects have teamId in DB, which is accurate way to get correct team
  if (teamId) {
    return teamId === gameInfo.awayTeamId ? 'away' : 'home';
  }

  // old ones don't have teamId in DB so there is inaccurate way to get it
  const isPlayerInAwayTeam = player.competitionTeams[gameInfo.competitionId]?.teamIds.includes(
    gameInfo.awayTeamId,
  );

  return isPlayerInAwayTeam ? 'away' : 'home';
};

const calculateScore = (score: IScore, team: 'home' | 'away'): IScore => {
  let homeGoals = score.home;
  let awayGoals = score.away;

  if (team === 'home') {
    homeGoals++;
  } else {
    awayGoals++;
  }

  return {
    home: homeGoals,
    away: awayGoals,
    state: score.state,
  };
};

const getCountOfPlayers = (goal: IGoal): string => {
  if (!goal.shot?.gameState) return '';
  const formated = goal.shot?.gameState?.split(':');
  if (!formated) return '';
  if (formated[0] === 'penaltyShot') return 'TS';
  return formated[0] + '/' + formated[1];
};

/** Transforms report data for GameShots component. */
export const goalOverviewConfigSelector = createSelector(
  [selectMainFilter, selectGames, selectPlayers],
  (mainFilter, gamesState, playersState) => {
    const { byId } = playersState;
    const { games, gamesReport } = gamesState;
    const { selectedGame } = mainFilter;

    if (!selectedGame) return [];

    const goals = gamesReport.goals;
    const gameInfo = games.byId[selectedGame.id];
    let score: IScore = {
      home: 0,
      away: 0,
      state: 'normal',
    };

    const goalOverviewConfig: IGoalsOverview[] = goals.map(goal => {
      const shootingPlayer = byId[goal.shooterId];
      const firstAssistPlayer = byId[goal.firstAssistsId];
      const secondAssistPlayer = byId[goal.secondAssistsId];
      const assistingPlayers = getAssistingPlayerArray([firstAssistPlayer, secondAssistPlayer]);
      const team = getTeam(shootingPlayer, gameInfo, goal.teamId);
      score = calculateScore(score, team);
      return {
        goalData: {
          player: getPlayerFullName(shootingPlayer),
          countOfPlayers: getCountOfPlayers(goal),
          time: secondsToMinutesAndSeconds(goal.time, true),
          assists: assistingPlayers,
          shot: goal.shot,
        },
        homeGoals: score.home,
        awayGoals: score.away,
        team,
      };
    });

    return goalOverviewConfig;
  },
);

/** Transforms report data for GameResult component. */
export const gameResultConfigSelector = createSelector(
  [selectMainFilter, selectGames, selectTeams],
  (mainFilter, gamesState, teamsState) => {
    const { byId } = teamsState;
    const { games, gamesReport } = gamesState;
    const { selectedGame } = mainFilter;

    if (!selectedGame) return undefined;

    const homeInfo = byId[selectedGame.homeTeamId];
    const awayInfo = byId[selectedGame.awayTeamId];
    const game = games.byId[selectedGame.id];

    const gameResultConfig: IGameResult = {
      home: {
        name: homeInfo.name,
        uuid: homeInfo.id,
        goals: game?.score.home,
        thirds: gamesReport.scorePeriods?.homeTeam,
      },
      away: {
        name: awayInfo.name,
        uuid: awayInfo.id,
        goals: game?.score.away,
        thirds: gamesReport.scorePeriods?.awayTeam,
      },
      scoreState: game?.score.state,
    };

    return gameResultConfig;
  },
);

export const reportConfigsSelector = createSelector(
  [goalOverviewConfigSelector, gameResultConfigSelector],
  (goalOverviewConfig, gameResultConfig) => {
    return {
      goalOverviewConfig,
      gameResultConfig,
    };
  },
);
