import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IMultiParamsVoid, IOnOffColors, IPlayerColors, ISelectOption } from '../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import { ISelectInputVariant } from '../types';
import './SelectOptionItem.styles.scss';

interface IMultiselectSettings {
  checked: boolean;
}

export interface ISelectOptionItemProps {
  /** Available option */
  option: ISelectOption;
  /**
   * Settings for multiselect variant of option.
   * If is not undefined, brings with self property `checked` which is value for checkbox.
   */
  multiselectSettings?: IMultiselectSettings;
  /** Adds styles for select all option. */
  isSelectAll?: boolean;
  /** Color variant of component. */
  color?: IPlayerColors | IOnOffColors;
  /** Style variant of input. */
  variant?: ISelectInputVariant;
  /** Function called when user clicks on option. */
  onChange: IMultiParamsVoid;
}

const classNames = createClassNames('select-option-item');

/**
 * Option component for select inputs.
 */
export const SelectOptionItem: FC<ISelectOptionItemProps> = ({
  option,
  multiselectSettings,
  isSelectAll,
  color,
  variant = 'filter',
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isSelectAll: isSelectAll || option.isAllOption,
          color,
          variant,
        }),
      })}
      onMouseDown={() => onChange(option)}
      data-testid='select-option-item'
    >
      {multiselectSettings && (
        <input
          className={classNames('checkbox', {
            ...transformObjectKeysToKebabCase({
              variant,
            }),
          })}
          type='checkbox'
          checked={multiselectSettings.checked}
          onChange={e => e.target.value}
        ></input>
      )}
      {option.icon && <div className={classNames('icon')}>{option.icon}</div>}
      <span>{t(option.label, { ...option.labelTranslationsOptions })}</span>
    </div>
  );
};
