import { Dispatch, FC, SetStateAction } from 'react';

import { ITranslationKeys } from '../../../i18n/types';
import { LoadIcon, MinusIcon, PlusIcon } from '../../../icons';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { Button } from '../../Button';
import './FilterButtons.styles.scss';

export interface IFilterButtonsProps {
  /** Is more filters opened? */
  openMore: boolean;
  /** Disables more filters button. */
  disableMoreFiltersButton?: boolean;
  /** Actual active tab. */
  disableLoadDataButton?: boolean;
  /** Function for set more filters opened. */
  setOpenMore: Dispatch<SetStateAction<boolean>>;
  /** Function for reload data. */
  onClickLoadData: () => void;
}

const classNames = createClassNames('filter-buttons');

/**
 * Filter buttons for more filters and reload data.
 */
export const FilterButtons: FC<IFilterButtonsProps> = ({
  openMore,
  disableMoreFiltersButton = false,
  disableLoadDataButton = false,
  setOpenMore,
  onClickLoadData,
}) => {
  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          openMore,
        }),
      })}
      data-testid='filter-buttons'
    >
      {!disableMoreFiltersButton && (
        <Button
          label={openMore ? ITranslationKeys.lessFilters : ITranslationKeys.moreFilters}
          variant='gray'
          iconComponent={openMore ? <MinusIcon /> : <PlusIcon />}
          onClick={() => setOpenMore(!openMore)}
          data-testid='more-filters-button'
        />
      )}
      <Button
        label={ITranslationKeys.loadData}
        variant='normal-underlined'
        iconComponent={<LoadIcon />}
        onClick={onClickLoadData}
        disabled={disableLoadDataButton}
      />
    </div>
  );
};
