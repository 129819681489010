import { FC } from 'react';

import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './CircleBar.styles.scss';

interface ICircleBarProps {
  /** Metric value of the team. */
  value: number;
  /** Based on team value we fill the inner circle diff color. */
  team: 'home' | 'away';
  /** bigger metric value to calculate the %. */
  calculateFrom?: number;
  /** If is set, the value will be displayed with n decimal points. */
  decimals?: number;
}

const classNames = createClassNames('circle-bar');

/** CircleBar UI component. */
export const CircleBar: FC<ICircleBarProps> = ({ value, team, calculateFrom, decimals }) => {
  const getPercentage = () => {
    if (calculateFrom && calculateFrom > value) {
      return value / calculateFrom;
    }
    return 1;
  };
  const getSize = () => `${getPercentage() * 100}%`;

  const size = getPercentage();
  const sizePercent = getSize();

  return (
    <div className={classNames()}>
      <div
        className={classNames('inner-circle', {
          ...transformObjectKeysToKebabCase({ team, small: size < 0.3 }),
        })}
        style={{ width: sizePercent, height: sizePercent }}
      >
        {!decimals ? value : value.toFixed(decimals)}
      </div>
    </div>
  );
};
