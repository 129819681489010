import { FC } from 'react';

import { IMetricSectionName } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './MetricsSectionCircle.styles.scss';

export interface IMetricsSectionCircleProps {
  sectionId: string;
}

const classNames = createClassNames('metrics-section-circle');

export const MetricsSectionCircle: FC<IMetricsSectionCircleProps> = ({ sectionId }) => {
  const isOnIceCategory = sectionId === IMetricSectionName.onIce;

  return (
    <div
      className={classNames({ ...transformObjectKeysToKebabCase({ onIce: isOnIceCategory }) })}
      data-testid='metrics-section-circle'
    >
      {isOnIceCategory ? 'T' : 'H'}
    </div>
  );
};
