import { FC, ImgHTMLAttributes } from 'react';

import { createClassNames } from '../../../utils';
import './ImageColumn.styles.scss';

const classNames = createClassNames('image-column');

interface IImageColumnProps extends ImgHTMLAttributes<HTMLImageElement> {
  variant?: 'default' | 'tcms';
}

export const ImageColumn: FC<IImageColumnProps> = ({ src, alt, variant = 'default' }) => {
  return <img className={classNames({ variant })} {...{ src, alt }} />;
};
