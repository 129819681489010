import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { IGameExpectedGoals, IPlayerExpectedGoals } from '../../../types';
import { createClassNames } from '../../../utils';
import { CircleBar } from '../../CircleBar';
import { GameExpectedGoalsStats } from '../GameExpectedGoalsStats';
import './GameExpectedGoals.styles.scss';

const classNames = createClassNames('game-expected-goals');

/**
 * GameExpectedGoals UI component.
 */
export const GameExpectedGoals: FC<IGameExpectedGoals> = ({ home, away }) => {
  const { t } = useTranslation();

  const sortPlayers = (players: IPlayerExpectedGoals[]) =>
    players.sort((a, b) => b.value - a.value);

  const sortedHome = sortPlayers(home.players);
  const sortedAway = sortPlayers(away.players);
  const maxSum = home.xG > away.xG ? home.xG : away.xG;
  const maxValue =
    sortedHome[0].value > sortedAway[0].value ? sortedHome[0].value : sortedAway[0].value;

  return (
    <div className={classNames()}>
      <div className={classNames('team')}>
        <GameExpectedGoalsStats players={sortedHome} team='home' maxValue={maxValue} />
        <CircleBar value={home.xG} team='home' calculateFrom={maxSum} decimals={2} />
      </div>
      <h3 className={classNames('title')}>{t(ITranslationKeys.expectedGoals)}</h3>
      <div className={classNames('team')}>
        <CircleBar value={away.xG} team='away' calculateFrom={maxSum} decimals={2} />
        <GameExpectedGoalsStats players={sortedAway} team='away' maxValue={maxValue} />
      </div>
    </div>
  );
};
