import { CSSProperties, FC } from 'react';

import { ITeamsMetricValues } from '../../types';
import { createClassNames, getMaxChartTicks } from '../../utils';
import { TeamMetricLegend } from '../TeamMetricLegend';
import './GameTimelineLegend.styles.scss';

export interface IGameTimelineLegendProps {
  /** Metric name. */
  metricName: string;
  /** Teams metric values for each period. */
  teamsMetricValues: ITeamsMetricValues;
  /** Max time in game. */
  maxTime: number;
}

const classNames = createClassNames('game-timeline-legend');

/** Shows legend with teams metrics for each period. Use with GameTimeline component chart. */
export const GameTimelineLegend: FC<IGameTimelineLegendProps> = ({
  metricName,
  teamsMetricValues,
  maxTime,
}) => {
  const { homeTeam, awayTeam } = teamsMetricValues;

  const ticksAmount = getMaxChartTicks(maxTime);
  const ticksOverflowedAmount = getMaxChartTicks(maxTime) % 4;
  const completePeriodsCount = Math.floor(ticksAmount / 4);
  const paddingRight = ticksOverflowedAmount === 1 ? 0 : '25px';

  const getPeriodLegend = (index: number, style?: CSSProperties) => (
    <div key={'period' + index} className={classNames('period-legend')} style={style}>
      <div className={classNames('period-legend__teams-box')}>
        <TeamMetricLegend
          metricName={metricName}
          metricValue={homeTeam.metricValues[index]}
          teamShortcut={homeTeam.shortcut}
        />
        <TeamMetricLegend
          metricName={metricName}
          metricValue={awayTeam.metricValues[index]}
          teamShortcut={awayTeam.shortcut}
          isAway
        />
      </div>
    </div>
  );

  return (
    <div className={classNames()} style={{ paddingRight }} data-testid='game-timeline-legend'>
      {Array.from({ length: completePeriodsCount }).map((_, index) => getPeriodLegend(index))}
      {ticksOverflowedAmount > 0 &&
        getPeriodLegend(completePeriodsCount, { flex: ticksOverflowedAmount / 4 })}
    </div>
  );
};
