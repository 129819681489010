import { FC } from 'react';

import { ILeftRight } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { PositionCircle } from '../../PositionCircle';
import './HeadToHeadPlayer.styles.scss';

export interface IHeadToHeadPlayerProps {
  displayName: string;
  position: string;
  team: 'home' | 'away';
  crossColorActive?: boolean;
  reversed?: boolean;
  border?: ILeftRight | 'bottom' | 'top';
}

const classNames = createClassNames('head-to-head-player');

/**
 * HeadToHeadPlayer UI component for HeadToHead component displaying informations about the player.
 */
export const HeadToHeadPlayer: FC<IHeadToHeadPlayerProps> = ({
  displayName,
  position,
  team,
  border,
  crossColorActive,
  reversed = false,
}) => {
  const convertedPosition = position === 'DE' ? 'DE' : 'FO';

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          border,
          crossColorActive,
          reversed,
        }),
      })}
      data-testid='head-to-head-player'
    >
      <div className={classNames('name')}>{displayName}</div>
      <div className={classNames('position')}>
        <PositionCircle position={convertedPosition} team={team} />
      </div>
    </div>
  );
};
