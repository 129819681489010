import { COLORS } from '../constants';
import { IPlayerColors } from '../types';

/**
 * @param color Color name of type IPlayerColors colors.
 * @returns color string
 */
export const getPlayerColor = (color: IPlayerColors | undefined): string => {
  if (color === IPlayerColors.red) return 'rgba(217,30,40,0.3)';
  if (color === IPlayerColors.purple) return 'rgba(139,50,252,0.3)';
  if (color === IPlayerColors.cyan) return 'rgba(13,112,195,0.3)';
  if (color === IPlayerColors.teal) return 'rgba(5,125,121,0.3)';
  if (color === IPlayerColors.orange) return 'rgba(244,102,1,0.3)';
  return 'rgba(0,0,0,0.3)';
};

/**
 * @param color Color name of type IPlayerColors colors.
 * @returns color string
 */
export const getSelectedPlayerColor = (color: IPlayerColors | undefined): string => {
  if (color === IPlayerColors.red) return COLORS.red[60];
  if (color === IPlayerColors.purple) return COLORS.purple[60];
  if (color === IPlayerColors.cyan) return COLORS.cyan[60];
  if (color === IPlayerColors.teal) return COLORS.teal[60];
  if (color === IPlayerColors.orange) return COLORS.orange[60];
  return COLORS.black.base;
};
