import { useMemo } from 'react';

import { useAppSelector } from '../app/hooks';
import { defaultTemplate } from '../constants';
import { selectDefinedDatasets, selectUserDatasets } from '../features';
import { IEntity, ISelectOption } from '../types';
import { createUserDatasetOptions } from '../utils';

export const useDataTemplateOptions = (entity: IEntity = IEntity.players) => {
  const { definedDatasets } = useAppSelector(selectDefinedDatasets);
  const userDatasets = useAppSelector(selectUserDatasets);

  const dataTemplateOptions: ISelectOption[] = useMemo(() => {
    if (definedDatasets) {
      const datasetOptions = Object.values(definedDatasets[entity].byId).map<ISelectOption>(
        playerDataset => ({
          value: playerDataset.id,
          label: playerDataset.name,
        }),
      );

      const userDatasetOptions = createUserDatasetOptions(userDatasets.byId, entity);

      const allDatasetOptions = userDatasetOptions.concat(datasetOptions);
      allDatasetOptions.unshift(defaultTemplate);
      return allDatasetOptions;
    }

    return [];
  }, [definedDatasets, userDatasets.byId, entity]);

  return dataTemplateOptions;
};
