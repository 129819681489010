import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppSelector } from '../../../app/hooks';
import { selectAuth, selectGoalkeepers, selectPlayers, selectTeams } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import {
  NegativeActiveIcon,
  NegativeIcon,
  NeutralActiveIcon,
  NeutralIcon,
  PositiveActiveIcon,
  PositiveIcon,
} from '../../../icons';
import { IClipRating, IGoalkeeper, IPlayer, ISharedVideo } from '../../../types';
import {
  createClassNames,
  formatPlayer,
  postVideocoachNote,
  prepareVideocoachNote,
} from '../../../utils';
import { Button } from '../../Button';
import { InnerModal } from '../../InnerModal';
import { Input } from '../../Input';
import { VideocoachHeader } from '../VideocoachHeader';
import './VideocoachModal.styles.scss';

interface IVideocoachModalProps {
  /** Is the modal opened? */
  open: boolean;
  /** Function that handles modal close */
  onClose: () => void;
  /** Selected video */
  video: ISharedVideo;
}

const classNames = createClassNames('videocoach-modal');

interface IPlayersByPosition {
  backwards: IPlayer[];
  forwards: IPlayer[];
  goalkeepers: IGoalkeeper[];
}

/**
 * VideocoachModal UI component.
 */
export const VideocoachModal: FC<IVideocoachModalProps> = ({ open, onClose, video }) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectAuth);
  const { byId: playerRecord } = useAppSelector(selectPlayers);
  const { byId: teamRecord } = useAppSelector(selectTeams);
  const { byId: goalkeeperRecord } = useAppSelector(selectGoalkeepers);

  const [selectedRating, setSelectedRating] = useState<IClipRating>('thumbs-up');
  const [clipName, setClipName] = useState<string>('');
  const [clipNote, setClipNote] = useState<string>('');
  const [players, setPlayers] = useState<IPlayersByPosition>({
    backwards: [],
    forwards: [],
    goalkeepers: [],
  });
  const [selectedPlayers, setSelectedPlayers] = useState<string[]>([]);

  useEffect(() => {
    setPlayers(prepareVideocoachPlayers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  const prepareVideocoachPlayers = () => {
    if (!user) return { backwards: [], forwards: [], goalkeepers: [] };

    const playerIds = user.teamId ? teamRecord[user.teamId].playerIds : [];
    const goalkeeperIds = user.teamId ? teamRecord[user.teamId].goalkeeperIds : [];

    const videocoachPlayers = playerIds
      .map(playerId => playerRecord[playerId])
      .filter(player => player.email !== null && player.currentTeam === user.teamId);

    const videocoachGoalkeepers = goalkeeperIds
      .map(goalkeeperId => goalkeeperRecord[goalkeeperId])
      .filter(goalkeeper => goalkeeper.email !== null && goalkeeper.currentTeam === user.teamId);

    return {
      backwards: videocoachPlayers.filter(player => player.position === 'DE'),
      forwards: videocoachPlayers.filter(player => player.position === 'FO'),
      goalkeepers: videocoachGoalkeepers,
    };
  };

  const sendVideoClip = () => {
    if (!clipName) {
      toast.error(t(ITranslationKeys.clipNameEmpty), { toastId: ITranslationKeys.clipNameEmpty });
    } else if (selectedPlayers.length === 0) {
      toast.error(t(ITranslationKeys.zeroPlayersSelected), {
        toastId: ITranslationKeys.zeroPlayersSelected,
      });
    } else {
      const note = prepareVideocoachNote(
        video,
        clipName,
        clipNote,
        selectedRating,
        selectedPlayers,
      );
      postVideocoachNote(note)
        .then(res => {
          toast.success(t(ITranslationKeys.noteSend), { toastId: ITranslationKeys.noteSend });
        })
        .catch(err => {
          toast.error(t(ITranslationKeys.noteNotSend), { toastId: ITranslationKeys.noteNotSend });
          console.log('Post videocoach note err:', err);
        })
        .finally(() => {
          clearNote();
        });
    }
  };

  const clearNote = () => {
    setClipName('');
    setClipNote('');
    setSelectedPlayers([]);
    setSelectedRating('thumbs-up');
  };

  const handleSelectRating = (value: IClipRating) => {
    setSelectedRating(value);
  };

  const handleClipName: ChangeEventHandler<HTMLInputElement> = event => {
    setClipName(event.target.value);
  };

  const handleClipNote: ChangeEventHandler<HTMLTextAreaElement> = event => {
    setClipNote(event.target.value);
  };

  const handleSelectPlayer = (playerId: string) => {
    if (selectedPlayers.includes(playerId)) {
      setSelectedPlayers(prevState => prevState.filter(id => id !== playerId));
    } else {
      setSelectedPlayers(prevState => [...prevState, playerId]);
    }
  };

  return (
    <InnerModal open={open} position='right'>
      <div className={classNames()}>
        <VideocoachHeader onClose={onClose} />
        <div className={classNames('inputs')}>
          <div className={classNames('item')}>
            <p>{t(ITranslationKeys.clipName)}</p>
            <Input variant='filter' type='text' value={clipName} onChange={handleClipName} />
          </div>
          <div className={classNames('item')}>
            <p>{t(ITranslationKeys.actionRating)}</p>
            <div className={classNames('rating-container')}>
              <div
                className={classNames('rating-button')}
                onClick={() => handleSelectRating('thumbs-up')}
              >
                {selectedRating === 'thumbs-up' ? <PositiveActiveIcon /> : <PositiveIcon />}
              </div>
              <div
                className={classNames('rating-button')}
                onClick={() => handleSelectRating('info')}
              >
                {selectedRating === 'info' ? <NeutralActiveIcon /> : <NeutralIcon />}
              </div>
              <div
                className={classNames('rating-button')}
                onClick={() => handleSelectRating('thumbs-down')}
              >
                {selectedRating === 'thumbs-down' ? <NegativeActiveIcon /> : <NegativeIcon />}
              </div>
            </div>
          </div>
          <div className={classNames('item', { single: true })}>
            <p>{t(ITranslationKeys.situationNote)}</p>
            <textarea value={clipNote} onChange={handleClipNote} />
          </div>
        </div>
        <div className={classNames('players')}>
          <h3>{t(ITranslationKeys.noteReceivers)}:</h3>
          {Object.keys(players).map(position => (
            <div key={position} className={classNames('position')}>
              <p>{t(ITranslationKeys[position as keyof typeof ITranslationKeys])}</p>
              <div className={classNames('players-container')}>
                {players[position as keyof typeof players].map(player => (
                  <div
                    key={player.id}
                    className={classNames('player', {
                      active: selectedPlayers.includes(player.id),
                    })}
                    onClick={() => handleSelectPlayer(player.id)}
                  >
                    {formatPlayer(player)}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <Button size='large' label={ITranslationKeys.sendToPlayers} onClick={sendVideoClip} />
      </div>
    </InnerModal>
  );
};
