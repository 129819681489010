import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IWowyState } from '../../types';
import { getWowy } from './wowyActions';

const initialState: IWowyState = {
  wowy: undefined,
  isLoading: false,
  error: undefined,
  isLoadedFirstTime: false,
  activeRows: { together: true, separately: true, teammateSeparately: true },
  wowyPercentiles: undefined,
  isWowyPercentilesActive: undefined,
  isWowyPercentilesLoading: undefined,
  errorWowyPercentiles: undefined,
};

export const wowySlice = createSlice({
  name: 'wowy',
  initialState,
  reducers: {
    setActiveRowsTogether: (state, action: PayloadAction<boolean>) => {
      state.activeRows.together = action.payload;
    },
    setActiveRowsSeparately: (state, action: PayloadAction<boolean>) => {
      state.activeRows.separately = action.payload;
    },
    setActiveRowsTeammateSeparately: (state, action: PayloadAction<boolean>) => {
      state.activeRows.teammateSeparately = action.payload;
    },
    setIsWowyPercentilesActive: (state, action: PayloadAction<boolean>) => {
      state.isWowyPercentilesActive = action.payload;
    },
    resetWowyLoadedData: state => {
      state.wowy = undefined;
      state.wowyPercentiles = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getWowy.pending, (state, action) => {
        if (action.meta.arg.isPercentile) {
          state.isWowyPercentilesLoading = true;
          state.errorWowyPercentiles = undefined;
        } else {
          state.isLoading = true;
          state.error = undefined;
        }
      })
      .addCase(getWowy.fulfilled, (state, action) => {
        if (action.meta.arg.isPercentile) {
          state.wowyPercentiles = action.payload;
          state.isWowyPercentilesLoading = false;
        } else {
          state.wowy = action.payload;
          state.isLoading = false;
          state.isLoadedFirstTime = true;
        }
      })
      .addCase(getWowy.rejected, (state, action) => {
        if (action.meta.arg.isPercentile) {
          state.isWowyPercentilesLoading = false;
          state.errorWowyPercentiles = action.error.message;
        } else {
          state.isLoading = false;
          state.error = action.error.message;
        }
      });
  },
});

export const {
  setActiveRowsTogether,
  setActiveRowsSeparately,
  setActiveRowsTeammateSeparately,
  setIsWowyPercentilesActive,
  resetWowyLoadedData,
} = wowySlice.actions;

export const wowyReducer = wowySlice.reducer;
