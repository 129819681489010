import {
  IBasicShotsStats,
  IBasicShotsStatsDTO,
  IGame,
  IGameDTO,
  IGameGraphicOverview,
  IGameGraphicOverviewDTO,
  IGraphicOverviewPlayer,
  IGraphicOverviewPlayerDTO,
  IGraphicOverviewShotsInfo,
  IGraphicOverviewShotsInfoDTO,
} from '../../../types';
import { parseShotsData } from '../../../utils';

export const parseGamesData = (data: IGameDTO[]): IGame[] =>
  data.map(game => ({
    id: game.uuid,
    competitionId: game.competitionUuid,
    homeTeamId: game.homeTeamUuid,
    awayTeamId: game.awayTeamUuid,
    date: game.date,
    length: game.length,
    score: game.score,
  }));

const parseShotsInfo = (shotsInfo: IGraphicOverviewShotsInfoDTO): IGraphicOverviewShotsInfo => ({
  c: shotsInfo.c,
  ld: parseShotsInfoStats(shotsInfo.ld),
  md: parseShotsInfoStats(shotsInfo.md),
  hd: parseShotsInfoStats(shotsInfo.hd),
});

const parseShotsInfoStats = (shotsInfoStats: IBasicShotsStatsDTO): IBasicShotsStats => ({
  ...shotsInfoStats,
  xG: shotsInfoStats.xg,
});

const parseGamePreviewPlayers = (players: IGraphicOverviewPlayerDTO[]): IGraphicOverviewPlayer[] =>
  players.map(player => ({
    ...player,
    xG: player.xg,
  }));

export const parseGamePreviewData = (
  data: IGameGraphicOverviewDTO,
  gameInfo: IGame,
): IGameGraphicOverview => {
  const gamePreview: IGameGraphicOverview = {
    matchId: gameInfo.id,
    homeTeam: {
      teamId: gameInfo.homeTeamId,
      expectedWin: data.expectedWin[gameInfo.homeTeamId],
      players: parseGamePreviewPlayers(data.players[gameInfo.homeTeamId]),
      scorePeriods: Object.values(data.score[gameInfo.homeTeamId]),
      shots: parseShotsData(data.shots[gameInfo.homeTeamId]),
      shotsInfo: parseShotsInfo(data.shotsInfo[gameInfo.homeTeamId]),
    },
    awayTeam: {
      teamId: gameInfo.awayTeamId,
      expectedWin: data.expectedWin[gameInfo.awayTeamId],
      players: parseGamePreviewPlayers(data.players[gameInfo.awayTeamId]),
      scorePeriods: Object.values(data.score[gameInfo.awayTeamId]),
      shots: parseShotsData(data.shots[gameInfo.awayTeamId]),
      shotsInfo: parseShotsInfo(data.shotsInfo[gameInfo.awayTeamId]),
    },
    heatmap: data.heatmap,
  };

  return gamePreview;
};
