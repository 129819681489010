import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITablePaginationConfig, OverviewCard, Skeleton } from '../../../../../../components';
import { ITranslationKeys } from '../../../../../../i18n/types';
import {
  IHokejPlayerTrackingFilterForm,
  ILogHokejPlayer,
  ISelectOption,
} from '../../../../../../types';
import {
  createClassNames,
  formatDateByLanguage,
  getLogHokejPlayerTotals,
} from '../../../../../../utils';
import { useColumnsConfigHokejPlayer } from '../../../hooks';
import { HokejPlayerTableContainer } from '../HokejPlayerTableContainer';
import './HokejPlayerContent.styles.scss';
import { useDataForTable } from './useDataForTable';

export interface IHokejPlayerContentProps {
  /** Submitted form values */
  submitValues: IHokejPlayerTrackingFilterForm;
  /** Loaded data */
  data: ILogHokejPlayer[] | undefined;
  /** Error */
  error: unknown;
  /** Is data fetching? */
  isFetching: boolean;
}

const paginationConfig: ITablePaginationConfig = {
  itemsOnPage: 10,
};

const classNames = createClassNames('hokej-player-content');

export const HokejPlayerContent: FC<IHokejPlayerContentProps> = ({
  submitValues,
  data,
  error,
  isFetching,
}) => {
  const { attendance, dateFromTo } = submitValues;

  const { t } = useTranslation();
  const { columns, initialSorting } = useColumnsConfigHokejPlayer();
  const { columns: columnsClickthrough, initialSorting: initialSortingClickthrough } =
    useColumnsConfigHokejPlayer(true);
  const { dataForTables, playersWithAttendanceCount } = useDataForTable(data, submitValues);

  if (isFetching) {
    return (
      <div className={classNames()}>
        <Skeleton height={126} borderRadius={8} />
        <div className={classNames('tables')}>
          <Skeleton height={400} borderRadius={8} />
          <Skeleton height={400} borderRadius={8} />
          <Skeleton height={400} borderRadius={8} />
        </div>
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={classNames({ error: true })}>{t(ITranslationKeys.defaultErrorMessage)}</div>
    );
  }

  const { notificationCount, openedNotificationCount } = getLogHokejPlayerTotals(data);
  const clickthroughRate = (openedNotificationCount / notificationCount) * 100;
  const totalPlayersWithSavedClips = data.reduce(
    (acc, item) => (item.clipsInPlaylist ? acc + 1 : acc),
    0,
  );

  const totalNotesSent = data.reduce((acc, item) => acc + item.videoCoachNotes, 0);
  const dateFrom = dateFromTo?.from ? formatDateByLanguage(new Date(dateFromTo.from), 'P') : '';
  const dateTo = dateFromTo?.to ? formatDateByLanguage(new Date(dateFromTo.to), 'P') : '';

  return (
    <div className={classNames()}>
      <div className={classNames('overview-card-list')}>
        <OverviewCard
          label={ITranslationKeys.registeredPlayers}
          value={data.length.toString()}
          variant='gradient'
        />
        <OverviewCard
          label={ITranslationKeys.sentLinks}
          value={notificationCount.toString()}
          variant='gradient'
        />
        <OverviewCard
          label={ITranslationKeys.clickthroughRate}
          value={isNaN(clickthroughRate) ? '0%' : clickthroughRate.toFixed(1) + '%'}
          variant='gradient'
        />
        <OverviewCard
          label={ITranslationKeys.playersWithAttendance}
          value={playersWithAttendanceCount.toString()}
          labelValue={getAttendanceLabel(attendance)}
          variant='gradient'
        />
        <OverviewCard
          label={ITranslationKeys.totalPlayersWithSavedClips}
          value={totalPlayersWithSavedClips.toString()}
          variant='gradient'
        />
        <OverviewCard
          label={ITranslationKeys.totalNotesSent}
          value={totalNotesSent.toString()}
          variant='gradient'
        />
      </div>
      <div className={classNames('tables')}>
        <HokejPlayerTableContainer
          {...{ paginationConfig, dateFrom, dateTo }}
          data={dataForTables?.clickthroughRateData}
          columns={columnsClickthrough}
          initialSorting={initialSortingClickthrough}
          label={ITranslationKeys.clickthroughRate}
          isClickthrough
        />
        <HokejPlayerTableContainer
          {...{ paginationConfig, dateFrom, dateTo }}
          data={dataForTables?.clipsInPlaylistData}
          columns={columns}
          initialSorting={initialSorting}
          label={ITranslationKeys.numberOfClipsInPlaylist}
        />
        <HokejPlayerTableContainer
          {...{ paginationConfig, dateFrom, dateTo }}
          data={dataForTables?.videoCoachNotesData}
          columns={columns}
          initialSorting={initialSorting}
          label={ITranslationKeys.videocoachNotesSent}
        />
      </div>
    </div>
  );
};

const getAttendanceLabel = (attendance: ISelectOption | undefined) => {
  if (!attendance) return '';
  if (attendance.value === 'all') return '>50 %';
  if (attendance.value === '0') return '0 %';
  if (attendance.value === '100') return '100 %';

  return `>${attendance.value} %`;
};
