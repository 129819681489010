import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MainTrackingFilter } from '../../../../components';
import { useHandleOnSubmitWithValues } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { useGetScreenLogAggregationEcQuery } from '../../../../services/api';
import { IMainTrackingFilterForm } from '../../../../types';
import {
  createClassNames,
  filterScreenLogData,
  getDefaultMainTrackingFormValues,
} from '../../../../utils';
import { ScreenChartBox } from '../components';
import { useScreenChartData } from '../hooks';
import './ExtraCoachPage.styles.scss';

const classNames = createClassNames('extra-coach-page');

export const ExtraCoachPage = () => {
  const methods = useFormContext<IMainTrackingFilterForm>();

  const [selectedScreen, setSelectedScreen] = useState<ITranslationKeys | string>();

  const { submitValues, handleOnSubmit } = useHandleOnSubmitWithValues(
    methods,
    getDefaultMainTrackingFormValues(true),
  );
  const { selectedAnalytics, dateFromTo } = submitValues;

  const { data, error, isFetching } = useGetScreenLogAggregationEcQuery(dateFromTo, {
    selectFromResult: result => ({
      ...result,
      data: filterScreenLogData(result.data, submitValues),
    }),
  });

  const { t } = useTranslation();

  const { tabsData } = useScreenChartData(data, selectedAnalytics, selectedScreen);

  return (
    <div className={classNames()}>
      <form noValidate>
        <MainTrackingFilter onSubmit={handleOnSubmit} isScreen />
      </form>
      <div className={classNames('container')}>
        <ScreenChartBox
          data={tabsData}
          header={t(ITranslationKeys.screens).toString()}
          {...{ submitValues, selectedScreen, setSelectedScreen, error, isFetching }}
        />
      </div>
    </div>
  );
};
