import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IDumpInOutDTO, IShotsRequestBody, IZones, IZonesDTO } from '../../types';
import { buildPath, defaultCatchErrorCallback } from '../../utils';
import { parseDumpInOutData, parseZoneData } from './parseZones';

interface IZoneBaseRequestParams {
  suffix?: 'formation' | 'opponent' | 'team';
  competitionsUuids: string[];
  teamUuid: string;
  body?: IShotsRequestBody;
}

interface IGetZoneParams extends IZoneBaseRequestParams {
  spot?: string;
}

interface IGetDumpInOutParams extends IZoneBaseRequestParams {}

const getZonesRequestUrl = (params: Omit<IGetZoneParams, 'body'>, zoneBaseApi: string): string => {
  const { competitionsUuids, teamUuid, spot, suffix } = params;
  const base = [zoneBaseApi, competitionsUuids, teamUuid].join('/');

  return buildPath(base, [suffix, spot]);
};

const getZoneEntryExitRequest = async ({
  competitionsUuids,
  teamUuid,
  body,
  spot,
  suffix,
  isExit,
}: IGetZoneParams & { isExit?: boolean }) => {
  const baseUrl = getZonesRequestUrl(
    { competitionsUuids, teamUuid, spot, suffix },
    isExit ? '/zoneExit' : '/zoneEntry',
  );
  const zones: IZones = await axiosInstance
    .post<IZonesDTO>(baseUrl, body ?? {})
    .then(response => parseZoneData(response.data))
    .catch(defaultCatchErrorCallback);

  return zones;
};

const getDumpInOutRequest = async ({
  competitionsUuids,
  teamUuid,
  body,
  suffix,
  isExit,
}: IGetDumpInOutParams & { isExit?: boolean }) => {
  const baseUrl = getZonesRequestUrl(
    { competitionsUuids, teamUuid, suffix },
    isExit ? '/dumpOut' : '/dumpIn',
  );
  const dumpoInOuts: IZones = await axiosInstance
    .post<IDumpInOutDTO>(baseUrl, body ?? {})
    .then(response => parseDumpInOutData(response.data))
    .catch(defaultCatchErrorCallback);

  return dumpoInOuts;
};

export const getZoneExit = createAsyncThunk<IZones, IGetZoneParams>(
  'zones/getZoneExit',
  async params => getZoneEntryExitRequest({ ...params, isExit: true }),
);

export const getDumpout = createAsyncThunk<IZones, IGetDumpInOutParams>(
  'zones/getDumpout',
  async params => getDumpInOutRequest({ ...params, isExit: true }),
);

export const getZoneEntry = createAsyncThunk<IZones, IGetZoneParams>(
  'zones/getZoneEntry',
  async params => getZoneEntryExitRequest(params),
);

export const getDumpin = createAsyncThunk<IZones, IGetDumpInOutParams>(
  'zones/getDumpin',
  async params => getDumpInOutRequest(params),
);

export const getZoneEntryAndDumpin = createAsyncThunk<IZones, IGetZoneParams>(
  'zones/getZoneEntryAndDumpin',
  async ({ spot, ...params }) => {
    const zoneEntries = await getZoneEntryExitRequest({ ...params, spot });
    const dumpins = await getDumpInOutRequest(params);

    return {
      for: zoneEntries.for.concat(dumpins.for),
      against: zoneEntries.against.concat(dumpins.against),
    };
  },
);

export const getZoneExitAndDumpout = createAsyncThunk<IZones, IGetZoneParams>(
  'zones/getZoneExitAndDumpout',
  async ({ spot, ...params }) => {
    const zoneExits = await getZoneEntryExitRequest({ ...params, spot, isExit: true });
    const dumpouts = await getDumpInOutRequest({ ...params, isExit: true });

    return {
      for: zoneExits.for.concat(dumpouts.for),
      against: zoneExits.against.concat(dumpouts.against),
    };
  },
);
