import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../app/hooks';
import { selectTeams, videoRecordWithEditsSelector } from '../../../features';
import { useMultiselect } from '../../../hooks';
import { ITranslationKeys } from '../../../i18n/types';
import { DownloadIcon } from '../../../icons';
import { IVideoRecord } from '../../../types';
import { createClassNames, prepareDownloadVideo } from '../../../utils';
import { Button } from '../../Button';
import { CheckboxButton } from '../../CheckboxButton';
import { VideoCenterSelectItemContent } from '../VideoCenterSelectItemContent';
import { useOnDownloadVideos } from '../hooks';
import { ISharedVideoCenterSelectListProps } from '../types';
import './VideoCenterSelectList.styles.scss';

export type IVideoCenterSelectList<TOption = undefined> =
  ISharedVideoCenterSelectListProps<TOption> & {
    /** Object with videos data. */
    videosRecord: IVideoRecord;
  };

const classNames = createClassNames('video-center-select-list');

/** Renders video center list and allows to multi select videos, select all videos or download videos. */
export const VideoCenterSelectList = <TOption extends any = undefined>({
  options,
  selected,
  onChange,
  hasSelectAllOption = true,
  color,
  shouldCheckFullBody = false,
  categoryKey,
  playerOrGoalkeeperRecord,
  videosRecord,
  onPlay,
  onVideocoachNote,
  onCroppedTime,
}: IVideoCenterSelectList<TOption>) => {
  const teams = useAppSelector(selectTeams);
  const { edits } = useAppSelector(videoRecordWithEditsSelector);

  const { t } = useTranslation();

  const { downloading, onDownload } = useOnDownloadVideos();

  const handleDownload = () => {
    const downloadVideos = [
      ...selected.map(option =>
        prepareDownloadVideo(
          videosRecord[option.value],
          playerOrGoalkeeperRecord,
          teams.byId,
          categoryKey,
        ),
      ),
    ];

    onDownload(downloadVideos);
  };

  const { selectedAll, onChangeToggle, onChangeToggleSelectAll } = useMultiselect<TOption>({
    options,
    selected,
    onChange,
    hasSelectAllOption,
  });

  return (
    <div className={classNames()}>
      <h2 className={classNames('label')}>{t(categoryKey)}</h2>
      {hasSelectAllOption && (
        <div className={classNames('all-button')}>
          <CheckboxButton checked={selectedAll} onClick={onChangeToggleSelectAll}>
            <p>{t(ITranslationKeys.selectAll)}</p>
          </CheckboxButton>
        </div>
      )}
      <div className={classNames('options-box')}>
        <div className={classNames('options-box__options')}>
          {options
            .sort((a, b) => {
              const videoA = videosRecord[a.value];
              const videoB = videosRecord[b.value];

              const dateA = new Date(videoA.matchDate).getTime();
              const dateB = new Date(videoB.matchDate).getTime();

              if (dateA > dateB) {
                return 1;
              } else if (dateA < dateB) {
                return -1;
              }

              if (videoA.matchId > videoB.matchId) {
                return 1;
              } else if (videoA.matchId < videoB.matchId) {
                return -1;
              }

              return videoA.videoTime - videoB.videoTime;
            })
            .map(option => {
              const isSelected = selected.some(item => item.value === option.value);
              const isEdited = edits.some(item => item.videoUuid === option.value);
              const video = videosRecord[option.value];

              if (!video) return null;

              const player = playerOrGoalkeeperRecord[video.playerId];

              return (
                <CheckboxButton
                  key={option.value}
                  checked={isSelected}
                  onClick={() => onChangeToggle(option)}
                  {...{ color, shouldCheckFullBody }}
                >
                  <VideoCenterSelectItemContent
                    key={option.value}
                    {...{ ...player, ...video }}
                    homeTeam={video.homeTeamId ? teams.byId[video.homeTeamId].shortcut : undefined}
                    awayTeam={video.awayTeamId ? teams.byId[video.awayTeamId].shortcut : undefined}
                    isEdited={isEdited}
                    onPlay={() => onPlay(option.value)}
                    onVideocoachNote={
                      onVideocoachNote ? () => onVideocoachNote(option.value) : undefined
                    }
                    onCroppedTime={onCroppedTime ? () => onCroppedTime(option.value) : undefined}
                    shouldPlayImmediately={options.length === 1}
                  />
                </CheckboxButton>
              );
            })}
        </div>
      </div>
      <Button
        label={ITranslationKeys.downloadSelectedVideos}
        disabled={selected.length === 0 || downloading}
        iconComponent={<DownloadIcon />}
        onClick={handleDownload}
      />
    </div>
  );
};
