import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Caption, HeadToHead, Loading, NoData, SelectInput } from '../../../../components';
import { countOfPlayersH2HOptions } from '../../../../constants';
import {
  filteredMainFilterDataSelector,
  getHeadToHead,
  selectGames,
  selectMainFilter,
  selectPlayers,
  selectTeams,
  setCountOfPlayers,
} from '../../../../features';
import { useHandleOnSubmit, useLocationPaths } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { IBaseRequestBody, IH2HCategory, IMainFilterForm, ISelectOption } from '../../../../types';
import {
  createClassNames,
  formatDateByLanguage,
  isFormValid,
  normalizeSelectWithAllValues,
} from '../../../../utils';
import './HeadToHeadContent.styles.scss';

export const useFetchHeadToHead = () => {
  const { selectedGame } = useAppSelector(selectMainFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { games } = useAppSelector(selectGames);
  const { countOfPlayers } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();
  const { byId } = games;

  const fetchHeadToHead = () => {
    const requestBody: IBaseRequestBody = {
      gameState: normalizeSelectWithAllValues(countOfPlayers),
    };
    const matchId = selectedGame?.id;

    if (matchId) {
      const gameById = byId[matchId];
      if (gameById) {
        dispatch(
          getHeadToHead({
            competitionsUuids: filteredParts[0].id,
            matchUuid: selectedGame.id,
            teamUuid: selectedGame.homeTeamId,
            body: requestBody,
          }),
        );
      }
    }
  };

  return fetchHeadToHead;
};

const options: ISelectOption[] = [
  {
    label: ITranslationKeys.allShotAttempts,
    value: 'CFaCA',
  },
  {
    label: ITranslationKeys.allShotAttemptsFromSlot,
    value: 'SCFaSCA',
  },
  {
    label: ITranslationKeys.shotsOnGoal,
    value: 'SOGFaSOGA',
  },
  {
    label: ITranslationKeys.expectedGoals,
    value: 'XGFaXGA',
  },
];

const classNames = createClassNames('head-to-head-content');

export const HeadToHeadContent = () => {
  const { selectedGame } = useAppSelector(selectMainFilter);
  const { similarPlayerNames } = useAppSelector(selectPlayers);
  const {
    games: { isLoading, isAllGamesLoaded, isHeadToHeadLoading, headToHead },
  } = useAppSelector(selectGames);
  const { byId } = useAppSelector(selectTeams);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const isInitialMountRef = useRef(true);
  const { formState } = useFormContext<IMainFilterForm>();
  const { errors } = formState;
  const disableCondition = !isFormValid(errors);

  const { activeTab } = useLocationPaths();

  const fetchHeadToHead = useFetchHeadToHead();
  const handleOnSubmit = useHandleOnSubmit(() => {
    fetchHeadToHead();
  }, isInitialMountRef);

  const [category, setCategory] = useState<ISelectOption>(options[0]);
  const [countOfPlayersList, setCountOfPlayersList] = useState(countOfPlayersH2HOptions[0]);

  useEffect(() => {
    if (selectedGame) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, selectedGame, countOfPlayersList]);

  const handleCountOfPlayersChange = (value: ISelectOption) => {
    setCountOfPlayersList(value);
    dispatch(setCountOfPlayers(value));
  };

  if (isLoading || !isAllGamesLoaded) {
    return <Loading />;
  }

  if (!selectedGame) {
    return (
      <div className='content-info-box'>
        {Object.values(formState.errors).map((error, index) => (
          <div key={`${error.message}_${index}`}>
            {error.message ? t(error.message) : error.message}
          </div>
        ))}
      </div>
    );
  }

  const homeTeam = byId[selectedGame.homeTeamId];
  const awayTeam = byId[selectedGame.awayTeamId];

  const renderHeadToHead = () => {
    if (isHeadToHeadLoading) {
      return <Loading size='medium' backgroundColor='transparent' disableMessage />;
    }

    if (headToHead && headToHead.headToHead.length > 0) {
      return (
        <HeadToHead
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          headToHead={headToHead}
          category={category.value as IH2HCategory}
          similarPlayerNamesRecord={similarPlayerNames}
        />
      );
    }

    return <NoData message={ITranslationKeys.noDataForSelected} />;
  };

  return (
    <div className={classNames()}>
      <div className={classNames('filters')}>
        <div>
          <Caption label={ITranslationKeys.countOfPlayers} />
          <SelectInput
            selected={countOfPlayersList}
            variant='filter'
            onChange={(value: ISelectOption) => handleCountOfPlayersChange(value)}
            options={countOfPlayersH2HOptions}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            disabled={disableCondition}
          />
        </div>
        <div>
          <Caption label={ITranslationKeys.shotCategory} />
          <SelectInput
            options={options}
            selected={category}
            variant='filter'
            onChange={(value: ISelectOption) => setCategory(value)}
            disabled={disableCondition}
          />
        </div>
      </div>
      <div className={classNames('content')}>
        <strong className={classNames('content__title')}>
          {formatDateByLanguage(new Date(selectedGame.date), 'P')}, {homeTeam.name} -{' '}
          {awayTeam.name}
        </strong>
        {renderHeadToHead()}
      </div>
    </div>
  );
};
