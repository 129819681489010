import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { selectPlayers } from '../../../../features';
import {
  IGameBlock,
  IGameReportCompetitionTeam,
  IGameReportMatchTeam,
  IGamesReportBlockStats,
  IGamesReportBlockTableData,
  IPlayerForTableWithValue,
  IPlayerRecord,
  IPlayerWithTeamIdAndStats,
  IReportMetricInfo,
  ISimilarPlayerNameRecord,
} from '../../../../types';
import { getPlayerShortName } from '../../../../utils';

const getTopPlayers = (
  players: IPlayerWithTeamIdAndStats[],
  playerType: string,
  playerRecord: IPlayerRecord,
  topPlayersCount: number = 5,
  similarPlayerNames: ISimilarPlayerNameRecord,
): IPlayerForTableWithValue[] => {
  const sortedHomeTeamPlayers = [...players].sort(
    (a, b) => b.stats[playerType] - a.stats[playerType],
  );

  const topPlayers = sortedHomeTeamPlayers.slice(0, topPlayersCount);

  return topPlayers.map<IPlayerForTableWithValue>(player => ({
    id: player.id,
    displayName: getPlayerShortName(playerRecord[player.id], similarPlayerNames),
    value: player.stats[playerType],
  }));
};

const getStatsMatch = (
  metric: IReportMetricInfo,
  team: IGameReportMatchTeam,
  playerRecord: IPlayerRecord,
  topPlayersCount: number = 5,
  similarPlayerNames: ISimilarPlayerNameRecord,
): IGamesReportBlockStats => {
  const { code, percentCode, playerCode } = metric;

  return {
    code,
    value: team.stats[code],
    percent: percentCode ? team.stats[percentCode] : undefined,
    topPlayers: getTopPlayers(
      team.players,
      playerCode,
      playerRecord,
      topPlayersCount,
      similarPlayerNames,
    ),
  };
};

const getStatsFromCompetition = (
  metric: IReportMetricInfo,
  team: IGameReportCompetitionTeam,
  playerRecord: IPlayerRecord,
  topPlayersCount: number = 5,
  similarPlayerNames: ISimilarPlayerNameRecord,
): IGamesReportBlockStats => {
  const { code, playerCode } = metric;

  return {
    code,
    value: team.averages[code],
    percent: team.index[code],
    topPlayers: getTopPlayers(
      team.players,
      playerCode,
      playerRecord,
      topPlayersCount,
      similarPlayerNames,
    ),
  };
};

const getTableData = (
  block: IGameBlock,
  metrics: IReportMetricInfo[],
  playerRecord: IPlayerRecord,
  topPlayersCount: number = 5,
  similarPlayerNames: ISimilarPlayerNameRecord,
): IGamesReportBlockTableData[] => {
  const { competition, match } = block;

  const tableData: IGamesReportBlockTableData[] = metrics.map(metric => ({
    name: metric.name,
    description: metric.description,
    homeTeamMatchStats: getStatsMatch(
      metric,
      match.homeTeam,
      playerRecord,
      topPlayersCount,
      similarPlayerNames,
    ),
    awayTeamMatchStats: getStatsMatch(
      metric,
      match.awayTeam,
      playerRecord,
      topPlayersCount,
      similarPlayerNames,
    ),
    homeTeamCompetitionStats: getStatsFromCompetition(
      metric,
      competition.homeTeam,
      playerRecord,
      topPlayersCount,
      similarPlayerNames,
    ),
    awayTeamCompetitionStats: getStatsFromCompetition(
      metric,
      competition.awayTeam,
      playerRecord,
      topPlayersCount,
      similarPlayerNames,
    ),
  }));

  return tableData;
};

export const useDataForTable = (
  gameBlock: IGameBlock | undefined,
  metrics: IReportMetricInfo[] | undefined,
  topPlayersCount?: number,
): IGamesReportBlockTableData[] => {
  const { byId, similarPlayerNames } = useAppSelector(selectPlayers);

  const data = useMemo(() => {
    if (gameBlock && metrics && metrics.length > 0) {
      return getTableData(gameBlock, metrics, byId, topPlayersCount, similarPlayerNames);
    }

    return [];
  }, [gameBlock, metrics, topPlayersCount, byId, similarPlayerNames]);

  return data;
};
