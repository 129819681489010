import { FC, useEffect, useState } from 'react';

import { IPlaygroundShotOrPass, IVideoInfo } from '../../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../../utils';
import { PlaygroundShotTooltip } from '../../PlaygroundShotTooltip';
import './PlaygroundShotsContent.styles.scss';

export interface IPlaygroundShotsContentProps {
  /** An array containing the shots to be drawn. */
  shots: IPlaygroundShotOrPass[];
  /** Function called when the play button is clicked. */
  onPlayClick: (array: IVideoInfo[]) => void;
  /** True when the playground component is small. */
  isSmall?: boolean;
}

const classNames = createClassNames('playground-shots-content');

export const PlaygroundShotsContent: FC<IPlaygroundShotsContentProps> = ({
  shots,
  onPlayClick,
  isSmall = false,
}) => {
  const [selectedShot, setSelectedShot] = useState<IPlaygroundShotOrPass>();

  const handleShotChange = (shot: IPlaygroundShotOrPass | undefined) => {
    if (shot === selectedShot) return;
    setSelectedShot(shot);
  };

  useEffect(() => {
    setSelectedShot(undefined);
  }, [shots]);

  return (
    <>
      <div
        className={classNames({
          ...transformObjectKeysToKebabCase({ isSmall }),
        })}
        data-testid='playground-shots-content'
      >
        <div
          className={classNames('shadow-canvas')}
          onClick={() => handleShotChange(undefined)}
        ></div>
        {shots.map((shot, index) => (
          <div
            key={index}
            className={classNames('shot', {
              ...transformObjectKeysToKebabCase({
                isGoal: shot.type === 'G',
                emptyNet: shot.emptyNet,
                color: shot.color ?? '0',
              }),
            })}
            tabIndex={index}
            style={{
              top: shot.position.y,
              left: shot.position.x,
            }}
            onClick={() => handleShotChange(shot)}
          ></div>
        ))}
      </div>
      {selectedShot && selectedShot.xG && (
        <PlaygroundShotTooltip
          date={new Date(selectedShot.videoInfo.matchDate)}
          time={selectedShot.videoInfo.time}
          homeTeamShortcut={selectedShot.homeTeamShortcut}
          awayTeamShortcut={selectedShot.awayTeamShortcut}
          shotPlayerName={selectedShot.playerName}
          xG={selectedShot.xG}
          activeZone={selectedShot.activeZone}
          show={!!selectedShot}
          position={{
            top: selectedShot.position.y,
            left: selectedShot.position.x,
          }}
          onPlayClick={() => onPlayClick([selectedShot.videoInfo])}
        />
      )}
    </>
  );
};
