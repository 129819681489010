import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClickOutsideTwoRefs, useMultiselect } from '../../hooks';
import { ITranslationKeys } from '../../i18n/types';
import { ChevronDownIcon, DownArrowIcon } from '../../icons';
import { IUseMultiselectConfig } from '../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import { SelectOptionItem } from '../SelectOptionItem';
import '../shared/selectInputs.styles.scss';
import { ISelectInputSharedProps } from '../types';

export type IMultiselectInputProps<TOption = undefined> = ISelectInputSharedProps &
  IUseMultiselectConfig<TOption>;

const classNames = createClassNames('select-input');

/**
 * MultiselectInput component for selecting multiple values.
 */
export const MultiselectInput = <TOption extends any = undefined>({
  options,
  selected,
  placeholder,
  variant,
  hasSelectAllOption,
  disabled,
  dropUp = false,
  scrollable,
  scrollableMaxHeight = '400px',
  error,
  iconComponent,
  onChange,
  onBlur,
}: IMultiselectInputProps<TOption>) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const { ref, refDontClose } = useClickOutsideTwoRefs<HTMLDivElement, HTMLDivElement>(() => {
    if (open) {
      setOpen(false);
      onBlur?.();
    }
  }, [open, setOpen]);

  const { selectedAll, onChangeToggle, onChangeToggleSelectAll } = useMultiselect<TOption>({
    options,
    selected,
    onChange,
    hasSelectAllOption,
  });

  return (
    <div className={classNames()} data-testid='multiselect-input'>
      <div
        ref={ref}
        className={classNames('selected', {
          open,
          variant,
          disabled,
          error,
        })}
        onClick={!disabled ? () => setOpen(!open) : undefined}
        data-testid='multiselect-input__selected'
      >
        {iconComponent}
        <div
          className={classNames('selected__field', {
            placeholder: !(selected.length > 0),
            disabled,
          })}
        >
          {selected.length > 0
            ? selected.map(item => t(item.label, { ...item.labelTranslationsOptions })).join(', ')
            : placeholder
            ? t(placeholder)
            : null}
        </div>
        {variant === 'tcms' ? <ChevronDownIcon /> : <DownArrowIcon />}
      </div>
      <div
        ref={refDontClose}
        className={classNames('options', {
          ...transformObjectKeysToKebabCase({
            visible: open && !disabled,
            variant,
            scrollable,
            dropUp,
          }),
        })}
        style={{ maxHeight: scrollable ? scrollableMaxHeight : undefined }}
        data-testid='multiselect-input__options'
      >
        {hasSelectAllOption ? (
          <SelectOptionItem
            key='selectAll1'
            isSelectAll
            multiselectSettings={{ checked: selectedAll }}
            onChange={onChangeToggleSelectAll}
            option={{
              value: 'selectAll1',
              label: !selectedAll ? t(ITranslationKeys.selectAll) : t(ITranslationKeys.removeAll),
            }}
          />
        ) : null}
        {options.map(option => (
          <SelectOptionItem
            key={
              option.additionalValue ? `${option.value}_${option.additionalValue}` : option.value
            }
            multiselectSettings={{ checked: selected.some(item => item.value === option.value) }}
            onChange={onChangeToggle}
            {...{ option, variant }}
          />
        ))}
      </div>
    </div>
  );
};
