import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  filteredPlayersByPositionSelector,
  selectIndividualStats,
  selectPlayers,
  selectRanks,
} from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IIndividualStatsTableData,
  IMetricWithDatasetMetric,
  IPlayerRecord,
  IPlayerWithStats,
  IRankRecord,
  ISimilarPlayerNameRecord,
} from '../../../../types';
import {
  getMetricsPercentilesWithValue,
  getMetricsWithRankValues,
  getMetricsWithValue,
  getPlayerShortName,
} from '../../../../utils';

const getTableData = (
  playersWithStatsFiltered: IPlayerWithStats[],
  playersRecord: IPlayerRecord,
  metrics: IMetricWithDatasetMetric[],
  similarPlayerNamesRecord: ISimilarPlayerNameRecord,
  rank?: IRankRecord,
) => {
  const tableData = playersWithStatsFiltered
    .reduce<IIndividualStatsTableData[]>((acc, playersWithStats) => {
      const player = playersRecord[playersWithStats.id];
      const playerTeamStats = Object.values(playersWithStats.teamStats).reduce<
        IIndividualStatsTableData[]
      >((playerTeamStatsAcc, playerTeam) => {
        const metricsWithValue = getMetricsWithValue(metrics, playerTeam.stats);
        const metricPercentilesWithValue = getMetricsPercentilesWithValue(metrics, playerTeam);
        const metricsWithRank = getMetricsWithRankValues(metrics, rank, player.id, playerTeam.id);

        if (player) {
          acc.push({
            entityId: player.id,
            teamId: playerTeam.id,
            player: {
              id: player.id,
              displayName: getPlayerShortName(player, similarPlayerNamesRecord),
            },
            gp: playerTeam.stats.gp,
            toi: playerTeam.stats.toi,
            stats: metricsWithValue,
            percentiles: metricPercentilesWithValue,
            summaryPercentile: playerTeam.percentiles
              ? playerTeam.percentiles['summaryPercentile']
              : undefined,
            rank: metricsWithRank,
          });
        }

        return playerTeamStatsAcc;
      }, []);

      return acc.concat(playerTeamStats);
    }, [])
    .sort((a, b) => b.toi - a.toi);

  return tableData;
};

export const useDataForTable = () => {
  const playersWithStats = useAppSelector(selectIndividualStats);
  const { byId, similarPlayerNames } = useAppSelector(selectPlayers);
  const { rank } = useAppSelector(selectRanks);
  const filteredPlayers = useAppSelector(filteredPlayersByPositionSelector);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const playersWithStatsFiltered = useMemo(
    () =>
      Object.values(playersWithStats.byId).filter(player =>
        filteredPlayers.map(player => player.id).includes(player.id),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [playersWithStats.byId],
  );

  const data: IIndividualStatsTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();

    if (filteredMetrics) {
      return getTableData(
        playersWithStatsFiltered,
        byId,
        filteredMetrics,
        similarPlayerNames,
        rank,
      );
    }

    return [];
  }, [playersWithStatsFiltered, rank, byId, similarPlayerNames, getFilteredMetrics]);

  return data;
};
