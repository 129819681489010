import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IScoreState } from '../../../types';
import { createClassNames, getScoreStateShortcut } from '../../../utils';
import './GameScore.styles.scss';

interface IGameScoreProps {
  /**
   * All home team's goals
   */
  home: number;
  /**
   * All away team's goals
   */
  away: number;
  /**
   * All away team's goals
   */
  scoreState: IScoreState;
  /**
   * Home team's goals by thirds
   */
  homeThirds?: number[];
  /**
   * Away team's goals by thirds
   */
  awayThirds?: number[];
}

const classNames = createClassNames('game-score');

/**
 * GameScore UI component.
 */
export const GameScore: FC<IGameScoreProps> = ({
  home,
  away,
  scoreState,
  homeThirds,
  awayThirds,
}) => {
  const stateShortcut = getScoreStateShortcut(scoreState);
  const { t } = useTranslation();
  return (
    <div className={classNames()}>
      <h3 className={classNames('score')}>
        {home}:{away}
        {stateShortcut ? t(stateShortcut) : null}
      </h3>
      {homeThirds && awayThirds && (
        <p className={classNames('thirds')}>
          ({homeThirds[0]}:{awayThirds[0]}, {homeThirds[1]}:{awayThirds[1]}, {homeThirds[2]}:
          {awayThirds[2]})
        </p>
      )}
    </div>
  );
};
