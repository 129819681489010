import { FC, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { createClassNames } from '../../utils';
import { Button } from '../Button';
import './WidgetItem.styles.scss';

export interface IWidgetItemProps {
  /** Icon to be displayed. */
  iconComponent: ReactElement;
  /** A translated name to be displayed. */
  name: ITranslationKeys;
  /** A translated description to be displayed. */
  description: ITranslationKeys;
  /** Additional input field for Widget. */
  additionalInput?: ReactNode;
  /** Is the section disabled? */
  disabled?: boolean;
  /**  Is the section's load button disabled? */
  disableLoadButton?: boolean;
  /** Click handler for the button component */
  onClick: (...args: any[]) => void;
}

const classNames = createClassNames('widget-item');

/** WidgetItem UI component for user interaction with the section changing. */
export const WidgetItem: FC<IWidgetItemProps> = ({
  iconComponent,
  name,
  description,
  additionalInput,
  disabled = false,
  disableLoadButton = false,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames({ disabled })}>
      {iconComponent}
      <div className={classNames('header')}>{t(name)}</div>
      <div className={classNames('description')}>{t(description)}</div>
      <div className={classNames('inline')}>
        {additionalInput}
        <Button
          label={ITranslationKeys.loadData}
          onClick={() => onClick(name)}
          disabled={disableLoadButton}
        />
      </div>
    </div>
  );
};
