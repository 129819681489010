import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './PositionCircle.styles.scss';

export interface IPositionCircleProps {
  /** Position of the player */
  position: 'DE' | 'FO';
  /** Team of the player */
  team?: 'home' | 'away';
  /** If true, component has white background. */
  hasWhiteBackground?: boolean;
}

const classNames = createClassNames('position-circle');

/**
 * UI component showing players position in the circle separating opponents by color.
 */
export const PositionCircle: FC<IPositionCircleProps> = ({
  position,
  team,
  hasWhiteBackground,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          team,
          hasWhiteBackground,
        }),
      })}
      data-testid='position-circle'
    >
      {position === 'DE'
        ? t(ITranslationKeys.defenseFirstLetter)
        : t(ITranslationKeys.forwardFirstLetter)}
    </div>
  );
};
