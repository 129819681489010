import { ITranslationKeys } from '../i18n/types';
import { IMetricParameterInfoRecord } from '../types';

export const METRIC_PARAMETERS_INFO_ZONES: IMetricParameterInfoRecord = {
  ur: {
    id: 'ur',
    prefix: 'ur.',
    prefixLabel: 'UR.',
    label: ITranslationKeys.ur,
    paramGroupLabel: ITranslationKeys.netZones,
  },
  ce: {
    id: 'ce',
    prefix: 'ce.',
    prefixLabel: 'CE.',
    label: ITranslationKeys.ce,
    paramGroupLabel: ITranslationKeys.netZones,
  },
  ul: {
    id: 'ul',
    prefix: 'ul.',
    prefixLabel: 'UL.',
    label: ITranslationKeys.ul,
    paramGroupLabel: ITranslationKeys.netZones,
  },
  br: {
    id: 'br',
    prefix: 'br.',
    prefixLabel: 'BR.',
    label: ITranslationKeys.br,
    paramGroupLabel: ITranslationKeys.netZones,
  },
  fh: {
    id: 'fh',
    prefix: 'fh.',
    prefixLabel: 'FH.',
    label: ITranslationKeys.fh,
    paramGroupLabel: ITranslationKeys.netZones,
  },
  bl: {
    id: 'bl',
    prefix: 'bl.',
    prefixLabel: 'BL.',
    label: ITranslationKeys.bl,
    paramGroupLabel: ITranslationKeys.netZones,
  },
};

export const METRIC_PARAMETERS_INFO: IMetricParameterInfoRecord = {
  slot: {
    id: 'slot',
    prefix: 's',
    prefixLabel: 's',
    label: ITranslationKeys.slot,
    paramGroupLabel: ITranslationKeys.shotLocation,
  },
  sslot: {
    id: 'sslot',
    prefix: 'ss',
    prefixLabel: 'ss',
    label: ITranslationKeys.innerSlot,
    paramGroupLabel: ITranslationKeys.shotLocation,
  },
  '1t': {
    id: '1t',
    prefix: '1t.',
    prefixLabel: '1T.',
    label: ITranslationKeys.oneTimer,
    paramGroupLabel: ITranslationKeys.shotTypes,
  },
  reb: {
    id: 'reb',
    prefix: 'reb.',
    prefixLabel: 'REB.',
    label: ITranslationKeys.rebounds,
    paramGroupLabel: ITranslationKeys.shotTypes,
  },
  s: {
    id: 's',
    prefix: 's.',
    prefixLabel: 'S.',
    label: ITranslationKeys.screeningPlayer,
    paramGroupLabel: ITranslationKeys.shotTypes,
  },
  d: {
    id: 'd',
    prefix: 'd.',
    prefixLabel: 'D.',
    label: ITranslationKeys.deflected,
    paramGroupLabel: ITranslationKeys.shotTypes,
  },
  hd: {
    id: 'hd',
    prefix: 'hd.',
    prefixLabel: 'HD.',
    label: ITranslationKeys.high,
    paramGroupLabel: ITranslationKeys.shotsDanger,
  },
  md: {
    id: 'md',
    prefix: 'md.',
    prefixLabel: 'MD.',
    label: ITranslationKeys.medium,
    paramGroupLabel: ITranslationKeys.shotsDanger,
  },
  ld: {
    id: 'ld',
    prefix: 'ld.',
    prefixLabel: 'LD.',
    label: ITranslationKeys.low,
    paramGroupLabel: ITranslationKeys.shotsDanger,
  },
  f: {
    id: 'f',
    prefix: 'f.',
    prefixLabel: 'F.',
    label: ITranslationKeys.forecheck,
    paramGroupLabel: ITranslationKeys.attackType,
  },
  r: {
    id: 'r',
    prefix: 'r.',
    prefixLabel: 'R.',
    label: ITranslationKeys.rush,
    paramGroupLabel: ITranslationKeys.attackType,
  },
  l: {
    id: 'l',
    prefix: 'l.',
    prefixLabel: 'L.',
    label: ITranslationKeys.cycle,
    paramGroupLabel: ITranslationKeys.attackType,
  },
  o: {
    id: 'o',
    prefix: 'o.',
    prefixLabel: 'O.',
    label: ITranslationKeys.oddManRush,
    paramGroupLabel: ITranslationKeys.attackType,
  },
  a: {
    id: 'a',
    prefix: 'a.',
    prefixLabel: 'A.',
    label: ITranslationKeys.fromFaceoff,
    paramGroupLabel: ITranslationKeys.attackType,
  },
  int: {
    id: 'int',
    prefix: 'int.',
    prefixLabel: 'INT.',
    label: ITranslationKeys.captureOrBlock,
    paramGroupLabel: ITranslationKeys.puckGainType,
  },
  b: {
    id: 'b',
    prefix: 'b.',
    prefixLabel: 'B.',
    label: ITranslationKeys.inFight,
    paramGroupLabel: ITranslationKeys.puckGainType,
  },
  c: {
    id: 'c',
    prefix: 'c.',
    prefixLabel: 'C.',
    label: ITranslationKeys.carryIn,
    paramGroupLabel: ITranslationKeys.entryExitType,
  },
  p: {
    id: 'p',
    prefix: 'p.',
    prefixLabel: 'P.',
    label: ITranslationKeys.withPass,
    paramGroupLabel: ITranslationKeys.entryExitType,
  },
  stp: {
    id: 'stp',
    prefix: 'stp.',
    prefixLabel: 'stP.',
    label: ITranslationKeys.passBehindRedLine,
    paramGroupLabel: ITranslationKeys.exitType,
  },
  le: {
    id: 'le',
    prefix: 'le.',
    prefixLabel: 'Le.',
    label: ITranslationKeys.left,
    paramGroupLabel: ITranslationKeys.enterExitLocation,
  },
  mi: {
    id: 'mi',
    prefix: 'mi.',
    prefixLabel: 'Mi.',
    label: ITranslationKeys.middle,
    paramGroupLabel: ITranslationKeys.enterExitLocation,
  },
  ri: {
    id: 'ri',
    prefix: 'ri.',
    prefixLabel: 'Ri.',
    label: ITranslationKeys.right,
    paramGroupLabel: ITranslationKeys.enterExitLocation,
  },
  ...METRIC_PARAMETERS_INFO_ZONES,
};
