import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { IGoalkeeper, IPlayer, IPlayerCardInfo } from '../../../types';
import { createClassNames, formatDateByLanguage } from '../../../utils';
import { BasicPlayerInfo } from '../BasicPlayerInfo';
import { PlayerInfoItem } from '../PlayerInfoItem';
import './PlayerDetails.styles.scss';

export interface IPlayerDetailsProps {
  /** Informations about player. */
  player: IPlayer | IGoalkeeper;
  /** Card informations about player. */
  playerInfo: IPlayerCardInfo | undefined;
  /** Additional actions buttons. */
  actions?: ReactNode;
}

const classNames = createClassNames('player-details');

/**
 * PlayerDetails component displaying all the information about player with PlayerInfoItem items.
 */
export const PlayerDetails: FC<IPlayerDetailsProps> = ({ player, playerInfo, actions }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()} data-testid='player-details'>
      <BasicPlayerInfo
        name={`${player.name} ${player.surname}`}
        toi={playerInfo?.toi ?? 0}
        gp={playerInfo?.gp ?? 0}
      />
      <div className={classNames('info')}>
        <PlayerInfoItem
          label={ITranslationKeys.born}
          value={formatDateByLanguage(new Date(player.birthday), 'P')}
        />
        <PlayerInfoItem
          label={ITranslationKeys.age}
          value={`${player.age.toString()} ${t(ITranslationKeys.years)}`}
        />
        <PlayerInfoItem label={ITranslationKeys.height} value={`${player.height?.toString()} cm`} />
        <PlayerInfoItem label={ITranslationKeys.weight} value={`${player.weight?.toString()} kg`} />
        <PlayerInfoItem label={ITranslationKeys.stick} value={t(player.stick)} />
      </div>
      <div className={classNames('actions')}>{actions}</div>
    </div>
  );
};
