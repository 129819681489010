import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IIndividualStatsRequestBody, IRankRecord } from '../../types';
import { defaultCatchErrorCallback } from '../../utils';
import { parseRankData } from './parseRanks';

interface IGetRanksParams {
  competitionsUuids: string[];
  body?: IIndividualStatsRequestBody;
  isGoalkeepers?: boolean;
}

export const getRanks = createAsyncThunk<IRankRecord, IGetRanksParams>(
  'ranks/getRanks',
  async ({ competitionsUuids, body, isGoalkeepers }) => {
    const requestPath = isGoalkeepers
      ? `/goalkeeper-rank/${competitionsUuids}`
      : `/rank/${competitionsUuids}`;
    const ranks: IRankRecord = await axiosInstance
      .post(requestPath, body)
      .then(response => parseRankData(response.data))
      .catch(defaultCatchErrorCallback);

    return ranks;
  },
);
