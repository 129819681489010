import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useAppSelector } from '../../../app/hooks';
import { allTeamOrMediaAllOption } from '../../../constants';
import { teamAndMediaOptionsSelector } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { ICmsUserFilterForm } from '../../../types';
import { createClassNames, isFormValid } from '../../../utils';
import { TcmsButton } from '../TcmsButton';
import { RoleController, SelectTeamOrMediaController, UserController } from '../TcmsControllers';
import './CmsUserFilter.styles.scss';

interface ICmsUserFilterProps {
  onSubmit: () => void;
}

const classNames = createClassNames('cms-user-filter');

export const CmsUserFilter: FC<ICmsUserFilterProps> = ({ onSubmit }) => {
  const teamAndMediaOptions = useAppSelector(teamAndMediaOptionsSelector);

  const [selectedRoleValue, setSelectedRoleValue] = useState<string | undefined>('all');

  const { control, formState, watch, setValue } = useFormContext<ICmsUserFilterForm>();
  const { isDirty, errors } = formState;

  const selectedRole = watch('selectedRole');
  const filteredTeamOrMediaOptions = teamAndMediaOptions.filter(
    option =>
      option.additionalValue === selectedRole?.value ||
      selectedRole?.value === 'all' ||
      option.value === 'all',
  );

  useEffect(() => {
    if (selectedRole?.value === selectedRoleValue) return;

    setValue('selectedTeamOrMedia', [allTeamOrMediaAllOption], {
      shouldDirty: true,
      shouldValidate: true,
    });
    setSelectedRoleValue(selectedRole?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole]);

  return (
    <div className={classNames()}>
      <form noValidate>
        <UserController name='userFilterText' control={control} />
        <RoleController name='selectedRole' control={control} />
        <SelectTeamOrMediaController
          name='selectedTeamOrMedia'
          control={control}
          options={filteredTeamOrMediaOptions}
          disabled={selectedRole?.value === 'client' || selectedRole?.value === 'user'}
        />
        <TcmsButton
          variant='filled'
          color='blue'
          label={ITranslationKeys.displayData}
          width={216}
          onClick={onSubmit}
          disabled={!isDirty || !isFormValid(errors)}
        />
      </form>
    </div>
  );
};
