import { useAppSelector } from '../../../app/hooks';
import {
  OpponentsAtGamesPageController,
  PartsController,
  SeasonsController,
  TeamController,
} from '../../../components';
import { selectMainFilter } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import {
  GoalkeepersIcon,
  GraphicOverviewIcon,
  HeadToHeadIcon,
  LinesIcon,
  PlayersIcon,
  ReportIcon,
} from '../../../icons';
import { INavigationContentKeys, IPageConfig } from '../../../types';
import { GameFormationsContent } from './GameFormationsContent';
import { GameGoalkeepersContent } from './GameGoalkeepersContent';
import { GamePlayersContent } from './GamePlayersContent';
import { GraphicOverviewContent } from './GraphicOverviewContent';
import { HeadToHeadContent } from './HeadToHeadContent';
import { ReportContent } from './ReportContent';

/** Custom hook returns config for GamesPage tabs and widgets. */
export const usePageConfig = (): IPageConfig => {
  const { selectedGame } = useAppSelector(selectMainFilter);

  return {
    baseFilter: (
      <>
        <SeasonsController required />
        <PartsController required />
        <TeamController required />
        <OpponentsAtGamesPageController required />
      </>
    ),
    tabsConfig: [
      {
        id: INavigationContentKeys.graphicOverview,
        name: ITranslationKeys.graphicOverview,
        icon: <GraphicOverviewIcon />,
        description: ITranslationKeys.graphicOverviewDescription,
        ContentComponent: GraphicOverviewContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <OpponentsAtGamesPageController required />
          </>
        ),
        disableLoadDataButton: !selectedGame,
      },
      {
        id: INavigationContentKeys.report,
        name: ITranslationKeys.report,
        icon: <ReportIcon />,
        description: ITranslationKeys.reportDescription,
        ContentComponent: ReportContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <OpponentsAtGamesPageController required />
          </>
        ),
        disableLoadDataButton: !selectedGame,
      },
      {
        id: INavigationContentKeys.players,
        name: ITranslationKeys.players,
        icon: <PlayersIcon />,
        description: ITranslationKeys.playersDescription,
        ContentComponent: GamePlayersContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <OpponentsAtGamesPageController required />
          </>
        ),
        disableLoadDataButton: !selectedGame,
      },
      {
        id: INavigationContentKeys.formations,
        name: ITranslationKeys.formations,
        icon: <LinesIcon />,
        description: ITranslationKeys.formationsGamesDescription,
        ContentComponent: GameFormationsContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <OpponentsAtGamesPageController required />
          </>
        ),
        disableLoadDataButton: !selectedGame,
      },
      {
        id: INavigationContentKeys.goalkeepers,
        name: ITranslationKeys.goalkeepers,
        icon: <GoalkeepersIcon />,
        description: ITranslationKeys.goalkeepersDescription,
        ContentComponent: GameGoalkeepersContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <OpponentsAtGamesPageController required />
          </>
        ),
        disableLoadDataButton: !selectedGame,
      },
      {
        id: INavigationContentKeys.headToHead,
        name: ITranslationKeys.headToHead,
        icon: <HeadToHeadIcon />,
        description: ITranslationKeys.headToHeadDescription,
        ContentComponent: HeadToHeadContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <OpponentsAtGamesPageController required />
          </>
        ),
        disableLoadDataButton: !selectedGame,
      },
    ],
  };
};
