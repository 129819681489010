import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { IPlayerForTableWithValue } from '../../types';
import {
  createClassNames,
  isTimeMetric,
  secondsToMinutesAndSeconds,
  transformObjectKeysToKebabCase,
} from '../../utils';
import './TopPlayers.styles.scss';

export interface ITopPlayersProps {
  /** Array of top players with value. */
  topPlayers: IPlayerForTableWithValue[];
  /** Metric code used for format value. */
  metricCode: string;
  /** Callback used when user clicks on show all players. */
  handleAllPlayersClick: () => void;
}

const classNames = createClassNames('top-players');

/** Component displays top players. */
export const TopPlayers: FC<ITopPlayersProps> = ({
  topPlayers,
  metricCode,
  handleAllPlayersClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()} data-testid='top-players'>
      {topPlayers.map(player => {
        const timeMetric = isTimeMetric(metricCode);
        const roundedValue = Math.round(player.value);
        const displayValue = timeMetric ? secondsToMinutesAndSeconds(roundedValue) : roundedValue;

        return (
          <div key={player.id} className={classNames('player')}>
            <div
              className={classNames('player__value', {
                ...transformObjectKeysToKebabCase({
                  isTimeMetric: timeMetric,
                }),
              })}
            >
              {displayValue}
            </div>
            <div className={classNames('player__name')}>{player.displayName}</div>
          </div>
        );
      })}
      <div className={classNames('all-players')} onClick={handleAllPlayersClick}>
        + {t(ITranslationKeys.showAllPlayers)}
      </div>
    </div>
  );
};
