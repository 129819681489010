import {
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { memo, useState } from 'react';

import { IBaseTablesProps, ITableGetSubRows } from '../../../../types';
import { createClassNames } from '../../../../utils';
import './GameReportTable.styles.scss';

export interface IGameReportTableProps<T extends {}>
  extends IBaseTablesProps<T>,
    ITableGetSubRows<T> {}

const classNames = createClassNames('game-report-table-component');

export const GameReportTable = memo(
  <T extends {}>({ data, columns, getSubRows }: IGameReportTableProps<T>) => {
    const [expanded, setExpanded] = useState<ExpandedState>({});

    const table = useReactTable({
      data,
      columns,
      state: {
        expanded,
      },
      onExpandedChange: setExpanded,
      getSubRows,
      getCoreRowModel: getCoreRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
    });

    return (
      <div className={classNames()}>
        <table>
          <thead className={classNames('header')}>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr
                key={row.id}
                className={classNames('row', {
                  expanded: row.getIsExpanded(),
                })}
              >
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  },
) as <T extends {}>(props: IGameReportTableProps<T>) => JSX.Element;
