import { FC } from 'react';

import { useAppSelector } from '../../../app/hooks';
import { selectTeams } from '../../../features';
import {
  NegativeActiveIcon,
  NeutralActiveIcon,
  PlayIcon,
  PositiveActiveIcon,
  TrashIcon,
} from '../../../icons';
import { IClip } from '../../../types/accountManagement.types';
import { createClassNames, formatDateByLanguage } from '../../../utils';
import './HokejPlayerManagementRolldown.styles.scss';

export interface IHokejPlayerManagementRolldownProps extends IClip {
  /**
   * Fires click hook when play play icon is clicked.
   */
  onPlayIconClick: () => void;
  /**
   * Fires click hook when play trash icon is clicked.
   */
  onTrashIconClick: () => void;
  /**
   * Is clip first in array - more padding
   */
  first?: boolean;
  /**
   * Is clip last in array - more padding
   */
  last?: boolean;
}

const classNames = createClassNames('hokej-player-management-rolldown');

/**
 * HokejPlayerManagementRolldown UI component.
 */
export const HokejPlayerManagementRolldown: FC<IHokejPlayerManagementRolldownProps> = ({
  matchInfo,
  type,
  name,
  first,
  last,
  onPlayIconClick,
  onTrashIconClick,
}) => {
  const { byId } = useAppSelector(selectTeams);
  const homeTeam = byId[matchInfo.homeTeamUuid];
  const awayTeam = byId[matchInfo.awayTeamUuid];

  return (
    <tr
      className={classNames({
        first,
        last,
      })}
    >
      <td></td>
      <td colSpan={3}>
        <div className={classNames('clip-info')}>
          <div className={classNames('clip-info__match')}>
            {formatDateByLanguage(new Date(matchInfo.date), 'dd.MM.yyyy')}, {homeTeam.shortcut} -{' '}
            {awayTeam.shortcut} {matchInfo.score.home}:{matchInfo.score.away}
          </div>
          {type === 'thumbs-up' && <PositiveActiveIcon />}
          {type === 'info' && <NeutralActiveIcon />}
          {type === 'thumbs-down' && <NegativeActiveIcon />}
          <div className={classNames('clip-info__label')}>{name}</div>
        </div>
      </td>
      <td className={classNames('icons')}>
        <PlayIcon
          className={classNames('icons__icon-button')}
          width={16}
          height={16}
          style={{ marginBottom: -2 }}
          onClick={onPlayIconClick}
        />
        <TrashIcon
          className={classNames('icons__icon-button')}
          width={16}
          height={16}
          style={{ marginBottom: -2 }}
          onClick={onTrashIconClick}
        />
      </td>
    </tr>
  );
};
