import { FC, useState } from 'react';

import { SHORT_NET_ZONES } from '../../../constants';
import { ITranslationKeys } from '../../../i18n/types';
import { IGoalStatsBoxProps, IGoalkeeper, INetZone, ISelectOption, ITeam } from '../../../types';
import {
  createClassNames,
  getCellColorByPercent,
  getColorByPercent,
  getPlayerShortName,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import { Caption } from '../../Caption';
import { Loading } from '../../Loading';
import { SelectInput } from '../../SelectInput';
import { GoalStatsBoxWrapper } from '../../goalStatsBoxComponents';
import { IGoalNetStats, IGoalNetStatsRecord } from '../../types';
import { GoalNetStatsCircle } from '../GoalNetStatsCircle';
import './GoalNetStats.styles.scss';

interface INetZoneConfigProps {
  /** Array of teams */
  teams: ITeam[];
  /** If true, selected goalkeeper deselects. */
  hasError: string;
  /** Fired when user selects a goalkeeper. */
  onGoalkeeperSelect: (selectedPlayer: ISelectOption, selectedTeam: ISelectOption) => void;
  /** Fired when user selects a team. */
  onTeamSelect: (selected: ISelectOption) => IGoalkeeper[];
}

export interface IGoalNetStatsProps extends Partial<INetZoneConfigProps> {
  /** GoalNetStatsCircle values. */
  statsValues: IGoalNetStatsRecord;
  /** If true, the percent sign is shown next to the value. */
  showPercent?: boolean;
  /** Content of the GoalNetShootout component. */
  goalNetBox?: IGoalStatsBoxProps[];
  /** Width of component. */
  width?: number;
  /** If true, the component shows no circles or boxes. */
  isEmpty?: boolean;
  /** If true, the loading indicator is shown. */
  showLoading?: boolean;
}

const classNames = createClassNames('goal-net-stats');

export const GoalNetStats: FC<IGoalNetStatsProps> = ({
  statsValues,
  goalNetBox,
  showPercent = false,
  width = '100%',
  isEmpty = false,
  teams,
  hasError,
  showLoading = false,
  onGoalkeeperSelect,
  onTeamSelect,
}) => {
  const [selectedTeam, setSelectedTeam] = useState<ISelectOption>();
  const [selectedGoalkeeper, setSelectedGoalkeeper] = useState<ISelectOption>();
  const [filteredGoalkeeperOptions, setFilteredGoalkeeperOptions] = useState<ISelectOption[]>();

  const statsValuesArray = Object.values(statsValues);
  const teamOptions = teams?.map(team => ({
    label: team.name,
    value: team.id,
  }));

  const handleTeamSelect = (selected: ISelectOption) => {
    if (!teams) return;

    setSelectedTeam(selected);
    const goalkeepers = onTeamSelect?.(selected).filter(goalkeeper => !!goalkeeper);

    const filteredGoalkeeperOptions = goalkeepers?.map(goalkeeper => ({
      label: getPlayerShortName(goalkeeper),
      value: goalkeeper.id,
    }));

    setFilteredGoalkeeperOptions(filteredGoalkeeperOptions);
    setSelectedGoalkeeper(undefined);
  };

  const handleGoalkeeperSelect = (selected: ISelectOption) => {
    if (!selectedTeam) return;

    onGoalkeeperSelect?.(selected, selectedTeam);
    setSelectedGoalkeeper(selected);
  };

  return (
    <div className={classNames()} style={{ width }} data-testid='goal-net-stats'>
      {teamOptions && (
        <div className={classNames('fields')}>
          <div className={classNames('fields__team-field')}>
            <Caption label={ITranslationKeys.selectTeam} />
            <SelectInput
              options={teamOptions}
              placeholder={ITranslationKeys.selectTeam}
              onChange={selected => handleTeamSelect(selected)}
              selected={selectedTeam}
              variant='filter'
            />
          </div>
          <div className={classNames('fields__goalkeeper-field')}>
            <Caption label={ITranslationKeys.selectGoalkeeper} />
            <SelectInput
              options={filteredGoalkeeperOptions ? filteredGoalkeeperOptions : []}
              placeholder={ITranslationKeys.selectGoalkeeper}
              onChange={selected => handleGoalkeeperSelect(selected)}
              selected={hasError ? undefined : selectedGoalkeeper}
              variant='filter'
            />
          </div>
        </div>
      )}
      <div className={classNames('goal')}>
        {!isEmpty &&
          SHORT_NET_ZONES.map((netZone, index) => (
            <div
              key={netZone}
              className={classNames('goal__content', {
                ...transformObjectKeysToKebabCase({ netZone }),
              })}
            >
              {!goalNetBox ? (
                <GoalNetStatsCircle
                  value={findStatValue(statsValuesArray, netZone)?.value}
                  showPercent={showPercent}
                />
              ) : (
                selectedGoalkeeper && (
                  <GoalStatsBoxWrapper
                    goalStatsBoxItems={[
                      {
                        items: goalNetBox[index].items,
                        isHorizontal: goalNetBox[index].isHorizontal,
                        onClick: () => undefined,
                        enablePlayIcon: false,
                      },
                    ]}
                    showHeader={false}
                  />
                )
              )}
            </div>
          ))}

        <svg
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          width={width}
          x='0px'
          y='0px'
          viewBox='0 0 304 202'
          xmlSpace='preserve'
        >
          <g>
            <path
              className='goal-post'
              d='M6,202h292V12c0-3.3-2.7-6-6-7H12c-3.3,0-6,2.7-6,6V202z M12,0h280c6.6,0,12,5.4,12,12v190H0V12C0,5.4,5.4,0,12,0z'
            />
            <polygon
              className={classNames({
                ...transformObjectKeysToKebabCase({
                  color: getColor(
                    findStatValue(statsValuesArray, INetZone.bl)?.relativeValue,
                    showPercent,
                    goalNetBox,
                    selectedGoalkeeper,
                  ),
                }),
              })}
              points='157,112 290,112 290,202 223.5,202'
            />
            <polygon
              className={classNames({
                ...transformObjectKeysToKebabCase({
                  color: getColor(
                    findStatValue(statsValuesArray, INetZone.fh)?.relativeValue,
                    showPercent,
                    goalNetBox,
                    selectedGoalkeeper,
                  ),
                }),
              })}
              points='152,118 213,202 91,202'
            />
            <polygon
              className={classNames({
                ...transformObjectKeysToKebabCase({
                  color: getColor(
                    findStatValue(statsValuesArray, INetZone.br)?.relativeValue,
                    showPercent,
                    goalNetBox,
                    selectedGoalkeeper,
                  ),
                }),
              })}
              points='14,112 147,112 80.5,202 14,202'
            />
            <path
              className={classNames({
                ...transformObjectKeysToKebabCase({
                  color: getColor(
                    findStatValue(statsValuesArray, INetZone.ul)?.relativeValue,
                    showPercent,
                    goalNetBox,
                    selectedGoalkeeper,
                  ),
                }),
              })}
              d='M156,14h131c1.7,0,3,1.3,3,3v87H156V14z'
            />
            <path
              className={classNames({
                ...transformObjectKeysToKebabCase({
                  color: getColor(
                    findStatValue(statsValuesArray, INetZone.ur)?.relativeValue,
                    showPercent,
                    goalNetBox,
                    selectedGoalkeeper,
                  ),
                }),
              })}
              d='M17,14h131v90H14V17C14,15.3,15.3,14,17,14z'
            />
          </g>
        </svg>
      </div>
      {showLoading && <Loading />}
    </div>
  );
};

/** Returns a stat value by metric name. */
const findStatValue = (statsValues: IGoalNetStats[], searchName: string) =>
  statsValues.find(stat => stat.keyName.includes(searchName));

/**
 * Returns a class that represents the color of the bar.
 */
const getColor = (
  value?: number,
  showPercent?: boolean,
  goalNetBox?: IGoalStatsBoxProps[],
  selectedGoalkeeper?: ISelectOption,
) => {
  if (!value || (goalNetBox && !selectedGoalkeeper)) return 'default-color';
  const className = showPercent ? getCellColorByPercent(value) : getColorByPercent(value);
  return className ? className : 'default-color';
};
