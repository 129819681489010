import { createAsyncThunk } from '@reduxjs/toolkit';

import { ITranslationKeys } from '../../i18n/types';
import { axiosInstance } from '../../services/axiosInstance';
import { IAuthToken, IBaseIdentity, ILoginCredentials } from '../../types';
import { defaultCatchErrorCallback } from '../../utils';

export const login = createAsyncThunk<IAuthToken, ILoginCredentials>(
  'auth/login',
  async ({ username, password }) => {
    const tokenData: IAuthToken | undefined = await axiosInstance
      .post('/token', {
        grant_type: 'client_credentials',
        client_id: username,
        client_secret: password,
      })
      .then(response => response.data)
      .catch(defaultCatchErrorCallback);

    if (tokenData) {
      return tokenData;
    } else {
      throw new Error(ITranslationKeys.invalidCredentialsErrorMessage);
    }
  },
);

export const getLoggedUser = createAsyncThunk<IBaseIdentity>('auth/getLoggedUser', async () => {
  const loggedUser: IBaseIdentity = await axiosInstance
    .get('/me')
    .then(response => response.data)
    .catch(defaultCatchErrorCallback);

  return loggedUser;
});

export const putNewPassword = createAsyncThunk<IBaseIdentity, { password: string }>(
  'auth/putNewPassword',
  async ({ password }) => {
    const status = await axiosInstance
      .put('/me/password', { password })
      .then(response => response.data)
      .catch(defaultCatchErrorCallback);

    return status;
  },
);
