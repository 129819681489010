import { FC, useState } from 'react';

import {
  createClassNames,
  getCellColorByPercent,
  getCellColorByValue,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import { TableMetricTooltip } from '../../tableComponents';
import './SummaryPercentileColumn.styles.scss';

export interface ISummaryPercentileColumnHeaderProps {
  isLastInTable?: boolean;
}

export interface ISummaryPercentileColumnCellProps {
  value: number;
  isOrder?: boolean;
  fontWeightInherit?: boolean;
}

const classNames = createClassNames('summary-percentile-column-header');

export const SummaryPercentileColumnHeader: FC<ISummaryPercentileColumnHeaderProps> = ({
  isLastInTable,
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isShown,
        }),
      })}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <div className={classNames('label')}>∅ PCT</div>
      <TableMetricTooltip
        metricInfo={{
          eng: 'Summary percentile %',
          description: 'Souhrný percentil všech statistik hráče',
        }}
        isShown={isShown}
        isLastChildRightTable={isLastInTable}
      />
    </div>
  );
};

const classNamesCell = createClassNames('summary-percentile-column-cell');

export const SummaryPercentileColumnCell: FC<ISummaryPercentileColumnCellProps> = ({
  value,
  isOrder,
  fontWeightInherit,
}) => (
  <div
    className={classNamesCell({
      ...transformObjectKeysToKebabCase({
        color: !isOrder ? getCellColorByPercent(value) : getCellColorByValue(value),
        fontWeightInherit,
      }),
    })}
  >
    {value}
  </div>
);
