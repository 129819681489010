import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { createClassNames } from '../../../utils';
import { DownloadManagementButton } from '../DownloadManagementButton';
import './DownloadManagementRow.styles.scss';

export interface IDownloadManagementRowProps {
  /**
   * Type of highligh shown on the video.
   */
  type: string;
  /**
   * Date of the match.
   */
  date: string;
  /**
   * Shortcut of home team name.
   */
  homeShortcut: string;
  /**
   * Shortcut of away team name.
   */
  awayShortcut: string;
  /**
   * Score of the match.
   */
  score: string;
  /**
   * Formated player #number lastName firstName.
   */
  player: string;
  /**
   * Time of the match, when highlight happened.
   */
  time: string;
  /**
   * file url
   */
  file: string;
  /**
   * Does file link includes multiple videos?
   */
  multiple?: boolean;
}

const classNames = createClassNames('download-management-row');

/**
 * DownloadManagementRow UI component.
 */
export const DownloadManagementRow: FC<IDownloadManagementRowProps> = ({
  type,
  date,
  homeShortcut,
  awayShortcut,
  score,
  player,
  time,
  file,
  multiple,
}) => {
  const { t } = useTranslation();

  const handleDownload = () => window.open(file);

  return (
    <tr className={classNames()}>
      <td>
        <p className={classNames('type')}>{t(type) ? t(type) : type}</p>
      </td>
      <td>
        <div className={classNames('match')}>
          {date}, {homeShortcut} - {awayShortcut} {score}
        </div>
      </td>
      <td>
        <p className={classNames('player')}>
          {player} {multiple && <span>({t(ITranslationKeys.multipleFiles)})</span>}
        </p>
      </td>
      <td>
        <p className={classNames('time')}>{time}</p>
      </td>
      <td>
        <DownloadManagementButton
          label={ITranslationKeys.downloadVideo}
          type='download'
          onClick={handleDownload}
        />
      </td>
      {/* //TODO: after delete endpoint addition
      <td>
        <DownloadManagementButton label={ITranslationKeys.deleteVideo} type='delete' />
      </td> */}
    </tr>
  );
};
