import { ITranslationKeys } from '../../../../i18n/types';

export interface IPlayerCardConfig {
  metricId: string;
  label: ITranslationKeys;
}

export const attackCardConfig: IPlayerCardConfig[] = [
  { metricId: 'g60', label: ITranslationKeys.goals },
  { metricId: 'xg60', label: ITranslationKeys.expectedGoals },
  { metricId: 'pgf_pct', label: ITranslationKeys.productivity },
  { metricId: 'sa60', label: ITranslationKeys.shotAssists },
  { metricId: 'xgf_sa60', label: ITranslationKeys.expectedGoalsFromAssists },
  { metricId: 'gf60_rel', label: ITranslationKeys.influenceOnScoredTeamGoals },
  { metricId: 'xgf60_rel', label: ITranslationKeys.influenceOnExpectedTeamGoals },
];

export const defendCardConfig: IPlayerCardConfig[] = [
  { metricId: 'blkca_pct', label: ITranslationKeys.enemyBlocksOrShots },
  { metricId: 'end60', label: ITranslationKeys.avoidedEntryIntoZone },
  { metricId: 'enar_percent', label: ITranslationKeys.bluelineDefending },
  { metricId: 'exd60', label: ITranslationKeys.zoneExitDenials },
  { metricId: 'dibaw_pct_rel', label: ITranslationKeys.influenceOnSuccessOfFightsAfterDumpins },
  { metricId: 'ga60_rel', label: ITranslationKeys.influenceOnRecivedGoalsOfTeam },
  { metricId: 'xga60_rel', label: ITranslationKeys.influenceOnExpectedGoalsOfEnemy },
];

export const transitionCardConfig: IPlayerCardConfig[] = [
  { metricId: 'en60', label: ITranslationKeys.controlledZoneEntries },
  { metricId: 'enr_percent', label: ITranslationKeys.preferenceOfControlledEntries },
  { metricId: 'enp60', label: ITranslationKeys.zoneEntryPasses },
  { metricId: 'dib60', label: ITranslationKeys.dumpinsWithFight },
  { metricId: 'ex60', label: ITranslationKeys.controlledZoneExits },
  { metricId: 'exen60', label: ITranslationKeys.transitionsFromDefensiveToOffensiveZone },
  { metricId: 'stp.ex60', label: ITranslationKeys.stretchPassZoneExits },
];

export const powerPlaysCardConfig: IPlayerCardConfig[] = [
  { metricId: 'g60', label: ITranslationKeys.goals },
  { metricId: 'xg60', label: ITranslationKeys.expectedGoals },
  { metricId: 'ssa60', label: ITranslationKeys.slotShotAssists },
  { metricId: 'xgf_sa60', label: ITranslationKeys.expectedGoalsFromAssists },
  { metricId: 'en60', label: ITranslationKeys.controlledZoneEntries },
  { metricId: 'gf60_rel', label: ITranslationKeys.influenceOnScoredTeamGoals },
  { metricId: 'xgf60_rel', label: ITranslationKeys.influenceOnExpectedTeamGoals },
];

export const penaltyKillingCardConfig: IPlayerCardConfig[] = [
  { metricId: 'blkca_pct', label: ITranslationKeys.enemyBlocksOrShots },
  { metricId: 'end60', label: ITranslationKeys.avoidedEntryIntoZone },
  { metricId: 'exd60', label: ITranslationKeys.zoneExitDenials },
  { metricId: 'ex60', label: ITranslationKeys.controlledZoneExits },
  { metricId: 'do60', label: ITranslationKeys.dumpouts },
  { metricId: 'ga60_rel', label: ITranslationKeys.influenceOnRecivedGoalsOfTeam },
  { metricId: 'xga60_rel', label: ITranslationKeys.influenceOnExpectedGoalsOfEnemy },
];
