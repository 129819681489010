import { FC } from 'react';

import { useSimpleHeatmap } from '../../../hooks';
import { IHeatmap } from '../../../types';
import { calculateShotOrPassPosition, createClassNames } from '../../../utils';
import './HeatmapGames.styles.scss';

const width = 512;
const height = 512;

export interface IHeatmapGamesProps {
  data: IHeatmap;
}
const classNames = createClassNames('heatmap-games');

export const HeatmapGames: FC<IHeatmapGamesProps> = ({ data }) => {
  const heatDataHome: [number, number, number][] = data.for.map(item => {
    const { x, y } = calculateShotOrPassPosition(item.x, item.y, width);
    return [x, y, 1];
  });

  const heatDataAway: [number, number, number][] = data.against.map(item => {
    const { x, y } = calculateShotOrPassPosition(item.x, item.y, width);
    return [x, y, 1];
  });

  const canvasRefHome = useSimpleHeatmap(heatDataHome, 29, 40);
  const canvasRefAway = useSimpleHeatmap(heatDataAway, 29, 40);

  return (
    <div className={classNames()} data-testid='heatmap-games'>
      <div className={classNames('canvas-container')}>
        <canvas
          className={classNames('canvas-map')}
          ref={canvasRefHome}
          width={width}
          height={height}
        />
        <canvas
          className={classNames('canvas-map', { away: true })}
          ref={canvasRefAway}
          width={width}
          height={height}
        />
      </div>
    </div>
  );
};
