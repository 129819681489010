import { CSSProperties, FC, ReactNode } from 'react';

import { createClassNames } from '../../../utils';
import './HeadCellBase.styles.scss';

interface IHeadCellBaseProps {
  /** Wrapped element */
  children: ReactNode;
  /** Custom style for wrapper */
  style?: CSSProperties;
}

const classNames = createClassNames('head-cell-base');

/** Wrapper component adds most common table head cell style. */
export const HeadCellBase: FC<IHeadCellBaseProps> = ({ children, style }) => (
  <div className={classNames()} style={style}>
    {children}
  </div>
);
