import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { createClassNames } from '../../../utils';
import { Bar } from '../../Bar';
import './GameExpectedWin.styles.scss';

interface IGameExpectedWinProps {
  /**
   * Percent of home team.
   */
  homePercent: number;
  /**
   * Percent of away team.
   */
  awayPercent: number;
}

const classNames = createClassNames('game-expected-win');

/**
 * GameExpectedWin UI component including Bar components.
 */
export const GameExpectedWin: FC<IGameExpectedWinProps> = ({ awayPercent, homePercent }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      <div className={classNames('percentile')}>{Math.round(homePercent)}%</div>
      <div className={classNames('bar')}>
        <Bar
          metricValue={homePercent}
          percent={homePercent}
          color='purple'
          showTooltip={false}
          reversed
        />
      </div>
      <span className={classNames('text')}>{t(ITranslationKeys.winExpectation)}</span>
      <div className={classNames('bar')}>
        <Bar metricValue={awayPercent} percent={awayPercent} color='orange' showTooltip={false} />
      </div>
      <div className={classNames('percentile')}>{Math.round(awayPercent)}%</div>
    </div>
  );
};
