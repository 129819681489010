import { AxiosError } from 'axios';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { logout, putNewPassword, selectAuth } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { changeRequestFailed, createClassNames, validatePassword } from '../../../utils';
import { Button } from '../../Button';
import { Input } from '../../Input';
import './ChangePassword.styles.scss';

const classNames = createClassNames('change-password');

/** ChangePassword UI component. */
export const ChangePassword = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);
  const [password, setPassword] = useState<string>('');
  const [passwordAgain, setPasswordAgain] = useState<string>('');

  const handleChangePassword = () => {
    if (!user) return;
    const validation = validatePassword(password, passwordAgain);

    if (validation === 'dont-match') {
      toast.error(t(ITranslationKeys.passwordsDontMatch), {
        toastId: ITranslationKeys.passwordsDontMatch,
      });
    } else if (validation === 'too-short') {
      toast.error(t(ITranslationKeys.passwordTooShort), {
        toastId: ITranslationKeys.passwordTooShort,
      });
    } else {
      dispatch(putNewPassword({ password }))
        .unwrap()
        .then(() => {
          toast.success(t(ITranslationKeys.passwordChangedLoginAgain), {
            toastId: ITranslationKeys.passwordChangedLoginAgain,
          });
          dispatch(logout());
        })
        .catch((error: AxiosError) => {
          changeRequestFailed(
            t(ITranslationKeys.cantChangePasswordErrorMessage),
            ITranslationKeys.cantChangePasswordErrorMessage,
            error,
          );
        });
    }

    setPassword('');
    setPasswordAgain('');
  };

  const handlePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handlePasswordAgain = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordAgain(event.target.value);
  };

  return (
    <div className={classNames()}>
      <h2>{t(ITranslationKeys.changeOfPassword)}</h2>
      <div className={classNames('form')}>
        <Input
          variant='filter'
          type='password'
          placeholder={`${t(ITranslationKeys.newPassword)}`}
          value={password}
          onChange={handlePassword}
        />
        <Input
          variant='filter'
          type='password'
          placeholder={`${t(ITranslationKeys.newPasswordAgain)}`}
          value={passwordAgain}
          onChange={handlePasswordAgain}
        />
        <Button label={ITranslationKeys.changePassword} onClick={handleChangePassword} />
      </div>
    </div>
  );
};
