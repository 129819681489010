import { FC } from 'react';

import { TcmsEditIcon, TrashIcon } from '../../../icons';
import { createClassNames } from '../../../utils';
import { Switch } from '../Switch';
import './TcmsItemActions.styles.scss';

interface ITcmsItemActionsProps {
  itemIndex: number;
  handleItemEditClick: (itemIndex: number) => void;
  handleItemRemoveClick: (itemIndex: number) => void;
  handleItemDisableClick?: (itemIndex: number) => void;
}

const classNames = createClassNames('tcms-item-actions');

export const TcmsItemActions: FC<ITcmsItemActionsProps> = ({
  itemIndex,
  handleItemDisableClick,
  handleItemEditClick,
  handleItemRemoveClick,
}) => (
  <div className={classNames()}>
    {handleItemDisableClick && (
      <div className={classNames('switch')}>
        <Switch checked onClick={() => handleItemDisableClick(itemIndex)} />
      </div>
    )}
    <div className={classNames('edit')}>
      <TcmsEditIcon onClick={() => handleItemEditClick(itemIndex)} />
    </div>
    <div className={classNames('delete')}>
      <TrashIcon onClick={() => handleItemRemoveClick(itemIndex)} />
    </div>
  </div>
);
