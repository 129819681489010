import { CellContext, HeaderContext } from '@tanstack/react-table';
import { CSSProperties, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { SortableArrowIcon } from '../../../icons';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './UserLogColumn.styles.scss';

type IAppLogoColor = 'default' | 'blue';

export interface IUserLogColumnHeaderProps<TData, TValue> extends HeaderContext<TData, TValue> {
  children?: ReactNode;
  translationKey?: ITranslationKeys;
  allowSortIcon?: boolean;
  justifyContent?: CSSProperties['justifyContent'];
  appLogo?: ReactNode;
  color?: IAppLogoColor;
}

export interface IUserLogColumnCellProps<TData, TValue> extends CellContext<TData, TValue> {
  children?: ReactNode;
  color?: IAppLogoColor;
}

const classNamesHeader = createClassNames('user-log-column-header');

export const UserLogColumnHeader = <TData, TValue>({
  column,
  children,
  translationKey,
  allowSortIcon = false,
  justifyContent = 'center',
  appLogo,
  color = 'default',
}: IUserLogColumnHeaderProps<TData, TValue>) => {
  const { t } = useTranslation();
  const isSortable = column.getCanSort();
  const sortDirection = column.getIsSorted();

  return (
    <div
      className={classNamesHeader({
        ...transformObjectKeysToKebabCase({
          isSortable,
          color,
        }),
      })}
      onClick={isSortable ? column.getToggleSortingHandler() : undefined}
      style={{ justifyContent }}
    >
      <div className={classNamesHeader('app-logo')}>{appLogo}</div>
      {translationKey && t(translationKey)}
      {children}
      {allowSortIcon && isSortable && (
        <div className={classNamesHeader('arrows', { sorted: !!sortDirection })}>
          <SortableArrowIcon
            className={classNamesHeader('arrows__arrow', {
              ...transformObjectKeysToKebabCase({
                sortDirection,
              }),
            })}
          />
        </div>
      )}
    </div>
  );
};
