import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { ITranslationKeys } from '../../../i18n/types';
import { axiosInstance } from '../../../services/axiosInstance';
import { IReportMetrics } from '../../../types';
import { defaultCatchErrorCallback } from '../../../utils';

export const getReportMetrics = createAsyncThunk<IReportMetrics>(
  'settings/reportMetrics/getReportMetrics',
  async () => {
    const reportMetrics: IReportMetrics = await axiosInstance
      .get(`/setting/reportMetrics`)
      .then(response => response.data)
      .catch(defaultCatchErrorCallback);

    return reportMetrics;
  },
);

export const putReportMetrics = createAsyncThunk<IReportMetrics | undefined, IReportMetrics>(
  'settings/reportMetrics/putReportMetrics',
  async newReportMetrics => {
    const status = await axiosInstance
      .put(`/setting/reportMetrics`, newReportMetrics)
      .then(response => response.status)
      .catch((error: AxiosError) => {
        const errorMessage = error.message ?? ITranslationKeys.saveDataFailedMessage;
        throw new Error(errorMessage);
      });

    return status === 204 ? newReportMetrics : undefined;
  },
);
