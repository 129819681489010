import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredMainFilterDataSelector,
  filteredVideomapsFilterDataSelector,
  getDumpin,
  getDumpout,
  getZoneEntry,
  getZoneEntryAndDumpin,
  getZoneExit,
  getZoneExitAndDumpout,
  resetZones,
  selectMainFilter,
  selectVideomapsFilter,
} from '../../features';
import {
  IFetchDataConfig,
  IFormation,
  IGame,
  IMainFilterForm,
  IZonesFetchDataMode,
  IZonesRequestBody,
} from '../../types';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

interface IZonesFetchDataConfig extends IFetchDataConfig {
  mode?: IZonesFetchDataMode;
  games?: IGame[];
}

export const useFetchZones = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const dispatch = useAppDispatch();
  const { selectedTeam } = useAppSelector(selectMainFilter);
  const { selectedPlayerItems } = useAppSelector(selectVideomapsFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { filteredGames } = useAppSelector(filteredVideomapsFilterDataSelector);

  const fetchZoneExits = (data: Partial<IMainFilterForm>, config?: IZonesFetchDataConfig) => {
    const teamUuid = config?.customSelectedTeamId || selectedTeam?.value;
    if (!teamUuid) return;
    const competitionsUuids = filteredParts.map(part => part.id);
    const selectedMatchUuids =
      config?.games?.map(game => game.id) || filteredGames.map(game => game.id);
    const filteredSelectedPlayers = Object.values(selectedPlayerItems).filter(
      item => item.selectedPlayer,
    );
    const selectedPlayers = config?.customSelectedPlayerItems || filteredSelectedPlayers;

    const formation: IFormation[] = selectedPlayers.map(item => ({
      player: item.selectedPlayer?.value || '',
      on: item.isActive ?? false,
    }));
    const isValidFormation = formation.length > 0;

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IZonesRequestBody = {
      ...requestBodyBase,
      matches: selectedMatchUuids,
    };

    dispatch(resetZones());

    const requestBodyWithFormation: IZonesRequestBody = {
      ...requestBodyBase,
      formation: isValidFormation ? formation : undefined,
      matches: selectedMatchUuids,
    };

    const sharedParams =
      selectedPlayers.length === 0
        ? {
            competitionsUuids,
            teamUuid,
            body: requestBody,
          }
        : {
            competitionsUuids,
            teamUuid,
            body: requestBodyWithFormation,
            suffix: 'formation' as const,
          };

    if (config?.mode === IZonesFetchDataMode.dumpInOut) {
      dispatch(getDumpout(sharedParams));
      return;
    }

    if (config?.mode === IZonesFetchDataMode.both) {
      dispatch(getZoneExitAndDumpout(sharedParams));
      return;
    }

    dispatch(getZoneExit(sharedParams));
  };

  const fetchZoneEntries = (data: Partial<IMainFilterForm>, config?: IZonesFetchDataConfig) => {
    const teamUuid = config?.customSelectedTeamId || selectedTeam?.value;
    if (!teamUuid) return;
    const competitionsUuids = filteredParts.map(part => part.id);
    const selectedMatchUuids =
      config?.games?.map(game => game.id) || filteredGames.map(game => game.id);
    const filteredSelectedPlayers = Object.values(selectedPlayerItems).filter(
      item => item.selectedPlayer,
    );
    const selectedPlayers = config?.customSelectedPlayerItems || filteredSelectedPlayers;

    const formation: IFormation[] = selectedPlayers.map(item => ({
      player: item.selectedPlayer?.value || '',
      on: item.isActive ?? false,
    }));
    const isValidFormation = formation.length > 0;

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IZonesRequestBody = {
      ...requestBodyBase,
      matches: selectedMatchUuids,
    };

    dispatch(resetZones());

    const requestBodyWithFormation: IZonesRequestBody = {
      ...requestBodyBase,
      formation: isValidFormation ? formation : undefined,
      matches: selectedMatchUuids,
    };

    const sharedParams =
      selectedPlayers.length === 0
        ? {
            competitionsUuids,
            teamUuid,
            body: requestBody,
          }
        : {
            competitionsUuids,
            teamUuid,
            body: requestBodyWithFormation,
            suffix: 'formation' as const,
          };

    if (config?.mode === IZonesFetchDataMode.dumpInOut) {
      dispatch(getDumpin(sharedParams));
      return;
    }

    if (config?.mode === IZonesFetchDataMode.both) {
      dispatch(getZoneEntryAndDumpin(sharedParams));
      return;
    }

    dispatch(getZoneEntry(sharedParams));
  };

  return { fetchZoneExits, fetchZoneEntries };
};
