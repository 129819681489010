import { createSelector } from '@reduxjs/toolkit';

import { filterAllTeamPlayersByParts, filterPlayersByPosition } from '../../utils';
import { filteredPartsSelector, filteredPlayersSelector } from '../mainFilter';
import { selectPlayers, selectTableFilter } from '../selectors';

export const filteredPlayersByPositionSelector = createSelector(
  [filteredPlayersSelector, selectTableFilter],
  (filteredPlayers, tableFilter) => {
    const { position } = tableFilter;

    return filterPlayersByPosition(filteredPlayers, position);
  },
);

export const filteredAllTeamsPlayersByPositionSelector = createSelector(
  [selectPlayers, filteredPartsSelector, selectTableFilter],
  (players, filteredParts, tableFilter) => {
    const { position } = tableFilter;
    const filteredPlayers = filterAllTeamPlayersByParts(players.byId, filteredParts);

    return filterPlayersByPosition(filteredPlayers, position);
  },
);
