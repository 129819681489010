import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  shootoutEndResultOptions,
  shootoutEndTypeOptions,
  shootoutStickGripOptions,
  shotoutEndSideOptions,
} from '../../../constants';
import { ISelectOption, IShootoutFilterState } from '../../../types';
import { getDefaultPredefinedOptionByValue } from '../../../utils';

const initialState: IShootoutFilterState = {
  endResult: getDefaultPredefinedOptionByValue(shootoutEndResultOptions, 'all'),
  endType: getDefaultPredefinedOptionByValue(shootoutEndTypeOptions, 'all'),
  endSide: getDefaultPredefinedOptionByValue(shotoutEndSideOptions, 'all'),
  stickGrip: getDefaultPredefinedOptionByValue(shootoutStickGripOptions, 'all'),
};

export const shootoutFilterSlice = createSlice({
  name: 'shootoutFilter',
  initialState,
  reducers: {
    setShootoutEndResult: (state, action: PayloadAction<ISelectOption>) => {
      state.endResult = action.payload;
    },
    setShootoutEndType: (state, action: PayloadAction<ISelectOption>) => {
      state.endType = action.payload;
    },
    setShootoutEndSide: (state, action: PayloadAction<ISelectOption>) => {
      state.endSide = action.payload;
    },
    setShootoutStickGrip: (state, action: PayloadAction<ISelectOption>) => {
      state.stickGrip = action.payload;
    },
    resetShootoutFilter: (_, action: PayloadAction<Partial<IShootoutFilterState> | undefined>) => ({
      ...initialState,
      ...action.payload,
    }),
  },
});

export const {
  setShootoutEndResult,
  setShootoutEndType,
  setShootoutEndSide,
  setShootoutStickGrip,
  resetShootoutFilter,
} = shootoutFilterSlice.actions;
