import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  CommonTable,
  DataSettingsModal,
  Loading,
  TableToolbar,
} from '../../../../components';
import { DEFAULT_CONTENT_TEMPLATE_NAMES } from '../../../../constants';
import {
  filteredMainFilterDataSelector,
  getGamelog,
  selectDataSettingsFilter,
  selectGamelog,
  selectMainFilter,
  selectMetrics,
  selectVideomapsFilter,
  setOpenDataSettings,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useHandleOnSubmit,
  useNormalizeMetrics,
  usePrepareBaseRequestBody,
  useRefetchContent,
  useTableCommonEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import {
  IEntity,
  IExportRows,
  IFormation,
  IGamelogPlayerStatsTableData,
  IGamelogRequestBody,
  IMainFilterForm,
} from '../../../../types';
import {
  createClassNames,
  formatDateByLanguage,
  getScoreStateShortcut,
  handleExportToXLSX,
  isAtLeastOnePlayerOn,
  roundNumberTo2Decimals,
} from '../../../../utils';
import './GamelogContent.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';

export const useFetchPlayerGamelog = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { selectedTeam } = useAppSelector(selectMainFilter);
  const { selectedPlayerItems } = useAppSelector(selectVideomapsFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchPlayerGamelog = (data: Partial<IMainFilterForm>) => {
    const competitionsUuids = filteredParts.map(part => part.id);

    const filteredSelectedPlayers = selectedPlayerItems
      ? Object.values(selectedPlayerItems).filter(item => item.selectedPlayer)
      : [];
    const formation: IFormation[] = filteredSelectedPlayers.map(item => ({
      player: item.selectedPlayer?.value ?? '',
      on: item.isActive ?? false,
    }));

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IGamelogRequestBody = {
      ...requestBodyBase,
      formation: formation,
      metrics: normalizeMetrics(DEFAULT_CONTENT_TEMPLATE_NAMES.gamelog, IEntity.players),
    };

    const isSomePlayerOn = isAtLeastOnePlayerOn(selectedPlayerItems, false);
    const isSelectionValid = filteredParts.length > 0 && selectedTeam && isSomePlayerOn;

    if (isSelectionValid) {
      dispatch(
        getGamelog({
          competitionsUuids,
          teamUuid: selectedTeam.value,
          body: requestBody,
        }),
      );
    }
  };

  return fetchPlayerGamelog;
};

const classNames = createClassNames('players-gamelog-content');

export const GamelogContent = () => {
  const { isLoading } = useAppSelector(selectGamelog);
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { metrics } = useAppSelector(selectMetrics);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const data = useDataForTable();
  const { columns, columnPinning, initialSorting } = useColumnsConfig();

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();

  const centerTablePartRef = useRef<HTMLDivElement | null>(null);
  const isInitialMountRef = useRef(true);

  const fetchPlayerGamelog = useFetchPlayerGamelog();
  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchPlayerGamelog(values);
  }, isInitialMountRef);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
  });

  useRefetchContent({ handleOnSubmit, isLoading });

  const commonHeader = [
    t(ITranslationKeys.homeOnes),
    t(ITranslationKeys.awayOnes),
    t(ITranslationKeys.date),
    t(ITranslationKeys.result),
    'TOI',
    t(ITranslationKeys.lineup),
  ];
  const tableRowCallback = (row: IGamelogPlayerStatsTableData): IExportRows => {
    const scoreState = getScoreStateShortcut(row.score.state);

    return {
      homeTeam: row.gameTeams.homeTeam.shortcut,
      awayTeam: row.gameTeams.awayTeam.shortcut,
      date: formatDateByLanguage(new Date(row.date), 'dd.MM.yyyy'),
      score: `${row.score.home}:${row.score.away}${scoreState ? t(scoreState) : ''}`,
      toi: roundNumberTo2Decimals(row.toi / 60),
      lineup: row.lineup,
    };
  };

  if (isLoading) {
    return <Loading />;
  }

  const renderTableOrInfoBox = () => {
    const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, data.length);
    if (errorInfoBox) {
      return errorInfoBox;
    }

    return (
      <CommonTable<IGamelogPlayerStatsTableData>
        centerPartRef={centerTablePartRef}
        {...{ data, columns, columnPinning, initialSorting }}
      />
    );
  };

  return (
    <div className={classNames()} data-testid='players-page__gamelog-content'>
      <TableToolbar
        centerTablePartRef={centerTablePartRef}
        scrollSizePx={130}
        disablePositionSelection
        exportButton={
          <Button
            label={ITranslationKeys.exportData}
            iconComponent={<DownloadIcon />}
            iconPosition='right'
            onClick={() =>
              handleExportToXLSX(
                t(ITranslationKeys.playersGamelog),
                commonHeader,
                tableRowCallback,
                data,
              )
            }
          />
        }
      />
      {renderTableOrInfoBox()}
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.players}
          open={open}
          onClose={() => dispatch(setOpenDataSettings(false))}
          individualName={ITranslationKeys.playerData}
          onIceName={ITranslationKeys.teamsData}
        />
      )}
    </div>
  );
};
