import { FC, useRef } from 'react';

import { useAppSelector } from '../../../app/hooks';
import { selectTeams } from '../../../features';
import { IGame, IMultiParamsVoid } from '../../../types';
import { createClassNames, handleScroll } from '../../../utils';
import { Loading } from '../../Loading';
import { NoDataGames } from '../../NoDataGames';
import { ScrollButton } from '../../ScrollButton';
import { GameButton } from '../GameButton';
import './GameButtonList.styles.scss';

export interface IGameButtonListProps {
  /** List of available games. */
  games: IGame[];
  /** Selected game. */
  selectedGame: IGame | undefined;
  /** Are games loading? */
  isLoading?: boolean;
  /** Should component show no data message? */
  showNoDataMessage?: boolean;
  /** Function called when other game is picked. */
  onChange: IMultiParamsVoid;
}

const classNames = createClassNames('game-button-list');

export const GameButtonList: FC<IGameButtonListProps> = ({
  games,
  selectedGame,
  isLoading,
  showNoDataMessage,
  onChange,
}) => {
  const teams = useAppSelector(selectTeams);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const renderScrollPart = () => {
    if (isLoading) {
      return (
        <Loading
          size='medium'
          backgroundColor='transparent'
          disableMessage
          disableHorizontalMargin
          disableAbsolutePosition
        />
      );
    }

    if (showNoDataMessage) {
      return <NoDataGames />;
    }

    return games.map(game => (
      <GameButton
        key={game.id}
        id={game.id}
        homeTeam={teams.byId[game.homeTeamId]}
        awayTeam={teams.byId[game.awayTeamId]}
        score={game.score}
        date={game.date}
        isActive={game.id === selectedGame?.id}
        onClick={() => onChange(game)}
      />
    ));
  };

  return (
    <div className={classNames()} data-testid='game-button-list'>
      <ScrollButton isLeft onClick={() => handleScroll(scrollRef, 'left', 320)} />
      <div ref={scrollRef} className={classNames('scroll-part')}>
        {renderScrollPart()}
      </div>
      <ScrollButton onClick={() => handleScroll(scrollRef, 'right', 320)} />
    </div>
  );
};
