import { useAppDispatch } from '../../app/hooks';
import {
  setEnterExitLocation,
  setEntryExitType,
  setGameActionType,
  setNetZone,
  setPuckGainType,
  setShotDanger,
  setShotLocation,
  setShotType,
} from '../../features';
import { ITranslationKeys } from '../../i18n/types';
import {
  IDatasetMetric,
  IMetricWithDatasetMetric,
  INavigationContentKeys,
  INetZoneUnion,
} from '../../types';
import { getMetricParametersInfo } from '../../utils';
import {
  useSetEntityFilter,
  useSetFaceoffsFilter,
  useSetPassesFilter,
  useSetShootoutsFilter,
  useSetShotsFilter,
  useSetZonesFilter,
} from './filterSetters';

export const useSetFilterByMetricParams = () => {
  const dispatch = useAppDispatch();

  const { setEntityFilterSetter } = useSetEntityFilter();
  const { setShotsFilterSetter } = useSetShotsFilter();
  const { setPassesFilterSetter } = useSetPassesFilter();
  const { setZonesFilterSetter } = useSetZonesFilter();
  const { setFaceoffsFilterSetter } = useSetFaceoffsFilter();
  const { setShootoutsFilterSetter } = useSetShootoutsFilter();

  const setFilterByMetricParams = (datasetMetric: IDatasetMetric) => {
    const parametersInfo = getMetricParametersInfo(datasetMetric);

    parametersInfo?.forEach(({ id, paramGroupLabel, label }) => {
      if (paramGroupLabel === ITranslationKeys.shotLocation) {
        dispatch(setShotLocation({ value: id, label }));
      }

      if (paramGroupLabel === ITranslationKeys.shotTypes) {
        dispatch(setShotType({ value: id, label }));
      }

      if (paramGroupLabel === ITranslationKeys.shotsDanger) {
        dispatch(setShotDanger({ value: id, label }));
      }

      if (paramGroupLabel === ITranslationKeys.attackType) {
        dispatch(setGameActionType({ value: id, label }));
      }

      if (paramGroupLabel === ITranslationKeys.netZones) {
        dispatch(setNetZone(id as INetZoneUnion));
      }

      if (
        paramGroupLabel === ITranslationKeys.entryExitType ||
        paramGroupLabel === ITranslationKeys.exitType
      ) {
        dispatch(setEntryExitType({ value: id, label }));
      }

      if (paramGroupLabel === ITranslationKeys.enterExitLocation) {
        dispatch(setEnterExitLocation({ value: id, label }));
      }

      if (paramGroupLabel === ITranslationKeys.puckGainType) {
        dispatch(setPuckGainType({ value: id, label }));
      }
    });
  };

  const setFilterByMetricSubgroup = (stat: IMetricWithDatasetMetric, entityId?: string) => {
    setEntityFilterSetter(stat);
    if (stat.metric.metricVideoLinkId === INavigationContentKeys.shots) setShotsFilterSetter(stat);
    if (stat.metric.metricVideoLinkId === INavigationContentKeys.passes)
      setPassesFilterSetter(stat);
    if (stat.metric.metricVideoLinkId === INavigationContentKeys.zoneEntries)
      setZonesFilterSetter(stat, entityId);
    if (stat.metric.metricVideoLinkId === INavigationContentKeys.zoneExits)
      setZonesFilterSetter(stat, entityId);
    if (stat.metric.metricVideoLinkId === INavigationContentKeys.faceoffs)
      setFaceoffsFilterSetter(stat);
    if (stat.metric.metricVideoLinkId === INavigationContentKeys.shootouts)
      setShootoutsFilterSetter(stat);
  };

  return {
    setFilterByMetricParams,
    setFilterByMetricSubgroup,
  };
};
