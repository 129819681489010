import { useEffect, useRef } from 'react';

import { useAppSelector } from '../app/hooks';
import { selectNavigation, selectNavigationNotPersisted } from '../features';
import { IHandleOnSubmitConfig } from '../types';
import { useLocationPaths } from './useLocationPaths';

export interface IUseRefetchContentConfig {
  handleOnSubmit: (config?: IHandleOnSubmitConfig) => Promise<void>;
  isLoading?: boolean;
  disableActiveTabRefetch?: boolean;
  disableInitialRefetch?: boolean;
}

/**
 * Refetches data on click `Load data` button at filter or on active tab change.
 * @param handleOnSubmit Callback function to handle submit for specific content.
 * @param isLoading Is data loading for specific content.
 * @param disableActiveTabRefetch Disables refetch data on activeTab change.
 * @param disableInitialRefetch Disables refetch data on init content.
 */
export const useRefetchContent = ({
  handleOnSubmit,
  isLoading,
  disableActiveTabRefetch = false,
  disableInitialRefetch = false,
}: IUseRefetchContentConfig) => {
  const { activeTab } = useLocationPaths();
  const { triggerContentReloadValue } = useAppSelector(selectNavigation);
  const { isInitialized } = useAppSelector(selectNavigationNotPersisted);

  const prevDepsValuesRef = useRef({
    activeTab,
    triggerContentReloadValue,
  });

  useEffect(() => {
    if (isInitialized) {
      if (prevDepsValuesRef.current.triggerContentReloadValue !== triggerContentReloadValue) {
        if (!isLoading && triggerContentReloadValue !== 0) {
          // TODO: smazat potom
          console.log('handleOnSubmit trigger');
          handleOnSubmit();
        }
      } else if (prevDepsValuesRef.current.activeTab !== activeTab) {
        if (!disableActiveTabRefetch) {
          // TODO: smazat potom
          console.log('handleOnSubmit activeTab');
          handleOnSubmit();
        }
      } else {
        if (!disableInitialRefetch) {
          // TODO: smazat potom
          console.log('handleOnSubmit init');
          handleOnSubmit();
        }
      }
    }

    prevDepsValuesRef.current = {
      activeTab,
      triggerContentReloadValue,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, triggerContentReloadValue, isInitialized]);
};
