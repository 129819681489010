import { IPlayerCircleProps } from '../components';
import { COLORS } from '../constants';
import { ITranslationKeys } from '../i18n/types';
import {
  ICoordinates,
  IGame,
  IGameEntity,
  IGameTimelineBase,
  IGamesTablesFormations,
  IGraphicOverviewPlayer,
  IH2HCategory,
  IHeadToHead,
  IHeadToHeadDTO,
  IHeadToHeadOpponent,
  IHeadToHeadPlayer,
  IPlayerRecord,
  IPlayerWithTeamIdAndStats,
  ISelectOption,
  IShot,
  ITeamRecord,
  ITeamsShots,
  ITimeFromTo,
  IVideoInfo,
} from '../types';
import {
  convertPlayerFullNameToShortName,
  findChartMaxValue,
  secondsToMinutesAndSeconds,
} from '../utils';

export const convertPlayersToShotFlowGraphItems = (
  graphicOverviewPlayersHome: IGraphicOverviewPlayer[],
  graphicOverviewPlayersAway: IGraphicOverviewPlayer[],
  players: IPlayerRecord,
) => {
  const extremes = getBoundExtremes(graphicOverviewPlayersHome, graphicOverviewPlayersAway);
  const home = transformShotFlowGraphTeamItems(
    graphicOverviewPlayersHome,
    players,
    'home',
    0,
    extremes.max,
  );
  const away = transformShotFlowGraphTeamItems(
    graphicOverviewPlayersAway,
    players,
    'away',
    0,
    extremes.max,
  );

  return {
    home: home,
    away: away,
    max: extremes.max,
    min: 0,
  };
};

/**
 * Computes the extremes of the shot flow graph.
 * @returns Extremes (max and min both from X and Y).
 */
export const getBoundExtremes = (
  graphicOverviewHomePlayers: IGraphicOverviewPlayer[],
  graphicOverviewAwayPlayers: IGraphicOverviewPlayer[],
) => {
  const allPlayers = [...graphicOverviewHomePlayers, ...graphicOverviewAwayPlayers];
  const maxX = Math.max.apply(
    Math,
    allPlayers.map(player => player.cf),
  );
  const minX = Math.min.apply(
    Math,
    allPlayers.map(player => player.cf),
  );
  const maxY = Math.max.apply(
    Math,
    allPlayers.map(player => player.ca),
  );
  const minY = Math.min.apply(
    Math,
    allPlayers.map(player => player.ca),
  );

  const max = Math.max(maxX, maxY);
  const min = Math.min(minX, minY);

  const maxCeiled = Math.ceil(max / 5) * 5;
  // If some player has the same value as the max, we need to add 5 to the max value so there will be visible padding.
  const maxPlayer = allPlayers.find(player => player.cf === maxCeiled || player.ca === maxCeiled);

  return {
    max: maxPlayer ? maxCeiled + 5 : maxCeiled,
    min: Math.floor(min / 5) * 5,
  };
};

/**
 * Computes the size of the player circle in the shot flow graph.
 * @param toi TOI of the player
 * @param inMin
 * @param inMax
 * @param outMin
 * @param outMax
 * @returns size of the player circle
 */
export const getShotFlowGraphPlayerCircleSize = (
  toi: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number,
) => ((toi - inMin) / (inMax - inMin)) * (outMax - outMin) + outMin;

/**
 * Converts graphic overview players and players to ShotFlowGraph items.
 * @param graphicOverviewPlayers IGraphicOverviewPlayer array type
 * @param players IPlayerRecord
 * @param team team of the players
 * @returns IPlayerCircleProps array type
 */
export const transformShotFlowGraphTeamItems = (
  graphicOverviewPlayers: IGraphicOverviewPlayer[],
  players: IPlayerRecord,
  team: 'home' | 'away',
  min: number,
  max: number,
): IPlayerCircleProps[] => {
  const maxToi = Math.max(...graphicOverviewPlayers.map(player => player.toi));
  const minToi = Math.min(...graphicOverviewPlayers.map(player => player.toi));
  const graphWidth = 496;
  const graphHeight = 496;

  return graphicOverviewPlayers.map(player => {
    const foundPlayer = players[player.uuid];

    return {
      playerInfo: {
        name: convertPlayerFullNameToShortName(foundPlayer.surname + ' ' + foundPlayer.name),
        cf_percent: player.cf_percent,
        cf: player.cf,
        ca: player.ca,
        toi: secondsToMinutesAndSeconds(player.toi),
      },
      team: team,
      x: (graphWidth * player.cf) / (max + min),
      y: (graphHeight * player.ca) / (max + min),
      size: getShotFlowGraphPlayerCircleSize(player.toi, minToi, maxToi, 10, 30),
    };
  });
};

const scaleBetween = (
  unscaledNum: number,
  minAllowed: number,
  maxAllowed: number,
  min: number,
  max: number,
) => ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) + minAllowed;

export const getPieChartPercent = (
  category: IH2HCategory,
  headToHeadData: IHeadToHead,
  playerUuid: string,
  opponentUuid: string,
) => {
  const config = {
    CFaCA: {
      f: 'cf',
      a: 'ca',
    },
    SCFaSCA: {
      f: 'scf',
      a: 'sca',
    },
    SOGFaSOGA: {
      f: 'sogf',
      a: 'soga',
    },
    XGFaXGA: {
      f: 'xgf',
      a: 'xga',
    },
  };

  const metrics = config[category];
  const player = headToHeadData.headToHead.find(player => player.uuid === playerUuid);
  if (!player) return 0;

  const opponent = player.opponents.find(opponent => opponent.uuid === opponentUuid);
  if (!opponent) return 0;

  const metricA = opponent.stats[metrics.a];
  const metricF = opponent.stats[metrics.f];
  return (100 / (metricF + metricA)) * metricA;
};

export const getPieChartSize = (
  headToHead: IHeadToHead,
  playerUuid: string,
  opponentUuid: string,
) => {
  const player = headToHead.headToHead.find(player => player.uuid === playerUuid);
  if (!player) return 0;

  const opponent = player.opponents.find(opponent => opponent.uuid === opponentUuid);
  if (!opponent) return 0;

  const size = Math.round(
    scaleBetween(opponent.stats.toi, 10, 30, headToHead.minToi, headToHead.maxToi),
  );
  return size;
};

export const getPieChartStyle = (
  category: IH2HCategory,
  size: number,
  headToHead: IHeadToHead,
  playerUuid: string,
  opponentUuid: string,
) => {
  const percent = getPieChartPercent(category, headToHead, playerUuid, opponentUuid);
  const grayPieChartSize = 14;

  if (!size || Number.isNaN(percent))
    return {
      pieChartSize: grayPieChartSize,
      pieChartMargin: (grayPieChartSize / 2) * -1,
      isGray: true,
    };

  return {
    pieChartSize: size,
    pieChartMargin: (size / 2) * -1,
    isGray: false,
  };
};

export const getH2HMetricValue = (
  data: IHeadToHead,
  playerUuid: string,
  opponentUuid: string,
  metric: string,
) => {
  const player = data.headToHead.find(item => item.uuid === playerUuid);
  if (!player) return null;

  const opponent = player.opponents.find(opponent => opponent.uuid === opponentUuid);
  if (!opponent) return null;

  return opponent.stats[metric];
};

/**
 * Reduce method callback that converts shot's xG growth value
 * to the sum of all previous xG growth values and given shot's xG value.
 * @param acc Array with shots for reduce callback.
 * @param shot Shot object from reduce callback.
 * @returns Converted shots array.
 */
export const mapShotsWithCalculatedXG = (acc: IShot[], shot: IShot) => {
  acc.push({
    ...shot,
    xG: (acc.length > 0 ? acc[acc.length - 1].xG : 0) + shot.xG,
  });

  return acc;
};

/**
 * Maps shot to x y coordinates.
 * @param shot Shot object.
 * @returns Coordinates object.
 */
export const mapShotToCoordinates = (shot: IShot): ICoordinates => ({
  x: shot.time,
  y: shot.xG,
});

/**
 * Gets chart series config from teams shots.
 * @param teamsShots Shots of both teams.
 * @param metricName Name of the y axis metric.
 * @param mapCallback Map shots callback.
 * @returns Config for the chart series.
 */
export const getChartSeriesFromShots = (
  teamsShots: ITeamsShots,
  metricName: string = 'xG',
  mapCallback: (acc: IShot[], shot: IShot) => IShot[] = mapShotsWithCalculatedXG,
): IGameTimelineBase => {
  const { homeTeam, awayTeam } = teamsShots;
  const homeCalculatedShots = homeTeam.shots.reduce<IShot[]>(mapCallback, []);
  const awayCalculatedShots = awayTeam.shots.reduce<IShot[]>(mapCallback, []);

  const dataHome = homeCalculatedShots.map(mapShotToCoordinates);
  const dataAway = awayCalculatedShots.map(mapShotToCoordinates);

  const homeGoals = homeCalculatedShots
    .filter(shot => shot.type === 'G')
    .map<ICoordinates>(mapShotToCoordinates);
  const awayGoals = awayCalculatedShots
    .filter(shot => shot.type === 'G')
    .map<ICoordinates>(mapShotToCoordinates);

  const yMaxValue = Math.ceil(findChartMaxValue([...dataHome, ...dataAway], 'y'));
  const maxTime = Math.max(
    ...homeTeam.shots.map(shot => shot.time),
    ...awayTeam.shots.map(shot => shot.time),
  );
  const series: ApexAxisChartSeries = [
    {
      name: metricName,
      data: dataHome,
      color: COLORS.purple[80],
    },
    {
      name: metricName,
      data: dataAway,
      color: COLORS.orange[60],
    },
  ];

  return {
    series,
    yMaxValue,
    maxTime,
    teamGoalsCoordinates: {
      homeTeam: {
        teamId: homeTeam.teamId,
        coordinates: homeGoals,
      },
      awayTeam: {
        teamId: awayTeam.teamId,
        coordinates: awayGoals,
      },
    },
  };
};

/**
 * Divides shots by time into 20 minute periods.
 * @param shots Array of all shots.
 * @returns Array of periods with shots arrays.
 */
export const divideShotsByTime = (shots: IShot[]) => {
  const dividedShots: IShot[][] = [];

  shots.forEach(shot => {
    const time = shot.time;
    const index = Math.floor((time - 1) / 1200);

    if (!dividedShots[index]) {
      dividedShots[index] = [];
    }

    dividedShots[index].push(shot);
  });

  return dividedShots;
};

const getPeriodTotalXg = (shots: IShot[]) => {
  const totalXg = shots.reduce<number>((acc, shot) => acc + shot.xG, 0);
  return Number(totalXg.toFixed(2));
};

/**
 * Gets total metric values for each period for both teams.
 * @param homeTeamShots Shots of the home team.
 * @param awayTeamShots Shots of the away team.
 * @returns Array of total metric values for each period for both teams.
 */
export const getTeamsMetricValues = (homeTeamShots: IShot[], awayTeamShots: IShot[]) => {
  const homeTeamPeriodsShots = divideShotsByTime(homeTeamShots);
  const awayTeamPeriodsShots = divideShotsByTime(awayTeamShots);

  const homeTeamPeriodMetricValues = homeTeamPeriodsShots.map(getPeriodTotalXg);
  const awayTeamPeriodMetricValues = awayTeamPeriodsShots.map(getPeriodTotalXg);

  return {
    homeTeamPeriodMetricValues,
    awayTeamPeriodMetricValues,
  };
};

/**
 * Counts max chart ticks from maxTime and tickTimeDistance.
 * @param maxTime Max time in seconds.
 * @param tickTimeDistance Distance between ticks in seconds.
 * @returns Max chart ticks.
 * @example getMaxChartTicks(3600, 300) => 12 // 60 minutes, 5 minutes per tick
 */
export const getMaxChartTicks = (maxTime: number, tickTimeDistance: number = 300) =>
  maxTime / tickTimeDistance - (maxTime % tickTimeDistance) / tickTimeDistance + 1;

export const parseHeadToHeadData = (data: IHeadToHeadDTO): IHeadToHead => {
  const tois: number[] = data.headToHead.reduce<number[]>(
    (acc, item) => acc.concat(item.opponents.map(opponent => opponent.metrics.toi)),
    [],
  );
  const headToHead = data.headToHead.reduce<IHeadToHeadPlayer[]>((acc, headToHead) => {
    const opponents = headToHead.opponents.map<IHeadToHeadOpponent>(opponent => ({
      ...opponent,
      stats: opponent.metrics,
    }));
    acc.push({
      ...headToHead,
      opponents: opponents,
    });
    return acc;
  }, []);

  return {
    ...data,
    headToHead,
    maxToi: Math.max.apply(Math, tois),
    minToi: Math.min.apply(Math, tois),
  };
};

export const isTimeMetric = (type: string) =>
  type === 'oz.toi' || type === 'oz.ptoi' || type === 'toi';

export const getTimeFromGamePart = (
  gamePart: ISelectOption | undefined,
): ITimeFromTo | undefined => {
  if (!gamePart) return undefined;

  switch (gamePart.value) {
    case '1':
      return {
        timeFrom: 0,
        timeTo: 20 * 60,
      };
    case '2':
      return {
        timeFrom: 20 * 60,
        timeTo: 40 * 60,
      };
    case '3':
      return {
        timeFrom: 40 * 60,
        timeTo: 60 * 60,
      };
    case '4':
      return {
        timeFrom: 60 * 60,
        timeTo: undefined,
      };
    default:
      return undefined;
  }
};

export const filteredPlayersOfGameTablesByTeamId = (
  players: IPlayerWithTeamIdAndStats[],
  selectedTeamId?: string,
) => {
  const filteredPlayers =
    !selectedTeamId || selectedTeamId === 'all'
      ? players
      : players.filter(player => player.teamId === selectedTeamId);
  return filteredPlayers;
};

export const filteredFormationsOfGameTablesByTeamId = (
  formations: IGamesTablesFormations[],
  selectedTeamId?: string,
) => {
  const filteredFormations =
    !selectedTeamId || selectedTeamId === 'all'
      ? formations
      : formations.filter(formation => formation.teamId === selectedTeamId);
  return filteredFormations;
};

export const createTeamSortingOptions = (
  teams: ITeamRecord,
  selectedGame?: IGame | null,
): ISelectOption<any>[] => {
  if (!selectedGame) return [];

  const homeTeam = teams[selectedGame.homeTeamId];
  const awayTeam = teams[selectedGame.awayTeamId];
  return [
    {
      label: ITranslationKeys.allPlayers,
      value: 'all',
    },
    {
      label: homeTeam.name,
      value: homeTeam.id,
    },
    {
      label: awayTeam.name,
      value: awayTeam.id,
    },
  ];
};

export const createGameEntity = <T extends IVideoInfo>(
  selectedGames: ISelectOption[],
  entities: T[],
) => {
  const filteredGameEntities: IGameEntity[] = selectedGames.map(game => {
    const videoInfoEntities = entities
      .filter(shot => shot.matchId === game.value)
      .map<IVideoInfo>(entity => ({
        videoId: entity.videoId,
        videoTime: entity.videoTime,
        matchId: entity.matchId,
        matchDate: entity.matchDate,
        playerId: entity.playerId,
        awayTeamId: entity.awayTeamId,
        homeTeamId: entity.homeTeamId,
        time: entity.time,
        realTime: entity.realTime,
        score: entity.score,
      }));

    return {
      gameId: game.value,
      entities: videoInfoEntities,
    };
  });

  return filteredGameEntities;
};
