import { FC } from 'react';

import { createClassNames } from '../../utils';
import './DisplayField.styles.scss';

export interface IDisplayFieldProps {
  /** Text to display. */
  children?: string;
}

const classNames = createClassNames('display-field');

/** UI component to display text in field. */
export const DisplayField: FC<IDisplayFieldProps> = ({ children }) => (
  <div className={classNames()}>
    <div className={classNames('inner')}>{children}</div>
  </div>
);
