import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredMainFilterDataSelector,
  filteredTeamsSelector,
  getFormations,
  getFormationsTeamOrderStats,
  resetTeamStatsLoadedData,
  selectFormations,
  selectMainFilter,
  setIsTeamOrderActive,
} from '../../features';
import { IEntity, IMainFilterForm, ITeamsFormationRequestBody } from '../../types';
import { useNormalizeMetrics } from '../useNormalizeMetrics';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

export const useFetchTeamsStats = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam } = useAppSelector(selectMainFilter);
  const filteredTeams = useAppSelector(filteredTeamsSelector);
  const { isTeamOrderActive } = useAppSelector(selectFormations);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchTeamStats = (data: Partial<IMainFilterForm>, teamOrder?: boolean) => {
    if (isTeamOrderActive) {
      dispatch(setIsTeamOrderActive(false));
    }

    const competitionsUuids = filteredParts.map(part => part.id);

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: ITeamsFormationRequestBody = {
      ...requestBodyBase,
      metrics: normalizeMetrics(undefined, IEntity.teams),
    };

    if (selectedTeam && selectedTeam.value !== 'all') {
      if (!teamOrder) {
        dispatch(resetTeamStatsLoadedData());
        dispatch(
          getFormations({
            competitionsUuids,
            body: requestBody,
            teamUuid: selectedTeam.value,
            isLast: true,
          }),
        );
      } else {
        dispatch(
          getFormationsTeamOrderStats({
            competitionsUuids,
            body: requestBody,
            teamUuid: selectedTeam.value,
            isLast: true,
          }),
        );
      }
    } else {
      filteredTeams.forEach((team, index, array) => {
        if (!teamOrder) {
          dispatch(
            getFormations({
              competitionsUuids,
              body: requestBody,
              teamUuid: team.id,
              isLast: index === array.length - 1,
            }),
          );
        } else {
          dispatch(
            getFormationsTeamOrderStats({
              competitionsUuids,
              body: requestBody,
              teamUuid: team.id,
              isLast: index === array.length - 1,
            }),
          );
        }
      });
    }
  };

  return fetchTeamStats;
};
