import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ICompetitionsDetailState } from '../../types';
import { getCompetitionDetail } from './competitionsDetailActions';

const initialState: ICompetitionsDetailState = {
  byId: {},
  allIds: [],
  isLoading: false,
  isAllCompetitionsDetailLoaded: false,
  error: undefined,
  isLastSeasonCompetitionsDetailLoaded: false,
};

export const competitionsDetailSlice = createSlice({
  name: 'competitionsDetail',
  initialState,
  reducers: {
    setIsAllCompetitionsDetailLoaded: (state, action: PayloadAction<boolean>) => {
      state.isAllCompetitionsDetailLoaded = action.payload;
    },
    setIsLastSeasonCompetitionsDetailLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLastSeasonCompetitionsDetailLoaded = action.payload;
    },
    resetCompetitionsDetail: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getCompetitionDetail.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getCompetitionDetail.fulfilled, (state, action) => {
        state.byId[action.payload.competitionDetail.id] = action.payload.competitionDetail;
        state.allIds.push(action.payload.competitionDetail.id);
        state.isLoading = false;
      })
      .addCase(getCompetitionDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setIsAllCompetitionsDetailLoaded,
  setIsLastSeasonCompetitionsDetailLoaded,
  resetCompetitionsDetail,
} = competitionsDetailSlice.actions;

export const competitionsDetailReducer = competitionsDetailSlice.reducer;
