import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IIndividualStatsState } from '../../types';
import { getPlayersWithStats, getPlayersWithStatsPercentile } from './individualStatsActions';

const initialState: IIndividualStatsState = {
  byId: {},
  allIds: [],
  isLoading: false,
  error: undefined,
  isLoadedFirstTime: undefined,
  isPercentilesLoading: undefined,
  errorPercentiles: undefined,
  isPercentilesActive: undefined,
};

export const individualStatsSlice = createSlice({
  name: 'individualStats',
  initialState,
  reducers: {
    setIsPercentilesActive: (state, action: PayloadAction<boolean>) => {
      state.isPercentilesActive = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPlayersWithStats.pending, state => {
        state.byId = {};
        state.allIds = [];
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getPlayersWithStats.fulfilled, (state, action) => {
        action.payload.forEach(player => {
          if (!state.byId[player.id]) {
            state.byId[player.id] = {
              id: player.id,
              teamStats: {
                [player.teamId]: {
                  id: player.teamId,
                  stats: player.stats,
                },
              },
            };
            state.allIds.push(player.id);
          } else {
            state.byId[player.id].teamStats[player.teamId] = {
              id: player.teamId,
              stats: player.stats,
            };
          }
        });
        state.isLoading = false;
        state.isLoadedFirstTime = true;
      })
      .addCase(getPlayersWithStats.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getPlayersWithStatsPercentile.pending, state => {
        state.isPercentilesLoading = true;
        state.errorPercentiles = undefined;
      })
      .addCase(getPlayersWithStatsPercentile.fulfilled, (state, action) => {
        action.payload.forEach(player => {
          state.byId[player.id].teamStats[player.teamId] = {
            id: player.teamId,
            stats: state.byId[player.id].teamStats[player.teamId].stats,
            percentiles: player.stats,
          };
        });
        state.isPercentilesLoading = false;
      })
      .addCase(getPlayersWithStatsPercentile.rejected, (state, action) => {
        state.isPercentilesLoading = false;
        state.errorPercentiles = action.error.message;
      });
  },
});

export const { setIsPercentilesActive } = individualStatsSlice.actions;

export const individualStatsReducer = individualStatsSlice.reducer;
