import { FC } from 'react';

import { IGoalStatsBoxWrapperProps } from '../../../../types';
import { createClassNames } from '../../../../utils';
import { GoalStatsBoxWrapper } from '../../../goalStatsBoxComponents';
import './GoalNetShootoutContent.styles.scss';

export interface IGoalNetShootoutContentProps {
  /**
   * Boxes to render.
   */
  boxes: IGoalStatsBoxWrapperProps[];
}

const classNames = createClassNames('goal-net-shootout-content');

export const GoalNetShootoutContent: FC<IGoalNetShootoutContentProps> = ({ boxes }) => {
  return (
    <div className={classNames()} data-testid='goal-net-shootout-content'>
      {boxes.map((box, index) => (
        <div className={classNames('box-wrapper-' + index)} key={index}>
          <GoalStatsBoxWrapper {...box} />
        </div>
      ))}
    </div>
  );
};
