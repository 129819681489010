import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MetricButton } from '..';
import { useMultiselect } from '../../../hooks';
import { ITranslationKeys } from '../../../i18n/types';
import { IDatasetMetric, IMetricRow, IUseMultiselectConfig } from '../../../types';
import { createClassNames } from '../../../utils';
import { GridLayout } from '../../GridLayout';
import './MetricsSectionRow.styles.scss';

export interface IMetricsSectionRowProps extends IUseMultiselectConfig<IDatasetMetric> {
  /** Metric row */
  row: IMetricRow;
  /** Metric row name */
  rowName?: ITranslationKeys;
}

const classNames = createClassNames('metrics-section-row');

export const MetricsSectionRow: FC<IMetricsSectionRowProps> = ({
  row,
  rowName,
  options,
  selected,
  onChange,
  hasSelectAllOption,
}) => {
  const { t } = useTranslation();
  const { onChangeToggle } = useMultiselect<IDatasetMetric>({
    options,
    selected,
    onChange,
    hasSelectAllOption,
  });

  return (
    <div className={classNames()} data-testid='metrics-section-row'>
      {rowName && <div className={classNames('header')}>{t(rowName)}</div>}
      <GridLayout columns={5} gap={'3.2rem'}>
        {Object.values(row.columns).map(column => {
          return (
            <div key={row.name + column.name} className={classNames('metrics')}>
              {column.metrics.map(metric => {
                const option = options.find(option => option.additionalInfo.origin === metric.id);
                if (!option) return null;

                const { color, eng, description } = metric.metricInfo;
                const isSelected = selected.some(item => item.value === option.value);

                return (
                  <MetricButton
                    key={option.value}
                    label={option.label}
                    color={color === 'white' ? 'black' : color}
                    isActive={isSelected}
                    metricTooltipProps={{
                      header: eng,
                      description,
                    }}
                    onClick={() => onChangeToggle(option)}
                  />
                );
              })}
            </div>
          );
        })}
      </GridLayout>
    </div>
  );
};
