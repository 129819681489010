import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IReturnTypeGetSeasonsAction } from '../../types';
import { defaultCatchErrorCallback } from '../../utils';
import { parseSeasonsData } from './parseSeasons.utils';

export const getSeasons = createAsyncThunk<IReturnTypeGetSeasonsAction>(
  'seasons/getSeasons',
  async () => {
    const seasonsData: IReturnTypeGetSeasonsAction = await axiosInstance
      .get('/competition')
      .then(response => parseSeasonsData(response.data))
      .catch(defaultCatchErrorCallback);

    return seasonsData;
  },
);
