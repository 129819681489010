import { FC } from 'react';

import {
  IDatasetMetric,
  IMetricSectionName,
  IMetricsSectionsToDisplayRecord,
  ISectionNames,
  IUseMultiselectConfig,
} from '../../../types';
import { createClassNames } from '../../../utils';
import { MetricsSection } from '../../metricComponents';
import './DataSettingsSections.styles.scss';

export interface IDataSettingsSectionsProps
  extends IUseMultiselectConfig<IDatasetMetric>,
    ISectionNames {
  /** Filtered metrics to display */
  filteredMetricsSections: IMetricsSectionsToDisplayRecord;
}

const classNames = createClassNames('data-settings-sections');

export const DataSettingsSections: FC<IDataSettingsSectionsProps> = ({
  filteredMetricsSections,
  options,
  selected,
  onChange,
  hasSelectAllOption,
  individualName,
  onIceName,
}) => {
  if (!filteredMetricsSections) {
    return null;
  }

  const individualSection = filteredMetricsSections[IMetricSectionName.individual] ?? undefined;
  const onIceSection = filteredMetricsSections[IMetricSectionName.onIce] ?? undefined;

  return (
    <div className={classNames()}>
      {individualSection && (
        <MetricsSection
          section={individualSection}
          header={individualName}
          {...{ options, selected, hasSelectAllOption, onChange }}
        />
      )}
      {onIceSection && (
        <MetricsSection
          section={onIceSection}
          header={onIceName}
          {...{ options, selected, hasSelectAllOption, onChange }}
        />
      )}
    </div>
  );
};
