import { IChangelog, IDefinedDatasets, IHelp, IMetrics, IReportMetrics } from '../types';
import changelogFile from './changelog.json';
import definedDatasetsFile from './definedDatasets.json';
import helpFile from './help.json';
import metricsFile from './metrics.json';
import reportMetricsFile from './reportMetrics.json';

export const changelog = changelogFile as IChangelog;
export const definedDatasets = definedDatasetsFile as IDefinedDatasets;
export const helpJson = helpFile as IHelp;
export const allMetrics = metricsFile as IMetrics;
export const reportMetrics = reportMetricsFile as IReportMetrics;
