import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { FieldErrors } from 'react-hook-form';

import { useAppDispatch } from '../../../app/hooks';
import { triggerContentReload } from '../../../features';
import { IMainFilterForm, IPageConfig } from '../../../types';
import { createClassNames, isFormValid, transformObjectKeysToKebabCase } from '../../../utils';
import { TabItemList } from '../../TabItemList';
import { FilterButtons } from '../FilterButtons';
import { FilterExtendedPart } from '../FilterExtendedPart';
import './FilterWrapper.styles.scss';

export interface IFilterWrapperProps {
  /** Config with information about page */
  pageConfig: IPageConfig;
  /** Errors field from main form state. */
  errors: FieldErrors<IMainFilterForm>;
  /** Is filter extended? Means that it is not at widget part of page. */
  isExtended?: boolean;
  /** Is select opponent field disabled at extended part of filter? */
  disableSelectOpponentAtExtended?: boolean;
  /** Filter needs active tab name if its extended. */
  activeTab?: string;
  /** Additional filter element (for example GamesList for select games). */
  additionalFilterElement?: ReactNode;
}

const classNames = createClassNames('filter-wrapper');

/**
 * HOC that handles switching between base and extended filter at every page.
 * Adds also styles for filters and tabs list at extended mode.
 */
export const FilterWrapper: FC<IFilterWrapperProps> = ({
  pageConfig,
  errors,
  isExtended,
  disableSelectOpponentAtExtended,
  activeTab,
  additionalFilterElement,
}) => {
  const dispatch = useAppDispatch();
  const [openMore, setOpenMore] = useState(false);

  const activeTabConfig = useMemo(
    () => pageConfig.tabsConfig.find(tab => tab.id === activeTab),
    [pageConfig, activeTab],
  );

  useEffect(() => {
    if (openMore && (activeTabConfig?.disableExtendedFilter || !activeTab)) {
      setOpenMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div className={classNames()}>
      <div className={classNames('container')}>
        <div
          className={classNames('container__filter-box', {
            ...transformObjectKeysToKebabCase({
              isExtended,
            }),
          })}
        >
          <div className={classNames('container__filter-box__filter-parts')}>
            <div
              className={classNames('container__filter-box__filter-parts__base', {
                ...transformObjectKeysToKebabCase({
                  isExtended,
                }),
              })}
            >
              {isExtended ? activeTabConfig?.contentFilter : pageConfig.baseFilter}
            </div>
            {openMore && (
              <FilterExtendedPart disableSelectOpponent={disableSelectOpponentAtExtended} />
            )}
          </div>
          {isExtended && (
            <FilterButtons
              disableMoreFiltersButton={activeTabConfig?.disableExtendedFilter}
              disableLoadDataButton={!isFormValid(errors)}
              onClickLoadData={() => dispatch(triggerContentReload())}
              {...{ openMore, setOpenMore }}
            />
          )}
        </div>
        {additionalFilterElement && (
          <div
            className={classNames('container__additional-filter-element', {
              ...transformObjectKeysToKebabCase({
                isExtended,
              }),
            })}
          >
            {additionalFilterElement}
          </div>
        )}
        {isExtended && pageConfig && (
          <div className={classNames('container__tabs-list')}>
            <TabItemList config={pageConfig.tabsConfig} />
          </div>
        )}
      </div>
    </div>
  );
};
