import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  IDataSettingsFilterState,
  IDatasetMetric,
  INetZoneUnion,
  ISelectOption,
} from '../../types';
import { DEFAULT_DATA_SETTINGS_METRIC_PARAMS_STATE } from './constants';
import {
  handleLastTwoMetricParamActions,
  handleLastTwoMetricParamActionsNetZone,
} from './sliceUtils';

const initialState: IDataSettingsFilterState = {
  open: false,
  selectedMetrics: [],
  dataSettingsDataTemplate: undefined,
  selectedGroup: undefined,
  lastTwoMetricParamActions: [],
  ...DEFAULT_DATA_SETTINGS_METRIC_PARAMS_STATE,
};

export const dataSettingsFilterSlice = createSlice({
  name: 'dataSettingsFilter',
  initialState,
  reducers: {
    setOpenDataSettings: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setDataSettingsSelectedMetrics: (
      state,
      action: PayloadAction<ISelectOption<IDatasetMetric>[]>,
    ) => {
      state.selectedMetrics = action.payload;
    },
    setDataSettingsDataTemplate: (state, action: PayloadAction<ISelectOption | undefined>) => {
      state.dataSettingsDataTemplate = action.payload;
    },
    setDataSettingsSelectedGroup: (state, action: PayloadAction<ISelectOption>) => {
      state.selectedGroup = action.payload;
    },
    setDataSettingsShotLocation: (state, action: PayloadAction<ISelectOption>) => {
      handleLastTwoMetricParamActions(state, action);
      state.shotLocation = action.payload;
    },
    setDataSettingsShotType: (state, action: PayloadAction<ISelectOption>) => {
      handleLastTwoMetricParamActions(state, action);
      state.shotType = action.payload;
    },
    setDataSettingsShotDanger: (state, action: PayloadAction<ISelectOption>) => {
      handleLastTwoMetricParamActions(state, action);
      state.shotDanger = action.payload;
    },
    setDataSettingsGameActionType: (state, action: PayloadAction<ISelectOption>) => {
      handleLastTwoMetricParamActions(state, action);
      state.gameActionType = action.payload;
    },
    setDataSettingsNetZone: (state, action: PayloadAction<INetZoneUnion | undefined>) => {
      handleLastTwoMetricParamActionsNetZone(state, action);
      state.netZone = action.payload;
    },
    setDataSettingsPuckGainType: (state, action: PayloadAction<ISelectOption>) => {
      handleLastTwoMetricParamActions(state, action);
      state.puckGainType = action.payload;
    },
    setDataSettingsEntryType: (state, action: PayloadAction<ISelectOption>) => {
      handleLastTwoMetricParamActions(state, action);
      state.entryType = action.payload;
    },
    setDataSettingsExitType: (state, action: PayloadAction<ISelectOption>) => {
      handleLastTwoMetricParamActions(state, action);
      state.exitType = action.payload;
    },
    setDataSettingsEnterExitLocation: (state, action: PayloadAction<ISelectOption>) => {
      handleLastTwoMetricParamActions(state, action);
      state.enterExitLocation = action.payload;
    },
    resetDataSettingsFilterState: () => initialState,
  },
});

export const {
  setOpenDataSettings,
  setDataSettingsSelectedMetrics,
  setDataSettingsDataTemplate,
  setDataSettingsSelectedGroup,
  setDataSettingsShotLocation,
  setDataSettingsShotType,
  setDataSettingsShotDanger,
  setDataSettingsGameActionType,
  setDataSettingsNetZone,
  setDataSettingsPuckGainType,
  setDataSettingsEntryType,
  setDataSettingsExitType,
  setDataSettingsEnterExitLocation,
  resetDataSettingsFilterState,
} = dataSettingsFilterSlice.actions;

export const dataSettingsFilterReducer = dataSettingsFilterSlice.reducer;
