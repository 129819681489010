import { FC, useState } from 'react';

import { useAppSelector } from '../../../app/hooks';
import { selectPlayers } from '../../../features';
import {
  IH2HCategory,
  IHeadToHead,
  IHeadToHeadTeamsDividerProps,
  ISimilarPlayerNameRecord,
} from '../../../types';
import { createClassNames, getPlayerShortName } from '../../../utils';
import { HeadToHeadPlayer } from '../HeadToHeadPlayer';
import { HeadToHeadTeamsDivider } from '../HeadToHeadTeamsDivider';
import { PieChart } from '../PieChart';
import './HeadToHead.styles.scss';

export interface IHeadToHeadProps extends IHeadToHeadTeamsDividerProps {
  /** Category of shots. */
  category: IH2HCategory;
  /** HeadToHead object. */
  headToHead: IHeadToHead;
  /** Record of players with the same surname and similar name. */
  similarPlayerNamesRecord: ISimilarPlayerNameRecord;
}

const classNames = createClassNames('head-to-head-graph');

/**
 * HeadToHead UI component.
 */
export const HeadToHead: FC<IHeadToHeadProps> = ({ category, headToHead, homeTeam, awayTeam }) => {
  const { byId } = useAppSelector(selectPlayers);
  const { opponents, players } = headToHead;
  const [crossCoors, setCrossCoors] = useState<number[]>([-1, -1]);

  const handlePieChartEnter = (rowI: number, colI: number) => {
    setCrossCoors([rowI, colI]);
  };

  const handlePieChartLeave = (rowI: number, colI: number) => {
    if (crossCoors[0] === rowI && crossCoors[1] === colI) setCrossCoors([-1, -1]);
  };

  return (
    <div className={classNames()} data-testid='head-to-head-graph'>
      <div className={classNames('row')}>
        <HeadToHeadTeamsDivider homeTeam={homeTeam} awayTeam={awayTeam} />
        {opponents.backwards.concat(opponents.forwards).map((opponent, index) => {
          const playerObject = byId[opponent];
          const displayName = getPlayerShortName(playerObject);

          return (
            <HeadToHeadPlayer
              key={opponent + index}
              displayName={displayName}
              position={playerObject.position}
              team='away'
              border={index === opponents.backwards.length - 1 ? 'right' : undefined}
              crossColorActive={crossCoors[1] === index}
              reversed={true}
            />
          );
        })}
      </div>
      {players.backwards.concat(players.forwards).map((player, index) => {
        const playerObject = byId[player];
        const displayName = getPlayerShortName(playerObject);

        return (
          <div className={classNames('row')} key={player + index}>
            <HeadToHeadPlayer
              displayName={displayName}
              position={playerObject.position}
              team='home'
              border={index === players.backwards.length - 1 ? 'bottom' : undefined}
              crossColorActive={crossCoors[0] === index}
            />

            {opponents.backwards.concat(opponents.forwards).map((opponent, column_i) => (
              <PieChart
                key={player + opponent + column_i}
                borderRight={column_i === opponents.backwards.length - 1}
                borderBottom={index === players.backwards.length - 1}
                category={category}
                headToHead={headToHead}
                opponentUuid={opponent}
                playerUuid={player}
                coordsX={column_i}
                coordsY={index}
                crossX={crossCoors[1]}
                crossY={crossCoors[0]}
                playersLength={players.backwards.length + players.forwards.length}
                opponentsLength={opponents.backwards.length + opponents.forwards.length}
                onMouseEnter={() => handlePieChartEnter(index, column_i)}
                onMouseLeave={() => handlePieChartLeave(index, column_i)}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};
