import {
  IAccessLog,
  IAccessLogFilterForm,
  IEventScreenLog,
  IEventScreenLogFilterForm,
  IIdentity,
  IRole,
  ISelectOption,
  ITeamRecord,
  ITrackingUserFilterForm,
} from '../../types';

const filterAccessLogOrEventLogByTeamOrMedia = (
  accessLogData: (IAccessLog | IEventScreenLog)[],
  teams: ITeamRecord,
  teamOrMedia?: ISelectOption[],
  role?: ISelectOption,
) => {
  if (!role || !teamOrMedia || teamOrMedia.some(item => item.value === 'all')) {
    return accessLogData;
  }

  if (role.value === IRole.media) {
    return accessLogData.filter(data => teamOrMedia.some(item => item.value === data.media));
  }

  return accessLogData.filter(data =>
    teamOrMedia.some(item => {
      const team = Object.values(teams).find(team => team.shortcut === item.value);
      return data.team === team?.id;
    }),
  );
};

export const filterAccessLogDataByFilterForm = (
  accessLogData: IAccessLog[] | undefined,
  filterValues: IAccessLogFilterForm,
  teamRecord: ITeamRecord,
) => {
  if (!accessLogData) return [];

  const { selectedRole, selectedTeamOrMedia } = filterValues;
  const filteredAccessLogByTeam = filterAccessLogOrEventLogByTeamOrMedia(
    accessLogData,
    teamRecord,
    selectedTeamOrMedia,
    selectedRole,
  );

  return accessLogData.filter(item => filteredAccessLogByTeam.includes(item));
};

export const filterEventScreenLogDataByFilterForm = (
  screenLogData: IEventScreenLog[] | undefined,
  filterValues: IEventScreenLogFilterForm,
  teamRecord: ITeamRecord,
) => {
  if (!screenLogData) return [];

  const { selectedRole, selectedTeamOrMedia } = filterValues;
  const filteredEventLogByTeam = filterAccessLogOrEventLogByTeamOrMedia(
    screenLogData,
    teamRecord,
    selectedTeamOrMedia,
    selectedRole,
  );

  return screenLogData.filter(item => filteredEventLogByTeam.includes(item));
};

const filterUserLogByRole = (identities: IIdentity[], roles: ISelectOption[]) => {
  if (roles.some(o => o.value === 'all')) {
    return identities;
  }

  return identities.filter(identity => roles.some(o => o.value === identity.role));
};

const filterUserLogByUser = (identities: IIdentity[], username: string) => {
  if (!username || !username.trim()) {
    return identities;
  }

  const usernameLowerCase = username.toLowerCase();
  return identities.filter(identity => identity.username.toLowerCase().includes(usernameLowerCase));
};

const filterUserLogByTeamOrMedia = (
  identities: IIdentity[],
  selectedTeamOrMedia: ISelectOption[],
) => {
  if (selectedTeamOrMedia.length === 0 || selectedTeamOrMedia.some(o => o.value === 'all'))
    return identities;

  return identities.filter(identity =>
    selectedTeamOrMedia.some(o => o.value === identity.teamId || o.value === identity.media),
  );
};

export const filterUserLogDataByFilterForm = (
  identities: IIdentity[],
  filterValues: ITrackingUserFilterForm,
) => {
  const { selectedRoles, selectedTeamOrMedia, userFilterText } = filterValues;
  const filteredIdentitiesLogByRole = filterUserLogByRole(identities, selectedRoles);
  const filteredIdentitiesLogByUser = filterUserLogByUser(identities, userFilterText);
  const filteredIdentitiesLogByTeamOrMedia = filterUserLogByTeamOrMedia(
    identities,
    selectedTeamOrMedia,
  );

  return identities.filter(
    item =>
      filteredIdentitiesLogByRole.includes(item) &&
      filteredIdentitiesLogByUser.includes(item) &&
      filteredIdentitiesLogByTeamOrMedia.includes(item),
  );
};
