import { createSlice } from '@reduxjs/toolkit';

import { IDefinedDatasetsState } from '../../../types';
import { getDefinedDatasets, putDefinedDatasets } from './definedDatasetsActions';

const initialState: IDefinedDatasetsState = {
  definedDatasets: undefined,
  isLoading: false,
  isDefinedDatasetsLoaded: false,
  error: undefined,
};

export const definedDatasetsSlice = createSlice({
  name: 'settings/definedDatasets',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDefinedDatasets.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getDefinedDatasets.fulfilled, (state, action) => {
        state.definedDatasets = action.payload;
        state.isLoading = false;
        state.isDefinedDatasetsLoaded = true;
      })
      .addCase(getDefinedDatasets.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(putDefinedDatasets.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(putDefinedDatasets.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.definedDatasets = action.payload;
        }
        state.isLoading = false;
      })
      .addCase(putDefinedDatasets.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
