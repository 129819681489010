import { HEATMAP_COLORS } from '../../constants';
import { createClassNames } from '../../utils';
import './ColorHeatmapBar.styles.scss';

const classNames = createClassNames('color-heatmap-bar');

/**
 * ColorHeatmapBar UI component.
 */
export const ColorHeatmapBar = () => {
  return (
    <div className={classNames()}>
      {HEATMAP_COLORS.map((color, index) => (
        <div key={index} className={classNames('bar', { color })}></div>
      ))}
    </div>
  );
};
