import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, To } from 'react-router-dom';

import { ITranslationKeys } from '../../../i18n/types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './NavigationItem.styles.scss';

export interface INavigationItemProps {
  /** Item label. */
  label: ITranslationKeys | string;
  /** Link to other page. */
  to: To;
  /** Is the item active? */
  isActive?: boolean;
  /** Icon to be displayed. */
  iconComponent?: ReactElement;
}

const classNames = createClassNames('navigation-item');

export const NavigationItem: FC<INavigationItemProps> = ({
  label,
  to,
  isActive,
  iconComponent,
}) => {
  const { t } = useTranslation();

  return (
    <Link
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isActive,
        }),
      })}
      data-testid={label}
      data-text={t(label)}
      {...{ to }}
    >
      {iconComponent}
      {t(label)}
    </Link>
  );
};
