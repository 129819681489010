import { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { TranslationFunctionOptions } from '../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import { ICommonButtonProps } from '../types';
import './Button.styles.scss';

export interface IButtonProps extends ICommonButtonProps {
  /** Does button have a primary style? */
  primary?: boolean;
  /** What variant of the button should it be? */
  variant?: 'normal' | 'normal-underlined' | 'gray' | 'red';
  /** Button text weight. */
  fontWeight?: CSSProperties['fontWeight'];
  /** Is button border radius smaller than default? */
  isBorderRadiusSmall?: boolean;
}

const classNames = createClassNames('button');

/**
 * Button UI component for user interaction.
 */
export const Button: FC<IButtonProps> = ({
  primary = false,
  size = 'medium',
  type = 'button',
  variant = 'normal',
  disabled,
  iconComponent,
  iconPosition = 'right',
  onClick,
  label,
  fontWeight,
  isBorderRadiusSmall,
  labelTranslationsOptions,
  ...props
}) => {
  const { t } = useTranslation();

  const renderIconWithLabel = (
    label: ITranslationKeys | string,
    labelTranslationsOptions?: TranslationFunctionOptions,
  ) => {
    if (iconPosition === 'left') {
      return (
        <>
          {iconComponent}
          {t(label, { ...labelTranslationsOptions })}
        </>
      );
    }

    return (
      <>
        {t(label, { ...labelTranslationsOptions })}
        {iconComponent}
      </>
    );
  };

  return (
    <button
      type={type}
      className={classNames({
        ...transformObjectKeysToKebabCase({
          primary,
          secondary: !primary,
          variant,
          size,
          hasIcon: !!iconComponent,
          isBorderRadiusSmall,
        }),
      })}
      onClick={onClick}
      disabled={disabled}
      {...props}
      style={{ fontWeight }}
    >
      {label ? renderIconWithLabel(label, labelTranslationsOptions) : iconComponent}
    </button>
  );
};
