import { FC } from 'react';
import { RangeSlider } from 'rsuite';

import { ITranslationKeys } from '../../../i18n/types';
import { EditIcon, PlayIcon } from '../../../icons';
import { IVideoControls } from '../../../types';
import { createClassNames, secondsToMinutesAndSeconds } from '../../../utils';
import { Button } from '../../Button';
import { VideoCenterInput } from '../VideoCenterInput';
import './RangeSlider.styles.scss';
import './VideoCenterControls.styles.scss';

interface IVideoCenterControls {
  /** Funtion that opens note editor (only if user is videocoach) */
  onVideocoachNote?: () => void;
  /** Funtion that plays video with changed controls. */
  onPlay: () => void;
  /** Values used in inputs */
  controls: IVideoControls;
  /** Function for changing control states */
  setControls: (value: IVideoControls) => void;
}

const classNames = createClassNames('video-center-controls');

/** VideoCenterControls UI component. */
export const VideoCenterControls: FC<IVideoCenterControls> = ({
  onVideocoachNote,
  onPlay,
  controls,
  setControls,
}) => {
  const handleSlider = (value: number[]) => {
    setControls({ ...controls, cutBefore: Math.abs(value[0]), cutAfter: Math.abs(value[1]) });
  };

  const setTime = (value: number) => {
    setControls({ ...controls, videoTime: value });
  };

  const setCutBefore = (value: number) => {
    setControls({ ...controls, cutBefore: value });
  };

  const setCutAfter = (value: number) => {
    setControls({ ...controls, cutAfter: value });
  };

  return (
    <div className={classNames()}>
      <div className={classNames('slider')}>
        <div className={classNames('center')}></div>
        <RangeSlider
          graduated
          min={-30}
          max={30}
          step={5}
          value={[-controls.cutBefore, controls.cutAfter]}
          tooltip={false}
          onChange={handleSlider}
          renderMark={mark => {
            if (mark === 0) {
              return secondsToMinutesAndSeconds(controls.videoTime, undefined, true);
            }
            return `${mark > 0 ? `+${mark}` : mark}s`;
          }}
        />
      </div>
      <div className={classNames('inputs')}>
        <VideoCenterInput
          value={controls.videoTime}
          setValue={setTime}
          type='time'
          title={ITranslationKeys.videoTime}
        />
        <VideoCenterInput
          value={controls.cutBefore}
          setValue={setCutBefore}
          type='negative'
          title={ITranslationKeys.cutBefore}
        />
        <VideoCenterInput
          value={controls.cutAfter}
          setValue={setCutAfter}
          type='positive'
          title={ITranslationKeys.cutAfter}
        />
        <div className={classNames('buttons')}>
          <Button label={ITranslationKeys.play} iconComponent={<PlayIcon />} onClick={onPlay} />
          {onVideocoachNote !== undefined && (
            <Button
              label={ITranslationKeys.videocoachClips}
              iconComponent={<EditIcon />}
              onClick={onVideocoachNote}
            />
          )}
        </div>
      </div>
    </div>
  );
};
