import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './Caption.styles.scss';

export type ICaptionColor = 'black' | 'white' | 'slate';

export interface ICaptionProps {
  /** A translated label to be displayed. */
  label: ITranslationKeys | string;
  /** Is the label required? When its required, the star is displayed. */
  required?: boolean;
  /** Additional label for caption. */
  additionalLabel?: string;
  /** Color of caption text. */
  color?: ICaptionColor;
  /** Variant of caption component. */
  variant?: 'default' | 'tcms';
}

const classNames = createClassNames('caption');

/**
 * Caipton UI component.
 */
export const Caption: FC<ICaptionProps> = ({
  label,
  required = false,
  additionalLabel,
  color = 'black',
  variant = 'default',
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          color,
          variant,
        }),
      })}
    >
      {t(label)}
      {additionalLabel}
      {required ? ' *' : ''}
    </div>
  );
};
