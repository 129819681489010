import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { SortEndHandler } from 'react-sortable-hoc';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IS_USER_DATASET } from '../../../constants';
import {
  selectDataSettingsFilter,
  setCustomMetrics,
  setDataSettingsDataTemplate,
  setDataSettingsSelectedMetrics,
  setDataTemplate,
} from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { ArrowReloadIcon, CrossIcon } from '../../../icons';
import { IMetricCategory, ISelectOption } from '../../../types';
import { createClassNames, moveArrayItem } from '../../../utils';
import { Button } from '../../Button';
import { SelectInput } from '../../SelectInput';
import { MetricSelectionSortableList } from '../../metricComponents';
import './SelectionSidebar.styles.scss';

export interface ISelectionSidebarProps {
  /** Metrics of one category (players, goalkeepers or teams). */
  metrics: IMetricCategory;
  /** Available data templates. */
  dataTemplateOptions: ISelectOption[];
  /** Callback function for close modal. */
  onClose: () => void;
  /** Set save dataset modal state action. */
  setSaveDatasetOpen: Dispatch<SetStateAction<boolean>>;
  /** Set edit dataset modal state action. */
  setEditDatasetOpen: Dispatch<SetStateAction<boolean>>;
  /** Set delete dataset modal state action. */
  setDeleteDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const classNames = createClassNames('selection-sidebar');

export const SelectionSidebar: FC<ISelectionSidebarProps> = ({
  metrics,
  dataTemplateOptions,
  onClose,
  setSaveDatasetOpen,
  setEditDatasetOpen,
  setDeleteDialogOpen,
}) => {
  const { dataSettingsDataTemplate, selectedMetrics } = useAppSelector(selectDataSettingsFilter);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleLoadCustomData = () => {
    dispatch(setCustomMetrics(selectedMetrics));
    dispatch(setDataTemplate(undefined));
    onClose();
  };

  const handleOnSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newArray = [...selectedMetrics];
      moveArrayItem(newArray, oldIndex, newIndex);

      dispatch(setDataSettingsSelectedMetrics(newArray));
    }
  };

  return (
    <div className={classNames()}>
      <div className={classNames('close')}>
        <span>{t(ITranslationKeys.close)}</span>
        <CrossIcon className='data-settings-cross-icon' onClick={onClose} />
      </div>
      <div>
        <div className={classNames('caption')}>{t(ITranslationKeys.dataTemplates)}</div>
        <SelectInput
          onChange={value => dispatch(setDataSettingsDataTemplate(value))}
          selected={dataSettingsDataTemplate}
          options={dataTemplateOptions}
          placeholder={ITranslationKeys.defaultSelectPlaceholder}
          scrollable
        />
      </div>
      <Button
        label={ITranslationKeys.saveDataset}
        primary
        isBorderRadiusSmall
        onClick={() => setSaveDatasetOpen(true)}
      />
      <Button
        label={ITranslationKeys.editDataset}
        primary
        isBorderRadiusSmall
        onClick={() => setEditDatasetOpen(true)}
      />
      <Button
        label={ITranslationKeys.deleteDataset}
        primary
        isBorderRadiusSmall
        onClick={() => setDeleteDialogOpen(true)}
        variant='red'
        disabled={dataSettingsDataTemplate?.additionalValue !== IS_USER_DATASET}
      />
      <MetricSelectionSortableList
        onChange={newValue => dispatch(setDataSettingsSelectedMetrics(newValue))}
        onSortEnd={handleOnSortEnd}
        helperClass='sortable-helper'
        lockAxis='y'
        {...{ metrics, selectedMetrics }}
      />
      <Button
        label={ITranslationKeys.loadData}
        primary
        isBorderRadiusSmall
        iconComponent={<ArrowReloadIcon />}
        onClick={handleLoadCustomData}
      />
    </div>
  );
};
