import { FC, useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';

import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { DragHandle } from '../../DragHandle';
import { IMetricTooltipProps, MetricTooltip } from '../MetricTooltip';
import './MetricButton.styles.scss';

export interface IMetricButtonProps {
  /**
   * Already translated label to be displayed.
   */
  label: string;
  /**
   * Is the button active?
   */
  isActive?: boolean;
  /**
   * What color of the button should it be?
   * @default "gray"
   */
  color?: 'gray' | 'green' | 'red' | 'black';
  /**
   * Should the button be disabled?
   */
  disabled?: boolean;
  /**
   * Should be displayed the MetricTooltip component after the hover?
   */
  metricTooltipProps?: IMetricTooltipProps;
  /**
   * Button has drag handle element.
   */
  hasDragHandle?: boolean;
  /**
   * Optional click handler
   */
  onClick?: () => void;
}

const classNames = createClassNames('metric-button');

/**
 * MetricButton UI component for user interaction.
 */
export const MetricButton: FC<IMetricButtonProps> = ({
  isActive = false,
  color = 'gray',
  disabled,
  label,
  metricTooltipProps,
  hasDragHandle = false,
  onClick,
}) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <div
      className={classNames({ ...transformObjectKeysToKebabCase({ isActive, color }) })}
      data-testid='metric-button'
    >
      {hasDragHandle && <DragHandle disabled={disabled} color={isActive ? 'white' : 'black'} />}
      <button
        className={classNames('button', { ...transformObjectKeysToKebabCase({ isActive }) })}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        data-testid='metric-button__button'
        {...{ onClick, disabled }}
      >
        {label}
        {metricTooltipProps && <MetricTooltip isShown={isShown} {...metricTooltipProps} />}
      </button>
    </div>
  );
};

export const MetricButtonSortableItem = SortableElement<IMetricButtonProps>(
  (props: IMetricButtonProps) => <MetricButton {...props} hasDragHandle />,
);
