import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ChartSummary, ScreenBarChart, Skeleton } from '../../../../../components';
import { ITranslationKeys } from '../../../../../i18n/types';
import { IMainTrackingFilterForm, IScreenBarChartData } from '../../../../../types';
import { createClassNames } from '../../../../../utils';
import './ScreenChartBox.styles.scss';

interface IScreenChartBoxProps {
  /** Data to display. */
  data: IScreenBarChartData[];
  /** Error */
  error: unknown;
  /** Is data fetching? */
  isFetching: boolean;
  /** Submitted filter values */
  submitValues: IMainTrackingFilterForm;
  /** Header of the box. */
  header: number | string;
  /** Selected screen */
  selectedScreen: ITranslationKeys | string | undefined;
  /** Set selected screen callback */
  setSelectedScreen: Dispatch<SetStateAction<string | undefined>>;
  /** If true, component has buttons instead of labels. */
  hasButtons?: boolean;
}

const classNames = createClassNames('screen-chart-box');

/** Component displays chart with header info. */
export const ScreenChartBox: FC<IScreenChartBoxProps> = ({
  data,
  error,
  isFetching,
  submitValues,
  header,
  selectedScreen,
  setSelectedScreen,
  hasButtons = false,
}) => {
  const { selectedAnalytics, dateFromTo } = submitValues;

  const { t } = useTranslation();

  const handleSelectScreen = (screen: ITranslationKeys | string) => {
    setSelectedScreen(screen);
  };

  if (isFetching) {
    return (
      <div className={classNames()}>
        <Skeleton width={630} height={42} borderRadius={8} />
        <Skeleton width={630} height={322} borderRadius={8} />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={classNames({ error: true })}>{t(ITranslationKeys.defaultErrorMessage)}</div>
    );
  }

  return (
    <div className={classNames()}>
      <ChartSummary header={header} isScreenLayout {...{ dateFromTo, selectedAnalytics }} />
      <div className={classNames('chart')}>
        {data.length > 0 ? (
          <ScreenBarChart
            data={data}
            selectedCategory={selectedScreen}
            onClick={item => handleSelectScreen(item.label)}
            hasButtons={hasButtons}
          />
        ) : (
          <div className={classNames('no-data')}>{t(ITranslationKeys.noDataForSelected)}</div>
        )}
      </div>
    </div>
  );
};
