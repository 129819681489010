import { createSlice } from '@reduxjs/toolkit';

import { IReportMetricsState } from '../../../types';
import { getReportMetrics, putReportMetrics } from './reportMetricsActions';

const initialState: IReportMetricsState = {
  reportMetrics: undefined,
  isLoading: false,
  isReportMetricsLoaded: false,
  error: undefined,
};

export const reportMetricsSlice = createSlice({
  name: 'settings/reportMetrics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getReportMetrics.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getReportMetrics.fulfilled, (state, action) => {
        state.reportMetrics = action.payload;
        state.isLoading = false;
        state.isReportMetricsLoaded = true;
      })
      .addCase(getReportMetrics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(putReportMetrics.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(putReportMetrics.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.reportMetrics = action.payload;
        }
        state.isLoading = false;
      })
      .addCase(putReportMetrics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
