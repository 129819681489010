import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { selectFormations, selectTeams } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IEntity,
  IFormationStatsTableData,
  IMetricWithDatasetMetric,
  IMetricWithValueRecord,
  ITeamRecord,
  ITeamStats,
  ITeamStatsRecord,
} from '../../../../types';
import { getMetricsWithValue } from '../../../../utils';

const getMetricsPercentilesWithValue = (
  metrics: IMetricWithDatasetMetric[],
  team: ITeamStats,
): IMetricWithValueRecord => {
  return metrics.reduce<IMetricWithValueRecord>((acc, metric) => {
    if (team.teamOrder) {
      acc[metric.id] = {
        ...metric,
        value: team.teamOrder[metric.id as keyof typeof team.teamOrder],
      };
    }

    return acc;
  }, {});
};

const getTableData = (
  teamsStatsRecord: ITeamStatsRecord,
  teamsRecord: ITeamRecord,
  metrics: IMetricWithDatasetMetric[],
) => {
  const tableData = Object.values(teamsStatsRecord)
    .reduce<IFormationStatsTableData[]>((acc, teamsWithStats) => {
      const team = teamsRecord[teamsWithStats.teamId];
      const teamStats = teamsStatsRecord[team.id];

      const metricsPercentilesWithValue = getMetricsPercentilesWithValue(metrics, teamStats);
      const metricsWithValue = getMetricsWithValue(metrics, teamsWithStats.stats);
      const teamOrder = teamsStatsRecord[team.id].teamOrder;

      if (team) {
        acc.push({
          entityId: team.id,
          teamId: team.id,
          teamName: team.name,
          gp: teamsWithStats.stats.gp,
          toi: teamsWithStats.stats.toi,
          stats: metricsWithValue,
          percentiles: metricsPercentilesWithValue,
          summaryPercentile: teamOrder ? teamOrder['summaryIndex'] : undefined,
        });
      }

      return acc;
    }, [])
    .sort((a, b) => b.toi - a.toi);

  return tableData;
};

export const useDataForTable = () => {
  const formations = useAppSelector(selectFormations);
  const teams = useAppSelector(selectTeams);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const data: IFormationStatsTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(IEntity.teams);

    if (filteredMetrics) {
      return getTableData(formations.byId, teams.byId, filteredMetrics);
    }

    return [];
  }, [formations.byId, teams.byId, getFilteredMetrics]);

  return data;
};
