import { FC } from 'react';

import { useAppSelector } from '../../../../../../app/hooks';
import { Caption, GoalStatsBoxWrapper } from '../../../../../../components';
import { selectFaceoffsFilter, selectMainFilter, selectTeams } from '../../../../../../features';
import { ITranslationKeys } from '../../../../../../i18n/types';
import {
  IFaceoffAllSides,
  IFaceoffComponentsParams,
  ISelectedPlayersRecord,
} from '../../../../../../types';
import { createClassNames, getFaceoffsAttributes } from '../../../../../../utils';
import './FaceoffsStatBoxCategory.styles.scss';

export interface IFaceoffsStatBoxCategory extends IFaceoffComponentsParams {
  /** A translated label to be displayed. */
  label: ITranslationKeys;
  /** Selected side in filter */
  side: IFaceoffAllSides;
  /** Selected player items above the playground */
  selectedPlayerItems: ISelectedPlayersRecord;
}

const classNames = createClassNames('faceoffs-stat-box-category');

export const FaceoffsStatBoxCategory: FC<IFaceoffsStatBoxCategory> = ({
  label,
  side,
  selectedPlayerItems,
  faceoffPlayerInfoByPositionRecord,
  onBoxClick,
}) => {
  const { selectedTeam } = useAppSelector(selectMainFilter);
  const teams = useAppSelector(selectTeams);
  const { faceoffResult } = useAppSelector(selectFaceoffsFilter);

  return (
    <div className={classNames()}>
      <Caption label={label} />
      <div className={classNames('wrapper')}>
        {Object.values(faceoffPlayerInfoByPositionRecord).map(
          (faceoffPlayerInfoByPosition, index) => {
            const { faceoffsWithAttributes, label, wrapperColor } = getFaceoffsAttributes(
              faceoffPlayerInfoByPosition,
              side,
              faceoffResult.value,
              selectedPlayerItems,
              teams.byId,
              selectedTeam,
            );

            const { attributes, faceoffs } = faceoffsWithAttributes;

            return (
              <GoalStatsBoxWrapper
                key={index}
                label={label}
                goalStatsBoxItems={[
                  {
                    items: [{ value: attributes.ratio }, { value: attributes.percentile }],
                    color: attributes.color,
                    onClick: () => onBoxClick(faceoffs),
                    variant: 'dark',
                    fontWeight: 'semiBold',
                  },
                ]}
                color={wrapperColor}
                disableBoxShadow={true}
                showHeader={false}
              />
            );
          },
        )}
      </div>
    </div>
  );
};
