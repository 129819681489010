import { createSelector } from '@reduxjs/toolkit';

import {
  IFormationsPlayersStatsState,
  IPlayer,
  ISelectOption,
  IShootout,
  ISimilarPlayerNameRecord,
  ITeam,
} from '../../types';
import {
  countShootoutPercentage,
  filterShootoutByPlayerId,
  filterShootoutsByGateZone,
  getPlayerShortName,
} from '../../utils';
import {
  filteredPartsSelector,
  filteredPlayersSelector,
  filteredTeamsSelector,
} from '../mainFilter';
import { selectFormations, selectMainFilter, selectPlayers } from '../selectors';
import { filteredShootoutFilterDataSelector } from '../shootout';

const parsePlayerToOptionWithTeamInLabel = (
  filteredTeams: ITeam[],
  player: IPlayer,
  playerTeamId: string,
): ISelectOption => {
  const playerTeam = filteredTeams.find(team => team.id === playerTeamId);
  const teamShortcut = playerTeam ? playerTeam.shortcut : '';
  const label = [teamShortcut, player.surname, player.name].join(' ');

  return {
    value: player.id,
    label,
    additionalValue: playerTeam?.id,
  };
};

const parsePlayerToOptionWithFaceoffStatsInLabel = (
  player: IPlayer,
  playerStatRecord: IFormationsPlayersStatsState,
  similarPlayerNames: ISimilarPlayerNameRecord,
): ISelectOption => {
  const playerStats = playerStatRecord.byId[player.id];
  const label = getPlayerShortName(player, similarPlayerNames);

  if (!playerStats) {
    return {
      value: player.id,
      label,
      additionalValue: '0',
    };
  }

  const fo = Math.round(playerStats.stats.fo);
  const fowPercent = Math.round(playerStats.stats['fow_percent']);
  const fullStatString = '(' + fo + '; ' + fowPercent + '%)';
  const labelWithStats = [label, fullStatString].join(' ');

  return {
    value: player.id,
    label: labelWithStats,
    additionalValue: fo.toString(),
  };
};

const parsePlayerToOptionWithShootoutStatsInLabel = (
  player: IPlayer,
  shootoutShots: IShootout[],
  similarPlayerNames: ISimilarPlayerNameRecord,
): ISelectOption => {
  const label = getPlayerShortName(player, similarPlayerNames);
  const playerShootoutShots = filterShootoutByPlayerId(shootoutShots, player.id);
  const shootoutCount = filterShootoutsByGateZone(playerShootoutShots, '').length.toString();
  const shootoutPercentage = countShootoutPercentage(playerShootoutShots, '') + '%';

  const fullStatString = '(' + shootoutCount + '; ' + shootoutPercentage + ')';
  const labelWithStats = [label, fullStatString].join(' ');

  return {
    value: player.id,
    label: labelWithStats,
    additionalValue: shootoutCount.toString(),
  };
};

export const filteredPlayersForCompareOptionsSelector = createSelector(
  [selectMainFilter, filteredPlayersSelector, filteredTeamsSelector, filteredPartsSelector],
  (mainFilterState, filteredPlayers, filteredTeams, filteredParts) => {
    const { selectedTeam } = mainFilterState;
    if (!selectedTeam) {
      return [];
    }
    const selectedTeamId = selectedTeam.value;

    const filteredPlayersForCompareOptions = filteredPlayers
      .reduce<ISelectOption[]>((acc, player) => {
        const playerCompetitionTeams = Object.values(player.competitionTeams).filter(
          competitionTeam => filteredParts.some(part => part.id === competitionTeam.id),
        );

        if (selectedTeamId && selectedTeamId === 'all') {
          const alwaysOnlyOneCompetitionSelected = playerCompetitionTeams[0];

          const playerOptions: ISelectOption[] = alwaysOnlyOneCompetitionSelected.teamIds.map(
            teamId => {
              return parsePlayerToOptionWithTeamInLabel(filteredTeams, player, teamId);
            },
          );
          return acc.concat(playerOptions);
        }

        const playerTeamId = playerCompetitionTeams[0].teamIds.find(
          teamId => teamId === selectedTeamId,
        );

        if (!playerTeamId) {
          return acc;
        }

        return acc.concat(parsePlayerToOptionWithTeamInLabel(filteredTeams, player, playerTeamId));
      }, [])
      .sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
      });

    return filteredPlayersForCompareOptions;
  },
);

export const filteredPlayersForFaceoffOptionsSelector = createSelector(
  [selectMainFilter, filteredPlayersSelector, selectFormations, selectPlayers],
  (mainFilterState, filteredPlayers, formations, playersState) => {
    const { selectedTeam } = mainFilterState;
    const { similarPlayerNames } = playersState;
    if (!selectedTeam) {
      return [];
    }

    const filteredPlayersForFaceoffOptions = filteredPlayers
      .reduce<ISelectOption[]>(
        (acc, player) =>
          acc.concat(
            parsePlayerToOptionWithFaceoffStatsInLabel(
              player,
              formations.teammateState,
              similarPlayerNames,
            ),
          ),
        [],
      )
      .sort((a, b) => {
        const aValue = parseInt(a.additionalValue ?? '0');
        const bValue = parseInt(b.additionalValue ?? '0');
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      });

    return filteredPlayersForFaceoffOptions;
  },
);

export const filteredPlayersForShootoutOptionsSelector = createSelector(
  [selectMainFilter, selectPlayers, filteredPlayersSelector, filteredShootoutFilterDataSelector],
  (mainFilterState, playersState, filteredPlayers, filteredShootout) => {
    const { selectedTeam } = mainFilterState;
    const { similarPlayerNames } = playersState;
    const { filteredShootoutsBySelectedGames } = filteredShootout;
    if (!selectedTeam) {
      return [];
    }

    const filteredPlayersForFaceoffOptions = filteredPlayers
      .reduce<ISelectOption[]>(
        (acc, player) =>
          acc.concat(
            parsePlayerToOptionWithShootoutStatsInLabel(
              player,
              filteredShootoutsBySelectedGames,
              similarPlayerNames,
            ),
          ),
        [],
      )
      .sort((a, b) => {
        const aValue = parseInt(a.additionalValue ?? '0');
        const bValue = parseInt(b.additionalValue ?? '0');
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      });

    return filteredPlayersForFaceoffOptions;
  },
);

export const filteredPlayerDataSelector = createSelector(
  [
    filteredPlayersForCompareOptionsSelector,
    filteredPlayersForFaceoffOptionsSelector,
    filteredPlayersForShootoutOptionsSelector,
  ],
  (
    filteredPlayersForCompareOptions,
    filteredPlayersForFaceoffOptions,
    filteredPlayersForShootoutOptions,
  ) => {
    return {
      filteredPlayersForCompareOptions,
      filteredPlayersForFaceoffOptions,
      filteredPlayersForShootoutOptions,
    };
  },
);
