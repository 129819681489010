import { forwardRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  GameReportTable,
  GameReportTableToolbar,
  Loading,
} from '../../../../../../components';
import { IUseExportAsImage } from '../../../../../../hooks';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { DownloadIcon, PrintIcon } from '../../../../../../icons';
import {
  IGamesReportBlockTableData,
  IGamesReportForm,
  ISelectOption,
} from '../../../../../../types';
import { createClassNames } from '../../../../../../utils';
import { IGamesReportBlockTableColumns } from '../../columns';
import './ReportTableBox.styles.scss';

export interface IReportTableBoxProps {
  /** Data for table. */
  data: IGamesReportBlockTableData[];
  /** Columns for table. */
  columns: IGamesReportBlockTableColumns;
  /** Methods for game report form. */
  methods: UseFormReturn<IGamesReportForm>;
  /** Available count of players input options. */
  countOfPlayersOptions: ISelectOption[];
  /** Are data for table loading? */
  isLoading: boolean | undefined;
  /** Translation key of the table label */
  tableLabel: ITranslationKeys;
  /** Called when user clicks at fetch data button at toolbar. */
  fetchCallbackOnClick: (values: IGamesReportForm) => void;
  /** Called when user clicks at export data button. */
  onExportDataClick: () => void;
  /** Export button callback and loading state. */
  exportTable?: IUseExportAsImage;
}

const classNames = createClassNames('report-table-box');

/** Box component contains report table, toolbar and export buttons. */
export const ReportTableBox = forwardRef<HTMLDivElement, IReportTableBoxProps>(
  (
    {
      data,
      columns,
      methods,
      countOfPlayersOptions,
      isLoading,
      tableLabel,
      fetchCallbackOnClick,
      onExportDataClick,
      exportTable,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <div ref={ref} className={classNames()}>
        <GameReportTableToolbar {...{ methods, countOfPlayersOptions, fetchCallbackOnClick }} />
        <div className={classNames('table-label')}>{t(tableLabel)}</div>
        <div className={classNames('table')}>
          {isLoading ? (
            <Loading size='medium' backgroundColor='gray' disableMessage disableAbsolutePosition />
          ) : (
            <GameReportTable<IGamesReportBlockTableData> {...{ data, columns }} />
          )}
        </div>
        <div className={classNames('export-buttons')}>
          <Button
            label={ITranslationKeys.exportData}
            iconComponent={<DownloadIcon />}
            onClick={onExportDataClick}
            disabled={isLoading}
          />
          <Button
            label={ITranslationKeys.print}
            iconComponent={<PrintIcon />}
            onClick={exportTable?.exportImage}
            disabled={exportTable?.isExporting || isLoading}
          />
        </div>
      </div>
    );
  },
);
