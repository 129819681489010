import html2canvas, { Options } from 'html2canvas';
import JsPDF, { jsPDFOptions } from 'jspdf';
import { RefObject } from 'react';
import { WorkBook, WorkSheet, utils, writeFileXLSX } from 'xlsx';

import { IExportData, IExportRows, IMetricWithValueRecord } from '../types';

interface PDFOptions {
  x?: number;
  y?: number;
  height?: number;
  width?: number;
  orientation?: 'l' | 'p';
  unit?: jsPDFOptions['unit'];
  pdfFormat?: string | number[];
}

const getHtml2canvasPromise = (
  element: HTMLElement,
  html2CanvasOptions: Partial<Options> = {},
): Promise<HTMLCanvasElement> =>
  html2canvas(element, {
    scrollY: -window.scrollY,
    useCORS: true,
    ...html2CanvasOptions,
  });

const exportComponentAsPDF = (
  element: HTMLElement,
  fileName: string,
  pdfOptions: PDFOptions = {},
  html2CanvasOptions?: Partial<Options>,
) => {
  const { x = 0, y = 0, height, width, orientation, unit, pdfFormat } = pdfOptions;

  getHtml2canvasPromise(element, html2CanvasOptions).then(canvas => {
    const w = width || canvas.width;
    const h = height || canvas.height;
    const o = orientation || w > h ? 'l' : 'p';
    const u = unit || 'mm';
    const format = pdfFormat || 'a4';

    const pdf = new JsPDF(o, u, format);
    pdf.addImage(canvas.toDataURL('image/png', 1.0), 'PNG', x, y, w, h);
    pdf.save(fileName);
  });
};

const exportComponentAsImage = (
  element: HTMLElement,
  fileName: string,
  type: string = 'image/jpeg',
  html2CanvasOptions?: Partial<Options>,
) => {
  getHtml2canvasPromise(element, html2CanvasOptions).then(canvas => {
    const href = canvas.toDataURL(type, 1.0);
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(href);
    }
  });
};

export const getColumnsWidth = (data: (string | number)[][]) =>
  data[0].map((_, i) => ({
    width: Math.max(...data.map(a2 => (a2[i] ? a2[i].toString().length : 0))) + 2,
  }));

export const exportAsImage = (
  component: RefObject<HTMLDivElement>,
  as: 'jpeg' | 'png' | 'pdf',
  fileName: string,
) => {
  if (component.current !== null) {
    if (as === 'jpeg') {
      return exportComponentAsImage(component.current, fileName);
    }
    if (as === 'png') {
      return exportComponentAsImage(component.current, fileName, 'image/png');
    }
    if (as === 'pdf') {
      return exportComponentAsPDF(component.current, fileName);
    }
  } else {
    console.error('Component ref is null');
  }
};

/**
 * Gets data and prepares it for export to XLSX.
 * @param commonHeader Specify column names for export (except stats columns, stats are added automatically).
 * @param mapRowCallback Maps row data for every common column.
 * @param data Data to export.
 * @returns Header data, export data and columns width needed for export.
 */
export const prepareExportToXLSX = <T extends { stats?: IMetricWithValueRecord }>(
  commonHeader: string[],
  mapRowCallback: (row: T) => IExportRows,
  data?: T[],
) => {
  const getExportHeaderAndData = () => {
    if (data && data.length > 0) {
      const exportHeader = data[0].stats
        ? [[...commonHeader, ...Object.values(data[0].stats).map(stat => stat.label)]]
        : [commonHeader];

      const exportData = data.map<IExportRows>(row => {
        const stats = row.stats
          ? Object.keys(row.stats).reduce<IExportRows>((acc, stat) => {
              if (row.stats) {
                acc[stat] = row.stats[stat].value;
              }
              return acc;
            }, {})
          : {};

        return {
          ...mapRowCallback(row),
          ...stats,
        };
      });

      return { exportHeader, exportData };
    }

    return {
      exportHeader: [commonHeader],
      exportData: [],
    };
  };

  const { exportData, exportHeader } = getExportHeaderAndData();

  const dataToCompare = [...exportHeader, ...exportData.map(row => Object.values(row))];
  const columnsWidth = getColumnsWidth(dataToCompare);

  return { exportHeader, exportData, columnsWidth };
};

/**
 * Exports prepared data to XLSX file.
 * @param fileName Name for exported file.
 * @param data Data prepared for export.
 */
export const exportToXLSX = (fileName: string, data: IExportData) => {
  const { columnsWidth, exportData, exportHeader } = data;
  const ws: WorkSheet = utils.json_to_sheet<IExportRows>([]);
  const wb: WorkBook = utils.book_new();

  if (exportHeader) {
    utils.sheet_add_aoa(ws, exportHeader);
    utils.sheet_add_json<IExportRows>(ws, exportData, { origin: 'A2', skipHeader: true });
  } else {
    utils.sheet_add_json<IExportRows>(ws, exportData);
  }

  if (columnsWidth) {
    ws['!cols'] = columnsWidth;
  }

  utils.book_append_sheet(wb, ws, 'Sheet1');
  writeFileXLSX(wb, `hokejLogic${fileName ? '-' + fileName : ''}.xlsx`);
};

/**
 * Prepares and exports data to XLSX file.
 * @param fileName Name for exported file.
 * @param commonHeader Specify column names for export (except stats columns, stats are added automatically).
 * @param mapRowCallback Maps row data for every common column.
 * @param data Data to export.
 */
export const handleExportToXLSX = <T extends {}>(
  fileName: string,
  commonHeader: string[],
  mapRowCallback: (row: T) => IExportRows,
  data?: T[],
  extraExportRows?: IExportRows,
) => {
  const { exportHeader, exportData, columnsWidth } = prepareExportToXLSX(
    commonHeader,
    mapRowCallback,
    data,
  );

  exportToXLSX(fileName, {
    columnsWidth,
    exportData: exportData.concat(extraExportRows || []),
    exportHeader,
  });
};
