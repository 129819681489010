import { useCallback } from 'react';

import { useAppSelector } from '../app/hooks';
import {
  selectDefinedDatasets,
  selectMetrics,
  selectTableFilter,
  selectUserDatasets,
} from '../features';
import { IEntity, IMetricWithDatasetMetric } from '../types';
import { getDataTemplateMetrics, getMetricLabel } from '../utils';
import { useLocationPaths } from './useLocationPaths';

export const useFilteredMetricsForTable = () => {
  const { metrics } = useAppSelector(selectMetrics);
  const { definedDatasets } = useAppSelector(selectDefinedDatasets);
  const userDatasets = useAppSelector(selectUserDatasets);
  const { dataTemplate, customMetrics } = useAppSelector(selectTableFilter);

  const { activeTab } = useLocationPaths();

  const getFilteredMetrics = useCallback(
    (entity: IEntity = IEntity.players) => {
      if (definedDatasets && metrics) {
        const customDatasetMetrics = customMetrics?.map(metric => metric.additionalInfo);
        const dataTemplateMetrics =
          customDatasetMetrics ??
          getDataTemplateMetrics(
            definedDatasets,
            userDatasets.byId,
            dataTemplate,
            activeTab,
            entity,
          );

        const filteredMetrics = dataTemplateMetrics.map<IMetricWithDatasetMetric>(datasetMetric => {
          const metricRecord = metrics[entity].byId;
          const metric = datasetMetric.parameter
            ? metricRecord[datasetMetric.origin]
            : metricRecord[datasetMetric.code];

          const label = getMetricLabel(metric, datasetMetric);

          return {
            id: datasetMetric.code,
            label,
            metric,
            datasetMetric,
          };
        });

        return filteredMetrics;
      }

      return undefined;
    },
    [metrics, definedDatasets, userDatasets.byId, dataTemplate, customMetrics, activeTab],
  );

  return getFilteredMetrics;
};
