import { FC } from 'react';

import { createClassNames } from '../../utils';
import { IModalProps } from '../modalComponents';
import './InnerModal.styles.scss';

interface IInnerModalProps extends IModalProps {
  /** Position of inner modal inside modal */
  position: 'left' | 'right';
}

const classNames = createClassNames('inner-modal');

/**
 * InnerModal UI component.
 */
export const InnerModal: FC<IInnerModalProps> = ({ children, open, position }) => {
  if (!open) {
    return null;
  }
  return <div className={classNames({ position })}>{children}</div>;
};
