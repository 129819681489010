import { FC, useEffect } from 'react';

const VIDEO_IFRAME_ID = 'video-file-iframe';

export interface IVideoPlayerFrameProps {
  videoPlayerHtml: string | undefined;
  iframeKey: number;
}
export const VideoPlayerFrame: FC<IVideoPlayerFrameProps> = ({ videoPlayerHtml, iframeKey }) => {
  useEffect(() => {
    const setIframeContent = () => {
      const currentIframe = document.getElementById(VIDEO_IFRAME_ID) as HTMLIFrameElement;

      const iframeDocument = currentIframe.contentDocument;
      if (iframeDocument) {
        iframeDocument.open();
        iframeDocument.write(videoPlayerHtml || '');
        iframeDocument.close();
      }
    };

    setIframeContent();
  }, [iframeKey, videoPlayerHtml]);

  return (
    <iframe
      key={iframeKey}
      id={VIDEO_IFRAME_ID}
      name={VIDEO_IFRAME_ID}
      title='videoplayer'
      width='100%'
      height='100%'
      allowFullScreen
    ></iframe>
  );
};
