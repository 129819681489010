import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IGoalStatsBoxWrapperProps } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { GoalStatsBox } from '../GoalStatsBox/GoalStatsBox';
import './GoalStatsBoxWrapper.styles.scss';

const classNames = createClassNames('goal-stats-box-wrapper');

export const GoalStatsBoxWrapper: FC<IGoalStatsBoxWrapperProps> = ({
  goalStatsBoxItems,
  label,
  color = 'white',
  showHeader = true,
  disableBoxShadow = false,
}) => {
  const { t } = useTranslation();
  const tableHeaders = Array.from(
    new Set(
      goalStatsBoxItems.flatMap(item =>
        item.items.filter(item => item.label).map(item => item.label),
      ),
    ),
  );

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          color,
          disableBoxShadow,
        }),
      })}
      data-testid='goal-stats-box-wrapper'
    >
      {label && <span className={classNames('label')}>{label}</span>}
      {showHeader && (
        <table className={classNames('table-labels')}>
          <thead>
            <tr>{tableHeaders.map((th, index) => th && <th key={index}>{t(th)}</th>)}</tr>
          </thead>
        </table>
      )}
      <div className={classNames('tables')}>
        {goalStatsBoxItems.map((item, index) => (
          <GoalStatsBox key={index} {...item} />
        ))}
      </div>
    </div>
  );
};
