import { FC } from 'react';

import { ITranslationKeys } from '../../../i18n/types';
import { IShotsStats } from '../../../types';
import { createClassNames } from '../../../utils';
import { GameShotsRow } from '../GameShotsRow';
import './GameShotsStats.styles.scss';

interface IGameShotsStatsProps {
  /**
   * High danger shots info.
   */
  high: IShotsStats;
  /**
   * Medium danger shots info.
   */
  medium: IShotsStats;
  /**
   * Low danger shots info.
   */
  low: IShotsStats;
  /**
   * If true, elements are rendered in reverse order.
   */
  reverse?: boolean;
}

const classNames = createClassNames('game-shots-stats');

/**
 * GameShotsStats UI component.
 */
export const GameShotsStats: FC<IGameShotsStatsProps> = ({ high, medium, low, reverse }) => {
  return (
    <div className={classNames()}>
      <div className={classNames('header', { reverse })}>
        <p>G</p>
        <p>xG</p>
        <p>C</p>
      </div>
      <GameShotsRow shotStats={high} shotType={ITranslationKeys.high} reverse={reverse} />
      <GameShotsRow shotStats={medium} shotType={ITranslationKeys.medium} reverse={reverse} />
      <GameShotsRow shotStats={low} shotType={ITranslationKeys.low} reverse={reverse} />
    </div>
  );
};
