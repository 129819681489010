import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { useAppSelector } from '../../../app/hooks';
import { allTeamOrMediaAllOption, baseRoleOptionsWithAll } from '../../../constants';
import { teamAndMediaAccessAndScreenLogOptionsSelector } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { IRole, ITrackingUserFilterForm } from '../../../types';
import { createClassNames, isFormValid, isRoleAllOrOnlyOther } from '../../../utils';
import { TcmsButton } from '../TcmsButton';
import {
  RoleMultiselectController,
  SelectTeamOrMediaController,
  UserController,
} from '../TcmsControllers';
import './TrackingUserFilter.styles.scss';

interface ITrackingUserFilterProps {
  onSubmit: () => void;
}

const classNames = createClassNames('tracking-user-filter');

export const TrackingUserFilter: FC<ITrackingUserFilterProps> = ({ onSubmit }) => {
  const teamAndMediaOptions = useAppSelector(teamAndMediaAccessAndScreenLogOptionsSelector);

  const { control, formState, watch, setValue } = useFormContext<ITrackingUserFilterForm>();
  const { isDirty, errors } = formState;

  const selectedRoles = watch('selectedRoles');
  const filteredTeamOrMediaOptions = teamAndMediaOptions.filter(
    option => option.value === 'all' || selectedRoles.some(o => o.value === option.additionalValue),
  );

  const handleOnRoleChange = () => {
    setValue('selectedTeamOrMedia', [allTeamOrMediaAllOption], {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <div className={classNames()}>
      <form noValidate>
        <UserController name='userFilterText' control={control} />
        <RoleMultiselectController
          name='selectedRoles'
          control={control}
          options={baseRoleOptionsWithAll}
          handleOnChange={handleOnRoleChange}
        />
        <SelectTeamOrMediaController
          name='selectedTeamOrMedia'
          control={control}
          options={filteredTeamOrMediaOptions}
          disabled={isRoleAllOrOnlyOther(selectedRoles, baseRoleOptionsWithAll.length, IRole.user)}
        />
        <TcmsButton
          variant='filled'
          color='blue'
          label={ITranslationKeys.displayData}
          width={216}
          onClick={onSubmit}
          disabled={!isDirty || !isFormValid(errors)}
        />
      </form>
    </div>
  );
};
