import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppSelector } from '../../app/hooks';
import { Loading } from '../../components';
import { selectNavigation, selectNavigationNotPersisted } from '../../features';
import { useHelpSidebar, useLocationPaths, useNewsModal, useSelectPlayersBar } from '../../hooks';
import { ITabConfig } from '../../types';

export interface IDataContentsWrapperProps {
  /** Config with information about tabs */
  config: ITabConfig[];
}

/**
 * Component adds functionality and styles to every content of the page.
 */
export const DataContentsWrapper: FC<IDataContentsWrapperProps> = ({ config }) => {
  const { activePage, activeTab } = useLocationPaths();
  const { isLoadingMinimumInitialData } = useAppSelector(selectNavigation);
  const { isInitialized } = useAppSelector(selectNavigationNotPersisted);

  const selectPlayersBar = useSelectPlayersBar();
  const getHelpSidebar = useHelpSidebar();
  const getNewsModal = useNewsModal();

  return (
    <div className='grow-and-flex-column' data-testid={'data-contents-wrapper'}>
      {selectPlayersBar}
      {!isLoadingMinimumInitialData && isInitialized ? <Outlet /> : <Loading />}
      {getHelpSidebar(activePage, activeTab)}
      {getNewsModal(activePage, activeTab)}
    </div>
  );
};
