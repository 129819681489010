import { FC } from 'react';

import { Caption, GoalStatsBoxWrapper } from '../../../../../../components';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { IGoalStatsBoxWrapperProps } from '../../../../../../types';
import { createClassNames } from '../../../../../../utils';
import './ShootoutsStatsBoxCategory.styles.scss';

export interface IShootoutsStatsBoxCategory {
  /** A translated label to be displayed. */
  label: ITranslationKeys;
  /** Box wrappers to be mapped */
  goalStatsBoxWrappers: IGoalStatsBoxWrapperProps[];
}

const classNames = createClassNames('shootouts-stats-box-category');

export const ShootoutsStatsBoxCategory: FC<IShootoutsStatsBoxCategory> = ({
  label,
  goalStatsBoxWrappers,
}) => {
  return (
    <div className={classNames()}>
      <Caption label={label} />
      <div className={classNames('wrapper')}>
        {goalStatsBoxWrappers.map((goalStatsBoxWrapper, index) => (
          <GoalStatsBoxWrapper key={index} {...goalStatsBoxWrapper} />
        ))}
      </div>
    </div>
  );
};
