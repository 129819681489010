import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import type { RootState } from 'app/store';
import { toast } from 'react-toastify';

import { BASE_API_URL } from '../../constants';
import { logout } from '../../features';
import i18n from '../../i18n/i18n';
import { ITranslationKeys } from '../../i18n/types';
import { isTokenExpired } from '../../utils';

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token) {
      headers.set('authorization', `${token.token_type} ${token.access_token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  const loginDate = (api.getState() as RootState).auth.loginDate;

  if (isTokenExpired(loginDate)) {
    api.dispatch(logout());
    toast.error(i18n.t(ITranslationKeys.sessionExpired), { toastId: 'tokenExpired' });
  }

  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
