import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredMainFilterDataSelector,
  getWowy,
  selectMainFilter,
  selectWowy,
  setIsWowyPercentilesActive,
} from '../../features';
import { IMainFilterForm, IWowyRequestBody } from '../../types';
import { useNormalizeMetrics } from '../useNormalizeMetrics';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

export const useFetchPairs = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { isWowyPercentilesActive } = useAppSelector(selectWowy);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedPlayer, selectedTeam } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchPairs = (data: Partial<IMainFilterForm>, isPercentiles?: boolean) => {
    if (isWowyPercentilesActive) {
      dispatch(setIsWowyPercentilesActive(false));
    }

    const competitionsUuids = filteredParts.map(part => part.id);

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IWowyRequestBody = {
      ...requestBodyBase,
      metrics: normalizeMetrics(),
    };

    if (selectedTeam && selectedTeam.value !== 'all' && selectedPlayer) {
      if (isPercentiles) {
        dispatch(
          getWowy({
            competitionsUuids,
            body: requestBody,
            playerUuid: selectedPlayer.value,
            teamUuid: selectedTeam.value,
            isPercentile: true,
          }),
        );
      } else {
        dispatch(
          getWowy({
            competitionsUuids,
            body: requestBody,
            playerUuid: selectedPlayer.value,
            teamUuid: selectedTeam.value,
          }),
        );
      }
    }
  };

  return fetchPairs;
};
