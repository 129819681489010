import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_MAIN_FILTER_FORM_VALUES } from '../../constants';
import { IDateRangeString, IGame, IMainFilterState, ISelectOption } from '../../types';

const initialState: IMainFilterState = DEFAULT_MAIN_FILTER_FORM_VALUES;

export const mainFilterSlice = createSlice({
  name: 'mainFilter',
  initialState,
  reducers: {
    setSelectedSeasons: (state, action: PayloadAction<ISelectOption[]>) => {
      state.selectedSeasons = [...action.payload];
    },
    setSelectedParts: (state, action: PayloadAction<ISelectOption[]>) => {
      state.selectedParts = [...action.payload];
    },
    setSelectedTeam: (state, action: PayloadAction<ISelectOption>) => {
      state.selectedTeam = action.payload;
    },
    setCountOfPlayers: (state, action: PayloadAction<ISelectOption>) => {
      state.countOfPlayers = action.payload;
    },
    setSelectedOpponents: (state, action: PayloadAction<ISelectOption[]>) => {
      state.selectedOpponents = [...action.payload];
    },
    setSelectedGoalkeeper: (state, action: PayloadAction<ISelectOption | undefined>) => {
      state.selectedGoalkeeper = action.payload;
    },
    setSelectedPlayer: (state, action: PayloadAction<ISelectOption | undefined>) => {
      state.selectedPlayer = action.payload;
    },
    setPlace: (state, action: PayloadAction<ISelectOption>) => {
      state.place = action.payload;
    },
    setScoreState: (state, action: PayloadAction<ISelectOption>) => {
      state.scoreState = action.payload;
    },
    setLastPlayedMatches: (state, action: PayloadAction<number | string>) => {
      state.lastPlayedMatches = action.payload;
    },
    setDateFromTo: (state, action: PayloadAction<IDateRangeString | undefined>) => {
      state.dateFromTo = action.payload;
    },
    setGameTimeFrom: (state, action: PayloadAction<number | string>) => {
      state.gameTimeFrom = action.payload;
    },
    setGameTimeTo: (state, action: PayloadAction<number | string>) => {
      state.gameTimeTo = action.payload;
    },
    setTimeOnIce: (state, action: PayloadAction<number | string>) => {
      state.timeOnIce = action.payload;
    },
    setOpponentsAtGamesPage: (state, action: PayloadAction<ISelectOption>) => {
      state.opponentsAtGamesPage = action.payload;
    },
    setSelectedGame: (state, action: PayloadAction<IGame | undefined>) => {
      state.selectedGame = action.payload;
    },
    setFormationType: (state, action: PayloadAction<ISelectOption>) => {
      state.formationType = action.payload;
    },
    resetMainFilter: (_, action: PayloadAction<IMainFilterState>) => action.payload,
  },
});

export const {
  setSelectedSeasons,
  setSelectedParts,
  setSelectedTeam,
  setCountOfPlayers,
  setSelectedOpponents,
  setSelectedGoalkeeper,
  setSelectedPlayer,
  setPlace,
  setScoreState,
  setLastPlayedMatches,
  setDateFromTo,
  setGameTimeFrom,
  setGameTimeTo,
  setTimeOnIce,
  setOpponentsAtGamesPage,
  setSelectedGame,
  setFormationType,
  resetMainFilter,
} = mainFilterSlice.actions;

export const mainFilterReducer = mainFilterSlice.reducer;
