import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { MainTrackingFilter } from '../../../../components';
import { useHandleOnSubmitWithValues } from '../../../../hooks';
import { HokejCoachLogoIcon, HokejLogicLogoIcon, HokejPlayerLogoIcon } from '../../../../icons';
import { IMainTrackingFilterForm } from '../../../../types';
import { createClassNames, getDefaultMainTrackingFormValues } from '../../../../utils';
import './OverviewPage.styles.scss';
import {
  AccessLogExtraCoach,
  AccessLogHockeyLogic,
  AccessLogHokejPlayer,
  ListHokejPlayer,
  RoleLogExtraCoach,
  RoleLogHockeyLogic,
} from './components';

const classNames = createClassNames('overview-page');

export const OverviewPage = () => {
  const methods = useForm<IMainTrackingFilterForm>({
    defaultValues: getDefaultMainTrackingFormValues(),
    mode: 'onBlur',
  });

  const { submitValues, handleOnSubmit } = useHandleOnSubmitWithValues(
    methods,
    getDefaultMainTrackingFormValues(),
  );

  const [pageLogic, setPageLogic] = useState(0);
  const [pageCoach, setPageCoach] = useState(0);

  const onSubmitForm = () => {
    if (pageLogic !== 0) {
      setPageLogic(0);
    }
    if (pageCoach !== 0) {
      setPageCoach(0);
    }
    handleOnSubmit();
  };

  return (
    <FormProvider {...methods}>
      <div className={classNames()}>
        <form noValidate>
          <MainTrackingFilter onSubmit={onSubmitForm} />
        </form>
        <div className={classNames('hl-ec')}>
          <div className={classNames('hl-ec__charts')}>
            <HokejLogicLogoIcon height={32} />
            <AccessLogHockeyLogic submitValues={submitValues} />
            <RoleLogHockeyLogic
              submitValues={submitValues}
              page={pageLogic}
              setPage={setPageLogic}
            />
          </div>
          <div className={classNames('hl-ec__charts')}>
            <HokejCoachLogoIcon height={32} />
            <AccessLogExtraCoach submitValues={submitValues} />
            <RoleLogExtraCoach
              submitValues={submitValues}
              page={pageCoach}
              setPage={setPageCoach}
            />
          </div>
        </div>
        <div className={classNames('ep')}>
          <HokejPlayerLogoIcon />
          <div className={classNames('ep__charts')}>
            <AccessLogHokejPlayer submitValues={submitValues} />
            <ListHokejPlayer submitValues={submitValues} />
          </div>
        </div>
      </div>
    </FormProvider>
  );
};
