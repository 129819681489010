import { FC } from 'react';

import shodaPath from '../../../assets/ico-shoda.svg';
import { createClassNames, getCellColorByPercent } from '../../../utils';
import './SimilarityColumn.styles.scss';

export interface ISimilarityColumnHeaderProps {}

export interface ISimilarityColumnCellProps {
  value: number;
}

const classNames = createClassNames('similarity-column-header');

export const SimilarityColumnHeader: FC<ISimilarityColumnHeaderProps> = () => {
  return (
    <div className={classNames()}>
      <img className={classNames('image')} src={shodaPath} alt='shoda-icon' />
    </div>
  );
};

const classNamesCell = createClassNames('similarity-column-cell');

export const SimilarityColumnCell: FC<ISimilarityColumnCellProps> = ({ value }) => {
  return (
    <div
      className={classNamesCell({
        color: getCellColorByPercent(value),
      })}
    >
      {value}
    </div>
  );
};
