import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeysPlayerInfo } from '../../../i18n/types';
import { createClassNames } from '../../../utils';
import './PlayerInfoItem.styles.scss';

export interface IPlayerInfoItemProps {
  /** Label of the player info item. */
  label: ITranslationKeysPlayerInfo;
  /** Value of the player info item. */
  value: string;
}

const classNames = createClassNames('player-info-item');

/**
 * PlayerInfoItem component showing single detail of the player.
 */
export const PlayerInfoItem: FC<IPlayerInfoItemProps> = ({ label, value }) => {
  const { t } = useTranslation();
  if (!value) return null;

  return (
    <div className={classNames()} data-testid='player-info-item'>
      <strong>{t(label)}</strong>
      {value}
    </div>
  );
};
