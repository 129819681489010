import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { shiftEventsOptions } from '../../constants';
import { ISelectOption, IVideoEdit, IVideoShiftsState } from '../../types';
import { getDefaultPredefinedOptionByValue } from '../../utils';
import { getVideoShifts } from './videoShiftsActions';

const initialState: IVideoShiftsState = {
  categoryKey: '',
  open: false,
  edits: [],
  shifts: {},
  selectedShiftEvents: getDefaultPredefinedOptionByValue(shiftEventsOptions),
  videoPlayer: undefined,
  playerId: undefined,
  isLoading: false,
  error: undefined,
};

export const videoShiftsSlice = createSlice({
  name: 'videoShifts',
  initialState,
  reducers: {
    setOpenShiftsVideoCenter: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    closeShiftsVideoCenter: () => {
      return initialState;
    },
    setSelectedShiftEvents: (state, action: PayloadAction<ISelectOption>) => {
      state.selectedShiftEvents = action.payload;
    },
    setShiftVideoEdit: (state, action: PayloadAction<IVideoEdit>) => {
      const shiftVideo = state.shifts[action.payload.videoUuid];
      const stateEdits = state.edits.find(item => item.videoUuid === action.payload.videoUuid);

      if (
        shiftVideo.cutAfter === action.payload.cutAfter &&
        shiftVideo.cutBefore === action.payload.cutBefore &&
        shiftVideo.videoTime === action.payload.videoTime
      ) {
        if (stateEdits) {
          state.edits = state.edits.filter(edit => edit.videoUuid !== action.payload.videoUuid);
        }
      } else {
        if (stateEdits) {
          state.edits = [
            ...state.edits.filter(edit => edit.videoUuid !== action.payload.videoUuid),
            action.payload,
          ];
        } else {
          state.edits.push(action.payload);
        }
      }
    },
    resetShiftVideoEditById: (state, action: PayloadAction<string>) => {
      const stateEdits = state.edits.find(item => item.videoUuid === action.payload);

      if (stateEdits) {
        state.edits = state.edits.filter(edit => edit.videoUuid !== action.payload);
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getVideoShifts.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getVideoShifts.fulfilled, (state, action) => {
        state.shifts = action.payload.shifts;
        state.videoPlayer = action.payload.videoPlayer;
        state.playerId = action.meta.arg.playerUuid;
        state.isLoading = false;
      })
      .addCase(getVideoShifts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setOpenShiftsVideoCenter,
  closeShiftsVideoCenter,
  setSelectedShiftEvents,
  setShiftVideoEdit,
  resetShiftVideoEditById,
} = videoShiftsSlice.actions;

export const videoShiftsReducer = videoShiftsSlice.reducer;
