import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { gamePartOptions } from '../../../constants';
import { useHandleOnSubmitSubForm } from '../../../hooks';
import { ITranslationKeys } from '../../../i18n/types';
import { IGamesReportForm, ISelectOption } from '../../../types';
import { createClassNames } from '../../../utils';
import { Button } from '../../Button';
import { Caption } from '../../Caption';
import { SelectInput } from '../../SelectInput';
import './GameReportTableToolbar.styles.scss';

export interface IGameReportTableToolbarProps {
  /** Methods for game report form. */
  methods: UseFormReturn<IGamesReportForm>;
  /** Available count of players input options. */
  countOfPlayersOptions: ISelectOption[];
  /** Called when user clicks at fetch data button. */
  fetchCallbackOnClick: (values: IGamesReportForm) => void;
}

const classNames = createClassNames('game-report-table-toolbar');

/** Toolbar with filter for GameReportTable component. */
export const GameReportTableToolbar: FC<IGameReportTableToolbarProps> = ({
  methods,
  countOfPlayersOptions,
  fetchCallbackOnClick,
}) => {
  const { control, formState } = methods;

  const handleOnSubmit = useHandleOnSubmitSubForm(values => {
    fetchCallbackOnClick(values);
  }, methods);

  return (
    <div className={classNames()}>
      <div>
        <Caption label={ITranslationKeys.gamePeriod} />
        <Controller
          name='gamePart'
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectInput
              onChange={onChange}
              selected={value}
              options={gamePartOptions}
              placeholder={ITranslationKeys.defaultSelectPlaceholder}
              variant='filter'
            />
          )}
        />
      </div>
      <div>
        <Caption label={ITranslationKeys.countOfPlayers} />
        <Controller
          name='countOfPlayers'
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectInput
              onChange={onChange}
              selected={value}
              options={countOfPlayersOptions}
              placeholder={ITranslationKeys.defaultSelectPlaceholder}
              variant='filter'
            />
          )}
        />
      </div>
      <Button
        label={ITranslationKeys.loadData}
        onClick={handleOnSubmit}
        disabled={!formState.isDirty}
      />
    </div>
  );
};
