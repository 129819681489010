import { RefObject, useEffect, useState } from 'react';

import { exportAsImage } from '../utils';

export interface IUseExportAsImage {
  isExporting: boolean;
  exportImage: () => void;
}

export const useExportAsImage = (
  ref: RefObject<HTMLDivElement>,
  fileName: string,
  as: 'jpeg' | 'png' | 'pdf',
): IUseExportAsImage => {
  const [isExporting, setIsExporting] = useState<boolean>(false);

  useEffect(() => {
    if (isExporting) {
      exportAsImage(ref, as, fileName);
      setIsExporting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExporting]);

  return {
    isExporting,
    exportImage: () => setIsExporting(true),
  };
};
