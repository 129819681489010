import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IBaseFormationParams, IFormationsPlayerStats, ITeamStats } from '../../types';
import { buildPath, defaultCatchErrorCallback, urlBooleanParamResolver } from '../../utils';
import { parseFormationsData, parseFormationsPlayersStatsData } from './parseFormations';

interface IGetFormationsPlayersStatsParams extends IBaseFormationParams {
  isTeammate?: boolean;
  isOpponent?: boolean;
  isRelativeToLeague?: boolean;
  isRelativeToTeam?: boolean;
}

interface IGetFormationsParams extends IBaseFormationParams {
  isRelativeToLeague?: boolean;
  isRelativeToTeam?: boolean;
  isLast?: boolean;
}

const getFormationsPlayersStatsRequestUrl = (
  params: Omit<IGetFormationsPlayersStatsParams, 'body'>,
): string => {
  const {
    competitionsUuids,
    teamUuid,
    isTeammate,
    isOpponent,
    isRelativeToLeague,
    isRelativeToTeam,
  } = params;
  const base = `/formation/${competitionsUuids}/${teamUuid}`;

  return buildPath(base, [
    urlBooleanParamResolver(isTeammate, 'teammate'),
    urlBooleanParamResolver(isOpponent, 'opponent'),
    urlBooleanParamResolver(isRelativeToLeague, 'relativeToLeague'),
    urlBooleanParamResolver(isRelativeToTeam, 'relativeToTeam'),
  ]);
};

const getFormationsRequestUrl = (params: Omit<IGetFormationsParams, 'body'>): string => {
  const { competitionsUuids, teamUuid, isRelativeToLeague, isRelativeToTeam } = params;
  const base = `/formation/${competitionsUuids}/${teamUuid}`;

  return buildPath(base, [
    urlBooleanParamResolver(isRelativeToLeague, 'relativeToLeague'),
    urlBooleanParamResolver(isRelativeToTeam, 'relativeToTeam'),
  ]);
};

export const getFormationsPlayersStats = createAsyncThunk<
  IFormationsPlayerStats[],
  IGetFormationsPlayersStatsParams
>(
  'formations/getFormationsPlayersStats',
  async ({
    competitionsUuids,
    teamUuid,
    isTeammate,
    isOpponent,
    isRelativeToLeague,
    isRelativeToTeam,
    body,
  }) => {
    const requestPath = getFormationsPlayersStatsRequestUrl({
      competitionsUuids,
      teamUuid,
      isTeammate,
      isOpponent,
      isRelativeToLeague,
      isRelativeToTeam,
    });

    const formationsPlayersStats: IFormationsPlayerStats[] = await axiosInstance
      .post(requestPath, body ?? {})
      .then(response => parseFormationsPlayersStatsData(response.data))
      .catch(defaultCatchErrorCallback);

    return formationsPlayersStats;
  },
);

export const getFormations = createAsyncThunk<ITeamStats, IGetFormationsParams>(
  'formations/getFormations',
  async ({ competitionsUuids, teamUuid, body, isRelativeToLeague, isRelativeToTeam }) => {
    const requestPath = getFormationsRequestUrl({
      competitionsUuids,
      teamUuid,
      isRelativeToLeague,
      isRelativeToTeam,
    });

    const formation: ITeamStats = await axiosInstance
      .post(requestPath, body ?? {})
      .then(response => parseFormationsData(response.data, teamUuid))
      .catch(defaultCatchErrorCallback);

    return formation;
  },
);

export const getFormationsTeamOrderStats = createAsyncThunk<ITeamStats, IGetFormationsParams>(
  'formations/getFormationsTeamOrderStats',
  async ({ competitionsUuids, teamUuid, body }) => {
    const requestPath = `/formation/${competitionsUuids}/${teamUuid}/index`;

    const formation: ITeamStats = await axiosInstance
      .post(requestPath, body ?? {})
      .then(response => parseFormationsData(response.data, teamUuid))
      .catch(defaultCatchErrorCallback);

    return formation;
  },
);
