import { ICompetition, IReturnTypeGetSeasonsAction, ISeasonDTO, ISeasonRecord } from '../../types';

export const parseSeasonsData = (data: ISeasonDTO[]): IReturnTypeGetSeasonsAction => {
  let byId: ISeasonRecord = {};

  const competitions = data.reduce((acc: ICompetition[], seasonData) => {
    const seasonYear = parseInt(seasonData.name);
    const seasonNextYear = seasonYear + 1;
    const competitions: ICompetition[] = seasonData.competitions.map(competitionData => {
      return {
        id: competitionData.uuid,
        part: competitionData.part,
      };
    });

    const competitionIds = competitions.map(competition => competition.id);

    byId[seasonData.name] = {
      id: seasonData.name,
      name: `${seasonYear.toString()} - ${seasonNextYear.toString()}`,
      competitionIds,
    };

    return acc.concat(competitions);
  }, []);

  return {
    byId,
    competitions,
  };
};
