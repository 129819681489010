import { ITranslationKeys } from '../i18n/types';
import { axiosInstance } from '../services/axiosInstance';
import {
  IBundleVideo,
  IClipRating,
  IGame,
  IGetVideoPlayerParams,
  IGoalkeeper,
  IGoalkeeperRecord,
  IPlayer,
  IPlayerRecord,
  ISharedVideo,
  IShiftVideo,
  ITeamRecord,
  IVideo,
  IVideoInfo,
  IVideoRecord,
  IVideocoachNote,
} from '../types';
import { formatDateByLanguage } from './date.utils';
import { secondsToMinutesAndSeconds } from './number.utils';

export const getVideoPlayer = async ({
  matchUuid,
  seekTime,
  text,
  description,
  videoType,
  videoStartTime,
  videoEndTime,
  extraControls,
}: IGetVideoPlayerParams) => {
  const response = await axiosInstance.get(`/video/${matchUuid}/player`, {
    params: {
      seekTime,
      text,
      description,
      videoType,
      videoStartTime,
      videoEndTime,
      extraControls,
    },
  });
  return response;
};

export const createVideoBundle = async (user: string, videos: IBundleVideo[]) => {
  const body = { customer: user, videos };
  const response = await axiosInstance.post('videoCut/bundle', body);
  return response;
};

export const postVideocoachNote = async (note: IVideocoachNote) => {
  const response = await axiosInstance.post('videoCoach/videoClip', note);
  return response;
};

const sharedPrepareDownloadVideo = <T extends ISharedVideo>(
  video: T,
  playerOrGoalkeeperRecord: IPlayerRecord | IGoalkeeperRecord,
  categoryKey: ITranslationKeys | string,
  params: {
    homeTeam: string;
    awayTeam: string;
    score: string;
  },
): IBundleVideo => {
  const { homeTeam, awayTeam, score } = params;

  const date = formatDateByLanguage(new Date(video.matchDate), 'd.M.yyyy');
  const saveDate = Math.floor(Date.now() / 1000);
  const player = playerOrGoalkeeperRecord[video.playerId];
  const time = secondsToMinutesAndSeconds(video.time);
  const start = video.videoTime - video.cutBefore;
  const end = video.videoTime + video.cutAfter;

  const description = `${date}, ${homeTeam} - ${awayTeam} ${score};${formatPlayer(
    player,
  )};type??;gameState??;${time};${saveDate};${categoryKey};bulyState??;enterSuccesState??`;

  const name = `${date}_${time.replace(':', '')}_${homeTeam}_${awayTeam}_${score}_${
    player.surname
  }`;

  return {
    videoId: video.videoId,
    time: { start, end },
    description,
    name,
  };
};

export const prepareDownloadVideo = (
  video: IVideo,
  playerOrGoalkeeperRecord: IPlayerRecord | IGoalkeeperRecord,
  teamRecord: ITeamRecord,
  categoryKey: ITranslationKeys | string,
): IBundleVideo => {
  const homeTeam = video.homeTeamId ? teamRecord[video.homeTeamId].shortcut : '?';
  const awayTeam = video.awayTeamId ? teamRecord[video.awayTeamId].shortcut : '?';
  const score = video.score ? `${video.score.home}-${video.score.away}` : '?';

  return sharedPrepareDownloadVideo(video, playerOrGoalkeeperRecord, categoryKey, {
    homeTeam,
    awayTeam,
    score,
  });
};

export const prepareDownloadVideoShift = (
  video: IShiftVideo,
  playerOrGoalkeeperRecord: IPlayerRecord | IGoalkeeperRecord,
  teamRecord: ITeamRecord,
  categoryKey: ITranslationKeys | string,
  game: IGame,
): IBundleVideo => {
  const homeTeam = game.homeTeamId ? teamRecord[game.homeTeamId].shortcut : '?';
  const awayTeam = game.awayTeamId ? teamRecord[game.awayTeamId].shortcut : '?';
  const score = game.score ? `${game.score.home}-${game.score.away}` : '?';

  return sharedPrepareDownloadVideo(video, playerOrGoalkeeperRecord, categoryKey, {
    homeTeam,
    awayTeam,
    score,
  });
};

export const formatPlayer = (player: IPlayer | IGoalkeeper) =>
  `#${player.jersey} ${player.surname} ${player.name[0]}.`;

export const createVideoRecordFromArray = (array: IVideoInfo[]) =>
  array.reduce<IVideoRecord>((acc, video) => {
    const videoUuid = video.videoId + video.videoTime;

    return {
      ...acc,
      [videoUuid]: { ...video, videoUuid, cutBefore: 5, cutAfter: 5 },
    };
  }, {});

export const prepareVideocoachNote = (
  video: ISharedVideo,
  name: string,
  description: string,
  type: IClipRating,
  players: string[],
): IVideocoachNote => {
  return {
    time: video.time,
    videoTime: video.videoTime - video.cutBefore,
    endVideoTime: video.videoTime + video.cutAfter,
    videoId: video.videoId,
    matchId: video.matchId,
    name,
    description,
    type,
    players,
  };
};
