import type { AppStartListening } from 'app/store';

import { defaultTemplate } from '../../../constants';
import { setDataTemplate } from '../../tableFilter';
import { getDefinedDatasets } from './definedDatasetsActions';

export const addDefinedDatasetsListeners = (startListening: AppStartListening) => {
  startListening({
    actionCreator: getDefinedDatasets.fulfilled,
    effect: async (_, listenerApi) => {
      listenerApi.dispatch(setDataTemplate(defaultTemplate));
    },
  });
};
