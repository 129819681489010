import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { IShotsStats } from '../../../types';
import { createClassNames } from '../../../utils';
import './GameShotsRow.styles.scss';

interface IGameShotsRowProps {
  /**
   * Stats of shots.
   */
  shotStats: IShotsStats;
  /**
   * Danger type of shot.
   */
  shotType: 'high' | 'medium' | 'low';
  /**
   * If true, order of elements is reversed by css.
   */
  reverse?: boolean;
}

const classNames = createClassNames('game-shots-row');

/**
 * GameShotsRow UI component.
 */
export const GameShotsRow: FC<IGameShotsRowProps> = ({ shotStats, shotType, reverse }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames({ shotType, reverse })}>
      <p className={classNames('title')}>
        <span>{t(shotType)}</span> {t(ITranslationKeys.danger)}
      </p>
      <div className={classNames('stats')}>{shotStats.g}</div>
      <div className={classNames('stats')}>{shotStats.xG.toFixed(2)}</div>
      <div className={classNames('stats', { count: true })}>{shotStats.count}</div>
    </div>
  );
};
