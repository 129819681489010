import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DEFAULT_CONTENT_TEMPLATE_NAMES } from '../../constants';
import {
  filteredMainFilterDataSelector,
  getGameSummary,
  getGameSummaryBlock,
  getGameTeams,
  selectGames,
  selectMainFilter,
  selectTableFilter,
} from '../../features';
import {
  IEntity,
  IGameReportTeamsRequestBody,
  IGamesReportBlocksRequestBody,
  IGamesReportForm,
  IMainFilterForm,
} from '../../types';
import { getTimeFromGamePart, normalizeSelectWithAllValues } from '../../utils';
import { useNormalizeMetrics } from '../useNormalizeMetrics';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

export const useFetchReport = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { selectedGame } = useAppSelector(selectMainFilter);
  const { games } = useAppSelector(selectGames);
  const dispatch = useAppDispatch();
  const { byId } = games;
  const { gamePart, gameCountOfPlayers } = useAppSelector(selectTableFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);

  const normalizeMetrics = useNormalizeMetrics();

  const fetchGameSummary = () => {
    if (selectedGame) {
      const gameInfo = byId[selectedGame.id];

      dispatch(
        getGameSummary({
          competitionsUuid: gameInfo.competitionId ?? filteredParts.map(part => part.id),
          matchUuid: selectedGame.id,
          teamUuid: selectedGame.homeTeamId,
          gameInfo,
        }),
      );
    }
  };

  const fetchGameBlockOne = (data: Partial<IGamesReportForm>) => {
    if (selectedGame) {
      const { countOfPlayers, gamePart } = data;

      const gameInfo = byId[selectedGame.id];

      const body: IGamesReportBlocksRequestBody = {
        gamePart: normalizeSelectWithAllValues(gamePart),
        gameState: normalizeSelectWithAllValues(countOfPlayers),
      };

      dispatch(
        getGameSummaryBlock({
          competitionsUuid: gameInfo.competitionId ?? filteredParts.map(part => part.id),
          matchUuid: selectedGame.id,
          teamUuid: selectedGame.homeTeamId,
          gameInfo,
          body,
        }),
      );
    }
  };

  const fetchGameBlockTwo = (data: Partial<IGamesReportForm>) => {
    if (selectedGame) {
      const { countOfPlayers, gamePart } = data;

      const gameInfo = byId[selectedGame.id];

      const body: IGamesReportBlocksRequestBody = {
        gamePart: normalizeSelectWithAllValues(gamePart),
        gameState: normalizeSelectWithAllValues(countOfPlayers),
      };

      dispatch(
        getGameSummaryBlock({
          competitionsUuid: gameInfo.competitionId ?? filteredParts.map(part => part.id),
          matchUuid: selectedGame.id,
          teamUuid: selectedGame.homeTeamId,
          gameInfo,
          body,
          isSecondBlock: true,
        }),
      );
    }
  };

  const fetchGameTeams = (data: Partial<IMainFilterForm>) => {
    const { requestBodyBase } = prepareBaseRequestBody(data);

    const timeFromTo = getTimeFromGamePart(gamePart);

    const requestBody: IGameReportTeamsRequestBody = {
      ...requestBodyBase,
      metrics: normalizeMetrics(DEFAULT_CONTENT_TEMPLATE_NAMES.report, IEntity.teams),
      gameState: normalizeSelectWithAllValues(gameCountOfPlayers),
      timeFrom: timeFromTo?.timeFrom,
      timeTo: timeFromTo?.timeTo,
    };

    if (selectedGame) {
      const gameInfo = byId[selectedGame.id];

      dispatch(
        getGameTeams({
          competitionsUuid: gameInfo.competitionId ?? filteredParts.map(part => part.id),
          matchUuid: selectedGame.id,
          teamUuid: selectedGame.homeTeamId,
          body: requestBody,
        }),
      );
    }
  };

  return {
    fetchGameSummary,
    fetchGameBlockOne,
    fetchGameBlockTwo,
    fetchGameTeams,
  };
};
