import { FC } from 'react';

import { LetterDIcon, LetterMIcon, LetterSIcon, LetterTIcon } from '../../../icons';
import { ITrackingTimeInterval } from '../../../types';
import { createClassNames } from '../../../utils';
import './TimeInterval.styles.scss';

interface ITimeIntervalProps {
  timeInterval: ITrackingTimeInterval;
  onTimeIntervalChange: (timeInterval: ITrackingTimeInterval) => void;
}

const classNames = createClassNames('time-interval');

export const TimeInterval: FC<ITimeIntervalProps> = ({ timeInterval, onTimeIntervalChange }) => {
  return (
    <div className={classNames()}>
      <div
        className={classNames('letter', { active: timeInterval === 'D' })}
        onClick={() => onTimeIntervalChange('D')}
      >
        <LetterDIcon />
      </div>
      <div
        className={classNames('letter', { active: timeInterval === 'W' })}
        onClick={() => onTimeIntervalChange('W')}
      >
        <LetterTIcon />
      </div>
      <div
        className={classNames('letter', { active: timeInterval === 'M' })}
        onClick={() => onTimeIntervalChange('M')}
      >
        <LetterMIcon />
      </div>
      <div
        className={classNames('letter', { active: timeInterval === 'S' })}
        onClick={() => onTimeIntervalChange('S')}
      >
        <LetterSIcon />
      </div>
    </div>
  );
};
