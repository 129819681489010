import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { createClassNames } from '../../utils';
import './NavigationBarButton.styles.scss';

interface INavigationBarButtonProps {
  /**
   * Item contents
   */
  label?: ITranslationKeys;
  /**
   * Icon to be displayed
   */
  icon: ReactElement;
  /**
   * Optional click handler
   */
  onClick?: () => void;
}

const classNames = createClassNames('navigation-bar-button');

/**
 * NavigationBarButton component providing access to logout, bug report or show the help window.
 */
export const NavigationBarButton: FC<INavigationBarButtonProps> = ({ icon, label, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()} onClick={onClick}>
      {icon}
      {label && t(label)}
    </div>
  );
};
