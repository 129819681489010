import { FC } from 'react';

import { CrossIcon, DateIcon, PlayerIcon, TimeIcon } from '../../../icons';
import { IGoalkeeper, IPlayer } from '../../../types';
import { createClassNames, formatDateByLanguage, secondsToMinutesAndSeconds } from '../../../utils';
import './VideoInfoHeader.styles.scss';

interface IVideoInfoHeader {
  /** clips player */
  player: IPlayer | IGoalkeeper;
  /** video time */
  time: number;
  /** matche's date */
  date: Date;
  /** function that closes video player */
  onClose: () => void;
}

const classNames = createClassNames('video-info-header');

/** VideoInfoHeader UI component. */
export const VideoInfoHeader: FC<IVideoInfoHeader> = ({ time, player, date, onClose }) => {
  return (
    <div className={classNames()}>
      <div className={classNames('info')}>
        <div className={classNames('item')}>
          <TimeIcon />
          <p>{secondsToMinutesAndSeconds(time)}</p>
        </div>
        <div className={classNames('item')}>
          <PlayerIcon />
          <p>
            #{player.jersey} {player.surname} {player.name[0]}.
          </p>
        </div>
        <div className={classNames('item')}>
          <DateIcon />
          <p>{formatDateByLanguage(date, 'P')}</p>
        </div>
      </div>
      <div className={classNames('button')} onClick={onClose}>
        <CrossIcon />
      </div>
    </div>
  );
};
