import { FC } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { MetricColumnCell } from '../../../../components';
import { selectWowy } from '../../../../features';
import { ITeamsFormationSelectedPlayer, IWowyTableData } from '../../../../types';

export interface ISelectedPlayerRowCenterPartProps {
  selectedPlayer: IWowyTableData | ITeamsFormationSelectedPlayer;
}

export const SelectedPlayerRowCenterPart: FC<ISelectedPlayerRowCenterPartProps> = ({
  selectedPlayer,
}) => {
  const { isWowyPercentilesActive, isWowyPercentilesLoading } = useAppSelector(selectWowy);

  return (
    <tr key={selectedPlayer.player.id}>
      {Object.values(selectedPlayer.stats).map(stat => {
        const percentileValue = selectedPlayer.percentiles
          ? selectedPlayer.percentiles[stat.id].value
          : undefined;

        return (
          <th key={stat.id} className='extra-header-cell'>
            <MetricColumnCell
              value={stat.value}
              stat={stat}
              entityId={selectedPlayer.player.id}
              percentileValue={percentileValue}
              isPercentilesShown={isWowyPercentilesActive && !isWowyPercentilesLoading}
            />
          </th>
        );
      })}
    </tr>
  );
};
