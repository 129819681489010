import {
  enterExitLocationOptions,
  entryTypeOptions,
  exitTypeOptions,
  puckGainTypeOptions,
  shotDangerOptions,
  shotGameActionTypeOptions,
  shotLocationExtendedOptions,
  shotTypeOptions,
} from '../../constants';
import { IDataSettingsMetricParams } from '../../types';
import { getDefaultPredefinedOptionByValue } from '../../utils';

export const DEFAULT_DATA_SETTINGS_METRIC_PARAMS_STATE: IDataSettingsMetricParams = {
  shotLocation: getDefaultPredefinedOptionByValue(shotLocationExtendedOptions),
  shotDanger: getDefaultPredefinedOptionByValue(shotDangerOptions),
  shotType: getDefaultPredefinedOptionByValue(shotTypeOptions),
  gameActionType: getDefaultPredefinedOptionByValue(shotGameActionTypeOptions),
  netZone: undefined,
  puckGainType: getDefaultPredefinedOptionByValue(puckGainTypeOptions),
  entryType: getDefaultPredefinedOptionByValue(entryTypeOptions),
  exitType: getDefaultPredefinedOptionByValue(exitTypeOptions),
  enterExitLocation: getDefaultPredefinedOptionByValue(enterExitLocationOptions),
};
