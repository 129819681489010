import type { AppStartListening } from 'app/store';

import { getPlayerCardInfo } from './playersActions';
import { setComparePlayerItems } from './playersSlice';

export const addPlayersListeners = (startListening: AppStartListening) => {
  startListening({
    actionCreator: getPlayerCardInfo.fulfilled,
    effect: async (action, listenerApi) => {
      if (action.meta.arg.isCalledFromComparePlayers) {
        const { comparePlayerItems } = listenerApi.getState().players;
        const loadedPlayerId = action.meta.arg.playerUuid;
        const loadedPlayerTeamId = action.meta.arg.teamUuid;

        const filteredComparePlayerItems = Object.values(comparePlayerItems).filter(
          item => item.loadingPlayer?.id === loadedPlayerId,
        );

        const comparePlayerItem = filteredComparePlayerItems.find(
          item => item.loadingPlayer?.teamId === loadedPlayerTeamId,
        );

        if (comparePlayerItem) {
          listenerApi.dispatch(
            setComparePlayerItems({
              comparePlayerItems: {
                ...comparePlayerItem,
                actualCompareLoadedPlayer: {
                  id: action.payload.playerId,
                  teamId: loadedPlayerTeamId,
                },
                isLoadingPlayerInfo: false,
              },
              itemKey: comparePlayerItem.id,
            }),
          );
        }
      }
    },
  });
};
