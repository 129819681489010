import { createSelector } from '@reduxjs/toolkit';

import { IFaceoff, IFaceoffPlayerInfoRecord } from '../../types';
import { createGameEntity } from '../../utils';
import {
  selectFaceoffs,
  selectFaceoffsFilter,
  selectMainFilter,
  selectPlayers,
  selectVideomapsFilter,
} from '../selectors';

const filterFaceoffsByFollowedBy = (faceoffs: IFaceoff[], finish: string): IFaceoff[] => {
  if (finish === 'shotAttempt') {
    return faceoffs.filter(faceoff => faceoff.finish !== 'none');
  }

  if (finish === 'shotFromSlot') {
    return faceoffs.filter(faceoff => faceoff.finish === 'slotShot' || faceoff.finish === 'goal');
  }

  if (finish === 'goal') {
    return faceoffs.filter(faceoff => faceoff.finish === 'goal');
  }

  return faceoffs;
};

export const filteredFaceoffsByEntityIdSelector = createSelector(
  [selectMainFilter, selectFaceoffs, selectVideomapsFilter],
  (mainFilterState, faceoffsState) => {
    const { selectedTeam } = mainFilterState;
    const { byId, allIds } = faceoffsState;

    if (!selectedTeam || !allIds || allIds.length === 0) return {};

    const allIdsFiltered = allIds.length === 1 ? allIds : allIds.filter(id => id !== 'all');
    const filteredFaceoffs = allIdsFiltered.reduce<IFaceoffPlayerInfoRecord>((acc, id) => {
      acc[id] = {
        playerId: id,
        faceoffs: byId[id].faceoffs,
      };

      return acc;
    }, {});

    return filteredFaceoffs;
  },
);

export const filteredFaceoffsByFaceoffResultSelector = createSelector(
  [selectFaceoffsFilter, filteredFaceoffsByEntityIdSelector],
  ({ faceoffResult }, faceoffs) => {
    if (faceoffResult.value === 'all') return faceoffs;

    const filteredFaceoffs = Object.values(faceoffs).reduce<IFaceoffPlayerInfoRecord>(
      (acc, { playerId, faceoffs }) => {
        const isWon = faceoffResult.value === 'won';

        acc[playerId] = {
          playerId,
          faceoffs: faceoffs.filter(faceoff => faceoff.won === isWon),
        };

        return acc;
      },
      {},
    );

    return filteredFaceoffs;
  },
);

export const filteredFaceoffsByFaceoffsFollowedBySelector = createSelector(
  [selectFaceoffsFilter, filteredFaceoffsByEntityIdSelector],
  ({ faceoffsFollowedBy }, faceoffs) => {
    if (faceoffsFollowedBy.value === 'all') return faceoffs;

    const filteredFaceoffs = Object.values(faceoffs).reduce<IFaceoffPlayerInfoRecord>(
      (acc, { playerId, faceoffs }) => {
        acc[playerId] = {
          playerId,
          faceoffs: filterFaceoffsByFollowedBy(faceoffs, faceoffsFollowedBy.value),
        };

        return acc;
      },
      {},
    );

    return filteredFaceoffs;
  },
);

export const filteredFaceoffsByStickGripSelector = createSelector(
  [selectFaceoffsFilter, selectPlayers, filteredFaceoffsByEntityIdSelector],
  ({ stickGrip }, players, faceoffs) => {
    if (!stickGrip || stickGrip.value === 'all') return faceoffs;

    const filteredFaceoffs = Object.values(faceoffs).reduce<IFaceoffPlayerInfoRecord>(
      (acc, { playerId, faceoffs }) => {
        const newFaceoffs = faceoffs.filter(faceoff => {
          const player = players.byId[faceoff.opponentId];
          return player && player.stick === stickGrip.value;
        });

        acc[playerId] = {
          playerId,
          faceoffs: newFaceoffs,
        };

        return acc;
      },
      {},
    );

    return filteredFaceoffs;
  },
);

export const filteredFaceoffsByFaceoffZoneSelector = createSelector(
  [selectFaceoffsFilter, filteredFaceoffsByEntityIdSelector],
  ({ faceoffZone }, faceoffs) => {
    if (!faceoffZone || faceoffZone.value === 'all') return faceoffs;

    const filteredFaceoffs = Object.values(faceoffs).reduce<IFaceoffPlayerInfoRecord>(
      (acc, { playerId, faceoffs }) => {
        acc[playerId] = {
          playerId,
          faceoffs: faceoffs.filter(faceoff => faceoff.zone === faceoffZone.value),
        };

        return acc;
      },
      {},
    );

    return filteredFaceoffs;
  },
);

/** Filtered by all possible filters. */
export const filteredFaceoffsPlayerInfoSelector = createSelector(
  [
    selectFaceoffs,
    filteredFaceoffsByFaceoffResultSelector,
    filteredFaceoffsByFaceoffsFollowedBySelector,
    filteredFaceoffsByStickGripSelector,
    filteredFaceoffsByFaceoffZoneSelector,
  ],
  (
    faceoffsState,
    filteredFaceoffsByFaceoffResult,
    filteredFaceoffsByFaceoffsFollowedBy,
    filteredFaceoffsByStickGrip,
    filteredFaceoffsByFaceoffZone,
  ) => {
    const { allIds } = faceoffsState;
    if (allIds.length === 0) return {};

    const filteredFaceoffsInfo = Object.values(
      filteredFaceoffsByFaceoffResult,
    ).reduce<IFaceoffPlayerInfoRecord>((acc, { playerId, faceoffs }) => {
      const newFaceoffs = faceoffs.filter(
        faceoff =>
          filteredFaceoffsByFaceoffsFollowedBy[playerId].faceoffs.includes(faceoff) &&
          filteredFaceoffsByStickGrip[playerId].faceoffs.includes(faceoff) &&
          filteredFaceoffsByFaceoffZone[playerId].faceoffs.includes(faceoff),
      );

      acc[playerId] = {
        playerId,
        faceoffs: newFaceoffs,
      };

      return acc;
    }, {});

    return filteredFaceoffsInfo;
  },
);

export const filteredFaceoffsPlayerInfoBySelectedGamesSelector = createSelector(
  [filteredFaceoffsPlayerInfoSelector, selectVideomapsFilter],
  (filteredFaceoffsPlayerInfo, videomapsFilter) => {
    const { selectedGames } = videomapsFilter;

    const filteredFaceoffsInfo = Object.values(
      filteredFaceoffsPlayerInfo,
    ).reduce<IFaceoffPlayerInfoRecord>((acc, { playerId, faceoffs }) => {
      const selectedGamesIds = selectedGames.map(game => game.value);
      const newFaceoffs = faceoffs.filter(faceoff => selectedGamesIds.includes(faceoff.matchId));

      acc[playerId] = {
        playerId,
        faceoffs: newFaceoffs,
      };

      return acc;
    }, {});

    return filteredFaceoffsInfo;
  },
);

export const filteredFaceoffsSelector = createSelector(
  [filteredFaceoffsPlayerInfoSelector],
  filteredFaceoffsPlayerInfo => {
    const allFilteredFaceoffs = Object.values(filteredFaceoffsPlayerInfo).reduce<IFaceoff[]>(
      (acc, faceoffPlayerInfo) => acc.concat(faceoffPlayerInfo.faceoffs),
      [],
    );

    return allFilteredFaceoffs;
  },
);

export const filteredFaceoffsBySelectedGamesSelector = createSelector(
  [filteredFaceoffsPlayerInfoBySelectedGamesSelector],
  filteredFaceoffsPlayerInfo => {
    const allFilteredFaceoffs = Object.values(filteredFaceoffsPlayerInfo).reduce<IFaceoff[]>(
      (acc, faceoffPlayerInfo) => acc.concat(faceoffPlayerInfo.faceoffs),
      [],
    );

    return allFilteredFaceoffs;
  },
);

export const filteredFaceoffsGameEntitySelector = createSelector(
  [selectVideomapsFilter, filteredFaceoffsBySelectedGamesSelector],
  (videomapsFilterState, filteredFaceoffs) => {
    const { selectedGames } = videomapsFilterState;
    return createGameEntity(selectedGames, filteredFaceoffs);
  },
);

export const filteredFaceoffsDataSelector = createSelector(
  [
    filteredFaceoffsPlayerInfoSelector,
    filteredFaceoffsPlayerInfoBySelectedGamesSelector,
    filteredFaceoffsSelector,
    filteredFaceoffsBySelectedGamesSelector,
    filteredFaceoffsGameEntitySelector,
  ],
  (
    filteredFaceoffsPlayerInfo,
    filteredFaceoffsPlayerInfoBySelectedGames,
    filteredFaceoffs,
    filteredFaceoffsBySelectedGames,
    filteredFaceoffsGameEntity,
  ) => {
    return {
      filteredFaceoffsPlayerInfo,
      filteredFaceoffsPlayerInfoBySelectedGames,
      filteredFaceoffs,
      filteredFaceoffsBySelectedGames,
      filteredFaceoffsGameEntity,
    };
  },
);
