import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../app/hooks';
import {
  Caption,
  SearchInput,
  SelectInput,
  SelectTeamOrMediaController,
  TcmsButton,
} from '../../../../../../components';
import {
  allTeamOrMediaAllOption,
  eventLogEventOptions,
  trackingRoleOptions,
} from '../../../../../../constants';
import { teamAndMediaAccessAndScreenLogOptionsSelector } from '../../../../../../features';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { AnalyticsTypeIcon, UserRoleIcon, UserSearchIcon } from '../../../../../../icons';
import {
  IEventScreenLogFilterForm,
  IIdentity,
  IMultiParamsVoid,
  ISelectOption,
  ITrackingUserType,
} from '../../../../../../types';
import { createClassNames, isFormValid } from '../../../../../../utils';
import './EventLogFilter.styles.scss';

interface IEventLogFilterProps {
  identities: IIdentity[];
  onSubmit: () => void;
}

const classNames = createClassNames('event-log-filter');

export const EventLogFilter: FC<IEventLogFilterProps> = ({ identities, onSubmit }) => {
  const teamAndMediaOptions = useAppSelector(teamAndMediaAccessAndScreenLogOptionsSelector);
  const { t } = useTranslation();

  const { control, formState, watch, setValue } = useFormContext<IEventScreenLogFilterForm>();
  const { isDirty, errors } = formState;

  const selectedRole = watch('selectedRole');
  const filteredTeamOrMediaOptions = teamAndMediaOptions.filter(
    option => option.additionalValue === selectedRole?.value || option.value === 'all',
  );

  const handleSelectRole = (value: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(value);
    setValue('selectedRole', value, {
      shouldDirty: true,
      shouldValidate: true,
    });

    onChange(value);
    setValue('selectedTeamOrMedia', [allTeamOrMediaAllOption], {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleSelectItem = (
    value: ISelectOption,
    onChange: IMultiParamsVoid,
    name: keyof IEventScreenLogFilterForm,
  ) => {
    onChange(value);
    setValue(name, value, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const userOptions: ISelectOption[] = identities.map(identity => ({
    label: identity.username,
    value: identity.username,
  }));

  return (
    <div className={classNames()}>
      <div>
        <Caption label={ITranslationKeys.pickUser} variant='tcms' color='slate' />
        <Controller
          name='selectedUser'
          control={control}
          render={({ field: { value, onChange } }) => (
            <SearchInput
              onChange={value => handleSelectItem(value, onChange, 'selectedUser')}
              selected={value ?? undefined}
              options={userOptions}
              placeholder={`${t(ITranslationKeys.allUsers)}`}
              variant='tcms'
              iconComponent={<UserSearchIcon />}
            />
          )}
        />
      </div>
      <div>
        <Caption label={ITranslationKeys.eventType} variant='tcms' color='slate' />
        <Controller
          name='selectedEvent'
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectInput
              onChange={value => handleSelectItem(value, onChange, 'selectedEvent')}
              selected={value}
              options={eventLogEventOptions}
              placeholder={ITranslationKeys.allEvents}
              variant='tcms'
              iconComponent={<AnalyticsTypeIcon />}
            />
          )}
        />
      </div>
      <div>
        <Caption label={ITranslationKeys.userRole} variant='tcms' color='slate' />
        <Controller
          name='selectedRole'
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectInput
              onChange={newValue => handleSelectRole(newValue, onChange)}
              selected={value}
              options={trackingRoleOptions}
              placeholder={ITranslationKeys.defaultSelectPlaceholder}
              variant='tcms'
              iconComponent={<UserRoleIcon />}
            />
          )}
        />
      </div>
      <SelectTeamOrMediaController
        name='selectedTeamOrMedia'
        control={control}
        options={filteredTeamOrMediaOptions}
        disabled={selectedRole?.value === 'all' || selectedRole?.value === ITrackingUserType.other}
      />
      <TcmsButton
        variant='filled'
        color='blue'
        label={ITranslationKeys.displayData}
        width={216}
        onClick={onSubmit}
        disabled={!isDirty || !isFormValid(errors)}
      />
    </div>
  );
};
