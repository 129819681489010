import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IHelpState } from '../../../types';
import { getHelp, putHelp } from './helpActions';

const initialState: IHelpState = {
  help: undefined,
  isLoading: false,
  isHelpLoaded: false,
  error: undefined,
  showHelpSidebar: false,
};

export const helpSlice = createSlice({
  name: 'settings/help',
  initialState,
  reducers: {
    setShowHelpSidebar: (state, action: PayloadAction<boolean>) => {
      state.showHelpSidebar = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getHelp.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getHelp.fulfilled, (state, action) => {
        state.help = action.payload;
        state.isLoading = false;
        state.isHelpLoaded = true;
      })
      .addCase(getHelp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(putHelp.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(putHelp.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.help = action.payload;
        }
        state.isLoading = false;
      })
      .addCase(putHelp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { setShowHelpSidebar } = helpSlice.actions;
