import { ReactNode } from 'react';
import { SortableHandle } from 'react-sortable-hoc';

import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './DragHandle.styles.scss';

export interface IDragHandleProps {
  /** Color of icon. */
  color?: 'black' | 'white';
  /** Is component disabled? */
  disabled?: boolean;
  /** Icon to be used instead of default lines */
  icon?: ReactNode;
}

const classNames = createClassNames('drag-handle');

/** UI component for drag handle. */
export const DragHandle = SortableHandle<IDragHandleProps>(
  ({ color = 'black', disabled = false, icon = undefined }) => (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          disabled,
          hasIcon: !!icon,
        }),
      })}
    >
      {!icon ? <div className={classNames('icon', { color })}></div> : icon}
    </div>
  ),
);
