import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../app/hooks';
import { Card, ICardItemProps } from '../../../../components';
import { selectMetrics } from '../../../../features';
import { ITranslationKeys } from '../../../../i18n/types';
import { IGroup, IPlayerCardInfo } from '../../../../types';
import { getStatValueByMetricId } from '../../../../utils';
import {
  IPlayerCardConfig,
  attackCardConfig,
  defendCardConfig,
  penaltyKillingCardConfig,
  powerPlaysCardConfig,
  transitionCardConfig,
} from './playerCardsConfig';

interface IGetPlayerCardsConfig {
  attack: ICardItemProps[];
  defend: ICardItemProps[];
  transition: ICardItemProps[];
  powerPlays: ICardItemProps[];
  penaltyKilling: ICardItemProps[];
}

interface IUseGetPlayerCards {
  getPlayerCards: (playerInfo: IPlayerCardInfo | undefined, playerId: string) => ReactNode;
  getPlayerCardsConfig: (playerInfo: IPlayerCardInfo | undefined) => IGetPlayerCardsConfig;
}

export const useGetPlayerCards = (): IUseGetPlayerCards => {
  const { metrics } = useAppSelector(selectMetrics);
  const { t } = useTranslation();

  const getConfig = (group: IGroup | undefined, cardConfig: IPlayerCardConfig[]) => {
    if (metrics) {
      const cardItems: ICardItemProps[] = cardConfig.map(item => {
        return {
          label: t(item.label),
          name: metrics.players.byId[item.metricId]?.metricInfo.name,
          metricValue: getStatValueByMetricId(group?.stats, item.metricId),
          percent: getStatValueByMetricId(group?.percentiles, item.metricId),
          rank: getStatValueByMetricId(group?.percentiles, item.metricId),
          showCenter: true,
        };
      });

      return cardItems;
    }

    return [];
  };

  const getPlayerCardsConfig = (playerInfo: IPlayerCardInfo | undefined): IGetPlayerCardsConfig => {
    return {
      attack: getConfig(playerInfo?.group1, attackCardConfig),
      defend: getConfig(playerInfo?.group2, defendCardConfig),
      transition: getConfig(playerInfo?.group3, transitionCardConfig),
      powerPlays: getConfig(playerInfo?.group4, powerPlaysCardConfig),
      penaltyKilling: getConfig(playerInfo?.group5, penaltyKillingCardConfig),
    };
  };

  const getPlayerCards = (playerInfo: IPlayerCardInfo | undefined) => {
    const cardsConfig = getPlayerCardsConfig(playerInfo);

    return (
      <>
        <Card
          label={`${t(ITranslationKeys.attack)}`}
          items={cardsConfig.attack}
          percentile={playerInfo?.group1?.percentile ?? 0}
          toi={playerInfo?.group1?.stats?.toi}
          showLegend
        />
        <Card
          label={`${t(ITranslationKeys.defend)}`}
          items={cardsConfig.defend}
          percentile={playerInfo?.group2?.percentile ?? 0}
          toi={playerInfo?.group2?.stats?.toi}
          showLegend
        />
        <Card
          label={`${t(ITranslationKeys.transition)}`}
          items={cardsConfig.transition}
          percentile={playerInfo?.group3?.percentile ?? 0}
          toi={playerInfo?.group3?.stats?.toi}
          showLegend
        />
        <Card
          label={`${t(ITranslationKeys.powerPlays)}`}
          items={cardsConfig.powerPlays}
          percentile={playerInfo?.group4?.percentile ?? 0}
          toi={playerInfo?.group4?.stats?.toi}
          showLegend
          showToi
        />
        <Card
          label={`${t(ITranslationKeys.penaltyKilling)}`}
          items={cardsConfig.penaltyKilling}
          percentile={playerInfo?.group5?.percentile ?? 0}
          toi={playerInfo?.group5?.stats?.toi}
          showLegend
          showToi
        />
      </>
    );
  };

  return {
    getPlayerCards,
    getPlayerCardsConfig,
  };
};
