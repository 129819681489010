import { InputHTMLAttributes, forwardRef } from 'react';

import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import { IInputSharedProps } from '../types';
import './Input.styles.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement>, IInputSharedProps {
  /** Disable appearance for number input (arrows). */
  disableAppearance?: boolean;
  /** Enable default submitting of form on press enter at input. */
  enableOnEnterSubmit?: boolean;
}

const classNames = createClassNames('input');

/**
 * Default input element with styles and with possibility
 * to change styles through prop `variant` and `disableAppearance`.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      variant,
      error,
      disableAppearance,
      enableOnEnterSubmit = false,
      type = 'text',
      iconComponent,
      ...props
    },
    ref,
  ) => {
    const handleOnKeyDown = (event: any) => {
      if (enableOnEnterSubmit) {
        if (props.onKeyDown) {
          props.onKeyDown(event);
        }
      } else {
        if (event.key === 'Enter') {
          event.preventDefault();
        }

        if (props.onKeyDown) {
          props.onKeyDown(event);
        }
      }
    };

    return (
      <div
        className={classNames({
          ...transformObjectKeysToKebabCase({
            variant,
            disableAppearance,
            error,
            notIcon: !iconComponent,
          }),
        })}
      >
        {iconComponent}
        <input {...props} ref={ref} type={type} onKeyDown={handleOnKeyDown} />
      </div>
    );
  },
);
