import { FC, useEffect } from 'react';

import { IVideoControls } from '../../../types';
import { createClassNames, formatDateByLanguage, secondsToMinutesAndSeconds } from '../../../utils';
import { VideoRowActions } from '../VideoRowActions';
import { IVideoCenterActions } from '../types';
import './VideoCenterSelectItemContent.styles.scss';

export interface IVideoCenterSelectItemContentProps
  extends Partial<IVideoControls>,
    IVideoCenterActions {
  /** Vide clip's player jersey */
  jersey: string;
  /** Vide clip's player name */
  name: string;
  /** Vide clip's player surname */
  surname: string;
  /** Vide clip's game date */
  matchDate: string;
  /** Vide clip's home team shortcut */
  homeTeam?: string;
  /** Vide clip's away team shortcut */
  awayTeam?: string;
  /** Vide clip's action time */
  time?: number;
  /** Vide clip's action time end */
  timeEnd?: number;
  /** Should video play immediately after open modal. */
  shouldPlayImmediately?: boolean;
  /** Player will not be displayed. */
  hidePlayer?: boolean;
  /** Function that handles click on row */
  onClick?: () => void;
}

const classNames = createClassNames('video-center-select-item-content');

/** UI component for display info about game in CheckboxButton in VideoCenterSelectList components. */
export const VideoCenterSelectItemContent: FC<IVideoCenterSelectItemContentProps> = ({
  jersey,
  name,
  surname,
  matchDate,
  homeTeam,
  awayTeam,
  time,
  timeEnd,
  shouldPlayImmediately,
  hidePlayer = false,
  cutBefore,
  cutAfter,
  videoTime,
  isEdited,
  hasDataListIcon = false,
  dataListItemsCount,
  onPlay,
  onVideocoachNote,
  onCroppedTime,
  onClick,
}) => {
  useEffect(() => {
    if (shouldPlayImmediately) {
      onPlay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldPlayImmediately]);

  return (
    <div className={classNames({ pointer: !!onClick })} onClick={onClick}>
      {!hidePlayer ? (
        <p className={classNames('player')}>
          #{jersey} {surname} {name?.[0]}.
        </p>
      ) : (
        homeTeam &&
        awayTeam && (
          <p className={classNames('team-and-date', { 'hide-player': hidePlayer })}>
            {homeTeam} - {awayTeam}
          </p>
        )
      )}
      <div className={classNames('info')}>
        {homeTeam && awayTeam && !hidePlayer && (
          <p className={classNames('team-and-date')}>
            {homeTeam} - {awayTeam}
          </p>
        )}
        <p className={classNames('team-and-date')}>
          {formatDateByLanguage(new Date(matchDate), 'P')}
        </p>
        {time !== undefined && (
          <p className={classNames('time', { 'time-end': timeEnd !== undefined })}>
            {secondsToMinutesAndSeconds(time)}
            {timeEnd ? ` - ${secondsToMinutesAndSeconds(timeEnd)}` : ''}
          </p>
        )}
        <VideoRowActions
          videoTime={videoTime}
          cutBefore={cutBefore}
          cutAfter={cutAfter}
          isEdited={isEdited}
          onPlay={onPlay}
          onVideocoachNote={onVideocoachNote}
          onCroppedTime={onCroppedTime}
          hasDataListIcon={hasDataListIcon}
          dataListItemsCount={dataListItemsCount}
        />
      </div>
    </div>
  );
};
