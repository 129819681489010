import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useHandleOnSubmitWithValues } from '../../../../hooks';
import { useGetLogHokejPlayerQuery } from '../../../../services/api';
import { IHokejPlayerTrackingFilterForm, ISelectOption } from '../../../../types';
import {
  createClassNames,
  filterLogHokejPlayerData,
  getDefaultHokejPlayerTrackingFormValues,
} from '../../../../utils';
import './HokejPlayerPage.styles.scss';
import { HokejPlayerContent, HokejPlayerTrackingFilter } from './components';

const classNames = createClassNames('hokej-player-page');

const DEFAULT_HOKEJ_PLAYER_TRACKING_FILTER_FORM_VALUES = getDefaultHokejPlayerTrackingFormValues();

export const HokejPlayerPage = () => {
  const methods = useForm<IHokejPlayerTrackingFilterForm>({
    defaultValues: DEFAULT_HOKEJ_PLAYER_TRACKING_FILTER_FORM_VALUES,
    mode: 'onBlur',
  });

  const { submitValues, handleOnSubmit } = useHandleOnSubmitWithValues(
    methods,
    DEFAULT_HOKEJ_PLAYER_TRACKING_FILTER_FORM_VALUES,
  );
  const { dateFromTo } = submitValues;

  const { data, error, isFetching } = useGetLogHokejPlayerQuery(dateFromTo, {
    selectFromResult: result => ({
      ...result,
      data: filterLogHokejPlayerData(result.data, submitValues),
    }),
  });

  const playersOptions = useMemo(() => {
    if (!data) return [];

    return data
      .map<ISelectOption>(player => ({
        value: player.registrationNumber,
        label: `${player.surname} ${player.name}`,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [data]);

  return (
    <FormProvider {...methods}>
      <div className={classNames()}>
        <form noValidate>
          <HokejPlayerTrackingFilter onSubmit={handleOnSubmit} playersOptions={playersOptions} />
        </form>
        <HokejPlayerContent {...{ submitValues, data, error, isFetching }} />
      </div>
    </FormProvider>
  );
};
