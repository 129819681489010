import { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './ModalButton.styles.scss';

interface IModalButtonProps {
  /** Button label */
  label: ITranslationKeys | string;
  /** Called when user clicks on the button */
  onClick: () => void;
  /** When true, the button is disabled */
  disabled?: boolean;
  /** CSS Font weight rule */
  fontWeight?: CSSProperties['fontWeight'];
}

const classNames = createClassNames('modal-button');

/**
 * A component that shows IModalButton with styles.
 */
export const ModalButton: FC<IModalButtonProps> = ({
  label,
  fontWeight = 'normal',
  disabled = false,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <button
      className={classNames({
        ...transformObjectKeysToKebabCase({
          disabled,
        }),
      })}
      onClick={() => !disabled && onClick()}
      style={{ fontWeight }}
      disabled={disabled}
    >
      {t(label)}
    </button>
  );
};
