import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import {
  IHeatmap,
  IPassAndShotInfoRequestParams,
  IPassesAndShotsRequestParams,
  IShot,
  IShotInfo,
  IShotsRequestBody,
} from '../../types';
import {
  buildPath,
  defaultCatchErrorCallback,
  parseShotsData,
  urlBooleanParamResolver,
} from '../../utils';

interface IGetShotsParams extends IPassesAndShotsRequestParams {
  isFormation?: boolean;
  isAgainst?: boolean;
  playerUuid?: string;
  body?: IShotsRequestBody;
}

interface IGetHeatmapParams extends IPassesAndShotsRequestParams {
  isFormation?: boolean;
  isRelativeToLeague?: boolean;
  isRelativeToTeam?: boolean;
  playerUuid?: string;
  body?: IShotsRequestBody;
}

const getShotsRequestUrl = (params: Omit<IGetShotsParams, 'body'>): string => {
  const { competitionsUuids, teamUuid, isFormation, isAgainst, playerUuid } = params;
  const base = `/shot/${competitionsUuids}/${teamUuid}`;

  if (playerUuid) {
    return `${base}/goalkeeper/${playerUuid}`;
  }

  return buildPath(base, [
    urlBooleanParamResolver(isFormation, 'formation'),
    urlBooleanParamResolver(isAgainst, 'against'),
  ]);
};

const getHeatmapRequestUrl = (params: Omit<IGetHeatmapParams, 'body'>): string => {
  const {
    competitionsUuids,
    teamUuid,
    isFormation,
    isRelativeToLeague,
    isRelativeToTeam,
    playerUuid,
  } = params;
  const base = `/shot/${competitionsUuids}/${teamUuid}/heatmap`;

  if (playerUuid) {
    return `${base}/individual/${playerUuid}`;
  }

  return buildPath(base, [
    isFormation ? urlBooleanParamResolver(isFormation, 'formation') : 'team',
    urlBooleanParamResolver(isRelativeToLeague, 'relativeToLeague'),
    urlBooleanParamResolver(isRelativeToTeam, 'relativeToTeam'),
  ]);
};

/** Post requests */
export const getShots = createAsyncThunk<IShot[], IGetShotsParams>(
  'shots/getShots',
  async ({ competitionsUuids, teamUuid, isFormation, isAgainst, playerUuid, body }) => {
    const requestPath = getShotsRequestUrl({
      competitionsUuids,
      teamUuid,
      isFormation,
      isAgainst,
      playerUuid,
    });

    const shots: IShot[] = await axiosInstance
      .post(requestPath, body ?? {})
      .then(response => parseShotsData(response.data))
      .catch(defaultCatchErrorCallback);

    return shots;
  },
);

/** Get request */ // TODO: Otestovat
export const getShotInfo = createAsyncThunk<IShotInfo, IPassAndShotInfoRequestParams>(
  'shots/getShotInfo',
  async ({ match, player, time }) => {
    const shotInfo: IShotInfo = await axiosInstance
      .get('/shot', { params: { match, player, time: time.toString() } })
      .then(response => response.data)
      .catch(defaultCatchErrorCallback);

    return shotInfo;
  },
);

/** Heatmap post request*/
export const getHeatmap = createAsyncThunk<IHeatmap, IGetHeatmapParams>(
  'shots/getHeatmap',
  async ({
    competitionsUuids,
    teamUuid,
    isFormation,
    isRelativeToLeague,
    isRelativeToTeam,
    playerUuid,
    body,
  }) => {
    const requestPath = getHeatmapRequestUrl({
      competitionsUuids,
      teamUuid,
      isFormation,
      isRelativeToLeague,
      isRelativeToTeam,
      playerUuid,
    });

    const heatmap: IHeatmap = await axiosInstance
      .post(requestPath, body ?? {})
      .then(response => response.data)
      .catch(defaultCatchErrorCallback);

    return heatmap;
  },
);
