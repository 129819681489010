import { IGoalNetStatsRecord } from '../components';
import {
  ICompetitionDetail,
  IGoalStatsBoxProps,
  IGoalkeeper,
  IGoalkeeperRecord,
  IPlayerWithStatsDTO,
  IPlayerWithTeamIdAndStats,
  IStats,
  ITeamWithPlayersWithStatsDTO,
} from '../types';

export const parseGoalkeepersStatsData = (
  data: ITeamWithPlayersWithStatsDTO[] | IPlayerWithStatsDTO[],
  teamUuid?: string,
): IPlayerWithTeamIdAndStats[] => {
  if (teamUuid) {
    const typedData = data as IPlayerWithStatsDTO[];
    const newData: IPlayerWithTeamIdAndStats[] = typedData.map(playerData => ({
      id: playerData.player,
      teamId: teamUuid,
      stats: playerData.stats,
    }));
    return newData;
  }

  const typedData = data as ITeamWithPlayersWithStatsDTO[];
  const newData: IPlayerWithTeamIdAndStats[] = typedData
    .map(teamData => {
      const playersData: IPlayerWithTeamIdAndStats[] = teamData.players.map(playerData => ({
        id: playerData.player,
        teamId: teamData.team,
        stats: playerData.stats,
      }));
      return playersData;
    })
    .reduce((acc: IPlayerWithTeamIdAndStats[], playersArray) => acc.concat(playersArray), []);

  return newData;
};

export const filterSelectedTeamsGoalkeepers = (
  filteredParts: ICompetitionDetail[],
  byId: IGoalkeeperRecord,
  teamId: string,
) => {
  const goalkeepers = filteredParts
    .reduce<IGoalkeeper[]>((filteredGoalkeepers, part) => {
      const goalkeepersInTeamInFilteredParts = part.teamGoalkeepers[teamId]?.goalkeeperIds.map(
        goalkeeperId => byId[goalkeeperId],
      );

      return filteredGoalkeepers.concat(goalkeepersInTeamInFilteredParts);
    }, [])
    .filter((value, index, self) => self.indexOf(value) === index);
  return goalkeepers;
};

export const reduceGoalNetBoxMetrics = (
  goalNetBoxMetrics: string[],
  goalkeeperStats: IStats,
): IGoalStatsBoxProps[] =>
  goalNetBoxMetrics.reduce<IGoalStatsBoxProps[]>((acc, metric) => {
    const metricValue = goalkeeperStats[`${metric}.sv_percent`];
    const ga = goalkeeperStats[`${metric}.ga`];

    acc.push({
      items: [
        {
          value: metricValue.toString() + '%',
        },
        {
          value: ga.toString(),
        },
      ],
      isHorizontal: true,
    });

    return acc;
  }, []);

export const reduceStatsToGoalNetStatsRecord = (teamStats: IStats) =>
  Object.keys(teamStats).reduce<IGoalNetStatsRecord>((acc, key) => {
    if (teamStats)
      acc[key] = {
        keyName: key,
        value: teamStats[key],
        relativeValue: teamStats[key],
      };
    return acc;
  }, {});
