import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './CheckboxWithDescription.styles.scss';

export interface ICheckboxWithDescriptionProps {
  /**
   * Sets the checked property
   */
  checked: boolean;
  /**
   * Description label
   */
  description?: ITranslationKeys;
  /**
   * A callback function for handling the click event
   */
  onClick: () => void;
}

const classNames = createClassNames('checkbox-with-description');

/**
 * UI component for indicating data loading.
 */
export const CheckboxWithDescription: FC<ICheckboxWithDescriptionProps> = ({
  checked,
  description,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()} onClick={onClick}>
      <button
        className={classNames('button', {
          ...transformObjectKeysToKebabCase({
            checked: checked,
          }),
        })}
      ></button>
      {description ? t(description) : null}
    </div>
  );
};
