import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IWowyData, IWowyRequestBody } from '../../types';
import { buildPath, defaultCatchErrorCallback, urlBooleanParamResolver } from '../../utils';
import { parseWowyData } from './parseWowy';

interface IGetWowyParams {
  competitionsUuids: string[];
  playerUuid: string;
  teamUuid: string;
  body?: IWowyRequestBody;
  isPercentile?: boolean;
}

export const getWowy = createAsyncThunk<IWowyData, IGetWowyParams>(
  'wowy/getWowy',
  async ({ competitionsUuids, playerUuid, teamUuid, body, isPercentile }) => {
    const base = `/wowy/${competitionsUuids}/${playerUuid}/${teamUuid}`;
    const requestPath = buildPath(base, [urlBooleanParamResolver(isPercentile, 'percentile')]);

    const wowyData: IWowyData = await axiosInstance
      .post(requestPath, body ?? {})
      .then(response => parseWowyData(response.data.wowy))
      .catch(defaultCatchErrorCallback);

    return wowyData;
  },
);
