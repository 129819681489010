import { createClassNames } from '../../../../utils';

export const useFetchTrend = () => {};

const classNames = createClassNames('trend-content');

export const TrendContent = () => {
  return (
    <div className={classNames()} data-testid='teams-page__trend-content'>
      Trend Content
    </div>
  );
};
