import type { AppStartListening } from 'app/store';

import {
  differenciateEqualNames,
  fetchLastSeasonCompetitionsAndWait,
  fetchOtherSeasonsCompetitionsAndWait,
  getLastSeason,
} from '../../utils';
import { endLoadingAllInitialData, endLoadingMinimumInitialData } from '../navigation';
import { setSimilarPlayerNames } from '../players';
import { getSeasons } from './seasonsActions';
import { setLastSeasonId } from './seasonsSlice';

export const addSeasonsListeners = (startListening: AppStartListening) => {
  startListening({
    actionCreator: getSeasons.fulfilled,
    effect: async (action, listenerApi) => {
      const competitions = action.payload.competitions;
      const lastSeason = getLastSeason(action.payload.byId);

      listenerApi.dispatch(setLastSeasonId(lastSeason.id));

      await fetchLastSeasonCompetitionsAndWait(listenerApi, lastSeason, competitions);
      listenerApi.dispatch(endLoadingMinimumInitialData());
      await fetchOtherSeasonsCompetitionsAndWait(listenerApi, lastSeason, competitions);

      const playerRecord = listenerApi.getState().players.byId;
      const equalNames = differenciateEqualNames(Object.values(playerRecord));
      listenerApi.dispatch(setSimilarPlayerNames(equalNames));
      listenerApi.dispatch(endLoadingAllInitialData());
    },
  });
};
