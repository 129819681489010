import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  battleLostResultOption,
  battleResultOption,
  battleWonResultOption,
  controlledZoneCategoryOption,
  denialZoneExitResultOption,
  dumpinZoneCategoryOption,
  dumpoutZoneCategoryOption,
  gainZoneExitResultOption,
  goalZoneFinishOption,
  shotZoneFinishOption,
  successfullyZoneSuccessOption,
} from '../../../constants';
import {
  selectGames,
  setDumpInOutResult,
  setFilterByProp,
  setSelectedGames,
  setShotPlayerId,
  setZoneCategory,
  setZoneExitResult,
  setZoneFinish,
  setZoneSuccess,
} from '../../../features';
import { IMetricWithDatasetMetric } from '../../../types';

export const useSetZonesFilter = () => {
  const { games } = useAppSelector(selectGames);
  const dispatch = useAppDispatch();

  const setZonesFilterSetter = (stat: IMetricWithDatasetMetric, entityId?: string) => {
    dispatch(
      setSelectedGames(Object.values(games.byId).map(game => ({ value: game.id, label: game.id }))),
    );

    if (stat.metric.metricSubgroup.id === 'zamezeni_vstupu_do_pasma') {
      if (
        stat.metric.id.startsWith('end') &&
        !stat.metric.id.startsWith('endf') &&
        !stat.metric.id.startsWith('enda')
      ) {
        dispatch(setFilterByProp('blocker'));
      }

      if (stat.metric.id.startsWith('dibaw')) {
        dispatch(setZoneCategory(dumpinZoneCategoryOption));
        dispatch(setDumpInOutResult(battleLostResultOption));
        return;
      } else {
        dispatch(setZoneSuccess({ value: 'blocked', label: 'blocked' }));
      }
    }

    if (stat.metric.metricSubgroup.id === 'zamezeni_vystupu_z_pasma') {
      dispatch(setZoneExitResult(denialZoneExitResultOption));

      if (
        stat.metric.id.startsWith('exd') &&
        !stat.metric.id.startsWith('exdf') &&
        !stat.metric.id.startsWith('exda')
      ) {
        dispatch(setFilterByProp('blocker'));
        return;
      }
    }

    if (
      stat.metric.metricSubgroup.id === 'nahozeni' ||
      stat.metric.metricSubgroup.id === 'nahozeni_vedouci_ke_strele'
    ) {
      dispatch(setZoneCategory(dumpinZoneCategoryOption));
    } else if (
      stat.metric.metricSubgroup.id === 'vyhozeni' ||
      stat.metric.metricSubgroup.id === 'vyhozeni_vedouci_ke_strele'
    ) {
      dispatch(setZoneCategory(dumpoutZoneCategoryOption));
    } else {
      dispatch(setZoneCategory(controlledZoneCategoryOption));
    }

    if (stat.metric.id.includes('r_percent') || stat.metric.id.includes('rf_percent')) {
      dispatch(setZoneCategory({ value: 'all', label: 'all' }));
    }

    if (
      stat.metric.metricSubgroup.id === 'kontrolovane_vstupy_vedouci_ke_strele' ||
      stat.metric.metricSubgroup.id === 'nahozeni_vedouci_ke_strele' ||
      stat.metric.metricSubgroup.id === 'vyhozeni_vedouci_ke_strele'
    ) {
      dispatch(setZoneFinish(shotZoneFinishOption));
    }

    if (stat.metric.metricSubgroup.id === 'kontrolovane_vystupy_vedouci_ke_vstupu_do_pasma') {
      if (
        stat.metric.id.startsWith('exen') ||
        stat.metric.id.startsWith('exfen') ||
        stat.metric.id.startsWith('exaen')
      ) {
        dispatch(setZoneExitResult(gainZoneExitResultOption));
      } else {
        dispatch(setZoneFinish(shotZoneFinishOption));
      }
    }

    if (stat.metric.id.startsWith('enw') || stat.metric.id.startsWith('exw')) {
      dispatch(setZoneSuccess(successfullyZoneSuccessOption));
    }

    if (stat.metric.id.startsWith('enc') && !stat.metric.id.startsWith('encf')) {
      dispatch(setShotPlayerId(entityId));
    }

    if (
      stat.metric.id.startsWith('engf') ||
      stat.metric.id.startsWith('enfgf') ||
      stat.metric.id.startsWith('enaga') ||
      stat.metric.id.startsWith('digf') ||
      stat.metric.id.startsWith('difgf') ||
      stat.metric.id.startsWith('diaga')
    ) {
      dispatch(setZoneFinish(goalZoneFinishOption));
    }

    if (stat.metric.id.startsWith('dib') || stat.metric.id.startsWith('dob')) {
      dispatch(setDumpInOutResult(battleResultOption));
    }

    if (
      stat.metric.id.startsWith('dibw') ||
      stat.metric.id.startsWith('dibfw') ||
      stat.metric.id.startsWith('dobw') ||
      stat.metric.id.startsWith('dobfw') ||
      stat.metric.id.startsWith('dobaw')
    ) {
      dispatch(setDumpInOutResult(battleWonResultOption));
    }
  };

  return { setZonesFilterSetter };
};
