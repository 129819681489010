import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { memo, useState } from 'react';

import { ICommonTableProps } from '../../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../../utils';
import { TableHead } from '../../TableHead';
import { TableRow } from '../../TableRow';
import { useTableResize } from '../useTableResize';
import './CommonTable.styles.scss';

const classNames = createClassNames('common-table-component');

export const CommonTable = memo(
  <T extends {}>({
    data,
    columns,
    columnPinning,
    initialSorting,
    centerPartRef,
    cellsHeight,
    disableIsSmall = false,
    extraRowHeaderRender,
  }: ICommonTableProps<T>) => {
    const { leftPartRef, rightPartRef, leftPartWidth, rightPartWidth } = useTableResize();

    const [sorting, setSorting] = useState<SortingState>(initialSorting || []);

    const table = useReactTable({
      data,
      columns,
      state: {
        sorting,
        columnPinning,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
    });

    return (
      <div className={classNames()}>
        <table ref={leftPartRef} className={classNames('left-part')}>
          <TableHead
            headerGroup={table.getLeftHeaderGroups()}
            extraHeaderRow={extraRowHeaderRender?.('left')}
          />
          <tbody>
            {table.getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                row={row}
                cells={row.getLeftVisibleCells()}
                cellsHeight={cellsHeight}
              />
            ))}
          </tbody>
        </table>
        <div
          ref={centerPartRef}
          className={classNames('center-part', {
            ...transformObjectKeysToKebabCase({
              isSmall: !disableIsSmall && table.getRowModel().rows.length < 4,
            }),
          })}
          style={{
            paddingLeft: leftPartWidth,
            paddingRight: rightPartWidth,
          }}
        >
          <table className={classNames('center-part__table')}>
            <TableHead
              headerGroup={table.getCenterHeaderGroups()}
              tablePart={'center'}
              extraHeaderRow={extraRowHeaderRender?.('center')}
            />
            <tbody>
              {table.getRowModel().rows.map(row => (
                <TableRow
                  key={row.id}
                  row={row}
                  cells={row.getCenterVisibleCells()}
                  cellsHeight={cellsHeight}
                />
              ))}
            </tbody>
          </table>
        </div>
        <table ref={rightPartRef} className={classNames('right-part')}>
          <TableHead
            headerGroup={table.getRightHeaderGroups()}
            tablePart={'right'}
            extraHeaderRow={extraRowHeaderRender?.('right')}
          />
          <tbody>
            {table.getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                row={row}
                cells={row.getRightVisibleCells()}
                cellsHeight={cellsHeight}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  },
) as <T extends {}>(props: ICommonTableProps<T>) => JSX.Element;
