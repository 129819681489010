import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Button, CardItemList, Loading, PlayerDetails } from '../../../../components';
import { GOALKEEPER_CARDS_METRICS } from '../../../../constants';
import {
  filteredMainFilterDataSelector,
  getGoalkeeperCardInfo,
  getGoalkeepersStats,
  selectGoalkeepers,
  selectMainFilter,
  setActualLoadedGoalkeeper,
  setCompareGoalkeeperItems,
} from '../../../../features';
import {
  useHandleOnSubmit,
  useLocationPaths,
  usePrepareBaseRequestBody,
  useRefetchContent,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import {
  IGoalkeeperGoalStatsRequestBody,
  IMainFilterForm,
  INavigationContentKeys,
  INavigationPageKeys,
} from '../../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../../utils';
import { useGetGoalkeeperCards } from '../shared';
import './GoalkeeperCardContent.styles.scss';

export const useFetchGoalkeeperCard = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { selectedTeam, selectedGoalkeeper } = useAppSelector(selectMainFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { byId } = useAppSelector(selectGoalkeepers);
  const dispatch = useAppDispatch();

  const fetchGoalkeeperCard = (data: Partial<IMainFilterForm>) => {
    const goalkeeperId = selectedGoalkeeper?.value;

    if (goalkeeperId) {
      const isSelectionValid = filteredParts.length === 1 && selectedTeam && selectedGoalkeeper;
      const loadedGoalkeeperInfo = byId[goalkeeperId]?.goalkeeperInfos?.find(
        info => info.teamId === selectedTeam?.value,
      );
      const goalkeeperInfoNotLoadedOrNewPlayerSelected = !loadedGoalkeeperInfo;

      const { requestBodyBase } = prepareBaseRequestBody(data);
      const requestBody: IGoalkeeperGoalStatsRequestBody = {
        ...requestBodyBase,
        gameState: undefined,
        metrics: GOALKEEPER_CARDS_METRICS,
      };

      if (isSelectionValid) {
        if (goalkeeperInfoNotLoadedOrNewPlayerSelected) {
          dispatch(
            getGoalkeeperCardInfo({
              competitionUuid: filteredParts[0].id,
              teamUuid: selectedTeam.value,
              playerUuid: selectedGoalkeeper.value,
            }),
          );
          dispatch(
            getGoalkeepersStats({
              competitionsUuids: [filteredParts[0].id],
              teamUuid: selectedTeam.value,
              body: requestBody,
            }),
          );
          dispatch(
            getGoalkeepersStats({
              competitionsUuids: [filteredParts[0].id],
              teamUuid: selectedTeam.value,
              body: requestBody,
              isPercentile: true,
            }),
          );
        } else {
          dispatch(
            setActualLoadedGoalkeeper({
              id: byId[goalkeeperId].id,
              teamId: selectedTeam?.value,
            }),
          );
        }
      }
    }
  };

  return fetchGoalkeeperCard;
};

const classNames = createClassNames('goalkeeper-card-content');

export const GoalkeeperCardContent = () => {
  const { actualLoadedGoalkeeper, byId, compareGoalkeeperItems, isGoalkeeperInfoLoading } =
    useAppSelector(selectGoalkeepers);
  const { selectedTeam, selectedGoalkeeper } = useAppSelector(selectMainFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const dispatch = useAppDispatch();

  const { activeTab, navigate, search } = useLocationPaths();
  const { t } = useTranslation();
  const { getGoalkeeperCards, getGoalkeeperGoalNetStats } = useGetGoalkeeperCards();

  const fetchGoalkeeperCard = useFetchGoalkeeperCard();
  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGoalkeeperCard(values);
  });

  useRefetchContent({
    handleOnSubmit,
    isLoading: isGoalkeeperInfoLoading,
    disableActiveTabRefetch: true,
  });

  useEffect(() => {
    if (!isGoalkeeperInfoLoading) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleCompareWithOtherGoalkeeper = () => {
    navigate(INavigationPageKeys.goalkeepers, INavigationContentKeys.compareGoalkeepers, search);
    dispatch(
      setCompareGoalkeeperItems({
        compareGoalkeeperItems: {
          ...compareGoalkeeperItems[0],
          selectedGoalkeeperCompare: selectedGoalkeeper,
          actualCompareLoadedGoalkeeper: {
            id: actualLoadedGoalkeeper?.id,
            teamId: actualLoadedGoalkeeper?.teamId,
          },
        },
        itemKey: '0',
      }),
    );
  };

  const render = () => {
    const classNames = createClassNames('goalkeeper-card-content');

    if (!actualLoadedGoalkeeper?.id && filteredParts.length === 0) {
      return (
        <div className='content-info-box'>
          {t(ITranslationKeys.pleaseSelectOneSeasonAndOnePart)}
        </div>
      );
    }

    if (!actualLoadedGoalkeeper?.id && filteredParts.length > 1) {
      return (
        <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectOnlyOneSeason)}</div>
      );
    }

    if (isGoalkeeperInfoLoading) {
      return <Loading />;
    }

    if (!actualLoadedGoalkeeper && !(selectedTeam && selectedGoalkeeper)) {
      return (
        <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectTeamAndGoalkeeper)}</div>
      );
    }

    if (!actualLoadedGoalkeeper?.id) {
      return <div className='content-info-box'>{t(ITranslationKeys.youCanLoadData)}</div>;
    }

    const goalkeeper = byId[actualLoadedGoalkeeper.id];
    const goalkeeperInfo = goalkeeper?.goalkeeperInfos?.find(
      info => info.teamId === actualLoadedGoalkeeper.teamId,
    );
    const goalkeeperStats = Object.values(goalkeeper?.teamStats)?.find(
      stats => stats.id === actualLoadedGoalkeeper.teamId,
    )?.stats;
    const goalkeeperRelativeStats = Object.values(goalkeeper?.teamStats)?.find(
      stats => stats.id === actualLoadedGoalkeeper.teamId,
    )?.percentiles;

    if (!goalkeeperInfo?.stats) {
      if (actualLoadedGoalkeeper?.id === selectedGoalkeeper?.value) {
        toast.warn(t(ITranslationKeys.noGoalkeeperDataMessage), {
          toastId: 'noGoalkeeperDataMessage',
        });
      }

      return <div className='content-info-box'>{t(ITranslationKeys.youCanLoadData)}</div>;
    }

    return (
      <>
        <PlayerDetails
          player={goalkeeper}
          playerInfo={goalkeeperInfo}
          actions={
            <>
              <Button
                label={ITranslationKeys.compareWithOtherGoalkeeper}
                onClick={handleCompareWithOtherGoalkeeper}
              />
              <Button
                label={ITranslationKeys.downloadGoalkeeperCard}
                iconComponent={<DownloadIcon />}
                onClick={() => console.log('TODO: doplnit stažení')}
              />
            </>
          }
        />
        <div className={classNames('statistics')}>
          <CardItemList>
            {getGoalkeeperCards(goalkeeperInfo, actualLoadedGoalkeeper.id)}
          </CardItemList>
          <div className={classNames('statistics__goalkeeper-goal-nets')}>
            {goalkeeperStats &&
              goalkeeperRelativeStats &&
              getGoalkeeperGoalNetStats(goalkeeperStats, goalkeeperRelativeStats)}
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isLoading: isGoalkeeperInfoLoading,
        }),
      })}
      data-testid='goalkeepers-page__goalkeeper-card'
    >
      {render()}
    </div>
  );
};
