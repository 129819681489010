import { FC } from 'react';

import { Button } from '..';
import { ITranslationKeys } from '../../i18n/types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './PaginationControls.styles.scss';

interface IPaginationControls {
  pagination: number;
  pageCount: number;
  displaySeparationLine?: boolean;
  moveToPrevious: () => void;
  moveToNext: () => void;
}

const classNames = createClassNames('pagination-controls');

/**
 * PaginationControls UI component.
 */
export const PaginationControls: FC<IPaginationControls> = ({
  pagination,
  pageCount,
  displaySeparationLine = false,
  moveToNext,
  moveToPrevious,
}) => {
  const maxPaginationSize = pageCount === 0 ? 1 : pageCount;

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          displaySeparationLine,
        }),
      })}
    >
      <Button
        label={ITranslationKeys.previous}
        onClick={moveToPrevious}
        disabled={pagination < 2}
      />
      <span>
        {pagination} / {maxPaginationSize}
      </span>
      <Button
        label={ITranslationKeys.next}
        onClick={moveToNext}
        disabled={pagination === maxPaginationSize}
      />
    </div>
  );
};
