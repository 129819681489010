import { ITeamFormation, ITeamsFormation, ITeamsFormationDTO } from '../../types';

export const parseTeamsFormationData = (
  data: ITeamsFormationDTO[],
  teamUuid: string,
): ITeamsFormation => {
  const teamsFormation = data.map<ITeamFormation>(({ players, stats, team }) => ({
    playerIds: players,
    stats,
    teamId: team,
  }));

  return {
    teamId: teamUuid,
    teamsFormation,
  };
};
