import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { TranslationFunctionOptions } from '../../../types';

export interface ITranslatedColumnHeaderProps {
  label: ITranslationKeys;
  /** Translation options for label (e.g. plural, context). */
  labelTranslationsOptions?: TranslationFunctionOptions;
}

export const TranslatedColumnHeader: FC<ITranslatedColumnHeaderProps> = ({
  label,
  labelTranslationsOptions,
}) => {
  const { t } = useTranslation();

  return (
    <Trans t={t} {...labelTranslationsOptions}>
      {label}
    </Trans>
  );
};
