import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import {
  IGoalkeeperGoalStatsRequestBody,
  IGoalkeeperNetZoneCardPlayerStats,
  IPlayerWithTeamIdAndStats,
  IReturnTypeGetGoalkeeperCardInfoAction,
} from '../../types';
import { buildPath, defaultCatchErrorCallback, urlBooleanParamResolver } from '../../utils';
import {
  parseGoalkeepersNetZonesCardInfoData,
  parseGoalkeepersNetZonesStatsData,
} from './parseNetZonesGoalkeepers';

interface IGetCompetitionDetailParams {
  competitionsUuids: string[];
  teamUuid: string;
  playerUuid: string;
  isCalledFromCompareGoalkeepers?: boolean;
  goalkeeperIndex?: number;
  body?: IGoalkeeperGoalStatsRequestBody;
}

interface IGetGoalkeeperStatsParams extends Partial<IReturnTypeGetGoalkeeperCardInfoAction> {
  competitionsUuids: string[];
  teamUuid?: string;
  body?: IGoalkeeperGoalStatsRequestBody;
  isPercentile?: boolean;
  goalkeeperIndex?: number;
}

const getGoalkeeperStatsRequestUrl = (params: Omit<IGetGoalkeeperStatsParams, 'body'>): string => {
  const { competitionsUuids, teamUuid, isPercentile } = params;
  const base = teamUuid
    ? `/goalkeeper/${competitionsUuids}/${teamUuid}`
    : `/goalkeeper/${competitionsUuids}`;

  return buildPath(base, [urlBooleanParamResolver(isPercentile, 'percentile')]);
};

export const getGoalkeeperNetZonesCardInfo = createAsyncThunk<
  IGoalkeeperNetZoneCardPlayerStats,
  IGetCompetitionDetailParams
>(
  'goalkeeper/getGoalkeeperNetZonesCardInfo',
  async ({ competitionsUuids, teamUuid, playerUuid, body }) => {
    const goalkeeperInfo: IGoalkeeperNetZoneCardPlayerStats = await axiosInstance
      .post(`/goalkeeper/${competitionsUuids}/${teamUuid}`, body)
      .then(response => parseGoalkeepersNetZonesCardInfoData(response.data, playerUuid))
      .catch(defaultCatchErrorCallback);

    return goalkeeperInfo;
  },
);

export const getGoalkeepersNetZoneStats = createAsyncThunk<
  IPlayerWithTeamIdAndStats[],
  IGetGoalkeeperStatsParams
>(
  'goalkeeper/getGoalkeeperNetZonesStats',
  async ({ competitionsUuids, teamUuid, body, isPercentile }) => {
    const requestPath = getGoalkeeperStatsRequestUrl({ competitionsUuids, teamUuid, isPercentile });

    const goalkeepersStats = await axiosInstance
      .post(requestPath, body)
      .then(response => parseGoalkeepersNetZonesStatsData(response.data, teamUuid))
      .catch(defaultCatchErrorCallback);

    return goalkeepersStats;
  },
);
