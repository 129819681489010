import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useLocationPaths } from '../hooks';
import { ITabConfig } from '../types';
import { DataContentsWrapper } from './wrappers';

export interface IContentContainerProps {
  /** Config with information about tabs */
  config: ITabConfig[];
  /** Active page */
  activePage: string;
}

export const ContentRoutesContainer: FC<IContentContainerProps> = ({ config, activePage }) => {
  const { search } = useLocationPaths();

  return (
    <Routes>
      <Route element={<DataContentsWrapper config={config} />}>
        {config.map(({ id, ContentComponent }) => (
          <Route key={id} path={id} element={<ContentComponent />} />
        ))}
      </Route>
      <Route
        path='*'
        element={
          <Navigate
            to={{
              pathname: `/${activePage}`,
              search,
            }}
          />
        }
      />
    </Routes>
  );
};
