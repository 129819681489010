import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { Button, Caption, OpponentsController } from '../../../../../../components';
import { playersInChartOptions } from '../../../../../../constants';
import { selectTableFilter, setPlayersInChart } from '../../../../../../features';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { IMainFilterForm } from '../../../../../../types';
import { createClassNames, isFormValid } from '../../../../../../utils';
import './TogglePlayersSide.styles.scss';

const classNames = createClassNames('toggle-players-side');

export const TogglePlayersSide = () => {
  const { playersInChart } = useAppSelector(selectTableFilter);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPlayersInChart(playersInChart ?? playersInChartOptions[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { formState } = useFormContext<IMainFilterForm>();
  const { errors } = formState;
  const disabled = !isFormValid(errors);

  const teammateOptionActive = playersInChart?.value === playersInChartOptions[0].value;
  const opponentOptionActive = playersInChart?.value === playersInChartOptions[1].value;

  return (
    <>
      <div className={classNames()}>
        <Caption label={ITranslationKeys.playersInChart} />
        <Button
          key={playersInChartOptions[0].value}
          label={playersInChartOptions[0].label}
          onClick={
            !teammateOptionActive
              ? () => dispatch(setPlayersInChart(playersInChartOptions[0]))
              : undefined
          }
          variant={teammateOptionActive ? 'normal' : 'gray'}
          disabled={disabled}
          primary
        />
      </div>
      <Button
        key={playersInChartOptions[1].value}
        label={playersInChartOptions[1].label}
        onClick={
          !opponentOptionActive
            ? () => dispatch(setPlayersInChart(playersInChartOptions[1]))
            : undefined
        }
        variant={opponentOptionActive ? 'normal' : 'gray'}
        disabled={disabled}
        primary
      />
      {opponentOptionActive && <OpponentsController />}
    </>
  );
};
