import { ICoordinates, IPlaygroundShotOrPass, IShot } from '../types';
import { INumberRange } from '../types';

export const formatNumberValueForForm = (
  value: number | string | undefined | null,
): number | string => {
  if (value === undefined || value === null || typeof value === 'string' || isNaN(value)) {
    return '';
  }

  return Math.abs(value);
};

export const secondsToMinutesAndSeconds = (
  seconds: number,
  withZero: boolean = false,
  minutesToHours: boolean = false,
) => {
  const m = Math.floor(seconds / 60)
    .toString()
    .padStart(withZero ? 2 : 0, '0');
  const s = (seconds % 60).toString().padStart(2, '0');

  if (minutesToHours && Number(m) > 60) {
    const h = Math.floor(Number(m) / 60);

    return `${h}:${(Number(m) % 60).toString().padStart(2, '0')}:${s}`;
  }

  return `${m}:${s}`;
};

export const calculateVerticalPositionToPercent = (x: number) =>
  x <= 0 ? Math.ceil(x + 100) : Math.ceil(100 - x);

export const calculateHorizontalPositionToPercent = (x: number, y: number) => {
  const position = Math.ceil((y + 100) / 2);
  return x <= 0 ? position : 100 - position;
};

export const calculateShotOrPassPosition = (
  x: number,
  y: number,
  playgroundWidth: number = 760,
): ICoordinates => {
  const positionTop = calculateVerticalPositionToPercent(x);
  const positionLeft = calculateHorizontalPositionToPercent(x, y);
  const posX = Math.ceil((playgroundWidth * positionLeft) / 100);
  const posY = Math.ceil((playgroundWidth * positionTop) / 100);

  return {
    x: posX,
    y: posY,
  };
};

const normalizeAssistPosition = (x: number, y: number): ICoordinates => {
  const width = 760;
  const height = 582;
  const posX = x / width;
  const posY = y / height;

  return {
    x: posX,
    y: posY,
  };
};

export const calculateAssistPosition = (
  x: number,
  y: number,
  playgroundWidth: number,
  playgroundHeight: number,
): ICoordinates => {
  const { x: normalizedX, y: normalizedY } = normalizeAssistPosition(x, y);
  const posX = normalizedX * playgroundWidth;
  const posY = normalizedY * playgroundHeight;

  return {
    x: posX,
    y: posY,
  };
};

export const getAssistAngle = (assist: IPlaygroundShotOrPass): number => {
  const { x, y } =
    assist.shot === null || assist.shot === undefined
      ? calculateShotOrPassPosition(0, 61)
      : calculateShotOrPassPosition(assist.shot.x, assist.shot.y);

  const angle = Number((Math.atan2(assist.position.y - y, assist.position.x - x) * 180) / Math.PI);
  return angle - 180;
};

export const getNumberOfGoals = (shots: IShot[]) => {
  return shots.filter(shot => shot.type === 'G').length;
};

export const roundNumberTo2Decimals = (number: number): number => Math.round(number * 100) / 100;

/**
 * Create number ranges from 1 to total split by rangeSize.
 * @param total Total number (eg. 52)
 * @param rangeSize Size of the range (eg. 10)
 * @returns Array of number ranges (eg. from 1 to 52 split by 10)
 * @example [{ from: 1, to: 10 }, { from: 11, to: 20 }, ... , { from: 51, to: 52 }])
 */
export const createNumberRanges = (total: number, rangeSize: number): INumberRange[] => {
  const rangesCount = Math.ceil(total / rangeSize);
  const rest = total % rangeSize;
  const ranges: INumberRange[] = [];

  for (let i = 1; i <= rangesCount; i++) {
    const range = {
      from: 1 + rangeSize * (i - 1),
      to: rest > 0 && i === rangesCount ? rangeSize * (i - 1) + rest : rangeSize * i,
    };
    ranges.push(range);
  }

  return ranges;
};
