import {
  IReturnTypeGetSimilarPlayers,
  IReturnTypePlayerSimilarity,
  ISimilarPlayersDTO,
} from '../../types';

export const parseSimilarPlayersData = (
  data: ISimilarPlayersDTO,
  referencedPlayer?: {
    id: string;
    teamId: string;
  },
): IReturnTypeGetSimilarPlayers => {
  const topSimilarities = data.topMatches.map<IReturnTypePlayerSimilarity>(
    ({ player, rank: { metrics, ...rankRest }, stats, team }) => ({
      id: player,
      teamId: team,
      stats,
      rank: {
        ...rankRest,
        stats: metrics,
      },
    }),
  );

  const newData: IReturnTypeGetSimilarPlayers = {
    referencedValues: data.referencedValues,
    topSimilarities,
    referencedPlayer,
  };

  return newData;
};
