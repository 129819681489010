import { FC } from 'react';

import { createClassNames } from '../../utils';
import './PlusMinusBar.styles.scss';

interface IPlusMinusBarProps {
  /**
   * Percent value (number only) of width of the progress bar.
   */
  percent: number;
}

const classNames = createClassNames('plus-minus-bar');

/**
 * PlusMinusBar UI component.
 */
export const PlusMinusBar: FC<IPlusMinusBarProps> = ({ percent }) => {
  return (
    <div className={classNames()}>
      <p>+</p>
      <div className={classNames('progress')}>
        <div className={classNames('progress--inner')} style={{ width: `${percent}%` }}></div>
      </div>
      <p>-</p>
    </div>
  );
};
