import { ITranslationKeys } from '../../i18n/types';
import { ISelectOption } from '../../types';

const metricParametersAllOption: ISelectOption = {
  value: 'all',
  label: ITranslationKeys.all,
  labelTranslationsOptions: { context: 'female' },
};

export const shotLocationExtendedOptions: ISelectOption[] = [
  metricParametersAllOption,
  { value: 'slot', label: ITranslationKeys.slot },
  { value: 'sslot', label: ITranslationKeys.innerSlot },
];

export const shotTypeBaseOptions: ISelectOption[] = [
  metricParametersAllOption,
  { value: '1t', label: ITranslationKeys.oneTimer },
  { value: 'reb', label: ITranslationKeys.rebounds },
];

export const shotTypeOptions: ISelectOption[] = [
  ...shotTypeBaseOptions,
  { value: 's', label: ITranslationKeys.screeningPlayer },
  { value: 'd', label: ITranslationKeys.deflected },
];

export const shotDangerOptions: ISelectOption[] = [
  metricParametersAllOption,
  { value: 'hd', label: ITranslationKeys.high },
  { value: 'md', label: ITranslationKeys.medium },
  { value: 'ld', label: ITranslationKeys.low },
];

export const shotGameActionTypeOptions: ISelectOption[] = [
  metricParametersAllOption,
  { value: 'f', label: ITranslationKeys.forecheck },
  { value: 'r', label: ITranslationKeys.rush },
  { value: 'l', label: ITranslationKeys.cycle },
  { value: 'o', label: ITranslationKeys.oddManRush },
  { value: 'a', label: ITranslationKeys.fromFaceoff },
];

export const puckGainTypeOptions: ISelectOption[] = [
  metricParametersAllOption,
  { value: 'int', label: ITranslationKeys.captureOrBlock },
  { value: 'b', label: ITranslationKeys.inFight },
];

export const entryTypeOptions: ISelectOption[] = [
  metricParametersAllOption,
  { value: 'c', label: ITranslationKeys.carryIn },
  { value: 'p', label: ITranslationKeys.withPass },
];

export const exitTypeOptions: ISelectOption[] = [
  metricParametersAllOption,
  { value: 'c', label: ITranslationKeys.carryOut },
  { value: 'p', label: ITranslationKeys.withPass },
  { value: 'stp', label: ITranslationKeys.passBehindRedLine },
];

export const enterExitLocationOptions: ISelectOption[] = [
  metricParametersAllOption,
  { value: 'le', label: ITranslationKeys.left },
  { value: 'mi', label: ITranslationKeys.middle },
  { value: 'ri', label: ITranslationKeys.right },
];
