import { FC } from 'react';
import ReactApexChart from 'react-apexcharts';

import { IGameTimelineBase } from '../../types';
import { createClassNames } from '../../utils';
import './GameTimeline.styles.scss';
import { useGameTimelineConfig } from './useGameTimelineConfig';

const classNames = createClassNames('game-timeline');

export interface IGameTimelineProps extends IGameTimelineBase {
  height?: string | number;
  width?: string | number;
}

/** Game timeline chart component. */
export const GameTimeline: FC<IGameTimelineProps> = ({
  series,
  yMaxValue,
  maxTime,
  teamGoalsCoordinates,
  height = 480,
  width = '100%',
}) => {
  const options = useGameTimelineConfig(yMaxValue, maxTime, teamGoalsCoordinates);

  return (
    <div className={classNames()} data-testid='game-timeline'>
      <ReactApexChart options={options} series={series} type='line' height={height} width={width} />
    </div>
  );
};
