import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlayIcon } from '../../../icons';
import { IPlaygroundBarBox } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './PlaygroundBarBox.styles.scss';

export interface IPlaygroundBarBoxProps extends IPlaygroundBarBox {
  /** This is true when the whole playground component is small. */
  small?: boolean;
  /** Fired when user clicks on the attributes. */
  onClick?: () => void;
}

const classNames = createClassNames('playground-bar-box');

export const PlaygroundBarBox: FC<IPlaygroundBarBoxProps> = ({
  stats,
  color = 'green',
  small = false,
  label,
  onClick,
}) => {
  const { t } = useTranslation();
  const [showIcon, setShowIcon] = useState<boolean>(false);

  const statsLabels = stats.some(stat => stat.label);

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({ color, small, showLabels: !!statsLabels }),
      })}
      data-testid='playground-bar-box'
    >
      {label && <span className={classNames('heading')}>{t(label)}</span>}
      <table className={classNames('values')}>
        <tbody
          onClick={onClick}
          onMouseEnter={() => setShowIcon(true)}
          onMouseLeave={() => setShowIcon(false)}
        >
          {statsLabels && (
            <tr>
              {stats.map((stat, index) => (
                <th key={index}>{stat.label && t(stat.label)}</th>
              ))}
            </tr>
          )}
          <tr>
            {stats.map((stat, index) => (
              <td key={index}>{stat.value}</td>
            ))}
          </tr>
        </tbody>
      </table>
      {color === 'white' && showIcon && (
        <PlayIcon
          onMouseEnter={() => setShowIcon(true)}
          onMouseLeave={() => setShowIcon(false)}
          onClick={onClick}
        />
      )}
    </div>
  );
};
