import { METRIC_PARAMETERS_INFO } from '../constants';
import { ITranslationKeys } from '../i18n/types';
import {
  IDatasetMetric,
  IMetric,
  IMetricCategory,
  IMetricColumnsRecord,
  IMetricGroupBase,
  IMetricParameterInfo,
  IMetricSubgroup,
  IMetricsSectionToDisplay,
  ISelectOption,
} from '../types';

export const getMetricsSectionIds = (metrics: IMetric[]) =>
  metrics
    .map(metric => metric.metricSectionId)
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

export const getMetricsGroupsBase = (allMetrics: IMetricCategory): IMetricGroupBase[] => {
  const metricsArray = Object.values(allMetrics.byId);
  const datasetsGroupsIds = metricsArray
    .reduce<string[]>((acc, metric) => {
      const groupIds = Object.values(metric.metricGroups).map(group => group.id);
      return acc.concat(groupIds);
    }, [])
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

  const datasetsGroupsIdsAndNames = datasetsGroupsIds.reduce<IMetricGroupBase[]>((acc, id) => {
    const metric = metricsArray.find(metric => metric.metricGroups[id]);

    if (metric) {
      acc.push({
        id,
        groupName: metric.metricGroups[id].groupName,
        groupNameEN: metric.metricGroups[id].groupNameEN,
      });
    }

    return acc;
  }, []);

  return datasetsGroupsIdsAndNames;
};

export const getMetricsSubgroups = (metrics: IMetric[]): IMetricSubgroup[] => {
  const metricSubgroups = metrics
    .map(metric => metric.metricSubgroup)
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

  return metricSubgroups;
};

export const getMetricsInfoDataTypeIds = (metrics: IMetric[]) =>
  metrics
    .map(metric => metric.metricInfo.data_type)
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

export const getMetricsGroupOptions = (groups: IMetricGroupBase[]): ISelectOption[] =>
  groups.map(group => ({
    value: group.id,
    label: group.groupName,
  }));

export const getMetricsOptions = (
  metrics: IMetric[],
  metricsPrefix: ISelectOption | undefined,
  value: number | null,
): ISelectOption<IDatasetMetric>[] =>
  metrics.map(metric => {
    const parameter = !!metricsPrefix;
    const metricCode = parameter
      ? metricsPrefix.value + metric.metricInfo.code
      : metric.metricInfo.code;

    const metricLabel = parameter
      ? metricsPrefix.label + metric.metricInfo.name
      : metric.metricInfo.name;

    return {
      value: metricCode,
      label: metricLabel,
      additionalInfo: {
        code: metricCode,
        origin: metric.metricInfo.code,
        parameter,
        value,
      },
    };
  });

export const getMetricRowsOrder = (infoData: string) => {
  if (infoData === '60') {
    return 1;
  }

  if (infoData === 'percent') {
    return 2;
  }

  if (infoData === 'relative') {
    return 3;
  }

  return 0;
};

export const getMetricsColumns = (
  metrics: IMetric[],
  columnMetricsSubgroups: IMetricSubgroup[],
) => {
  const columns = columnMetricsSubgroups.reduce<IMetricColumnsRecord>(
    (accColumns, columnSubgroup, index) => {
      const filteredMetricsByCategory = metrics.filter(
        metric => metric.metricSubgroup.id === columnSubgroup.id,
      );

      accColumns[columnSubgroup.id] = {
        name: columnSubgroup.name,
        metrics: filteredMetricsByCategory,
        order: index,
      };

      return accColumns;
    },
    {},
  );

  return columns;
};

export const getMetricsAvailableColumnsNames = (section: IMetricsSectionToDisplay) => {
  const allNames = Object.values(section.rows).reduce<string[]>((acc, row) => {
    const names = Object.values(row.columns).map(column => column.name);
    acc.push(...names);

    return acc;
  }, []);

  return allNames.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
};

export const getMetricRowName = (name: string) => {
  if (name === '60') {
    return ITranslationKeys.timeRecalculatedValues;
  }

  if (name === 'percent') {
    return ITranslationKeys.equityAndDerivedValues;
  }

  if (name === 'relative') {
    return ITranslationKeys.relativeToTeam;
  }

  return undefined;
};

const getPrefixFromDatasetMetric = (datasetMetric: IDatasetMetric): string => {
  const { code, origin } = datasetMetric;

  return code.substring(0, code.lastIndexOf(origin));
};

const getParametersInfoFromPrefix = (allPrefixesValue: string) => {
  const prefixes = allPrefixesValue.match(/([a-zA-Z0-9]+\.)|[^.]+$/g) || [allPrefixesValue];

  const prefixesParameterInfo = prefixes.reduce<IMetricParameterInfo[]>((acc, prefix) => {
    const prefixLabel = Object.values(METRIC_PARAMETERS_INFO).find(
      param => param.prefix === prefix,
    );
    if (prefixLabel) {
      acc.push(prefixLabel);
    }

    return acc;
  }, []);

  return prefixesParameterInfo;
};

export const getMetricLabel = (
  metric: IMetric | undefined,
  datasetMetric: IDatasetMetric,
): string => {
  if (!metric) return '';

  const { name } = metric.metricInfo;
  if (datasetMetric.parameter) {
    const allPrefixesValue = getPrefixFromDatasetMetric(datasetMetric);
    const parametersInfo = getParametersInfoFromPrefix(allPrefixesValue);
    const prefixesLabels = parametersInfo.map(param => param.prefixLabel);

    return prefixesLabels.join('') + name;
  }

  return name;
};

export const getMetricParametersInfo = (
  datasetMetric: IDatasetMetric,
): IMetricParameterInfo[] | undefined => {
  if (datasetMetric.parameter) {
    const allPrefixesValue = getPrefixFromDatasetMetric(datasetMetric);
    return getParametersInfoFromPrefix(allPrefixesValue);
  }

  return undefined;
};
