import { ColumnPinningState, SortingState, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  BasicColumnCell,
  BasicColumnHeader,
  EyeDropdown,
  ImageColumn,
  Sticker,
  TranslatedColumnHeader,
} from '../../../../../../components';
import { logosPaths } from '../../../../../../constants';
import { useFilteredMetricsForTable } from '../../../../../../hooks';
import { ITranslationKeys } from '../../../../../../i18n/types';
import {
  IColumnsConfig,
  IEntity,
  IGameTeamsTableData,
  INavigationContentKeys,
  INavigationPageKeys,
  IUrlFilterParams,
} from '../../../../../../types';
import { createDynamicStatsColumnsDef, secondsToMinutesAndSeconds } from '../../../../../../utils';

const columnHelper = createColumnHelper<IGameTeamsTableData>();

const columnPinning: ColumnPinningState = {
  left: ['actions', 'teamId', 'teamName', 'toi'],
};

const initialSorting: SortingState = [
  {
    id: 'toi',
    desc: true,
  },
];

const fixedColumns = [
  columnHelper.display({
    id: 'actions',
    cell: props => {
      const filterParams: IUrlFilterParams[] = [
        {
          name: 'selectedTeam',
          value: props.row.original.teamId,
        },
      ];

      return (
        <EyeDropdown
          eyeDropdownTooltipItems={[
            {
              pageTranslationLabel: ITranslationKeys.gamelog,
              navigationPageKey: INavigationPageKeys.teams,
              navigationTabKey: INavigationContentKeys.gamelog,
              filterParams,
            },
            // // TODO: Add teams trend after implementing it
            // {
            //   pageTranslationLabel: ITranslationKeys.trend,
            //   navigationPageKey: INavigationPageKeys.teams,
            //   navigationTabKey: INavigationContentKeys.trend,
            //   filterParams,
            // },
          ]}
        />
      );
    },
  }),
  columnHelper.accessor('teamId', {
    header: '',
    cell: props => <ImageColumn src={logosPaths[props.getValue()]} alt='team-logo' />,
  }),
  columnHelper.accessor('teamName', {
    header: () => <TranslatedColumnHeader label={ITranslationKeys.teams} />,
    cell: props => (
      <span style={{ display: 'flex' }}>
        <Sticker
          label={props.getValue()}
          color={props.row.original.homeAway === 'away' ? 'orange' : 'violet'}
        />
      </span>
    ),
  }),
  columnHelper.accessor('toi', {
    header: props => <BasicColumnHeader {...props}>TOI</BasicColumnHeader>,
    cell: props => (
      <BasicColumnCell {...props}>{secondsToMinutesAndSeconds(props.getValue())}</BasicColumnCell>
    ),
  }),
];

export const useColumnsConfig = (
  centerPartWidth: number | undefined,
): IColumnsConfig<IGameTeamsTableData> => {
  const getFilteredMetrics = useFilteredMetricsForTable();

  const columns = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(IEntity.teams);
    const dynamicColumns = createDynamicStatsColumnsDef(
      filteredMetrics,
      columnHelper,
      undefined,
      centerPartWidth,
    );

    return fixedColumns.concat(dynamicColumns);
  }, [centerPartWidth, getFilteredMetrics]);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};
