import { HeaderGroup, flexRender } from '@tanstack/react-table';
import { Fragment, ReactNode } from 'react';

import { ITablePart } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './TableHead.styles.scss';

export interface ITableHeadProps<T extends {}> {
  headerGroup: HeaderGroup<T>[];
  tablePart?: ITablePart;
  variant?: 'default' | 'tcms';
  extraHeaderRow?: ReactNode;
}

const classNames = createClassNames('table-head');

export const TableHead = <T extends {}>({
  headerGroup,
  tablePart,
  variant = 'default',
  extraHeaderRow,
}: ITableHeadProps<T>) => (
  <thead
    className={classNames({
      ...transformObjectKeysToKebabCase({
        tablePart,
        variant,
      }),
    })}
  >
    {headerGroup.map(headerGroup => (
      <Fragment key={headerGroup.id}>
        <tr key={headerGroup.id}>
          {headerGroup.headers.map(header => (
            <th key={header.id}>
              {header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.header, header.getContext())}
            </th>
          ))}
        </tr>
        {extraHeaderRow}
      </Fragment>
    ))}
  </thead>
);
