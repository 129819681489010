import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  selectMainFilter,
  setGameTimeFrom,
  setGameTimeTo,
  setLastPlayedMatches,
  setTimeOnIce,
} from '../features';
import { IBaseRequestBody, IGamesRequestBody, IMainFilterForm } from '../types';
import {
  formatNumberValueForForm,
  normalizeNumberValue,
  normalizeOpponentTeams,
  normalizeSelectWithAllValues,
  normalizeTimeValues,
} from '../utils';

interface IPrepareBaseRequestBody {
  requestBodyGames: IGamesRequestBody;
  requestBodyBase: IBaseRequestBody;
}

export const usePrepareBaseRequestBody = () => {
  const { selectedOpponents, dateFromTo, countOfPlayers, scoreState, place } =
    useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const prepareBaseRequestBody = (data: Partial<IMainFilterForm>): IPrepareBaseRequestBody => {
    const { gameTimeFrom, gameTimeTo, lastPlayedMatches, timeOnIce } = data;

    dispatch(setGameTimeFrom(formatNumberValueForForm(gameTimeFrom)));
    dispatch(setGameTimeTo(formatNumberValueForForm(gameTimeTo)));
    dispatch(setLastPlayedMatches(formatNumberValueForForm(lastPlayedMatches)));
    dispatch(setTimeOnIce(formatNumberValueForForm(timeOnIce)));

    const dateRange =
      dateFromTo && dateFromTo.from && !dateFromTo.to
        ? { ...dateFromTo, to: dateFromTo.from }
        : dateFromTo;

    const requestBodyGames: IGamesRequestBody = {
      dateFrom: dateRange?.from,
      dateTo: dateRange?.to,
      lastPlayedMatches: normalizeNumberValue(lastPlayedMatches),
    };

    const requestBodyBase: IBaseRequestBody = {
      ...requestBodyGames,
      gameState: normalizeSelectWithAllValues(countOfPlayers),
      scoreState: normalizeSelectWithAllValues(scoreState),
      place: normalizeSelectWithAllValues(place),
      opponentTeams: normalizeOpponentTeams(selectedOpponents),
      timeFrom: normalizeTimeValues(gameTimeFrom),
      timeTo: normalizeTimeValues(gameTimeTo),
      timeOnIce: normalizeTimeValues(timeOnIce),
    };

    return {
      requestBodyGames,
      requestBodyBase,
    };
  };

  return prepareBaseRequestBody;
};
