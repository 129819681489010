import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OverviewCard, Skeleton, TcmsTable } from '../../../../../../components';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { useGetLogHokejPlayerQuery } from '../../../../../../services/api';
import { createClassNames, getLogHokejPlayerTotals } from '../../../../../../utils';
import { useColumnsConfigHokejPlayer } from '../../../hooks';
import { IOverviewComponentsProps } from '../types';
import './ListHokejPlayer.styles.scss';
import { useDataForTable } from './useDataForTable';

interface IListHokejPlayerProps extends IOverviewComponentsProps {}

const classNames = createClassNames('list-hokej-player');

export const ListHokejPlayer: FC<IListHokejPlayerProps> = ({ submitValues }) => {
  const { dateFromTo } = submitValues;
  const { data, error, isFetching } = useGetLogHokejPlayerQuery(dateFromTo);

  const { t } = useTranslation();
  const { columns, initialSorting } = useColumnsConfigHokejPlayer(true);
  const dataForTable = useDataForTable(data);

  if (isFetching) {
    return (
      <div className={classNames()}>
        <Skeleton />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={classNames({ error: true })}>{t(ITranslationKeys.defaultErrorMessage)}</div>
    );
  }

  const { notificationCount, openedNotificationCount } = getLogHokejPlayerTotals(data);
  const clickthroughRate = (openedNotificationCount / notificationCount) * 100;

  return (
    <div className={classNames()}>
      <div className={classNames('overview-card-list')}>
        <OverviewCard
          label={ITranslationKeys.registeredPlayers}
          value={data.length.toString()}
          variant='gradient'
        />
        <OverviewCard
          label={ITranslationKeys.sentLinks}
          value={notificationCount.toString()}
          variant='gradient'
        />
        <OverviewCard
          label={ITranslationKeys.clickthroughRate}
          value={isNaN(clickthroughRate) ? '0%' : clickthroughRate.toFixed(1) + '%'}
          variant='gradient'
        />
      </div>
      <TcmsTable
        data={dataForTable}
        {...{
          columns,
          initialSorting,
        }}
        showHeader={false}
        disableCellLine
      />
    </div>
  );
};
