import { IPlayerWithStatsDTO, IStats, ITrend, ITrendDTO } from '../../types';

export const parseTrendPlayerData = (data: ITrendDTO[]): ITrend[] =>
  data.map<ITrend>(({ match, awayTeam, homeTeam, ...rest }) => ({
    gameId: match,
    awayTeamId: awayTeam,
    homeTeamId: homeTeam,
    ...rest,
  }));

export const parseTrendFormationData = (data: ITrendDTO[]): ITrend[] =>
  data.map<ITrend>(({ match, awayTeam, homeTeam, ...rest }) => ({
    gameId: match,
    awayTeamId: awayTeam,
    homeTeamId: homeTeam,
    ...rest,
  }));

export const parseTimePeriodPlayerStatsData = (
  data: IPlayerWithStatsDTO[],
  playerUuid: string,
): IStats | undefined => data.find(player => player.player === playerUuid)?.stats;
