import { createSelector } from '@reduxjs/toolkit';

import { ISelectOption } from '../../../types';
import { countAllGamesTotalToi, filterGames } from '../../../utils';
import {
  selectGames,
  selectMetricParamsFilter,
  selectShots,
  selectVideomapsFilter,
} from '../../selectors';

const getMetricByShotCategory = (shotCategory: ISelectOption, isIndividual?: boolean) => {
  if (isIndividual) {
    if (shotCategory.value === 'SG') {
      return 'sog60';
    }

    if (shotCategory.value === 'G') {
      return 'g60';
    }

    return 'c60';
  }

  if (shotCategory.value === 'SG') {
    return 'sogf60';
  }

  if (shotCategory.value === 'G') {
    return 'gf60';
  }

  return 'cf60';
};

export const filteredGamesSelector = createSelector(
  [selectVideomapsFilter, selectGames],
  (videomapsFilterState, { games }) => filterGames(videomapsFilterState.selectedGames, games.byId),
);

export const filteredGamesTotalToiSelector = createSelector(
  [filteredGamesSelector],
  filteredGames => countAllGamesTotalToi(filteredGames),
);

export const filteredHeatmapByEntitySelector = createSelector(
  [selectVideomapsFilter, selectShots],
  (videomapsFilterState, shotsState) => {
    const { heatmap } = shotsState;
    const { entity } = videomapsFilterState;

    if (entity.value === 'team') return heatmap?.for;

    if (entity.value === 'opponent') return heatmap?.against;

    return heatmap?.for; // TODO: change to player data
  },
);

export const heatmapMetricSelector = createSelector(
  [selectVideomapsFilter, selectMetricParamsFilter],
  (videomapsFilterState, metricParamsFilterState) => {
    const { entity, shotCategory, comparisonType } = videomapsFilterState;
    const { gameActionType } = metricParamsFilterState;

    if (comparisonType?.value === 'chance') {
      if (entity.value === 'player') {
        return 'gxg60'; // TODO: z API chodí většinou jako 0 pro všechny střěly
      }

      return 'gfxgf60'; // TODO: z API nechodí data pro gfxgf60 v kombinaci s action type
    }

    if (gameActionType.value === 'all') {
      return getMetricByShotCategory(shotCategory, entity.value === 'player');
    }

    const actionTypePrefix = gameActionType.value.charAt(0);
    const category = getMetricByShotCategory(shotCategory, entity.value === 'player');

    return `${actionTypePrefix}.${category}`;
  },
);

export const filteredVideomapsFilterDataSelector = createSelector(
  [
    filteredGamesSelector,
    filteredGamesTotalToiSelector,
    filteredHeatmapByEntitySelector,
    heatmapMetricSelector,
  ],
  (filteredGames, filteredGamesTotalToi, filteredHeatmapByEntity, heatmapMetric) => {
    return {
      filteredGames,
      filteredGamesTotalToi,
      filteredHeatmapByEntity,
      heatmapMetric,
    };
  },
);
