import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { goalkeeperSavesOptions, stickGripToggleOptions } from '../../../constants';
import { IGoalkeepersFilterState, ISelectOption } from '../../../types';
import { getDefaultPredefinedOptionByValue } from '../../../utils';

const initialState: IGoalkeepersFilterState = {
  saves: getDefaultPredefinedOptionByValue(goalkeeperSavesOptions),
  stickGrip: getDefaultPredefinedOptionByValue(stickGripToggleOptions),
};

export const goalkeepersFilterSlice = createSlice({
  name: 'goalkeepersFilter',
  initialState,
  reducers: {
    setGoalkeeperSaves: (state, action: PayloadAction<ISelectOption>) => {
      state.saves = action.payload;
    },
    setGoalkeeperStickGrip: (state, action: PayloadAction<ISelectOption>) => {
      state.stickGrip = action.payload;
    },
    resetGoalkeepersFilter: (
      _,
      action: PayloadAction<Partial<IGoalkeepersFilterState> | undefined>,
    ) => ({
      ...initialState,
      ...action.payload,
    }),
  },
});

export const { setGoalkeeperSaves, setGoalkeeperStickGrip, resetGoalkeepersFilter } =
  goalkeepersFilterSlice.actions;
