import { IShootout, IShootoutDTO } from '../../types';

export const parseShootoutData = (data: IShootoutDTO[]): IShootout[] => {
  return data.reduce<IShootout[]>((acc, shootout) => {
    const { goalkeeper, skater, match, ...restData } = shootout;

    acc.push({
      matchId: match,
      goalkeeper: {
        playerId: goalkeeper.player,
        teamId: goalkeeper.team,
      },
      skater: {
        playerId: skater.player,
        teamId: skater.team,
      },
      ...restData,
    });
    return acc;
  }, []);
};
