import { teamAllOption } from '../constants';
import {
  ICompetitionDetail,
  ICompetitionsDetailRecord,
  IGoalkeeper,
  IGoalkeeperRecord,
  IPlayer,
  IPlayerRecord,
  ISelectOption,
  ITeam,
  ITeamRecord,
} from '../types';
import { teamsToOptions } from './common.utils';
import { filterAllTeamPlayersByParts } from './filter.utils';
import { filterSelectedTeamsGoalkeepers } from './goalkeepers.utils';

export const filterParts = (
  byId: ICompetitionsDetailRecord,
  selectedSeasonsIds: string[],
  selectedPartsIds: string[],
) => {
  const filteredParts = Object.values(byId)
    .filter(competition => !!selectedSeasonsIds.find(season => season === competition.seasonId))
    .filter(competition => !!selectedPartsIds.find(part => part === competition.part));

  return filteredParts;
};

export const filterTeams = (byId: ITeamRecord, filteredParts: ICompetitionDetail[]) => {
  const filteredTeams = filteredParts
    .reduce<ITeam[]>((filteredTeams, competition) => {
      const teamsInCompetition = Object.keys(competition.teamPlayers).map(teamId => byId[teamId]);
      return filteredTeams.concat(teamsInCompetition);
    }, [])
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

  return filteredTeams;
};

export const filterTeamsToOptions = (teams: ITeam[]) => {
  const filteredTeamsOptions: ISelectOption[] = teamsToOptions(teams, 'name');

  filteredTeamsOptions.unshift(teamAllOption);

  return filteredTeamsOptions;
};

export const filterPlayers = (
  byId: IPlayerRecord,
  filteredParts: ICompetitionDetail[],
  selectedTeamId: string | undefined,
) => {
  if (!selectedTeamId) {
    return [];
  }

  if (selectedTeamId === 'all') {
    return filterAllTeamPlayersByParts(byId, filteredParts);
  }

  const filteredPlayers = filteredParts
    .reduce<IPlayer[]>((filteredPlayers, part) => {
      const playersInTeamInFilteredParts = part.teamPlayers[selectedTeamId]?.playerIds.map(
        playerId => byId[playerId],
      );

      return filteredPlayers.concat(playersInTeamInFilteredParts);
    }, [])
    .filter((value, index, self) => self.indexOf(value) === index)
    .filter(player => !!player);

  return filteredPlayers;
};

export const filterPlayersToOptions = (players: IPlayer[]) => {
  const filteredPlayersOptions: ISelectOption[] =
    players.length > 0
      ? players
          .map(player => {
            return {
              value: player?.id,
              label: `${player?.surname} ${player?.name}`,
            };
          })
          .sort((a, b) => {
            const labelA = a.label.toLowerCase();
            const labelB = b.label.toLowerCase();
            return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
          })
      : [];

  return filteredPlayersOptions;
};

export const filterGoalkeepers = (
  byId: IGoalkeeperRecord,
  filteredParts: ICompetitionDetail[],
  selectedTeamId: string | undefined,
) => {
  if (!selectedTeamId) {
    return [];
  }

  if (selectedTeamId === 'all') {
    const filteredGoalkeepers = filteredParts
      .reduce<IGoalkeeper[]>((filteredGoalkeepers, part) => {
        const goalkeepersInTeamInFilteredParts: IGoalkeeper[] = [];

        Object.values(part.teamGoalkeepers).forEach(team => {
          goalkeepersInTeamInFilteredParts.push(
            ...team.goalkeeperIds.map(goalkeeperId => byId[goalkeeperId]),
          );
        });

        return filteredGoalkeepers.concat(goalkeepersInTeamInFilteredParts);
      }, [])
      .filter((value, index, self) => self.indexOf(value) === index);

    return filteredGoalkeepers;
  }

  return filterSelectedTeamsGoalkeepers(filteredParts, byId, selectedTeamId);
};

export const filterGoalkeepersToOptions = (goalkeepers: IGoalkeeper[]) => {
  const filteredGoalkeepersOptions: ISelectOption[] =
    goalkeepers.length > 0
      ? goalkeepers
          .map(goalkeeper => {
            return {
              value: goalkeeper?.id,
              label: `${goalkeeper?.surname} ${goalkeeper?.name}`,
            };
          })
          .sort((a, b) => {
            const labelA = a.label.toLowerCase();
            const labelB = b.label.toLowerCase();
            return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
          })
      : [];

  return filteredGoalkeepersOptions;
};
