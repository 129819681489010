import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  changeUserDatasetMetrics,
  changeUserDatasetName,
  selectDataSettingsFilter,
  selectUserDatasets,
} from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { CloseBlueIcon } from '../../../icons';
import { IDatasetModalContentProps, ISelectOption } from '../../../types';
import {
  createClassNames,
  createUserDatasetMetricRecordFromSelected,
  createUserDatasetOptions,
} from '../../../utils';
import { Button } from '../../Button';
import { Caption } from '../../Caption';
import { Input } from '../../Input';
import { SelectInput } from '../../SelectInput';
import './ChangeDataset.styles.scss';

export interface IChangeDatasetProps extends IDatasetModalContentProps {}

const classNames = createClassNames('change-dataset');

export const ChangeDataset: FC<IChangeDatasetProps> = ({ entity, onClose }) => {
  const { selectedMetrics } = useAppSelector(selectDataSettingsFilter);
  const userDatasets = useAppSelector(selectUserDatasets);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [newDatasetName, setNewDatasetName] = useState<string>('');
  const [newDatasetNameError, setNewDatasetNameError] = useState(false);
  const [selectedUserDataset, setSelectedUserDataset] = useState<ISelectOption | undefined>(
    undefined,
  );

  const userDatasetOptions = useMemo(
    () => createUserDatasetOptions(userDatasets.byId, entity),
    [userDatasets.byId, entity],
  );

  const handleOnChangeDatasetForEdit = (value: ISelectOption) => {
    setSelectedUserDataset(value);
    setNewDatasetName(value.label);
  };

  const handleOnChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNewDatasetName(value);
    setNewDatasetNameError(value === '' || value.length > 80);
  };

  const handleOnSubmitChangeName = () => {
    if (selectedUserDataset) {
      dispatch(
        changeUserDatasetName({
          datasetId: selectedUserDataset.value,
          newName: newDatasetName,
        }),
      )
        .unwrap()
        .then(() => {
          toast.success(t(ITranslationKeys.datasetChangedSuccessfully), {
            toastId: ITranslationKeys.datasetChangedSuccessfully,
          });
          onClose();
        })
        .catch(error => {
          toast.error(t(ITranslationKeys.datasetSaveFailed), {
            toastId: ITranslationKeys.datasetSaveFailed,
          });
          console.error('[ChangeDataset]: Change name user dataset error:', error);
        });
    }
  };

  const handleOnSubmitChangeMetrics = () => {
    if (selectedUserDataset) {
      const newDatasetMetrics = createUserDatasetMetricRecordFromSelected(selectedMetrics);

      dispatch(
        changeUserDatasetMetrics({
          datasetId: selectedUserDataset.value,
          newDatasetMetrics,
          entity,
        }),
      )
        .unwrap()
        .then(() => {
          toast.success(t(ITranslationKeys.datasetChangedSuccessfully), {
            toastId: ITranslationKeys.datasetChangedSuccessfully,
          });
          onClose();
        })
        .catch(error => {
          toast.error(t(ITranslationKeys.datasetSaveFailed), {
            toastId: ITranslationKeys.datasetSaveFailed,
          });
          console.error('[ChangeDataset]: Change user dataset metrics error:', error);
        });
    }
  };

  return (
    <div className={classNames()}>
      <div className={classNames('header')}>
        <span>{t(ITranslationKeys.editDataset)}</span>
        <CloseBlueIcon className={classNames('close')} onClick={onClose} />
      </div>
      <div className={classNames('content')}>
        {t(ITranslationKeys.selectDatasetToEdit)}
        <div>
          <Caption label={ITranslationKeys.dataTemplates} />
          <SelectInput
            onChange={handleOnChangeDatasetForEdit}
            selected={selectedUserDataset}
            options={userDatasetOptions}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='filter'
          />
        </div>
        <div className={classNames('content__rename-box', { hide: !selectedUserDataset })}>
          <div>
            <Caption label={ITranslationKeys.newDatasetName} />
            <Input
              value={newDatasetName}
              onChange={handleOnChangeName}
              placeholder={t(ITranslationKeys.name).toString()}
              variant='filter'
              error={newDatasetNameError}
            />
          </div>
          <Button
            size='small'
            label={ITranslationKeys.rename}
            onClick={handleOnSubmitChangeName}
            disabled={
              newDatasetName === '' ||
              newDatasetNameError ||
              newDatasetName === selectedUserDataset?.label
            }
          />
          <Button
            size='small'
            label={ITranslationKeys.saveNewDatasetMetrics}
            onClick={handleOnSubmitChangeMetrics}
            disabled={newDatasetName === '' || newDatasetNameError}
          />
        </div>
      </div>
    </div>
  );
};
