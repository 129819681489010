import { createSlice } from '@reduxjs/toolkit';

import { IPassesState } from '../../types';
import { getPasses } from './passesActions';

const initialState: IPassesState = {
  passes: [],
  passesAgainst: [],
  isLoading: false,
  error: undefined,
  isLoadedFirstTime: undefined,
};

export const passesSlice = createSlice({
  name: 'passes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPasses.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getPasses.fulfilled, (state, action) => {
        if (action.meta.arg.isAgainst) {
          state.passesAgainst = action.payload;
        } else {
          state.passes = action.payload;
        }
        state.isLoading = false;
        state.isLoadedFirstTime = true;
      })
      .addCase(getPasses.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const passesReducer = passesSlice.reducer;
