import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { filteredGoalkeepersSelector, selectGoalkeepers, selectRanks } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IEntity,
  IGoalkeeper,
  IGoalkeeperStatsTableData,
  IMetricWithDatasetMetric,
  IRankRecord,
} from '../../../../types';
import {
  getMetricsPercentilesWithValue,
  getMetricsWithRankValues,
  getMetricsWithValue,
  getPlayerShortName,
} from '../../../../utils';

const getTableData = (
  goalkeepers: IGoalkeeper[],
  metrics: IMetricWithDatasetMetric[],
  rankRecord: IRankRecord,
) => {
  const tableData = goalkeepers
    .reduce<IGoalkeeperStatsTableData[]>((acc, goalkeeper) => {
      const goalkeeperTeamStats = Object.values(goalkeeper.teamStats).reduce<
        IGoalkeeperStatsTableData[]
      >((goalkeeperTeamStatsAcc, goalkeeperTeam) => {
        const metricsWithValue = getMetricsWithValue(metrics, goalkeeperTeam.stats);
        const metricsPercentilesWithValue = getMetricsPercentilesWithValue(metrics, goalkeeperTeam);
        const metricsWithRankValue = getMetricsWithRankValues(
          metrics,
          rankRecord,
          goalkeeper.id,
          goalkeeperTeam.id,
        );

        acc.push({
          entityId: goalkeeper.id,
          teamId: goalkeeperTeam.id,
          player: {
            id: goalkeeper.id,
            displayName: getPlayerShortName(goalkeeper),
          },
          gp: goalkeeperTeam.stats.gp,
          toi: goalkeeperTeam.stats.toi,
          stats: metricsWithValue,
          percentiles: metricsPercentilesWithValue,
          summaryPercentile: goalkeeperTeam.percentiles
            ? goalkeeperTeam.percentiles['summaryPercentile']
            : undefined,
          rank: metricsWithRankValue,
        });

        return goalkeeperTeamStatsAcc;
      }, []);

      return acc.concat(goalkeeperTeamStats);
    }, [])
    .sort((a, b) => b.toi - a.toi);

  return tableData;
};

export const useDataForTable = () => {
  const goalkeepers = useAppSelector(selectGoalkeepers);
  const { rank } = useAppSelector(selectRanks);
  const filteredGoalkeepers = useAppSelector(filteredGoalkeepersSelector);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const goalkeepersWithStatsFiltered = useMemo(
    () =>
      Object.values(goalkeepers.byId).filter(goalkeeper =>
        filteredGoalkeepers.map(goalkeeper => goalkeeper.id).includes(goalkeeper.id),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [goalkeepers.byId, filteredGoalkeepers],
  );

  const data: IGoalkeeperStatsTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(IEntity.goalkeepers);

    if (filteredMetrics) {
      return getTableData(goalkeepersWithStatsFiltered, filteredMetrics, rank);
    }

    return [];
  }, [goalkeepersWithStatsFiltered, rank, getFilteredMetrics]);

  return data;
};
