import { PaginationState } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Loading, TcmsButton, TcmsTable, TrackingUserFilter } from '../../../../components';
import {
  DEFAULT_TCMS_TABLE_PAGE_ITEMS_COUNT,
  DEFAULT_USER_LOG_FILTER_FORM_VALUES,
  mediaOptions,
} from '../../../../constants';
import { getIdentities, selectIdentity, selectTeams } from '../../../../features';
import { useHandleOnSubmitWithValues } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadUsersIcon } from '../../../../icons';
import { IExportRows, IIdentitiesTableData, ITrackingUserFilterForm } from '../../../../types';
import {
  createClassNames,
  filterEnabledRoles,
  filterUserLogDataByFilterForm,
  formatDateByLanguage,
  handleExportToXLSX,
  teamOrMediaIdByRole,
} from '../../../../utils';
import './UserPage.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';

const DEFAULT_PAGINATION_STATE = { pageIndex: 0, pageSize: DEFAULT_TCMS_TABLE_PAGE_ITEMS_COUNT };

const classNames = createClassNames('user-page');

export const UserPage = () => {
  const teams = useAppSelector(selectTeams);
  const { identities, isLoading, hasBeenIdentitiesLoaded } = useAppSelector(selectIdentity);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [pagination, setPagination] = useState<PaginationState>(DEFAULT_PAGINATION_STATE);

  const methods = useForm<ITrackingUserFilterForm>({
    defaultValues: DEFAULT_USER_LOG_FILTER_FORM_VALUES,
    mode: 'onBlur',
  });

  const { submitValues, handleOnSubmit } = useHandleOnSubmitWithValues(
    methods,
    DEFAULT_USER_LOG_FILTER_FORM_VALUES,
  );

  const data = useMemo(
    () =>
      filterUserLogDataByFilterForm(
        identities.filter(identity => filterEnabledRoles(identity)),
        submitValues,
      ),
    [identities, submitValues],
  );

  const { columns, initialSorting } = useColumnsConfig();

  useEffect(() => {
    if (!hasBeenIdentitiesLoaded) {
      dispatch(getIdentities());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className={classNames()}>
        <Loading />
      </div>
    );
  }

  /* Export dat */
  const commonHeader = [
    t(ITranslationKeys.user),
    t(ITranslationKeys.role),
    t(ITranslationKeys.teamMedia),
    t(ITranslationKeys.hlTotalLoginCount),
    t(ITranslationKeys.hlLastLogin),
    t(ITranslationKeys.ecTotalLoginCount),
    t(ITranslationKeys.ecLastLogin),
  ];
  const tableRowCallback = (row: IIdentitiesTableData): IExportRows => {
    const lastLoginHL = row.accessLog?.HL.lastAccessAt
      ? formatDateByLanguage(new Date(row.accessLog.HL.lastAccessAt), 'P')
      : '';
    const lastLoginEC = row.accessLog?.HLEC.lastAccessAt
      ? formatDateByLanguage(new Date(row.accessLog.HLEC.lastAccessAt), 'P')
      : '';

    return {
      username: row.username,
      role: row.role,
      teamOrMedia:
        teamOrMediaIdByRole(row.role, row.teamId, row.media, teams.byId, mediaOptions) ??
        'Nevyplněno',
      loginCountHL: row.accessLog?.HL.total + '; ' + row.accessLog?.HL.total,
      lastLoginHL,
      loginCountEC: row.accessLog?.HLEC.total + '; ' + row.accessLog?.HLEC.total,
      lastLoginEC,
    };
  };

  return (
    <div className={classNames()}>
      <div className={classNames('toolbar')}>
        <div className={classNames('toolbar__filter')}>
          <FormProvider {...methods}>
            <TrackingUserFilter onSubmit={handleOnSubmit} />
          </FormProvider>
        </div>
        <div className={classNames('toolbar__controls')}>
          <TcmsButton
            color='blue'
            variant='text'
            label={ITranslationKeys.downloadUsersList}
            iconComponent={<DownloadUsersIcon />}
            iconPosition='left'
            disabled={isLoading || data.length === 0}
            onClick={() =>
              handleExportToXLSX(t(ITranslationKeys.appUsers), commonHeader, tableRowCallback, data)
            }
          />
        </div>
      </div>
      <div className={classNames('content')}>
        {!data || data.length === 0 ? (
          <div className='content-info-box'>{t(ITranslationKeys.noDataForSelected)}</div>
        ) : (
          <TcmsTable
            {...{
              data,
              columns,
              initialSorting,
              pagination,
              setPagination,
            }}
            paginationConfig={{
              itemsOnPage: 15,
              displayTableBottomLine: true,
            }}
            disableCellLine
            hasTransparentBackground
            isForUserLog
          />
        )}
      </div>
    </div>
  );
};
