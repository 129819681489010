import { IS_USER_DATASET } from '../constants';
import {
  IDatasetMetric,
  IDatasetMetricRecord,
  IEntity,
  ISelectOption,
  IUserDatasetRecord,
} from '../types';

export const createUserDatasetMetricRecordFromSelected = (
  selectedMetrics: ISelectOption<IDatasetMetric>[],
): IDatasetMetricRecord =>
  selectedMetrics.reduce<IDatasetMetricRecord>((acc, metric) => {
    const { code, origin, parameter, value } = metric.additionalInfo;

    acc[metric.value] = {
      code,
      origin,
      parameter,
      value,
    };

    return acc;
  }, {});

export const createUserDatasetOptions = (userDatasetRecord: IUserDatasetRecord, entity: IEntity) =>
  Object.values(userDatasetRecord).reduce<ISelectOption[]>((acc, userDataset) => {
    if (userDataset.entity === entity) {
      acc.push({
        value: userDataset.id,
        label: userDataset.name !== '' ? userDataset.name : '-',
        additionalValue: IS_USER_DATASET,
      });
    }

    return acc;
  }, []);
