import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import { RoutesContainer } from './routes/RoutesContainer';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <ToastContainer
          style={{ zIndex: 1000000000000 }}
          position='top-right'
          autoClose={5000}
          limit={5}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
        />
        <RoutesContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
