import { FC } from 'react';

import { createClassNames } from '../../../utils';
import {
  DateFromToController,
  GameTimeController,
  LastPlayedMatchesController,
  OpponentsController,
  PlaceController,
  ScoreStateController,
} from '../Controllers';
import './FilterExtendedPart.styles.scss';

interface IFilterExtendedProps {
  /** Is select opponent field disabled at extended part of filter? */
  disableSelectOpponent?: boolean;
}

const classNames = createClassNames('filter-extended-part');

/**
 * Extended part of filter that displays if user clicks at button `More filters`.
 */
export const FilterExtendedPart: FC<IFilterExtendedProps> = ({ disableSelectOpponent }) => {
  return (
    <div className={classNames()}>
      <LastPlayedMatchesController />
      <DateFromToController />
      <PlaceController />
      <ScoreStateController />
      <GameTimeController />
      {!disableSelectOpponent && <OpponentsController />}
    </div>
  );
};
