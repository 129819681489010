import { useAppDispatch } from '../../../app/hooks';
import {
  blockedShotCategoryOption,
  goalShotCategoryOption,
  missedShotCategoryOption,
  onNetShotCategoryOption,
  postsShotCategoryOption,
} from '../../../constants';
import { setFilterByProp, setShotCategory } from '../../../features';
import { IMetricWithDatasetMetric, ISelectOption } from '../../../types';

export const useSetShotsFilter = () => {
  const dispatch = useAppDispatch();

  const setShotsFilterSetter = (stat: IMetricWithDatasetMetric) => {
    const option = getShotMetricSubgroupOption(stat.metric.metricSubgroup.id, stat.metric.id);
    if (option) dispatch(setShotCategory(option));

    if (stat.metric.metricSubgroup.id === 'bloky') dispatch(setFilterByProp('blocker'));
  };

  return { setShotsFilterSetter };
};

const getShotMetricSubgroupOption = (
  metricSubgroupId: string,
  metricId: string,
): ISelectOption | undefined => {
  if (metricSubgroupId === 'strely') {
    if (metricId === 'ms') return missedShotCategoryOption;
    if (metricId === 'bs') return blockedShotCategoryOption;
    if (metricId === 'post') return postsShotCategoryOption;
  }

  if (metricSubgroupId === 'strely_na_branku') return onNetShotCategoryOption;
  if (metricSubgroupId === 'goly') return goalShotCategoryOption;
  if (metricSubgroupId === 'bloky') return blockedShotCategoryOption;

  return undefined;
};
