import { useEffect, useState } from 'react';

interface ILeftRightOffsetSettings {
  dataCountToDisplay?: number;
  reverse?: boolean;
  defaultOffset?: number;
}

export const useLeftRightOffset = (dataLength: number, settings: ILeftRightOffsetSettings) => {
  const { dataCountToDisplay = 7, reverse = false, defaultOffset = 0 } = settings;

  const [offset, setOffset] = useState(defaultOffset);

  useEffect(() => {
    if (offset + dataCountToDisplay > dataLength) {
      setOffset(defaultOffset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLength, dataCountToDisplay]);

  const enableRight = dataCountToDisplay + offset < dataLength;
  const enableLeft = offset > 0;

  const handleClickLeft = () => {
    if (enableLeft) {
      setOffset(offset - 1);
    }
  };

  const handleClickRight = () => {
    if (enableRight) {
      setOffset(offset + 1);
    }
  };

  return {
    offset,
    setOffset,
    handleClickLeft: reverse ? handleClickRight : handleClickLeft,
    handleClickRight: reverse ? handleClickLeft : handleClickRight,
    enableLeft: reverse ? enableRight : enableLeft,
    enableRight: reverse ? enableLeft : enableRight,
  };
};
