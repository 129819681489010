import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import {
  IGoalkeeperGoalStatsRequestBody,
  IPlayerWithTeamIdAndStats,
  IReturnTypeGetGoalkeeperCardInfoAction,
} from '../../types';
import { buildPath, defaultCatchErrorCallback, urlBooleanParamResolver } from '../../utils';
import { parseGoalkeeperCardInfoData, parseGoalkeeperStatsData } from './parseGoalkeepers';

interface IGetCompetitionDetailParams {
  competitionUuid: string;
  teamUuid: string;
  playerUuid: string;
  isCalledFromCompareGoalkeepers?: boolean;
}

interface IGetGoalkeeperStatsParams extends Partial<IReturnTypeGetGoalkeeperCardInfoAction> {
  competitionsUuids: string[];
  teamUuid?: string;
  body?: IGoalkeeperGoalStatsRequestBody;
  isPercentile?: boolean;
}

const getGoalkeeperStatsRequestUrl = (params: Omit<IGetGoalkeeperStatsParams, 'body'>): string => {
  const { competitionsUuids, teamUuid, isPercentile } = params;
  const base = teamUuid
    ? `/goalkeeper/${competitionsUuids}/${teamUuid}`
    : `/goalkeeper/${competitionsUuids}`;

  return buildPath(base, [urlBooleanParamResolver(isPercentile, 'percentile')]);
};

export const getGoalkeeperCardInfo = createAsyncThunk<
  IReturnTypeGetGoalkeeperCardInfoAction,
  IGetCompetitionDetailParams
>('goalkeeper/getGoalkeeperCardInfo', async ({ competitionUuid, teamUuid, playerUuid }) => {
  const goalkeeperInfo: IReturnTypeGetGoalkeeperCardInfoAction = await axiosInstance
    .get(`/goalkeeper/${competitionUuid}/${teamUuid}/${playerUuid}`)
    .then(response => parseGoalkeeperCardInfoData(response.data, teamUuid))
    .catch(defaultCatchErrorCallback);

  return goalkeeperInfo;
});

export const getGoalkeepersStats = createAsyncThunk<
  IPlayerWithTeamIdAndStats[],
  IGetGoalkeeperStatsParams
>('goalkeeper/getGoalkeepersStats', async ({ competitionsUuids, teamUuid, body, isPercentile }) => {
  const requestPath = getGoalkeeperStatsRequestUrl({ competitionsUuids, teamUuid, isPercentile });

  const goalkeepersStats = await axiosInstance
    .post(requestPath, body)
    .then(response => parseGoalkeeperStatsData(response.data, teamUuid))
    .catch(defaultCatchErrorCallback);

  return goalkeepersStats;
});
