import { useFormContext } from 'react-hook-form';

import { useAppSelector } from '../app/hooks';
import { SelectPlayersBar } from '../components';
import { DISABLED_TABS_ON_OFF } from '../constants';
import { filteredMainFilterDataSelector, filteredPlayerDataSelector } from '../features';
import { IMainFilterForm, INavigationContentKeys, INavigationPageKeys } from '../types';
import { isFormValid } from '../utils';
import { useLocationPaths } from './useLocationPaths';

/**
 * Decides how to display SelectPlayersBar component.
 * @returns SelectPlayersBar component as JSX.Element or null.
 */
export const useSelectPlayersBar = () => {
  const { activePage, activeTab } = useLocationPaths();
  const { filteredPlayersOptions } = useAppSelector(filteredMainFilterDataSelector);
  const { filteredPlayersForFaceoffOptions, filteredPlayersForShootoutOptions } = useAppSelector(
    filteredPlayerDataSelector,
  );

  const { formState } = useFormContext<IMainFilterForm>();
  const { errors } = formState;

  const hasOnOff = !DISABLED_TABS_ON_OFF.includes(activeTab);

  if (activePage === INavigationPageKeys.videomaps) {
    const disabled = !isFormValid(errors);

    if (activeTab === INavigationContentKeys.faceoffs) {
      return (
        <SelectPlayersBar
          playersOptions={filteredPlayersForFaceoffOptions}
          disabled={disabled}
          hasOnOff={hasOnOff}
        />
      );
    }

    if (activeTab === INavigationContentKeys.shootouts) {
      return (
        <SelectPlayersBar
          playersOptions={filteredPlayersForShootoutOptions}
          disabled={disabled}
          hasOnOff={hasOnOff}
        />
      );
    }

    return (
      <SelectPlayersBar
        playersOptions={filteredPlayersOptions}
        disabled={disabled}
        hasOnOff={hasOnOff}
      />
    );
  } else if (
    activePage === INavigationPageKeys.players &&
    (activeTab === INavigationContentKeys.gamelog || activeTab === INavigationContentKeys.trend)
  ) {
    return (
      <SelectPlayersBar
        playersOptions={filteredPlayersOptions}
        disabled={!isFormValid(errors)}
        colorAll='blue'
        onOffColored
        paddingBottom={'2.5rem'}
      />
    );
  }

  return null;
};
