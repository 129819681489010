import { FC, ReactNode, useState } from 'react';

import { COLORS } from '../../constants';
import { IOnOffColors, IPlayerColors } from '../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './CheckboxButton.styles.scss';

export interface ICheckboxButtonProps {
  /** The content of the checkbox button. */
  children: ReactNode;
  /** The color of the checkbox button. */
  color?: 'black' | IPlayerColors | IOnOffColors;
  /** Should the content have same color as the color of the checkbox button? */
  shouldContentBeColored?: boolean;
  /** Should the checkbox react on the full body or only on the checkbox itself? */
  shouldCheckFullBody?: boolean;
  /** Sets the checked property. */
  checked?: boolean;
  /** A callback function for handling the click event. */
  onClick: () => void;
}

const classNames = createClassNames('checkbox-button');

/** CheckboxButon component for indicating data loading. */
export const CheckboxButton: FC<ICheckboxButtonProps> = ({
  children,
  checked = false,
  shouldCheckFullBody = true,
  color = 'black',
  shouldContentBeColored = false,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          checked,
          color,
          shouldCheckFullBody,
          isHovered,
        }),
      })}
      onClick={shouldCheckFullBody ? onClick : undefined}
      onMouseEnter={shouldCheckFullBody ? () => setIsHovered(true) : undefined}
      onMouseLeave={shouldCheckFullBody ? () => setIsHovered(false) : undefined}
    >
      <div
        className={classNames('checkbox', {
          ...transformObjectKeysToKebabCase({
            checked,
            color,
            isHovered,
          }),
        })}
        onClick={!shouldCheckFullBody ? onClick : undefined}
        onMouseEnter={!shouldCheckFullBody ? () => setIsHovered(true) : undefined}
        onMouseLeave={!shouldCheckFullBody ? () => setIsHovered(false) : undefined}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          version='1.1'
          width='10'
          height='10'
          x='0'
          y='0'
          viewBox='0 0 515.556 515.556'
          xmlSpace='preserve'
        >
          {checked && (
            <g>
              <path
                xmlns='http://www.w3.org/2000/svg'
                d='m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z'
                fill='black'
                data-original={COLORS.black.base}
              />
            </g>
          )}
        </svg>
      </div>
      <div
        className={classNames('content', {
          ...transformObjectKeysToKebabCase({
            color: shouldContentBeColored ? color : false,
            isHovered,
          }),
        })}
      >
        {children}
      </div>
    </div>
  );
};
