import { FC, ReactNode } from 'react';

import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './Container.styles.scss';

export interface IContainerProps {
  children: ReactNode;
  height?: number;
  flexDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  justifyContent?: 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly';
  alignItems?: 'center' | 'start' | 'end' | 'baseline' | 'stretch';
}

const classNames = createClassNames('container');

/**
 * Container UI component for user interaction.
 */
export const Container: FC<IContainerProps> = ({
  alignItems,
  children,
  flexDirection,
  justifyContent,
  height,
}) => {
  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          alignItems,
          flexDirection,
          justifyContent,
        }),
      })}
      style={{ height: height && height + 'rem' }}
    >
      {children}
    </div>
  );
};
