import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer } from 'react-sortable-hoc';

import { ITranslationKeys } from '../../../i18n/types';
import { CrossIcon } from '../../../icons';
import { IDatasetMetric, IMetricCategory, ISelectOption } from '../../../types';
import { createClassNames } from '../../../utils';
import { IMetricButtonProps, MetricButton, MetricButtonSortableItem } from '../MetricButton';
import './MetricSelectionList.styles.scss';

export interface IMetricSelectionListProps {
  /** All loaded metrics i one category. */
  metrics: IMetricCategory;
  /** Selected metrics. */
  selectedMetrics: ISelectOption<IDatasetMetric>[];
  /** Callback fired when user clicks on metric in list. */
  onChange: (value: ISelectOption<IDatasetMetric>[]) => void;
  /** Items order can be change with drag and drop. */
  isSortable?: boolean;
}

const classNames = createClassNames('metric-selection-list');

export const MetricSelectionList: FC<IMetricSelectionListProps> = ({
  metrics,
  selectedMetrics,
  onChange,
  isSortable = false,
}) => {
  const { t } = useTranslation();

  const handleDeselectAll = () => {
    onChange([]);
  };

  const handleMetricButton = (metricOption: ISelectOption) => {
    onChange(selectedMetrics.filter(metric => metric.value !== metricOption.value));
  };

  return (
    <div className={classNames()} data-testid='metric-selection-list'>
      <div className={classNames('delete-box')}>
        <span>{t(ITranslationKeys.deleteSelected)}</span>
        <CrossIcon
          className='data-settings-cross-icon'
          onClick={handleDeselectAll}
          data-testid='data-settings-cross-icon'
        />
      </div>
      {selectedMetrics.map((selectedOption, index) => {
        const { color } = metrics.byId[selectedOption.additionalInfo.origin]?.metricInfo;

        const buttonProps: IMetricButtonProps = {
          label: selectedOption.label,
          color: color === 'white' ? 'black' : color,
          isActive: true,
          onClick: () => handleMetricButton(selectedOption),
        };

        if (isSortable) {
          return (
            <MetricButtonSortableItem
              {...buttonProps}
              key={selectedOption.value}
              index={index}
              hasDragHandle
            />
          );
        }

        return <MetricButton {...buttonProps} key={selectedOption.value} />;
      })}
      <div className={classNames('empty-button')}></div>
      <div className={classNames('empty-button')}></div>
      <div className={classNames('empty-button')}></div>
    </div>
  );
};

export const MetricSelectionSortableList = SortableContainer<IMetricSelectionListProps>(
  (props: IMetricSelectionListProps) => <MetricSelectionList {...props} isSortable />,
);
