import { Dispatch, FC, SetStateAction, useState } from 'react';

import { useGetRoleLogAggregationEcQuery } from '../../../../../services/api/trackingApi';
import { ITrackingTimeInterval } from '../../../../../types';
import { filterRoleLogData, getNextPageDateRangeString } from '../../../../../utils';
import { RoleLogOverview } from './RoleLogOverview';
import { IOverviewComponentsProps } from './types';

interface IRoleLogHockeyLogicProps extends IOverviewComponentsProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const OLDEST_DATE = '2022-01-01';

export const RoleLogExtraCoach: FC<IRoleLogHockeyLogicProps> = ({
  submitValues,
  page,
  setPage,
}) => {
  const [timeInterval, setTimeInterval] = useState<ITrackingTimeInterval>('D');

  const lastSubmittedDateTo = submitValues.dateFromTo?.to || submitValues.dateFromTo?.from;
  const dateForFetch = getNextPageDateRangeString(page, lastSubmittedDateTo, timeInterval);
  const hasMoreData = !!dateForFetch.from && new Date(dateForFetch.from) > new Date(OLDEST_DATE);

  const { data, error, isFetching } = useGetRoleLogAggregationEcQuery(
    { dateFromTo: dateForFetch, aggregation: timeInterval, lastSubmittedDateTo },
    {
      selectFromResult: result => ({
        ...result,
        data: filterRoleLogData(result.data, submitValues),
      }),
    },
  );

  const handleTimeIntervalChange = (timeInterval: ITrackingTimeInterval) => {
    setTimeInterval(timeInterval);
  };

  return (
    <RoleLogOverview
      data={data}
      error={error}
      isFetching={isFetching}
      submitValues={submitValues}
      timeInterval={timeInterval}
      onTimeIntervalChange={handleTimeIntervalChange}
      setPage={setPage}
      hasMoreData={hasMoreData}
    />
  );
};
