import { FC } from 'react';
import { BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { ITranslationKeys } from '../../../i18n/types';
import { IScreenBarChartData } from '../../../types';
import { createClassNames } from '../../../utils';
import { TcmsChartTooltip } from '../TcmsChartTooltip';
import { getTeamMediaOtherStackedBars } from '../utils';
import { BarTick } from './BarTick';
import './ScreenBarChart.styles.scss';

export interface IScreenBarChartProps {
  /** Data to display. */
  data: IScreenBarChartData[];
  /** If true, component has buttons instead of labels. */
  hasButtons?: boolean;
  /** Heigh of component. */
  height?: string | number;
  /** Selected button */
  selectedCategory?: ITranslationKeys | string;
  /** Function to call when a bar is clicked. */
  onClick?: (item: IScreenBarChartData) => void;
}

const layout = 'vertical';

const classNames = createClassNames('screen-bar-chart');

export const ScreenBarChart: FC<IScreenBarChartProps> = ({
  data,
  hasButtons = false,
  height = 322,
  selectedCategory,
  onClick,
}) => (
  <div className={classNames()}>
    <div className={classNames('gradient-element')}></div>
    <ResponsiveContainer width='100%' height={height} className={classNames('chart')}>
      <BarChart
        layout={layout}
        data={data}
        margin={{
          top: 20,
        }}
        barSize={12}
      >
        <CartesianGrid vertical={false} horizontal={false} />
        <XAxis type='number' tickLine={false} axisLine={false} tick={false} />
        <YAxis
          dataKey='label'
          type='category'
          tickLine={false}
          interval={0}
          axisLine={false}
          tick={props => {
            const item = data.find(item => item.label === props.payload.value);
            if (!item)
              return <BarTick {...props} total={0} hasButtons={hasButtons} onClick={onClick} />;

            const total = item.media + item.team + item.other;
            return (
              <BarTick
                {...props}
                total={total}
                hasButtons={hasButtons}
                isSelected={item.label === selectedCategory}
                onClick={() => onClick?.(item)}
              />
            );
          }}
        />
        <Tooltip cursor={{ fill: 'none' }} content={<TcmsChartTooltip />} separator='' />
        {getTeamMediaOtherStackedBars(layout)}
      </BarChart>
    </ResponsiveContainer>
  </div>
);
