import { FC } from 'react';

import { createClassNames, secondsToMinutesAndSeconds } from '../../../utils';
import './BasicPlayerInfo.styles.scss';

export interface IBasicPlayerInfoProps {
  /**
   * Player name.
   */
  name: string;
  /**
   * Time on ice.
   */
  toi: number;
  /**
   * GP metric
   */
  gp: number;
}

const classNames = createClassNames('basic-player-info');

/**
 * BasicPlayerInfo component showing basic info about the player.
 */
export const BasicPlayerInfo: FC<IBasicPlayerInfoProps> = ({ name, toi, gp }) => {
  return (
    <div className={classNames()} data-testid='basic-player-info'>
      <h3 className={classNames('name')}>{name}</h3>
      <div className={classNames('stats')}>
        <span>
          <strong>TOI:</strong>&nbsp;{secondsToMinutesAndSeconds(toi)}
        </span>
        <span>
          <strong>GP:</strong>&nbsp;{gp}
        </span>
      </div>
    </div>
  );
};
