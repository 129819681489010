import { FC, useState } from 'react';
import {
  Brush,
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { COLORS } from '../../../constants';
import { TrendBarIcon } from '../../../icons';
import { IShowTrendChartLines, ITrendChartData } from '../../../types';
import { TrendTick } from '../TrendTick';
import { TrendTooltip } from '../TrendTooltip';

interface BrushStartEndIndex {
  startIndex?: number;
  endIndex?: number;
}

const height = 584;
const maxMatches = 75;

export interface ITrendChartProps extends IShowTrendChartLines {
  /** Name of metric. */
  metricName: string;
  /** Chart data. */
  data: ITrendChartData[];
  /** League average value. */
  leagueAverage?: number;
}

/** Trend chart component. */
export const TrendChart: FC<ITrendChartProps> = ({
  metricName,
  data,
  showLeagueAverage,
  showMovingAverage,
  showMetric,
  leagueAverage,
}) => {
  const [moreThanSeason, setMoreThanSeason] = useState<boolean>(data.length > maxMatches);

  const handleBrushChange = (newIndex: BrushStartEndIndex) => {
    const { startIndex, endIndex } = newIndex;

    if (!startIndex || !endIndex) return;

    if (endIndex - startIndex > maxMatches) {
      if (!moreThanSeason) {
        setMoreThanSeason(true);
      }
    } else {
      if (moreThanSeason) {
        setMoreThanSeason(false);
      }
    }
  };

  return (
    <ResponsiveContainer width='100%' height={height}>
      <LineChart
        width={1000}
        height={height}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 156,
        }}
      >
        <CartesianGrid vertical={false} stroke={COLORS.coolGray[20]} />
        <XAxis
          dataKey='matchInfo.xAxisTickValue'
          axisLine={{ stroke: COLORS.coolGray[20] }}
          tickLine={false}
          tickMargin={10}
          interval={moreThanSeason ? 5 : 0}
          tick={<TrendTick moreThanSeason={moreThanSeason} />}
          padding={{ left: 20, right: 20 }}
        />
        <YAxis
          tickCount={10}
          tickSize={0}
          height={384}
          axisLine={false}
          tickMargin={10}
          tick={{ fill: COLORS.coolGray[100], fontSize: 10 }}
          label={<TrendBarIcon />}
        />
        <Tooltip
          cursor={{ stroke: COLORS.coolGray[20] }}
          separator=' = '
          content={<TrendTooltip />}
        />
        <Brush
          y={height - 25}
          height={20}
          stroke={COLORS.blue[60]}
          strokeWidth={4}
          travellerWidth={6}
          traveller={<rect></rect>}
          padding={{ left: 20, right: 20 }}
          onChange={handleBrushChange}
        />
        <Line
          type='bump'
          dataKey='metricValue'
          name={metricName}
          stroke={COLORS.blue[60]}
          strokeWidth={3}
          dot={{ r: 5, stroke: COLORS.coolGray[100] }}
          activeDot={{ r: 5, fill: COLORS.coolGray[100] }}
          style={{ visibility: showMetric ? 'visible' : 'hidden' }}
        />
        {showMovingAverage && (
          <Line
            type='monotone'
            dataKey='movingAverageValue'
            stroke={COLORS.red[60]}
            strokeWidth={3}
            dot={false}
            activeDot={false}
          />
        )}
        {showLeagueAverage && (
          <ReferenceLine
            y={leagueAverage}
            stroke={COLORS.coolGray[100]}
            strokeWidth={3}
            strokeDasharray='3 3'
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};
