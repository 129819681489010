import { ColumnDef, SortingState, createColumnHelper } from '@tanstack/react-table';

import { BasicColumnCell, BasicColumnHeader } from '../../../../../../components';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { IAccessLogTableData } from '../../../../../../types';
import { createClassNames, formatAccessAtToDate } from '../../../../../../utils';
import './columns.styles.scss';

const classNames = createClassNames('access-log-users-content-columns');

const columnHelper = createColumnHelper<IAccessLogTableData>();

const initialSorting: SortingState = [
  {
    id: 'accessAt',
    desc: true,
  },
];

const fixedColumns: ColumnDef<IAccessLogTableData, any>[] = [
  columnHelper.accessor('username', {
    header: props => (
      <BasicColumnHeader
        {...props}
        translationKey={ITranslationKeys.user}
        justifyContent='flex-start'
        allowSortIcon
        variant='tcms'
      />
    ),
    cell: props => (
      <BasicColumnCell {...props} variant='tcms'>
        <div className={[classNames(), classNames('username-cell')].join(' ')}>
          {props.row.original.username}
        </div>
      </BasicColumnCell>
    ),
    enableSorting: false,
  }),
  columnHelper.accessor('accessAt', {
    header: props => (
      <BasicColumnHeader
        {...props}
        translationKey={ITranslationKeys.dateAndTime}
        justifyContent='start'
        allowSortIcon
        variant='tcms'
      />
    ),
    cell: props => (
      <div className={[classNames(), classNames('access-at-cell')].join(' ')}>
        {formatAccessAtToDate(props.row.original.accessAt)}
      </div>
    ),
  }),
  columnHelper.accessor('ip', {
    header: props => (
      <BasicColumnHeader
        {...props}
        translationKey={ITranslationKeys.ipAdress}
        justifyContent='start'
        allowSortIcon={false}
        variant='tcms'
      />
    ),
    cell: props => (
      <BasicColumnCell {...props} variant='tcms'>
        <div className={[classNames(), classNames('ip-cell')].join(' ')}>
          <a
            href={`https://www.ip-tracker.org/lookup.php?ip=${props.row.original.ip}`}
            target='_blank'
            rel='noreferrer'
          >
            {props.row.original.ip}
          </a>
        </div>
      </BasicColumnCell>
    ),
    enableSorting: false,
  }),
];

export const useColumnsConfig = () => {
  return {
    columns: fixedColumns,
    initialSorting,
  };
};
