import { useState } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { selectTeams, shiftVideoRecordWithEditsSelector } from '../../../../features';
import { ISelectOption, IShiftVideo } from '../../../../types';
import { createClassNames } from '../../../../utils';
import { CheckboxButton } from '../../../CheckboxButton';
import { VideoCenterSelectItemContent } from '../../VideoCenterSelectItemContent';
import { VideoEventRow } from '../../VideoEventRow';
import { ISharedVideoCenterComponentsProps } from '../../types';
import './VideoShiftsCheckboxButton.styles.scss';

export interface IVideoShiftsCheckboxButtonProps<TOption = undefined>
  extends ISharedVideoCenterComponentsProps {
  /** Shift video object. */
  video: IShiftVideo;
  /** Main shift option for expandable row. */
  option: ISelectOption<TOption>;
  /** Selected options. */
  selected: ISelectOption<TOption>[];
  /** Method called on select video. */
  onChangeToggle: (option: ISelectOption<TOption>) => void;
}

const classNames = createClassNames('video-shifts-checkbox-button');

/** CheckboxButon component for indicating data loading. */
export const VideoShiftsCheckboxButton = <TOption extends any = undefined>({
  video,
  option,
  selected,
  onChangeToggle,
  playerOrGoalkeeperRecord,
  onPlay,
  onVideocoachNote,
  onCroppedTime,
  color = 'black',
  shouldCheckFullBody = false,
}: IVideoShiftsCheckboxButtonProps<TOption>) => {
  const teams = useAppSelector(selectTeams);
  const { videoRecord, edits } = useAppSelector(shiftVideoRecordWithEditsSelector);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const isEdited = edits.some(item => item.videoUuid === video.videoUuid);
  const player = playerOrGoalkeeperRecord[video.playerId];
  const isSelected = selected.some(item => item.value === option.value);

  const shiftVideoEvents = video.shiftInfo
    ? video.shiftInfo.shiftVideoEventIds
        .map<IShiftVideo>(shiftVideoId => videoRecord[shiftVideoId])
        .filter((item): item is IShiftVideo => !!item)
        .sort((a, b) => a.time - b.time)
    : [];

  return (
    <div className={classNames()}>
      <CheckboxButton
        key={option.value}
        checked={isSelected}
        onClick={() => onChangeToggle(option)}
        {...{ color, shouldCheckFullBody }}
      >
        <VideoCenterSelectItemContent
          key={option.value}
          {...{ ...player, ...video }}
          timeEnd={video.shiftInfo?.timeEnd}
          isEdited={isEdited}
          onPlay={() => onPlay(option.value)}
          onVideocoachNote={onVideocoachNote ? () => onVideocoachNote(option.value) : undefined}
          onClick={shiftVideoEvents.length > 0 ? () => setIsExpanded(!isExpanded) : undefined}
          onCroppedTime={onCroppedTime ? () => onCroppedTime(option.value) : undefined}
          dataListItemsCount={shiftVideoEvents.length}
          hasDataListIcon
        />
      </CheckboxButton>
      {isExpanded && (
        <div className={classNames('expanded-list')}>
          {shiftVideoEvents.map(shiftVideo => {
            if (!shiftVideo || !shiftVideo.eventInfo) return null;

            const isEdited = edits.some(item => item.videoUuid === shiftVideo.videoUuid);
            const player = playerOrGoalkeeperRecord[shiftVideo.playerId];
            const checked = selected.some(item => item.value === shiftVideo.videoUuid);
            const subOption: ISelectOption<TOption> = {
              value: shiftVideo.videoUuid,
              label: shiftVideo.videoId,
            } as ISelectOption<TOption>;

            return (
              <VideoEventRow
                key={shiftVideo.videoUuid}
                {...{ ...player, ...shiftVideo }}
                checked={checked}
                teamShortcut={
                  shiftVideo.eventInfo?.teamId
                    ? teams.byId[shiftVideo.eventInfo.teamId].shortcut
                    : undefined
                }
                gameState={shiftVideo.gameState}
                shiftEventId={shiftVideo.eventInfo?.shiftEventId}
                isEdited={isEdited}
                onPlay={() => onPlay(shiftVideo.videoUuid)}
                onCheckboxClick={() => onChangeToggle(subOption)}
                onVideocoachNote={
                  onVideocoachNote ? () => onVideocoachNote(shiftVideo.videoUuid) : undefined
                }
                onCroppedTime={
                  onCroppedTime ? () => onCroppedTime(shiftVideo.videoUuid) : undefined
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
