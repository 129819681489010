import { PaginationState, SortDirection } from '@tanstack/react-table';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableSkeleton, TcmsTable } from '../../../../../../components';
import { DEFAULT_TCMS_TABLE_PAGE_ITEMS_COUNT } from '../../../../../../constants';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { HokejCoachLogoIcon } from '../../../../../../icons';
import { useGetScreenLogCoachQuery } from '../../../../../../services/api';
import { createClassNames, normalizeSelectedEventParam } from '../../../../../../utils';
import { IEventLogTableProps, useColumnsConfig } from '../shared';
import './EventLogTableCoach.styles.scss';

const LIMIT = 100;

const classNames = createClassNames('event-log-table-coach');

export const EventLogTableCoach: FC<IEventLogTableProps> = ({ submitValues, page, setPage }) => {
  const { t } = useTranslation();

  const { selectedRole, selectedTeamOrMedia, selectedUser, selectedEvent } = submitValues;

  const { columns, initialSorting } = useColumnsConfig();

  const [orderDirection, setOrderDirection] = useState<SortDirection>('desc');
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_TCMS_TABLE_PAGE_ITEMS_COUNT,
  });

  const { data, isFetching, isError } = useGetScreenLogCoachQuery({
    offset: page * LIMIT,
    limit: LIMIT,
    username: selectedUser?.value,
    role: selectedRole && selectedRole.value !== 'all' ? selectedRole.value : undefined,
    team: selectedTeamOrMedia.some(teamOrMedia => teamOrMedia.value === 'all')
      ? undefined
      : selectedTeamOrMedia.map(item => item.value),
    tab:
      selectedEvent && selectedEvent.value !== 'all'
        ? normalizeSelectedEventParam(selectedEvent.value)
        : undefined,
    orderDirection,
  });

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: DEFAULT_TCMS_TABLE_PAGE_ITEMS_COUNT });
  }, [submitValues]);

  const render = () => {
    if (isFetching) {
      return <TableSkeleton rowsCount={DEFAULT_TCMS_TABLE_PAGE_ITEMS_COUNT} columnsCount={3} />;
    }

    if (!data) {
      return <div className='content-info-box'>{t(ITranslationKeys.noDataForSelected)}</div>;
    }

    if (isError) {
      return <div className='content-info-box'>{t(ITranslationKeys.defaultErrorMessage)}</div>;
    }

    return (
      <TcmsTable
        {...{
          columns,
          initialSorting,
          pagination,
          setPagination,
          setPage,
        }}
        data={data.items}
        totalAvailableItems={data.totalCount}
        paginationConfig={{
          itemsOnPage: DEFAULT_TCMS_TABLE_PAGE_ITEMS_COUNT,
        }}
        customSorting={[
          {
            id: 'accessAt',
            desc: orderDirection === 'desc',
          },
        ]}
        setCustomSorting={() => setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc')}
      />
    );
  };

  return (
    <div className={classNames()}>
      <HokejCoachLogoIcon height={32} />
      <div className={classNames('content')}>
        <div className={classNames('content__table')}>{render()}</div>
      </div>
    </div>
  );
};
