import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IGamelog, IGamelogRequestBody } from '../../types';
import { defaultCatchErrorCallback } from '../../utils';
import { parseGamelogData } from './parseGamelog';

interface IGetGamelogParams {
  competitionsUuids: string[];
  teamUuid: string;
  goalkeeperUuid?: string;
  body?: IGamelogRequestBody;
}

export const getGamelog = createAsyncThunk<IGamelog[], IGetGamelogParams>(
  'gamelog/getGamelog',
  async ({ competitionsUuids, teamUuid, goalkeeperUuid, body = {} }) => {
    const requestPath = goalkeeperUuid
      ? `/gameLog/${competitionsUuids}/${teamUuid}/goalkeeper/${goalkeeperUuid}`
      : `/gameLog/${competitionsUuids}/${teamUuid}`;

    const gamelog: IGamelog[] = await axiosInstance
      .post(requestPath, body)
      .then(response => parseGamelogData(response.data))
      .catch(defaultCatchErrorCallback);

    return gamelog;
  },
);
