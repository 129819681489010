import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../app/hooks';
import { Card, GoalNetStats, ICardItemProps, IGoalNetStatsRecord } from '../../../../components';
import { selectMetrics } from '../../../../features';
import { ITranslationKeys } from '../../../../i18n/types';
import { IGoalkeeperAllStats, IGoalkeeperCardInfo, IStats } from '../../../../types';
import { filterObjectKeysByText, getGoalkeeperStatValueByMetricId } from '../../../../utils';
import {
  IGoalkeeperCardConfig,
  actualFormConfig,
  seasonStatisticsConfig,
} from './goalkeeperCardsConfig';

interface IUseGetPlayerCards {
  getGoalkeeperCards: (playerInfo: IGoalkeeperCardInfo | undefined, playerId: string) => ReactNode;
  getGoalkeeperGoalNetStats: (stats: IStats, relativeStats: IStats) => ReactNode;
}

export const useGetGoalkeeperCards = (): IUseGetPlayerCards => {
  const { metrics } = useAppSelector(selectMetrics);
  const { t } = useTranslation();

  const getCardConfig = (
    playerInfo: IGoalkeeperAllStats | undefined,
    cardConfig: IGoalkeeperCardConfig[],
  ) => {
    if (!playerInfo || !metrics) return [];

    const cardItems: ICardItemProps[] = cardConfig.map(item => {
      const stats = playerInfo;
      return {
        label: t(item.label),
        name: metrics.goalkeepers.byId[item.metricId]?.metricInfo.name,
        metricValue: getGoalkeeperStatValueByMetricId(stats?.stats, item.metricId),
        percent: getGoalkeeperStatValueByMetricId(stats?.percentiles, item.metricId),
        rank: getGoalkeeperStatValueByMetricId(stats?.ranks, item.metricId),
        showCenter: true,
        showHash: true,
      };
    });

    return cardItems;
  };

  const getGoalNetConfig = (stats: IStats, relativeStats: IStats) => {
    const savesStats: IStats = filterObjectKeysByText(stats, 'sv');
    const gaStats: IStats = filterObjectKeysByText(stats, 'ga');
    const savesRelativeStats: IStats = filterObjectKeysByText(relativeStats, 'sv');
    const gaRelativeStats: IStats = filterObjectKeysByText(relativeStats, 'ga');

    const savesStatsNetValues = Object.keys(savesStats).reduce<IGoalNetStatsRecord>((acc, key) => {
      acc[key] = { keyName: key, value: savesStats[key], relativeValue: savesRelativeStats[key] };
      return acc;
    }, {});

    const gaStatsNetValues = Object.keys(gaStats).reduce<IGoalNetStatsRecord>((acc, key) => {
      acc[key] = { keyName: key, value: gaStats[key], relativeValue: gaRelativeStats[key] };
      return acc;
    }, {});

    return {
      savesStatsNetValues,
      gaStatsNetValues,
    };
  };

  const getGoalkeeperCards = (goalkeeperInfo: IGoalkeeperCardInfo | undefined) => {
    const statisticCardConfig = getCardConfig(goalkeeperInfo?.stats, seasonStatisticsConfig);
    const actualFormCardConfig = getCardConfig(
      goalkeeperInfo?.lastPlayedMatchesStats,
      actualFormConfig,
    );

    return (
      <>
        <Card
          label={`${t(ITranslationKeys.seasonStatistics)}`}
          items={statisticCardConfig}
          toi={goalkeeperInfo?.toi}
          showToi={false}
        />
        {goalkeeperInfo?.lastPlayedMatchesStats && (
          <Card
            label={`${t(ITranslationKeys.actualForm)}`}
            items={actualFormCardConfig}
            toi={goalkeeperInfo?.toi}
            showToi={false}
          />
        )}
      </>
    );
  };

  const getGoalkeeperGoalNetStats = (stats: IStats, relativeStats: IStats) => {
    const { gaStatsNetValues, savesStatsNetValues } = getGoalNetConfig(stats, relativeStats);

    return (
      <>
        <div style={{ width: 'inherit' }}>
          <h2>{t(ITranslationKeys.successOfInterventions)}</h2>
          <GoalNetStats statsValues={savesStatsNetValues} showPercent={true} />
        </div>
        <div style={{ width: 'inherit' }}>
          <h2>{t(ITranslationKeys.receivedGoals)}</h2>
          <GoalNetStats statsValues={gaStatsNetValues} />
        </div>
      </>
    );
  };

  return {
    getGoalkeeperCards,
    getGoalkeeperGoalNetStats,
  };
};
