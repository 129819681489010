import { createSelector } from '@reduxjs/toolkit';

import { IVideoRecord } from '../../types';
import { selectVideoCenter } from '../selectors';

export const videoRecordWithEditsSelector = createSelector(
  [selectVideoCenter],
  videoCenterState => {
    const { videoRecord, edits } = videoCenterState;

    const videoRecordWithEdits = edits.reduce<IVideoRecord>((acc, { videoUuid, ...restEdit }) => {
      const editedRecord: IVideoRecord = {
        ...acc,
        [videoUuid]: {
          ...videoRecord[videoUuid],
          ...restEdit,
        },
      };

      return editedRecord;
    }, videoRecord);

    return {
      videoRecordWithEdits,
      edits,
    };
  },
);
