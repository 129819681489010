import { IRankDTO, IRankRecord, IRankStats } from '../../types';

export const parseRankData = (rankDTO: IRankDTO): IRankRecord => {
  const ranks = Object.keys(rankDTO).reduce<IRankRecord>((acc, rankKey) => {
    const rankStatsDTO = rankDTO[rankKey];
    if (!rankStatsDTO) return acc;

    const rankStat = rankStatsDTO.map<IRankStats>(rankStat => ({
      playerId: rankStat.player,
      teamId: rankStat.team,
      position: rankStat.position,
      value: rankStat.value,
    }));

    acc[rankKey] = {
      metricId: rankKey,
      rankStats: rankStat,
    };

    return acc;
  }, {});

  return ranks;
};
