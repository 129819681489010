import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  filteredAllTeamsPlayersByPositionSelector,
  selectFormations,
  selectPlayers,
  selectTableFilter,
} from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IFormationsPlayerStats,
  IFormationsSelectedTeam,
  IMetricWithDatasetMetric,
  IPlayerCombinationsTableData,
  IPlayerCombinationsTableWithHeaderData,
  IPlayerRecord,
  ISelectOption,
  ISimilarPlayerNameRecord,
} from '../../../../types';
import { getMetricsWithValue, getPlayerShortName } from '../../../../utils';

const getTableData = (
  formationsPlayersFiltered: IFormationsPlayerStats[],
  playersRecord: IPlayerRecord,
  metrics: IMetricWithDatasetMetric[],
  playersInChart: ISelectOption | undefined,
  lastLoadedTeamId: string,
  similarPlayerNames: ISimilarPlayerNameRecord,
) => {
  const tableData = formationsPlayersFiltered
    .map<IPlayerCombinationsTableData>(formationsPlayer => {
      const player = playersRecord[formationsPlayer.id];
      const teamId = playersInChart?.value === 'opponent' ? player.currentTeam : lastLoadedTeamId;

      const metricsWithValue = getMetricsWithValue(metrics, formationsPlayer.stats);

      return {
        entityId: player.id,
        teamId,
        player: {
          id: player.id,
          displayName: getPlayerShortName(player, similarPlayerNames),
        },
        gp: formationsPlayer.stats.gp,
        toi: formationsPlayer.stats.toi,
        stats: metricsWithValue,
      };
    })
    .sort((a, b) => b.toi - a.toi);

  return tableData;
};

export const useDataForTable = () => {
  const { byId, lastLoadedTeamId, opponentState, teammateState } = useAppSelector(selectFormations);
  const { playersInChart } = useAppSelector(selectTableFilter);
  const players = useAppSelector(selectPlayers);
  const filteredPlayers = useAppSelector(filteredAllTeamsPlayersByPositionSelector);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const formationsPlayersFiltered = useMemo(
    () => {
      if (playersInChart?.value === 'opponent') {
        return Object.values(opponentState.byId);
      }

      return Object.values(teammateState.byId).filter(player =>
        filteredPlayers.map(player => player.id).includes(player.id),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [opponentState.byId, teammateState.byId, playersInChart],
  );

  const dataWithHeader: IPlayerCombinationsTableWithHeaderData = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();

    if (filteredMetrics && lastLoadedTeamId) {
      const data = getTableData(
        formationsPlayersFiltered,
        players.byId,
        filteredMetrics,
        playersInChart,
        lastLoadedTeamId,
        players.similarPlayerNames,
      );

      if (lastLoadedTeamId) {
        const teamInfo = byId[lastLoadedTeamId];
        const formationsSelectedTeam: IFormationsSelectedTeam = {
          entityId: lastLoadedTeamId,
          id: lastLoadedTeamId,
          gp: teamInfo?.stats.gp,
          toi: teamInfo?.stats.toi,
          stats: getMetricsWithValue(filteredMetrics, teamInfo?.stats),
        };

        return {
          selectedTeamData: formationsSelectedTeam,
          data,
        };
      }

      return {
        selectedTeamData: undefined,
        data,
      };
    }

    return {
      selectedTeamData: undefined,
      data: [],
    };
  }, [
    formationsPlayersFiltered,
    players.byId,
    players.similarPlayerNames,
    playersInChart,
    lastLoadedTeamId,
    byId,
    getFilteredMetrics,
  ]);

  return dataWithHeader;
};
