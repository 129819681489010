import { ITranslationKeys } from '../../i18n/types';
import {
  DefinedTemplatesIcon,
  HelpManagementIcon,
  MetricsIcon,
  TemplatesIcon,
  UserManagementIcon,
} from '../../icons';
import { INavigationPath } from '../types';

export const NAVIGATION_PATHS_TRACKING: INavigationPath[] = [
  {
    label: ITranslationKeys.analyticsOverview,
    to: '/overview',
  },
  {
    label: 'hokej.LOGIC',
    to: '/hokejlogic',
  },
  {
    label: 'hokej.COACH',
    to: '/hokejcoach',
  },
  {
    label: 'hokej.PLAYER',
    to: '/hokejplayer',
  },
  {
    label: ITranslationKeys.users,
    to: '/users',
  },
  {
    label: ITranslationKeys.logins,
    to: '/logins',
  },
  {
    label: ITranslationKeys.eventLog,
    to: '/event-log',
  },
];

export const NAVIGATION_PATHS_CMS: INavigationPath[] = [
  {
    label: ITranslationKeys.helpManagement,
    to: '/help',
    Icon: HelpManagementIcon,
  },
  {
    label: ITranslationKeys.metricsManagement,
    to: '/metrics',
    Icon: MetricsIcon,
  },
  {
    label: ITranslationKeys.templatesManagement,
    to: '/templates',
    Icon: TemplatesIcon,
  },
  {
    label: ITranslationKeys.definedTemplatesManagement,
    to: '/defined-templates',
    Icon: DefinedTemplatesIcon,
  },
  {
    label: ITranslationKeys.userManagement,
    to: '/users',
    Icon: UserManagementIcon,
  },
];
