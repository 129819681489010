import { FC } from 'react';
import { BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import { ArrowNarrowIcon } from '../../../icons';
import {
  ILeftRightArrowSettings,
  ILogStackedBarChartData,
  ITrackingTimeInterval,
} from '../../../types';
import { createClassNames } from '../../../utils';
import { TcmsChartTooltip } from '../TcmsChartTooltip';
import { getChartGradientDef, getTeamMediaOtherStackedBars } from '../utils';
import { BarTick } from './BarTick';
import './LogStackedBarChart.styles.scss';

export interface ILogStackedBarChartProps {
  /** Data to display. */
  data: ILogStackedBarChartData[];
  /** Heigh of component. */
  height?: string | number;
  /** Enables right and left arrow and has settings object for it. */
  leftRightArrowSettings?: ILeftRightArrowSettings;
  /** Time interval to display correct tick. */
  timeInterval?: ITrackingTimeInterval;
}

const classNames = createClassNames('log-stacked-bar-chart');

export const LogStackedBarChart: FC<ILogStackedBarChartProps> = ({
  data,
  height = 352,
  leftRightArrowSettings,
  timeInterval,
}) => (
  <div className={classNames()}>
    {renderArrows(leftRightArrowSettings)}
    <ResponsiveContainer width='100%' height={height}>
      <BarChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 30,
        }}
        barSize={12}
      >
        {getChartGradientDef()}
        <CartesianGrid vertical={false} horizontal={false} fill='url(#chart-gradient)' />
        <XAxis
          dataKey='xAxisTickValue'
          tickLine={false}
          interval={0}
          axisLine={false}
          padding={{ left: 46, right: 46 }}
          tick={props => <BarTick {...props} timeInterval={timeInterval} />}
        />
        <Tooltip cursor={{ fill: 'none' }} content={<TcmsChartTooltip />} separator='' />
        {getTeamMediaOtherStackedBars()}
      </BarChart>
    </ResponsiveContainer>
  </div>
);

const renderArrows = (leftRightArrowSettings?: ILeftRightArrowSettings) => {
  if (!leftRightArrowSettings) {
    return null;
  }

  const { enableLeft, enableRight, handleClickLeft, handleClickRight } = leftRightArrowSettings;

  return (
    <>
      <ArrowNarrowIcon
        className={classNames('arrow', { disabled: !enableLeft, left: true })}
        onClick={handleClickLeft}
      />
      <ArrowNarrowIcon
        className={classNames('arrow', { disabled: !enableRight })}
        onClick={handleClickRight}
      />
    </>
  );
};
