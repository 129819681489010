import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Button, GoalNetStats } from '../../../../components';
import { SHORT_NET_ZONES } from '../../../../constants';
import {
  filteredMainFilterDataSelector,
  getGoalkeeperNetZonesCardInfo,
  getGoalkeepersNetZoneStats,
  selectGoalkeepers,
  selectGoalkeepersNetZones,
  setNetZonesGoalkeeperItems,
} from '../../../../features';
import { useHandleOnSubmit, usePrepareBaseRequestBody, useRefetchContent } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { PlusActiveIcon } from '../../../../icons';
import {
  IGoalStatsBoxProps,
  IGoalkeeperGoalStatsRequestBody,
  INetZonesGoalkeeperRecord,
  ISelectOption,
} from '../../../../types';
import {
  createClassNames,
  filterSelectedTeamsGoalkeepers,
  reduceGoalNetBoxMetrics,
  reduceStatsToGoalNetStatsRecord,
} from '../../../../utils';
import './ZonesContent.styles.scss';

const classNames = createClassNames('zones-content');

export const ZonesContent = () => {
  const { filteredParts, filteredTeams } = useAppSelector(filteredMainFilterDataSelector);
  const { byId, isStatsLoading } = useAppSelector(selectGoalkeepers);
  const { netZonesGoalkeeperItems } = useAppSelector(selectGoalkeepersNetZones);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const prepareBaseRequestBody = usePrepareBaseRequestBody();

  const indexes = Object.values(netZonesGoalkeeperItems).map((item, index) =>
    item.goalkeeperStats && item.teamStats ? index : undefined,
  );

  useEffect(() => {
    if (Object.values(netZonesGoalkeeperItems).length === 0) {
      generateMoreGoalFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateMoreGoalFields = () => {
    const generateFieldSize = 4;
    const sizeOfExistingItems = Object.values(netZonesGoalkeeperItems).length;
    const newNetZoneItems: INetZonesGoalkeeperRecord = { ...netZonesGoalkeeperItems };

    for (let i = 0; i < generateFieldSize; i++) {
      newNetZoneItems[(sizeOfExistingItems + i).toString()] = {
        id: (sizeOfExistingItems + i).toString(),
      };
    }

    dispatch(setNetZonesGoalkeeperItems(newNetZoneItems));
  };

  const handleGoalkeeperSelect = (
    selectedPlayer: ISelectOption,
    selectedTeam: ISelectOption,
    index: number,
  ) => {
    const competitionsUuids = filteredParts.map(part => part.id);

    const { requestBodyBase } = prepareBaseRequestBody({});
    const requestBody: IGoalkeeperGoalStatsRequestBody = {
      ...requestBodyBase,
      metrics: [
        'br.sv_percent',
        'bl.sv_percent',
        'ur.sv_percent',
        'ul.sv_percent',
        'fh.sv_percent',
        'br.ga',
        'bl.ga',
        'ur.ga',
        'ul.ga',
        'fh.ga',
      ],
    };

    dispatch(
      getGoalkeeperNetZonesCardInfo({
        competitionsUuids,
        teamUuid: selectedTeam.value,
        playerUuid: selectedPlayer.value,
        goalkeeperIndex: index,
        body: requestBody,
      }),
    );

    dispatch(
      getGoalkeepersNetZoneStats({
        competitionsUuids,
        teamUuid: selectedTeam.value,
        goalkeeperId: selectedPlayer.value,
        goalkeeperIndex: index,
        body: requestBody,
        isPercentile: true,
      }),
    );
  };

  const handleTeamSelect = (selected: ISelectOption) =>
    filterSelectedTeamsGoalkeepers(filteredParts, byId, selected.value);

  const handleOnSubmit = useHandleOnSubmit(() => {
    Object.values(netZonesGoalkeeperItems).forEach((item, index) => {
      if (item.goalkeeperStats && item.teamStats) {
        handleGoalkeeperSelect(
          { label: 'reload', value: item.id },
          { label: 'reload', value: item.id },
          index,
        );
      }
    });
  });

  useRefetchContent({ handleOnSubmit });

  return (
    <div className={classNames()} data-testid='goalkeepers__zones-content'>
      <div className={classNames('goal-nets')}>
        {Object.values(netZonesGoalkeeperItems).map((goalNetStats, index) => {
          if (goalNetStats.error) {
            toast.error(t(ITranslationKeys.noGoalkeeperDataMessage), {
              toastId: ITranslationKeys.noGoalkeeperDataMessage,
            });
          }
          if (!goalNetStats.goalkeeperStats || !goalNetStats.teamStats) {
            return (
              <GoalNetStats
                key={index}
                {...goalNetStats}
                statsValues={{}}
                width={350}
                isEmpty={true}
                hasError={goalNetStats.error}
                teams={filteredTeams}
                onGoalkeeperSelect={(selectedPlayer, selectedTeam) =>
                  handleGoalkeeperSelect(selectedPlayer, selectedTeam, index)
                }
                onTeamSelect={selected => handleTeamSelect(selected)}
                showLoading={
                  goalNetStats.isLoadingGoalkeeperStats || goalNetStats.isLoadingTeamStats
                }
              />
            );
          }

          const statsValues = reduceStatsToGoalNetStatsRecord(goalNetStats.teamStats);
          const goalNetBoxProps: IGoalStatsBoxProps[] = reduceGoalNetBoxMetrics(
            SHORT_NET_ZONES,
            goalNetStats.goalkeeperStats,
          );
          const isEmpty = indexes[index] === undefined;

          return (
            <GoalNetStats
              key={index}
              {...goalNetStats}
              statsValues={statsValues}
              goalNetBox={goalNetBoxProps}
              width={350}
              isEmpty={isEmpty}
              teams={filteredTeams}
              onGoalkeeperSelect={(selectedPlayer, selectedTeam) =>
                handleGoalkeeperSelect(selectedPlayer, selectedTeam, index)
              }
              onTeamSelect={selected => handleTeamSelect(selected)}
            />
          );
        })}
      </div>
      <div className={classNames('more-button')}>
        <Button
          label={ITranslationKeys.moreGoalies}
          iconComponent={<PlusActiveIcon />}
          onClick={() => generateMoreGoalFields()}
          disabled={isStatsLoading}
        />
      </div>
    </div>
  );
};
