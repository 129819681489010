import {
  IFaceoff,
  IFaceoffDTO,
  IFaceoffPlayerInfo,
  IFaceoffPositionDTO,
  IFaceoffPositionKeys,
} from '../../types';

const parseFaceoffPosition = (
  faceoffPosition: IFaceoffPositionDTO,
  positionId: IFaceoffPositionKeys,
): IFaceoff[] => {
  const faceoffs = faceoffPosition.faceOffs.map<IFaceoff>(faceoff => {
    const { matchUuid, playerUuid, awayTeam, homeTeam, opponentUuid, ...restFaceoff } = faceoff;

    return {
      matchId: matchUuid,
      playerId: playerUuid,
      awayTeamId: awayTeam,
      homeTeamId: homeTeam,
      opponentId: opponentUuid,
      positionId,
      ...restFaceoff,
    };
  });

  return faceoffs;
};

export const parseFaceoffsData = (data: IFaceoffDTO, playerUuid?: string): IFaceoffPlayerInfo => {
  const {
    center,
    defenseDefenseLeft,
    defenseDefenseRight,
    defenseNeutralLeft,
    defenseNeutralRight,
    offenseDefenseLeft,
    offenseDefenseRight,
    offenseNeutralLeft,
    offenseNeutralRight,
  } = data;

  const faceoffs: IFaceoff[] = [
    ...parseFaceoffPosition(center, IFaceoffPositionKeys.center),
    ...parseFaceoffPosition(defenseDefenseLeft, IFaceoffPositionKeys.defenseDefenseLeft),
    ...parseFaceoffPosition(defenseDefenseRight, IFaceoffPositionKeys.defenseDefenseRight),
    ...parseFaceoffPosition(defenseNeutralLeft, IFaceoffPositionKeys.defenseNeutralLeft),
    ...parseFaceoffPosition(defenseNeutralRight, IFaceoffPositionKeys.defenseNeutralRight),
    ...parseFaceoffPosition(offenseDefenseLeft, IFaceoffPositionKeys.offenseDefenseLeft),
    ...parseFaceoffPosition(offenseDefenseRight, IFaceoffPositionKeys.offenseDefenseRight),
    ...parseFaceoffPosition(offenseNeutralLeft, IFaceoffPositionKeys.offenseNeutralLeft),
    ...parseFaceoffPosition(offenseNeutralRight, IFaceoffPositionKeys.offenseNeutralRight),
  ];

  return {
    playerId: playerUuid ? playerUuid : 'all',
    faceoffs,
  };
};
