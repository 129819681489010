import { FC } from 'react';

import { logosPaths } from '../../../constants';
import { IHeadToHeadTeamsDividerProps } from '../../../types';
import { createClassNames } from '../../../utils';
import './HeadToHeadTeamsDivider.styles.scss';

const classNames = createClassNames('head-to-head-team-divider');

/**
 * HeadToHeadTeamdDivider UI component dividing the stats by its team.
 */
export const HeadToHeadTeamsDivider: FC<IHeadToHeadTeamsDividerProps> = ({
  homeTeam,
  awayTeam,
}) => {
  return (
    <div className={classNames()} data-testid='head-to-head-team-divider'>
      <img
        className={classNames('home-team')}
        src={logosPaths[homeTeam.id]}
        alt={homeTeam.shortcut}
        width={18}
      />
      <img
        className={classNames('away-team')}
        src={logosPaths[awayTeam.id]}
        alt={awayTeam.shortcut}
        width={18}
      />
      <div className={classNames('home-team-name')}>{homeTeam.shortcut}</div>
      <div className={classNames('away-team-name')}>{awayTeam.shortcut}</div>
    </div>
  );
};
