import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './ShotFlowGraphAxis.styles.scss';

export interface IShotFlowGraphAxisProps {
  /**
   * Minimal value of CA & CF.
   */
  min: number;
  /**
   * Maximal value of CA & CF.
   */
  max: number;
  /**
   * Determines whether the axis is Y axis or not.
   */
  isYAxis: boolean;
}

const classNames = createClassNames('shot-flow-graph-axis');

/**
 * ShotFlowGraphAxis UI component for ShotFlowGraph component.
 */
export const ShotFlowGraphAxis: FC<IShotFlowGraphAxisProps> = ({ min, max, isYAxis }) => {
  const { t } = useTranslation();
  const graphWidth = 496;

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({ isYAxis }),
      })}
      data-testid='shot-flow-graph-axis'
    >
      <div className={classNames('label', { isYAxis })}>
        {t(isYAxis ? ITranslationKeys.shotsAgainst : ITranslationKeys.teamShots)}
      </div>
      {Array.from<number>({ length: (max - min) / 5 + 1 }).map((_, index) => (
        <span
          key={index}
          className={classNames('item')}
          style={{
            right: isYAxis ? `${(graphWidth * (index * 5)) / (max - min)}px` : 'unset',
            left: !isYAxis ? `${(graphWidth * (index * 5)) / (max - min)}px` : 'unset',
          }}
        >
          {index * 5 + min}
        </span>
      ))}
    </div>
  );
};
