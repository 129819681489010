import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  filteredAllTeamsPlayersByPositionSelector,
  selectIndividualStats,
  selectPlayers,
  selectSimilarPlayers,
  selectTableFilter,
} from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IMetricWithDatasetMetric,
  IPlayerRecord,
  IPlayerSimilarity,
  ISelectedPlayerSimilarData,
  ISimilarPlayerNameRecord,
  ISimilarPlayersTableData,
  ISimilarPlayersTableWithHeaderData,
} from '../../../../types';
import { getMetricsWithValue, getPlayerShortName } from '../../../../utils';

const getTableData = (
  playerSimilaritiesFiltered: IPlayerSimilarity[],
  playersRecord: IPlayerRecord,
  metrics: IMetricWithDatasetMetric[],
  similarPlayerNames: ISimilarPlayerNameRecord,
) => {
  const tableData = playerSimilaritiesFiltered
    .reduce<ISimilarPlayersTableData[]>((acc, playerSimilarity) => {
      const player = playersRecord[playerSimilarity.id];

      const playerTeamStats = Object.values(playerSimilarity.teamStats).reduce<
        ISimilarPlayersTableData[]
      >((playerTeamStatsAcc, playerTeam) => {
        const metricsWithValue = getMetricsWithValue(metrics, playerTeam.stats);
        const metricsWithValueRank = getMetricsWithValue(metrics, playerTeam.rank.stats);

        if (player) {
          acc.push({
            entityId: player.id,
            teamId: playerTeam.teamId,
            player: {
              id: player.id,
              displayName: getPlayerShortName(player, similarPlayerNames),
            },
            position: player.position,
            stick: player.stick,
            toi: playerTeam.stats.toi,
            stats: {
              baseStats: metricsWithValue,
              rankStats: metricsWithValueRank,
            },
            total: playerTeam.rank.total,
            positiveDeviation: Math.round(
              (playerTeam.rank.positiveDeviation / playerTeam.rank.deviation) * 100,
            ),
          });
        }

        return playerTeamStatsAcc;
      }, []);

      return acc.concat(playerTeamStats);
    }, [])
    .sort((a, b) => b.toi - a.toi);

  return tableData;
};

export const useDataForTable = () => {
  const { byId, referencedValues, referencedPlayer } = useAppSelector(selectSimilarPlayers);
  const playersWithStats = useAppSelector(selectIndividualStats);
  const { position } = useAppSelector(selectTableFilter);
  const players = useAppSelector(selectPlayers);
  const filteredPlayers = useAppSelector(filteredAllTeamsPlayersByPositionSelector);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const playerSimilaritiesFiltered = useMemo(
    () =>
      Object.values(byId).filter(player =>
        filteredPlayers.map(player => player.id).includes(player.id),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [byId, position],
  );

  const dataWithHeader: ISimilarPlayersTableWithHeaderData = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();

    if (filteredMetrics && referencedPlayer) {
      const indexOfSelectedPlayer = playerSimilaritiesFiltered.findIndex(
        player => player.id === referencedPlayer.id,
      );

      const selectedPlayerSimilarity = playersWithStats.byId[referencedPlayer.id];

      if (indexOfSelectedPlayer === -1) {
        playerSimilaritiesFiltered.splice(indexOfSelectedPlayer, 1);
      }

      const data = getTableData(
        playerSimilaritiesFiltered,
        players.byId,
        filteredMetrics,
        players.similarPlayerNames,
      );

      if (!selectedPlayerSimilarity) {
        return {
          selectedPlayerData: undefined,
          data,
        };
      }

      const player = players.byId[referencedPlayer.id];
      const playerTeamStats = selectedPlayerSimilarity.teamStats[referencedPlayer.teamId];

      const selectedPlayerData: ISelectedPlayerSimilarData = {
        teamId: referencedPlayer.teamId,
        player: {
          id: referencedPlayer.id,
          displayName: getPlayerShortName(player, players.similarPlayerNames),
        },
        position: player.position,
        stick: player.stick,
        toi: playerTeamStats.stats.toi,
        stats: getMetricsWithValue(filteredMetrics, playerTeamStats.stats),
      };

      return {
        selectedPlayerData,
        data,
      };
    }

    return {
      selectedPlayerData: undefined,
      data: [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    playerSimilaritiesFiltered,
    players.byId,
    players.similarPlayerNames,
    referencedValues,
    position,
    getFilteredMetrics,
  ]);

  return dataWithHeader;
};
