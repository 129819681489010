import {
  ColumnDef,
  ColumnPinningState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  BasicColumnCell,
  BasicColumnHeader,
  EyeDropdown,
  ImageColumn,
  PlayerColumnCell,
  PlayerColumnHeader,
  SummaryPercentileColumnCell,
  SummaryPercentileColumnHeader,
} from '../../../../components';
import { logosPaths } from '../../../../constants';
import { selectGoalkeepers } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IColumnsConfig,
  IEntity,
  IGoalkeeperStatsTableData,
  INavigationContentKeys,
  INavigationPageKeys,
  IUrlFilterParams,
} from '../../../../types';
import {
  createDynamicStatsColumnsDef,
  createIndexColumn,
  secondsToMinutesAndSeconds,
} from '../../../../utils';

const columnHelper = createColumnHelper<IGoalkeeperStatsTableData>();

const columnPinning: ColumnPinningState = {
  left: ['actions', 'index', 'teamId', 'player', 'gp', 'toi'],
  right: ['summaryPercentile'],
};

const initialSorting: SortingState = [
  {
    id: 'toi',
    desc: true,
  },
];

const fixedColumns: ColumnDef<IGoalkeeperStatsTableData, any>[] = [
  columnHelper.display({
    id: 'index',
    header: 'P',
    cell: props => createIndexColumn(props),
  }),
  columnHelper.accessor('teamId', {
    header: '',
    cell: props => <ImageColumn src={logosPaths[props.getValue()]} alt='team-logo' />,
  }),
  columnHelper.accessor('player', {
    header: () => <PlayerColumnHeader />,
    cell: props => <PlayerColumnCell {...props} isGoalkeeper />,
  }),
  columnHelper.accessor('gp', {
    header: props => <BasicColumnHeader {...props}>GP</BasicColumnHeader>,
    cell: props => <BasicColumnCell {...props}>{props.getValue()}</BasicColumnCell>,
  }),
  columnHelper.accessor('toi', {
    header: props => <BasicColumnHeader {...props}>TOI</BasicColumnHeader>,
    cell: props => (
      <BasicColumnCell {...props}>{secondsToMinutesAndSeconds(props.getValue())}</BasicColumnCell>
    ),
  }),
];

export const useColumnsConfig = (
  centerPartWidth: number | undefined,
): IColumnsConfig<IGoalkeeperStatsTableData> => {
  const { isPercentilesActive, isPercentilesLoading } = useAppSelector(selectGoalkeepers);
  const getFilteredMetrics = useFilteredMetricsForTable();

  const columns = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(IEntity.goalkeepers);
    const dynamicColumns = createDynamicStatsColumnsDef(
      filteredMetrics,
      columnHelper,
      {
        isPercentilesActive,
        isPercentilesLoading,
      },
      centerPartWidth,
    );

    const conditionalColumns: ColumnDef<IGoalkeeperStatsTableData, any>[] =
      isPercentilesActive && !isPercentilesLoading
        ? [
            columnHelper.accessor('summaryPercentile', {
              header: props => (
                <BasicColumnHeader {...props}>
                  <SummaryPercentileColumnHeader isLastInTable />
                </BasicColumnHeader>
              ),
              cell: props => (
                <BasicColumnCell {...props}>
                  <SummaryPercentileColumnCell value={props.getValue()} />
                </BasicColumnCell>
              ),
            }),
          ]
        : [];

    const actionsColumn = createActionsColumn();

    return [actionsColumn].concat(fixedColumns).concat(dynamicColumns).concat(conditionalColumns);
  }, [isPercentilesActive, isPercentilesLoading, centerPartWidth, getFilteredMetrics]);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};

const createActionsColumn = () =>
  columnHelper.display({
    id: 'actions',
    cell: props => {
      const filterParams: IUrlFilterParams[] = [
        {
          name: 'selectedTeam',
          value: props.row.original.teamId,
        },
        {
          name: 'selectedGoalkeeper',
          value: props.row.original.player.id,
        },
      ];

      return (
        <EyeDropdown
          eyeDropdownTooltipItems={[
            {
              pageTranslationLabel: ITranslationKeys.gamelog,
              navigationPageKey: INavigationPageKeys.goalkeepers,
              navigationTabKey: INavigationContentKeys.gamelog,
              filterParams,
            },
            // TODO: Add goalkeepers trend after implementing it
            // {
            //   pageTranslationLabel: ITranslationKeys.trend,
            //   navigationPageKey: INavigationPageKeys.goalkeepers,
            //   navigationTabKey: INavigationContentKeys.trend,
            //   filterParams,
            // },
            {
              pageTranslationLabel: ITranslationKeys.shots,
              navigationPageKey: INavigationPageKeys.goalkeepers,
              navigationTabKey: INavigationContentKeys.shotmap,
              filterParams,
            },
            {
              pageTranslationLabel: ITranslationKeys.shootouts,
              navigationPageKey: INavigationPageKeys.goalkeepers,
              navigationTabKey: INavigationContentKeys.shootouts,
              filterParams,
            },
          ]}
        />
      );
    },
  });
