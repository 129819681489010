import { CSSProperties, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../app/hooks';
import { selectNavigation } from '../../../features';
import { useLocationPaths, useSetFilterByMetricParams } from '../../../hooks';
import { useVideoCenterFromMetric } from '../../../hooks/useVideoCenter';
import { ITranslationKeys } from '../../../i18n/types';
import {
  IMetricSectionName,
  IMetricWithDatasetMetric,
  IMetricWithRankValues,
} from '../../../types';
import {
  createClassNames,
  getCellColorByPercent,
  getCellColorByValue,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import { Tooltip } from '../../Tooltip';
import { MetricsSectionCircle } from '../../metricComponents';
import { TableMetricTooltip } from '../../tableComponents';
import './MetricColumn.styles.scss';

export interface IMetricColumnHeaderProps {
  stat: IMetricWithDatasetMetric;
  alignToRight?: boolean;
  style?: CSSProperties;
}

export interface IMetricColumnCellProps {
  value: number;
  stat: IMetricWithDatasetMetric;
  entityId: string;
  teamId?: string;
  rowIndex?: number;
  percentileValue?: number;
  isPercentilesShown?: boolean;
  isOrder?: boolean;
  rankValues?: IMetricWithRankValues;
}

const classNames = createClassNames('metric-column-header');

export const MetricColumnHeader: FC<IMetricColumnHeaderProps> = ({ stat, alignToRight, style }) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  if (!stat.metric) return null;

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          metricLine: stat.metric.metricInfo.color,
          isShown,
        }),
      })}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      style={style}
    >
      <div className={classNames('category')}>
        <MetricsSectionCircle sectionId={stat.metric.metricSectionId as IMetricSectionName} />
      </div>
      <div className={classNames('metric-name')}>{stat.label}</div>
      <TableMetricTooltip
        metricInfo={stat.metric.metricInfo}
        isShown={isShown}
        alignToRight={alignToRight}
        datasetMetric={stat.datasetMetric}
      />
    </div>
  );
};

const classNamesCell = createClassNames('metric-column-cell');

export const MetricColumnCell: FC<IMetricColumnCellProps> = ({
  value,
  stat,
  entityId,
  teamId,
  rowIndex,
  percentileValue,
  isPercentilesShown,
  isOrder,
  rankValues,
}) => {
  const { disableMetricVideo } = useAppSelector(selectNavigation);

  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const { t } = useTranslation();

  const { playVideoFromMetric } = useVideoCenterFromMetric();
  const { setFilterByMetricParams, setFilterByMetricSubgroup } = useSetFilterByMetricParams();

  const { activePage, activeTab } = useLocationPaths(); // TODO: remove after implementing goalkeepers and teams videometrics

  const hasLink =
    !disableMetricVideo &&
    !!stat.metric.metricVideoLinkId &&
    !notImplementedYetZoneMetrics.includes(stat.metric.id) &&
    activePage === 'players' &&
    activeTab === 'tabs';

  const onMetricClick = () => {
    if (stat.metric.metricVideoLinkId) {
      setFilterByMetricParams(stat.datasetMetric);
      setFilterByMetricSubgroup(stat, entityId);
      playVideoFromMetric(
        entityId,
        {
          metricLinkId: stat.metric.metricVideoLinkId,
          metricSubgroupId: stat.metric.metricSubgroup.id,
          metricId: stat.metric.id,
        },
        teamId,
      );
    }
  };

  return (
    <div
      className={classNamesCell()}
      onMouseEnter={() => setIsTooltipShown(true)}
      onMouseLeave={() => setIsTooltipShown(false)}
    >
      <div
        className={classNamesCell('value', {
          ...transformObjectKeysToKebabCase({
            hasLink,
          }),
        })}
        onClick={hasLink ? onMetricClick : undefined}
      >
        {value}
      </div>
      {isPercentilesShown && percentileValue !== undefined && percentileValue !== null && (
        <div
          className={classNamesCell('percentile', {
            color: !isOrder
              ? getCellColorByPercent(percentileValue)
              : getCellColorByValue(percentileValue),
          })}
        >
          {rankValues && (
            <Tooltip
              children={
                <span>
                  {t(ITranslationKeys.teamRanking) + ': '}
                  <strong>
                    {rankValues.teamRank.rank}. z {rankValues.teamRank.total}
                  </strong>
                  <br />
                  {t(ITranslationKeys.telhRanking) + ': '}
                  <strong>
                    {rankValues.overallRank.rank}. z {rankValues.overallRank.total}
                  </strong>
                </span>
              }
              isShown={isTooltipShown}
              variant='white'
              width={150}
              centerXAxis
              arrowDirection={rowIndex === 0 ? 'up' : 'down'}
            />
          )}
          {percentileValue}
        </div>
      )}
    </div>
  );
};

const notImplementedYetZoneMetrics = [
  // Possible to implement - type: pass
  'enp',
  'enp60',
  'enpenf_pct',
  // Need to add values from API
  'dir',
  'dir60',
  'dir_pct',
  'diw_pct',
  // Need to add values from API, added already to filter
  'exen',
  'exenf',
  'exen60',
  'exenf60',
  'exen_pct',
  'exenf_pct',
  'exfenf',
  'exfenf60',
  'exfenf_pct',
  'exaena',
  'exaena60',
  'exaena_pct',
  'exd',
  'exd60',
  'exd_pct',
  'exdexdf_pct',
  'exdf',
  'exda',
  'exdf60',
  'exda60',
  'exdf_pct',
  'exda_pct',
  'exdf_pct_rel',
  'exda_pct_rel',
  // Possible to implement maybe
  'ozs',
  'dzs',
  'zsr_percent',
  'dzs_percent',
  'ozs_percent',
  'oz.fowcf',
  'dz.folca',
  'oz.fowcf_pct',
  'dz.folca_pct',
  'oz.fowgf',
  'dz.folga',
  'oz.fowgf_pct',
  'dz.folga_pct',
  'xgf_oz.fow',
  'xga_dz.fol',
];
