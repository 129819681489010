import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IIndividualStatsRequestBody, IPlayerWithTeamIdAndStats } from '../../types';
import { defaultCatchErrorCallback } from '../../utils';
import { parsePlayersWithStatsData } from './parseIndividualStats';

interface IGetPlayersWithStatsParams {
  competitionsUuids: string[];
  body?: IIndividualStatsRequestBody;
  teamUuid?: string;
}

const fetchData = async (
  requestPath: string,
  body?: IIndividualStatsRequestBody,
  teamUuid?: string,
) => {
  const data = await axiosInstance
    .post(requestPath, body)
    .then(response => parsePlayersWithStatsData(response.data, teamUuid))
    .catch(defaultCatchErrorCallback);

  return data;
};

export const getPlayersWithStats = createAsyncThunk<
  IPlayerWithTeamIdAndStats[],
  IGetPlayersWithStatsParams
>('individualStats/getPlayersWithStats', async ({ competitionsUuids, body, teamUuid }) => {
  const requestPath = teamUuid
    ? `/individual/${competitionsUuids}/${teamUuid}`
    : `/individual/${competitionsUuids}`;
  const playersWithStats: IPlayerWithTeamIdAndStats[] = await fetchData(
    requestPath,
    body,
    teamUuid,
  );

  return playersWithStats;
});

export const getPlayersWithStatsPercentile = createAsyncThunk<
  IPlayerWithTeamIdAndStats[],
  IGetPlayersWithStatsParams
>(
  'individualStats/getPlayersWithStatsPercentile',
  async ({ competitionsUuids, body, teamUuid }) => {
    const requestPath = teamUuid
      ? `/individual/${competitionsUuids}/${teamUuid}/percentile`
      : `/individual/${competitionsUuids}/percentile`;
    const playersWithStats: IPlayerWithTeamIdAndStats[] = await fetchData(
      requestPath,
      body,
      teamUuid,
    );

    return playersWithStats;
  },
);
