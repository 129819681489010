import { CSSProperties, FC } from 'react';

import { useSimpleHeatmap } from '../../../hooks';
import { ICoordinates } from '../../../types';
import { calculateHeatmapPointPositionToPercent, createClassNames } from '../../../utils';
import './Heatmap.styles.scss';

export interface IHeatmapProps {
  data: ICoordinates[];
  width?: number;
  height?: number;
  style?: CSSProperties;
}

const classNames = createClassNames('heatmap-component');

export const Heatmap: FC<IHeatmapProps> = ({ data, width = 760, height = 582, style }) => {
  const heatData: [number, number, number][] = data.map(item => {
    const { x, y } = calculateHeatmapPointPositionToPercent(
      { x: item.x, y: item.y },
      height,
      width,
    );
    return [y, x, 1];
  });

  const radius = Math.exp(
    2.7995655 + 0.0000000715628 * Math.pow(data.length, 2) - 0.000545733 * data.length,
  );

  const canvasRef = useSimpleHeatmap(heatData, radius, 33);

  return (
    <div className={classNames()} data-testid='heatmap-component'>
      <canvas style={style} ref={canvasRef} {...{ width, height }} />
    </div>
  );
};
