import {
  IFetchDataConfig,
  IMainFilterForm,
  IMetricVideoIds,
  INavigationContentKeys,
  IZonesFetchDataMode,
} from '../../types';
import {
  useFetchFaceoffs,
  useFetchShootouts,
  useFetchShotsOrPasses,
  useFetchZones,
} from '../fetch';

export const useFetchDataByMetricLinkId = () => {
  const { fetchShots, fetchPasses, fetchGoalkeepersShots, fetchGamesAndToiForShotsOrPasses } =
    useFetchShotsOrPasses();
  const { fetchFaceoffs } = useFetchFaceoffs();
  const { fetchZoneExits, fetchZoneEntries } = useFetchZones();
  const { fetchShootouts } = useFetchShootouts();

  const fetchDataByMetricLinkId = (
    values: IMainFilterForm,
    entityId: string,
    metricIds: IMetricVideoIds,
    teamId?: string,
  ) => {
    const { metricLinkId, metricId, metricSubgroupId } = metricIds;
    const config: IFetchDataConfig = {
      customSelectedPlayerItems: [
        {
          id: '0',
          selectedPlayer: { value: entityId, label: entityId },
          isActive: true,
        },
      ],
      customSelectedTeamId: teamId,
    };

    if (metricLinkId === INavigationContentKeys.shots) {
      fetchShots(values, config);
    }

    if (metricLinkId === INavigationContentKeys.passes) {
      fetchPasses(values, config);
    }

    if (metricLinkId === INavigationContentKeys.faceoffs) {
      fetchFaceoffs(values, config);
    }

    if (metricLinkId === INavigationContentKeys.zoneEntries) {
      const isDumpin =
        metricSubgroupId === 'nahozeni' ||
        metricSubgroupId === 'nahozeni_vedouci_ke_strele' ||
        metricId?.startsWith('dibaw');

      const fetchBoth = metricId?.includes('r_percent') || metricId?.includes('rf_percent');

      const mode = fetchBoth
        ? IZonesFetchDataMode.both
        : isDumpin
        ? IZonesFetchDataMode.dumpInOut
        : IZonesFetchDataMode.entry;

      fetchGamesAndToiForShotsOrPasses(values, {
        ...config,
        onGamesLoad: games => fetchZoneEntries(values, { ...config, games, mode }),
      });
    }

    if (metricLinkId === INavigationContentKeys.zoneExits) {
      const isDumpout =
        metricSubgroupId === 'vyhozeni' || metricSubgroupId === 'vyhozeni_vedouci_ke_strele';

      const fetchBoth =
        metricSubgroupId === 'kontrolovane_vystupy' && metricId?.includes('r_percent');

      const mode = fetchBoth
        ? IZonesFetchDataMode.both
        : isDumpout
        ? IZonesFetchDataMode.dumpInOut
        : IZonesFetchDataMode.entry;

      fetchGamesAndToiForShotsOrPasses(values, {
        ...config,
        onGamesLoad: games => fetchZoneExits(values, { ...config, games, mode }),
      });
    }

    if (metricLinkId === INavigationContentKeys.shootouts) {
      fetchShootouts(values, 'videomaps', config);
    }

    if (metricLinkId === INavigationContentKeys.shotmap) {
      fetchGoalkeepersShots(values, config);
    }
  };

  return {
    fetchDataByMetricLinkId,
  };
};
