import { FC } from 'react';

import { logosPaths } from '../../constants';
import { createClassNames } from '../../utils';
import './LogoCircle.styles.scss';

interface ILogoCircle {
  /**
   * Team uuid used as path for team's logo.
   */
  logoPath: string;
}

const classNames = createClassNames('logo-circle');

/**
 * LogoCircle UI component.
 */
export const LogoCircle: FC<ILogoCircle> = ({ logoPath }) => {
  return (
    <div className={classNames()}>
      <div className={classNames('img-wrapper')}>
        <img src={logosPaths[logoPath]} alt='team-logo' />
      </div>
    </div>
  );
};
