import {
  PaginationState,
  SortingState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Dispatch, SetStateAction, memo, useState } from 'react';

import { IBaseTablesProps } from '../../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../../utils';
import { PaginationControls } from '../../../PaginationControls';
import { TableCell } from '../../TableCell';
import { TableHead } from '../../TableHead';
import './TcmsTable.styles.scss';

const classNames = createClassNames('tcms-table-component');

export interface ITablePaginationConfig {
  itemsOnPage: number;
  displayTableBottomLine?: boolean;
}

export interface ITcmsTableProps<T extends {}> extends IBaseTablesProps<T> {
  disableCellLine?: boolean;
  hasTransparentBackground?: boolean;
  initialSorting?: SortingState;
  paginationConfig?: ITablePaginationConfig;
  pagination?: PaginationState;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
  showHeader?: boolean;
  isForUserLog?: boolean;
  setPage?: Dispatch<SetStateAction<number>>;
  totalAvailableItems?: number;
  customSorting?: SortingState;
  setCustomSorting?: () => void;
}

export const TcmsTable = memo(
  <T extends {}>({
    data,
    columns,
    initialSorting,
    paginationConfig,
    pagination = { pageIndex: 0, pageSize: 15 },
    setPagination,
    showHeader = true,
    disableCellLine = false,
    hasTransparentBackground = false,
    isForUserLog = false,
    setPage,
    totalAvailableItems,
    customSorting,
    setCustomSorting,
  }: ITcmsTableProps<T>) => {
    const [sorting, setSorting] = useState<SortingState>(initialSorting || []);

    const table = useReactTable({
      data,
      columns,
      state: {
        sorting: customSorting || sorting,
        pagination,
      },
      onSortingChange: customSorting ? setCustomSorting : setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      onPaginationChange: setPagination,
      manualSorting: !!customSorting,
    });

    const handleMoveToPreviousPage = () => {
      table.previousPage();
    };
    const handleMoveToNextPage = () => {
      table.nextPage();

      if (!setPage) return;
      const { pageIndex, pageSize } = pagination;
      const offset = (pageIndex + 1) * pageSize;
      const preloadedCount = 2 * pageSize;
      const hasNextData = totalAvailableItems && data.length < totalAvailableItems;

      if (offset + preloadedCount > data.length && hasNextData) {
        setPage?.(prevPage => prevPage + 1);
      }
    };

    return (
      <div
        className={classNames({
          ...transformObjectKeysToKebabCase({
            disableCellLine,
            showHeader,
            hasGradient: !hasTransparentBackground,
            isForUserLog,
          }),
        })}
      >
        <div className={classNames('table')}>
          <table>
            {showHeader && <TableHead headerGroup={table.getHeaderGroups()} variant='tcms' />}
            <tbody>
              {table.getRowModel().rows.map((row, index, { length }) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <TableCell
                      key={cell.id}
                      cell={cell}
                      variant='tcms'
                      disableBorder={disableCellLine || index === length - 1}
                      height={38}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {paginationConfig && (
          <div
            className={classNames('pagination', {
              ...transformObjectKeysToKebabCase({
                displayTableBottomLine: paginationConfig.displayTableBottomLine,
              }),
            })}
          >
            <PaginationControls
              displaySeparationLine={paginationConfig.displayTableBottomLine}
              moveToPrevious={handleMoveToPreviousPage}
              moveToNext={handleMoveToNextPage}
              pagination={pagination.pageIndex + 1}
              pageCount={
                totalAvailableItems
                  ? Math.ceil(totalAvailableItems / paginationConfig.itemsOnPage)
                  : table.getPageCount()
              }
            />
          </div>
        )}
      </div>
    );
  },
) as <T extends {}>(props: ITcmsTableProps<T>) => JSX.Element;
