import { FC, useState } from 'react';

import { IShotFlowGraphPlayerInfo, IShotFlowGraphPlayerWithCoordinates } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './PlayerCircle.styles.scss';

export interface IPlayerCircleProps extends IShotFlowGraphPlayerWithCoordinates {
  /**
   * Mouse enter event handler.
   * @param playerInfo Player info to be displayed in the tooltip.
   */
  onMouseEnter?: (playerInfo: IShotFlowGraphPlayerInfo) => void;
  /**
   * Mouse leave event handler.
   */
  onMouseLeave?: () => void;
}

const classNames = createClassNames('player-circle');

/**
 * Circle component for ShotFlowGraph component.
 */
export const PlayerCircle: FC<IPlayerCircleProps> = ({
  playerInfo,
  team,
  x,
  y,
  size,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
    onMouseEnter?.(playerInfo);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    onMouseLeave?.();
  };

  return (
    <div
      className={classNames({ ...transformObjectKeysToKebabCase({ team }) })}
      data-testid='player-circle'
      style={{
        width: `${size}px`,
        height: `${size}px`,
        left: `${isHovered ? x - 2 : x}px`,
        top: `${isHovered ? y - 2 : y}px`,
        transform: `translate(-${size / 2}px, -${size / 2}px)`,
        zIndex: size,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={classNames('name')} style={{ marginLeft: `${size}px` }}>
        {playerInfo.name}
      </div>
    </div>
  );
};
