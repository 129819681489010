import { ITranslationKeys } from '../../i18n/types';
import { ISelectOption } from '../../types';

export const countOfPlayersDefaultOption: ISelectOption = { value: '5:5', label: '5/5' };
export const countOfPlayersEvenStrengthOption: ISelectOption = {
  value: 'ES',
  label: ITranslationKeys.evenStrength,
};
export const countOfPlayersFiveToFourOption: ISelectOption = { value: '5:4', label: '5/4' };
export const countOfPlayersPowerPlaysOption: ISelectOption = {
  value: 'PP',
  label: ITranslationKeys.powerPlaysShort,
};

export const countOfPlayersOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.allSituations, isAllOption: true },
  countOfPlayersDefaultOption,
  countOfPlayersFiveToFourOption,
  { value: '4:5', label: '4/5' },
  { value: '3:3', label: '3/3' },
  countOfPlayersEvenStrengthOption,
  countOfPlayersPowerPlaysOption,
  { value: 'SH', label: ITranslationKeys.penaltyKilling },
  { value: '6:5', label: '6/5' },
  { value: '5:6', label: '5/6' },
];
export const countOfPlayersH2HOptions: ISelectOption[] = [
  countOfPlayersDefaultOption,
  { value: '3:3', label: '3/3' },
  countOfPlayersEvenStrengthOption,
  { value: '6:5', label: '6/5' },
  { value: '5:6', label: '5/6' },
];

export const countOfPlayersGamesBlockOneOptions: ISelectOption[] = [
  countOfPlayersDefaultOption,
  countOfPlayersEvenStrengthOption,
];

export const countOfPlayersGamesBlockTwoOptions: ISelectOption[] = [
  countOfPlayersPowerPlaysOption,
  countOfPlayersFiveToFourOption,
];

export const formationTypeDefaultOption: ISelectOption = {
  value: '5:5',
  label: ITranslationKeys.formationAll,
};

export const formationTypeOptions: ISelectOption[] = [
  formationTypeDefaultOption,
  { value: '5:5-FO', label: ITranslationKeys.formationForwards },
  { value: '5:5-DE', label: ITranslationKeys.formationDefensemen },
  countOfPlayersFiveToFourOption,
  { value: '4:5', label: '4/5' },
  { value: '3:3', label: '3/3' },
];

export const formationTypeOptionsWithAllSituations: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.allSituations, isAllOption: true },
  ...formationTypeOptions,
];
