import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { createClassNames } from '../../../utils';
import { PlusMinusBar } from '../../PlusMinusBar';
import './DeviationColumn.styles.scss';

export interface IDeviationColumnCellProps {
  value: number;
}

const classNames = createClassNames('deviation-column-header');

export const DeviationColumnHeader = () => {
  const { t } = useTranslation();

  return <div className={classNames()}>{t(ITranslationKeys.positiveDeviation)}</div>;
};

const classNamesCell = createClassNames('deviation-column-cell');

export const DeviationColumnCell: FC<IDeviationColumnCellProps> = ({ value }) => (
  <div className={classNamesCell()}>
    <PlusMinusBar percent={value} />
    <div className={classNamesCell('value')}>{value}%</div>
  </div>
);
