import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './MetricTooltip.styles.scss';

export interface IMetricTooltipProps {
  /**
   * Content for tooltip.
   */
  header: string;
  /**
   * A translated label to be displayed.
   */
  description: ITranslationKeys | string;
  /**
   * Whether the tooltip is shown or not.
   */
  isShown?: boolean;
}

const classNames = createClassNames('metric-tooltip');

/**
 * Tooltip component for displaying additional information above some element.
 */
export const MetricTooltip: FC<IMetricTooltipProps> = ({ header, description, isShown }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isShown,
        }),
      })}
      data-testid='metric-tooltip'
    >
      <h4>{header}</h4>
      <span>{t(description)}</span>
    </div>
  );
};
