import { ColumnDef, ColumnPinningState, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  BasicColumnCell,
  BasicColumnHeader,
  EyeDropdown,
  FormationColumnCell,
  ImageColumn,
} from '../../../../components';
import { logosPaths } from '../../../../constants';
import { useFilteredMetricsForTable } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IColumnsConfig,
  IGamesTablesFormationsTableData,
  INavigationContentKeys,
  INavigationPageKeys,
  IUrlFilterParams,
} from '../../../../types';
import { createDynamicStatsColumnsDef, secondsToMinutesAndSeconds } from '../../../../utils';

const columnHelper = createColumnHelper<IGamesTablesFormationsTableData>();

const columnPinning: ColumnPinningState = {
  left: ['actions', 'players', 'teamId', 'toi'],
};

const fixedColumns: ColumnDef<IGamesTablesFormationsTableData, any>[] = [
  columnHelper.accessor('players', {
    header: () => '',
    cell: props => <FormationColumnCell {...props} />,
  }),
  columnHelper.accessor('teamId', {
    header: '',
    cell: props => <ImageColumn src={logosPaths[props.getValue()]} alt='team-logo' />,
  }),
  columnHelper.accessor('toi', {
    header: props => <BasicColumnHeader {...props}>TOI</BasicColumnHeader>,
    cell: props => (
      <BasicColumnCell {...props}>{secondsToMinutesAndSeconds(props.getValue())}</BasicColumnCell>
    ),
  }),
];

export const useColumnsConfig = (
  centerPartWidth: number | undefined,
): IColumnsConfig<IGamesTablesFormationsTableData> => {
  const getFilteredMetrics = useFilteredMetricsForTable();

  const columns = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();
    const dynamicColumns = createDynamicStatsColumnsDef(
      filteredMetrics,
      columnHelper,
      undefined,
      centerPartWidth,
    );
    const actionsColumn = createActionsColumn();

    return [actionsColumn].concat(fixedColumns).concat(dynamicColumns);
    // eslint-disable-next-line
  }, [centerPartWidth, getFilteredMetrics]);

  return {
    columns,
    columnPinning,
  };
};

const createActionsColumn = () =>
  columnHelper.display({
    id: 'actions',
    cell: props => {
      const players = [
        ...props.row.original.players.firstRow,
        ...props.row.original.players.secondRow,
      ];
      const playerIds = players.map(player => player.id).join(',');

      const filterParams: IUrlFilterParams[] = [
        {
          name: 'selectedTeam',
          value: props.row.original.teamId,
        },
        {
          name: 'selectedPlayerItems',
          value: playerIds,
        },
      ];

      return (
        <EyeDropdown
          eyeDropdownTooltipItems={[
            {
              pageTranslationLabel: ITranslationKeys.gamelog,
              navigationPageKey: INavigationPageKeys.players,
              navigationTabKey: INavigationContentKeys.gamelog,
              filterParams,
            },
            {
              pageTranslationLabel: ITranslationKeys.trend,
              navigationPageKey: INavigationPageKeys.players,
              navigationTabKey: INavigationContentKeys.trend,
              filterParams,
            },
            {
              pageTranslationLabel: ITranslationKeys.combinations,
              navigationPageKey: INavigationPageKeys.formations,
              navigationTabKey: INavigationContentKeys.playerCombinations,
              filterParams,
            },
          ]}
        />
      );
    },
  });
