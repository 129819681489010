import { ITranslationKeys } from '../i18n/types';
import {
  IMainFilterForm,
  IMainFilterFormPreloadDataInputs,
  IMainFilterFormStandardInputs,
  INavigationContentKeys,
  INetZone,
  IPlayerColors,
  ISelectedPlayersRecord,
  ITimePeriodTableForm,
} from '../types';
import {
  countOfPlayersDefaultOption,
  formationTypeDefaultOption,
  placeOptions,
  scoreStateOptions,
} from './selectOptionsConstants';

export const GOALKEEPER_CARDS_METRICS = [
  'br.sv_percent',
  'bl.sv_percent',
  'ur.sv_percent',
  'ul.sv_percent',
  'fh.sv_percent',
  'br.ga',
  'bl.ga',
  'ur.ga',
  'ul.ga',
  'fh.ga',
];

export const BASE_TEMPLATE_NAME = 'zakladni_sablona';
export const DEFAULT_CONTENT_TEMPLATE_NAMES: Record<string, string> = {
  [INavigationContentKeys.gamelog]: 'gamelog',
  [INavigationContentKeys.similarityPlayers]: 'shoda_hracu',
  [INavigationContentKeys.report]: 'gamelog',
  [INavigationContentKeys.formations]: 'gamelog',
  [INavigationContentKeys.players]: 'gamelog',
  [INavigationContentKeys.goalkeepers]: 'gamelog',
};

export const SELECT_PLAYERS_INPUT_COLORS: Record<string, IPlayerColors> = {
  '0': IPlayerColors.red,
  '1': IPlayerColors.purple,
  '2': IPlayerColors.cyan,
  '3': IPlayerColors.teal,
  '4': IPlayerColors.orange,
};

export const INITIAL_SELECTED_PLAYER_ITEMS: ISelectedPlayersRecord = {
  '0': { id: '0' },
  '1': { id: '1' },
  '2': { id: '2' },
  '3': { id: '3' },
  '4': { id: '4' },
};

export const HEATMAP_COLORS = [
  'red1',
  'red2',
  'red3',
  'red4',
  'red5',
  'white',
  'blue5',
  'blue4',
  'blue3',
  'blue2',
  'blue1',
];

/** Main filter form default values that do not need to reset on data preload. */
export const DEFAULT_MAIN_FILTER_FORM_STANDARD_VALUES: IMainFilterFormStandardInputs = {
  countOfPlayers: countOfPlayersDefaultOption,
  selectedOpponents: [],
  selectedPlayer: null,
  selectedGoalkeeper: null,
  lastPlayedMatches: '',
  dateFromTo: undefined,
  place: placeOptions[0],
  scoreState: scoreStateOptions.find(option => option.value === 'all'),
  gameTimeFrom: '',
  gameTimeTo: '',
  timeOnIce: '',
  selectedGame: null,
  formationType: formationTypeDefaultOption,
};

/** Main filter form default values that need to be reset on data preload. */
export const DEFAULT_MAIN_FILTER_FORM_PRELOAD_DATA_VALUES: IMainFilterFormPreloadDataInputs = {
  selectedSeasons: [],
  selectedParts: [],
  selectedTeam: null,
  opponentsAtGamesPage: null,
};

/** All main filter form default values. */
export const DEFAULT_MAIN_FILTER_FORM_VALUES: IMainFilterForm = {
  ...DEFAULT_MAIN_FILTER_FORM_PRELOAD_DATA_VALUES,
  ...DEFAULT_MAIN_FILTER_FORM_STANDARD_VALUES,
};

export const REQUIRED_PLAYER = { value: true, message: ITranslationKeys.pleaseSelectTeamAndPlayer };
export const REQUIRED_GOALKEEPER = {
  value: true,
  message: ITranslationKeys.pleaseSelectTeamAndGoalkeeper,
};
export const REQUIRED_ARRAY = {
  value: true,
  message: ITranslationKeys.requiredArray,
};

export const IS_USER_DATASET = 'isUserDataset';

export const NET_ZONES: INetZone[] = [
  INetZone.ur,
  INetZone.ce,
  INetZone.ul,
  INetZone.br,
  INetZone.fh,
  INetZone.bl,
];

export const SHORT_NET_ZONES: INetZone[] = [
  INetZone.br,
  INetZone.bl,
  INetZone.ul,
  INetZone.ur,
  INetZone.fh,
];

export const DISABLED_TABS_ON_OFF: INavigationContentKeys[] = [
  INavigationContentKeys.faceoffs,
  INavigationContentKeys.shootouts,
];

export const DEFAULT_TIME_PERIOD_TABLE_FORM_VALUES: ITimePeriodTableForm = {
  timePeriodByGames: [{ from: undefined, to: undefined }],
  timePeriodByDate: [{ from: undefined, to: undefined }],
};
