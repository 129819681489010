import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import {
  IPass,
  IPassAndShotInfoRequestParams,
  IPassesAndShotsRequestParams,
  IPassesRequestBody,
  ISharedShotsAndPassesInfo,
} from '../../types';
import { buildPath, defaultCatchErrorCallback, urlBooleanParamResolver } from '../../utils';
import { parseGetPassesData } from './parsePasses';

interface IGetPassesParams extends IPassesAndShotsRequestParams {
  isFormation?: boolean;
  isAgainst?: boolean;
  body?: IPassesRequestBody;
}

const getPassesRequestUrl = (params: Omit<IGetPassesParams, 'body'>): string => {
  const { competitionsUuids, teamUuid, isFormation, isAgainst } = params;
  const base = `/pass/${competitionsUuids}/${teamUuid}`;

  return buildPath(base, [
    urlBooleanParamResolver(isFormation, 'formation'),
    urlBooleanParamResolver(isAgainst, 'against'),
  ]);
};

/** Post requests */
export const getPasses = createAsyncThunk<IPass[], IGetPassesParams>(
  'passes/getPasses',
  async ({ competitionsUuids, teamUuid, isFormation, isAgainst, body }) => {
    const requestPath = getPassesRequestUrl({
      competitionsUuids,
      teamUuid,
      isFormation,
      isAgainst,
    });

    const passes: IPass[] = await axiosInstance
      .post(requestPath, body ?? {})
      .then(response => parseGetPassesData(response.data))
      .catch(defaultCatchErrorCallback);

    return passes;
  },
);

/** Get request */ // TODO: Otestovat
export const getPassInfo = createAsyncThunk<
  ISharedShotsAndPassesInfo,
  IPassAndShotInfoRequestParams
>('passes/getPassInfo', async ({ match, player, time }) => {
  const passInfo: ISharedShotsAndPassesInfo = await axiosInstance
    .get('/pass', { params: { match, player, time: time.toString() } })
    .then(response => response.data)
    .catch(defaultCatchErrorCallback);

  return passInfo;
});
