import { createSelector } from '@reduxjs/toolkit';

import { IGoalkeeper, ISelectOption, ITeam } from '../../types';
import {
  filteredGoalkeepersSelector,
  filteredPartsSelector,
  filteredTeamsSelector,
} from '../mainFilter';
import { selectMainFilter } from '../selectors';

const parseGoalkeeperToOptionWithTeamInLabel = (
  filteredTeams: ITeam[],
  goalkeeper: IGoalkeeper,
  goalkeeperTeamId: string,
): ISelectOption => {
  const playerTeam = filteredTeams.find(team => team.id === goalkeeperTeamId);
  const teamShortcut = playerTeam ? playerTeam.shortcut : '';
  const label = [teamShortcut, goalkeeper.surname, goalkeeper.name].join(' ');

  return {
    value: goalkeeper.id,
    label,
    additionalValue: playerTeam?.id,
  };
};

export const filteredGoalkeepersForCompareOptionsSelector = createSelector(
  [selectMainFilter, filteredGoalkeepersSelector, filteredTeamsSelector, filteredPartsSelector],
  (mainFilterState, filteredGoalkeepers, filteredTeams, filteredParts) => {
    const { selectedTeam } = mainFilterState;
    if (!selectedTeam) {
      return [];
    }
    const selectedTeamId = selectedTeam.value;

    const filteredGoalkeepersForCompareOptions = filteredGoalkeepers
      .reduce<ISelectOption[]>((acc, goalkeeper) => {
        const goalkeeperCompetitionTeams = Object.values(goalkeeper.competitionTeams).filter(
          competitionTeam => filteredParts.some(part => part.id === competitionTeam.id),
        );

        if (selectedTeamId && selectedTeamId === 'all') {
          const alwaysOnlyOneCompetitionSelected = goalkeeperCompetitionTeams[0];

          const goalkeeperOptions: ISelectOption[] = alwaysOnlyOneCompetitionSelected.teamIds.map(
            teamId => {
              return parseGoalkeeperToOptionWithTeamInLabel(filteredTeams, goalkeeper, teamId);
            },
          );
          return acc.concat(goalkeeperOptions);
        }

        const goalkeeperTeamId = goalkeeperCompetitionTeams[0].teamIds.find(
          teamId => teamId === selectedTeamId,
        );

        if (!goalkeeperTeamId) {
          return acc;
        }

        return acc.concat(
          parseGoalkeeperToOptionWithTeamInLabel(filteredTeams, goalkeeper, goalkeeperTeamId),
        );
      }, [])
      .sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
      });

    return filteredGoalkeepersForCompareOptions;
  },
);
