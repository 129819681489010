import {
  IAccessLogAggregationEntityRecord,
  IDateRangeString,
  ILogHokejPlayer,
  IMainTrackingFilterForm,
  IRoleLogAggregationDateRecord,
  IScreenLogTabRecord,
} from '../../types';
import { createDateFromTo, parseDateToAtomString } from '../../utils';
import { api } from './api';
import {
  parseAccessLogAggregationData,
  parseRoleLogAggregationData,
  parseScreenLogAggregationData,
} from './utils';

const getDateFromToParams = (date: IDateRangeString | undefined) => {
  if (!date || !date.from) return undefined;

  const from = parseDateToAtomString(new Date(date.from));
  const to = date.to
    ? parseDateToAtomString(new Date(createDateFromTo(date.to, 1).to!))
    : undefined;

  return {
    from,
    to: to || parseDateToAtomString(new Date(createDateFromTo(date.from, 1).to!)),
  };
};

type IGetLogAggregationParams = Pick<IMainTrackingFilterForm, 'dateFromTo'>['dateFromTo'];
type IGetRoleLogAggregationParams = {
  dateFromTo: IGetLogAggregationParams;
  aggregation: string;
  lastSubmittedDateTo: string | undefined;
};

export const trackingApi = api.injectEndpoints({
  endpoints: builder => ({
    getAccessLogAggregationHl: builder.query<
      IAccessLogAggregationEntityRecord,
      IGetLogAggregationParams
    >({
      query: date => ({
        url: '/log/access-log-aggregation/log-iq',
        method: 'get',
        params: {
          ...getDateFromToParams(date),
        },
      }),
      transformResponse: parseAccessLogAggregationData,
    }),
    getAccessLogAggregationEc: builder.query<
      IAccessLogAggregationEntityRecord,
      IGetLogAggregationParams
    >({
      query: date => ({
        url: '/log/access-log-aggregation/extra-coach',
        method: 'get',
        params: {
          ...getDateFromToParams(date),
        },
      }),
      transformResponse: parseAccessLogAggregationData,
    }),
    getRoleLogAggregationHl: builder.query<
      IRoleLogAggregationDateRecord,
      IGetRoleLogAggregationParams
    >({
      query: ({ dateFromTo, aggregation }) => ({
        url: '/log/role-log-aggregation/log-iq',
        method: 'get',
        params: {
          ...getDateFromToParams(dateFromTo),
          aggregation,
        },
      }),
      transformResponse: parseRoleLogAggregationData,
      serializeQueryArgs: ({ queryArgs: { dateFromTo, ...restArgs } }) => restArgs,
      forceRefetch: ({ currentArg, previousArg }) => sharedForceRefetch(currentArg, previousArg),
      merge: (currentData, newData) => {
        Object.values(newData).forEach(item => {
          currentData[item.date] = item;
        });
      },
    }),
    getRoleLogAggregationEc: builder.query<
      IRoleLogAggregationDateRecord,
      IGetRoleLogAggregationParams
    >({
      query: ({ dateFromTo, aggregation }) => ({
        url: '/log/role-log-aggregation/extra-coach',
        method: 'get',
        params: {
          ...getDateFromToParams(dateFromTo),
          aggregation,
        },
      }),
      transformResponse: parseRoleLogAggregationData,
      serializeQueryArgs: ({ queryArgs: { dateFromTo, ...restArgs } }) => restArgs,
      forceRefetch: ({ currentArg, previousArg }) => sharedForceRefetch(currentArg, previousArg),
      merge: (currentData, newData) => {
        Object.values(newData).forEach(item => {
          currentData[item.date] = item;
        });
      },
    }),
    getScreenLogAggregationHl: builder.query<IScreenLogTabRecord, IGetLogAggregationParams>({
      query: date => ({
        url: '/log/screen-log-aggregation/log-iq',
        method: 'get',
        params: {
          ...getDateFromToParams(date),
        },
      }),
      transformResponse: parseScreenLogAggregationData,
    }),
    getScreenLogAggregationEc: builder.query<IScreenLogTabRecord, IGetLogAggregationParams>({
      query: date => ({
        url: '/log/screen-log-aggregation/extra-coach',
        method: 'get',
        params: {
          ...getDateFromToParams(date),
        },
      }),
      transformResponse: parseScreenLogAggregationData,
    }),
    getLogHokejPlayer: builder.query<ILogHokejPlayer[], IGetLogAggregationParams>({
      query: date => ({
        url: '/log/extra-player',
        method: 'get',
        params: {
          ...getDateFromToParams(date),
        },
      }),
      transformResponse: (data: ILogHokejPlayer[]) => data.filter(item => !!item.team),
    }),
  }),
});

export const {
  useGetAccessLogAggregationHlQuery,
  useGetAccessLogAggregationEcQuery,
  useGetRoleLogAggregationHlQuery,
  useGetRoleLogAggregationEcQuery,
  useGetScreenLogAggregationHlQuery,
  useGetScreenLogAggregationEcQuery,
  useGetLogHokejPlayerQuery,
} = trackingApi;

const sharedForceRefetch = (
  currentArg?: IGetRoleLogAggregationParams,
  previousArg?: IGetRoleLogAggregationParams,
) => {
  if (!previousArg || !currentArg) {
    return false;
  }
  return currentArg?.dateFromTo !== previousArg?.dateFromTo;
};
