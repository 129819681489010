import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './OverviewCard.styles.scss';

export interface IOverviewCardProps {
  /**
   * Used as title for the card.
   */
  label: ITranslationKeys;
  /**
   * String representing % or numerical value of usage.
   */
  value: string;
  /**
   * Additional value added to the end of the label.
   */
  labelValue?: string;
  /**
   * Variant of the card.
   * @default "default"
   */
  variant?: 'default' | 'gradient';
}

const classNames = createClassNames('overview-card');

/**
 * OverviewCard component.
 */
export const OverviewCard: FC<IOverviewCardProps> = ({
  label,
  value,
  labelValue,
  variant = 'default',
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({ variant }),
      })}
    >
      {variant === 'default' ? (
        <>
          <h3 className={classNames('title')}>{t(label)}</h3>
          <p className={classNames('value')}>{value}</p>
        </>
      ) : (
        <>
          <p className={classNames('value')}>{value}</p>
          <div className={classNames('label')}>
            {t(label)}
            {labelValue && <span> {labelValue}</span>}
          </div>
        </>
      )}
    </div>
  );
};
