import {
  ExpandedState,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { memo, useState } from 'react';

import { ICommonTableProps, ITableGetSubRows } from '../../../../types';
import { createClassNames } from '../../../../utils';
import { TableHead } from '../../TableHead';
import { TableRow } from '../../TableRow';
import { useTableResize } from '../useTableResize';
import './SubrowsTable.styles.scss';

export interface ISubrowsTableProps<T extends {}>
  extends ICommonTableProps<T>,
    ITableGetSubRows<T> {
  highlightMainRow?: boolean;
  hideFirstRowInMainRow?: boolean;
  disableFirstCellBorder?: boolean;
  isAlwaysExpanded?: boolean;
}

const classNames = createClassNames('subrows-table-component');

export const SubrowsTable = memo(
  <T extends {}>({
    data,
    columns,
    columnPinning,
    initialSorting,
    centerPartRef,
    cellsHeight,
    highlightMainRow,
    hideFirstRowInMainRow,
    disableFirstCellBorder,
    isAlwaysExpanded,
    extraRowHeaderRender,
    getSubRows,
  }: ISubrowsTableProps<T>) => {
    const { leftPartRef, rightPartRef, leftPartWidth, rightPartWidth } = useTableResize();

    const [sorting, setSorting] = useState<SortingState>(initialSorting || []);
    const [expanded, setExpanded] = useState<ExpandedState>({});

    const table = useReactTable({
      data,
      columns,
      state: {
        sorting,
        columnPinning,
        expanded: isAlwaysExpanded ? true : expanded,
      },
      onSortingChange: setSorting,
      getExpandedRowModel: getExpandedRowModel(),
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onExpandedChange: setExpanded,
      getSubRows,
    });

    return (
      <div className={classNames()}>
        <table ref={leftPartRef} className={classNames('left-part')}>
          <TableHead
            headerGroup={table.getLeftHeaderGroups()}
            extraHeaderRow={extraRowHeaderRender?.('left')}
          />
          <tbody>
            {table.getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                row={row}
                cells={row.getLeftVisibleCells()}
                cellsHeight={cellsHeight}
                highlightMainRow={highlightMainRow}
                hideFirstRowInMainRow={hideFirstRowInMainRow}
                disableFirstCellBorder={disableFirstCellBorder}
              />
            ))}
          </tbody>
        </table>
        <div
          ref={centerPartRef}
          className={classNames('center-part')}
          style={{
            paddingLeft: leftPartWidth,
            paddingRight: rightPartWidth,
          }}
        >
          <table className={classNames('center-part__table')}>
            <TableHead
              headerGroup={table.getCenterHeaderGroups()}
              tablePart={'center'}
              extraHeaderRow={extraRowHeaderRender?.('center')}
            />
            <tbody>
              {table.getRowModel().rows.map(row => (
                <TableRow
                  key={row.id}
                  row={row}
                  cells={row.getCenterVisibleCells()}
                  cellsHeight={cellsHeight}
                  highlightMainRow={highlightMainRow}
                  hideFirstRowInMainRow={hideFirstRowInMainRow}
                />
              ))}
            </tbody>
          </table>
        </div>
        <table ref={rightPartRef} className={classNames('right-part')}>
          <TableHead
            headerGroup={table.getRightHeaderGroups()}
            tablePart={'right'}
            extraHeaderRow={extraRowHeaderRender?.('right')}
          />
          <tbody>
            {table.getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                row={row}
                cells={row.getRightVisibleCells()}
                cellsHeight={cellsHeight}
                highlightMainRow={highlightMainRow}
                hideFirstRowInMainRow={hideFirstRowInMainRow}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  },
) as <T extends {}>(props: ISubrowsTableProps<T>) => JSX.Element;
