import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n/i18n';
import { ITranslationKeys } from '../../i18n/types';
import { IChangelog } from '../../types';
import { createClassNames, formatDateByLanguage } from '../../utils';
import { LoginButton } from '../loginComponents';
import './Changelog.styles.scss';

export interface IChangelogProps {
  /** Changelog object. */
  changelog: IChangelog;
  /** Callback to invoke when the button for close modal is clicked. */
  handleClose: () => void;
}

const classNames = createClassNames('changelog');

/** Component that displays developer's release notes. */
export const Changelog: FC<IChangelogProps> = ({ changelog, handleClose }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      <div className={classNames('log-text')}>
        {Object.values(changelog).map(item => (
          <div key={item.id}>
            <div className={classNames('log-text__version-header')}>
              <span>
                {t(ITranslationKeys.version)} {item.id}
              </span>
              {formatDateByLanguage(new Date(item.date), 'P')}
            </div>
            <ul>
              {item.changes.map((change, index) => (
                <li key={index}>{i18n.language === 'en' ? change.en : change.cz}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <LoginButton label={ITranslationKeys.close} type='button' onClick={handleClose} />
    </div>
  );
};
