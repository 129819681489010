import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectMainFilter,
  selectSettings,
  setCustomMetrics,
  setDataTemplate,
} from '../../../features';
import { useLocationPaths } from '../../../hooks';
import { ITranslationKeys } from '../../../i18n/types';
import {
  IDatasetMetric,
  IGamesReportBlockStats,
  INavigationContentKeys,
  INavigationPageKeys,
} from '../../../types';
import {
  createClassNames,
  getCellColorByValue,
  getMetricLabel,
  isTimeMetric,
  roundNumberTo2Decimals,
  secondsToMinutesAndSeconds,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import { LogoCircle } from '../../LogoCircle';
import { TopPlayers } from '../../TopPlayers';
import './GameReportColumn.styles.scss';

export interface IGameReportColumnHeaderProps {
  valueLabel?: ITranslationKeys | string;
  percentValueLabel?: ITranslationKeys | string;
  hasLogos?: boolean;
  type?: 'match' | 'competition';
}

export interface IGameReportColumnCellProps<TData, TValue> extends CellContext<TData, TValue> {
  stats: IGamesReportBlockStats;
  blockType: 'reportEvenStrength' | 'reportPowerPlay';
  isPercentColored?: boolean;
}

const classNames = createClassNames('game-report-column-header');

export const GameReportColumnHeader: FC<IGameReportColumnHeaderProps> = ({
  valueLabel,
  percentValueLabel,
  hasLogos = false,
  type = 'match',
}) => {
  const { selectedGame } = useAppSelector(selectMainFilter);
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      <div className={classNames('labels')}>
        <div className={classNames('labels__label')}>{valueLabel ? t(valueLabel) : ''}</div>
        <div className={classNames('labels__label')}>
          {percentValueLabel ? t(percentValueLabel) : ''}
        </div>
      </div>
      {hasLogos && selectedGame && (
        <div className={classNames('logos')}>
          <LogoCircle logoPath={selectedGame.homeTeamId} />
          <div className={classNames('logos__label')}>
            {type === 'match' ? t(ITranslationKeys.inMatch) : t(ITranslationKeys.inSeason)}
          </div>
          <LogoCircle logoPath={selectedGame.awayTeamId} />
        </div>
      )}
    </div>
  );
};

const classNamesCell = createClassNames('game-report-column-cell');

export const GameReportColumnCell = <TData, TValue>({
  row,
  stats,
  blockType,
  isPercentColored = false,
}: IGameReportColumnCellProps<TData, TValue>) => {
  const {
    metrics: { metrics },
    reportMetrics: { reportMetrics },
  } = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();
  const { navigate } = useLocationPaths();

  const { value, percent, code, topPlayers } = stats;

  const handleAllPlayersClick = () => {
    if (!reportMetrics) return;
    const reportMetricsInfo = reportMetrics[blockType as keyof typeof reportMetrics];
    const metricInfo = reportMetricsInfo?.find(metric => metric.code === code);

    if (!metricInfo) return;
    const { parameter, playerCode, playerOrigin, value } = metricInfo;
    const metric = parameter
      ? metrics?.players.byId[playerOrigin]
      : metrics?.players.byId[playerCode];
    const datasetMetric: IDatasetMetric = {
      code: playerCode,
      origin: playerOrigin,
      parameter,
      value,
    };

    const metricLabel = getMetricLabel(metric, datasetMetric);

    dispatch(setDataTemplate(undefined));
    navigate(INavigationPageKeys.games, INavigationContentKeys.players);
    if (metric) {
      dispatch(
        setCustomMetrics([
          {
            value: playerCode,
            label: metricLabel,
            additionalInfo: datasetMetric,
          },
        ]),
      );
    }
  };

  const roundedPercent = percent !== undefined ? roundNumberTo2Decimals(percent) : '';
  const roundedValue = isTimeMetric(code)
    ? secondsToMinutesAndSeconds(Math.round(value))
    : roundNumberTo2Decimals(value);

  return (
    <div className={classNamesCell({ expanded: row.getIsExpanded() })}>
      <div className={classNamesCell('values-box')}>
        <div className={classNamesCell('value')}>{roundedValue}</div>
        <div
          className={classNamesCell('value', {
            ...transformObjectKeysToKebabCase({
              color:
                isPercentColored && getCellColorByValue(roundedPercent !== '' ? roundedPercent : 0),
            }),
          })}
        >
          {roundedPercent}
        </div>
      </div>
      {row.getIsExpanded() && (
        <TopPlayers
          topPlayers={topPlayers}
          metricCode={code}
          handleAllPlayersClick={handleAllPlayersClick}
        />
      )}
    </div>
  );
};
