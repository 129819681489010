import { CellContext, HeaderContext } from '@tanstack/react-table';
import { CSSProperties, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { SortableArrowIcon } from '../../../icons';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './BasicColumn.styles.scss';

export interface IBasicColumnHeaderProps<TData, TValue> extends HeaderContext<TData, TValue> {
  children?: ReactNode;
  translationKey?: ITranslationKeys;
  allowSortIcon?: boolean;
  justifyContent?: CSSProperties['justifyContent'];
  variant?: 'default' | 'tcms';
}

export interface IBasicColumnCellProps<TData, TValue> extends CellContext<TData, TValue> {
  children?: ReactNode;
  variant?: 'default' | 'tcms';
  color?: 'default' | 'blue' | 'slate';
}

const classNamesHeader = createClassNames('basic-column-header');

export const BasicColumnHeader = <TData, TValue>({
  column,
  children,
  translationKey,
  allowSortIcon = false,
  justifyContent = 'center',
  variant = 'default',
}: IBasicColumnHeaderProps<TData, TValue>) => {
  const { t } = useTranslation();
  const isSortable = column.getCanSort();
  const sortDirection = column.getIsSorted();

  return (
    <div
      className={classNamesHeader({
        ...transformObjectKeysToKebabCase({
          isSortable,
          variant,
        }),
      })}
      onClick={isSortable ? column.getToggleSortingHandler() : undefined}
      style={{ justifyContent }}
    >
      {translationKey && t(translationKey)}
      {children}
      {allowSortIcon && isSortable && (
        <div className={classNamesHeader('arrows', { sorted: !!sortDirection })}>
          <SortableArrowIcon
            className={classNamesHeader('arrows__arrow', {
              ...transformObjectKeysToKebabCase({
                sortDirection,
                variant,
              }),
            })}
          />
        </div>
      )}
    </div>
  );
};

const classNamesCell = createClassNames('basic-column-cell');

export const BasicColumnCell = <TData, TValue>({
  column,
  children,
  variant = 'default',
  color = 'default',
}: IBasicColumnCellProps<TData, TValue>) => {
  const isSortable = column.getCanSort();
  const isSorted = !!column.getIsSorted();

  return (
    <div
      className={classNamesCell({
        ...transformObjectKeysToKebabCase({
          isSorted: isSortable && isSorted,
          color,
          variant,
        }),
      })}
    >
      {children}
    </div>
  );
};
