import { FC } from 'react';

import { createClassNames } from '../../../utils';
import { Skeleton } from '../../Skeleton';
import './TableSkeleton.styles.scss';

export interface ITableSkeletonProps {
  rowsCount: number;
  columnsCount: number;
  disablePagination?: boolean;
}

const classNames = createClassNames('table-skeleton');

export const TableSkeleton: FC<ITableSkeletonProps> = ({
  rowsCount,
  columnsCount,
  disablePagination = false,
}) => {
  return (
    <div className={classNames()}>
      <div className={classNames('header')}>
        {Array.from(Array(columnsCount)).map((_, index) => (
          <Skeleton key={index} height={16} borderRadius={8} />
        ))}
      </div>
      <div className={classNames('rows')}>
        {Array.from(Array(rowsCount)).map((_, index) => (
          <Skeleton key={index} height={24} borderRadius={8} />
        ))}
      </div>
      {!disablePagination && (
        <div className={classNames('pagination')}>
          <Skeleton height={38} borderRadius={8} />
          <Skeleton height={38} borderRadius={8} />
        </div>
      )}
    </div>
  );
};
