import { CSSProperties, FC } from 'react';

import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './TeamMetricLegend.styles.scss';

export interface ITeamMetricLegendProps {
  teamShortcut: string;
  metricName: string;
  metricValue?: number;
  isAway?: boolean;
  width?: CSSProperties['width'];
}

const classNames = createClassNames('team-metric-legend');

export const TeamMetricLegend: FC<ITeamMetricLegendProps> = ({
  teamShortcut,
  metricName,
  metricValue = 0,
  isAway = false,
  width = '100%',
}) => (
  <div className={classNames()} style={{ width }} data-testid='team-metric-legend'>
    <div className={classNames('title')}>{teamShortcut}</div>
    <div
      className={classNames('metric-box', {
        ...transformObjectKeysToKebabCase({
          isAway,
        }),
      })}
    >
      {`${metricValue.toFixed(2)} ${metricName}`}
    </div>
  </div>
);
