import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { IVideoControls } from '../../../types';
import { createClassNames, secondsToMinutesAndSeconds } from '../../../utils';
import { CheckboxWithDescription } from '../../CheckboxWithDescription';
import { VideoRowActions } from '../VideoRowActions';
import { IVideoCenterActions } from '../types';
import './VideoEventRow.styles.scss';

export interface IVideoEventRowProps extends Partial<IVideoControls>, IVideoCenterActions {
  checked: boolean;
  /** Video clip's player jersey */
  jersey: string;
  /** Video clip's player name */
  name: string;
  /** Video clip's player surname */
  surname: string;
  /** Video clip's player's team shortcut */
  teamShortcut?: string;
  /** Video clip's game state */
  gameState?: string;
  /** Video clip's action time */
  time?: number;
  /** Video clip's event name (pass, shot, faceoff etc.) */
  shiftEventId?: ITranslationKeys | string;
  /** Function that handles click on row's checkbox */
  onCheckboxClick: () => void;
}

const classNames = createClassNames('video-event-row');

/** UI component for display info about event info in video center modal. */
export const VideoEventRow: FC<IVideoEventRowProps> = ({
  checked,
  jersey,
  name,
  surname,
  teamShortcut,
  gameState,
  time,
  shiftEventId,
  cutBefore,
  cutAfter,
  videoTime,
  isEdited,
  hasDataListIcon = false,
  onPlay,
  onVideocoachNote,
  onCroppedTime,
  onCheckboxClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      <div className={classNames('info')}>
        <CheckboxWithDescription checked={checked} onClick={onCheckboxClick} />
        {time !== undefined && (
          <p className={classNames('time')}>{secondsToMinutesAndSeconds(time)}</p>
        )}
        {teamShortcut && <p className={classNames('team-and-game-state')}>{teamShortcut}</p>}
        <p className={classNames('team-and-game-state')}>{gameState || '?'}</p>
        {shiftEventId && <p className={classNames('shift-event')}>{t(shiftEventId)}</p>}
        <p>
          #{jersey} {surname} {name?.[0]}.
        </p>
      </div>
      <div className={classNames('info')}>
        <VideoRowActions
          videoTime={videoTime}
          cutBefore={cutBefore}
          cutAfter={cutAfter}
          isEdited={isEdited}
          onPlay={onPlay}
          onVideocoachNote={onVideocoachNote}
          onCroppedTime={onCroppedTime}
          hasDataListIcon={hasDataListIcon}
        />
      </div>
    </div>
  );
};
