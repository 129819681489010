import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ChartSummary, LogBarChart, Skeleton, SummaryPieChart } from '../../../../../../components';
import { ITranslationKeys } from '../../../../../../i18n/types';
import {
  IAccessLogAggregationEntityRecord,
  IAnalyticsType,
  ITrackingUserType,
} from '../../../../../../types';
import { createClassNames } from '../../../../../../utils';
import { IOverviewComponentsProps } from '../types';
import './AccessLogOverview.styles.scss';

interface IAccessLogOverviewProps extends IOverviewComponentsProps {
  /** Loaded data */
  data: IAccessLogAggregationEntityRecord | undefined;
  /** Error */
  error: unknown;
  /** Is data fetching? */
  isFetching: boolean;
}

const classNames = createClassNames('access-log-overview');

export const AccessLogOverview: FC<IAccessLogOverviewProps> = ({
  data,
  error,
  isFetching,
  submitValues,
}) => {
  const { selectedAnalytics, dateFromTo } = submitValues;

  const { t } = useTranslation();

  if (isFetching) {
    return (
      <div className={classNames()}>
        <Skeleton />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={classNames({ error: true })}>{t(ITranslationKeys.defaultErrorMessage)}</div>
    );
  }

  const analyticsType = selectedAnalytics.value as IAnalyticsType;
  const total = Object.values(data).reduce<number>(
    (acc, item) => acc + item.analytics[analyticsType],
    0,
  );

  const totalTeam = Object.values(data).reduce<number>((acc, item) => {
    if (item.type === ITrackingUserType.team) {
      return acc + item.analytics[analyticsType];
    }

    return acc;
  }, 0);
  const totalMedia = Object.values(data).reduce<number>((acc, item) => {
    if (item.type === ITrackingUserType.media) {
      return acc + item.analytics[analyticsType];
    }

    return acc;
  }, 0);

  return (
    <div className={classNames()}>
      <div className={classNames('header')}>
        <ChartSummary header={total} {...{ dateFromTo, selectedAnalytics }} />
        <SummaryPieChart total={totalTeam + totalMedia} totalMedia={totalMedia} />
      </div>
      <LogBarChart
        data={Object.values(data)}
        analyticsType={analyticsType}
        total={total}
        height={229}
      />
    </div>
  );
};
