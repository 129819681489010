import { FC, MouseEventHandler } from 'react';

import { ButtonDataIcon, EditIcon, PlayIcon } from '../../../icons';
import { IVideoControls } from '../../../types';
import { createClassNames } from '../../../utils';
import { CroppedTime } from '../../CroppedTime';
import { IVideoCenterActions } from '../types';
import './VideoRowActions.styles.scss';

export interface IVideoRowActionsProps extends Partial<IVideoControls>, IVideoCenterActions {}

const classNames = createClassNames('video-row-actions');

export const VideoRowActions: FC<IVideoRowActionsProps> = ({
  videoTime,
  cutBefore,
  cutAfter,
  isEdited,
  hasDataListIcon = false,
  dataListItemsCount,
  onPlay,
  onVideocoachNote,
  onCroppedTime,
}) => {
  const handlePlay: MouseEventHandler<SVGSVGElement> = event => {
    event.stopPropagation();
    onPlay();
  };

  const handleVideocoachNote: MouseEventHandler<SVGSVGElement> = event => {
    event.stopPropagation();
    onVideocoachNote?.();
  };

  return (
    <div className={classNames()}>
      <div className={classNames('cropped-time')}>
        <CroppedTime {...{ videoTime, cutBefore, cutAfter, isEdited }} onClick={onCroppedTime} />
      </div>
      {onVideocoachNote !== undefined && (
        <EditIcon onClick={handleVideocoachNote} className={classNames('action')} />
      )}
      <PlayIcon onClick={handlePlay} className={classNames('action')} />
      {hasDataListIcon && (
        <div className={classNames('data-list')}>
          {!!dataListItemsCount && <ButtonDataIcon className={classNames('action')} />}
        </div>
      )}
    </div>
  );
};
