import { InputHTMLAttributes, forwardRef, useState } from 'react';

import { EyeIcon, EyeOffIcon, LockIcon, MailIcon } from '../../../icons';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './LoginInput.styles.scss';

interface ILoginInputProps extends InputHTMLAttributes<HTMLInputElement> {
  title: string;
  placeholder: string;
  value: string;
  name: string;
  isError: boolean;
  setValue: (newValue: string) => void;
  maxWidth?: number;
  type?: 'email' | 'password';
  enableOnEnterSubmit?: boolean;
}

const classNames = createClassNames('login-input');

export const LoginInput = forwardRef<HTMLInputElement, ILoginInputProps>(
  (
    {
      title,
      placeholder,
      value,
      name,
      isError,
      setValue,
      maxWidth,
      type = 'email',
      enableOnEnterSubmit,
      ...props
    },
    ref,
  ) => {
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
      setValue(e.currentTarget.value);
    };

    const handleOnKeyDown = (event: any) => {
      if (enableOnEnterSubmit) {
        if (props.onKeyDown) {
          props.onKeyDown(event);
        }
      } else {
        if (event.key === 'Enter') {
          event.preventDefault();
        }

        if (props.onKeyDown) {
          props.onKeyDown(event);
        }
      }
    };

    const [showPassword, setShowPassword] = useState(false);
    const prefixIcon = type === 'email' ? <MailIcon /> : <LockIcon />;

    return (
      <div
        className={classNames(
          transformObjectKeysToKebabCase({
            isError,
          }),
        )}
        style={{ maxWidth }}
      >
        <label className={classNames('title')} htmlFor={name}>
          {title}
        </label>
        <div className={classNames('input')}>
          <input
            type={type === 'password' && showPassword ? 'text' : type}
            placeholder={placeholder}
            value={value}
            id={name}
            onChange={handleChange}
            onKeyDown={handleOnKeyDown}
            autoComplete='on'
            {...props}
          />
          <div className={classNames('input__prefix-icon')}>{prefixIcon}</div>
          <div className={classNames('input__suffix-icon')}>
            {type === 'password' ? (
              showPassword ? (
                <EyeIcon onClick={() => setShowPassword(false)} />
              ) : (
                <EyeOffIcon onClick={() => setShowPassword(true)} />
              )
            ) : null}
          </div>
        </div>
      </div>
    );
  },
);
