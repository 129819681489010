import {
  ColumnDef,
  ColumnPinningState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import { BasicColumnCell, BasicColumnHeader, ImageColumn, Sticker } from '../../../../components';
import { logosPaths } from '../../../../constants';
import { useFilteredMetricsForTable } from '../../../../hooks';
import { IColumnsConfig, IPlayerCombinationsTableData } from '../../../../types';
import {
  createDynamicStatsColumnsDef,
  createIndexColumn,
  secondsToMinutesAndSeconds,
} from '../../../../utils';

const columnHelper = createColumnHelper<IPlayerCombinationsTableData>();

const columnPinning: ColumnPinningState = {
  left: ['index', 'teamId', 'player', 'gp', 'toi'],
};

const initialSorting: SortingState = [
  {
    id: 'toi',
    desc: true,
  },
];

const fixedColumns: ColumnDef<IPlayerCombinationsTableData, any>[] = [
  columnHelper.display({
    id: 'index',
    header: '',
    cell: props => createIndexColumn(props),
  }),
  columnHelper.accessor('teamId', {
    header: '',
    cell: props => <ImageColumn src={logosPaths[props.getValue()]} alt='team-logo' />,
  }),
  columnHelper.accessor('player', {
    header: '',
    cell: props => (
      <span style={{ display: 'flex' }}>
        <Sticker key={props.getValue().id} label={props.getValue().displayName} size='small' />
      </span>
    ),
  }),
  columnHelper.accessor('gp', {
    header: props => <BasicColumnHeader {...props}>GP</BasicColumnHeader>,
    cell: props => <BasicColumnCell {...props}>{props.getValue()}</BasicColumnCell>,
  }),
  columnHelper.accessor('toi', {
    header: props => <BasicColumnHeader {...props}>TOI</BasicColumnHeader>,
    cell: props => (
      <BasicColumnCell {...props}>{secondsToMinutesAndSeconds(props.getValue())}</BasicColumnCell>
    ),
  }),
];

export const useColumnsConfig = (
  centerPartWidth: number | undefined,
): IColumnsConfig<IPlayerCombinationsTableData> => {
  const getFilteredMetrics = useFilteredMetricsForTable();

  const columns = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();
    const dynamicColumns = createDynamicStatsColumnsDef(
      filteredMetrics,
      columnHelper,
      undefined,
      centerPartWidth,
    );

    return fixedColumns.concat(dynamicColumns);
  }, [centerPartWidth, getFilteredMetrics]);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};
