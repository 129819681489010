import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';

import { createClassNames } from '../../../utils';
import './TcmsChartTooltip.styles.scss';

const classNames = createClassNames('tcms-chart-tooltip');

export const TcmsChartTooltip: FC<TooltipProps<number, string>> = ({
  active,
  separator = ' = ',
  payload,
}) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    return (
      <div className={classNames()}>
        {payload
          .map((item, index) =>
            item.dataKey ? (
              <div key={index} className={classNames('line')}>
                <p style={{ color: item.color }}>{`${t(
                  item.name ?? item.dataKey.toString(),
                )}${separator}`}</p>
                <p style={{ color: item.color }}>{item.value}</p>
              </div>
            ) : null,
          )
          .reverse()}
      </div>
    );
  }

  return null;
};
