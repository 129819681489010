import { CellContext } from '@tanstack/react-table';
import { ReactNode } from 'react';

import { PlayIcon } from '../../../icons';
import { IPlayerForTable, ITableTeamAndPlayer } from '../../../types';
import { createClassNames } from '../../../utils';
import './VideoCenterColumn.styles.scss';

export interface IVideoCenterColumnCellProps<T extends ITableTeamAndPlayer>
  extends CellContext<T, IPlayerForTable> {
  children: ReactNode;
  onClick: () => void;
}

const classNames = createClassNames('video-center-column');

export const VideoCenterColumnCell = <T extends ITableTeamAndPlayer>({
  children,
  onClick,
}: IVideoCenterColumnCellProps<T>) => {
  return (
    <div className={classNames()} onClick={onClick}>
      <PlayIcon />
      <div className={classNames('content')}>{children}</div>
    </div>
  );
};
