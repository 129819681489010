import { ISelectOption } from './common.types';

export const IRole = {
  admin: 'admin',
  media: 'media',
  user: 'user',
  team: 'team',
  client: 'client',
  test: 'test',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IRole = (typeof IRole)[keyof typeof IRole];

export interface IRoleMediaTeam {
  role: IRole;
  media: string | null;
  team: string | null;
}

export interface IIdentityAccessLogData {
  total: number;
  inLastThirtyDays: number;
  lastAccessAt: string;
}

export interface IIdentityAccessLog {
  HL: IIdentityAccessLogData;
  HLEC: IIdentityAccessLogData;
}

export interface IBaseIdentity {
  id: string;
  name: string;
  surname: string;
  role: IRole;
  teamId: string | null;
  media: string | null;
}

export interface IIdentity extends IBaseIdentity {
  username: string;
  disabled: boolean;
  accessLog?: IIdentityAccessLog;
}

export interface INewIdentityParams extends Omit<IIdentity, 'id' | 'disabled' | 'accessLog'> {
  password: string;
}

export interface IIdentityState {
  identities: IIdentity[];
  hasBeenIdentitiesLoaded?: boolean;
  isLoading?: boolean;
  error?: string;
}

export interface INewIdentityForm extends Omit<INewIdentityParams, 'role' | 'teamId' | 'media'> {
  role: ISelectOption;
  teamOrMediaOption: ISelectOption | null;
}

export interface IIdentitiesTableData extends IIdentity {}
