import { useAppDispatch, useAppSelector } from '../app/hooks';
import { BugReport } from '../components';
import { selectBugReport, setShowBugReport } from '../features';

export const useBugReportSidebar = () => {
  const dispatch = useAppDispatch();
  const { showBugReportSidebar, type, videoInfo } = useAppSelector(selectBugReport);

  const handleCloseBugReportSidebar = () => {
    dispatch(setShowBugReport({ show: false }));
  };

  const getBugReportSidebar = (pageName: string, tabName: string) => {
    return (
      <BugReport
        isOpened={showBugReportSidebar}
        onClose={handleCloseBugReportSidebar}
        type={type}
        pageName={pageName}
        tabName={tabName}
        videoInfo={videoInfo}
      />
    );
  };

  return getBugReportSidebar;
};
