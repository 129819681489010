import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import {
  IDatasetMetricRecord,
  IEntity,
  INewUserDataset,
  IUserDataset,
  IUserDatasetContent,
  IUserDatasetDTO,
} from '../../types';
import { defaultCatchErrorCallback } from '../../utils';
import { parseGetUserDatasetsData, parseUserDatasetData } from './parseUserDatasets';

interface IBaseChangeUserDatasetParams {
  datasetId: string;
}

interface IRenameUserDatasetParams extends IBaseChangeUserDatasetParams {
  newName: string;
}

interface IChangeUserDatasetMetricsParams extends IBaseChangeUserDatasetParams {
  newDatasetMetrics: IDatasetMetricRecord;
  entity: IEntity;
}

interface IReturnNewDatasetInfo {
  id: string;
}

export const getUserDatasets = createAsyncThunk<IUserDataset[]>(
  'userDatasets/getUserDatasets',
  async () => {
    const userDatasets: IUserDataset[] = await axiosInstance
      .get<IUserDatasetDTO[]>(`/template`)
      .then(response => parseGetUserDatasetsData(response.data))
      .catch(defaultCatchErrorCallback);

    return userDatasets;
  },
);

export const getUserDatasetById = createAsyncThunk<IUserDataset, string>(
  'userDatasets/getUserDatasetById',
  async id => {
    const userDataset: IUserDataset = await axiosInstance
      .get<IUserDatasetDTO>(`/template/${id}`)
      .then(response => parseUserDatasetData(response.data))
      .catch(defaultCatchErrorCallback);

    return userDataset;
  },
);

export const createUserDataset = createAsyncThunk<IReturnNewDatasetInfo, INewUserDataset>(
  'userDatasets/createUserDataset',
  async ({ name, datasetMetrics, entity }) => {
    const newDatasetInfo: IReturnNewDatasetInfo = await axiosInstance
      .post(`/template`, {
        name,
        content: stringifyContent(datasetMetrics, entity),
      })
      .then(response => response.data)
      .catch(defaultCatchErrorCallback);

    return newDatasetInfo;
  },
);

export const deleteUserDatasetById = createAsyncThunk<number, string>(
  'userDatasets/deleteUserDatasetById',
  async id => {
    const status = await axiosInstance
      .delete(`/template/${id}`)
      .then(response => response.status)
      .catch(defaultCatchErrorCallback);

    return status;
  },
);

export const changeUserDatasetName = createAsyncThunk<number, IRenameUserDatasetParams>(
  'userDatasets/changeUserDatasetName',
  async ({ datasetId, newName }) => {
    const status = await axiosInstance
      .put(`/template/${datasetId}/name`, { name: newName })
      .then(response => response.status)
      .catch(defaultCatchErrorCallback);

    return status;
  },
);

export const changeUserDatasetMetrics = createAsyncThunk<number, IChangeUserDatasetMetricsParams>(
  'userDatasets/changeUserDatasetMetrics',
  async ({ datasetId, newDatasetMetrics, entity }) => {
    const status = await axiosInstance
      .put(`/template/${datasetId}/content`, {
        content: stringifyContent(newDatasetMetrics, entity),
      })
      .then(response => response.status)
      .catch(defaultCatchErrorCallback);

    return status;
  },
);

const stringifyContent = (datasetMetrics: IDatasetMetricRecord, entity: IEntity) => {
  const contentToStringify: IUserDatasetContent = {
    datasetMetrics,
    entity,
  };
  return JSON.stringify(contentToStringify);
};
