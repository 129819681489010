import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IFormationsState } from '../../types';
import {
  getFormations,
  getFormationsPlayersStats,
  getFormationsTeamOrderStats,
} from './formationsActions';

const initialState: IFormationsState = {
  byId: {},
  allIds: [],
  isLoading: false,
  isLoadedFirstTime: undefined,
  isTeamOrderActive: undefined,
  isTeamOrderLoading: undefined,
  error: undefined,
  isAllTeamsLoaded: undefined,
  errorTeamOrder: undefined,
  teammateState: {
    byId: {},
    allIds: [],
    isLoading: undefined,
    error: undefined,
  },
  opponentState: {
    byId: {},
    allIds: [],
    isLoading: undefined,
    error: undefined,
  },
  lastLoadedTeamId: undefined,
};

export const formationsSlice = createSlice({
  name: 'formations',
  initialState,
  reducers: {
    resetTeamStatsLoadedData: state => {
      state.byId = {};
      state.allIds = [];
      state.isLoadedFirstTime = false;
      state.isAllTeamsLoaded = false;
    },
    setIsTeamOrderActive: (state, action: PayloadAction<boolean>) => {
      state.isTeamOrderActive = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getFormations.pending, state => {
        state.isLoading = true;
        state.isAllTeamsLoaded = false;
        state.error = undefined;
      })
      .addCase(getFormations.fulfilled, (state, action) => {
        const { payload, meta } = action;
        if (!state.byId[payload.teamId]) {
          state.allIds.push(payload.teamId);
        }
        state.byId[payload.teamId] = {
          teamId: payload.teamId,
          stats: payload.stats,
        };
        if (meta.arg.isLast) {
          state.isLoadedFirstTime = true;
          state.isAllTeamsLoaded = true;
        }
        state.lastLoadedTeamId = meta.arg.teamUuid ?? undefined;
        state.isLoading = false;
      })
      .addCase(getFormations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(getFormationsTeamOrderStats.pending, state => {
        state.isTeamOrderLoading = true;
        state.errorTeamOrder = undefined;
      })
      .addCase(getFormationsTeamOrderStats.fulfilled, (state, action) => {
        const { payload, meta } = action;
        if (!state.byId[payload.teamId]) {
          state.allIds.push(payload.teamId);
        }
        state.byId[payload.teamId].teamOrder = payload.stats;
        if (meta.arg.isLast) {
          state.isTeamOrderLoading = false;
        }
      })
      .addCase(getFormationsTeamOrderStats.rejected, (state, action) => {
        state.isTeamOrderActive = false;
        state.errorTeamOrder = action.error.message;
      })
      // Next action case
      .addCase(getFormationsPlayersStats.pending, (state, action) => {
        if (action.meta.arg.isOpponent) {
          state.opponentState.byId = {};
          state.opponentState.allIds = [];
          state.opponentState.isLoading = true;
          state.opponentState.error = undefined;
        } else {
          state.teammateState.byId = {};
          state.teammateState.allIds = [];
          state.teammateState.isLoading = true;
          state.teammateState.error = undefined;
        }
      })
      .addCase(getFormationsPlayersStats.fulfilled, (state, action) => {
        const { payload, meta } = action;

        if (meta.arg.isOpponent) {
          payload.forEach(playerStat => {
            const { id, stats } = playerStat;
            if (!state.opponentState.byId[id]) {
              state.opponentState.allIds.push(id);
            }
            state.opponentState.byId[id] = { id, stats };
          });
          state.opponentState.isLoading = false;
        } else {
          payload.forEach(playerStat => {
            const { id, stats } = playerStat;
            if (!state.teammateState.byId[id]) {
              state.teammateState.allIds.push(id);
            }
            state.teammateState.byId[id] = { id, stats };
          });
          state.teammateState.isLoading = false;
        }
      })
      .addCase(getFormationsPlayersStats.rejected, (state, action) => {
        if (action.meta.arg.isOpponent) {
          state.opponentState.isLoading = false;
          state.opponentState.error = action.error.message;
        } else {
          state.teammateState.isLoading = false;
          state.teammateState.error = action.error.message;
        }
      });
  },
});

export const { resetTeamStatsLoadedData, setIsTeamOrderActive } = formationsSlice.actions;

export const formationsReducer = formationsSlice.reducer;
