import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Changelog, Loading, Modal, SelectInput } from '../../components';
import { LoginButton, LoginInput } from '../../components/loginComponents';
import { IS_PRODUCTION, IS_TEST } from '../../constants';
import { login, selectAuth } from '../../features';
import { ITranslationKeys } from '../../i18n/types';
import { CzechFlagLoginIcon, ECLoginIcon, EnglishFlagLoginIcon, HLLoginIcon } from '../../icons';
import { changelog } from '../../jsons';
import { ISelectOption } from '../../types';
import { createClassNames, formatDateByLanguage } from '../../utils';
import './LoginPage.styles.scss';

const LANGUAGE_OPTIONS: ISelectOption[] = [
  { value: 'cz', label: 'Česky', icon: <CzechFlagLoginIcon /> },
  { value: 'en', label: 'English', icon: <EnglishFlagLoginIcon /> },
];

export interface ILoginInputs {
  username: string;
  password: string;
  language: string;
}

const classNames = createClassNames('login-page');

export const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const { error, isLoading } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const [isChangelogOpen, setIsChangelogOpen] = useState(false);

  const { control, handleSubmit } = useForm<ILoginInputs>({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  useEffect(() => {
    if (error) {
      toast.error(t(error), { toastId: 'loginError' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onSubmit = (loginInputs: ILoginInputs) => {
    const { username, password } = loginInputs;
    if (username && password) {
      const resolution = window.screen.width + 'x' + window.screen.height;
      dispatch(login({ username, password, resolution }));
    } else {
      toast.error(t(ITranslationKeys.fillAllInputs), { toastId: 'fillInputs' });
    }
  };

  const onChangeLanguage = (option: ISelectOption) => {
    i18n.changeLanguage(option.value);
    localStorage.setItem('language', option.value);
  };

  const openChangelog = () => {
    setIsChangelogOpen(true);
  };

  const closeChangelog = () => {
    setIsChangelogOpen(false);
  };

  const lastVersion = Object.values(changelog)[0];
  const selectedLanguage = LANGUAGE_OPTIONS.find(options => options.value === i18n.language);
  const buildTime = process.env.REACT_APP_BUILD_TIME
    ? formatDateByLanguage(new Date(process.env.REACT_APP_BUILD_TIME), 'P')
    : 'Unknown build time';

  return (
    <div className={classNames()}>
      {isLoading && (
        <div className='gray-background'>
          <Loading />
        </div>
      )}
      <div className={classNames('content')}>
        <form className={classNames('content__form')} noValidate onSubmit={handleSubmit(onSubmit)}>
          <HLLoginIcon />
          <span className={classNames('content__form__app-state')}>
            {IS_PRODUCTION ? null : IS_TEST ? '(TEST) ' + buildTime : '(STG) ' + buildTime}
          </span>
          <Controller
            name='username'
            control={control}
            render={({ field }) => (
              <LoginInput
                {...field}
                title='E-mail'
                type='email'
                placeholder='E-mail'
                setValue={field.onChange}
                isError={!!error}
                data-testid='email-input'
                enableOnEnterSubmit
              />
            )}
          />
          <Controller
            name='password'
            control={control}
            render={({ field }) => (
              <LoginInput
                {...field}
                title={t(ITranslationKeys.password)}
                type='password'
                placeholder={t(ITranslationKeys.password)}
                setValue={field.onChange}
                isError={!!error}
                data-testid='password-input'
                enableOnEnterSubmit
              />
            )}
          />
          <div className={classNames('content__form__language')}>
            <label>{t(ITranslationKeys.language)}</label>
            <SelectInput
              onChange={onChangeLanguage}
              selected={{
                value: i18n.language,
                label: selectedLanguage?.label || '',
              }}
              iconComponent={selectedLanguage?.icon}
              options={LANGUAGE_OPTIONS}
              variant='login'
            />
          </div>
          <LoginButton label={ITranslationKeys.login} />
        </form>
        <div className={classNames('content__version')}>
          {t(ITranslationKeys.version)} {lastVersion.id}{' '}
          {`(${formatDateByLanguage(new Date(lastVersion.date), 'P')})`}
        </div>
        <div className={classNames('content__changelog')} onClick={openChangelog}>
          Changelog
        </div>
      </div>
      <div className={classNames('login-footer')}>
        <Link to='https://hokejcoach.cz/' target='_blank' rel='noreferrer'>
          <div className={classNames('login-footer__ec-app')}>
            <ECLoginIcon />
            {t(ITranslationKeys.goToApp)}
          </div>
        </Link>
        <hr />
        <div className={classNames('login-footer__copyright')}>
          &copy; {new Date().getFullYear()}{' '}
          <a href={`${t(ITranslationKeys.bpaUrl)}`} target='_blank' rel='noreferrer'>
            {t(ITranslationKeys.bpaSportMarketing)}
          </a>
          {' & '}
          <a href={`${t(ITranslationKeys.eSportsUrl)}`} target='_blank' rel='noreferrer'>
            {t(ITranslationKeys.eSports)}
          </a>
        </div>
      </div>
      <Modal open={isChangelogOpen} size='medium'>
        <Changelog changelog={changelog} handleClose={closeChangelog} />
      </Modal>
    </div>
  );
};
