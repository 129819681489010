import { createSlice } from '@reduxjs/toolkit';

import { IAuthState } from '../../types';
import { getLoggedUser, login } from './authActions';

const initialState: IAuthState = {
  user: undefined,
  token: undefined,
  isLoading: false,
  isUserLoggedIn: false,
  error: undefined,
  loginDate: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      state.user = undefined;
      state.token = undefined;
      state.isUserLoggedIn = false;
      state.loginDate = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.token = action.payload;
        state.isLoading = false;
        state.isUserLoggedIn = true;
        state.loginDate = new Date().toString();
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(getLoggedUser.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getLoggedUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
      })
      .addCase(getLoggedUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { logout } = authSlice.actions;

export const authReducer = authSlice.reducer;
