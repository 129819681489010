import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { IPlaygroundBarBoxes } from '../../types';
import { PlaygroundBarBox } from '../PlaygroundBarBox/PlaygroundBarBox';
import './PlaygroundBarBoxList.styles.scss';

export interface IPlaygroundBarBoxListProps {
  /** Boxes for playground bar. */
  boxes: IPlaygroundBarBoxes;
  /** A heading above the boxes. */
  heading: ITranslationKeys;
  /** True when the playground component is reversed. */
  isReversed?: boolean;
  /** True when the playground component is small. */
  isSmall?: boolean;
  /** When true, the playground is responsive. */
  isResponsive?: boolean;
  /** Fired when user clicks on the attributes in boxes. */
  onBoxesClick?: () => void;
}

const classNames = createClassNames('playground-bar-box-list');

/** Component that renders list of PlaygroundBarBoxes components with heading. */
export const PlaygroundBarBoxList: FC<IPlaygroundBarBoxListProps> = ({
  heading,
  boxes,
  isReversed = false,
  isSmall = false,
  isResponsive = false,
  onBoxesClick,
}) => {
  const { boxHigh, boxMedium, boxLow } = boxes;
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isReversed,
          isSmall,
          isResponsive,
        }),
      })}
      data-testid='playground-bar-box-list'
    >
      <span className={classNames('heading')}>{t(heading)}</span>
      <div className={classNames('boxes')}>
        <PlaygroundBarBox {...boxHigh} small={isSmall} onClick={onBoxesClick} />
        <PlaygroundBarBox {...boxMedium} small={isSmall} onClick={onBoxesClick} />
        <PlaygroundBarBox {...boxLow} small={isSmall} onClick={onBoxesClick} />
      </div>
    </div>
  );
};
