import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  BasicPlayerInfo,
  Button,
  Caption,
  CardItemList,
  IComparePlayerForm,
  Loading,
  SearchInput,
} from '../../../../components';
import {
  filteredMainFilterDataSelector,
  filteredPlayersForCompareOptionsSelector,
  getPlayerCardInfo,
  selectPlayers,
  setComparePlayerItems,
} from '../../../../features';
import { ITranslationKeys } from '../../../../i18n/types';
import { IActualLoadedPlayer, IMultiParamsVoid, ISelectOption } from '../../../../types';
import { createClassNames } from '../../../../utils';
import { useGetPlayerCards } from '../shared';
import './ComparePlayersContent.styles.scss';

const classNames = createClassNames('compare-players-content');

export const ComparePlayersContent = () => {
  const filteredPlayersForCompareOptions = useAppSelector(filteredPlayersForCompareOptionsSelector);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { byId, comparePlayerItems } = useAppSelector(selectPlayers);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { getPlayerCards } = useGetPlayerCards();

  const { control, handleSubmit, reset } = useForm<IComparePlayerForm>({
    defaultValues: {
      players: {
        '0': null,
        '1': null,
        '2': null,
        '3': null,
      },
    },
  });

  useEffect(() => {
    const definedSelectedPlayerOptions = Object.values(comparePlayerItems).reduce<
      Record<string, ISelectOption | null>
    >((acc, item) => {
      if (item.selectedPlayerCompare) {
        acc[item.id] = item.selectedPlayerCompare;
      } else {
        acc[item.id] = null;
      }

      return acc;
    }, {});

    reset({ players: definedSelectedPlayerOptions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparePlayerItems]);

  const handleChangePlayer = (
    value: ISelectOption,
    itemKey: string,
    onChange: IMultiParamsVoid,
  ) => {
    onChange(value);
    dispatch(
      setComparePlayerItems({
        comparePlayerItems: {
          ...comparePlayerItems[itemKey],
          selectedPlayerCompare: value,
        },
        itemKey,
      }),
    );
  };

  const handleOnSubmit = (data: IComparePlayerForm, itemKey: string) => {
    const formData = data.players[itemKey];
    const selectedPlayerCompare = comparePlayerItems[itemKey].selectedPlayerCompare;
    const playerId = selectedPlayerCompare?.value;

    if (formData && playerId) {
      const loadedPlayerInfo = byId[playerId]?.playerInfos?.find(
        playerInfo => playerInfo.teamId === selectedPlayerCompare?.additionalValue,
      );

      if (!loadedPlayerInfo) {
        if (formData.additionalValue) {
          dispatch(
            getPlayerCardInfo({
              competitionUuid: filteredParts[0].id,
              teamUuid: formData.additionalValue,
              playerUuid: formData.value,
              isCalledFromComparePlayers: true,
            }),
          );
          dispatch(
            setComparePlayerItems({
              comparePlayerItems: {
                ...comparePlayerItems[itemKey],
                loadingPlayer: {
                  id: formData.value,
                  teamId: formData.additionalValue,
                },
                isLoadingPlayerInfo: true,
              },
              itemKey,
            }),
          );
        }
      } else {
        dispatch(
          setComparePlayerItems({
            comparePlayerItems: {
              ...comparePlayerItems[itemKey],
              actualCompareLoadedPlayer: {
                id: byId[playerId].id,
                teamId: formData.additionalValue,
              },
            },
            itemKey,
          }),
        );
      }
    }
  };

  if (filteredParts.length > 1) {
    return <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectOnlyOneSeason)}</div>;
  }

  const renderPlayerInfo = (actualPlayer: IActualLoadedPlayer) => {
    if (!actualPlayer.id) {
      return null;
    }

    const player = byId[actualPlayer.id];
    const playerInfo = player?.playerInfos?.find(
      playerInfo => playerInfo.teamId === actualPlayer.teamId,
    );

    return (
      <>
        <BasicPlayerInfo
          name={`${player.name} ${player.surname}`}
          toi={playerInfo?.toi ?? 0}
          gp={playerInfo?.gp ?? 0}
        />
        <CardItemList isAtCompare>{getPlayerCards(playerInfo, actualPlayer.id)}</CardItemList>
      </>
    );
  };

  return (
    <div className={classNames()} data-testid='players-page__compare-player'>
      <div className={classNames('inputs')} data-testid='players-page__compare-player__inputs'>
        {Object.keys(comparePlayerItems).map(itemKey => (
          <div key={itemKey}>
            <Caption label={ITranslationKeys.selectPlayerToCompare} />
            <Controller
              name={`players.${itemKey}`}
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className={classNames('inputs__control-box')}>
                  <SearchInput
                    onChange={value => handleChangePlayer(value, itemKey, onChange)}
                    selected={value ?? undefined}
                    options={filteredPlayersForCompareOptions}
                    placeholder={`${t(ITranslationKeys.defaultSelectPlaceholder)}`}
                    variant='filter'
                  />
                  <Button
                    label={ITranslationKeys.select}
                    disabled={!value}
                    onClick={handleSubmit(values => handleOnSubmit(values, itemKey))}
                  />
                </div>
              )}
            />
          </div>
        ))}
      </div>
      <div className={classNames('cards')} data-testid='players-page__compare-players__card'>
        {Object.values(comparePlayerItems).map((item, index) => {
          const { actualCompareLoadedPlayer, isLoadingPlayerInfo } = item;

          return (
            <div key={index} className={classNames('cards__card')}>
              {isLoadingPlayerInfo && <Loading size='medium' disableHorizontalMargin />}
              {actualCompareLoadedPlayer &&
                !isLoadingPlayerInfo &&
                renderPlayerInfo(actualCompareLoadedPlayer)}
            </div>
          );
        })}
      </div>
    </div>
  );
};
