import { FC, ReactNode, useState } from 'react';

import { ISize } from '../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import { Tooltip } from '../Tooltip';
import './Sticker.styles.scss';

export interface IStickerProps {
  /** A text to be shown. */
  label: string;
  /** Optional content to be shown in the tooltip. */
  tooltipContent?: ReactNode;
  /** Color of the sticker component. */
  color?: 'gray' | 'green' | 'red' | 'violet' | 'orange' | 'white' | 'blue-active' | 'on' | 'off';
  /** Size of the component.*/
  size?: ISize;
  /** Font weight. */
  fontWeight?: 'normal' | 'bold';
  /** Optional onClick event for sticker. */
  onClick?: () => void;
}

const classNames = createClassNames('sticker');

/**
 * Sticker component for displaying text at colored background.
 */
export const Sticker: FC<IStickerProps> = ({
  label,
  tooltipContent,
  color = 'gray',
  size = 'medium',
  fontWeight,
  onClick,
}) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isClickable: !!onClick,
          color,
          size,
          fontWeight,
        }),
      })}
      onMouseEnter={tooltipContent ? () => setIsShown(true) : undefined}
      onMouseLeave={tooltipContent ? () => setIsShown(false) : undefined}
      onClick={onClick ?? undefined}
      data-testid='sticker'
    >
      {tooltipContent && <Tooltip children={tooltipContent} isShown={isShown} />}
      {label}
    </div>
  );
};
