import { useAppSelector } from '../app/hooks';
import { BASE_TEMPLATE_NAME } from '../constants';
import { selectDefinedDatasets, selectTableFilter, selectUserDatasets } from '../features';
import { IEntity } from '../types';
import { getMetricsFromDataTemplate } from '../utils';

export const useNormalizeMetrics = () => {
  const { definedDatasets } = useAppSelector(selectDefinedDatasets);
  const userDatasets = useAppSelector(selectUserDatasets);
  const { dataTemplate, customMetrics } = useAppSelector(selectTableFilter);

  const normalizeMetrics = (
    defaultTemplateName: string = BASE_TEMPLATE_NAME,
    entity: IEntity = IEntity.players,
  ) => {
    const customMetricsIds = customMetrics?.map(metric => metric.additionalInfo.code);
    if (definedDatasets) {
      return (
        customMetricsIds ??
        getMetricsFromDataTemplate(
          definedDatasets,
          userDatasets.byId,
          dataTemplate,
          defaultTemplateName,
          entity,
        ).map(metric => metric.code)
      );
    }

    return undefined;
  };

  return normalizeMetrics;
};
