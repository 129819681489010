import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import {
  IDatasetMetric,
  IMetricSectionName,
  IMetricsSectionToDisplay,
  IUseMultiselectConfig,
} from '../../../types';
import {
  createClassNames,
  getMetricRowName,
  getMetricsAvailableColumnsNames,
} from '../../../utils';
import { MetricsSectionCircle } from '../MetricsSectionCircle';
import { MetricsSectionRow } from '../MetricsSectionRow';
import './MetricsSection.styles.scss';

export interface IMetricsSectionProps extends IUseMultiselectConfig<IDatasetMetric> {
  /** section */
  section: IMetricsSectionToDisplay;
  /** Section header */
  header?: ITranslationKeys;
}

const classNames = createClassNames('metrics-section');

export const MetricsSection: FC<IMetricsSectionProps> = ({
  section,
  header,
  options,
  selected,
  onChange,
  hasSelectAllOption,
}) => {
  const { t } = useTranslation();
  const sectionColumnsNames = getMetricsAvailableColumnsNames(section);

  return (
    <div className={classNames()} data-testid='metrics-section'>
      <div className={classNames('header', { gap: true })}>
        <MetricsSectionCircle sectionId={section.id as IMetricSectionName} />
        {header && t(header)}
      </div>
      <hr className={classNames('divider')} />
      <div className={classNames('column-names-row')}>
        {sectionColumnsNames.map((name, index) => (
          <div key={index}>{name}</div>
        ))}
      </div>
      {Object.values(section.rows)
        .sort((a, b) => a.order - b.order)
        .map((row, rowIndex) => (
          <MetricsSectionRow
            key={rowIndex}
            row={row}
            rowName={getMetricRowName(row.name)}
            {...{ options, selected, hasSelectAllOption, onChange }}
          />
        ))}
    </div>
  );
};
