import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { MinusBlueIcon, PlusBlueIcon } from '../../../icons';
import { createClassNames, secondsToMinutesAndSeconds } from '../../../utils';
import { Input } from '../../Input';
import './VideoCenterInput.styles.scss';

export interface IVideoCenterInputProps {
  /** Input initial value */
  value: number;
  /** Functions that changes value */
  setValue: (value: number) => void;
  /** Input's title */
  title: ITranslationKeys;
  /** type of input's data */
  type: 'positive' | 'negative' | 'time';
}

const classNames = createClassNames('video-center-input');

/** VideoCenterInput UI component. */
export const VideoCenterInput: FC<IVideoCenterInputProps> = ({ value, setValue, title, type }) => {
  const { t } = useTranslation();
  const leadingChar = type === 'positive' ? '+' : type === 'negative' ? '-' : '';

  const handleIncrement = () => {
    setValue(value + 1);
  };

  const handleDecrement = () => {
    if (value > 0) {
      setValue(value - 1);
    }
  };

  const formatValue = () => {
    if (type === 'time') {
      return secondsToMinutesAndSeconds(value, undefined, true);
    }

    return `${value}`;
  };

  return (
    <div className={classNames()}>
      <p className={classNames('title')}>{t(title)}</p>
      <div className={classNames('input')}>
        <Input style={{ maxWidth: 80 }} value={`${leadingChar}${formatValue()}`} disabled />
        <div className={classNames('controls')}>
          <div className={classNames('button')} onClick={handleIncrement}>
            <PlusBlueIcon />
          </div>
          <div className={classNames('button', { disabled: value <= 0 })} onClick={handleDecrement}>
            <MinusBlueIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
