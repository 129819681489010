import { FC, MouseEventHandler } from 'react';

import { IOnOffColors, IPlayerColors } from '../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import { IOnOffProps } from '../types';
import './OnOffButton.styles.scss';

const classNames = createClassNames('on-off-button');

export interface IOnOffButtonProps extends IOnOffProps {
  /** If true, button has ON or OFF text, otherwise it is empty. */
  isEnabled?: boolean;
  /** Color variant of component. */
  color?: IPlayerColors | IOnOffColors | 'black';
}

/**
 * On/off button component.
 */
export const OnOffButton: FC<IOnOffButtonProps> = ({
  isEnabled,
  color = 'black',
  isOn,
  onIsOnChange,
}) => {
  const handleSelectionActiveChange: MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();
    onIsOnChange?.();
  };

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          color,
          isOn: isEnabled && isOn,
        }),
      })}
      data-testid='on-off-button'
      onClick={isEnabled ? handleSelectionActiveChange : undefined}
    >
      {isEnabled ? (isOn ? 'On' : 'Off') : ''}
    </div>
  );
};
