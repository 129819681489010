import { FC } from 'react';
import { FieldErrors } from 'react-hook-form';

import { useLocationPaths } from '../../hooks';
import { IMainFilterForm, ITabConfig } from '../../types';
import { createClassNames, isFormValid } from '../../utils';
import { WidgetItem } from '../WidgetItem';
import './WidgetItemList.styles.scss';

export interface IWidgetItemListProps {
  /** Tabs config */
  config: ITabConfig[];
  /** Errors field from main form state. */
  errors: FieldErrors<IMainFilterForm>;
}

const classNames = createClassNames('widget-item-list');

/**
 * Component for displaying list of widget items.
 */
export const WidgetItemList: FC<IWidgetItemListProps> = ({ config, errors }) => {
  const { activePage, search, navigate } = useLocationPaths();

  return (
    <div className={classNames()} data-testid={'widget-item-list'}>
      {config.map(tab => (
        <WidgetItem
          key={tab.id}
          iconComponent={tab.icon}
          name={tab.name}
          description={tab.description}
          additionalInput={tab.additionalInput}
          disabled={tab.disabled}
          disableLoadButton={!isFormValid(errors) || tab.disableLoadDataButton}
          onClick={() => navigate(activePage, tab.id, search)}
        />
      ))}
    </div>
  );
};
