import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  INITIAL_SELECTED_PLAYER_ITEMS,
  comparisonTypeOptions,
  entityOptions,
  minimapComparisonOptions,
  passTypeOptions,
  shotCategoryOptions,
} from '../../../constants';
import { ISelectOption, ISelectedPlayerItemPayload, IVideomapsFilterState } from '../../../types';
import { getDefaultPredefinedOptionByValue } from '../../../utils';

const initialState: IVideomapsFilterState = {
  selectedGames: [],
  selectedPlayerItems: INITIAL_SELECTED_PLAYER_ITEMS,
  entity: getDefaultPredefinedOptionByValue(entityOptions, 'team'),
  shotCategory: getDefaultPredefinedOptionByValue(shotCategoryOptions),
  passType: getDefaultPredefinedOptionByValue(passTypeOptions),
  drawAllPassesTrajectories: true,
  compareBy: getDefaultPredefinedOptionByValue(minimapComparisonOptions, 'attackType'),
  comparisonType: getDefaultPredefinedOptionByValue(comparisonTypeOptions, 'shotsProduction'),
  entityId: undefined,
  teamId: undefined,
  filterByProp: 'playerId',
};

export const videomapsFilterSlice = createSlice({
  name: 'videomapsFilter',
  initialState,
  reducers: {
    setSelectedGames: (state, action: PayloadAction<ISelectOption[]>) => {
      state.selectedGames = action.payload;
    },
    setSelectedPlayerItems: (state, action: PayloadAction<ISelectedPlayerItemPayload>) => {
      state.selectedPlayerItems[action.payload.itemKey] = action.payload.selectedPlayerItems;
    },
    setEntity: (state, action: PayloadAction<ISelectOption>) => {
      state.entity = action.payload;
    },
    setShotCategory: (state, action: PayloadAction<ISelectOption>) => {
      state.shotCategory = action.payload;
    },
    setPassType: (state, action: PayloadAction<ISelectOption>) => {
      state.passType = action.payload;
    },
    setDrawAllPassesTrajectories: (state, action: PayloadAction<boolean | undefined>) => {
      state.drawAllPassesTrajectories = action.payload;
    },
    setCompareBy: (state, action: PayloadAction<ISelectOption>) => {
      state.compareBy = action.payload;
    },
    setComparisonType: (state, action: PayloadAction<ISelectOption>) => {
      state.comparisonType = action.payload;
    },
    setVideoEntityId: (state, action: PayloadAction<string | undefined>) => {
      state.entityId = action.payload;
    },
    setVideoTeamId: (state, action: PayloadAction<string | undefined>) => {
      state.teamId = action.payload;
    },
    setFilterByProp: (state, action: PayloadAction<string | undefined>) => {
      state.filterByProp = action.payload;
    },
    resetSelectedPlayerItems: state => {
      state.selectedPlayerItems = INITIAL_SELECTED_PLAYER_ITEMS;
    },
    resetVideomapsFilter: (
      _,
      action: PayloadAction<Partial<IVideomapsFilterState> | undefined>,
    ) => ({
      ...initialState,
      ...action.payload,
    }),
  },
});

export const {
  setSelectedGames,
  setSelectedPlayerItems,
  setEntity,
  setShotCategory,
  setPassType,
  setDrawAllPassesTrajectories,
  setCompareBy,
  setComparisonType,
  setVideoEntityId,
  setVideoTeamId,
  setFilterByProp,
  resetSelectedPlayerItems,
  resetVideomapsFilter,
} = videomapsFilterSlice.actions;
