import { FC, ReactNode } from 'react';

import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './CardItemList.styles.scss';

export interface ICardItemListProps {
  children: ReactNode;
  isAtCompare?: boolean;
  isExporting?: boolean;
  mode?: 'column' | 'wrap';
}

const classNames = createClassNames('card-item-list');

export const CardItemList: FC<ICardItemListProps> = ({
  children,
  isAtCompare,
  isExporting,
  mode,
}) => (
  <div
    className={classNames({
      ...transformObjectKeysToKebabCase({
        isAtCompare,
        isExporting,
        mode,
      }),
    })}
  >
    {children}
  </div>
);
