import { DEFAULT_TIME_PERIOD_TABLE_FORM_VALUES } from '../constants';
import { ITimePeriodDataBy, ITimePeriodTableDataToSet, ITrend } from '../types';
import { createMonthRanges } from './date.utils';
import { createNumberRanges } from './number.utils';

export const createTimePeriodTableFormDataByGames = (
  dataLength: number,
  tableDataBy: ITimePeriodDataBy | undefined,
  size: number,
): ITimePeriodTableDataToSet => {
  const timePeriodByGames = createNumberRanges(dataLength, size);

  return {
    rowCount: timePeriodByGames.length,
    timePeriodForm: {
      timePeriodByGames:
        timePeriodByGames.length > 0
          ? timePeriodByGames
          : DEFAULT_TIME_PERIOD_TABLE_FORM_VALUES.timePeriodByGames,
      timePeriodByDate: DEFAULT_TIME_PERIOD_TABLE_FORM_VALUES.timePeriodByDate,
    },
    tableFormDataBy: tableDataBy || 'playerMatches',
  };
};

export const createTimePeriodTableFormDataByDate = (
  trend: ITrend[],
  tableDataBy: ITimePeriodDataBy | undefined,
  byMonthCount: number = 1,
): ITimePeriodTableDataToSet => {
  const firstGameDate = new Date(trend[0].date);
  const lastGameDate = new Date(trend[trend.length - 1].date);

  const timePeriodByDate = createMonthRanges(firstGameDate, lastGameDate, byMonthCount);

  return {
    rowCount: timePeriodByDate.length,
    timePeriodForm: {
      timePeriodByGames: DEFAULT_TIME_PERIOD_TABLE_FORM_VALUES.timePeriodByGames,
      timePeriodByDate:
        timePeriodByDate.length > 0
          ? timePeriodByDate
          : DEFAULT_TIME_PERIOD_TABLE_FORM_VALUES.timePeriodByDate,
    },
    tableFormDataBy: tableDataBy || 'calendar',
  };
};
