import { FC, useState } from 'react';

import { MinusActiveIcon, PlusActiveIcon } from '../../../icons';
import { IHelpTextItem } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './TipsItem.styles.scss';

export interface ITipsItemProps extends IHelpTextItem {}

const classNames = createClassNames('tips-item');

export const TipsItem: FC<ITipsItemProps> = ({ title, text }) => {
  const [active, setActive] = useState<boolean>(false);

  return (
    <div className={classNames()}>
      <div className={classNames('header')} onClick={() => setActive(!active)}>
        <h5>{title}</h5>
        {active ? <MinusActiveIcon /> : <PlusActiveIcon />}
      </div>
      <span
        className={classNames('description', {
          ...transformObjectKeysToKebabCase({ active }),
        })}
        dangerouslySetInnerHTML={{ __html: text }}
      ></span>
    </div>
  );
};
