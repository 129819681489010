import { useEffect, useState } from 'react';

import { useAppSelector } from '../app/hooks';
import { Modal, ModalContent } from '../components';
import { selectDataSettingsFilter, selectSettings } from '../features';
import i18n from '../i18n/i18n';
import { IHelpNewsTextItem, ILanguageType } from '../types';
import { findHelpContent } from '../utils';
import { useLocationPaths } from './useLocationPaths';

export const useNewsModal = () => {
  const { help } = useAppSelector(selectSettings).help;
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { activePage, activeTab } = useLocationPaths();

  const [openNews, setOpenNews] = useState(true);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const hiddenNewsIds = localStorage.getItem('hiddenNewsIds')?.split(';') || [];

  useEffect(() => {
    setOpenNews(true);
    setDontShowAgain(false);
  }, [activePage, activeTab]);

  const handleCloseNewsModal = (newsTextItems: IHelpNewsTextItem[]) => {
    if (dontShowAgain) {
      const newHiddenNewsIds = [...hiddenNewsIds, ...newsTextItems.map(item => item.id)];
      localStorage.setItem('hiddenNewsIds', newHiddenNewsIds.join(';'));
    }
    setOpenNews(false);
  };

  const getNewsModal = (pageName: string, tabName: string) => {
    if (!help) return null;

    const news = findHelpContent(help.news, activePage, activeTab, open);

    if (!news) {
      console.error(`[useNewsModal]: News for ${pageName} - ${tabName} not found`);
      return null;
    }

    const language = i18n.language as keyof ILanguageType<string>;
    const newsTextItems = news[language];
    const filteredNewsTextItems = newsTextItems?.filter(item => !hiddenNewsIds.includes(item.id));

    if (!news.show || !filteredNewsTextItems?.length) {
      return null;
    }

    return (
      <Modal open={openNews} size='medium'>
        <ModalContent
          header={news.title[language]}
          news={filteredNewsTextItems}
          checkboxChecked={dontShowAgain}
          handleCheckboxClick={() => setDontShowAgain(!dontShowAgain)}
          handleClose={() => handleCloseNewsModal(filteredNewsTextItems)}
        />
      </Modal>
    );
  };

  return getNewsModal;
};
