import { compareAsc, compareDesc, format, formatISO, isValid, parse } from 'date-fns';
import { cs, enUS } from 'date-fns/locale';
import { SetStateAction } from 'react';
import { DateRange } from 'react-day-picker';

import i18n from '../i18n/i18n';
import { IDateRangeString } from '../types';

export const getLocaleByLanguage = () => (i18n.language === 'en' ? enUS : cs);

export const formatDateByLanguage = (date: Date, formatString: string) =>
  format(date, formatString, { locale: getLocaleByLanguage() });

export const parseStringToDate = (dateString: string | undefined) =>
  dateString ? new Date(dateString) : undefined;

export const parseDateToString = (date: Date | undefined) =>
  date ? formatISO(date, { representation: 'date' }) : undefined;

export const parseStringToDateDateRange = (
  dateRangeString: IDateRangeString | undefined,
): DateRange | undefined => {
  if (dateRangeString) {
    return {
      from: parseStringToDate(dateRangeString.from),
      to: parseStringToDate(dateRangeString.to),
    };
  }

  return undefined;
};

export const parseDateToStringDateRange = (
  dateRange: DateRange | undefined,
): IDateRangeString | undefined => {
  if (dateRange) {
    return {
      from: parseDateToString(dateRange.from),
      to: parseDateToString(dateRange.to),
    };
  }

  return undefined;
};

export const parseDateToAtomString = (date: Date | undefined) => {
  if (!date) return undefined;

  return `${date.toISOString().substring(0, 19)}+00:00`;
};

export const splitDateRangeInputValue = (inputValue: string | undefined): DateRange | undefined => {
  if (inputValue) {
    const [fromDate, toDate] = inputValue.split('-');
    const locale = getLocaleByLanguage();

    return {
      from: parse(fromDate?.trim(), 'P', new Date(), { locale }),
      to: parse(toDate?.trim(), 'P', new Date(), { locale }),
    };
  }

  return undefined;
};

export const validateDateRangeAndSetRange = (
  value: string,
  callback: (value: SetStateAction<DateRange | undefined>) => void,
) => {
  const dates = splitDateRangeInputValue(value);

  if (dates) {
    const { from, to } = dates;

    const fromDateValid = isValid(from);
    const toDateValid = isValid(to);

    if (fromDateValid && toDateValid) {
      callback({
        from,
        to,
      });
    } else if (fromDateValid) {
      callback({
        from,
        to: undefined,
      });
    } else if (toDateValid) {
      callback({
        from: undefined,
        to,
      });
    } else {
      callback({
        from: undefined,
        to: undefined,
      });
    }
  } else {
    callback({
      from: undefined,
      to: undefined,
    });
  }
};

export const getStringValueFromDateRange = (dateRange: DateRange | undefined) => {
  if (dateRange?.from) {
    if (!dateRange.to) {
      return formatDateByLanguage(dateRange.from, 'P');
    } else {
      return `${formatDateByLanguage(dateRange.from, 'P')} - ${formatDateByLanguage(
        dateRange.to,
        'P',
      )}`;
    }
  }

  return '';
};

export const getDefaultMonths = (value: DateRange | undefined) => {
  const actualMonth = new Date();
  const nextMonth = new Date(actualMonth.getFullYear(), actualMonth.getMonth() + 1, 1);

  if (value === undefined) {
    return {
      firstMonth: actualMonth,
      secondMonth: nextMonth,
    };
  }

  if (value.from && value.to && value.from.getMonth() === value.to.getMonth()) {
    const secondMonth = new Date(value.from.getFullYear(), value.from.getMonth() + 1, 1);
    return {
      firstMonth: value.from,
      secondMonth: secondMonth,
    };
  }

  return {
    firstMonth: value.from || actualMonth,
    secondMonth: value.to || nextMonth,
  };
};

export const compareDateStrings = (a: string, b: string, asc: boolean) => {
  return asc ? compareAsc(new Date(a), new Date(b)) : compareDesc(new Date(a), new Date(b));
};

export const createMonthRanges = (
  startDate: Date,
  endDate: Date,
  byCount: number = 1,
): IDateRangeString[] => {
  const monthsCount =
    (endDate.getFullYear() - startDate.getFullYear()) * 12 +
    endDate.getMonth() -
    startDate.getMonth() +
    1;
  const ranges: IDateRangeString[] = [];

  let currentMonth = startDate.getMonth();
  let currentYear = startDate.getFullYear();

  for (let i = 0; i < Math.ceil(monthsCount / byCount); i++) {
    const fromDate = new Date(currentYear, currentMonth, 1);
    const toDate = new Date(currentYear, currentMonth + byCount, 0);

    ranges.push({
      from: parseDateToString(fromDate),
      to: parseDateToString(toDate),
    });

    const nextMonth = currentMonth + byCount;
    if (nextMonth >= 12) {
      currentMonth = nextMonth % 12;
      currentYear += Math.floor(nextMonth / 12);
    } else {
      currentMonth = nextMonth;
    }
  }

  return ranges;
};

/**
 * Create date range from the initial date with the specified days difference.
 * @param dateString Initial date in string format. If not provided, current date is used.
 * @param daysDifference Count of days from the initial date (dateString). Default is -30, which means 30 days back from the initial date.
 * @returns Date range object with from and to properties. From is always lower date than to.
 */
export const createDateFromTo = (
  dateString?: string,
  daysDifference: number = -30,
): IDateRangeString => {
  const initialDate = dateString || parseDateToString(new Date())!;

  const from = new Date(initialDate).toISOString();
  const helperDate = new Date(initialDate);
  const to = new Date(helperDate.setDate(helperDate.getDate() + daysDifference)).toISOString();
  const isNegative = Math.sign(daysDifference) === -1;

  return {
    from: isNegative ? to : from,
    to: isNegative ? from : to,
  };
};

export const formatLongYearRangeToShort = (text: string, separator: string = '/') => {
  const match = text.match(/(\d{4}) - (\d{4})/);

  if (match) {
    const year1 = match[1].slice(2);
    const year2 = match[2].slice(2);
    return `${year1}${separator}${year2}`;
  }

  return text;
};
