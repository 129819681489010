import { ITranslationKeys } from '../../i18n/types';
import { ISelectOption } from '../../types';
import { allFemaleOption } from './commonOptions.constants';

// start of ZoneCategory
export const controlledZoneCategoryOption: ISelectOption = {
  value: 'controlled',
  label: ITranslationKeys.controlled,
};
export const dumpoutZoneCategoryOption: ISelectOption = {
  value: 'dumpInOut',
  label: ITranslationKeys.dumpout,
};
export const dumpinZoneCategoryOption: ISelectOption = {
  value: 'dumpInOut',
  label: ITranslationKeys.dumpins,
};
export const zoneExitCategoryOptions: ISelectOption[] = [
  controlledZoneCategoryOption,
  dumpoutZoneCategoryOption,
];
export const zoneEntryCategoryOptions: ISelectOption[] = [
  controlledZoneCategoryOption,
  dumpinZoneCategoryOption,
];
// end of ZoneCategory
// start of ZoneSuccess
export const successfullyZoneSuccessOption: ISelectOption = {
  value: 'successfully',
  label: ITranslationKeys.successfully,
};
export const unsuccessfullyZoneSuccessOption: ISelectOption = {
  value: 'unsuccessfully',
  label: ITranslationKeys.unsuccessfully,
};
export const zoneSuccessOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all },
  successfullyZoneSuccessOption,
  unsuccessfullyZoneSuccessOption,
];
// end of ZoneSuccess
// start of ZoneFinish
export const shotZoneFinishOption: ISelectOption = { value: 'shot', label: ITranslationKeys.shot };
export const shotFromSlotZoneFinishOption: ISelectOption = {
  value: 'shotFromSlot',
  label: ITranslationKeys.shotFromSlot,
};
export const goalZoneFinishOption: ISelectOption = { value: 'goal', label: ITranslationKeys.goal };

const zoneFinishBaseOptions: ISelectOption[] = [
  shotZoneFinishOption,
  shotFromSlotZoneFinishOption,
  goalZoneFinishOption,
];

export const zoneFinishOptions: ISelectOption[] = [allFemaleOption, ...zoneFinishBaseOptions];
// end of ZoneFinish
// start of ZoneExitResult
export const gainZoneExitResultOption: ISelectOption = {
  value: 'offensiveZoneGain',
  label: ITranslationKeys.offensiveZoneGain,
};
export const denialZoneExitResultOption: ISelectOption = {
  value: 'losingPuckInMiddleZone',
  label: ITranslationKeys.losingPuckInMiddleZone,
};
export const zoneExitResultOptions: ISelectOption[] = [
  allFemaleOption,
  gainZoneExitResultOption,
  denialZoneExitResultOption,
];
// end of ZoneExitResult
// start of DumpInOutResult
export const battleResultOption: ISelectOption = {
  value: 'battle',
  label: ITranslationKeys.fightForPuck,
};
export const battleWonResultOption: ISelectOption = {
  value: 'battleWon',
  label: ITranslationKeys.puckGainOfTheAttackingTeam,
};
export const battleLostResultOption: ISelectOption = {
  value: 'battleLost',
  label: ITranslationKeys.puckGainOfTheDefendingTeam,
};
export const dumpInOutResultOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all },
  battleResultOption,
  battleWonResultOption,
  battleLostResultOption,
];
