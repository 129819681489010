import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';

import { useAppSelector } from '../../../app/hooks';
import { selectWowy } from '../../../features';
import { ITeamsFormationPlayers, ITeamsFormationTableData, IWowyData } from '../../../types';
import { createClassNames } from '../../../utils';
import { Sticker } from '../../Sticker';
import './FormationColumn.styles.scss';

const getColorForWowySelectedPlayer = (wowy: IWowyData | undefined, playerId: string) => {
  if (wowy?.selectedPlayer && wowy.selectedPlayer.id === playerId) return 'blue-active';
  return undefined;
};

export interface IFormationColumnCellProps
  extends CellContext<ITeamsFormationTableData, ITeamsFormationPlayers> {}

const classNames = createClassNames('formation-column');

export const FormationColumnCell: FC<IFormationColumnCellProps> = ({ getValue }) => {
  const { wowy } = useAppSelector(selectWowy);
  const allPlayers = getValue();

  return (
    <div className={classNames()}>
      <div className={classNames('position')}>
        {allPlayers.firstRow.map(player => (
          <Sticker
            key={player.id}
            label={player.displayName}
            color={getColorForWowySelectedPlayer(wowy, player.id)}
          />
        ))}
      </div>
      {allPlayers.secondRow.length > 0 && (
        <div className={classNames('position')}>
          {allPlayers.secondRow.map(player => (
            <Sticker
              key={player.id}
              label={player.displayName}
              color={getColorForWowySelectedPlayer(wowy, player.id)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
