import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IGetCompetitionDetailParams, IReturnTypeGetCompetitionDetailAction } from '../../types';
import { defaultCatchErrorCallback } from '../../utils';
import { parseCompetitionsDetailData } from './parseCompetitionsDetail.utils';

export const getCompetitionDetail = createAsyncThunk<
  IReturnTypeGetCompetitionDetailAction,
  IGetCompetitionDetailParams
>('competitionsDetail/getCompetitionDetail', async ({ competitionUuid, part }) => {
  const competitionDetail: IReturnTypeGetCompetitionDetailAction = await axiosInstance
    .get(`/competition/${competitionUuid}`)
    .then(response => parseCompetitionsDetailData(response.data, { competitionUuid, part }))
    .catch(defaultCatchErrorCallback);

  return competitionDetail;
});
