import { FormProvider, useForm } from 'react-hook-form';
import { Outlet } from 'react-router-dom';

import { IMainTrackingFilterForm } from '../../../../types';
import { getDefaultMainTrackingFormValues } from '../../../../utils';

export const ScreenLogTrackingFormWrapper = () => {
  const methods = useForm<IMainTrackingFilterForm>({
    defaultValues: getDefaultMainTrackingFormValues(true),
    mode: 'onBlur',
  });

  return (
    <div className='grow-and-flex-column'>
      <FormProvider {...methods}>
        <Outlet />
      </FormProvider>
    </div>
  );
};
