export const COLORS_TCMS = {
  white: {
    base: '#fff',
  },
  black: {
    base: '#000',
  },
  slate: {
    base: '#334155',
    950: '#020617',
    600: '#475569',
    500: '#64748b',
    300: '#cbd5e1',
    200: '#e2e8f0',
    100: '#f1f5f9',
  },
  red: {
    base: '#ef4444',
    500: '#ef4444',
    200: '#fecaca',
  },
  blue: {
    base: '#1d4ed8',
    800: '#1e40af',
    700: '#1d4ed8',
    100: '#e0e7ff',
  },
  fuchsia: {
    base: '#c026d3',
    700: '#a21caf',
    600: '#c026d3',
  },
  cyan: {
    base: '#06b6d4',
    600: '#0891b2',
    500: '#06b6d4',
  },
  sky: {
    base: '#0ea5e9',
    500: '#0ea5e9',
    200: '#bae6fd',
  },
  darkBlue: {
    base: '#035fa1',
    650: '#035fa1',
  },
};
