import { useEffect, useMemo, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  Caption,
  GamesSelectList,
  GoalNet,
  GoalNetCountContent,
  Loading,
  Playground,
  PlaygroundShotsContent,
  ToggleSelect,
} from '../../../../components';
import {
  baseShotCategoryOptions,
  goalkeeperSavesOptions,
  onNetShotCategoryOption,
  shotDangerOptions,
  shotGameActionTypeOptions,
  shotLocationOptions,
  shotTypeOptions,
  stickGripToggleOptions,
} from '../../../../constants';
import {
  filteredGoalkeepersFilterDataSelector,
  selectGames,
  selectGoalkeepersFilter,
  selectMainFilter,
  selectMetricParamsFilter,
  selectPlayers,
  selectShots,
  selectTeams,
  selectVideomapsFilter,
  setGameActionType,
  setGoalkeeperSaves,
  setGoalkeeperStickGrip,
  setNetZone,
  setSelectedGames,
  setShotCategory,
  setShotDanger,
  setShotLocation,
  setShotType,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useFetchShotsOrPasses,
  useHandleOnSubmit,
  useLocationPaths,
  useShotAndPassEffects,
  useVideoCenter,
  useWindowSize,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import { INetZoneUnion, ISelectOption } from '../../../../types';
import {
  calculateShotsTotalXGA,
  computeResponsivePlaygroundSize,
  convertShotToPlaygroundShot,
  createClassNames,
  filterGoals,
  getGamesOptions,
  getNetZonesShotsCount,
  getShotsDangerBoxes,
} from '../../../../utils';
import './ShotMapContent.styles.scss';

const classNames = createClassNames('shot-map-content');

export const ShotMapContent = () => {
  const { isLoading } = useAppSelector(selectShots);
  const {
    filteredGoalkeepersGamesTotalToi,
    filteredGoalkeepersShotsGameEntity,
    filteredGoalkeepersShots,
  } = useAppSelector(filteredGoalkeepersFilterDataSelector);
  const teams = useAppSelector(selectTeams);
  const players = useAppSelector(selectPlayers);
  const { games } = useAppSelector(selectGames);
  const { stickGrip, saves } = useAppSelector(selectGoalkeepersFilter);
  const { shotDanger, shotLocation, shotType, gameActionType, netZone } =
    useAppSelector(selectMetricParamsFilter);
  const { selectedGames, shotCategory } = useAppSelector(selectVideomapsFilter);
  const { selectedTeam } = useAppSelector(selectMainFilter);

  const gamesOptions = useMemo(() => getGamesOptions(games.byId), [games.byId]);
  const netZonesShotsCount = useMemo(
    () => getNetZonesShotsCount(filteredGoalkeepersShots),
    [filteredGoalkeepersShots],
  );
  const dispatch = useAppDispatch();
  const { state } = useLocationPaths();

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();
  const { fetchGamesAndToiForShotsOrPasses, fetchGoalkeepersShots } = useFetchShotsOrPasses();
  const { playSelectedGamesVideos, playGameVideos, playVideos } = useVideoCenter(
    filteredGoalkeepersShotsGameEntity,
    ITranslationKeys.shots,
  );

  const isInitialMountRef = useRef(true);
  const [disableResetSelectedAfterLoadGames, setDisableResetSelectedAfterLoadGames] =
    useState(false);

  const windowSize = useWindowSize();

  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGamesAndToiForShotsOrPasses(values);
    fetchGoalkeepersShots(values);
  }, isInitialMountRef);

  useShotAndPassEffects(handleOnSubmit, isInitialMountRef, isLoading);

  useEffect(
    () => {
      if (!disableResetSelectedAfterLoadGames && !state?.disableReset) {
        handleChangeGame(gamesOptions);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gamesOptions],
  );

  useEffect(
    () => {
      if (state?.disableReset) {
        setDisableResetSelectedAfterLoadGames(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state?.disableReset],
  );

  useEffect(
    () => {
      if (!state?.disableReset) {
        setDisableResetSelectedAfterLoadGames(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTeam],
  );

  useEffect(() => {
    dispatch(setShotCategory(onNetShotCategoryOption));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeGame = (value: ISelectOption[]) => {
    dispatch(setSelectedGames(value));
  };

  const handleChangeNetZone = (newValue: INetZoneUnion) => {
    if (newValue === netZone) {
      dispatch(setNetZone(''));
    } else {
      dispatch(setNetZone(newValue));
    }
  };

  const handleSaveMap = () => {
    // TODO: Dodělat uložení mapy
  };

  const filteredPlaygroundShots = filteredGoalkeepersShots.map(shot => {
    const playgroundSize = computeResponsivePlaygroundSize(windowSize.width);
    return convertShotToPlaygroundShot(shot, teams.byId, players.byId, {}, playgroundSize.width);
  });

  if (isLoading) return <Loading />;

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, 1);
  if (errorInfoBox) {
    return errorInfoBox;
  }

  return (
    <div className={classNames()} data-testid='goalkeepers-page__shots-content'>
      <div className={classNames('main')}>
        <GamesSelectList
          selected={selectedGames}
          options={gamesOptions}
          gameRecord={games.byId}
          teamRecord={teams.byId}
          shouldCheckFullBody={false}
          gameEntities={filteredGoalkeepersShotsGameEntity}
          onChange={newValue => handleChangeGame(newValue)}
          onItemContentActionClick={gameId => playGameVideos(gameId)}
          onPlaySelectedGamesVideos={playSelectedGamesVideos}
          hasSelectAllOption
        />
        <div className={classNames('main__playground-wrapper')}>
          <Playground
            toi={filteredGoalkeepersGamesTotalToi}
            c={filteredGoalkeepersShots.length}
            xG={calculateShotsTotalXGA(filteredGoalkeepersShots)}
            g={filterGoals(filteredGoalkeepersShots).length}
            boxes={getShotsDangerBoxes(filteredGoalkeepersShots)}
            boxListHeading={ITranslationKeys.shotsDanger}
            isResponsive
            isGoalkeeper
            renderContent={() => (
              <PlaygroundShotsContent shots={filteredPlaygroundShots} onPlayClick={playVideos} />
            )}
          />
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption label={ITranslationKeys.shotCategory} />
            <ToggleSelect
              selected={shotCategory}
              options={baseShotCategoryOptions}
              onChange={newValue => dispatch(setShotCategory(newValue))}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.saves} />
            <ToggleSelect
              selected={saves}
              options={goalkeeperSavesOptions}
              onChange={newValue => dispatch(setGoalkeeperSaves(newValue))}
              hasOnlyOneColumn
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.shotTypes} />
            <ToggleSelect
              selected={shotType}
              options={shotTypeOptions}
              onChange={newValue => dispatch(setShotType(newValue))}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.shotsDanger} />
            <ToggleSelect
              selected={shotDanger}
              options={shotDangerOptions}
              onChange={newValue => dispatch(setShotDanger(newValue))}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.opponentsStick} />
            <ToggleSelect
              selected={stickGrip}
              options={stickGripToggleOptions}
              onChange={newValue => dispatch(setGoalkeeperStickGrip(newValue))}
              twoColumns
            />
          </div>
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption label={ITranslationKeys.attackType} />
            <ToggleSelect
              selected={gameActionType}
              options={shotGameActionTypeOptions}
              onChange={newValue => dispatch(setGameActionType(newValue))}
              hasOnlyOneColumn
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.shotLocation} />
            <ToggleSelect
              selected={shotLocation}
              options={shotLocationOptions}
              onChange={newValue => dispatch(setShotLocation(newValue))}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.netZones} />
            <GoalNet
              onZoneChange={newValue => handleChangeNetZone(newValue)}
              activeZone={netZone}
              isClickable
              variant='black'
              content={<GoalNetCountContent netZonesShotsCount={netZonesShotsCount} />}
              disableContentPointerEvents
            />
            <div className={classNames('main__form-box__save-button')}>
              <Button
                label={ITranslationKeys.saveMap}
                iconComponent={<DownloadIcon />}
                onClick={handleSaveMap}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
