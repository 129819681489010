import { FC } from 'react';

import { IVideoInfo } from '../../../types';
import { createClassNames } from '../../../utils';
import { Loading } from '../../Loading';
import { IGoalsOverview } from '../../types';
import { GoalsOverviewRow } from '../GoalsOverviewRow';
import './GoalsOverview.styles.scss';

interface IGoalsOverviewProps {
  /** All goals data & needed props. */
  data: IGoalsOverview[];
  /** Is goals overview loading? */
  isLoading?: boolean;
  /** Fired when user clicks on the play icon. */
  onPlayClick: (array: IVideoInfo[]) => void;
}

const classNames = createClassNames('goals-overview');

/** GoalsOverview UI component. */
export const GoalsOverview: FC<IGoalsOverviewProps> = ({ data, isLoading, onPlayClick }) => (
  <div className={classNames()}>
    {isLoading ? (
      <div className={classNames('loading-wrapper')}>
        <Loading
          size='medium'
          backgroundColor='gray'
          disableMessage
          disableAbsolutePosition
          disableHorizontalMargin
        />
      </div>
    ) : (
      data.map((goal, index) => (
        <GoalsOverviewRow
          key={index}
          goalData={goal.goalData}
          homeGoals={goal.homeGoals}
          awayGoals={goal.awayGoals}
          team={goal.team}
          isFirstRow={index === 0}
          onPlayClick={onPlayClick}
        />
      ))
    )}
  </div>
);
