import {
  ICompetitionDetail,
  ICompetitionDetailDTO,
  IGetCompetitionDetailParams,
  IGoalkeeper,
  IPlayer,
  IReturnTypeGetCompetitionDetailAction,
  ITeam,
  ITeamGoalkeepersRecord,
  ITeamPlayersRecord,
} from '../../types';

export const parseCompetitionsDetailData = (
  data: ICompetitionDetailDTO,
  params: IGetCompetitionDetailParams,
): IReturnTypeGetCompetitionDetailAction => {
  const players: IPlayer[] = [];
  const goalkeepers: IGoalkeeper[] = [];

  const teams: ITeam[] = data.teams?.map(team => {
    const playerIds: string[] = [];
    const goalkeeperIds: string[] = [];

    team.players.forEach(player => {
      const { uuid, ...playerInfo } = player;

      if (player.position === 'GK') {
        if (goalkeepers.some(goalkeeper => goalkeeper.id === uuid)) {
          const existingGoalkeeper = goalkeepers.find(goalkeeper => goalkeeper.id === uuid);

          if (existingGoalkeeper) {
            if (!existingGoalkeeper.teamIds.includes(team.uuid)) {
              existingGoalkeeper.teamIds.push(team.uuid);
              existingGoalkeeper.competitionTeams[params.competitionUuid].teamIds.push(team.uuid);
              goalkeeperIds.push(uuid);
            }
          }
        } else {
          const parsedGoalkeeper: IGoalkeeper = {
            ...playerInfo,
            id: uuid,
            teamIds: [team.uuid],
            seasonIds: [data.season],
            competitionTeams: {
              [params.competitionUuid]: {
                id: params.competitionUuid,
                teamIds: [team.uuid],
              },
            },
            goalkeeperInfos: [],
            teamStats: {},
          };
          goalkeepers.push(parsedGoalkeeper);
          goalkeeperIds.push(uuid);
        }
      } else {
        if (players.some(player => player.id === uuid)) {
          const existingPlayer = players.find(player => player.id === uuid);

          if (existingPlayer) {
            if (!existingPlayer.teamIds.includes(team.uuid)) {
              existingPlayer.teamIds.push(team.uuid);
              existingPlayer.competitionTeams[params.competitionUuid].teamIds.push(team.uuid);
              playerIds.push(uuid);
            }
          }
        } else {
          const parsedPlayer: IPlayer = {
            ...playerInfo,
            id: uuid,
            teamIds: [team.uuid],
            seasonIds: [data.season],
            competitionTeams: {
              [params.competitionUuid]: {
                id: params.competitionUuid,
                teamIds: [team.uuid],
              },
            },
            playerInfos: [],
          };
          players.push(parsedPlayer);
          playerIds.push(uuid);
        }
      }
    });

    return {
      id: team.uuid,
      name: team.name,
      shortName: team.shortName,
      shortcut: team.shortcut,
      seasonIds: [data.season],
      competitionIds: [params.competitionUuid],
      playerIds,
      goalkeeperIds,
    };
  });

  const teamPlayers = teams?.reduce<ITeamPlayersRecord>((acc, team) => {
    acc[team.id] = {
      id: team.id,
      playerIds: team.playerIds,
    };
    return acc;
  }, {});

  const teamGoalkeepers = teams?.reduce<ITeamGoalkeepersRecord>((acc, team) => {
    acc[team.id] = {
      id: team.id,
      goalkeeperIds: team.goalkeeperIds,
    };
    return acc;
  }, {});

  const competitionDetail: ICompetitionDetail = {
    id: params.competitionUuid,
    name: data.name,
    seasonId: data.season,
    part: params.part,
    teamPlayers,
    teamGoalkeepers,
  };

  return {
    competitionDetail,
    teams,
    players,
    goalkeepers,
  };
};
