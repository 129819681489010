import { FC } from 'react';

import { ISelectOption } from '../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import { Button } from '../Button';
import './ToggleSelect.styles.scss';

export interface IToggleSelectProps {
  /** Available select options */
  options: ISelectOption[];
  /** Selected option. */
  selected?: ISelectOption;
  /** Function called when other option is picked. */
  onChange: (...args: any[]) => void;
  /** All buttons will be in one column. */
  hasOnlyOneColumn?: boolean;
  /** Sets small gap between options (0.2rem). */
  hasSmallGap?: boolean;
  /** If true, component will flex first option if there is odd count of options. */
  twoColumns?: boolean;
  /** If true, specified option's button will be disabled. */
  disabledOptions?: ISelectOption[];
  /**
   * Render function, that returns JSX element for one option.
   * If undefined, it use default render function with Button component.
   */
  customOptionRenderer?: (
    option: ISelectOption,
    onChange: (...args: any[]) => void,
    selected?: ISelectOption,
    disabledOptions?: ISelectOption[],
  ) => JSX.Element;
}

const defaultOptionRenderer = (
  option: ISelectOption,
  onChange: (...args: any[]) => void,
  selected?: ISelectOption,
  disabledOptions?: ISelectOption[],
) => {
  const activeOption = selected?.value === option.value;
  const disabled = disabledOptions?.some(item => item.value === option.value);

  return (
    <Button
      key={option.value}
      label={option.label}
      labelTranslationsOptions={option.labelTranslationsOptions}
      onClick={!activeOption ? () => onChange(option) : undefined}
      variant={activeOption ? 'normal' : 'gray'}
      disabled={disabled}
      primary
    />
  );
};

const classNames = createClassNames('toggle-select');

/** Layout component that displays options that toggle the selection. */
export const ToggleSelect: FC<IToggleSelectProps> = ({
  options,
  selected,
  onChange,
  hasOnlyOneColumn,
  hasSmallGap,
  twoColumns,
  disabledOptions,
  customOptionRenderer,
}) => (
  <div
    className={classNames({
      ...transformObjectKeysToKebabCase({
        hasOnlyOneColumn,
        hasSmallGap,
        twoColumns,
      }),
    })}
    data-testid='toggle-select'
  >
    {options.map(option =>
      customOptionRenderer
        ? customOptionRenderer(option, onChange, selected, disabledOptions)
        : defaultOptionRenderer(option, onChange, selected, disabledOptions),
    )}
  </div>
);
