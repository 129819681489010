import {
  IPlayerWithStatsDTO,
  IPlayerWithTeamIdAndStats,
  ITeamWithPlayersWithStatsDTO,
} from '../../types';

export const parsePlayersWithStatsData = (
  data: ITeamWithPlayersWithStatsDTO[] | IPlayerWithStatsDTO[],
  teamUuid?: string,
): IPlayerWithTeamIdAndStats[] => {
  if (teamUuid) {
    const typedData = data as IPlayerWithStatsDTO[];
    const newData: IPlayerWithTeamIdAndStats[] = typedData.map(playerData => ({
      id: playerData.player,
      teamId: teamUuid,
      stats: playerData.stats,
    }));
    return newData;
  }

  const typedData = data as ITeamWithPlayersWithStatsDTO[];
  const newData: IPlayerWithTeamIdAndStats[] = typedData
    .map(teamData => {
      const playersData: IPlayerWithTeamIdAndStats[] = teamData.players.map(playerData => ({
        id: playerData.player,
        teamId: teamData.team,
        stats: playerData.stats,
      }));
      return playersData;
    })
    .reduce((acc: IPlayerWithTeamIdAndStats[], playersArray) => acc.concat(playersArray), []);

  return newData;
};
