import { FC, ReactNode } from 'react';

import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './Modal.styles.scss';

export interface IModalProps {
  /** Content for modal. */
  children: ReactNode;
  /** Is the modal opened? */
  open: boolean;
  /** Size of the border radius. Default is large */
  size?: 'small' | 'medium' | 'large';
  /** ModalButton components to the bottom of the modal */
  modalButtons?: ReactNode;
  /** Is overflow visible? */
  overflowVisible?: boolean;
}

const classNames = createClassNames('modal');

/**
 * Modal UI component for displaying info modal.
 */
export const Modal: FC<IModalProps> = ({
  children,
  open,
  size = 'large',
  modalButtons,
  overflowVisible = false,
}) => {
  if (!open) {
    return null;
  }

  return (
    <div className={classNames()}>
      <div
        className={classNames('body', {
          ...transformObjectKeysToKebabCase({
            size,
            hasModalButtons: !!modalButtons,
            overflowVisible,
          }),
        })}
      >
        {children}
        {modalButtons && <div className={classNames('body__buttons')}>{modalButtons}</div>}
      </div>
    </div>
  );
};
