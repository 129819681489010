import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';

import { ITranslationKeys } from '../../../i18n/types';
import {
  IAccessLogAggregation,
  IAccessLogAggregationEntity,
  IAnalyticsType,
  ITrackingUserType,
} from '../../../types';
import { createClassNames } from '../../../utils';
import './LogBarChartTooltip.styles.scss';

export interface ILogBarChartTooltipProps {
  /** Standard tooltip props from recharts library. */
  tooltipProps: TooltipProps<number, string>;
  /** Analytics type. */
  analyticsType: IAnalyticsType;
  /** Selected analytics type total value of all displayed data. */
  total: number;
  /** Should display also total value or only calculated percentage. */
  displayAlsoTotal?: boolean;
}

const classNames = createClassNames('log-bar-chart-tooltip');

/** Tooltip component for LogBarChart component. */
export const LogBarChartTooltip: FC<ILogBarChartTooltipProps> = ({
  tooltipProps: { active, payload },
  analyticsType,
  total,
  displayAlsoTotal,
}) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    const dataPayload = payload[0];

    const { id, type, analytics, users }: IAccessLogAggregationEntity = dataPayload.payload;

    const percentage = total > 0 ? (analytics[analyticsType] * 100) / total : 0;
    const roundedPercentage = `${Math.round(percentage * 10) / 10}%`;
    const displayValue = displayAlsoTotal
      ? `${analytics[analyticsType]} (${roundedPercentage})`
      : roundedPercentage;

    return (
      <div className={classNames({ type })}>
        <div className={classNames('header')}>
          {type === ITrackingUserType.other ? t(ITranslationKeys.others) : id}
          <span>{displayValue}</span>
        </div>
        {renderTop3Users(users, analyticsType)}
      </div>
    );
  }

  return null;
};

const renderTop3Users = (users: IAccessLogAggregation[], analyticsType: IAnalyticsType) => {
  if (analyticsType === IAnalyticsType.uniqueAccessCount) {
    return null;
  }

  const sortedUsers = [...users].sort(
    (a, b) => b.analytics[analyticsType] - a.analytics[analyticsType],
  );
  const top3Users = sortedUsers.slice(0, 3);

  return (
    <>
      <hr />
      <div className={classNames('top3')}>
        {top3Users.map(({ username, name, surname, analytics }) => (
          <div key={username}>
            {`${name && surname ? [name, surname].join(' ') : username} (`}
            <span>{analytics[analyticsType]}</span>
            {`)`}
          </div>
        ))}
      </div>
    </>
  );
};
