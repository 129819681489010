import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { IMultiParamsVoid, ISelectOption, IShowTrendChartLines } from '../../../types';
import { createClassNames } from '../../../utils';
import { Caption } from '../../Caption';
import { CheckboxButton } from '../../CheckboxButton';
import { DisplayField } from '../../DisplayField';
import { SelectInput } from '../../SelectInput';
import './TrendToolbar.styles.scss';

export interface ITrendToolbarProps extends IShowTrendChartLines {
  /** Available metrics to select options. */
  metricOptions: ISelectOption[];
  /** Selected metric option. */
  selectedMetric: ISelectOption | undefined;
  /** Name of selected player. */
  selectedPlayerName: string | undefined;
  /** Function called when other metric option is picked. */
  onMetricChange: IMultiParamsVoid;
  /** Function to toggle league average value. */
  setShowLeagueAverage: Dispatch<SetStateAction<boolean>>;
  /** Function to toggle moving average values. */
  setShowMovingAverage: Dispatch<SetStateAction<boolean>>;
  /** Function to toggle show metric values. */
  setShowMetric: Dispatch<SetStateAction<boolean>>;
  /** When true, player selection field is disabled. */
  disablePlayerField?: boolean;
}

const classNames = createClassNames('trend-toolbar');

/** Toolbar component for trend chart. */
export const TrendToolbar: FC<ITrendToolbarProps> = ({
  metricOptions,
  selectedMetric,
  selectedPlayerName,
  showLeagueAverage,
  showMovingAverage,
  showMetric,
  disablePlayerField = false,
  onMetricChange,
  setShowLeagueAverage,
  setShowMovingAverage,
  setShowMetric,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      <div>
        <Caption label={ITranslationKeys.pointer} />
        <SelectInput
          onChange={onMetricChange}
          selected={selectedMetric}
          options={metricOptions}
          placeholder={ITranslationKeys.defaultSelectPlaceholder}
          variant='filter'
        />
      </div>
      {!disablePlayerField && (
        <div>
          <Caption label={ITranslationKeys.player} />
          <DisplayField>{selectedPlayerName}</DisplayField>
        </div>
      )}
      <div>
        <Caption label={ITranslationKeys.displayInGraph} />
        {/* TODO: Přidat tlačítko na první pozici až bude hotový na API ligový průměr */}
        {/* <CheckboxButton
          children={
            <span className={classNames('button-label')}>{t(ITranslationKeys.leagueAverage)}</span>
          }
          checked={showLeagueAverage}
          onClick={() => setShowLeagueAverage(!showLeagueAverage)}
          shouldCheckFullBody
          shouldContentBeColored
        /> */}
        <CheckboxButton
          children={
            <span className={classNames('button-label')}>{t(ITranslationKeys.movingAverage)}</span>
          }
          checked={showMovingAverage}
          onClick={() => setShowMovingAverage(!showMovingAverage)}
          color='red'
          shouldCheckFullBody
          shouldContentBeColored
        />
      </div>
      <CheckboxButton
        children={<span className={classNames('button-label')}>{selectedMetric?.label}</span>}
        checked={showMetric}
        onClick={() => setShowMetric(!showMetric)}
        color='blue'
        shouldCheckFullBody
        shouldContentBeColored
      />
    </div>
  );
};
