import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { defaultTemplate, positionOptions } from '../../constants';
import { IDatasetMetric, ISelectOption, ITableFilterState } from '../../types';

const initialState: ITableFilterState = {
  dataTemplate: defaultTemplate,
  position: positionOptions[0],
  customMetrics: undefined,
  playersInChart: undefined,
  stickGrip: undefined,
  gamePart: undefined,
  gameCountOfPlayers: undefined,
};

export const tableFilterSlice = createSlice({
  name: 'tableFilter',
  initialState,
  reducers: {
    setDataTemplate: (state, action: PayloadAction<ISelectOption | undefined>) => {
      state.dataTemplate = action.payload;
    },
    setPosition: (state, action: PayloadAction<ISelectOption>) => {
      state.position = action.payload;
    },
    setCustomMetrics: (
      state,
      action: PayloadAction<ISelectOption<IDatasetMetric>[] | undefined>,
    ) => {
      state.customMetrics = action.payload;
    },
    setPlayersInChart: (state, action: PayloadAction<ISelectOption>) => {
      state.playersInChart = action.payload;
    },
    setStickGrip: (state, action: PayloadAction<ISelectOption>) => {
      state.stickGrip = action.payload;
    },
    setGamePart: (state, action: PayloadAction<ISelectOption>) => {
      state.gamePart = action.payload;
    },
    setGameCountOfPlayers: (state, action: PayloadAction<ISelectOption>) => {
      state.gameCountOfPlayers = action.payload;
    },
    resetTableFilterState: () => initialState,
  },
});

export const {
  setDataTemplate,
  setPosition,
  setCustomMetrics,
  setPlayersInChart,
  setStickGrip,
  setGamePart,
  setGameCountOfPlayers,
  resetTableFilterState,
} = tableFilterSlice.actions;

export const tableFilterReducer = tableFilterSlice.reducer;
