import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { IDangerShotsStats } from '../../../types';
import { createClassNames } from '../../../utils';
import { CircleBar } from '../../CircleBar';
import { GameShotsStats } from '../GameShotsStats';
import './GameShots.styles.scss';

export interface IGameShotsProps {
  /**
   * Home team's shots info
   */
  home: IDangerShotsStats;
  /**
   * Away team's shots info
   */
  away: IDangerShotsStats;
}

const classNames = createClassNames('game-shots');

/**
 * GameShots UI component.
 */
export const GameShots: FC<IGameShotsProps> = ({ home, away }) => {
  const { t } = useTranslation();

  const homeCount = home.low.count + home.medium.count + home.high.count;
  const awayCount = away.low.count + away.medium.count + away.high.count;
  const calculateFrom = homeCount > awayCount ? homeCount : awayCount;

  return (
    <div className={classNames()}>
      <div className={classNames('team')}>
        <GameShotsStats high={home.high} medium={home.medium} low={home.low} />
        <CircleBar value={homeCount} calculateFrom={calculateFrom} team='home' />
      </div>
      <h3 className={classNames('title')}>{t(ITranslationKeys.shots)}</h3>
      <div className={classNames('team')}>
        <CircleBar value={awayCount} calculateFrom={calculateFrom} team='away' />
        <GameShotsStats high={away.high} medium={away.medium} low={away.low} reverse />
      </div>
    </div>
  );
};
