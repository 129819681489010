import { useState } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { LanguageController, Loading, TcmsButton } from '../../../../components';
import { selectSettings } from '../../../../features';
import { ILanguageType, ISelectOption, helpManagementSectionKeys } from '../../../../types';
import { createClassNames } from '../../../../utils';
import './HelpManagementPage.styles.scss';

const classNames = createClassNames('help-management-page');

export const HelpManagementPage = () => {
  const settings = useAppSelector(selectSettings);
  const { help: originalHelp } = settings;

  const [activeLanguage, setActiveLanguage] = useState<keyof ILanguageType<string>>('cz');
  const [activeSection, setActiveSection] = useState<ISelectOption>(helpManagementSectionKeys[0]);

  const handleHelpManagementSectionClick = (option: ISelectOption) => {
    setActiveSection(option);
  };

  if (originalHelp.isLoading) {
    return <Loading />;
  }

  return (
    <div className={classNames()}>
      <div className={classNames('controls')}>
        <h2>Kategorie nápovědy</h2>
        <div className={classNames('controls__buttons')}>
          {helpManagementSectionKeys.map((option, index) => (
            <TcmsButton
              key={index}
              label={option.label}
              variant='filled'
              onClick={() => handleHelpManagementSectionClick(option)}
              color={activeSection === option ? 'blue' : 'default'}
            />
          ))}
        </div>
      </div>
      <div className={classNames('content')}>
        <div className={classNames('content__flags')}>
          <LanguageController
            activeLanguage={activeLanguage}
            onClick={() => setActiveLanguage(activeLanguage === 'cz' ? 'en' : 'cz')}
          />
        </div>
        <div className={classNames('content__logic')}>Help content</div>
      </div>
    </div>
  );
};
