import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  closeVideoCenter,
  resetVideoEditById,
  selectAuth,
  selectGoalkeepers,
  selectPlayers,
  selectTeams,
  selectVideoCenter,
  setVideoEdit,
  videoRecordWithEditsSelector,
} from '../../../features';
import { useVideoCenter } from '../../../hooks';
import { ITranslationKeys } from '../../../i18n/types';
import { IClip, IGameEntity, IGoalkeeper, IPlayer, ITeam, IVideoEdit } from '../../../types';
import {
  createClassNames,
  deleteClip,
  getVideoClips,
  getVideoOptions,
  preparePlayer,
  sortPlayersByClips,
  sortPlayersByPost,
} from '../../../utils';
import { Loading } from '../../Loading';
import { Modal, ModalButton } from '../../modalComponents';
import { VideoCenterModal, VideoCenterSelectList } from '../../videoCenterComponents';
import { HokejPlayerManagementHeader } from '../HokejPlayerManagementHeader';
import {
  HokejPlayerManagementRow,
  IHokejPlayerManagementRowProps,
} from '../HokejPlayerManagementRow';
import './HokejPlayerManagement.styles.scss';

const classNames = createClassNames('hokej-player-management');

/**
 * HokejPlayerManagement UI component.
 */
export const HokejPlayerManagement = () => {
  const { user } = useAppSelector(selectAuth);
  const teamSelector = useAppSelector(selectTeams);
  const playerSelector = useAppSelector(selectPlayers);
  const goalkeeperSelector = useAppSelector(selectGoalkeepers);
  const [videocoachTeam, setVideocoachTeam] = useState<ITeam>();
  const [data, setData] = useState<IHokejPlayerManagementRowProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const videoCenter = useAppSelector(selectVideoCenter);
  const [gameEntities, setGameEntities] = useState<IGameEntity[]>([]);
  const [clipToDelete, setClipToDelete] = useState<IClip | undefined>(undefined);
  const { videoRecordWithEdits } = useAppSelector(videoRecordWithEditsSelector);
  const { t } = useTranslation();
  const { playVideos } = useVideoCenter(gameEntities, ITranslationKeys.shifts);
  const dispatch = useAppDispatch();

  const handleAddToData = (item: IPlayer | IGoalkeeper, teamId: string, clips: IClip[]) => {
    if (item.currentTeam === teamId) {
      setData(prevState => [...prevState, preparePlayer(item, clips)]);
    }
  };

  const handleClose = () => {
    dispatch(closeVideoCenter());
  };

  const handleResetVideoEdit = (videoUuid: string) => {
    dispatch(resetVideoEditById(videoUuid));
  };

  const handleSetVideoEdit = (edit: IVideoEdit) => {
    dispatch(setVideoEdit(edit));
  };

  const handleGetViedos = (goalkeeperIds: string[], playerIds: string[], teamId: string) => {
    getVideoClips()
      .then(res => {
        goalkeeperIds.forEach(goalkeeperId => {
          const goalkeeper = goalkeeperSelector.byId[goalkeeperId];
          handleAddToData(goalkeeper, teamId, res.data);
        });
        playerIds.forEach(playerId => {
          const player = playerSelector.byId[playerId];
          handleAddToData(player, teamId, res.data);
        });
        setData(prevState => [...prevState].sort(sortPlayersByClips).sort(sortPlayersByPost));
        setIsLoading(false);
      })
      .catch(err => console.log('Get video clips err:', err));
  };

  useEffect(() => {
    setData([]);
    setIsLoading(true);
    if (user?.teamId) {
      setVideocoachTeam(teamSelector.byId[user.teamId]);
    }
    if (videocoachTeam) {
      handleGetViedos(videocoachTeam.goalkeeperIds, videocoachTeam.playerIds, videocoachTeam.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videocoachTeam, user]);

  useEffect(() => {
    if (!gameEntities || gameEntities.length === 0) return;
    playVideos(gameEntities[0].entities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameEntities]);

  const handleClipSelection = (clip: IClip, row: IHokejPlayerManagementRowProps) => {
    const gameEntity: IGameEntity = {
      entities: [
        {
          videoId: clip.videoId,
          playerId: row.playerId,
          time: clip.time,
          videoTime: clip.videoTime,
          matchId: clip.matchId,
          matchDate: clip.date,
        },
      ],
      gameId: clip.matchId,
    };
    setGameEntities([gameEntity]);
  };

  const handleClipDeleteClick = (clip: IClip) => setClipToDelete(clip);

  const handleClipDeletion = (clip: IClip) =>
    deleteClip(clip.id)
      .then(() => {
        toast.success(t(ITranslationKeys.clipDeleted), {
          toastId: ITranslationKeys.clipDeleted,
        });
        setData(prevState =>
          prevState.map(row => ({
            ...row,
            clips: row.clips.filter(rowClip => rowClip.id !== clip.id),
          })),
        );
      })
      .catch(() =>
        toast.error(t(ITranslationKeys.clipDeleteError), {
          toastId: ITranslationKeys.clipDeleteError,
        }),
      )
      .finally(() => setClipToDelete(undefined));

  return (
    <div className={classNames()}>
      <HokejPlayerManagementHeader logoPath={videocoachTeam ? videocoachTeam.id : ''} />
      {isLoading ? (
        <Loading />
      ) : (
        <div className={classNames('table-container')}>
          <table className={classNames('table')}>
            <thead>
              <tr>
                <th align='left'>{t(ITranslationKeys.playerPosition)}</th>
                <th align='left'>{t(ITranslationKeys.player)}</th>
                <th align='right'>{t(ITranslationKeys.email)}</th>
                <th align='center'>{t(ITranslationKeys.language)}</th>
                <th align='right'>{t(ITranslationKeys.videocoachClips)}</th>
              </tr>
            </thead>
            <tbody>
              {data.map(row => (
                <HokejPlayerManagementRow
                  key={row.playerId}
                  {...row}
                  onClipSelect={clip => handleClipSelection(clip, row)}
                  onClipDelete={handleClipDeleteClick}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
      <VideoCenterModal
        {...videoCenter}
        categoryKey={ITranslationKeys.accountManagement}
        handleClose={handleClose}
        handleSetVideoEdit={handleSetVideoEdit}
        handleResetVideoEdit={handleResetVideoEdit}
        renderList={params => (
          <VideoCenterSelectList {...params} videosRecord={videoRecordWithEdits} />
        )}
        videoRecord={videoRecordWithEdits}
        originalVideoRecord={videoCenter.videoRecord}
        options={getVideoOptions(videoCenter.videoRecord)}
      />
      {clipToDelete && (
        <Modal
          open={true}
          modalButtons={
            <>
              <ModalButton
                label={ITranslationKeys.close}
                fontWeight='normal'
                onClick={() => setClipToDelete(undefined)}
              />
              <ModalButton
                label={ITranslationKeys.delete}
                fontWeight='bold'
                onClick={() => handleClipDeletion(clipToDelete)}
              />
            </>
          }
        >
          {t(ITranslationKeys.removeVideocoachNote)}
        </Modal>
      )}
    </div>
  );
};
