import { IAccessLogAggregationEntityRecord, ILogHokejPlayer, ITrackingUserType } from '../../types';

const parseLogHokejPlayerToAccessLogAggregation = (
  acc: IAccessLogAggregationEntityRecord,
  item: ILogHokejPlayer,
  id: string,
) => {
  const { registrationNumber, openedNotificationCount, name, surname, team } = item;

  const exists = !!acc[id];

  if (exists) {
    acc[id].users.push({
      name,
      surname,
      team,
      username: registrationNumber,
      analytics: {
        accessCount: openedNotificationCount,
        screenCount: 0,
      },
    });

    acc[id].analytics.accessCount += openedNotificationCount;
    if (openedNotificationCount > 0) {
      acc[id].analytics.uniqueAccessCount += 1;
    }
  } else {
    acc[id] = {
      id,
      type: ITrackingUserType.team,
      users: [
        {
          name,
          surname,
          team,
          username: registrationNumber,
          analytics: {
            accessCount: openedNotificationCount,
            screenCount: 0,
          },
        },
      ],
      analytics: {
        accessCount: openedNotificationCount,
        screenCount: 0,
        uniqueAccessCount: openedNotificationCount > 0 ? 1 : 0,
      },
    };
  }
};

export const parseLogHokejPlayerOverviewData = (data: ILogHokejPlayer[] | undefined) => {
  if (!data) return undefined;

  const filterData = data.filter(item => !!item.team);

  return filterData.reduce<IAccessLogAggregationEntityRecord>((acc, item) => {
    parseLogHokejPlayerToAccessLogAggregation(acc, item, item.team!);
    return acc;
  }, {});
};
