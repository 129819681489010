import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';

import { COLORS } from '../../../../constants';
import { useWindowSize } from '../../../../hooks';
import { ArrowPassIcon } from '../../../../icons';
import { IPlaygroundShotOrPass, IVideoInfo } from '../../../../types';
import {
  calculateAssistPosition,
  calculateShotOrPassPosition,
  computeResponsivePlaygroundSize,
  createClassNames,
  getAssistAngle,
  getPlayerColor,
  getSelectedPlayerColor,
  transformObjectKeysToKebabCase,
} from '../../../../utils';
import { PlaygroundShotTooltip } from '../../PlaygroundShotTooltip';
import './PlaygroundAssistsContent.styles.scss';

export interface IPlaygroundAssistsContentProps {
  /** An array containing the assists to be drawn. */
  assists: IPlaygroundShotOrPass[];
  /** Hides trajectories. */
  hideTrajectories?: boolean;
  /** Function called when the play button is clicked. */
  onPlayClick?: (array: IVideoInfo[]) => void;
  /** Click event on the canvas fired after the assists are drawn. */
  onCanvasClick?: () => void;
  /** Click event on the assists fired after the selected assist is drawn as selected. */
  onAssistClick?: (assist: IPlaygroundShotOrPass) => void;
}

const classNames = createClassNames('playground-assists-content');

/** A content component displaying the assists of the selected match. */
export const PlaygroundAssistsContent: FC<IPlaygroundAssistsContentProps> = ({
  assists,
  hideTrajectories,
  onCanvasClick,
  onAssistClick,
  onPlayClick,
}) => {
  const [selectedAssist, setSelectedAssist] = useState<IPlaygroundShotOrPass>();

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const { width: windowWidth } = useWindowSize();
  const { width: playgroundWidth, height: playgroundHeight } =
    computeResponsivePlaygroundSize(windowWidth);

  useEffect(() => {
    if (canvasRef.current) {
      if (hideTrajectories) {
        drawTrajectories([], canvasRef, playgroundWidth, playgroundHeight);
      } else {
        drawTrajectories(assists, canvasRef, playgroundWidth, playgroundHeight);
      }
    }
  }, [canvasRef, playgroundWidth, playgroundHeight, assists, hideTrajectories]);

  useEffect(() => {
    setSelectedAssist(undefined);
  }, [assists]);

  const handleCanvasClick = () => {
    if (hideTrajectories) {
      drawTrajectories([], canvasRef, playgroundWidth, playgroundHeight);
    } else {
      drawTrajectories(assists, canvasRef, playgroundWidth, playgroundHeight);
    }
    setSelectedAssist(undefined);
    onCanvasClick?.();
  };

  const handleAssistClick = (assist: IPlaygroundShotOrPass) => {
    if (assist === selectedAssist) return;
    if (hideTrajectories) {
      drawTrajectories([], canvasRef, playgroundWidth, playgroundHeight);
    } else {
      drawTrajectories(assists, canvasRef, playgroundWidth, playgroundHeight);
    }
    drawOnlyTrajectory(assist, canvasRef, playgroundWidth, playgroundHeight);

    setSelectedAssist(assist);
    onAssistClick?.(assist);
  };

  return (
    <>
      <div className={classNames()} data-testid='playground-assists-content'>
        <canvas
          ref={canvasRef}
          width='760px'
          height='582px'
          className={classNames('canvas')}
          onClick={handleCanvasClick}
        ></canvas>

        {assists.map((assist, index) => {
          const { x: assistPosX, y: assistPosY } = calculateAssistPosition(
            assist.position.x,
            assist.position.y,
            playgroundWidth,
            playgroundHeight,
          );

          return (
            <div
              key={index}
              className={classNames('assist', {
                ...transformObjectKeysToKebabCase({
                  selected: assist === selectedAssist,
                  color: assist.color,
                }),
              })}
              tabIndex={index}
              style={{
                left: assistPosX - 8,
                top: assistPosY - 8,
                transform: 'rotateZ(' + getAssistAngle(assist) + 'deg)',
              }}
              onClick={() => handleAssistClick(assist)}
            >
              <ArrowPassIcon />
            </div>
          );
        })}
      </div>
      {selectedAssist && selectedAssist.xG && (
        <PlaygroundShotTooltip
          date={new Date(selectedAssist.videoInfo.matchDate)}
          time={selectedAssist.videoInfo.time}
          homeTeamShortcut={selectedAssist.homeTeamShortcut}
          awayTeamShortcut={selectedAssist.awayTeamShortcut}
          shotPlayerName={selectedAssist.playerName}
          passPlayerName={selectedAssist.assistPlayerName}
          xG={selectedAssist.xG}
          activeZone={selectedAssist.activeZone}
          show={!!selectedAssist}
          position={{
            top: selectedAssist.position.y,
            left: selectedAssist.position.x,
          }}
          onPlayClick={() => onPlayClick?.([selectedAssist.videoInfo])}
        />
      )}
    </>
  );
};

/**
 * Draws trajectories of the given assists from their position to the shot position.
 * @param assists An array containing the assists to be drawn with.
 * @param canvasRef A reference of the canvas element.
 * @returns
 */
const drawTrajectories = (
  assists: IPlaygroundShotOrPass[],
  canvasRef: MutableRefObject<HTMLCanvasElement | null>,
  playgroundWidth: number,
  playgroundHeight: number,
) => {
  if (canvasRef?.current === null) return;

  const drawCanvas: CanvasRenderingContext2D | null = canvasRef.current.getContext('2d');
  if (!drawCanvas) return;
  drawCanvas.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

  assists.forEach(assist => {
    if (!drawCanvas) return;

    const strokeColor = getPlayerColor(assist.color);
    const { x: assistPosX, y: assistPosY } = calculateAssistPosition(
      assist.position.x,
      assist.position.y,
      playgroundWidth,
      playgroundHeight,
    );

    if (assist.shot) {
      const { x, y } = calculateShotOrPassPosition(assist.shot.x, assist.shot.y, playgroundWidth);

      drawLine(drawCanvas, COLORS.white.base, 5, [assistPosX, assistPosY], [x, y]);
      drawLine(drawCanvas, strokeColor, 2, [assistPosX, assistPosY], [x, y]);
      drawArc(drawCanvas, strokeColor, COLORS.white.base, 1, 9, [x, y]);

      if (assist.shot.type === 'G') {
        const { x, y } = calculateShotOrPassPosition(assist.shot.x, assist.shot.y, playgroundWidth);
        drawCanvas.font = '500 12px verdana, sans-serif';
        drawCanvas.fillStyle = COLORS.white.base;
        drawCanvas.fillText('G', x - 5, y + 4);
      }
    }

    drawArc(drawCanvas, COLORS.coolGray[100], strokeColor, 1, 2, [assistPosX, assistPosY]);
  });
};

const drawOnlyTrajectory = (
  assist: IPlaygroundShotOrPass,
  canvasRef: MutableRefObject<HTMLCanvasElement | null>,
  playgroundWidth: number,
  playgroundHeight: number,
) => {
  if (canvasRef?.current === null) return;

  const drawCanvas: CanvasRenderingContext2D | null = canvasRef.current.getContext('2d');
  if (!drawCanvas) return;

  const strokeColor = getSelectedPlayerColor(assist.color);
  const { x: assistPosX, y: assistPosY } = calculateAssistPosition(
    assist.position.x,
    assist.position.y,
    playgroundWidth,
    playgroundHeight,
  );

  if (assist.shot) {
    const { x, y } = calculateShotOrPassPosition(assist.shot.x, assist.shot.y, playgroundWidth);

    drawLine(drawCanvas, COLORS.white.base, 5, [assistPosX, assistPosY], [x, y]);
    drawLine(drawCanvas, strokeColor, 2, [assistPosX, assistPosY], [x, y]);
    drawArc(drawCanvas, strokeColor, COLORS.white.base, 1, 9, [x, y]);

    if (assist.shot.type === 'G') {
      const { x, y } = calculateShotOrPassPosition(assist.shot.x, assist.shot.y, playgroundWidth);
      drawCanvas.font = '500 12px verdana, sans-serif';
      drawCanvas.fillStyle = COLORS.white.base;
      drawCanvas.fillText('G', x - 5, y + 4);
    }
  }

  drawArc(drawCanvas, COLORS.coolGray[100], strokeColor, 1, 2, [assistPosX, assistPosY]);
};

/**
 * Draws a line to the given canvas.
 */
const drawLine = (
  canvas: CanvasRenderingContext2D,
  strokeColor: string,
  lineWidth: number,
  moveToPosition: number[],
  lineToPosition: number[],
): void => {
  canvas.beginPath();
  canvas.strokeStyle = strokeColor;
  canvas.lineWidth = lineWidth;
  canvas.moveTo(moveToPosition[0], moveToPosition[1]);
  canvas.lineTo(lineToPosition[0], lineToPosition[1]);
  canvas.stroke();
};

/**
 * Draws a rounded rectangle to the given canvas.
 */
const drawArc = (
  canvas: CanvasRenderingContext2D,
  fillColor: string,
  strokeColor: string,
  lineWidth: number,
  arcRadius: number,
  position: number[],
): void => {
  canvas.beginPath();
  canvas.arc(position[0], position[1], arcRadius, 0, 2 * Math.PI, false);
  canvas.fillStyle = fillColor;
  canvas.fill();
  canvas.lineWidth = lineWidth;
  canvas.strokeStyle = strokeColor;
  canvas.stroke();
};
