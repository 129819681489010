import { CellContext } from '@tanstack/react-table';

import { MinusActiveIcon, PlusActiveIcon } from '../../../icons';
import { createClassNames } from '../../../utils';
import './ExpandIconButtonColumn.styles.scss';

export interface IExpandIconButtonColumnCellProps<TData, TValue>
  extends CellContext<TData, TValue> {}

const classNamesCell = createClassNames('expand-icon-button-column-cell');

export const ExpandIconButtonColumnCell = <TData, TValue>({
  row,
}: IExpandIconButtonColumnCellProps<TData, TValue>) => (
  <div className={classNamesCell()} onClick={() => row.toggleExpanded()}>
    {row.getIsExpanded() ? <MinusActiveIcon /> : <PlusActiveIcon />}
  </div>
);
