import { CSSProperties, FC } from 'react';

import { createClassNames } from '../../utils';
import './Skeleton.styles.scss';

export type ISkeletonProps = {
  /** Width (default 100%) */
  width?: CSSProperties['width'];
  /** Height (default 100%) */
  height?: CSSProperties['height'];
  /** Circle shape */
  circle?: boolean;
  /** Border radius */
  borderRadius?: CSSProperties['borderRadius'];
};

const classNames = createClassNames('skeleton');

/** UI component indicates loading. */
export const Skeleton: FC<ISkeletonProps> = ({
  width = '100%',
  height = '100%',
  circle,
  borderRadius,
}) => <div className={classNames({ circle })} style={{ width, height, borderRadius }} />;
