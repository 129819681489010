import { createSelector } from 'reselect';

import { allTeamOrMediaAllOption, mediaOptions, notFilledOutOption } from '../../../constants';
import { ISelectOption, ITeamRecord, ITrackingUserType } from '../../../types';
import { teamsToOptions } from '../../../utils';
import { selectTeams } from '../../selectors';

const createTeamsOptions = (byId: ITeamRecord, attr: 'id' | 'shortcut' = 'id'): ISelectOption[] =>
  Object.values(Object.values(byId))
    .map<ISelectOption>(team => {
      return {
        value: team[attr],
        label: team['name'],
        additionalValue: ITrackingUserType.team,
      };
    })
    .sort((a, b) => {
      const labelA = a.label.toLowerCase();
      const labelB = b.label.toLowerCase();
      return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
    });

export const teamAndMediaOptionsSelector = createSelector(selectTeams, teamsState => {
  const { byId } = teamsState;

  const teamAndMediaOptions = createTeamsOptions(byId, 'shortcut').concat(mediaOptions);
  teamAndMediaOptions.unshift(allTeamOrMediaAllOption);

  return teamAndMediaOptions;
});

export const teamAndMediaAccessAndScreenLogOptionsSelector = createSelector(
  selectTeams,
  teamsState => {
    const { byId } = teamsState;

    const teamAndMediaOptions = createTeamsOptions(byId).concat(mediaOptions);
    teamAndMediaOptions.unshift(allTeamOrMediaAllOption);

    return teamAndMediaOptions;
  },
);

export const teamAndMediaToSetUserOptionsSelector = createSelector(selectTeams, teamsState => {
  const { byId } = teamsState;

  const teamAndMediaOptions = teamsToOptions(Object.values(byId), 'name').concat(mediaOptions);
  teamAndMediaOptions.unshift(notFilledOutOption);

  return teamAndMediaOptions;
});
