import { useAppDispatch } from '../../../app/hooks';
import {
  faceoffDefensiveZoneOption,
  faceoffNeutralZoneOption,
  faceoffOffensiveZoneOption,
  faceoffResultWonOption,
} from '../../../constants';
import { setFaceoffResult, setFaceoffZone } from '../../../features';
import { IMetricWithDatasetMetric } from '../../../types';

export const useSetFaceoffsFilter = () => {
  const dispatch = useAppDispatch();

  const setFaceoffsFilterSetter = (stat: IMetricWithDatasetMetric) => {
    const isParameter = stat.metric.id.includes('.');
    const [parameter, origin] = stat.metric.id.split('.');

    const mainMetric = isParameter ? origin : stat.metric.id;

    if (isParameter) {
      if (parameter === 'dz') {
        dispatch(setFaceoffZone(faceoffDefensiveZoneOption));
      } else if (parameter === 'nz') {
        dispatch(setFaceoffZone(faceoffNeutralZoneOption));
      } else if (parameter === 'oz') {
        dispatch(setFaceoffZone(faceoffOffensiveZoneOption));
      }
    }

    if (mainMetric.startsWith('fow')) {
      dispatch(setFaceoffResult(faceoffResultWonOption));
    }
  };

  return { setFaceoffsFilterSetter };
};
