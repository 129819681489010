import { useCallback, useRef } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  EyeDropdown,
  IEyeDropdownTooltipItem,
  ImageColumn,
  MetricColumnCell,
  Sticker,
} from '../../../../components';
import { logosPaths } from '../../../../constants';
import { selectPlayers, selectTeams, selectVideomapsFilter } from '../../../../features';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IFormationsSelectedTeam,
  INavigationContentKeys,
  INavigationPageKeys,
  ITablePart,
  IUrlFilterParams,
} from '../../../../types';
import { isAtLeastOnePlayerOn, secondsToMinutesAndSeconds } from '../../../../utils';
import { OnOffFormationsPlayers } from './components';

export const useExtraRowHeaderRender = (selectedTeam: IFormationsSelectedTeam | undefined) => {
  const { byId } = useAppSelector(selectTeams);
  const { similarPlayerNames } = useAppSelector(selectPlayers);
  const { selectedPlayerItems } = useAppSelector(selectVideomapsFilter);

  const ref = useRef<HTMLTableRowElement>(null);

  const extraRowHeaderRender = useCallback(
    (tablePart: ITablePart = 'left') => {
      if (selectedTeam) {
        const teamInfo = selectedTeam ? byId[selectedTeam.id] : undefined;
        const isSomePlayerSelected = isAtLeastOnePlayerOn(selectedPlayerItems, true);

        if (tablePart === 'right') {
          return undefined;
        }

        if (tablePart === 'center') {
          return (
            <tr
              key={selectedTeam.id}
              style={{ height: ref.current?.getBoundingClientRect().height }}
            >
              {Object.values(selectedTeam.stats).map(stat => (
                <th key={stat.id} className='extra-header-cell'>
                  <MetricColumnCell value={stat.value} stat={stat} entityId={selectedTeam.id} />
                </th>
              ))}
            </tr>
          );
        }

        const selectedTeamInfo = byId[selectedTeam.id];

        const filterParams: IUrlFilterParams[] = [
          {
            name: 'selectedTeam',
            value: selectedTeamInfo.id,
          },
        ];

        const eyeDropdownTooltipItems: IEyeDropdownTooltipItem[] = [
          {
            pageTranslationLabel: ITranslationKeys.gamelog,
            navigationPageKey: isSomePlayerSelected
              ? INavigationPageKeys.players
              : INavigationPageKeys.teams,
            navigationTabKey: INavigationContentKeys.gamelog,
            filterParams,
          },
        ];

        if (isSomePlayerSelected) {
          filterParams.push({
            name: 'selectedPlayerItems',
            value: Object.values(selectedPlayerItems)
              .map(item => `${item.selectedPlayer?.value}_${!!item.isActive}`)
              .join(','),
          });

          // TODO: Add also for teams trend after implementing it
          eyeDropdownTooltipItems.push({
            pageTranslationLabel: ITranslationKeys.trend,
            navigationPageKey: isSomePlayerSelected
              ? INavigationPageKeys.players
              : INavigationPageKeys.teams,
            navigationTabKey: INavigationContentKeys.trend,
            filterParams,
          });
        }

        return (
          <tr ref={ref} key={selectedTeam.id}>
            <th className='extra-header-cell'>
              <EyeDropdown eyeDropdownTooltipItems={eyeDropdownTooltipItems} />
            </th>
            <th className='extra-header-cell'>
              <ImageColumn src={logosPaths[selectedTeam.id]} alt='team-logo' />
            </th>
            <th className='extra-header-cell'>
              <span style={{ display: 'flex' }}>
                {isSomePlayerSelected ? (
                  <OnOffFormationsPlayers
                    selectedPlayerItems={selectedPlayerItems}
                    similarPlayerNames={similarPlayerNames}
                  />
                ) : (
                  <Sticker label={teamInfo?.name ?? ''} fontWeight='bold' color='white' />
                )}
              </span>
            </th>
            <th className='extra-header-cell'>{selectedTeam.gp}</th>
            <th className='extra-header-cell'>{secondsToMinutesAndSeconds(selectedTeam.toi)}</th>
          </tr>
        );
      }

      return null;
    },
    [selectedTeam, byId, selectedPlayerItems, similarPlayerNames],
  );

  return extraRowHeaderRender;
};
