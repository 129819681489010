import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { CheckboxButton } from '../../../../../../components';
import {
  selectWowy,
  setActiveRowsSeparately,
  setActiveRowsTeammateSeparately,
  setActiveRowsTogether,
} from '../../../../../../features';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { createClassNames } from '../../../../../../utils';
import './WowyCheckboxRowButtons.styles.scss';

const classNames = createClassNames('wowy-checkbox-row-buttons');

export const WowyCheckboxRowButtons = () => {
  const { activeRows } = useAppSelector(selectWowy);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <>
      <CheckboxButton
        children={<span className={classNames()}>{t(ITranslationKeys.withTeammate)}</span>}
        checked={activeRows.together}
        onClick={() => dispatch(setActiveRowsTogether(!activeRows.together))}
        color='teal'
        shouldCheckFullBody
        shouldContentBeColored
      />
      <CheckboxButton
        children={<span className={classNames()}>{t(ITranslationKeys.withoutTeammate)}</span>}
        checked={activeRows.separately}
        onClick={() => dispatch(setActiveRowsSeparately(!activeRows.separately))}
        color='red'
        shouldCheckFullBody
        shouldContentBeColored
      />
      <CheckboxButton
        children={<span className={classNames()}>{t(ITranslationKeys.teammateWithout)}</span>}
        checked={activeRows.teammateSeparately}
        onClick={() => dispatch(setActiveRowsTeammateSeparately(!activeRows.teammateSeparately))}
        color='purple'
        shouldCheckFullBody
        shouldContentBeColored
      />
    </>
  );
};
