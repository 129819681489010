import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLeftRightOffset } from '../../../hooks';
import { ITranslationKeys } from '../../../i18n/types';
import { ISeasonRecord, ISelectOption } from '../../../types';
import { createClassNames, formatLongYearRangeToShort, getSeasonsOptions } from '../../../utils';
import { ScrollButton } from '../../ScrollButton';
import { TcmsButton } from '../TcmsButton';
import './SeasonSelect.styles.scss';

export interface ISeasonSelectProps {
  /** Season record. */
  seasonRecord: ISeasonRecord;
  /** On season click callback. */
  onSeasonClick?: (season: ISelectOption) => void;
  /** Width of component. */
  width?: string;
}

const classNames = createClassNames('season-select');

/** Component for season select. */
export const SeasonSelect: FC<ISeasonSelectProps> = ({
  seasonRecord,
  onSeasonClick,
  width = '100%',
}) => {
  const { t } = useTranslation();

  const seasonOptions = useMemo(() => getSeasonsOptions(seasonRecord).reverse(), [seasonRecord]);

  const { offset, setOffset, ...leftRightArrowSettings } = useLeftRightOffset(
    seasonOptions.length,
    { dataCountToDisplay: 1 },
  );

  useEffect(() => {
    setOffset(seasonOptions.length - 1);
  }, [seasonOptions, setOffset]);

  const selectedSeason = seasonOptions[offset];

  return (
    <div className={classNames()} style={{ width }}>
      <ScrollButton
        isLeft
        variant='tcms'
        disabled={!leftRightArrowSettings.enableLeft}
        onClick={leftRightArrowSettings.handleClickLeft}
      />
      <TcmsButton
        label={`${t(ITranslationKeys.selectSeason)} ${formatLongYearRangeToShort(
          selectedSeason.label,
        )}`}
        variant='text'
        color='blue'
        size='large'
        onClick={() => onSeasonClick?.(selectedSeason)}
      />
      <ScrollButton
        variant='tcms'
        disabled={!leftRightArrowSettings.enableRight}
        onClick={leftRightArrowSettings.handleClickRight}
      />
    </div>
  );
};
