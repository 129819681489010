import { useMemo } from 'react';

import {
  IHokejPlayerTrackingFilterForm,
  ILogHokejPlayer,
  ILogHokejPlayerTableData,
  ISelectOption,
} from '../../../../../../types';

export const useDataForTable = (
  data: ILogHokejPlayer[] | undefined,
  submitValues: IHokejPlayerTrackingFilterForm,
) => {
  const { attendance, searchedPlayer } = submitValues;

  const filteredPlayersByAttendance = useMemo(
    () => (data ? filterPlayersByAttendance(data, attendance) : undefined),
    [data, attendance],
  );

  const dataForTables = useMemo(() => {
    if (!data || !filteredPlayersByAttendance) return undefined;

    const filteredData = searchedPlayer
      ? data.filter(item => item.registrationNumber === searchedPlayer.value)
      : attendance?.value === 'all'
      ? data
      : filteredPlayersByAttendance;

    const sharedData = getSharedData(filteredData);

    const clickthroughRateData = sharedData
      .map<ILogHokejPlayerTableData>(({ mainValues, values, ...restItem }) => ({
        ...restItem,
        value: {
          ...values,
          mainValue: mainValues.percentage,
        },
      }))
      .sort((a, b) => {
        if (b.value.mainValue > a.value.mainValue) return 1;
        if (b.value.mainValue < a.value.mainValue) return -1;

        return b.value.openedNotificationCount - a.value.openedNotificationCount;
      });

    const clipsInPlaylistData = sharedData
      .map<ILogHokejPlayerTableData>(({ mainValues, values, ...restItem }) => ({
        ...restItem,
        value: {
          ...values,
          mainValue: mainValues.clipsInPlaylist,
        },
      }))
      .sort((a, b) => b.value.mainValue - a.value.mainValue);

    const videoCoachNotesData = sharedData
      .map<ILogHokejPlayerTableData>(({ mainValues, values, ...restItem }) => ({
        ...restItem,
        value: {
          ...values,
          mainValue: mainValues.videoCoachNotes,
        },
      }))
      .sort((a, b) => b.value.mainValue - a.value.mainValue);

    return {
      clickthroughRateData,
      clipsInPlaylistData: !searchedPlayer
        ? clipsInPlaylistData.filter(item => item.value.mainValue > 0)
        : clipsInPlaylistData,
      videoCoachNotesData: !searchedPlayer
        ? videoCoachNotesData.filter(item => item.value.mainValue > 0)
        : videoCoachNotesData,
    };
  }, [filteredPlayersByAttendance, data, attendance, searchedPlayer]);

  return {
    dataForTables,
    playersWithAttendanceCount: filteredPlayersByAttendance?.length ?? 0,
  };
};

const getSharedData = (data: ILogHokejPlayer[]) =>
  data.map(
    ({
      registrationNumber,
      name,
      surname,
      team,
      notificationCount,
      openedNotificationCount,
      clipsInPlaylist,
      videoCoachNotes,
    }) => {
      const clickthroughRate = (openedNotificationCount / notificationCount) * 100;

      return {
        player: {
          registrationNumber,
          name,
          surname,
        },
        team: team ?? '',
        mainValues: {
          percentage: !isNaN(clickthroughRate) ? clickthroughRate : 0,
          clipsInPlaylist,
          videoCoachNotes,
        },
        values: {
          notificationCount,
          openedNotificationCount,
        },
      };
    },
  );

const filterPlayersByAttendance = (
  data: ILogHokejPlayer[],
  attendance: ISelectOption | undefined,
) => {
  if (!attendance || !data) return data || [];

  return data.filter(item => {
    if (attendance.value === '0') return !item.openedNotificationCount;
    if (attendance.value === '100') return item.openedNotificationCount === item.notificationCount;

    const clickthroughRate = (item.openedNotificationCount / item.notificationCount) * 100;

    if (isNaN(clickthroughRate)) return false;

    return attendance.value === 'all'
      ? clickthroughRate >= 50
      : clickthroughRate >= Number(attendance.value);
  });
};
