import { FC } from 'react';

import { createClassNames } from '../../../utils';
import { Bar, IBarProps } from '../../Bar';
import './CardItem.styles.scss';

export interface ICardItemProps extends IBarProps {
  /**
   * Label of the item shown above the bar component
   */
  label: string;
  /**
   * Name of the attribute
   */
  name: string;
  /**
   * Rank value displayed at the right side next to the bar
   */
  rank: number;
  /**
   * If true, the hash character (#) is shown next to the rank value.
   * @default false
   */
  showHash?: boolean;
}

const classNames = createClassNames('card-item');

export const CardItem: FC<ICardItemProps> = ({
  label,
  name,
  metricValue,
  percent,
  rank,
  color,
  showCenter,
  reversed,
  showTooltip,
  showHash = false,
}) => {
  return (
    <div className={classNames()} data-testid='card-item'>
      <span className={classNames('header')}>{label}</span>
      <div className={classNames('percent-bar')}>
        <Bar
          name={name}
          metricValue={metricValue}
          percent={percent}
          color={color}
          showCenter={showCenter}
          reversed={reversed}
          showTooltip={showTooltip}
        />
        <div>
          {showHash && <>#</>}
          {rank}
        </div>
      </div>
    </div>
  );
};
