import { useMemo } from 'react';

import { ITranslationKeys } from '../../../../i18n/types';
import {
  INavigationContentKeys,
  IScreenBarChartData,
  IScreenLogTabRecord,
  IScreenTrackingAnalytics,
  ISelectOption,
  ITrackingUserType,
} from '../../../../types';
import { getTranslationKeyForNavigationKey } from '../../../../utils';

// TODO: tohle by mělo být ošetřeno už na API
const OLD_TO_REMOVE: string[] = ['faceOff', 'whoVersusWho'];

export const useScreenChartData = (
  data: IScreenLogTabRecord | undefined,
  selectedAnalytics: ISelectOption,
  selectedScreen: ITranslationKeys | string | undefined,
) => {
  const tabsData = useMemo(() => {
    const analyticsType = selectedAnalytics.value as keyof IScreenTrackingAnalytics;

    if (!data) {
      return [];
    }

    return Object.values(data)
      .map<IScreenBarChartData>(item => {
        const { name, subTabs } = item;

        const existingSubTabs = Object.values(subTabs).filter(subTab =>
          Object.values(INavigationContentKeys).some(key => key === subTab.name),
        );

        const teamTotal = existingSubTabs.reduce((acc, subTab) => {
          const subTabTeamTotal = subTab.aggregation[ITrackingUserType.team].reduce(
            (accAgg, item) => accAgg + item.analytics[analyticsType],
            0,
          );

          return acc + subTabTeamTotal;
        }, 0);

        const mediaTotal = existingSubTabs.reduce((acc, subTab) => {
          const subTabTeamTotal = subTab.aggregation[ITrackingUserType.media].reduce(
            (accAgg, item) => accAgg + item.analytics[analyticsType],
            0,
          );

          return acc + subTabTeamTotal;
        }, 0);

        const otherTotal = existingSubTabs.reduce((acc, subTab) => {
          const subTabTeamTotal = subTab.aggregation[ITrackingUserType.other].reduce(
            (accAgg, item) => accAgg + item.analytics[analyticsType],
            0,
          );

          return acc + subTabTeamTotal;
        }, 0);

        return {
          label: name,
          team: teamTotal,
          media: mediaTotal,
          other: otherTotal,
        };
      })
      .filter(item => !OLD_TO_REMOVE.includes(item.label))
      .sort((a, b) => b.team + b.media + b.other - (a.team + a.media + a.other));
  }, [data, selectedAnalytics.value]);

  const subTabsData = useMemo(() => {
    const analyticsType = selectedAnalytics.value as keyof IScreenTrackingAnalytics;

    if (!data || !selectedScreen) {
      return [];
    }

    const selectedTab = data[selectedScreen];
    const existingSelectedSubTabs = Object.values(selectedTab.subTabs).filter(subTab =>
      Object.values(INavigationContentKeys).some(key => key === subTab.name),
    );

    return existingSelectedSubTabs
      .map<IScreenBarChartData>(subTab => {
        const teamTotal = subTab.aggregation[ITrackingUserType.team].reduce(
          (acc, item) => acc + item.analytics[analyticsType],
          0,
        );

        const mediaTotal = subTab.aggregation[ITrackingUserType.media].reduce(
          (acc, item) => acc + item.analytics[analyticsType],
          0,
        );

        const otherTotal = subTab.aggregation[ITrackingUserType.other].reduce(
          (acc, item) => acc + item.analytics[analyticsType],
          0,
        );

        return {
          label: getTranslationKeyForNavigationKey(subTab.name),
          team: teamTotal,
          media: mediaTotal,
          other: otherTotal,
        };
      })
      .filter(item => item.label !== '')
      .sort((a, b) => b.team + b.media + b.other - (a.team + a.media + a.other));
  }, [data, selectedAnalytics.value, selectedScreen]);

  return {
    tabsData,
    subTabsData,
  };
};
