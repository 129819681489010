import { createColumnHelper } from '@tanstack/react-table';

import {
  ExpandIconButtonColumnCell,
  GameReportColumnCell,
  GameReportColumnHeader,
  HeadCellBase,
  TranslatedColumnHeader,
} from '../../../../components';
import { ITranslationKeys } from '../../../../i18n/types';
import { IGamesReportBlockTableData } from '../../../../types';
import { createClassNames } from '../../../../utils';
import './columns.styles.scss';

const classNames = createClassNames('report-content-columns');

const columnHelper = createColumnHelper<IGamesReportBlockTableData>();

export const getColumns = (blockType: 'reportEvenStrength' | 'reportPowerPlay') => [
  columnHelper.display({
    id: 'expand',
    header: () => (
      <HeadCellBase style={{ width: '98px' }}>
        <TranslatedColumnHeader
          label={ITranslationKeys.topPlayers}
          labelTranslationsOptions={{ count: 5 }}
        />
      </HeadCellBase>
    ),
    cell: props => (
      <div className={classNames('expand-icon-cell', { expanded: props.row.getIsExpanded() })}>
        <ExpandIconButtonColumnCell {...props} />
      </div>
    ),
  }),
  columnHelper.accessor('description', {
    header: '',
    cell: props => (
      <div className={classNames('description-cell', { expanded: props.row.getIsExpanded() })}>
        <div>{props.getValue()}</div>
      </div>
    ),
  }),
  columnHelper.accessor('name', {
    header: '',
    cell: props => (
      <div className={classNames('name-cell', { expanded: props.row.getIsExpanded() })}>
        <div>{props.getValue()}</div>
      </div>
    ),
  }),
  columnHelper.accessor('homeTeamMatchStats', {
    header: () => (
      <GameReportColumnHeader valueLabel={ITranslationKeys.count} percentValueLabel='%' hasLogos />
    ),
    cell: props => (
      <GameReportColumnCell {...props} stats={props.getValue()} blockType={blockType} />
    ),
  }),
  columnHelper.accessor('awayTeamMatchStats', {
    header: () => (
      <GameReportColumnHeader valueLabel={ITranslationKeys.count} percentValueLabel='%' />
    ),
    cell: props => (
      <GameReportColumnCell {...props} stats={props.getValue()} blockType={blockType} />
    ),
  }),
  columnHelper.accessor('homeTeamCompetitionStats', {
    header: () => (
      <GameReportColumnHeader
        valueLabel={ITranslationKeys.average}
        percentValueLabel={ITranslationKeys.inLeague}
        hasLogos
        type='competition'
      />
    ),
    cell: props => (
      <GameReportColumnCell
        {...props}
        stats={props.getValue()}
        isPercentColored
        blockType={blockType}
      />
    ),
  }),
  columnHelper.accessor('awayTeamCompetitionStats', {
    header: () => (
      <GameReportColumnHeader
        valueLabel={ITranslationKeys.average}
        percentValueLabel={ITranslationKeys.inLeague}
        type='competition'
      />
    ),
    cell: props => (
      <GameReportColumnCell
        {...props}
        stats={props.getValue()}
        isPercentColored
        blockType={blockType}
      />
    ),
  }),
];

export type IGamesReportBlockTableColumns = ReturnType<typeof getColumns>;
