import { ButtonHTMLAttributes, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { createClassNames } from '../../../utils';
import './LoginButton.styles.scss';

interface ILoginButtonProps {
  label: ITranslationKeys;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: () => void;
}

const classNames = createClassNames('login-button');

export const LoginButton: FC<ILoginButtonProps> = ({ label, type = 'submit', onClick }) => {
  const { t } = useTranslation();

  return (
    <button
      className={classNames()}
      onClick={onClick}
      type={type}
      data-testid='submit-login-button'
    >
      {t(label)}
    </button>
  );
};
