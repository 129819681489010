import { IBaseRequestBodyWithMetrics, IStats } from './common.types';

export const IWowyType = {
  together: 'together',
  separately: 'separately',
  teammateSeparately: 'teammateSeparately',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IWowyType = (typeof IWowyType)[keyof typeof IWowyType];

export interface IWowyRequestBody extends IBaseRequestBodyWithMetrics {}

export interface IPlayerStats {
  toi: number;
  gp: number;
  stats: IStats;
}

export interface IGenericWowyType<T> {
  together: T;
  separately: T;
  teammateSeparately: T;
}

export interface IWowyPlayer {
  id: string;
  wowyStats: IGenericWowyType<IPlayerStats>;
}

export interface ISelectedPlayerStats extends IPlayerStats {
  id: string;
}

export interface IWowyData {
  selectedPlayer: ISelectedPlayerStats;
  wowyPlayers: IWowyPlayer[];
}

export interface IWowyState {
  wowy?: IWowyData;
  isLoading?: boolean;
  error?: string;
  isLoadedFirstTime?: boolean;
  activeRows: IGenericWowyType<boolean>;
  wowyPercentiles?: IWowyData;
  isWowyPercentilesActive?: boolean;
  isWowyPercentilesLoading?: boolean;
  errorWowyPercentiles?: string;
}

export type IWowyStatsDTO = { type: string } & IStats;

export interface IWowyPlayerDTO {
  uuid: string;
  stats: IWowyStatsDTO[];
}

export type ISelectedPlayerStatsDTO = { uuid: string } & IStats;

export interface IWowyDataDTO {
  selected_player: ISelectedPlayerStatsDTO[];
  stats: IWowyPlayerDTO[];
}
