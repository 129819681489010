import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { logosPaths } from '../../../constants';
import { IHomeAwayTeams, IScore, ITeam } from '../../../types';
import {
  createClassNames,
  formatDateByLanguage,
  getScoreStateShortcut,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import './GameButton.styles.scss';

export interface IGameButtonProps extends IHomeAwayTeams<ITeam> {
  id: string;
  score: IScore;
  date: string;
  isActive?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const classNames = createClassNames('game-button');

export const GameButton: FC<IGameButtonProps> = ({
  homeTeam,
  awayTeam,
  score,
  date,
  isActive,
  disabled,
  onClick,
}) => {
  const { t } = useTranslation();

  const scoreState = getScoreStateShortcut(score.state);

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isActive,
          disabled,
        }),
      })}
      onClick={!disabled ? onClick : undefined}
      data-testid='game-button'
    >
      <div className={classNames('date')}>{formatDateByLanguage(new Date(date), 'P')}</div>
      <div className={classNames('teams')}>
        <img src={logosPaths[homeTeam.id]} alt={homeTeam.shortcut} width={18} />
        {homeTeam.shortcut} - {awayTeam.shortcut}
        <img src={logosPaths[awayTeam.id]} alt={awayTeam.shortcut} width={18} />
      </div>
      <div className={classNames('score')}>
        {score.home}:{score.away}
        {scoreState ? t(scoreState) : null}
      </div>
    </div>
  );
};
