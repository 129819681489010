import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ITranslationKeys } from '../../../i18n/types';
import {
  INavigationContentKeys,
  INavigationPageKeys,
  IPlayerForTable,
  ITableTeamAndPlayer,
  IUrlFilterParams,
} from '../../../types';
import { combineSearchAndFilterParams, normalizeUrlWithFilterParams } from '../../../utils';
import { Sticker } from '../../Sticker';
import './PlayerColumn.styles.scss';

export const PlayerColumnHeader = () => {
  const { t } = useTranslation();
  return <span className='player-column'>{t(ITranslationKeys.player)}</span>;
};

export interface IPlayerColumnRowProps<T extends ITableTeamAndPlayer>
  extends CellContext<T, IPlayerForTable> {
  isGoalkeeper?: boolean;
}

export const PlayerColumnCell = <T extends ITableTeamAndPlayer>({
  row,
  getValue,
  isGoalkeeper = false,
}: IPlayerColumnRowProps<T>) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const player = getValue();

  const handleLoadPlayerCard = () => {
    const filterParams: IUrlFilterParams[] = [
      { name: 'selectedTeam', value: row.original.teamId },
      { name: isGoalkeeper ? 'selectedGoalkeeper' : 'selectedPlayer', value: player.id },
    ];
    const urlParamsToOpen = combineSearchAndFilterParams(searchParams, filterParams);
    const pagesUrl = getPagesUrl(isGoalkeeper);
    const urlToOpen = normalizeUrlWithFilterParams(pagesUrl, urlParamsToOpen);
    window.open(urlToOpen, '_blank');
  };

  return (
    <span className='player-column'>
      <Sticker
        label={player.displayName}
        tooltipContent={
          <div className='player-column__tooltip-content'>{t(ITranslationKeys.playerCard)}</div>
        }
        onClick={handleLoadPlayerCard}
        size='small'
      />
    </span>
  );
};

const getPagesUrl = (isGoalkeeper: boolean) => {
  if (isGoalkeeper) {
    return `/${INavigationPageKeys.goalkeepers}/${INavigationContentKeys.goalkeeperCard}`;
  }

  return `/${INavigationPageKeys.players}/${INavigationContentKeys.playerCard}`;
};
