import type { AppStartListening } from 'app/store';

import { getGoalkeeperCardInfo } from './goalkeepersActions';
import { setCompareGoalkeeperItems } from './goalkeepersSlice';

export const addGoalkeeperListeners = (startListening: AppStartListening) => {
  startListening({
    actionCreator: getGoalkeeperCardInfo.fulfilled,
    effect: async (action, listenerApi) => {
      if (action.meta.arg.isCalledFromCompareGoalkeepers) {
        const { compareGoalkeeperItems } = listenerApi.getState().goalkeepers;
        const loadedGoalkeeperId = action.meta.arg.playerUuid;
        const loadedGoalkeeperTeamId = action.meta.arg.teamUuid;

        const filteredCompareGoalkeeperItems = Object.values(compareGoalkeeperItems).filter(
          item => item.loadingGoalkeeper?.id === loadedGoalkeeperId,
        );

        const compareGoalkeeperItem = filteredCompareGoalkeeperItems.find(
          item => item.loadingGoalkeeper?.teamId === loadedGoalkeeperTeamId,
        );

        if (compareGoalkeeperItem) {
          listenerApi.dispatch(
            setCompareGoalkeeperItems({
              compareGoalkeeperItems: {
                ...compareGoalkeeperItem,
                actualCompareLoadedGoalkeeper: {
                  id: action.payload.goalkeeperId,
                  teamId: loadedGoalkeeperTeamId,
                },
                isLoadingGoalkeeperInfo: false,
              },
              itemKey: compareGoalkeeperItem.id,
            }),
          );
        }
      }
    },
  });
};
