import { FC } from 'react';

import { IPieChartTooltipConfig, IPieChartTooltipProps } from '../../../types';
import {
  createClassNames,
  getH2HMetricValue,
  secondsToMinutesAndSeconds,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import './PieChartTooltip.styles.scss';

const config: IPieChartTooltipConfig = {
  CFaCA: {
    cf: {
      label: 'CF',
    },
    ca: {
      label: 'CA',
    },
    cf_percent: {
      label: 'CF%',
      suffix: '%',
    },
  },
  SCFaSCA: {
    scf: {
      label: 'sCF',
    },
    sca: {
      label: 'sCA',
    },
    scf_percent: {
      label: 'sCF%',
      suffix: '%',
    },
  },
  SOGFaSOGA: {
    sogf: {
      label: 'SOGF',
    },
    soga: {
      label: 'SOGA',
    },
    sogf_percent: {
      label: 'SOGF%',
      suffix: '%',
    },
  },
  XGFaXGA: {
    xgf: {
      label: 'XGF',
    },
    xga: {
      label: 'XGA',
    },
  },
};

const classNames = createClassNames('pie-chart-tooltip');

/**
 * PieChart UI component for HeadToHead component.
 */
export const PieChartTooltip: FC<IPieChartTooltipProps> = ({
  headToHead,
  playerUuid,
  opponentUuid,
  category,
  show = false,
}) => {
  const specificConfig = config[category];
  const toi = getH2HMetricValue(headToHead, playerUuid, opponentUuid, 'toi');

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          show,
        }),
      })}
      data-testid='pie-chart-tooltip'
    >
      {Object.keys(specificConfig).map((metric, index) => (
        <div key={index} className={classNames(index % 2 === 0 ? 'blue' : 'red')}>
          <strong>{specificConfig[metric].label}</strong> ={' '}
          {getH2HMetricValue(headToHead, playerUuid, opponentUuid, metric)}
          {specificConfig[metric].suffix}
        </div>
      ))}
      <div>
        <strong>TOI</strong> = {toi && secondsToMinutesAndSeconds(toi)}
      </div>
    </div>
  );
};
