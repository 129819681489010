import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  DEFAULT_MAIN_FILTER_FORM_STANDARD_VALUES,
  INITIAL_SELECTED_PLAYER_ITEMS,
  countOfPlayersOptions,
  formationTypeOptionsWithAllSituations,
  placeOptions,
  scoreStateOptions,
  teamAllOption,
} from '../constants';
import {
  resetDataSettingsFilterState,
  resetMainFilter,
  resetTableFilterState,
  resetVideomapsFilter,
  selectCompetitionsDetail,
  selectGoalkeepers,
  selectNavigation,
  selectPlayers,
  selectSeasons,
  selectTeams,
} from '../features';
import { IMainFilterForm, IVideomapsFilterState } from '../types';
import {
  filterParts,
  filterTeams,
  getLastSeason,
  getPartOption,
  getSeasonOption,
  parseUrlDateRange,
  parseUrlNumberValue,
  parseUrlOpponentsAtGamesPage,
  parseUrlSelectedGoalkeeper,
  parseUrlSelectedOpponents,
  parseUrlSelectedParts,
  parseUrlSelectedPlayer,
  parseUrlSelectedPlayerItems,
  parseUrlSelectedSeasons,
  parseUrlSelectedTeam,
  parseUrlStaticSelectOption,
} from '../utils';
import { useLocationPaths } from './useLocationPaths';

const useUrlParamsData = () => {
  const seasons = useAppSelector(selectSeasons);
  const competitionsDetail = useAppSelector(selectCompetitionsDetail);
  const teams = useAppSelector(selectTeams);
  const players = useAppSelector(selectPlayers);
  const goalkeepers = useAppSelector(selectGoalkeepers);
  const [searchParams] = useSearchParams();

  const getUrlParamsData = (defaultData: IMainFilterForm) => {
    const selectedSeasonsParams = parseUrlSelectedSeasons(searchParams, seasons.byId);
    const selectedSeasons =
      selectedSeasonsParams.length > 0 ? selectedSeasonsParams : defaultData.selectedSeasons;

    const selectedPartsParams = parseUrlSelectedParts(searchParams, competitionsDetail.byId);
    const selectedParts =
      selectedPartsParams.length > 0 ? selectedPartsParams : defaultData.selectedParts;

    const filteredParts = filterParts(
      competitionsDetail.byId,
      selectedSeasons.map(season => season.value),
      selectedParts.map(part => part.value),
    );
    const filteredTeams = filterTeams(teams.byId, filteredParts);

    const selectedTeamParams = parseUrlSelectedTeam(searchParams, filteredTeams);
    const selectedTeam = selectedTeamParams || defaultData.selectedTeam;

    const selectedPlayer = parseUrlSelectedPlayer(
      searchParams,
      players.byId,
      filteredParts,
      selectedTeam?.value,
    );
    const selectedGoalkeeper = parseUrlSelectedGoalkeeper(
      searchParams,
      goalkeepers.byId,
      filteredParts,
      selectedTeam?.value,
    );
    const selectedOpponents = parseUrlSelectedOpponents(
      searchParams,
      filteredTeams,
      selectedTeam?.value,
    );
    const opponentsAtGamesPage = parseUrlOpponentsAtGamesPage(
      searchParams,
      filteredTeams,
      selectedTeam?.value,
    );

    const countOfPlayers = parseUrlStaticSelectOption(
      searchParams,
      'countOfPlayers',
      countOfPlayersOptions,
    );
    const place = parseUrlStaticSelectOption(searchParams, 'place', placeOptions);
    const scoreState = parseUrlStaticSelectOption(searchParams, 'scoreState', scoreStateOptions);
    const formationType = parseUrlStaticSelectOption(
      searchParams,
      'formationType',
      formationTypeOptionsWithAllSituations,
    );

    const lastPlayedMatches = parseUrlNumberValue(searchParams, 'lastPlayedMatches');
    const gameTimeFrom = parseUrlNumberValue(searchParams, 'gameTimeFrom');
    const gameTimeTo = parseUrlNumberValue(searchParams, 'gameTimeTo');
    const timeOnIce = parseUrlNumberValue(searchParams, 'timeOnIce');
    const dateFromTo = parseUrlDateRange(searchParams, 'dateFromTo');

    const mainFilter: IMainFilterForm = {
      selectedSeasons,
      selectedParts,
      selectedTeam,
      opponentsAtGamesPage: opponentsAtGamesPage || defaultData.opponentsAtGamesPage,
      selectedPlayer: selectedPlayer || defaultData.selectedPlayer,
      selectedGoalkeeper: selectedGoalkeeper || defaultData.selectedGoalkeeper,
      selectedOpponents: selectedOpponents || defaultData.selectedOpponents,
      countOfPlayers: countOfPlayers || defaultData.countOfPlayers,
      lastPlayedMatches: lastPlayedMatches || defaultData.lastPlayedMatches,
      dateFromTo: dateFromTo || defaultData.dateFromTo,
      place: place || defaultData.place,
      scoreState: scoreState || defaultData.scoreState,
      gameTimeFrom: gameTimeFrom || defaultData.gameTimeFrom,
      gameTimeTo: gameTimeTo || defaultData.gameTimeTo,
      timeOnIce: timeOnIce || defaultData.timeOnIce,
      selectedGame: defaultData.selectedGame,
      formationType: formationType || defaultData.formationType,
    };

    const selectedPlayerItems = parseUrlSelectedPlayerItems(
      searchParams,
      players.byId,
      filteredParts,
      selectedTeam?.value,
    );

    const videomapsFilter: Partial<IVideomapsFilterState> = {
      selectedPlayerItems: selectedPlayerItems || INITIAL_SELECTED_PLAYER_ITEMS,
    };

    return {
      mainFilter,
      videomapsFilter,
    };
  };

  return getUrlParamsData;
};

/**
 * Hook that resets filter forms when the page is reloaded or changed. It resets hook form and redux state.
 * @param methods Main filter's react hook form methods
 */
export const useResetFormsOnPageReloadOrChange = (methods: UseFormReturn<IMainFilterForm>) => {
  const seasons = useAppSelector(selectSeasons);
  const competitionsDetail = useAppSelector(selectCompetitionsDetail);
  const { isLoadingAllInitialData } = useAppSelector(selectNavigation);
  const dispatch = useAppDispatch();

  const getUrlParamsData = useUrlParamsData();
  const { activePage } = useLocationPaths();

  useEffect(() => {
    if (competitionsDetail.isAllCompetitionsDetailLoaded && !isLoadingAllInitialData) {
      const lastSeason = getLastSeason(seasons.byId);
      const lastSeasonOption = getSeasonOption(lastSeason);
      const firstCompetitionDetail =
        Object.values(competitionsDetail.byId).find(competition => competition.part === 'base') ||
        competitionsDetail.byId[competitionsDetail.allIds[0]];
      const firstPartOption = getPartOption(firstCompetitionDetail);

      const defaultData: IMainFilterForm = {
        selectedSeasons: [lastSeasonOption],
        selectedParts: [firstPartOption],
        selectedTeam: teamAllOption,
        opponentsAtGamesPage: teamAllOption,
        ...DEFAULT_MAIN_FILTER_FORM_STANDARD_VALUES,
      };

      const { mainFilter, videomapsFilter } = getUrlParamsData(defaultData);

      dispatch(resetMainFilter(mainFilter));
      methods.reset(mainFilter);

      dispatch(resetVideomapsFilter(videomapsFilter));
      dispatch(resetTableFilterState());
      dispatch(resetDataSettingsFilterState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, isLoadingAllInitialData]);
};
