import { FC, useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
} from 'recharts';

import {
  IAccessAndScreenCounts,
  IAccessLogAggregationEntity,
  IAnalyticsType,
} from '../../../types';
import { createClassNames } from '../../../utils';
import { LogBarChartTooltip } from '../LogBarChartTooltip';
import { getChartGradientDef } from '../utils';
import { BarRectangle } from './BarRectangle';
import { BarTick } from './BarTick';
import './LogBarChart.styles.scss';

interface IAccessLogAggregationEntityWithTickInfo extends IAccessLogAggregationEntity {
  xAxisTickValue: string;
}

export interface ILogBarChartProps {
  /** Data to display. */
  data: IAccessLogAggregationEntity[];
  /** Analytics type. */
  analyticsType: IAnalyticsType;
  /** Selected analytics type total value of all displayed data. */
  total: number;
  /** Heigh of component. */
  height?: string | number;
}

const classNames = createClassNames('log-bar-chart');

/** Log bar chart component. */
export const LogBarChart: FC<ILogBarChartProps> = ({
  data,
  analyticsType,
  total,
  height = 352,
}) => {
  const chartData = useMemo(
    () =>
      data
        .sort((a, b) => b.analytics[analyticsType] - a.analytics[analyticsType])
        .map<IAccessLogAggregationEntityWithTickInfo>(item => {
          const { id, type, analytics } = item;

          return {
            ...item,
            xAxisTickValue: `${id};${type};${
              analytics[analyticsType as keyof IAccessAndScreenCounts]
            }`,
          };
        }),
    [data, analyticsType],
  );

  return (
    <ResponsiveContainer width='100%' height={height} className={classNames()}>
      <BarChart
        data={chartData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 30,
        }}
      >
        {getChartGradientDef()}
        <CartesianGrid vertical={false} horizontal={false} fill='url(#chart-gradient)' />
        <XAxis
          dataKey='xAxisTickValue'
          tickLine={false}
          tick={props => <BarTick {...props} />}
          interval={0}
          axisLine={false}
          padding={{ left: 8, right: 8 }}
        />
        <Tooltip
          cursor={{ fill: 'none' }}
          content={(props: TooltipProps<number, string>) => (
            <LogBarChartTooltip tooltipProps={props} analyticsType={analyticsType} total={total} />
          )}
        />
        <Bar
          dataKey={`analytics.${analyticsType}`}
          barSize={12}
          shape={(props: any) => <BarRectangle {...{ props }} />}
          activeBar={(props: any) => <BarRectangle {...{ props }} isActive />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
