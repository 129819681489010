import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IDateRangeString, ISelectOption } from '../../../types';
import {
  createClassNames,
  formatDateByLanguage,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import { ChartLegend } from '../ChartLegend';
import './ChartSummary.styles.scss';

export interface IChartSummaryProps {
  header: number | string;
  dateFromTo: IDateRangeString | undefined;
  selectedAnalytics: ISelectOption;
  disableLegend?: boolean;
  isScreenLayout?: boolean;
  dateFormatter?: (dateString: string) => string;
}

const classNames = createClassNames('chart-summary');

export const ChartSummary: FC<IChartSummaryProps> = ({
  header,
  dateFromTo,
  selectedAnalytics,
  disableLegend = false,
  isScreenLayout = false,
  dateFormatter = defaultDateFormatter,
}) => {
  const { t } = useTranslation();

  const dateFrom = dateFromTo?.from ? dateFormatter(dateFromTo.from) : '';
  const dateTo = dateFromTo?.to ? dateFormatter(dateFromTo.to) : '';
  const dateToDisplay = [dateFrom, dateTo].filter(date => !!date).join(' - ');

  return (
    <div className={classNames()}>
      <div
        className={classNames('header', {
          ...transformObjectKeysToKebabCase({
            isScreenLayout,
          }),
        })}
      >
        {header}
      </div>
      <div
        className={classNames('analytics', {
          ...transformObjectKeysToKebabCase({
            isScreenLayout,
          }),
        })}
      >
        <div>{`${t(selectedAnalytics.label)} ${dateToDisplay}`}</div>
        {!disableLegend && <ChartLegend />}
      </div>
    </div>
  );
};

const defaultDateFormatter = (dateString: string) =>
  formatDateByLanguage(new Date(dateString), 'P');
