import {
  CSSProperties,
  ChangeEventHandler,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ClassNames, DateRange, DayPicker } from 'react-day-picker';
import styles from 'react-day-picker/dist/style.module.css';
import { useTranslation } from 'react-i18next';

import { useClickOutsideTwoRefs } from '../../hooks';
import { ITranslationKeys } from '../../i18n/types';
import { IMultiParamsVoid } from '../../types';
import {
  createClassNames,
  getDefaultMonths,
  getLocaleByLanguage,
  getStringValueFromDateRange,
  transformObjectKeysToKebabCase,
  validateDateRangeAndSetRange,
} from '../../utils';
import { Button } from '../Button';
import { Input } from '../Input';
import { IInputSharedProps } from '../types';
import './DateRangePicker.styles.scss';

export interface IDateRangePickerProps extends IInputSharedProps {
  /** Date range value */
  value: DateRange | undefined;
  /** Default it displays two months. You can disable second month. */
  disableSecondMonth?: boolean;
  /** Default it displays arrow that points to its input field. You can disable the arrow. */
  disableArrowPointer?: boolean;
  /** Align pop-up to left or right. */
  popUpAlignSelf?: CSSProperties['alignSelf'];
  /** Function called when date range is picked. */
  onChange: IMultiParamsVoid;
  /** Renders extra control element in picker (e.g. button). */
  renderExtraControlElement?: (setOpen: Dispatch<SetStateAction<boolean>>) => ReactNode;
}

const classNames = createClassNames('date-range-picker');

const dayPickerClasses: ClassNames = {
  ...styles,
  day_selected: `${styles.day_selected} ${classNames('pop-up__pickers-box--selected')}`,
};

const locale = getLocaleByLanguage();

/**
 * DateRangePicker input component for select date range.
 */
export const DateRangePicker: FC<IDateRangePickerProps> = ({
  value,
  disableSecondMonth = false,
  disableArrowPointer = false,
  variant = 'filter',
  iconComponent,
  popUpAlignSelf = 'flex-start',
  renderExtraControlElement,
  onChange,
}) => {
  const [range, setRange] = useState<DateRange>();
  const [inputValue, setInputValue] = useState<string>('');
  const [inputOnFocusValue, setInputOnFocusValue] = useState<string>('');
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const { ref, refDontClose } = useClickOutsideTwoRefs<HTMLInputElement, HTMLDivElement>(() => {
    setOpen(false);
  }, [setOpen]);

  const memoizedDefaultMonths = useMemo(() => getDefaultMonths(value), [value]);

  const memoizedInputValue = useMemo(() => getStringValueFromDateRange(value), [value]);

  useEffect(() => {
    setRange(value);
    setInputValue(memoizedInputValue);
  }, [value, open, memoizedInputValue]);

  const handleOnChange = (dateRange: DateRange | undefined) => {
    setRange(dateRange);
  };

  const handleConfirm = () => {
    onChange(range);
    setOpen(false);
    if (range) {
      setInputValue(getStringValueFromDateRange(range));
    } else {
      setInputValue('');
    }
  };

  const handleDelete = () => {
    setRange(undefined);
    onChange(undefined);
    setInputValue('');
    setOpen(false);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    setInputValue(event.currentTarget.value);
    validateDateRangeAndSetRange(event.currentTarget.value, setRange);
  };

  const handleInputFocus = () => {
    setInputOnFocusValue(inputValue);
  };

  const handleInputBlur = () => {
    if (inputOnFocusValue !== inputValue) {
      onChange(range);
    }
  };

  return (
    <div className={classNames()} data-testid={'date-range-picker'}>
      <Input
        ref={ref}
        variant={variant}
        placeholder={t(ITranslationKeys.dateFromTo) ?? ''}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        onClick={() => setOpen(true)}
        data-testid={'date-range-picker-input'}
        iconComponent={iconComponent}
      />
      {open && (
        <>
          {!disableArrowPointer ? <span className={classNames('arrow-pointer')} /> : null}
          <div
            ref={refDontClose}
            className={classNames('pop-up', {
              ...transformObjectKeysToKebabCase({
                disableArrowPointer,
              }),
            })}
            data-testid={'date-range-picker-pop-up'}
            style={{ alignSelf: popUpAlignSelf }}
          >
            <div
              className={classNames('pop-up__pickers-box')}
              data-testid={'date-range-picker-pop-up-pickers-box'}
            >
              <DayPicker
                showOutsideDays
                fixedWeeks
                classNames={dayPickerClasses}
                id='dayPicker'
                mode='range'
                locale={locale}
                defaultMonth={memoizedDefaultMonths.firstMonth}
                selected={range}
                onSelect={handleOnChange}
              />
              {!disableSecondMonth ? (
                <DayPicker
                  showOutsideDays
                  fixedWeeks
                  classNames={dayPickerClasses}
                  id='dayPicker'
                  mode='range'
                  locale={locale}
                  defaultMonth={memoizedDefaultMonths.secondMonth}
                  selected={range}
                  onSelect={handleOnChange}
                />
              ) : null}
            </div>
            <div
              className={classNames('pop-up__controls', {
                ...transformObjectKeysToKebabCase({
                  hasExtraElement: !!renderExtraControlElement,
                }),
              })}
            >
              {renderExtraControlElement?.(setOpen)}
              <div className={classNames('pop-up__controls__buttons')}>
                <Button
                  variant='red'
                  label={ITranslationKeys.delete}
                  onClick={handleDelete}
                  data-testid={'date-range-picker-delete-button'}
                />
                <Button
                  label={ITranslationKeys.confirm}
                  onClick={handleConfirm}
                  data-testid={'date-range-picker-confirm-button'}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
