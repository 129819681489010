import { FC, ReactNode } from 'react';

import { ShootoutPlaygroundIcon } from '../../../icons';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './GoalNetShootout.styles.scss';

export interface IGoalNetShootoutProps {
  /**
   * Content of the GoalNet component.
   */
  content?: ReactNode;
}

const classNames = createClassNames('goal-net-shootout');

export const GoalNetShootout: FC<IGoalNetShootoutProps> = ({ content }) => {
  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({}),
      })}
      data-testid='goal-net-shootout'
    >
      <div className={classNames('icon')}>
        <ShootoutPlaygroundIcon />
      </div>
      {content && <div className={classNames('content')}>{content}</div>}
    </div>
  );
};
