import { ITranslationKeys } from '../i18n/types';
import {
  IBaseRequestBody,
  IDefaultPlayerColorNames,
  ILeftRight,
  IScore,
  IVideoIdAndTime,
  IVideoInfo,
} from './common.types';

export interface IFaceoffComponentsParams {
  /** All players' faceoffs info divided by faceoff position. */
  faceoffPlayerInfoByPositionRecord: IFaceoffPlayerInfoByPositionRecord;
  /**
   * Callback function fired after the user clicks on the box.
   * @param faceoffs Faceoffs to be displayed in the video center.
   */
  onBoxClick: (faceoffs: IFaceoff[]) => void;
}

export const IFaceoffPositionKeys = {
  center: 'center',
  defenseDefenseLeft: 'defenseDefenseLeft',
  defenseDefenseRight: 'defenseDefenseRight',
  defenseNeutralLeft: 'defenseNeutralLeft',
  defenseNeutralRight: 'defenseNeutralRight',
  offenseDefenseLeft: 'offenseDefenseLeft',
  offenseDefenseRight: 'offenseDefenseRight',
  offenseNeutralLeft: 'offenseNeutralLeft',
  offenseNeutralRight: 'offenseNeutralRight',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IFaceoffPositionKeys = (typeof IFaceoffPositionKeys)[keyof typeof IFaceoffPositionKeys];

export interface IFaceoff extends Required<IVideoInfo> {
  finish: string;
  finishTeam: string | null;
  gameState: string;
  gameType: string;
  hasGoalie: boolean;
  onPlayersHalf: boolean;
  opponentId: string;
  won: boolean;
  xG: number | null;
  spot: string;
  zone: string;
  positionId: IFaceoffPositionKeys;
}

export interface IFaceoffsWithAttributes {
  faceoffs: IFaceoff[];
  attributes: IPlaygroundFaceOffAttributes;
}
export interface IFaceoffPosition extends IFaceoffsWithAttributes {
  positionId: IFaceoffPositionKeys;
}
export type IFaceoffPositionRecord = Record<IFaceoffPositionKeys, IFaceoffPosition>;

export interface IFaceoffPlayerInfoByPosition {
  playerId: string;
  faceoffPositions: IFaceoffPositionRecord;
}
export type IFaceoffPlayerInfoByPositionRecord = Record<string, IFaceoffPlayerInfoByPosition>;

export interface IFaceoffPlayerInfo {
  playerId: string;
  faceoffs: IFaceoff[];
}
export type IFaceoffPlayerInfoRecord = Record<string, IFaceoffPlayerInfo>;

export interface IFaceoffState {
  byId: IFaceoffPlayerInfoRecord;
  allIds: string[];
  isLoading?: boolean;
  error?: string;
}

export interface IFaceoffRequestBody extends IBaseRequestBody {
  stick?: ILeftRight | null;
}

export type IFaceoffAllSides = 'all' | ILeftRight;

export interface IPlaygroundSideInfoBox {
  label: ITranslationKeys;
  side: IFaceoffAllSides;
}

export type IPlaygroundFaceOffAttributes = {
  /**
   * Count metric.
   */
  ratio: string;
  /**
   * xG metric.
   */
  percentile: string;
  /**
   * Color of the box.
   */
  color: IDefaultPlayerColorNames;
};

export interface IFaceoffStatsDTO extends IVideoIdAndTime {
  matchDate: string;
  score: IScore;
  realTime: string;
  awayTeam: string;
  homeTeam: string;
  finish: string;
  finishTeam: string;
  gameState: string;
  gameType: string;
  hasGoalie: boolean;
  matchUuid: string;
  onPlayersHalf: boolean;
  opponentUuid: string;
  playerUuid: string;
  time: number;
  won: boolean;
  xG: number;
  spot: string;
  zone: string;
}

export interface IFaceoffPositionDTO {
  count: number;
  won: number;
  loss: number;
  faceOffs: IFaceoffStatsDTO[];
}

export interface IFaceoffDTO {
  center: IFaceoffPositionDTO;
  defense: IFaceoffPositionDTO;
  offense: IFaceoffPositionDTO;
  defenseDefenseLeft: IFaceoffPositionDTO;
  defenseDefenseRight: IFaceoffPositionDTO;
  defenseNeutralLeft: IFaceoffPositionDTO;
  defenseNeutralRight: IFaceoffPositionDTO;
  offenseDefenseLeft: IFaceoffPositionDTO;
  offenseDefenseRight: IFaceoffPositionDTO;
  offenseNeutralLeft: IFaceoffPositionDTO;
  offenseNeutralRight: IFaceoffPositionDTO;
}
