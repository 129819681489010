import { useAppSelector } from '../../../../app/hooks';
import { selectGames, selectPlayers, selectTeams } from '../../../../features';
import {
  IBasicShotsStats,
  IDangerShotsStats,
  IGameExpectedGoals,
  IGameGraphicOverview,
  IGameGraphicOverviewConfigs,
  IGameResult,
  IGameShots,
  IGameTeamInfo,
  IGameTimelineBase,
  IGraphicOverviewTeamInfo,
  IPlayerExpectedGoals,
  IShotFlowGraphConfig,
  IShotFlowGraphConfigs,
  IShotsStats,
  ITeamExpectedGoals,
} from '../../../../types';
import {
  convertPlayersToShotFlowGraphItems,
  getChartSeriesFromShots,
  roundNumberTo2Decimals,
} from '../../../../utils';

interface IUseGetGameOverview {
  getGraphicOverviewConfigs: (graphicOverview: IGameGraphicOverview) => IGameGraphicOverviewConfigs;
}
/**
 * Custom hook to render graphic overview data.
 * @returns IUseGetGameOverview object with getGraphicOverview method to get graphic overview data.
 */
export const useGetGraphicOverview = (): IUseGetGameOverview => {
  const { byId } = useAppSelector(selectTeams);
  const players = useAppSelector(selectPlayers);
  const playerSelector = useAppSelector(selectPlayers);
  const { games } = useAppSelector(selectGames);

  /**
   * Transforms graphicOverview data for GameResult component.
   * @param graphicOverview object with graphic overview data.
   * @returns IGameResultProps object for the GameResult component.
   */
  const getGameResultConfig = (graphicOverview: IGameGraphicOverview): IGameResult => {
    const homeInfo = byId[graphicOverview.homeTeam.teamId];
    const awayInfo = byId[graphicOverview.awayTeam.teamId];
    const game = games.byId[graphicOverview.matchId];

    const home: IGameTeamInfo = {
      name: homeInfo.name,
      uuid: homeInfo.id,
      goals: game?.score.home,
      thirds: graphicOverview.homeTeam.scorePeriods,
    };
    const away: IGameTeamInfo = {
      name: awayInfo.name,
      uuid: awayInfo.id,
      goals: game?.score.away,
      thirds: graphicOverview.awayTeam.scorePeriods,
    };

    return {
      home: home,
      away: away,
      scoreState: game?.score.state,
    };
  };

  /**
   * Transforms 5 top players with team expected goals metric into ITeamExpectedGoals object.
   * @param team Home or away team of the graphicOverview object.
   * @returns ITeamExpectedGoals object.
   */
  const getTeamExpectedGoals = (team: IGraphicOverviewTeamInfo): ITeamExpectedGoals => {
    const players: IPlayerExpectedGoals[] = team.players.slice(0, 5).map(player => {
      const playerInfo = playerSelector.byId[player.uuid];
      return { firstName: playerInfo.name, lastName: playerInfo.surname, value: player.xG };
    });
    const xG = team.players.reduce((acc, player) => acc + player.xG, 0);

    return {
      players: players,
      xG: roundNumberTo2Decimals(xG),
    };
  };

  /**
   * Transforms graphicOverview data for GameExpectedGoals component.
   * @param graphicOverview object with graphic overview data.
   * @returns IGameExpectedGoalsProps object for the component.
   */
  const getGameExpectedGoalsConfig = (
    graphicOverview: IGameGraphicOverview,
  ): IGameExpectedGoals => ({
    home: getTeamExpectedGoals(graphicOverview.homeTeam),
    away: getTeamExpectedGoals(graphicOverview.awayTeam),
  });

  /**
   * Transforms stats into IShotsStats object.
   * @param stats hd | md | ld
   * @returns IShotsStats object.
   */
  const getGameBasicShotsStats = (stats: IBasicShotsStats): IShotsStats => ({
    count: stats.c,
    g: stats.g,
    xG: roundNumberTo2Decimals(stats.xG),
  });

  /**
   * Transforms graphicOverview data for GameShots component.
   * @param graphicOverview object with graphic overview data.
   * @returns IGameShotsProps object containing both teams for the GameShots component.
   */
  const getGameShotsConfig = (graphicOverview: IGameGraphicOverview): IGameShots => {
    const home: IDangerShotsStats = {
      high: getGameBasicShotsStats(graphicOverview.homeTeam.shotsInfo.hd),
      medium: getGameBasicShotsStats(graphicOverview.homeTeam.shotsInfo.md),
      low: getGameBasicShotsStats(graphicOverview.homeTeam.shotsInfo.ld),
    };
    const away: IDangerShotsStats = {
      high: getGameBasicShotsStats(graphicOverview.awayTeam.shotsInfo.hd),
      medium: getGameBasicShotsStats(graphicOverview.awayTeam.shotsInfo.md),
      low: getGameBasicShotsStats(graphicOverview.awayTeam.shotsInfo.ld),
    };

    return { home, away };
  };

  /**
   * Transforms graphicOverview data for shotsFlowGraph properties.
   * @param graphicOverview object with graphic overview data.
   * @returns IGameShotsProps object containing both teams for the GameShots component.
   */
  const getGameShotsFlowGraphConfigs = (
    graphicOverview: IGameGraphicOverview,
  ): IShotFlowGraphConfigs => {
    const shotFlowGraphTeamItems = convertPlayersToShotFlowGraphItems(
      graphicOverview.homeTeam.players,
      graphicOverview.awayTeam.players,
      players.byId,
    );

    const match = games.byId[graphicOverview.matchId];
    const homeTeam = byId[match.homeTeamId];
    const awayTeam = byId[match.awayTeamId];

    const home: IShotFlowGraphConfig = {
      items: shotFlowGraphTeamItems.home,
      teamShortcut: homeTeam.shortcut,
      max: shotFlowGraphTeamItems.max,
      min: shotFlowGraphTeamItems.min,
    };
    const away: IShotFlowGraphConfig = {
      items: shotFlowGraphTeamItems.away,
      teamShortcut: awayTeam.shortcut,
      max: shotFlowGraphTeamItems.max,
      min: shotFlowGraphTeamItems.min,
    };

    return { home, away };
  };

  /**
   * Prepare data for chart series component.
   * @param graphicOverview Object with graphic overview data.
   * @returns Data for chart series component.
   */
  const getGameCourseExpectedGoalsConfig = (
    graphicOverview: IGameGraphicOverview,
  ): IGameTimelineBase => {
    const { homeTeam, awayTeam } = graphicOverview;

    return getChartSeriesFromShots({
      homeTeam: {
        shots: homeTeam.shots,
        teamId: homeTeam.teamId,
      },
      awayTeam: {
        shots: awayTeam.shots,
        teamId: awayTeam.teamId,
      },
    });
  };

  const getGraphicOverviewConfigs = (
    graphicOverview: IGameGraphicOverview,
  ): IGameGraphicOverviewConfigs => ({
    gameResult: getGameResultConfig(graphicOverview),
    gameExpectedGoals: getGameExpectedGoalsConfig(graphicOverview),
    gameShots: getGameShotsConfig(graphicOverview),
    gameShotsFlowGraph: getGameShotsFlowGraphConfigs(graphicOverview),
    gameCourseExpectedGoals: getGameCourseExpectedGoalsConfig(graphicOverview),
  });

  return {
    getGraphicOverviewConfigs,
  };
};
