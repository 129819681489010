import { FC, ReactNode, useState } from 'react';

import playgroundImagePath from '../../../assets/playground/playground.png';
import { ITranslationKeys } from '../../../i18n/types';
import { IBasicShotsStats } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { IPlaygroundBarBoxes } from '../../types';
import { PlaygroundBar } from '../PlaygroundBar';
import './Playground.styles.scss';

export interface IPlaygroundProps extends IBasicShotsStats {
  /** Time on ice. */
  toi: number;
  /** Heading for the PlaygroundBoxList component. */
  boxListHeading: ITranslationKeys;
  /** Boxes for playground bar. */
  boxes: IPlaygroundBarBoxes;
  /** When true, the playground is small (Used in the bottom of the ShotsMap page). */
  isSmall?: boolean;
  /** When true, the playground is reversed (Used in the ZoneExit). */
  isReversed?: boolean;
  /** When true, the playground is responsive. */
  isResponsive?: boolean;
  /** Is component displaying goalkeeper data? */
  isGoalkeeper?: boolean;
  /** Content to be rendered in the playground. */
  renderContent: (config?: { showBoxes?: boolean; isSmall?: boolean }) => ReactNode;
  /** Callback fired when the print icon is clicked. */
  onPrintIconClick?: () => void;
  /** Fired when user clicks on the attributes in boxes. */
  onBoxesClick?: () => void;
  /** Fired when boxes change state (visible / hidden state). */
  onBoxesAppearanceChange?: (showBoxes: boolean) => void;
}

const classNames = createClassNames('playground');

export const Playground: FC<IPlaygroundProps> = ({
  toi,
  c,
  xG,
  g,
  boxListHeading,
  boxes,
  isSmall = false,
  isReversed = false,
  isResponsive = false,
  isGoalkeeper = false,
  renderContent,
  onPrintIconClick,
  onBoxesClick,
  onBoxesAppearanceChange,
}) => {
  const [showBoxes, setShowBoxes] = useState(false);

  const onShowBoxesIconClickHandler = () => {
    const newAppearance = !showBoxes;
    setShowBoxes(newAppearance);
    onBoxesAppearanceChange?.(newAppearance);
  };

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isReversed,
          isSmall,
          isResponsive,
        }),
      })}
      data-testid='playground'
    >
      <img src={playgroundImagePath} className={classNames('image')} alt='playground' />
      <PlaygroundBar
        {...{
          toi,
          c,
          xG,
          g,
          boxListHeading,
          boxes,
          isReversed,
          isSmall,
          showBoxes,
          onPrintIconClick,
          onBoxesClick,
          isResponsive,
          isGoalkeeper,
        }}
        onShowBoxesIconClick={onShowBoxesIconClickHandler}
        onPrintIconClick={onPrintIconClick}
      />
      <div className={classNames('canvas')}>{renderContent({ showBoxes, isSmall })}</div>
    </div>
  );
};
