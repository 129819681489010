import { useAppSelector } from '../../../app/hooks';
import {
  CountOfPlayersController,
  PartsController,
  PlayerController,
  SeasonsController,
  TeamController,
  TimeOnIceController,
} from '../../../components';
import { REQUIRED_PLAYER } from '../../../constants';
import { selectMainFilter } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import {
  CompareIcon,
  FindSimilarPlayerIcon,
  GamelogIcon,
  PlayerCardIcon,
  TabsIcon,
  TrendIcon,
} from '../../../icons';
import { INavigationContentKeys, IPageConfig } from '../../../types';
import { getNotSelectedTeamValidationObject } from '../../../utils';
import { ComparePlayersContent } from './ComparePlayersContent';
import { GamelogContent } from './GamelogContent';
import { PlayerCardContent } from './PlayerCardContent';
import { SimilarityPlayersContent } from './SimilarityPlayersContent';
import { TabsContent } from './TabsContent';
import { TrendContent } from './TrendContent';

/** Custom hook returns config for IndividualStatsPage tabs and widgets. */
export const usePageConfig = (): IPageConfig => {
  const { selectedPlayer, selectedTeam } = useAppSelector(selectMainFilter);

  return {
    baseFilter: (
      <>
        <SeasonsController required />
        <PartsController required />
        <TeamController />
        <CountOfPlayersController />
      </>
    ),
    tabsConfig: [
      {
        id: INavigationContentKeys.tabs,
        name: ITranslationKeys.tabs,
        icon: <TabsIcon />,
        description: ITranslationKeys.tabsPlayersDescription,
        ContentComponent: TabsContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController />
            <CountOfPlayersController />
            <TimeOnIceController />
          </>
        ),
      },
      {
        id: INavigationContentKeys.gamelog,
        name: ITranslationKeys.gamelog,
        icon: <GamelogIcon />,
        description: ITranslationKeys.gamelogPlayersDescription,
        ContentComponent: GamelogContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <CountOfPlayersController />
            <TimeOnIceController />
          </>
        ),
        disableLoadDataButton: !selectedTeam,
      },
      {
        id: INavigationContentKeys.trend,
        name: ITranslationKeys.trend,
        icon: <TrendIcon />,
        description: ITranslationKeys.trendPlayersDescription,
        ContentComponent: TrendContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController
              rules={{
                validate: getNotSelectedTeamValidationObject(ITranslationKeys.pleaseSelectTeam),
              }}
              required
            />
            <CountOfPlayersController />
            <TimeOnIceController />
          </>
        ),
      },
      {
        id: INavigationContentKeys.playerCard,
        name: ITranslationKeys.playerCard,
        icon: <PlayerCardIcon />,
        description: ITranslationKeys.playerCardDescription,
        ContentComponent: PlayerCardContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <PlayerController rules={{ required: REQUIRED_PLAYER }} required />
          </>
        ),
        additionalInput: <PlayerController />,
        disableExtendedFilter: true,
        disableLoadDataButton: !selectedPlayer,
      },
      {
        id: INavigationContentKeys.comparePlayers,
        name: ITranslationKeys.comparePlayers,
        icon: <CompareIcon />,
        description: ITranslationKeys.comparePlayersDescription,
        ContentComponent: ComparePlayersContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
          </>
        ),
        disableExtendedFilter: true,
      },
      {
        id: INavigationContentKeys.similarityPlayers,
        name: ITranslationKeys.similarityPlayers,
        icon: <FindSimilarPlayerIcon />,
        description: ITranslationKeys.similarityPlayersDescription,
        ContentComponent: SimilarityPlayersContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController />
            <PlayerController rules={{ required: REQUIRED_PLAYER }} required />
            <CountOfPlayersController />
            <TimeOnIceController />
          </>
        ),
        additionalInput: <PlayerController />,
        disableLoadDataButton: !selectedPlayer,
      },
    ],
  };
};
