import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IFaceoffPlayerInfo, IFaceoffRequestBody } from '../../types';
import { defaultCatchErrorCallback } from '../../utils';
import { parseFaceoffsData } from './parseFaceoffs';

interface IGetFaceoffsParams {
  competitionsUuids: string[];
  teamUuid: string;
  playerUuid?: string;
  body?: IFaceoffRequestBody;
}

export const getFaceoffs = createAsyncThunk<IFaceoffPlayerInfo, IGetFaceoffsParams>(
  'faceoffs/getFaceoffs',
  async ({ competitionsUuids, teamUuid, playerUuid, body }) => {
    const requestPath = playerUuid
      ? `/faceOff/${competitionsUuids}/${teamUuid}/${playerUuid}`
      : `/faceOff/${competitionsUuids}/${teamUuid}`;

    const faceoffs = await axiosInstance
      .post(requestPath, body)
      .then(response => parseFaceoffsData(response.data, playerUuid))
      .catch(defaultCatchErrorCallback);

    return faceoffs;
  },
);
