import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredFaceoffsSelector,
  filteredPassesVideoMetricSelector,
  filteredShootoutsSelector,
  filteredShotsVideoMetricSelector,
  filteredZoneEntriesFilterDataSelector,
  filteredZoneExitsFilterDataSelector,
  selectVideoCenter,
  selectVideomapsFilter,
  setVideoRecord,
} from '../../features';
import { INavigationContentKeys, IShot, IZoneEntity } from '../../types';
import { createVideoRecordFromArray, mapShootoutToVideoInfo } from '../../utils';

export const useSetVideoRecordAtMetricDataLoad = () => {
  const filteredShots = useAppSelector(filteredShotsVideoMetricSelector);
  const filteredPasses = useAppSelector(filteredPassesVideoMetricSelector);
  const filteredFaceoffs = useAppSelector(filteredFaceoffsSelector);
  const filteredShootouts = useAppSelector(filteredShootoutsSelector);
  const { metricVideoLinkId } = useAppSelector(selectVideoCenter);
  const { entity, entityId, filterByProp } = useAppSelector(selectVideomapsFilter);
  const zoneEntries = useAppSelector(filteredZoneEntriesFilterDataSelector);
  const zoneExits = useAppSelector(filteredZoneExitsFilterDataSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (metricVideoLinkId === INavigationContentKeys.shots) {
      // TODO: Jirka - dát do selectoru
      const filtered = filteredShots.filter(shot => shot[filterByProp as keyof IShot] === entityId);
      const shots =
        entity.value === 'player'
          ? filtered
          : filterByProp === 'blocker'
          ? filtered
          : filteredShots;

      console.log('shots', shots);
      dispatch(setVideoRecord(createVideoRecordFromArray(shots)));
    }

    if (metricVideoLinkId === INavigationContentKeys.passes) {
      // TODO: Jirka - dát do selectoru - půjde spojit s shots.
      const passes =
        entity.value === 'player'
          ? filteredPasses.filter(pass => pass.playerId === entityId)
          : filteredPasses;

      console.log('passes', passes);
      dispatch(setVideoRecord(createVideoRecordFromArray(passes)));
    }

    if (metricVideoLinkId === INavigationContentKeys.faceoffs) {
      console.log('filteredFaceoffs', filteredFaceoffs);
      dispatch(setVideoRecord(createVideoRecordFromArray(filteredFaceoffs)));
    }

    if (metricVideoLinkId === INavigationContentKeys.zoneEntries) {
      // TODO: Jirka - dát do selectoru - půjde spojit s shots.
      const filtered = zoneEntries.filter(
        zone => zone[filterByProp as keyof IZoneEntity] === entityId,
      );

      const entries =
        entity.value === 'player' ? filtered : filterByProp === 'blocker' ? filtered : zoneEntries;

      console.log('entries', entries);
      dispatch(setVideoRecord(createVideoRecordFromArray(entries)));
    }

    if (metricVideoLinkId === INavigationContentKeys.zoneExits) {
      // TODO: Jirka - dát do selectoru - půjde spojit s shots.
      const exits =
        entity.value === 'player'
          ? zoneExits.filter(zone => zone.playerId === entityId)
          : zoneExits;

      console.log('exits', exits);
      dispatch(setVideoRecord(createVideoRecordFromArray(exits)));
    }

    if (metricVideoLinkId === INavigationContentKeys.shootouts) {
      console.log(
        'filtered test',
        filteredShootouts.filter(shootout => shootout.skater.playerId === entityId),
      );

      const shootouts = filteredShootouts
        .map(shootout => mapShootoutToVideoInfo(shootout))
        .filter(shootout => shootout.playerId === entityId);

      dispatch(setVideoRecord(createVideoRecordFromArray(shootouts)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredShots, filteredPasses, filteredFaceoffs, filteredShootouts, zoneEntries, zoneExits]);
};
