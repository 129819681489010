import { CSSProperties, FC, ReactNode } from 'react';

import { createClassNames } from '../../utils';
import './GridLayout.styles.scss';

export interface IGridLayoutProps extends CSSProperties {
  /** Items to render. */
  children: ReactNode;
  /** Number of columns. */
  columns: number;
  /** Gap size in rem. */
  gap?: CSSProperties['gap'];
  /** Gap size in rem. */
  width?: CSSProperties['width'];
}

const classNames = createClassNames('grid-layout');

export const GridLayout: FC<IGridLayoutProps> = ({ columns, children, gap, width = '100%' }) => (
  <div
    className={classNames()}
    style={{
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      gap: gap ?? undefined,
      width,
    }}
  >
    {children}
  </div>
);
