import { createSlice } from '@reduxjs/toolkit';

import { IShotsState } from '../../types';
import { getHeatmap, getShots } from './shotsActions';

const initialState: IShotsState = {
  shots: [],
  shotsAgainst: [],
  isLoading: false,
  error: undefined,
  isLoadedFirstTime: undefined,
  heatmap: undefined,
  isHeatmapLoading: false,
  errorHeatmap: undefined,
  isHeatmapLoadedFirstTime: undefined,
};

export const shotsSlice = createSlice({
  name: 'shots',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getShots.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getShots.fulfilled, (state, action) => {
        if (action.meta.arg.isAgainst) {
          state.shotsAgainst = action.payload;
        } else {
          state.shots = action.payload;
        }
        state.isLoading = false;
        state.isLoadedFirstTime = true;
      })
      .addCase(getShots.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next case
      .addCase(getHeatmap.pending, state => {
        state.isHeatmapLoading = true;
        state.errorHeatmap = undefined;
      })
      .addCase(getHeatmap.fulfilled, (state, action) => {
        state.heatmap = action.payload;
        state.isHeatmapLoading = false;
        state.isHeatmapLoadedFirstTime = true;
      })
      .addCase(getHeatmap.rejected, (state, action) => {
        state.isHeatmapLoading = false;
        state.errorHeatmap = action.error.message;
      });
  },
});

export const shotsReducer = shotsSlice.reducer;
