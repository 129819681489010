import { useEffect, useState } from 'react';

import { ISelectOption, IUseMultiselectConfig } from '../types';

export const useMultiselect = <TOption = undefined>(config: IUseMultiselectConfig<TOption>) => {
  const { options, selected, onChange, hasSelectAllOption } = config;
  const [selectedAll, setSelectedAll] = useState<boolean>(false);

  useEffect(() => {
    if (hasSelectAllOption) {
      if (selected.length === options.length && options.length > 0) {
        setSelectedAll(true);
      } else {
        setSelectedAll(false);
      }
    }
  }, [hasSelectAllOption, selected, options.length]);

  const onChangeToggle = (option: ISelectOption<TOption>) => {
    if (selected.some(item => item.value === option.value)) {
      onChange(selected.filter(item => item.value !== option.value));
    } else {
      onChange([...selected, option]);
    }
  };

  const onChangeToggleSelectAll = () => {
    if (hasSelectAllOption) {
      setSelectedAll(!selectedAll);
      onChange(!selectedAll ? [...options] : []);
    }
  };

  return {
    selectedAll,
    onChangeToggle,
    onChangeToggleSelectAll,
  };
};
