import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';

import { ITrendChartData } from '../../../types';
import { createClassNames, formatDateByLanguage, getScoreStateShortcut } from '../../../utils';
import './TrendTooltip.styles.scss';

const classNames = createClassNames('trend-tooltip');

/** Trend tooltip component for Trend chart component. */
export const TrendTooltip: FC<TooltipProps<number, string>> = ({
  active,
  separator = ' = ',
  payload,
}) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    const linePayload = payload[0];
    const { matchInfo }: ITrendChartData = linePayload.payload;

    const scoreState = getScoreStateShortcut(matchInfo.score.state);

    return (
      <div className={classNames()}>
        <div className={classNames('teams')}>
          <p>{matchInfo.homeTeamShortcut}</p>
          <p className={classNames('teams__score', { won: matchInfo.trendTeamWon })}>
            {matchInfo.score.home}:{matchInfo.score.away}
            {scoreState && t(scoreState)}
          </p>
          <p>{matchInfo.awayTeamShortcut}</p>
        </div>
        <p className={classNames('date')}>
          {formatDateByLanguage(new Date(matchInfo.matchDate), 'P')}
        </p>
        <p
          className={classNames('metric')}
          style={{ color: linePayload.color }}
        >{`${linePayload.name}${separator}${linePayload.value}`}</p>
      </div>
    );
  }

  return null;
};
