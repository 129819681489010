import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClickOutside } from '../../hooks';
import { ChevronDownIcon, CrossIcon, DownArrowIcon } from '../../icons';
import { IMultiParamsVoid, IOnOffColors, IPlayerColors, ISelectOption } from '../../types';
import { createClassNames, getOnOffColor, transformObjectKeysToKebabCase } from '../../utils';
import { OnOffButton } from '../OnOffButton';
import { SelectOptionItem } from '../SelectOptionItem';
import '../shared/selectInputs.styles.scss';
import { IOnOffProps, ISelectInputSharedProps } from '../types';

export interface ISelectInputProps extends ISelectInputSharedProps, IOnOffProps {
  /** Available select options */
  options: ISelectOption[];
  /** Selected option. */
  selected?: ISelectOption;
  /** If true, it displays clear button that allows to user clear input. */
  isClearable?: boolean;
  /** If true, input has on/off button for activate or deactivate input. */
  hasOnOff?: boolean;
  /** Color variant of component. Only for variant filter. */
  color?: IPlayerColors | IOnOffColors;
  /** Tells if the input should be colored to green when it's on or to red when it's off. */
  onOffColored?: boolean;
  /** Function called when other option is picked. */
  onChange: IMultiParamsVoid;
}

const classNames = createClassNames('select-input');

/**
 * SelectInput component for selecting single value.
 */
export const SelectInput: FC<ISelectInputProps> = ({
  options,
  selected,
  isClearable,
  placeholder,
  variant,
  disabled,
  dropUp = false,
  scrollable,
  scrollableMaxHeight = '400px',
  error,
  hasOnOff,
  color: colorProp,
  onOffColored = false,
  isOn,
  iconComponent,
  onIsOnChange,
  onChange,
  onBlur,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const ref = useClickOutside<HTMLDivElement>(() => {
    if (open) {
      setOpen(false);
      onBlur?.();
    }
  }, [open, setOpen]);

  const color = useMemo(
    () => getOnOffColor(colorProp, onOffColored && !!selected, isOn),
    [colorProp, onOffColored, selected, isOn],
  );

  return (
    <div className={classNames()} data-testid='select-input'>
      <div
        ref={ref}
        className={classNames('selected', {
          open,
          variant,
          disabled,
          color,
          error,
        })}
        onClick={!disabled ? () => setOpen(!open) : undefined}
        data-testid='select-input__selected'
      >
        {hasOnOff && (
          <OnOffButton
            isEnabled={!!selected}
            isOn={isOn}
            onIsOnChange={onIsOnChange ? () => onIsOnChange(selected) : undefined}
            color={color}
          />
        )}
        {iconComponent}
        <div
          className={classNames('selected__field', {
            placeholder: !selected,
            disabled,
            color,
          })}
        >
          {selected
            ? t(selected.label, { ...selected.labelTranslationsOptions })
            : placeholder
            ? t(placeholder)
            : null}
        </div>
        {isClearable && selected && (
          <CrossIcon
            className={classNames('selected__cross-icon', {
              ...transformObjectKeysToKebabCase({
                color,
              }),
            })}
            onMouseDown={() => {
              onChange(undefined);
              onIsOnChange?.(false);
            }}
          />
        )}
        {variant === 'tcms' ? (
          <ChevronDownIcon />
        ) : (
          <DownArrowIcon
            className={classNames('selected__down-arrow-icon', {
              ...transformObjectKeysToKebabCase({
                color,
              }),
            })}
          />
        )}
      </div>
      <div
        className={classNames('options', {
          ...transformObjectKeysToKebabCase({
            visible: open && !disabled,
            variant,
            scrollable,
            color,
            dropUp,
          }),
        })}
        style={{ maxHeight: scrollable ? scrollableMaxHeight : undefined }}
        data-testid='select-input__options'
      >
        {options.map(option => {
          const { icon, ...optionWithoutIcon } = option;

          return (
            <SelectOptionItem
              key={
                option.additionalValue ? `${option.value}_${option.additionalValue}` : option.value
              }
              onChange={() => onChange(optionWithoutIcon)}
              color={variant === 'filter' ? color : undefined}
              {...{ option, variant }}
            />
          );
        })}
      </div>
    </div>
  );
};
