import { FC, HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';

import { ILeftRight } from '../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './Tooltip.styles.scss';

export interface ITooltipProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'onMouseEnter' | 'onMouseLeave'> {
  /**
   * Content for tooltip.
   */
  children: ReactNode;
  /**
   * Aligment of the tooltip arrow.
   * @default "center"
   */
  arrowAligment?: 'center' | 'up' | 'down' | ILeftRight;
  /**
   * Direction of the arrow.
   * @default "down"
   */
  arrowDirection?: 'up' | 'down' | ILeftRight;
  /**
   * Should the tooltip be X centered?
   */
  centerXAxis?: boolean;
  /**
   * Should the tooltip be Y centered?
   */
  centerYAxis?: boolean;
  /**
   * Whether the tooltip is shown or not.
   */
  isShown?: boolean;
  /**
   * If true, the tooltip component is reversed
   */
  reversed?: boolean;
  /**
   * If the effect is set to fade then the tooltip vanishes slowly. With effect none it just disappears.
   * @default "none"
   */
  effect?: 'none' | 'fade';
  /**
   * Color of the tooltip background
   */
  variant?: 'white' | 'black';
  /**
   * Width in px of the tooltip component (if auto width doesnt work)
   */
  width?: number;
}

const classNames = createClassNames('tooltip');

/**
 * Tooltip component for displaying additional information above some element.
 */
export const Tooltip: FC<ITooltipProps> = ({
  children,
  arrowAligment = 'center',
  arrowDirection = 'down',
  centerXAxis = false,
  centerYAxis = false,
  isShown,
  reversed,
  effect = 'none',
  variant = 'black',
  width,
  onMouseEnter,
  onMouseLeave,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [dynamicHeight, setDynamicHeight] = useState<number>(0);

  useEffect(() => {
    if (tooltipRef.current && isShown) {
      const children = tooltipRef.current.children[0];
      if (!children) return;

      setDynamicHeight(children.clientHeight);
    }
  }, [children, arrowDirection, isShown]);

  return (
    <div
      {...{ onMouseEnter, onMouseLeave }}
      className={classNames({
        ...transformObjectKeysToKebabCase({
          arrowAligment,
          arrowDirection,
          centerXAxis,
          centerYAxis,
          isShown,
          reversed,
          variant,
          effect,
        }),
      })}
      ref={tooltipRef}
      style={{ width: width && width + 'px', height: dynamicHeight && dynamicHeight + 'px' }}
      data-testid='tooltip'
    >
      {children}
    </div>
  );
};
