import { useEffect, useRef } from 'react';
import simpleheat from 'simpleheat';

type IUseSimpleHeatmap = (
  data: [number, number, number][],
  radius?: number,
  blur?: number,
) => React.MutableRefObject<HTMLCanvasElement | null>;

/**
 * Hook that uses simpleheat library to draw heatmap.
 * @param data Data to draw heatmap
 * @param radius Size of the circle for one data point
 * @param blur Blur of the circle for one data point
 * @returns Canvas element reference
 */
export const useSimpleHeatmap: IUseSimpleHeatmap = (data, radius = 29, blur = 40) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const heat = simpleheat(canvasRef.current);
      heat.radius(radius, blur);
      heat.data(data);
      heat.draw();
    }
  }, [data, radius, blur]);

  return canvasRef;
};
