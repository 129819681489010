import { FC, ReactNode } from 'react';

import { FaceOffPlaygroundIcon } from '../../../icons';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './FaceOffPlayground.styles.scss';

export interface IFaceOffPlaygroundProps {
  content: ReactNode;
}

const classNames = createClassNames('face-off-playground');

export const FaceOffPlayground: FC<IFaceOffPlaygroundProps> = ({ content }) => {
  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({}),
      })}
      data-testid='face-off-playground'
    >
      <FaceOffPlaygroundIcon />
      <div className={classNames('canvas')}>{content}</div>
    </div>
  );
};
