import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  Caption,
  GamesSelectList,
  GoalNetShootout,
  GoalNetShootoutContent,
  Loading,
  ToggleSelect,
} from '../../../../components';
import {
  shootoutEndResultOptions,
  shootoutEndTypeOptions,
  shotoutEndSideOptions,
} from '../../../../constants';
import {
  filteredShootoutFilterDataSelector,
  selectGames,
  selectMainFilter,
  selectPlayers,
  selectShootout,
  selectShootoutFilter,
  selectTeams,
  selectVideomapsFilter,
  setSelectedGames,
  setShootoutEndResult,
  setShootoutEndSide,
  setShootoutEndType,
  triggerContentReload,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useExportAsImage,
  useFetchFaceoffs,
  useFetchShootouts,
  useFetchShotsOrPasses,
  useHandleOnSubmit,
  useShootoutEffects,
  useVideoCenter,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import { IGoalStatsBoxWrapperProps, ISelectOption } from '../../../../types';
import {
  changeUrlSearchParamsMultiselectInput,
  createClassNames,
  getGamesOptions,
  goalNetProps,
  totalValuesPerTeamWrappers,
  transformObjectKeysToKebabCase,
} from '../../../../utils';
import './ShootoutsContent.styles.scss';
import { ShootoutsStatsBoxCategory } from './components';

const classNames = createClassNames('videomaps-shootouts-content');

export const ShootoutsContent = () => {
  const dispatch = useAppDispatch();
  const { games } = useAppSelector(selectGames);
  const teams = useAppSelector(selectTeams);
  const players = useAppSelector(selectPlayers);
  const { filteredShootoutGameEntity, filteredShootoutsBySelectedGames } = useAppSelector(
    filteredShootoutFilterDataSelector,
  );
  const { selectedTeam } = useAppSelector(selectMainFilter);
  const { selectedGames, selectedPlayerItems } = useAppSelector(selectVideomapsFilter);
  const { endResult, endSide, endType } = useAppSelector(selectShootoutFilter);
  const { isLoading, shootouts } = useAppSelector(selectShootout);

  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();

  const gamesOptions = useMemo(() => getGamesOptions(games.byId), [games.byId]);
  const isInitialMountRef = useRef(true);

  /* Export dat */
  const exportRef = useRef<HTMLDivElement>(null);
  const { isExporting, exportImage } = useExportAsImage(exportRef, `hokejLogic-karta-hráče`, 'png');

  const { fetchGamesAndToiForShotsOrPasses } = useFetchShotsOrPasses();
  const { fetchShootouts } = useFetchShootouts();
  const { fetchFaceoffStatsOfPlayers } = useFetchFaceoffs();
  const { playSelectedGamesVideos, playGameVideos, playVideos } = useVideoCenter(
    filteredShootoutGameEntity,
    ITranslationKeys.shootouts,
  );

  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGamesAndToiForShotsOrPasses(values);
    fetchShootouts(values, 'videomaps');
    dispatch(triggerContentReload());
    fetchFaceoffStatsOfPlayers(values);
  }, isInitialMountRef);
  useShootoutEffects(handleOnSubmit, isLoading, false);

  const handleChangeGame = (options: ISelectOption[]) => {
    dispatch(setSelectedGames(options));
    changeUrlSearchParamsMultiselectInput(options, 'selectedGames', setSearchParams);
  };

  if (!selectedTeam) {
    return <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectTeam)}</div>;
  }

  if (isLoading) return <Loading />;

  if (!shootouts) {
    return <div className='content-info-box'>{t(ITranslationKeys.youCanLoadData)}</div>;
  }

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, shootouts.length);
  if (errorInfoBox) return errorInfoBox;

  const goalStatBoxWrappers: IGoalStatsBoxWrapperProps[] = goalNetProps(
    filteredShootoutsBySelectedGames,
    selectedPlayerItems,
    playVideos,
  );
  const teamName = teams.byId[selectedTeam.value]?.name;
  const infoWrappers = totalValuesPerTeamWrappers(
    filteredShootoutsBySelectedGames,
    players.byId,
    selectedPlayerItems,
    teamName,
    players.similarPlayerNames,
    playVideos,
  );

  return (
    <div
      ref={exportRef}
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isLoading,
        }),
      })}
    >
      <div className={classNames()} data-testid='videomaps-page__shootouts-content'>
        <div className={classNames('main')}>
          <GamesSelectList
            selected={selectedGames}
            options={gamesOptions}
            gameRecord={games.byId}
            teamRecord={teams.byId}
            shouldCheckFullBody={false}
            selectedPlayerItems={selectedPlayerItems}
            gameEntities={filteredShootoutGameEntity}
            onChange={newValue => handleChangeGame(newValue)}
            onItemContentActionClick={gameId => playGameVideos(gameId)}
            onPlaySelectedGamesVideos={playSelectedGamesVideos}
            hasSelectAllOption
          />
          <div className={classNames('main__playground-wrapper')}>
            <GoalNetShootout content={<GoalNetShootoutContent boxes={goalStatBoxWrappers} />} />
            <div className={classNames('main__playground-wrapper__user-box')}>
              <ShootoutsStatsBoxCategory
                label={ITranslationKeys.totalValuesPerTeam}
                goalStatsBoxWrappers={infoWrappers}
              />
            </div>
          </div>
          <div className={classNames('main__form-box')}>
            <div>
              <Caption label={ITranslationKeys.resultOfEnding} />
              <ToggleSelect
                selected={endResult}
                options={shootoutEndResultOptions}
                onChange={newValue => dispatch(setShootoutEndResult(newValue))}
                hasOnlyOneColumn
              />
            </div>
            <div>
              <Caption label={ITranslationKeys.typeOfEnding} />
              <ToggleSelect
                selected={endType}
                options={shootoutEndTypeOptions}
                onChange={newValue => dispatch(setShootoutEndType(newValue))}
                twoColumns
              />
            </div>
            <div>
              <Caption label={ITranslationKeys.sideOfEnding} />
              <ToggleSelect
                selected={endSide}
                options={shotoutEndSideOptions}
                onChange={newValue => dispatch(setShootoutEndSide(newValue))}
                twoColumns
              />
            </div>
            <div className={classNames('main__form-box__save-button')}>
              <Button
                label={ITranslationKeys.saveMap}
                iconComponent={<DownloadIcon />}
                onClick={exportImage}
                disabled={isExporting}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
