import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { INavigationNotPersistedState } from '../../types';

const initialState: INavigationNotPersistedState = {
  isInitialized: false,
};

export const navigationNotPersistedSliceSlice = createSlice({
  name: 'navigationNotPersisted',
  initialState,
  reducers: {
    setIsInitialized: (state, action: PayloadAction<boolean | undefined>) => {
      state.isInitialized = action.payload;
    },
  },
});

export const { setIsInitialized } = navigationNotPersistedSliceSlice.actions;

export const navigationNotPersistedSliceSliceReducer = navigationNotPersistedSliceSlice.reducer;
