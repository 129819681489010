import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { ITranslationKeys } from '../../../i18n/types';
import { axiosInstance } from '../../../services/axiosInstance';
import { IHelp } from '../../../types';
import { defaultCatchErrorCallback } from '../../../utils';

export const getHelp = createAsyncThunk<IHelp>('settings/help/getHelp', async () => {
  const help: IHelp = await axiosInstance
    .get(`/setting/help`)
    .then(response => response.data)
    .catch(defaultCatchErrorCallback);

  return help;
});

export const putHelp = createAsyncThunk<IHelp | undefined, IHelp>(
  'settings/help/putHelp',
  async newHelp => {
    const status = await axiosInstance
      .put(`/setting/help`, newHelp)
      .then(response => response.status)
      .catch((error: AxiosError) => {
        const errorMessage = error.message ?? ITranslationKeys.saveDataFailedMessage;
        throw new Error(errorMessage);
      });

    return status === 204 ? newHelp : undefined;
  },
);
