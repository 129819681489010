import { Cell, Row } from '@tanstack/react-table';

import { createClassNames } from '../../../utils';
import { TableCell } from '../TableCell';
import './TableRow.styles.scss';

interface ITableRowProps<TData, TValue> {
  /** Row definition. */
  row: Row<TData>;
  /** Cells definition. */
  cells: Cell<TData, TValue>[];
  /** If there are sub rows, main row will be bold. */
  highlightMainRow?: boolean;
  /** If there are sub rows, first row will be hidden. */
  hideFirstRowInMainRow?: boolean;
  /** Hides first cell's bottom border. */
  disableFirstCellBorder?: boolean;
  /** Custom height of cells. */
  cellsHeight?: number;
}

const classNames = createClassNames('table-row');

/**
 * Table row component for table body (tbody).
 * @returns Styled `<tr>` element
 */
export const TableRow = <TData, TValue>({
  row,
  cells,
  highlightMainRow,
  hideFirstRowInMainRow,
  disableFirstCellBorder,
  cellsHeight,
}: ITableRowProps<TData, TValue>) => (
  <tr
    className={classNames({
      hide: hideFirstRowInMainRow && row.depth === 0,
    })}
  >
    {cells.map((cell, index) => (
      <TableCell
        key={cell.id}
        cell={cell}
        disableBorder={disableFirstCellBorder && index === 0}
        boldBorder={highlightMainRow && !row.getCanExpand()}
        height={cellsHeight}
      />
    ))}
  </tr>
);
