import { createSlice } from '@reduxjs/toolkit';

import { ITeamsState } from '../../types';
import { getCompetitionDetail } from '../competitionsDetail';

const initialState: ITeamsState = {
  byId: {},
  allIds: [],
  isLoading: undefined,
  error: undefined,
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    resetTeams: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getCompetitionDetail.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getCompetitionDetail.fulfilled, (state, action) => {
        action.payload.teams.forEach(team => {
          if (!state.byId[team.id]) {
            state.byId[team.id] = team;
            state.allIds.push(team.id);
          } else {
            if (!state.byId[team.id].seasonIds.includes(team.seasonIds[0])) {
              state.byId[team.id].seasonIds.push(team.seasonIds[0]);
            }
            if (!state.byId[team.id].competitionIds.includes(team.competitionIds[0])) {
              state.byId[team.id].competitionIds.push(team.competitionIds[0]);
            }
            team.playerIds.forEach(playerId => {
              if (!state.byId[team.id].playerIds.includes(playerId)) {
                state.byId[team.id].playerIds.push(playerId);
              }
            });
            team.goalkeeperIds.forEach(goalkeeperId => {
              if (!state.byId[team.id].goalkeeperIds.includes(goalkeeperId)) {
                state.byId[team.id].goalkeeperIds.push(goalkeeperId);
              }
            });
          }
        });
        state.isLoading = false;
      })
      .addCase(getCompetitionDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetTeams } = teamsSlice.actions;

export const teamsReducer = teamsSlice.reducer;
