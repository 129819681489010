import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IIdentityState } from '../../types';
import {
  changeIdentityPassword,
  changeIdentityRole,
  changeIdentityTeamOrMedia,
  createIdentity,
  deleteIdentity,
  enableIdentity,
  getIdentities,
} from './identityActions';

const initialState: IIdentityState = {
  identities: [],
  hasBeenIdentitiesLoaded: false,
  isLoading: false,
  error: undefined,
};

export const identitySlice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    setHasBeenIdentitiesLoaded: (state, action: PayloadAction<boolean>) => {
      state.hasBeenIdentitiesLoaded = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getIdentities.pending, state => {
        state.isLoading = true;
        state.hasBeenIdentitiesLoaded = false;
        state.error = undefined;
      })
      .addCase(getIdentities.fulfilled, (state, action) => {
        state.identities = action.payload;
        state.hasBeenIdentitiesLoaded = true;
        state.isLoading = false;
      })
      .addCase(getIdentities.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(deleteIdentity.fulfilled, (state, action) => {
        const identity = state.identities.find(identity => identity.id === action.meta.arg);
        if (identity) {
          identity.disabled = true;
        }
        state.isLoading = false;
      })
      // Next action case
      .addCase(enableIdentity.fulfilled, (state, action) => {
        const identity = state.identities.find(identity => identity.id === action.meta.arg);
        if (identity) {
          identity.disabled = false;
        }
        state.isLoading = false;
      })
      // Next action case
      .addCase(changeIdentityRole.fulfilled, (state, action) => {
        const identity = state.identities.find(
          identity => identity.id === action.meta.arg.identityId,
        );
        if (identity) {
          identity.role = action.meta.arg.newRole;
        }
        state.isLoading = false;
      })
      // Next action case
      .addCase(changeIdentityTeamOrMedia.fulfilled, (state, action) => {
        const identity = state.identities.find(
          identity => identity.id === action.meta.arg.identityId,
        );
        if (identity) {
          if (action.meta.arg.type === 'media') {
            identity.media = action.meta.arg.newTeamOrMediaId;
          } else {
            identity.teamId = action.meta.arg.newTeamOrMediaId;
          }
        }
        state.isLoading = false;
      })
      // Shared pending and rejected cases
      .addMatcher(isAnyOf(createIdentity.pending, changeIdentityPassword.pending), state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addMatcher(isAnyOf(createIdentity.fulfilled, changeIdentityPassword.fulfilled), state => {
        state.isLoading = false;
      })
      .addMatcher(
        isAnyOf(createIdentity.rejected, changeIdentityPassword.rejected),
        (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        },
      );
  },
});

export const { setHasBeenIdentitiesLoaded } = identitySlice.actions;

export const identityReducer = identitySlice.reducer;
