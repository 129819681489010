import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredMainFilterDataSelector,
  getPlayersWithStats,
  getPlayersWithStatsPercentile,
  getRanks,
  selectIndividualStats,
  selectMainFilter,
  setIsPercentilesActive,
} from '../../features';
import { IIndividualStatsRequestBody, IMainFilterForm } from '../../types';
import { useNormalizeMetrics } from '../useNormalizeMetrics';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

export const useFetchIndividualStats = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { isPercentilesActive } = useAppSelector(selectIndividualStats);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchIndividualStats = (data: Partial<IMainFilterForm>, isPercentiles?: boolean) => {
    if (isPercentilesActive) {
      dispatch(setIsPercentilesActive(false));
    }

    const competitionsUuids = filteredParts.map(part => part.id);

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IIndividualStatsRequestBody = {
      ...requestBodyBase,
      metrics: normalizeMetrics(),
    };

    if (isPercentiles) {
      const teamUuid =
        selectedTeam && selectedTeam.value !== 'all' ? selectedTeam.value : undefined;

      dispatch(
        getPlayersWithStatsPercentile({
          competitionsUuids,
          body: requestBody,
          teamUuid,
        }),
      );
      dispatch(
        getRanks({
          competitionsUuids,
          body: requestBody,
        }),
      );
    } else {
      dispatch(
        getPlayersWithStats({
          competitionsUuids,
          body: requestBody,
          teamUuid: selectedTeam && selectedTeam.value !== 'all' ? selectedTeam.value : undefined,
        }),
      );
    }
  };

  return fetchIndividualStats;
};
