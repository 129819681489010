import { FC, useState } from 'react';

import { IPieChartTooltipProps } from '../../../types';
import {
  createClassNames,
  getH2HMetricValue,
  getPieChartPercent,
  getPieChartSize,
  getPieChartStyle,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import { PieChartTooltip } from '../PieChartTooltip';
import './PieChart.styles.scss';

export interface IPieChartProps extends IPieChartTooltipProps {
  /**
   * Has border at right side?
   */
  borderRight: boolean;
  /**
   * Has border at bottom side?
   */
  borderBottom: boolean;
  /**
   * Is this last item on X axis?
   */
  playersLength: number;
  /**
   * Is this last item on Y axis?
   */
  opponentsLength: number;
  coordsX: number;
  coordsY: number;
  crossX: number;
  crossY: number;
  /**
   * Event triggered after mouse enter.
   */
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  /**
   * Event triggered after mouse leave.
   */
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const classNames = createClassNames('pie-chart');

/**
 * PieChart UI component for HeadToHead component.
 */
export const PieChart: FC<IPieChartProps> = ({
  category,
  headToHead,
  opponentUuid,
  playerUuid,
  borderRight,
  borderBottom,
  playersLength,
  opponentsLength,
  coordsX,
  coordsY,
  crossX,
  crossY,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [show, setShow] = useState(false);
  const toi = getH2HMetricValue(headToHead, playerUuid, opponentUuid, 'toi');
  const size = getPieChartSize(headToHead, playerUuid, opponentUuid);
  const blue = getPieChartPercent(category, headToHead, playerUuid, opponentUuid);
  const { pieChartSize, pieChartMargin, isGray } = getPieChartStyle(
    category,
    size,
    headToHead,
    playerUuid,
    opponentUuid,
  );

  const isCrossXLast = crossX === opponentsLength - 1;
  const isCrossYLast = crossY === playersLength - 1;
  const isXLast = opponentsLength - 1 === coordsX;
  const isYLast = playersLength - 1 === coordsY;
  const isLast = isXLast && isYLast;
  const isCrossColorActive = coordsX === crossX || coordsY === crossY;

  const handlePieChartEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    onMouseEnter?.(event);
    setShow(true);
  };

  const handlePieChartLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    onMouseLeave?.(event);
    setShow(false);
  };

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          borderBottom,
          borderRight,
          gray: !toi || isGray,
          crossColorActive: isCrossColorActive,
          isXLast: isXLast && !isCrossXLast,
          isYLast: isYLast && !isCrossYLast,
          isLast,
        }),
      })}
      onMouseEnter={handlePieChartEnter}
      onMouseLeave={handlePieChartLeave}
      data-testid='pie-chart'
    >
      <svg
        viewBox='0 0 32 32'
        style={{
          width: pieChartSize + 'px',
          height: pieChartSize + 'px',
          marginTop: pieChartMargin + 'px',
          marginLeft: pieChartMargin + 'px',
        }}
      >
        <circle strokeDasharray={blue + ' 100'} r={16} cx={16} cy={16}></circle>
      </svg>

      {size > 1 && (
        <PieChartTooltip
          headToHead={headToHead}
          playerUuid={playerUuid}
          opponentUuid={opponentUuid}
          category={category}
          show={show}
        />
      )}
    </div>
  );
};
