import { ListenerEffectAPI } from '@reduxjs/toolkit';
import type { AppDispatch, RootState } from 'app/store';

import { teamAllOption } from '../constants';
import {
  getCompetitionDetail,
  setIsAllCompetitionsDetailLoaded,
  setIsLastSeasonCompetitionsDetailLoaded,
  setOpponentsAtGamesPage,
  setSelectedParts,
  setSelectedSeasons,
  setSelectedTeam,
} from '../features';
import {
  ICompetition,
  ICompetitionDetail,
  ICompetitionsDetailRecord,
  IDateRangeString,
  ISeason,
  ISeasonRecord,
  ISelectOption,
} from '../types';

/**
 * It takes all loaded `seasons`, find one with max id (=year) value and return it.
 * @param byId All loaded season records.
 * @returns Last season
 */
export const getLastSeason = (byId: ISeasonRecord) => {
  const seasonIds = Object.keys(byId).map(id => parseInt(id));
  const lastSeasonId = Math.max(...seasonIds).toString();
  return byId[lastSeasonId];
};

/**
 * Function returns one season value and label for select input components.
 * @param season Season object
 * @returns Option for select input
 */
export const getSeasonOption = (season: ISeason): ISelectOption => ({
  value: season.id,
  label: season.name,
});

/**
 * Function returns seasons array with values and labels for select input components.
 * @param byId Season byId record object
 * @returns Options for select input
 */
export const getSeasonsOptions = (byId: ISeasonRecord): ISelectOption[] =>
  Object.values(byId)
    .map(season => getSeasonOption(season))
    .sort((a, b) => b.value.localeCompare(a.value));

/**
 * Function returns one season part value and label for select input components.
 * @param competitionDetails Competition detail object
 * @returns Options for select input
 */
export const getPartOption = (competitionDetail: ICompetitionDetail): ISelectOption => {
  return {
    value: competitionDetail.part,
    label: competitionDetail.part,
  };
};

/**
 * Function returns season parts array with values and labels for select input components.
 * @param competitionsDetails Competition details array
 * @returns Options for select input.
 */
export const getPartsOptions = (byId: ICompetitionsDetailRecord): ISelectOption[] => {
  const uniqueValues = new Set();

  const removedDuplicatesCompetitions = Object.values(byId).filter(competition => {
    if (!uniqueValues.has(competition.part)) {
      uniqueValues.add(competition.part);
      return true;
    }
    return false;
  });

  return removedDuplicatesCompetitions.map(competition => getPartOption(competition));
};

export const getBasePart = (partOptions: ISelectOption[]): ISelectOption => {
  const basePart = partOptions.find(part => part.value === 'base');

  return basePart ?? partOptions[0];
};

/**
 * Function for `seasonsListeners`. Dispatches `getCompetitionDetail` actions
 * for all last season competitions. Waits for all promises to resolve and then
 * dispatches `setIsLastSeasonCompetitionsDetailLoaded`,
 * which indicates that last seasons competitions are loaded.
 * @param listenerApi listenerApi
 * @param lastSeason Last season object
 * @param lastSeasonCompetitions Last season competitions array
 * @returns Last season
 */
export const fetchLastSeasonCompetitionsAndWait = async (
  listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  lastSeason: ISeason,
  competitions: ICompetition[],
) => {
  const lastSeasonCompetitions = competitions.filter(competition =>
    lastSeason.competitionIds.includes(competition.id),
  );

  const lastSeasonPromises = lastSeasonCompetitions.map(competition => {
    return listenerApi.dispatch(
      getCompetitionDetail({
        competitionUuid: competition.id,
        part: competition.part,
      }),
    );
  });

  await Promise.allSettled(lastSeasonPromises).then(() => {
    const competitionsDetailState = listenerApi.getState().competitionsDetail;
    const firstCompetitionDetail =
      Object.values(competitionsDetailState.byId).find(
        competition => competition.part === 'base',
      ) || competitionsDetailState.byId[competitionsDetailState.allIds[0]];

    listenerApi.dispatch(setIsLastSeasonCompetitionsDetailLoaded(true));
    listenerApi.dispatch(setSelectedSeasons([getSeasonOption(lastSeason)]));
    listenerApi.dispatch(setSelectedParts([getPartOption(firstCompetitionDetail)]));
    listenerApi.dispatch(setSelectedTeam(teamAllOption));
    listenerApi.dispatch(setOpponentsAtGamesPage(teamAllOption));
  });
};

/**
 * Function for `seasonsListeners`. Dispatches `getCompetitionDetail` actions
 * for all other seasons competitions (except last season).
 * Waits for all promises to resolve.
 * Then dispatches `setIsAllCompetitionsDetailLoaded`,
 * which indicates that all seasons competitions are loaded.
 * @param listenerApi listenerApi
 * @param competitions Season competitions array
 * @param lastSeasonId Last season id
 * @returns Last season
 */
export const fetchOtherSeasonsCompetitionsAndWait = async (
  listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  lastSeason: ISeason,
  competitions: ICompetition[],
) => {
  const otherSeasonsCompetitons = competitions.filter(
    competition => !lastSeason.competitionIds.includes(competition.id),
  );

  const otherSeasonsPromises = otherSeasonsCompetitons.map(competition => {
    return listenerApi.dispatch(
      getCompetitionDetail({
        competitionUuid: competition.id,
        part: competition.part,
      }),
    );
  });

  await Promise.allSettled(otherSeasonsPromises).then(() => {
    listenerApi.dispatch(setIsAllCompetitionsDetailLoaded(true));
  });
};

/**
 * Creates date range from the year of the season.
 * @param year Season's year (e.g. value from select option object).
 */
export const createDateRangeFromSeasonYear = (year: string): IDateRangeString => {
  const from = `${year}-05-01`;
  const to = `${parseInt(year) + 1}-04-30`;

  return {
    from,
    to,
  };
};

/**
 * Creates season's date range from the date.
 * @param date Date (usually actual date).
 */
export const createSeasonDateRangeFromDate = (date: Date): IDateRangeString => {
  const divider = new Date(`${date.getFullYear()}-05-01`);
  const year = date < divider ? date.getFullYear() - 1 : date.getFullYear();

  return createDateRangeFromSeasonYear(year.toString());
};
