import { createSelector } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { DEFAULT_TIME_PERIOD_TABLE_FORM_VALUES } from '../../constants';
import { ITimePeriodTableDataToSet, ITrendChartData } from '../../types';
import {
  createTimePeriodTableFormDataByDate,
  createTimePeriodTableFormDataByGames,
  formatDateByLanguage,
  getScoreStateShortcut,
} from '../../utils';
import { selectPlayers, selectTeams, selectTrend } from '../selectors';

export const trendChartDataSelector = createSelector(
  [selectTrend, selectTeams, selectPlayers],
  (trendState, teamsState, playersState) => {
    const { trend, selectedMetric, trendTeamId, trendPlayerId } = trendState;
    const trendTeam = trendTeamId ? teamsState.byId[trendTeamId] : undefined;
    const trendPlayer = trendPlayerId ? playersState.byId[trendPlayerId] : undefined;

    const trendChartData: ITrendChartData[] = trend.map<ITrendChartData>(item => {
      const { awayTeamId, homeTeamId, date, score, stats, movingAverage } = item;

      const homeTeamShortcut = teamsState.byId[homeTeamId].shortcut;
      const awayTeamShortcut = teamsState.byId[awayTeamId].shortcut;
      const opponentTeamShortcut = trendTeamId === homeTeamId ? awayTeamShortcut : homeTeamShortcut;
      const trendTeamWon =
        trendTeamId === homeTeamId ? score.home > score.away : score.away > score.home;
      const scoreState = getScoreStateShortcut(score.state);
      const scoreString = `${score.home}:${score.away}${scoreState ? t(scoreState) : ''}`;

      const metricValue = selectedMetric && stats ? stats[selectedMetric.value] : null;
      const movingAverageValue =
        selectedMetric && movingAverage ? movingAverage[selectedMetric.value] : null;

      return {
        matchInfo: {
          matchDate: date,
          homeTeamShortcut,
          awayTeamShortcut,
          score,
          trendTeamWon,
          xAxisTickValue: `${formatDateByLanguage(
            new Date(date),
            'P',
          )};${opponentTeamShortcut};${scoreString}${trendTeamWon ? ';won' : ''}`,
        },
        metricValue,
        movingAverageValue,
      };
    });

    return {
      trendChartData,
      trendTeam,
      trendPlayer,
    };
  },
);

export const filteredTrendSelector = createSelector([selectTrend], trendState => {
  const { trend, tableDataBy } = trendState;

  return tableDataBy === 'playerMatches' ? Object.values(trend).filter(item => item.stats) : trend;
});

export const trendTimePeriodTableFormDataSelector = createSelector(
  [selectTrend, filteredTrendSelector],
  (trendState, filteredTrend): ITimePeriodTableDataToSet => {
    const { tableTimePeriodType, tableDataBy } = trendState;

    if (!tableTimePeriodType)
      return {
        rowCount: 1,
        timePeriodForm: DEFAULT_TIME_PERIOD_TABLE_FORM_VALUES,
        tableFormDataBy: tableDataBy || 'playerMatches',
      };

    if (tableTimePeriodType.value === '10games') {
      return createTimePeriodTableFormDataByGames(filteredTrend.length, tableDataBy, 10);
    }

    if (tableTimePeriodType.value === '5games') {
      return createTimePeriodTableFormDataByGames(filteredTrend.length, tableDataBy, 5);
    }

    if (tableTimePeriodType.value === 'months') {
      return createTimePeriodTableFormDataByDate(filteredTrend, tableDataBy);
    }

    if (tableTimePeriodType.value === 'quarter') {
      return createTimePeriodTableFormDataByGames(filteredTrend.length, tableDataBy, 13);
    }

    return {
      rowCount: 1,
      timePeriodForm: DEFAULT_TIME_PERIOD_TABLE_FORM_VALUES,
      tableFormDataBy: tableDataBy || 'playerMatches',
    };
  },
);

export const trendDataSelector = createSelector(
  [trendChartDataSelector, filteredTrendSelector, trendTimePeriodTableFormDataSelector],
  (trendChart, filteredTrend, trendTimePeriodTableFormData) => {
    return {
      trendChart,
      filteredTrend,
      trendTimePeriodTableFormData,
    };
  },
);
