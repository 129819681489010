import { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { TranslationFunctionOptions } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { ICommonButtonProps } from '../../types';
import './TcmsButton.styles.scss';

interface ITcmsButtonProps extends ICommonButtonProps {
  /** What variant of the button should it be? */
  variant?: 'outlined' | 'filled' | 'text';
  /** Color */
  color?: 'default' | 'blue' | 'gray';
  /** Width */
  width?: CSSProperties['width'];
  /** Text align */
  textAlign?: 'start' | 'center' | 'end';
  /** Is icon position absolute? */
  isIconAbsolute?: boolean;
}

const classNames = createClassNames('tcms-button');

export const TcmsButton: FC<ITcmsButtonProps> = ({
  label,
  labelTranslationsOptions,
  size = 'medium',
  type = 'button',
  disabled,
  iconComponent,
  iconPosition = 'right',
  onClick,
  variant = 'outlined',
  color = 'default',
  width,
  textAlign = 'center',
  isIconAbsolute,
}) => {
  const { t } = useTranslation();

  const renderIconWithLabel = (
    label: ITranslationKeys | string,
    labelTranslationsOptions?: TranslationFunctionOptions,
  ) => {
    if (iconPosition === 'left') {
      return (
        <>
          {iconComponent}
          {t(label, { ...labelTranslationsOptions })}
        </>
      );
    }

    return (
      <>
        {t(label, { ...labelTranslationsOptions })}
        {iconComponent}
      </>
    );
  };

  return (
    <button
      type={type}
      className={classNames({
        ...transformObjectKeysToKebabCase({
          variant,
          size,
          hasIcon: !!iconComponent && !isIconAbsolute,
          color,
          iconPosition,
          isIconAbsolute,
          textAlign,
        }),
      })}
      onClick={onClick}
      disabled={disabled}
      style={{ width }}
    >
      {label ? renderIconWithLabel(label, labelTranslationsOptions) : iconComponent}
    </button>
  );
};
