import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppSelector } from '../../app/hooks';
import { selectAuth } from '../../features';
import { ITranslationKeys } from '../../i18n/types';
import { IBundleVideo } from '../../types';
import { createVideoBundle } from '../../utils';

export const useOnDownloadVideos = () => {
  const { user } = useAppSelector(selectAuth);

  const { t } = useTranslation();

  const [downloading, setDownloading] = useState<boolean>(false);

  const onDownload = (downloadVideos: IBundleVideo[]) => {
    setDownloading(true);
    toast.success(t(ITranslationKeys.downloadingVideos), {
      toastId: ITranslationKeys.downloadingVideos,
    });

    if (user?.id) {
      createVideoBundle(user?.id, downloadVideos)
        .then(() => {
          toast.success(t(ITranslationKeys.downloadingVideosSuccesful), {
            toastId: ITranslationKeys.downloadingVideosSuccesful,
          });
        })
        .catch(err => {
          toast.error(t(ITranslationKeys.downloadingVideosError), {
            toastId: ITranslationKeys.downloadingVideosError,
          });
          console.error('[useHandleDownloadVideos] create video bundle err:', err);
        })
        .finally(() => setDownloading(false));
    } else {
      setDownloading(false);
    }
  };

  return {
    downloading,
    onDownload,
  };
};
