import {
  ColumnDef,
  ColumnPinningState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  BasicColumnCell,
  BasicColumnHeader,
  EyeDropdown,
  ImageColumn,
  SummaryPercentileColumnCell,
  SummaryPercentileColumnHeader,
  TeamColumnCell,
  TeamColumnHeader,
} from '../../../../components';
import { logosPaths } from '../../../../constants';
import { selectFormations } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IColumnsConfig,
  IEntity,
  IFormationStatsTableData,
  INavigationContentKeys,
  INavigationPageKeys,
  IUrlFilterParams,
} from '../../../../types';
import {
  createDynamicStatsColumnsDef,
  createIndexColumn,
  secondsToMinutesAndSeconds,
} from '../../../../utils';

const columnHelper = createColumnHelper<IFormationStatsTableData>();

const columnPinning: ColumnPinningState = {
  left: ['actions', 'index', 'teamId', 'teamName', 'gp', 'toi'],
  right: ['summaryPercentile'],
};

const initialSorting: SortingState = [
  {
    id: 'toi',
    desc: true,
  },
];

const fixedColumns: ColumnDef<IFormationStatsTableData, any>[] = [
  columnHelper.display({
    id: 'actions',
    cell: props => {
      const filterParams: IUrlFilterParams[] = [
        {
          name: 'selectedTeam',
          value: props.row.original.teamId,
        },
      ];

      return (
        <EyeDropdown
          eyeDropdownTooltipItems={[
            {
              pageTranslationLabel: ITranslationKeys.gamelog,
              navigationPageKey: INavigationPageKeys.teams,
              navigationTabKey: INavigationContentKeys.gamelog,
              filterParams,
            },
            // TODO: Add teams trend after implementing it
            // {
            //   pageTranslationLabel: ITranslationKeys.trend,
            //   navigationPageKey: INavigationPageKeys.teams,
            //   navigationTabKey: INavigationContentKeys.trend,
            //   filterParams,
            // },
          ]}
        />
      );
    },
  }),
  columnHelper.display({
    id: 'index',
    header: 'P',
    cell: props => createIndexColumn(props),
  }),
  columnHelper.accessor('teamId', {
    header: '',
    cell: props => <ImageColumn src={logosPaths[props.getValue()]} alt='team-logo' />,
  }),
  columnHelper.accessor('teamName', {
    header: () => <TeamColumnHeader />,
    cell: props => <TeamColumnCell {...props} />,
  }),
  columnHelper.accessor('gp', {
    header: props => <BasicColumnHeader {...props}>GP</BasicColumnHeader>,
    cell: props => <BasicColumnCell {...props}>{props.getValue()}</BasicColumnCell>,
    enableSorting: false,
  }),
  columnHelper.accessor('toi', {
    header: props => <BasicColumnHeader {...props}>TOI</BasicColumnHeader>,
    cell: props => (
      <BasicColumnCell {...props}>{secondsToMinutesAndSeconds(props.getValue())}</BasicColumnCell>
    ),
  }),
];

export const useColumnsConfig = (
  centerPartWidth: number | undefined,
): IColumnsConfig<IFormationStatsTableData> => {
  const { isTeamOrderActive, isTeamOrderLoading } = useAppSelector(selectFormations);
  const getFilteredMetrics = useFilteredMetricsForTable();

  const columns = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(IEntity.teams);
    const dynamicColumns = createDynamicStatsColumnsDef(
      filteredMetrics,
      columnHelper,
      {
        isPercentilesActive: isTeamOrderActive,
        isPercentilesLoading: isTeamOrderLoading,
        isOrder: true,
      },
      centerPartWidth,
    );

    const conditionalColumns: ColumnDef<IFormationStatsTableData, any>[] =
      isTeamOrderActive && !isTeamOrderLoading
        ? [
            columnHelper.accessor('summaryPercentile', {
              header: props => (
                <BasicColumnHeader {...props}>
                  <SummaryPercentileColumnHeader isLastInTable />
                </BasicColumnHeader>
              ),
              cell: props => (
                <BasicColumnCell {...props}>
                  <SummaryPercentileColumnCell value={props.getValue()} isOrder={true} />
                </BasicColumnCell>
              ),
            }),
          ]
        : [];
    return fixedColumns.concat(dynamicColumns).concat(conditionalColumns);
  }, [isTeamOrderActive, isTeamOrderLoading, centerPartWidth, getFilteredMetrics]);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};
