import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import {
  IReturnTypeGetSimilarPlayers,
  ISimilarPlayersDTO,
  ISimilarPlayersRequestBody,
} from '../../types';
import { buildPath, defaultCatchErrorCallback, urlBooleanParamResolver } from '../../utils';
import { parseSimilarPlayersData } from './parseSimilarPlayers';

interface IGetSimilarPlayersParams {
  competitionsUuids: string[];
  playerUuid?: string;
  teamUuid?: string;
  body?: ISimilarPlayersRequestBody;
  isByPlayer?: boolean;
}

export const getSimilarPlayers = createAsyncThunk<
  IReturnTypeGetSimilarPlayers,
  IGetSimilarPlayersParams
>(
  'similarPlayers/getSimilarPlayers',
  async ({ competitionsUuids, playerUuid, teamUuid, body, isByPlayer }) => {
    const base = `/similarPlayers/${competitionsUuids}`;
    const requestPath = buildPath(base, [
      urlBooleanParamResolver(isByPlayer, `${playerUuid}/${teamUuid}`),
    ]);

    const similarPlayersData: IReturnTypeGetSimilarPlayers = await axiosInstance
      .post<ISimilarPlayersDTO>(requestPath, body ?? {})
      .then(response =>
        parseSimilarPlayersData(
          response.data,
          playerUuid && teamUuid ? { id: playerUuid, teamId: teamUuid } : undefined,
        ),
      )
      .catch(defaultCatchErrorCallback);

    return similarPlayersData;
  },
);
