import { useAppDispatch } from '../../../app/hooks';
import { opponentEntityOption, playerEntityOption, teamEntityOption } from '../../../constants';
import { setEntity } from '../../../features';
import { IMetricSectionName, IMetricWithDatasetMetric } from '../../../types';

export const useSetEntityFilter = () => {
  const dispatch = useAppDispatch();

  const setEntityFilterSetter = (stat: IMetricWithDatasetMetric) => {
    if (stat.metric.metricSectionId === IMetricSectionName.individual) {
      if (stat.metric.metricInfo.color === 'green') {
        dispatch(setEntity(playerEntityOption));
      }

      if (stat.metric.metricInfo.color === 'red') {
        dispatch(setEntity(opponentEntityOption));
      }
    }

    if (stat.metric.metricSectionId === IMetricSectionName.onIce) {
      if (stat.metric.metricInfo.color === 'green') {
        dispatch(setEntity(teamEntityOption));
      }

      if (stat.metric.metricInfo.color === 'red') {
        dispatch(setEntity(opponentEntityOption));
      }
    }
  };

  return { setEntityFilterSetter };
};
