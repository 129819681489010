import { FC } from 'react';

import { PlayIcon } from '../../../icons';
import { IGoalStatsBoxProps } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './GoalStatsBox.styles.scss';

const classNames = createClassNames('goal-stats-box');

export const GoalStatsBox: FC<IGoalStatsBoxProps> = ({
  items,
  enablePlayIcon = true,
  isHorizontal = false,
  color = 'white',
  variant = 'default',
  fontWeight = '400',
  onClick,
}) => {
  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          hasMoreElements: items.length > 1,
          isHorizontal,
          disablePlayIcon: !enablePlayIcon,
          color,
          variant,
          fontWeight,
        }),
      })}
      data-testid='goal-stats-box'
      onClick={onClick}
    >
      <table onClick={onClick}>
        <tbody>
          <tr>
            {items.map((item, index) => (
              <td key={index}>{item.value}</td>
            ))}
          </tr>
        </tbody>
      </table>
      <div className={classNames('play-icon')}>
        <PlayIcon />
      </div>
    </div>
  );
};
