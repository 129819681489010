import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IZonesState } from '../../types';
import {
  getDumpin,
  getDumpout,
  getZoneEntry,
  getZoneEntryAndDumpin,
  getZoneExit,
  getZoneExitAndDumpout,
} from './zonesActions';

const initialState: IZonesState = {
  zones: { for: [], against: [] },
  areZonesLoading: false,
  errorZones: '',
};

export const zonesSlice = createSlice({
  name: 'zones',
  initialState,
  reducers: {
    resetZones: state => {
      state.zones = initialState.zones;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(
          getZoneExit.pending,
          getZoneEntry.pending,
          getDumpout.pending,
          getDumpin.pending,
          getZoneEntryAndDumpin.pending,
          getZoneExitAndDumpout.pending,
        ),
        state => {
          state.areZonesLoading = true;
          state.errorZones = undefined;
        },
      )
      .addMatcher(
        isAnyOf(
          getZoneExit.fulfilled,
          getZoneEntry.fulfilled,
          getDumpout.fulfilled,
          getDumpin.fulfilled,
          getZoneEntryAndDumpin.fulfilled,
          getZoneExitAndDumpout.fulfilled,
        ),
        (state, action) => {
          state.zones = action.payload;
          state.areZonesLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(
          getZoneExit.rejected,
          getZoneEntry.rejected,
          getDumpout.rejected,
          getDumpin.rejected,
          getZoneEntryAndDumpin.rejected,
          getZoneExitAndDumpout.rejected,
        ),
        (state, action) => {
          state.areZonesLoading = false;
          state.errorZones = action.error.message;
        },
      );
  },
});

export const { resetZones } = zonesSlice.actions;

export const zonesReducer = zonesSlice.reducer;
