import { createSlice } from '@reduxjs/toolkit';

import { IRanksState } from '../../types';
import { getRanks } from './ranksActions';

const initialState: IRanksState = {
  rank: {},
  isLoading: false,
  error: undefined,
};

export const ranksSlice = createSlice({
  name: 'ranks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRanks.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getRanks.fulfilled, (state, action) => {
        state.rank = action.payload;
        state.isLoading = false;
      })
      .addCase(getRanks.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const ranksReducer = ranksSlice.reducer;
