import { useEffect } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  BasicPlayerInfo,
  Button,
  Caption,
  CardItemList,
  ICompareGoalkeeperForm,
  Loading,
  SearchInput,
} from '../../../../components';
import { GOALKEEPER_CARDS_METRICS } from '../../../../constants';
import {
  filteredGoalkeepersForCompareOptionsSelector,
  filteredMainFilterDataSelector,
  getGoalkeeperCardInfo,
  getGoalkeepersStats,
  selectGoalkeepers,
  setCompareGoalkeeperItems,
} from '../../../../features';
import { usePrepareBaseRequestBody } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IActualLoadedPlayer,
  IGoalkeeperGoalStatsRequestBody,
  IMainFilterForm,
  IMultiParamsVoid,
  ISelectOption,
} from '../../../../types';
import { createClassNames } from '../../../../utils';
import { useGetGoalkeeperCards } from '../shared';
import './CompareGoalkeepersContent.styles.scss';

const classNames = createClassNames('compare-goalkeepers-content');

export const CompareGoalkeepersContent = () => {
  const filteredGoalkeepersForCompareOptions = useAppSelector(
    filteredGoalkeepersForCompareOptionsSelector,
  );
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { byId, compareGoalkeeperItems } = useAppSelector(selectGoalkeepers);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { getGoalkeeperCards, getGoalkeeperGoalNetStats } = useGetGoalkeeperCards();

  const mainFilterMethods = useFormContext<IMainFilterForm>();

  const { control, handleSubmit, reset } = useForm<ICompareGoalkeeperForm>({
    defaultValues: {
      goalkeepers: {
        '0': null,
        '1': null,
        '2': null,
        '3': null,
      },
    },
  });

  useEffect(() => {
    const definedSelectedGoalkeeperOptions = Object.values(compareGoalkeeperItems).reduce<
      Record<string, ISelectOption | null>
    >((acc, item) => {
      if (item.selectedGoalkeeperCompare) {
        acc[item.id] = item.selectedGoalkeeperCompare;
      } else {
        acc[item.id] = null;
      }

      return acc;
    }, {});

    reset({ goalkeepers: definedSelectedGoalkeeperOptions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareGoalkeeperItems]);

  const handleChangeGoalkeeper = (
    value: ISelectOption,
    itemKey: string,
    onChange: IMultiParamsVoid,
  ) => {
    onChange(value);
    dispatch(
      setCompareGoalkeeperItems({
        compareGoalkeeperItems: {
          ...compareGoalkeeperItems[itemKey],
          selectedGoalkeeperCompare: value,
        },
        itemKey,
      }),
    );
  };

  const handleOnSubmit = (data: ICompareGoalkeeperForm, itemKey: string) => {
    const formData = data.goalkeepers[itemKey];
    const selectedGoalkeeperCompare = compareGoalkeeperItems[itemKey].selectedGoalkeeperCompare;
    const goalkeeperId = selectedGoalkeeperCompare?.value;

    if (formData && goalkeeperId) {
      const loadedGoalkeeperInfo = byId[goalkeeperId]?.goalkeeperInfos?.find(
        info => info.teamId === selectedGoalkeeperCompare?.additionalValue,
      );

      if (!loadedGoalkeeperInfo) {
        if (formData.additionalValue) {
          const { requestBodyBase } = prepareBaseRequestBody(mainFilterMethods.getValues());
          const requestBody: IGoalkeeperGoalStatsRequestBody = {
            ...requestBodyBase,
            metrics: GOALKEEPER_CARDS_METRICS,
          };
          dispatch(
            getGoalkeeperCardInfo({
              competitionUuid: filteredParts[0].id,
              teamUuid: formData.additionalValue,
              playerUuid: formData.value,
              isCalledFromCompareGoalkeepers: true,
            }),
          );
          dispatch(
            getGoalkeepersStats({
              competitionsUuids: [filteredParts[0].id],
              teamUuid: formData.additionalValue,
              body: requestBody,
            }),
          );
          dispatch(
            getGoalkeepersStats({
              competitionsUuids: [filteredParts[0].id],
              teamUuid: formData.additionalValue,
              body: requestBody,
              isPercentile: true,
            }),
          );
          dispatch(
            setCompareGoalkeeperItems({
              compareGoalkeeperItems: {
                ...compareGoalkeeperItems[itemKey],
                loadingGoalkeeper: {
                  id: formData.value,
                  teamId: formData.additionalValue,
                },
                isLoadingGoalkeeperInfo: true,
              },
              itemKey,
            }),
          );
        }
      } else {
        dispatch(
          setCompareGoalkeeperItems({
            compareGoalkeeperItems: {
              ...compareGoalkeeperItems[itemKey],
              actualCompareLoadedGoalkeeper: {
                id: byId[goalkeeperId].id,
                teamId: formData.additionalValue,
              },
            },
            itemKey,
          }),
        );
      }
    }
  };

  if (filteredParts.length > 1) {
    return <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectOnlyOneSeason)}</div>;
  }

  const renderGoalkeeperInfo = (actualGoalkeeper: IActualLoadedPlayer) => {
    if (!actualGoalkeeper.id) {
      return null;
    }

    const goalkeeper = byId[actualGoalkeeper.id];
    const goalkeeperInfo = goalkeeper?.goalkeeperInfos?.find(
      info => info.teamId === actualGoalkeeper.teamId,
    );
    const goalkeeperStats = Object.values(goalkeeper?.teamStats)?.find(
      stats => stats.id === actualGoalkeeper.teamId,
    )?.stats;
    const goalkeeperRelativeStats = Object.values(goalkeeper?.teamStats)?.find(
      stats => stats.id === actualGoalkeeper.teamId,
    )?.percentiles;

    return (
      <>
        <BasicPlayerInfo
          name={`${goalkeeper.name} ${goalkeeper.surname}`}
          toi={goalkeeperInfo?.toi ?? 0}
          gp={goalkeeperInfo?.gp ?? 0}
        />
        <CardItemList isAtCompare>
          {getGoalkeeperCards(goalkeeperInfo, actualGoalkeeper.id)}
        </CardItemList>
        <div className={classNames('statistics__goalkeeper-goal-nets')}>
          {goalkeeperStats &&
            goalkeeperRelativeStats &&
            getGoalkeeperGoalNetStats(goalkeeperStats, goalkeeperRelativeStats)}
        </div>
      </>
    );
  };

  return (
    <div className={classNames()} data-testid='goalkeepers-page__compare-goalkeeper'>
      <div
        className={classNames('inputs')}
        data-testid='goalkeepers-page__compare-goalkeeper__inputs'
      >
        {Object.keys(compareGoalkeeperItems).map(itemKey => (
          <div key={itemKey}>
            <Caption label={ITranslationKeys.selectGoalkeeperToCompare} />
            <Controller
              name={`goalkeepers.${itemKey}`}
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className={classNames('inputs__control-box')}>
                  <SearchInput
                    onChange={value => handleChangeGoalkeeper(value, itemKey, onChange)}
                    selected={value ?? undefined}
                    options={filteredGoalkeepersForCompareOptions}
                    placeholder={`${t(ITranslationKeys.defaultSelectPlaceholder)}`}
                    variant='filter'
                  />
                  <Button
                    label={ITranslationKeys.select}
                    disabled={!value}
                    onClick={handleSubmit(values => handleOnSubmit(values, itemKey))}
                  />
                </div>
              )}
            />
          </div>
        ))}
      </div>
      <div
        className={classNames('cards')}
        data-testid='goalkeepers-page__compare-goalkeepers__card'
      >
        {Object.values(compareGoalkeeperItems).map((item, index) => {
          const { actualCompareLoadedGoalkeeper, isLoadingGoalkeeperInfo } = item;

          return (
            <div key={index} className={classNames('cards__card')}>
              {isLoadingGoalkeeperInfo && <Loading size='medium' disableHorizontalMargin />}
              {actualCompareLoadedGoalkeeper &&
                !isLoadingGoalkeeperInfo &&
                renderGoalkeeperInfo(actualCompareLoadedGoalkeeper)}
            </div>
          );
        })}
      </div>
    </div>
  );
};
