import {
  IGenericWowyType,
  IPlayerStats,
  ISelectedPlayerStats,
  IWowyData,
  IWowyDataDTO,
  IWowyPlayer,
} from '../../types';

export const parseWowyData = (data: IWowyDataDTO): IWowyData => {
  const { uuid, toi, gp, ...stats } = data.selected_player[0];

  const selectedPlayer: ISelectedPlayerStats = {
    id: uuid,
    toi,
    gp,
    stats,
  };

  const wowyPlayers: IWowyPlayer[] = data.stats.map(player => {
    const wowyStats = player.stats.reduce((acc, stat) => {
      const { toi, gp, type, ...restStats } = stat;
      const playerStats: IPlayerStats = {
        toi,
        gp,
        stats: restStats,
      };

      if (type === 'hraci_spolu') {
        acc.together = playerStats;
      }

      if (type === 'hraci_zvlast') {
        acc.separately = playerStats;
      }

      if (type === 'spoluhraci_zvlast') {
        acc.teammateSeparately = playerStats;
      }

      return acc;
    }, {} as IGenericWowyType<IPlayerStats>);

    return {
      id: player.uuid,
      wowyStats,
    };
  });

  return {
    selectedPlayer,
    wowyPlayers,
  };
};
