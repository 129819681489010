import { FC } from 'react';

import { createClassNames } from '../../../utils';
import './GoalNetStatsCircle.styles.scss';

export interface IGoalNetStatsCircleProps {
  /**
   * Value in the circle.
   */
  value?: number;
  /**
   * If true, the percent sign is shown next to the value.
   */
  showPercent?: boolean;
}

const classNames = createClassNames('goal-net-stats-circle');

export const GoalNetStatsCircle: FC<IGoalNetStatsCircleProps> = ({
  value,
  showPercent = false,
}) => {
  return (
    <span className={classNames()} data-testid='goal-net-stats-circle'>
      {showPercent ? value + '%' : value}
    </span>
  );
};
