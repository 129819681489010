import { FC } from 'react';

import { logosPathsTcms } from '../../../constants';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './TeamSelectionCard.styles.scss';

export interface ITeamSelectionCardProps {
  /** Id of the team */
  teamId: string;
  /** Is the item active? */
  isActive?: boolean;
  /** Function to be called on component click */
  onClick?: () => void;
}

const classNames = createClassNames('team-selection-card');

export const TeamSelectionCard: FC<ITeamSelectionCardProps> = ({
  teamId,
  isActive = false,
  onClick,
}) => {
  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isActive,
        }),
      })}
      onClick={onClick}
      data-testid='team-selection-card'
    >
      <img src={logosPathsTcms[teamId]} alt='team-logo' className={classNames('logo')} />
    </div>
  );
};
