import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart } from 'recharts';

import { COLORS_TCMS } from '../../../constants';
import { ITranslationKeys } from '../../../i18n/types';
import { ITrackingUserType } from '../../../types';
import { createClassNames } from '../../../utils';
import './SummaryPieChart.styles.scss';

interface ISummaryPieChartProps {
  /** Selected analytics type total value of displayed data. */
  total: number;
  /** Selected analytics type total value of media. */
  totalMedia: number;
}

const classNames = createClassNames('summary-pie-chart');

/** Summary pie chart component displays difference between media and team anayltics. */
export const SummaryPieChart: FC<ISummaryPieChartProps> = ({ total, totalMedia }) => {
  const { t } = useTranslation();

  const mediaPercentage = total > 0 ? (totalMedia * 100) / total : 0;
  const teamPercentage = 100 - mediaPercentage;
  const data = [
    { type: ITrackingUserType.media, value: mediaPercentage },
    { type: ITrackingUserType.team, value: teamPercentage },
  ];

  return (
    <div className={classNames()}>
      <div className={classNames('legend', { type: ITrackingUserType.media })}>
        {t(ITranslationKeys.media)}
        <span>{Math.round(mediaPercentage * 10) / 10}%</span>
      </div>
      <PieChart width={80} height={80}>
        <Pie
          data={data}
          cx='50%'
          cy='50%'
          startAngle={90}
          endAngle={450}
          innerRadius={16}
          outerRadius={40}
          dataKey='value'
          stroke='none'
        >
          {data.map(({ type }) => (
            <Cell
              key={`cell-${type}`}
              style={{ outline: 'none' }}
              fill={
                type === ITrackingUserType.media ? COLORS_TCMS.fuchsia[600] : COLORS_TCMS.blue[700]
              }
            />
          ))}
        </Pie>
      </PieChart>
      <div className={classNames('legend')}>
        {t(ITranslationKeys.teams)}
        <span>{Math.round(teamPercentage * 10) / 10}%</span>
      </div>
    </div>
  );
};
