import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { NoDataIcon } from '../../icons';
import { createClassNames } from '../../utils';
import './NoData.styles.scss';

export interface INoDataProps {
  /**
   * Message to display to user.
   */
  message?: ITranslationKeys;
}

const classNames = createClassNames('no-data');

/**
 * NoData UI component for displaying error message to user.
 */
export const NoData: FC<INoDataProps> = ({ message = ITranslationKeys.noDataForSelected }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      <NoDataIcon className={classNames('icon')} />
      <span className={classNames('message')}>{t(message)}</span>
    </div>
  );
};
